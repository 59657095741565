import './GenerativeStarfighterMintingInfo.scss';
import PageHeader from '../../PageHeader';
import { episode_dropdown_arrow } from '../../../assets/images/NFT';
import { useState } from 'react';

export default function GenerativeStarfighterMintingInfo() {
  const [mintPlanetInfo, setMintPlanetInfo] = useState(false);
  const [catalystInfo, setCatalystInfo] = useState(false);
  const [planetInfo, setPlanetInfo] = useState(false);

  const toggleMintPlanetInfo = () => {
    setMintPlanetInfo(!mintPlanetInfo);
  };

  const toggleCatalystInfo = () => {
    setCatalystInfo(!catalystInfo);
  };

  const togglePlanetInfo = () => {
    setPlanetInfo(!planetInfo);
  };

  return (
    <div className={'generative-planet-info-container'}>
      <PageHeader headerText={'INFO'} />
      <div className={'mint-planet-info-container'}>
        <div onClick={toggleMintPlanetInfo} className={'mint-planet-info-header'}>
          <img className={mintPlanetInfo ? 'mint-info-arrow rotate' : 'mint-info-arrow'} alt={'>'} src={episode_dropdown_arrow} />
          <div className={'mint-planet-info-header-text'}>HOW DO I MINT A STARFIGHTER?</div>
        </div>
        {mintPlanetInfo && (
          <div className={'mint-planet-info-text'}>
            <p>You can mint a Starfighter using either the Batch Mint or Use Catalyst options.</p>
            <p>
              When using <em>Batch Mint</em>, you can add up to one hundred (100) of your Fleet Mintpass NFTs by clicking the plus (“+”) button. There
              is no restriction on the type of Starfighters to be minted at the same time. However, while using the <em>Batch Mint</em> mode, you can
              NOT add any Catalyst NFTs to the Starfighters, so they will all be assigned random Traits.
            </p>
            <p>
              For more granular control over the Starfighter being minted, use the Use Catalyst option. While you can only mint one of your Fleet
              Mintpass NFTs at a time using this method, it allows you to select which class of Starfighter you would like to receive; Assault,
              Buster, Breacher or Lancer. Note: You can only apply one Trait per Catalyst category.
            </p>
            <p>
              When you are happy with your Starfighter(s), select “Mint” and follow the steps shown in the checklist. Some steps only need to be
              followed once, so don’t worry if they already show as complete!
            </p>
            <p>
              Once you have completed the checklist, you will receive your Starfighter NFT(s) in your wallet. Note: The image and metadata of each
              Starfighter NFT may not be visible immediately as it does take time to generate.
            </p>
          </div>
        )}
      </div>
      <div className={'catalyst-info-container'}>
        <div onClick={toggleCatalystInfo} className={'catalyst-info-header'}>
          <img className={catalystInfo ? 'mint-info-arrow rotate' : 'mint-info-arrow'} alt={'>'} src={episode_dropdown_arrow} />
          <div className={'catalyst-info-header-text'}>CATALYSTS & TRAITS</div>
        </div>
        {catalystInfo && (
          <div className={'catalyst-info-text'}>
            <p>Catalysts are used to influence the attributes of your Starfighter(s) at the time of minting.</p>
            <p>The Build Catalyst defines what build of Starfighter you will receive, along with their corresponding ability set of that build.</p>
          </div>
        )}
      </div>
      <div className={'catalyst-info-container'}>
        <div onClick={togglePlanetInfo} className={'catalyst-info-header'}>
          <img className={planetInfo ? 'mint-info-arrow rotate' : 'mint-info-arrow'} alt={'>'} src={episode_dropdown_arrow} />
          <div className={'catalyst-info-header-text'}>WHERE ARE MY STARFIGHTERS?</div>
        </div>
        {planetInfo && (
          <div className={'catalyst-info-text'}>
            {' '}
            <p className="congratulations-popup-sub-text">
              You can view all your minted starfighters at any time on{' '}
              <a href="https://opensea.io/" target={'_blank'} rel="noreferrer">
                OpenSea
              </a>
              !
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
