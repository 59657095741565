import React from "react";
import "./BuyOnOpenSea.scss"
import {buy_on_opensea_icon} from "../../../assets/images/icons";

export default function BuyOnOpenSea({link, backgroundColor, borderColor, text}) {

  return <div className={'buy-on-opensea-container'} style={borderColor && {backgroundColor: borderColor}}>
    <a href={link} style={backgroundColor && {backgroundColor: backgroundColor}}
       className="buy-on-opensea" disabled={true}
       target="_blank" rel="noreferrer">
      <img alt="" src={buy_on_opensea_icon}/> {text ? text : 'BUY ON OPENSEA'}
    </a>
  </div>

}
