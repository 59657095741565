import { ethers } from 'ethers';
import { Biconomy } from '@biconomy/mexa';

let biconomy;
let biconomyReadyOrError = false;

export const getBiconomy = () => {
  return biconomy;
};

async function getBiconomyProvider(provider) {
  biconomy = new Biconomy(provider, {
    apiKey: process.env.REACT_APP_BICONOMY_API_KEY,
  });

  biconomy
    .onEvent(biconomy.READY, () => {
      // Initialize your dapp here
      console.log('BICONOMY IS READY!!!');
      biconomyReadyOrError = true;
    })
    .onEvent(biconomy.ERROR, (error, message) => {
      // Handle error while initializing mexa
      console.error('BICONOMY ERROR! [' + error + '] [' + message + ']');
      biconomyReadyOrError = true;
    });

  while (!biconomyReadyOrError) {
    await new Promise(r => setTimeout(r, 200));
  }

  return biconomy;
}

export const getBlockchainContract = (contractAddress, abi, provider) => {
  try {
    let prov = new ethers.providers.Web3Provider(provider);
    let sign = prov.getSigner();
    return new ethers.Contract(contractAddress, abi, sign);
  } catch (err) {
    console.error('Blockchain Contract error: ' + err);
  }
};

// no try/catch block so the error can be caught and handled by the component
export const getBlockchainContractV2 = (contractAddress, abi, provider) => {
  let prov = new ethers.providers.Web3Provider(provider);
  let sign = prov.getSigner();
  return new ethers.Contract(contractAddress, abi, sign);
};

export const getBiconomyBlockchainContract = async (contractAddress, abi, userAddress, provider) => {
  try {
    let biconomyProvider = await getBiconomyProvider(provider);
    let sign = biconomyProvider.getSignerByAddress(userAddress);
    return new ethers.Contract(contractAddress, abi, sign);
  } catch (err) {
    console.error('Biconomy Blockchain Contract error: ' + err);
  }
};
