import React, { useContext, useEffect, useState } from "react";

import jwt from "jsonwebtoken";
import "./Downloade.scss";

import { walletContext } from "../utils/WalletContext";

import PageHeader from "../components/PageHeader";
import HeaderComponent from "../components/Header/HeaderComponent";
import Footer from "../components/Footer/Footer";
import CustomConnectWalletButton from "../components/CustomeConnectWalletButton/CustomeConnectWalletButton";
import FighterLady from "../components/Footer/FooterElements/FighterLady/FighterLady";
import initializeTimelines from "../old/scripts/timelines";
import ShowOnScroll from "../old/scripts/show-on-scroll";
import { downloadDescriptions } from '../utils/GeneralUtils';
import AccessCode from "../components/Download/AllTabs/AccessCode";
import DownloadCodeContent from "../components/Download/DownloadCodeContent";
import { ReactComponent as WarningSign } from "../assets/images/vectors/orange-warning-sign.svg";
import { useSignMessage } from 'wagmi'
import {AuthenticationManager} from "../utils/auth";

// export default class Download extends React.Component {
export default function Download() {
  useContext(walletContext.context);
  const windowsBetaDownloadLink = process.env.REACT_APP_GAME_WINDOWS_URL;
  const macOSBetaDownloadLink = process.env.REACT_APP_GAME_MACOS_URL;

  const [error, setError] = useState(null);
  const [verified, setVerified] = useState(false);
  const [betaAccessToken, setBetaAccessToken] = useState(null);
  const [signingToken, setSigningToken] = useState(null);
  const [activeTab, setActiveTab] = useState('alpha');
  const [windowsDownloadLink, setWindowsDownloadLink] = useState('');
  const [macOSDownloadLink, setMacOSDownloadLink] = useState('');

  const { signMessage } = useSignMessage({
    async onSuccess(data) {
      try {
        //Just finished signing message - save signature and navigate page on now
        // const tokenResponse = await requestAccessToken(walletContext.currentWallet, signingToken, data);
        const tokenResponse = await AuthenticationManager.instance.requestAccessToken(walletContext.currentWallet, signingToken, data);

        if (tokenResponse) {
          setVerified(true);
          setError(null);
          setBetaAccessToken(tokenResponse.accessToken);
        } else {
          setVerified(true);
        }
      } catch (error) {
        if (error.response.status === 403) {
          setError(error);
        } else {
          setError(error);
        }
      }
    },
  });

  useEffect(() => {
    const getData = async () => {
      //  await maybeCreateAccessToken();
      initializeTimelines();
      setTimeout(function () {
        var instShowOnScroll = new ShowOnScroll();
        instShowOnScroll.init();
      }, 250);
      handleDownloadLinks();
      handleTab('beta');
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleDownloadLinks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, betaAccessToken]);


  /**
   * Gets and decodes the signingToken from /auth/v1/token and sets the challenge string in the state for the beta access token.
   * @returns {Promise<void>}
   */
  const handleGenerateToken = async () => {
    const signingToken = await AuthenticationManager.instance.requestSigningToken(walletContext.currentWallet);
    setSigningToken(signingToken);
    let decodedSigningToken = jwt.decode(signingToken);

    signMessage({ message: decodedSigningToken.challenge });
  };

  const handleTab = id => {
    setActiveTab(id);
  };


  const handleDownloadLinks = () => {
    if (betaAccessToken && activeTab === 'beta') {
      setWindowsDownloadLink(windowsBetaDownloadLink);
      setMacOSDownloadLink(macOSBetaDownloadLink);
    } else {
      setWindowsDownloadLink('');
      setMacOSDownloadLink('');
    }
  };

  return (
    <>
      <HeaderComponent noBackground={true} />
      <div className={'download-section-main-container'}>
        <div className={'adjust-download-header'}>
          <PageHeader headerText={'DOWNLOAD'} classname={'page-mobile-header-text-no-margin'} />
        </div>
        {error && <div style={{ marginBottom: '50px' }}>Error: {error.message}</div>}
        <div className={'download-top-description'}>{downloadDescriptions.top}</div>
        {!walletContext.signed && (
          <div className={'align-download-page-boxes'}>
            <div className="mx-auto code-panel">
              <p className={'connect-wallet-general-text-design'}>Connect your wallet to Phantom Galaxies</p>
              <div className={'home-connect-wallet-button'}>
                <div className={'home-connect-wallet-button-mobile'}>
                  <CustomConnectWalletButton />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={'download-code-container'}>
          {walletContext.signed && (
            <DownloadCodeContent id={'beta'} activeTab={activeTab}>
              <AccessCode
                verified={verified}
                betaAccessToken={betaAccessToken}
                activeTab={activeTab}
                successMessage={'Your access code has been generated!'}
              >
                {!betaAccessToken && activeTab === 'beta' && (
                  <>
                    <p className={'download-code-tooltip'}>Generate your game access code by clicking the button below</p>
                    <div
                      className={'generate-token-button'}
                      onClick={async () => {
                        await handleGenerateToken();
                      }}
                    >
                      GENERATE ACCESS CODE
                    </div>
                  </>
                )}
                {error && (
                  <p className={'download-code-error'}>
                    <span>
                      <WarningSign />
                    </span>{' '}
                    Request timed out. Please try again.
                  </p>
                )}
              </AccessCode>
            </DownloadCodeContent>
          )}
        </div>
        <div className={'download-bottom-description'}>
          <p className={'download-button-instruction-text'}>Copy your access code, and download the Phantom Galaxies Launcher.</p>
          {windowsDownloadLink && (
            <div className={'download-buttons-container'}>
              <div className={'download-button-main-container'}>
                <a href={windowsDownloadLink} target="_blank" rel="noreferrer" className={'download-button-for-os'}>
                  <div className="adjust-game-download-button">
                    <div className={'center-the-download-button-value'}>
                      <div className={'download-button-container'}>Windows</div>
                    </div>
                  </div>
                </a>
              </div>
              {activeTab === 'beta' && (
                <div className={'download-button-main-container'}>
                  <a href={process.env.REACT_APP_GAME_HYPER_PLAY_URL} target="_blank" rel="noreferrer" className={'download-button-for-os'}>
                    <div className="adjust-game-download-button">
                      <div className={'center-the-download-button-value'}>
                        <div className={'download-button-container hyper-play'}></div>
                      </div>
                    </div>
                  </a>
                </div>
              )}

              <div className={'element-separator-with-margin'} />
              {macOSDownloadLink && (
                <div className={'download-button-main-container'}>
                  <a href={macOSDownloadLink} target="_blank" rel="noreferrer" className={'download-button-for-os'}>
                    <div className="adjust-game-download-button">
                      <div className={'center-the-download-button-value'}>
                        <div className={'download-button-container'}>macOS</div>
                      </div>
                    </div>
                  </a>
                </div>
              )}
            </div>
          )}
          {downloadDescriptions.bottom}
        </div>
      </div>

      <Footer>
        <FighterLady />
      </Footer>
    </>
  );
}
