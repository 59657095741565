import './OtherNFT.scss'
import ClaimButton from "../../ClaimButton/ClaimButton";

export default function OtherNFT({tokenId, isClaimed, handleClick}){
  const imageUrl = `${process.env.REACT_APP_AFR_TOKEN_IMAGE_BASEURL}/${tokenId}.png`;

  return <>
    <div className={'other-nft-main-container'}>
      <div className={'token-id-container'}>TOKEN ID <span className={'not-mobile'}>-</span> <span className={'token-id-number'}>{tokenId}</span></div>
      <img alt={''} src={imageUrl} className={'other-nft-img'}/>
      <div className={'view-other-nft-button'}>
        <ClaimButton isActive={!isClaimed} activeText={'VIEW'} disabledText={'CLAIMED'} handler={handleClick}/>
      </div>
    </div>
  </>
}
