import HeaderComponent from "../components/Header/HeaderComponent";
import React from "react";
import BottomComponent from "../components/Footer/FooterElements/BottomComponent/BottomComponent";
import ClaimRewardContent from "../components/ClaimRewards/ClaimRewardContent/ClaimRewardContent";


export default function ClaimReward() {
  return <>
    <HeaderComponent noBackground={true}/>
    <ClaimRewardContent/>
    <BottomComponent/>
  </>
}
