import React from "react";
import "./Medal.scss";

export default function Medal(props) {

  return <>
    <div className={"medal-drow-background"}>
      <div className={"medal-content-container"}>
        <img alt="" className={"medal-img-dimension"} src={props.img}/>
        <p className={"medal-text-adjustment"}>{props.text}</p>
      </div>
    </div>
  </>
}
