import './PlanetExplorationCheckInList.scss'
import React from "react";
import CheckInTab from "../../CheckInTab/CheckInTab";

export default function PlanetExplorationCheckInList({checkInList}) {
  return <>
    <div className={'planet-exploration-check-in-list-container'}>
      {checkInList && checkInList.map((checkIn, index) => (
        <div key={index}
             className={'planet-exploration-check-in-list-element'}
        >
          <CheckInTab checkIn={checkIn} index={index+1}/>
        </div>
      ))}
    </div>
  </>
}
