import React from 'react';

export default class Wallet {
  constructor() {
    const pgStorage = JSON.parse(localStorage.getItem('pg.wallet'));
    if (pgStorage) {
      this.walletProvider = pgStorage.walletProvider;
      this.currentWallet = pgStorage.currentWallet;
      this.signed = pgStorage.signed;
      this.signature = pgStorage.signature;
    } else {
      this.walletProvider = undefined;
      this.currentWallet = undefined;
      this.signed = false;
      this.signature = undefined;
    }

    this.context = React.createContext({
      walletProvider: this.walletProvider,
      currentWallet: this.currentWallet,
      signed: this.signed,
      signature: this.signature,
    });
  }

  setCurrentWallet(wallet, provider) {
    this.walletProvider = provider;
    this.currentWallet = wallet;
    this.saveContext();
  }

  setSignature(signature, signedStatus) {
    this.signature = signature;
    this.signed = signedStatus;
    this.saveContext();
  }

  clearPgWallet() {
    this.walletProvider = undefined;
    this.currentWallet = undefined;
    this.signed = false;
    this.signature = undefined;
    this.saveContext();

    localStorage.removeItem('pg.wallet');
  }

  saveContext() {
    let context = {
      walletProvider: this.walletProvider,
      currentWallet: this.currentWallet,
      signed: this.signed,
      signature: this.signature,
    };
    localStorage.setItem('pg.wallet', JSON.stringify(context));
  }
}

export let walletContext = new Wallet();
