import { kyc_header_boarder } from "../assets";

export const Roadmap = ({container, header, roadmap, footer, image}) => (
  <div className={container}>
    <div className={header}>
      <img alt={""} className='psg-border-img' src={kyc_header_boarder}/>
    </div>
    <div className={`${roadmap} oswald-normal-white-45px`}>ROADMAP</div>
    <img alt="Roadmap" className="roadmap-image" src={image} />
    <p className={`${footer} sofiapro-regular-normal-stack-12px`}>
      All dates are estimates and subject to change
    </p>
  </div>
);
