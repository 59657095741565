import React, {useState} from "react";
import "./LeaderBoardSpecialRow.scss"
import {hover_triangle} from "../../../assets/images/AstrafiteRushEvent";

export default function LeaderBoardSpecialRow({rank,player,completion, time,piratesKilled,astrafiteBlocksMined,fireDancersDefeated,targetBlocks}){
  const [copyValue, setCopyValue] = useState("Copy");

  function copyThis(textToCopy) {
    navigator.clipboard.writeText(textToCopy);
    setCopyValue("Copied!");
  }

  function resetCopy() {
    setCopyValue("Copy");
  }


  return (  <>
    <div className={"leader-board-special-row-container"}>
      <div className={"add-white-border-from-row"}/>

      <div className={"leader-board-special-row-content"}>
        <div className={"add-background-from-row"}/>

        <div className={"leader-table-element-description"}>
          <div className={"first-element-in-row add-font-bold"}>{rank}</div>
        </div>
        <div className={"leader-table-element-description"}>
          <div className={"player-element-in-row add-font-bold"} onMouseLeave={() => resetCopy()}
               onClick={() => copyThis(player)}><div className={"player-name"}>
            {
              player.substring(0, 9)
              + "..." +
              player.substring(player.length - 4)

            }
          </div>
            <div className={"player-name-375"}>
              {
                player.substring(0, 4)
                + "..." +
                player.substring(player.length - 3)

              }
            </div>
            <div className="copy-hover-element">
              <div className={"scale-copy-box"}>
                {copyValue}
                <img alt={""} className={"cycle-progress-hover-triangle"} src={hover_triangle}/>
              </div>
            </div>
          </div>

        </div>
        <div className={"leader-table-element-description"}>
          <div className={"completion-element-in-row add-font-bold"}>{completion}{completion!=="-" && "%"}
            <div className="completion-hover-element">
              <div className={"scale-copy-box"}>
                <div> Fire Dancers Defeated - {piratesKilled}/{fireDancersDefeated}</div>
                <div> Astrafite Mined - {astrafiteBlocksMined}/{targetBlocks}</div>
                <img alt={""} className={"cycle-progress-hover-triangle"} src={hover_triangle}/>
              </div>
            </div>
          </div>
        </div>
        <div className={"leader-table-element-description"}>
          <div className={"last-element-in-row add-font-bold"}>{time}</div>
        </div>
      </div>
    </div>

  </>)
}
