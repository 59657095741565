import React from "react";
import "./Badge.scss";

export default function Badge(props) {

  return <>
    <div className={"polygon-border"}>
      <div className={"badge-drow-background"}>
        <div className={"badge-content-container"}>
          <img alt="" className={"badge-img-dimension"} src={props.img}/>
          <p className={"badge-text-adjustment"}>{props.text}</p>
        </div>
      </div>
    </div>
  </>
}
