import './ExplorationLandingContent.scss';
import PageHeader from '../../PageHeader';
import { exploration_landing_border, exploration_landing_image, missions_mobile_image } from '../../../assets/images';
import ExplorationLandingMissionList from '../ExplorationLandingMissionList/ExplorationLandingMissionList';
import React from 'react';
import { walletContext } from '../../../utils/WalletContext';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../LoadingSpinner';
import { useFetchMissions } from '../../../hooks/useFetchMissions';
import { useFetchMissionEntries } from '../../../hooks/useFetchMissionEntries';
import { useNetwork } from 'wagmi';
import SwitchNetworkChainSection from '../../SwitchNetworkChain';
import { useIsCorrectChain } from '../../../hooks/useIsCorrectChain';

export default function ExplorationLandingContent() {
  const isCorrectChain = useIsCorrectChain();
  const navigate = useNavigate();
  const network = useNetwork();
  const location = useLocation();

  const { mission, missionLoadError } = useFetchMissions(location.state?.loadedMission);
  const { missionEntries, isMissionEntriesLoading } = useFetchMissionEntries(mission, walletContext.currentWallet);

  return (
    <div className={'exploration-main-container'}>
      <div className={'exploration-title-container'}>
        <PageHeader headerText={'THE CERES QUADRANT - RECON'} />
      </div>
      <div className={'exploration-title-container-mobile'}>
        <PageHeader headerText={'THE CERES QUADRANT -'} headerTextSecondLine={'RECON'} />
      </div>
      {missionLoadError && <div style={{ marginBottom: '40px', width: '500px', textAlign: 'center' }}>{missionLoadError}</div>}
      {!isCorrectChain && (
        <SwitchNetworkChainSection network={network} message={`You must be on the ${process.env.REACT_APP_CHAINID} network to mint NFTs`} />
      )}
      {walletContext.currentWallet && isCorrectChain && (
        <>
          <img className={'exploration-image-container'} src={exploration_landing_image} alt={'exploration-cover'}></img>
          <img src={missions_mobile_image} style={{ width: '100%' }} alt="Xanorra Slave Barge Nft" className={'exploration-image-container-mobile'} />
          <div className={'exploration-text-container'}>
            Launch into a new planetary space race! Automated drones – the most advanced Pointech innovations – warp into biohazardous uncharted
            reaches where no Ranger can tread. Survive the perils of teeming asteroid belts lying in your path, blasting the hurtling rocks to gather
            valuable Hermesium from their remains. The greater the destruction you wreak, the greater your rewards. Illuminate the unknown frontiers
            of the Ceres Quadrant, and take your first steps towards the future…
          </div>
          <div className={'exploration-text-container'}>
            Select ‘New Mission’ to assess potential ASTRAFER and Planet rewards, choose Planets to explore, and launch forth! Your Active Missions
            are listed below.
          </div>
          <div className={'exploration-text-container'}>
            Note: Launching a new Mission will automatically Bind the Planet to your wallet - preventing it from being moved, sold or used for other
            Missions until the Active Mission is completed or abandoned.
          </div>
          <div className={'exploration-buttons-container'}>
            <button
              className={'exploration-new-mission-button'}
              onClick={() => navigate('/portfolio/tcq-recon/new-mission', { state: { loadedMission: mission } })}
            >
              NEW MISSION
            </button>
            <div
              className={missionEntries?.length > 0 ? 'exploration-view-missions-button-border' : 'exploration-view-missions-button-border disabled'}
            >
              <button
                className={missionEntries?.length > 0 ? 'exploration-view-missions-button' : 'exploration-view-missions-button disabled'}
                onClick={() => navigate('/portfolio/tcq-recon/view-mission', { state: { loadedMissionEntries: missionEntries } })}
                disabled={missionEntries?.length <= 0}
              >
                VIEW MISSIONS
              </button>
            </div>
          </div>
          <img className={'exploration-border-container'} src={exploration_landing_border} alt={'exploration-border'}></img>
          {missionEntries && <ExplorationLandingMissionList missionEntries={missionEntries} />}
          {isMissionEntriesLoading && <LoadingSpinner marginTop={'64px'} />}
        </>
      )}
    </div>
  );
}
