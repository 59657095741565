import './PlanetPurchaseTCsContent.scss';

export default function PlanetPurchaseTCsContent() {
  return (<div className={'planet-purchase-tcs-content'}>
    <h3>TERMS AND CONDITIONS</h3>

    <p>Last updated: May 20, 2022</p>

    <p>Please read these terms and conditions carefully before using this Website.</p>

    <h4>INTRODUCTION</h4>

    <p>The Phantom Galaxies user-owned planets and asteroids (each a "<b>Planet</b>" and collectively the
      "<b>Planets</b>") is a collection of digital collectibles or non-fungible tokens (each an "<b>NFT</b>", and in
      plurality the "<b>NFTs</b>") utilizing blockchain technologies on the Polygon Network. This Website is only an
      interface allowing Users (as defined below) to transact and interact with the NFTs. Users are entirely
      responsible for the safety and management of their own cryptocurrency wallets and in validating all Transactions
      (as defined
      below) generated by this Website before User’s approval. Users expressly acknowledge that there is no ability to
      undo, reverse, or restore any such Transactions.</p>

    <p>This Website, our smart contracts, and all services related to the minting or purchase of the Planets are
      provided to Users on an "as is" basis without any warranty or promise of availability of services of any kind.
      By using this Website, acquiring or minting any NFTs, or interacting with our smart contracts, Users are (i)
      accepting these Terms and (ii) assuming sole responsibility for any and all Transactions involving the NFTs or
      any other Phantom Galaxies collectibles. These Terms are meant to cover any Planet which exist in the Phantom
      Galaxies universe and their associated NFTs.</p>

    <ol className={'planet-purchase-tcs-list-first-level'}>
      <li>DEFINITIONS
        <ol className={'planet-purchase-tcs-list-second-level'}>
          <li>For the purposes of these Terms, the following definitions shall have the same meaning regardless of
            whether they
            appear in singular or in plural:

            <p>"<b>Affiliate</b>" means the respective shareholders, directors, officers, employees, affiliates,
              agents,
              representatives,
              licensors, suppliers, members or service providers of Company or Users.</p>

            <p>"<b>Art</b>" means the all artwork, designs, text, graphics, pictures, information, data, software,
              sound
              files,
              other
              files, content and the selection and arrangement thereof which may be associated with an individual
              NFT.</p>

            <p>"<b>Jurisdiction</b>" means the Hong Kong Special Administrative Region.</p>
            <p>"<b>Company</b>" (referred to as either "<b>Company</b>", "<b>we</b>", "<b>us</b>" or "<b>our</b>" in
              these Terms) refers to Ultra Modern
              Group
              Limited, located at Vistra Corporate Services Centre, Wickhams Cay II, Road Town, Tortola, British
              Virgin
              Islands.</p>

            <p>"<b>Device</b>" means any internet-capable apparatuses, machines, appliances, and hardware, now known
              or
              hereinafter
              devised and/or created, regardless of the particular underlying technology and mechanism and manner of
              connection,
              including, but not limited to, so-called "Smart TVs," computers, laptops, desktops, tablets, iPads, and
              mobile
              phones.</p>

            <p>"<b>Polygon Network</b>" means the blockchain powered by Polygon.</p>
            <p>"<b>License</b>" has the meaning set out in these Terms.</p>

            <p>"<b>Purchased NFT</b>" means an NFT that you have acquired through this Website.</p>

            <p>"<b>Services</b>" means all communications, functionality, features, arrangements, organization, codes,
              software,
              opportunities, interactions, and activities made available on or through this Website.</p>

            <p>"<b>Terms and Conditions</b>" (also referred as these "<b>Terms</b>" or this "<b>Agreement</b>") mean
              these Terms that form
              the
              entire
              agreement between Company and Users regarding the use of this Website.</p>

            <p>"<b>Third-Party Providers</b>" means any individuals, entities, services, manufacturers, organizations,
              contributors, or
              marketplaces involved with or connected to any Transaction.</p>

            <p> "<b>Transaction(s)</b>" means any and all aspects of Users’ purchase and/or interactions with NFTs on
              and/or
              through the
              Website and/or any Third-Party Providers.</p>

            <p>"<b>User</b>", "<b>Users</b>", "<b>you</b>", or "<b>your</b>" means any individual or Affiliate
              accessing
              this Website.</p>
            <p>"<b>Website</b>" means the website accessible at https://phantomgalaxies.com.</p>
          </li>
          <li>All capitalized terms or expressions not otherwise defined in Section 1.1 above shall have the meanings
            ascribed to them in these Terms.
          </li>

          <li>Wherever a singular expression is used in these Terms, that expression is considered as including the
            plural or
            the body corporate where required by the context.
          </li>
        </ol>
      </li>
      <li>ACKNOWLEDGMENT
        <ol className={'planet-purchase-tcs-list-second-level'}>
          <li>By accessing and using the Website, User represents, warrants, covenants, and affirms that User:
            <ol className={'planet-purchase-tcs-list-third-level'}>
              <li>has read, comprehends, and agrees to be bound and to abide by these Terms;</li>
              <li>is eighteen (18) years or older; and</li>
              <li>solely responsible for ensuring that all others who use or otherwise access the Website satisfy the
                requirements set forth in these Terms at all times.
              </li>
            </ol>
            <li>If User does not satisfy the foregoing requirements, adherence to these Terms, including, any
              subsequent
              modifications, updates, or alterations to any of the foregoing, User is required, and hereby agrees, to
              immediately cease and discontinue any access to and use of the Website.
            </li>
          </li>
        </ol>
      </li>

      <li>DATA PROTECTION AND PRIVACY POLICY
        <ol className={'planet-purchase-tcs-list-second-level'}>
          <li>You agree and understand that identification data (name, billing address, personal ID number) will be
            shared with
            your contractual partners with whom you will enter in any Transactions on this Website (all under terms of
            our
            Privacy Policy (as defined below)), or in other cases as covered in our Privacy Policy.
          </li>

          <li>Protecting your privacy is very important to us. Before accessing the Website and/or accessing/using any
            of the
            Services, you are required to review and consent to our Privacy Policy, accessible on <a href="https://phantomgalaxies.com/PrivacyPolicy.pdf" target="_blank" rel="noreferrer">https://phantomgalaxies.com/PrivacyPolicy.pdf</a>, where
            we
            explain how we collect, use, store and disclose information regarding your privacy ("Privacy Policy").
            When
            accessing or using the Services, you agree with the Privacy Policy as well as these Terms.
          </li>
        </ol>
      </li>
      <li>OWNERSHIP OF THE NFTS
        <ol className={'planet-purchase-tcs-list-second-level'}>
          <li>Upon the delivery of any Purchased NFTs from the Company to Users, Users shall have the following:
            <ol className={'planet-purchase-tcs-list-third-level'}>
              <li>all rights of ownership to the Purchased NFTs, including rights of possession, control, commercial
                exploitation,
                and transfer; and
              </li>
              <li>the limited License (as defined below) regarding the use of the Art that is the proprietary property
                of the
                Company or its Affiliates.
              </li>
            </ol>
          </li>

          <li>The Art and other rights associated with the Art, including ownership, derivative uses and other such
            rights to
            commercialize and exploit the Artwork, shall be excluded from the sale of the NFTs on this Website and
            exclusively
            retained by the Company or its Affiliates.
          </li>

          <li>User hereby acknowledges and understands that all Transactions are mediated entirely by smart contracts
            and the
            Polygon Network, outside of the control of Company and any of Company’s Affiliates. At no point may
            Company
            seize,
            freeze, or otherwise modify the ownership of any NFTs.
          </li>
        </ol>
      </li>
      <li>LICENSE
        <ol className={'planet-purchase-tcs-list-second-level'}>
          <li>Subject to your compliance with these Terms, Company hereby grants you a limited, worldwide,
            non-exclusive,
            royalty-free right and license (the "License") to use, copy, and display any Purchased NFT and the
            underlying Art
            is
            the proprietary property of the Company or its Affiliates, together with any extensions thereof that you
            choose
            to
            create or use, in any media now known or hereinafter devised or created, solely:
            <ol className={'planet-purchase-tcs-list-third-level'}>
              <li>for personal use: for your own personal, non-commercial use;</li>
              <li>for commercial use: for creating derivative works for commercial use based upon the Art, such as
                using
                the
                Art in
                connection with various creative works and/or products, including, but not limited to, film,
                television,
                animated
                productions, video games, endorsements, designs, products, merchandising and any other creative
                activities,
                which
                display or represent copies of the Art (each, a "Derivative Work"), provided that such commercial use
                of
                the
                Derivative Work does not result in you earning more than Three Hundred Thousand US Dollars
                ($300,000.00)
                in
                gross
                revenue each year; and
              </li>
              <li>as part of a marketplace, third-party website or application (each, a "Platform" and in plurality,
                "Platforms")
                that
                permits any Transaction or display of any Purchased NFT, even where any revenue generated from the use
                of
                the
                Purchased NFT as part of any Platform exceeds Three Hundred Thousand US Dollars ($300,000.00) each
                year,
                provided
                that (i) the Platform verifies cryptographically on the blockchain each Purchased NFT-owner’s rights
                to
                such
                Transaction or display in order to ensure that only the actual owner can approve such Transaction or
                display
                such
                NFT, and (ii) the Purchased NFT is no longer visible on such Platform once removed from the Platform
                or
                the
                owner
                of
                such Purchased NFT leaves the Platform.
              </li>
            </ol>

            <li>The License can only be transferred if your Purchased NFT has successfully been transferred to the
              purchaser(s)
              with all relevant gas fees settled and such purchaser(s) is shown as the new owner of your Purchased
              NFT.
            </li>

            <li>For the avoidance of doubt, the License is not available for any Art that contains any third-party
              patent
              rights
              (including, without limitation, patent applications and disclosures), copyrights, trade secrets,
              trademarks,
              know-how or any other intellectual property rights recognized in any country or jurisdiction in the
              world.
            </li>
          </li>
        </ol>
      </li>
      <li>RESTRICTIONS
        <ol className={'planet-purchase-tcs-list-second-level'}>
          <li>You agree that you may not, nor permit any third party to do or attempt to do any of the foregoing
            without the
            Company’s express prior written consent in each case: (i) modify the Art for your Purchased NFT in any
            way,
            including, without limitation, the shapes, designs, drawings, attributes, or color schemes (your use of
            any
            extensions will not constitute a prohibited modification hereunder); (ii) use the Art for your Purchased
            NFTs
            to
            advertise, market, or sell any third-party product or service; (iii) use the Art for your Purchased NFTs
            in
            connection with images, videos, or other forms of media that depict hatred, intolerance, violence,
            cruelty, or
            anything else that could reasonably be found to constitute hate speech or otherwise infringe upon the
            rights
            of
            others; (iv) use the Art for your Purchased NFTs in movies, videos, or any other forms of media, except to
            the
            limited extent that such use is expressly permitted in Section 5(b) above or solely for your own personal,
            non-commercial use; (v) sell, distribute for commercial gain (including, without limitation, giving away
            in
            the
            hopes of eventual commercial gain), or otherwise commercialize merchandise that includes, contains, or
            consists
            of
            the Art for your Purchased NFTs, except as expressly permitted in Section 5(b) above; (vi) attempt to
            trademark,
            copyright, or otherwise acquire additional intellectual property rights in or to the Art for your
            Purchased
            NFTs;
            or
            (vii) otherwise utilize the Art for your Purchased NFTs for your or any third party’s commercial benefit
            except
            as
            permitted by these Terms.
          </li>

          <li>The License applies only to the extent that you continue to own the applicable Purchased NFT. If at any
            time
            you
            sell, trade, donate, give away, transfer, or otherwise dispose of your Purchased NFT for any reason, the
            License
            will immediately expire with respect to those Purchased NFTs without the requirement of notice, and you
            will
            have
            no
            further rights in or to the Art for those Purchased NFTs. If you exceed the Three Hundred Thousand US
            Dollars
            ($300,000.00) limitation on annual gross revenue set forth in Section 5(b) above, you will be in breach of
            this
            License, and must send an email to the Company within fifteen (15) days for the potential execution of any
            broader
            license agreement or obtaining an exemption (which may be granted or withheld in Company’s sole and
            absolute
            discretion), otherwise in addition to any remedies that may be available to the Company at law or in
            equity,
            the
            Company may immediately terminate this License; and you will be responsible to reimburse the Company for
            any
            reasonable costs and expenses incurred by the Company during the course of enforcing the terms of the
            License
            against you.
          </li>
        </ol>
      </li>
      <li>TRANSACTIONS VIA THIRD-PARTY PROVIDERS
        <p>Users understand and agree that any Transaction of an NFT will be, at all times, subject to any third-party
        rights that may be transferred by any previous owners and/or Affiliates of such NFT. For the avoidance of
        doubt, Users
        understand and agree to be bound by any and all pre-existing grants and/or assignments that may be attached
        to
          any such NFT.</p>
      </li>
      <li>THIRD-PARTY LINKS
        <p>The Website and/or the Services may contain links to third-party websites or services that are not owned or
        controlled by Company. User hereby acknowledges that Company has no control over, and assumes no
        responsibility
        for,
        the content, privacy policies, or practices of any third-party websites or services. User further acknowledges
        that
        Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged
        to
        be
        caused by or in connection with the use of or reliance on any such content, goods or services available on or
        through any such websites or services. Company strongly advises that User reads the terms of service and
        privacy
          policies of any third-party websites or services that User visits.</p>
      </li>
      <li>THIRD-PARTY APPLICATIONS
        <p>If, to the extent permitted by us from time to time, you grant express permission to a third party to access or
        connect to your account in the Phantom Galaxies universe (if any), either through the third-party’s product or
        service or through this Website, you acknowledge that granting permission to a third party to take specific
        actions
        on your behalf does not relieve you of any of your responsibilities under these Terms. You are fully responsible
        for
        all acts or omissions of any third party using your account credentials. Further, you acknowledge and agree that
        you
        will not hold us responsible for, and will indemnify us from, any liability arising out of or related to any act
        or
        omission of any third party using your account credentials.</p>
      </li>
      <li>TERMINATION
        <p>We may immediately terminate or suspend your access to the Website, without prior notice or liability, for
          any
          reason whatsoever, including, but not limited to, if you breach these Terms. Upon such termination, your right
          to use the Website and/or the Services will cease immediately.</p>
      </li>

      <li>LIMITATION OF LIABILITY
        <ol className={'planet-purchase-tcs-list-second-level'}>
        <li>Notwithstanding any damages that you may incur, the entire liability of Company and its Affiliates under
            any
            provision of these Terms and your exclusive remedy for all of the foregoing shall be limited to the amount
            actually
            paid by you in connection with the Services. To the maximum extent permitted by applicable law, in no event
            shall
            Company or its Affiliates be liable for any special, incidental, indirect, or consequential damages
            whatsoever
            (including but not limited to damages for loss of profits, loss of data or other information, for business
            interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or
            inability
            to use the Services, third-party software and/or third-party hardware used with the Services, loss of any
            gas
            fees
            for failed Transactions, any excessive gas fees charged due to Website or smart contract bugs, any loss of
            any
            NFT
            due to Website or smart contract bugs, loss of assets from your wallet, or otherwise in connection with any
            provision of these Terms), even if Company or its Affiliates has been advised of the possibility of such
            damages
            and
            even if the remedy fails of its essential purpose.
          </li>

          <li>Neither Company nor any of its Affiliates makes any representation or warranty of any kind, express or
            implied:
            (a)
            as to the operation or availability of the Website and/or Services, or the information, content, and
            materials
            or
            products included thereon; (b) that the Website and/or Services will be uninterrupted or error-free; (c) as
            to
            the
            accuracy, reliability, or currency of any information or content provided through the Website and/or
            Services;
            or
            (iv) that the Website and/or Services, its servers, the Transactions, or communications sent from or on
            behalf
            of
            Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.
            Some
            jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable
            statutory
            rights of a consumer, so some or all of the above exclusions and limitations may not apply; but in such a
            case
            the
            exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable
            under
            applicable law.
          </li>
        </ol>
      </li>
      <li>DISCLAIMER
        <ol className={'planet-purchase-tcs-list-second-level'}>
        <li>EXCEPT AS EXPRESSLY PROVIDED IN THESE TERMS, THE COMPANY MAKES NO WARRANTY THAT ANY OF THE NFTS: (A) WILL
            MEET
            USERS’ REQUIREMENTS; OR (B) WILL BE ACCURATE, RELIABLE, COMPLETE OR SAFE. THE COMPANY DISCLAIMS ALL OTHER
            WARRANTIES
            OR CONDITIONS, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OR CONDITIONS OF
            MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, AS TO THE NFTS. THE COMPANY DOES NOT REPRESENT OR
            WARRANT
            THAT
            THE NFTS ARE COMPLETE OR ERROR-FREE. THE COMPANY WILL NOT BE LIABLE FOR ANY LOSS OF ANY KIND FROM ANY ACTION
            TAKEN
            IN RELIANCE ON MATERIAL OR INFORMATION CONTAINED IN THE NFTS. THE COMPANY CANNOT GUARANTEE THE SECURITY OF
            ANY
            DATA
            THAT USERS DISCLOSE ONLINE. USERS ACCEPTS THE INHERENT SECURITY RISKS OF PROVIDING INFORMATION AND DEALING
            ONLINE
            OVER THE INTERNET AND WILL NOT HOLD THE COMPANY RESPONSIBLE FOR ANY BREACH OF SECURITY UNLESS IT IS DUE TO
            THE
            COMPANY’S FRAUD, GROSS NEGLIGENCE OR WILLFUL MISCONDUCT.
          </li>

          <li>THE COMPANY WILL NOT BE RESPONSIBLE OR LIABLE TO USERS FOR ANY USE OF THE NFTS, INCLUDING BUT NOT LIMITED
            TO ANY
            LOSSES, DAMAGES OR CLAIMS ARISING FROM: (A) USER ERROR SUCH AS FORGOTTEN PASSWORDS, INCORRECTLY CONSTRUCTED
            TRANSACTIONS, OR MISTYPED ADDRESSES; (B) SERVER FAILURE OR DATA LOSS; (C) CORRUPTED WALLET FILES; (D)
            UNAUTHORIZED
            ACCESS TO APPLICATIONS; (E) ANY UNAUTHORIZED THIRD-PARTY ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE OF
            VIRUSES, PHISHING, BRUTEFORCING OR OTHER MEANS OF ATTACK AGAINST THE SERVICE OR THE NFTS.
          </li>

          <li>NFTS ARE INTANGIBLE DIGITAL ASSETS. THEY EXIST ONLY BY VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE
            POLYGON
            NETWORK. ANY TRANSFER OF TITLE THAT MIGHT OCCUR IN ANY UNIQUE DIGITAL ASSET OCCURS ON THE DECENTRALIZED
            LEDGER
            WITHIN THE POLYGON PLATFORM. THE COMPANY DOES NOT GUARANTEE THAT THE COMPANY OR ANY OF ITS AFFILIATES CAN
            AFFECT
            THE
            TRANSFER OF TITLE OR RIGHT IN THE NFTS.
          </li>

          <li>The Company makes no claims or guarantees that your Purchased NFT is or will be worth anything. You
            understand
            that
            your Purchased NFT has no inherent monetary value, and any such Purchased NFT should be treated as nothing
            more
            than
            a fun collectible. Additionally, there should be no expectation that your Purchased NFT will create any
            ancillary
            value whether monetary or otherwise.
          </li>

          <li>The Company is not responsible for losses due to vulnerability or any kind of failure, abnormal behavior
            of
            software
            (e.g., wallet, smart contract), blockchains or any other features of the NFTs. The Company is not
            responsible
            for
            losses due to late report by third-party developers or representatives (or no report at all) of any issues
            with
            the
            blockchain supporting the NFTs including forks, technical node issues or any other issues having fund losses
            as
            a
            result.
          </li>

          <li>Any roadmap or sale plan on this Website that detail the objectives that we hope to achieve in respect of
            expanding
            the Phantom Galaxies universe or the collection of the NFTs is provided for reference only and subject to
            any
            changes that we deem necessary in our sole discretion. While our goal is to fulfil such roadmap or sale
            plan, we
            do
            not make any guarantee as to the success or any further development of the Phantom Galaxies universe or the
            NFTs.
            By
            conducting a Transaction hereunder, you agree that you are not relying on any future commitment by us.
          </li>

          <li>Nothing in this agreement shall exclude or limit liability of any party for fraud, willful misconduct or
            negligence
            or any other activity that cannot be limited or excluded by legitimate means.
          </li>
        </ol>
      </li>

      <li>ASSUMPTION OF RISKS
        <ol className={'planet-purchase-tcs-list-second-level'}>
          <li>The prices of the NFTs may be extremely volatile. Fluctuations in the price of other digital assets could
            materially
            and adversely affect the NFTs, which may also be subject to significant price volatility. The Sellers cannot
            guarantee that any purchasers of any NFTs will not lose money.
          </li>

          <li>User is solely responsible for determining what, if any, taxes apply to a Transaction. The Company is not
            responsible for determining the taxes that apply to any Transactions.
          </li>

          <li>There are risks associated with using an internet-based currency, including but not limited to, the risk
            of
            hardware, software and internet connections, the risk of malicious software introduction, and the risk that
            third
            parties may obtain unauthorized access to information stored within a wallet. User accepts and acknowledges
            that
            the
            Company will not be responsible for any communication failures, disruptions, errors, distortions or delays
            User
            may
            experience when using the NFTs after delivery to the User, however caused.
          </li>

          <li>A lack of use or public interest in the creation and development of distributed ecosystems could
            negatively
            impact
            the development of those ecosystems and related applications, and could therefore also negatively impact the
            potential utility or value of the NFTs.
          </li>

          <li>The regulatory regime governing blockchain technologies, cryptocurrencies, and tokens is uncertain, and
            new
            regulations or policies may materially adversely affect the development of and the utility of the NFTs.
          </li>

          <li>The Company’s deliveries will rely on Platforms to perform the Transactions. If the Company is unable to
            maintain
            a
            good relationship with such Platform providers; if the terms and conditions or pricing of such Platform
            providers
            change; if the Company violates or cannot comply with the terms and conditions of such Platforms; or if any
            of
            such
            Platforms loses market share or falls out of favor or is unavailable for a prolonged period of time, access
            to
            and
            use of the Company’s deliveries will suffer.
          </li>

          <li>There are risks associated with purchasing user-generated content, including but not limited to, the risk
            of
            purchasing counterfeit assets, mislabeled assets, assets that are vulnerable to metadata decay, assets on
            smart
            contracts with bugs, and assets that may become untransferable. The Company reserves the right to hide
            collections,
            contracts, and assets affected by any of these issues or by other issues. Your Purchased NFTs may become
            inaccessible and under no circumstances shall the inability to view your Purchased NFTs serve as grounds for
            a
            claim
            against the Company.
          </li>
        </ol>
      </li>
      <li>INDEMNIFICATION
        <ol className={'planet-purchase-tcs-list-second-level'}>
          <li>Users shall indemnify, defend, and hold harmless the Company and its Affiliates, their respective
            directors,
            officers, employees, agents, licensors, attorneys, independent contractors, providers, subsidiaries, and
            affiliates
            (the "Indemnified Parties") from and against any and all actual or alleged claims, actions, proceedings,
            investigations, demands, suits, losses, damages, demand of liability, costs, including attorneys’,
            investigators’,
            and experts’ (or similar) fees, disbursements and all expenses incurred, whether known or unknown, foreseen
            or
            unforeseen, matured or unmatured, or suspected or unsuspected, in law or equity, whether in tort, contract
            or
            otherwise (the "Claims"), arising out of or in connection with:
            <ol className={'planet-purchase-tcs-list-third-level'}>
              <li>any negligent acts, omissions, or willful misconduct by Users;</li>
              <li>Users’ access to and uses or misuses of this Website;</li>
              <li>any dispute between the Users;</li>
              <li>Users’ violation of these Terms;</li>

              <li>Users’ violation or infringement of any rights of any third party, including but not limited to the
                intellectual
                property rights; and/or
              </li>
              <li>Users’ violation or infringement of any laws or regulations.</li>
            </ol>
          </li>
          <li>Users agree to promptly notify Company of any Claims and cooperate with us in defending such Claims. Users
            further
            agree that the Indemnified Parties shall have control of the defense or settlement of any third-party
            Claims.
            This
            indemnity is in addition to, and not in lieu of, any other claims or indemnities that we may have against
            Users.
          </li>
        </ol>
      </li>
      <li>SUCCESSORS AND ASSIGNS

      <p>Users shall have the right to assign its rights under this Agreement in whole without the prior written consent
      of
      the Company to (i) a third party upon the sale of any Purchased NFTs by a User to such third party, or (ii) any
      Affiliate of such User. The Company may assign this Agreement in whole, without the consent of Users, to any of
      its
      Affiliates.</p>
      </li>
      <li>GOVERNING LAW

      <p>This Agreement shall be construed, interpreted and enforced in accordance with, and the respective rights and
      obligations of the parties shall be governed by, the laws of the Jurisdiction without giving effect to its
      conflict
        of law provisions.</p>
      </li>
      <li>DISPUTE RESOLUTION
        <ol className={'planet-purchase-tcs-list-second-level'}>
          <li>Any dispute, controversy, difference or claim arising out of or relating to this Agreement, including the
            existence,
            validity, interpretation, performance, breach or termination thereof or any dispute regarding
            non-contractual
            obligations arising out of or relating to it shall be referred to and finally resolved by arbitration
            administered
            by the Hong Kong International Arbitration Centre ("HKIAC") under the HKIAC Administered Arbitration Rules
            in
            force
            when the Notice of Arbitration is submitted. The law of this arbitration clause shall be the laws of the
            Jurisdiction. The seat of arbitration shall be Hong Kong. The number of arbitrators shall be one (1). The
            arbitration proceedings shall be conducted in English.
          </li>

          <li>If any suit or other proceeding is brought for the enforcement or interpretation of these Terms, or
            because of
            any
            alleged dispute, breach, default or misrepresentation in connection with any of the provisions of these
            Terms,
            the
            successful or prevailing party shall be entitled to recover, from the other party, reasonable attorneys ’
            fees
            and
            other costs incurred in that suit or proceeding, in addition to any other relief to which such party may be
            entitled.
          </li>
        </ol>
      </li>
      <li>SEVERABILITY

      <p>If any term or provision of this Agreement is invalid, illegal or unenforceable in any jurisdiction, such
        invalidity, illegality or unenforceability shall not affect any other term or provision of this Agreement or
        invalidate or render unenforceable such term or provision in any other jurisdiction.</p>
      </li>
      <li>AMENDMENT AND WAIVER
        <ol className={'planet-purchase-tcs-list-second-level'}>
          <li>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. By continuing
            to
            access
            or use the Website and/or the Services after those revisions become effective, you agree to be bound by the
            revised
            terms. If you do not agree to the new terms, in whole or in part, please stop using the Website and/or the
            Services.
          </li>

          <li>No waiver of any provision of this Agreement shall be binding on any party unless consented to in writing
            by
            such
            party. No waiver of any provision of this Agreement shall constitute a waiver of any other provision, nor
            shall
            any
            waiver constitute a continuing waiver unless otherwise provided.
          </li>
        </ol>
      </li>
      <li>NOTICES
        <ol className={'planet-purchase-tcs-list-second-level'}>
          <li>All notices, requests, consents, claims, demands, waivers and other communications hereunder shall be in writing
      and
      shall be deemed to have been given (a) when delivered by hand (with written confirmation of receipt); (b) on the
      next business day if sent by a nationally recognized overnight courier (receipt requested); (c) when sent by
      e-mail
      of a PDF document (with confirmation of transmission and receipt) if sent during normal business hours of the
      recipient, and on the next business day if sent after normal business hours of the recipient or (d) on the third
      (3rd) day after the date mailed, by certified or registered mail, return receipt requested, postage prepaid.
      Such
      communications, if directed to the Company, must be sent to the following email address:

            <p>To the Company: contact@phantomgalaxies.com</p></li>
        </ol>
      </li>
      <li>ENTIRE AGREEMENT

      <p>This Agreement constitutes the entire agreement between the parties with respect to the subject matter hereof
      and
      supersedes all prior agreements, understandings, negotiations and discussions, whether written or oral. There
      are
      no
      conditions, covenants, agreements, representations, warranties or other provisions, express or implied,
      collateral,
        statutory or otherwise, relating to the subject matter hereof except as herein provided.</p>
      </li>
    </ol>
  </div>)
}
