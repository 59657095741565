import {episode_dropdown_arrow} from "../../assets/images/NFT";
import {useState} from "react";
import './InfoDropdown.scss'

export default function InfoDropdown({infoTitle, infoDescription}) {
  const [dropdownInfo, setDropdownInfo] = useState(false);

  const toggleDropdownInfo = () => {
    setDropdownInfo(!dropdownInfo);
  }

  return <div className={'info-dropdown-container'}>
    <div onClick={toggleDropdownInfo} className={'info-dropdown-header'}>
      <img className={dropdownInfo ? 'info-dropdown-arrow rotate' : 'info-dropdown-arrow'} alt={'>'} src={episode_dropdown_arrow}/>
      <div className={'info-dropdown-header-text'}>
        {infoTitle}
      </div>
    </div>
    {dropdownInfo && <div className={'info-dropdown-text'}>
      {infoDescription}
    </div>}
  </div>
}
