import React from "react";
import "./Multiple.scss";

export default function Multiple(props){

  return<>
        <div className={"multiple-main-container"}>
          <p className={"remove-margin"}>{"x"+props.multipleValue}</p>
          <div className={"transparent-background"}></div>
        </div>
  </>
}
