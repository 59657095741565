import "./GenerativePlanetTrait.scss";
import BuyOnOpenSea from "../../NFTComponents/BuyOnOpenSea/BuyOnOpenSea";

export default function GenerativePlanetTrait({
  selected,
  imgSrc,
  title,
  stock,
  isDisabled,
  onClick,
  openseaUrl,
}) {
  const traitClickHandler = () => {
    if (isDisabled) return;
    onClick();
  };

  const traitBorder = () => {
    if (selected) return "planet-trait-border selected";
    else if (!isDisabled) return "planet-trait-border clickable";
    return "planet-trait-border";
  };

  return (
    <div className={"planet-trait-container"}>
      <div onClick={traitClickHandler} className={traitBorder()}>
        <div className={"planet-trait-image-container"}>
          <img className={"planet-trait-image"} src={imgSrc} alt={"PLANET"} />
          <div className={"planet-trait-stock"}>{stock}</div>
        </div>
      </div>
      <div className={"planet-trait-title"} onClick={traitClickHandler}>
        {title.toUpperCase()}
      </div>
      <div className={"planet-trait-open-sea"}>
        <BuyOnOpenSea
          text={"VIEW"}
          borderColor={"#d42758"}
          backgroundColor={"#000"}
          link={openseaUrl}
        />
      </div>
      <div className={"planet-trait-open-sea mobile"}>
        <BuyOnOpenSea
          text={"VIEW"}
          borderColor={"#d42758"}
          backgroundColor={"#000"}
          link={openseaUrl}
        />
      </div>
    </div>
  );
}
