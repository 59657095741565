import './Track&Save.scss'

import CarouselModalButton from "../CarouselModalButton/CarouselModalButton";
import {carouselText} from "../../../utils/GeneralUtils";
import {pointer_image, save_progress_image, track_image} from "../../../assets/images/OnboardingModal";
import React from "react";

export default function TrackSave() {
  return <>
    <div className={'track-main-container'}>
      <div className={"track-title"}>TRACK & SAVE</div>
      <div className={"track-second-title"}>YOUR PROGRESS</div>
      <div className={"track-title-375"}>TRACK AND</div>
      <div className={"track-second-title-375"}>SAVE YOUR</div>
      <div className={"track-third-title-375"}>PROGRESS</div>
      <div className={'track-content'}>
        <div className={'track-container'}>
          <img alt={""} src={track_image} className={'track-image'}/>
          <div className={'track-button-container'}>
            <CarouselModalButton text={'TRACK'}/>
          </div>
        </div>
        <div className={'save-container'}>
          <div className={'save-cycle-container'}>
            <img alt={""} src={save_progress_image} className={'save-progress-image'}/>
            <img alt={""} src={pointer_image} className={'pointer-image'}/>
          </div>
          <div className={'track-button-container'}>
            <CarouselModalButton text={'SAVE'}/>
          </div>
        </div>
      </div>
      <div className={'track-description'}>
        {carouselText.paragraph3}
      </div>
    </div>
  </>
}
