import { Size } from '@pg/planet-api';

export const PlanetSizePGERC721Address = {
  [Size.SMALL_ASTEROID]: process.env.REACT_APP_CONTRACT_ADDRESS_721_SMALL_ASTEROID,
  [Size.LARGE_ASTEROID]: process.env.REACT_APP_CONTRACT_ADDRESS_721_LARGE_ASTEROID,
  [Size.SMALL_PLANET]: process.env.REACT_APP_CONTRACT_ADDRESS_721_SMALL_PLANET,
  [Size.MEDIUM_PLANET]: process.env.REACT_APP_CONTRACT_ADDRESS_721_MEDIUM_PLANET,
  [Size.LARGE_PLANET]: process.env.REACT_APP_CONTRACT_ADDRESS_721_LARGE_PLANET,
};

export const getCurrentPlanetSizeAddress = (size: Size): string => {
  return PlanetSizePGERC721Address[size] as string;
};
