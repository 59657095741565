import React from "react";
import "./ViewOnOpenseaButton.scss";

export default function ViewOnOpenseaButton(props) {
  const { token } = props;

  let openseaUrl = `${process.env.REACT_APP_AFR_TOKEN_OPENSEA_BASEURL}/${token}`;

  return (
    <>
      <a
        href={openseaUrl}
        className="button-view-on-opensea"
        disabled={true}
        target="_blank"
        rel="noreferrer"
      >
        <div className="button-border-view-open-sea">
          <p className="button-text-view-open-sea">VIEW ON OPENSEA</p>
        </div>
      </a>
    </>
  );
}
