import './MissionTab.scss'

export default function MissionTab({title, content, missionRef}) {
  return <>
    <div className={'mission-tab-main-container'} ref={missionRef}>
      <div className={'mission-tab-left-decor'}></div>
      <div className={'mission-tab-right-container'}>
        <p className={'mission-tab-title'}> {title} </p>
        <p className={'mission-tab-content'}> {content}</p>
      </div>
    </div>
  </>
}
