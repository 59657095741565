import { TokenBridgeAction, TokenBridgeFormState, TokenBridgeState, TokenInfo } from './tokenBridgeTypes';
import {
  SET_ERROR_MODAL_TEXT,
  SET_IS_TRANSACTION_LOADING,
  SET_IS_TRANSACTION_SUCCESSFUL,
  SET_RECEIVE_ADDED_TOKEN_BALANCE,
  SET_RECEIVE_BALANCE_IS_LOADING,
  SET_RECEIVE_CHAIN_CONFIG,
  SET_RECEIVE_CHAIN_ID,
  SET_RECEIVE_CHAIN_NAME,
  SET_RECEIVE_TOKEN_BALANCE,
  SET_RECEIVE_TOKEN_NAME,
  SET_SEND_BALANCE_IS_LOADING,
  SET_SEND_CHAIN_CONFIG,
  SET_SEND_CHAIN_ID,
  SET_SEND_CHAIN_NAME,
  SET_SEND_INPUT_AMOUNT,
  SET_SEND_IS_CHAIN_ID_SUPPORTED,
  SET_SEND_SHOW_MODAL,
  SET_SEND_SUBTRACTED_TOKEN_BALANCE,
  SET_SEND_TOKEN_BALANCE,
  SET_SEND_TOKEN_NAME,
  SET_SHOW_ERROR_MODAL,
  SET_SWITCH_SEND_RECEIVE,
  SET_TRANSACTION_HASH,
} from './tokenBridgeConstants';
import {TokenNetworkFormType} from "../TokenForm/TokenNetworkForm";

const tokenInfoInitialState: TokenInfo = {
  name: '',
  logo: '',
  logoAltText: '',
};

const chainConfigInitialState = {
  name: '',
  abi: undefined,
  proxyAddress: '',
  layerZeroChainId: 0,
  chainId: null,
  etherscanUrl: '',
  astraferAddress: '',
  rpcUrl: '',
  astraferAbi: undefined,
  tokenName: '',
  imageSrc: '',
  altImageText: '',
  imageClassName: '',
};

const initialState: TokenBridgeState = {
  send: {
    chainConfig: chainConfigInitialState,
    tokenInfo: tokenInfoInitialState,
    tokenBalance: 0,
    isBalanceLoading: false,
    inputAmount: null,
  },
  receive: {
    chainConfig: chainConfigInitialState,
    tokenInfo: tokenInfoInitialState,
    tokenBalance: 0,
    isBalanceLoading: false,
    inputAmount: null,
  },
  showSendModal: false,
  showErrorModal: false,
  errorModalText: '',
  isTransactionSuccessful: false,
  isTransactionLoading: false,
  transactionHash: undefined,
};

const createSendReceiveReducer = (type: TokenNetworkFormType.Send | TokenNetworkFormType.Receive) => {
  return (state: TokenBridgeFormState, action: TokenBridgeAction): TokenBridgeFormState => {
    switch (action.type) {
      case type === TokenNetworkFormType.Send ? SET_SEND_CHAIN_ID : SET_RECEIVE_CHAIN_ID:
        return { ...state, chainConfig: { ...state.chainConfig, chainId: action.payload } };
      case type === TokenNetworkFormType.Send ? SET_SEND_CHAIN_CONFIG : SET_RECEIVE_CHAIN_CONFIG:
        return { ...state, chainConfig: action.payload };
      case type === TokenNetworkFormType.Send ? SET_SEND_CHAIN_NAME : SET_RECEIVE_CHAIN_NAME:
        return { ...state, chainConfig: { ...state.chainConfig, name: action.payload } };
      case type === TokenNetworkFormType.Send ? SET_SEND_TOKEN_NAME : SET_RECEIVE_TOKEN_NAME:
        return { ...state, tokenInfo: action.payload };
      case type === TokenNetworkFormType.Send ? SET_SEND_TOKEN_BALANCE : SET_RECEIVE_TOKEN_BALANCE: {
        return { ...state, tokenBalance: action.payload };
      }
      case type === TokenNetworkFormType.Send ? SET_SEND_BALANCE_IS_LOADING : SET_RECEIVE_BALANCE_IS_LOADING:
        return { ...state, isBalanceLoading: action.payload };
      case type === TokenNetworkFormType.Send ? SET_SEND_SUBTRACTED_TOKEN_BALANCE : SET_RECEIVE_ADDED_TOKEN_BALANCE: {
        const isSend = type === TokenNetworkFormType.Send;
        const existingBalance = state.tokenBalance || 0;
        const changeAmount = isSend ? -action.payload : action.payload;
        const newBalance = existingBalance + changeAmount;

        return { ...state, tokenBalance: newBalance };
      }
      case SET_SEND_IS_CHAIN_ID_SUPPORTED:
        return { ...state, isChainIdSupported: action.payload };
      case SET_SEND_INPUT_AMOUNT:
        return { ...state, inputAmount: action.payload };
      default:
        return state;
    }
  };
};

const sendReducer = createSendReceiveReducer(TokenNetworkFormType.Send);
const receiveReducer = createSendReceiveReducer(TokenNetworkFormType.Receive);

const tokenBridgeReducer = (state: TokenBridgeState, action: TokenBridgeAction): TokenBridgeState => {
  switch (action.type) {
    case SET_SWITCH_SEND_RECEIVE:
      return {
        ...state,
        send: { ...state.receive },
        receive: { ...state.send },
      };
    case SET_SEND_SHOW_MODAL:
      return {
        ...state,
        showSendModal: action.payload,
      };
    case SET_SHOW_ERROR_MODAL:
      return {
        ...state,
        showErrorModal: action.payload,
      };
    case SET_ERROR_MODAL_TEXT:
      return {
        ...state,
        errorModalText: action.payload,
      };
    case SET_IS_TRANSACTION_SUCCESSFUL:
      return {
        ...state,
        isTransactionSuccessful: action.payload,
      };
    case SET_IS_TRANSACTION_LOADING:
      return {
        ...state,
        isTransactionLoading: action.payload,
      };
    case SET_TRANSACTION_HASH:
      return {
        ...state,
        transactionHash: action.payload,
      };

    default:
      return {
        ...state,
        send: sendReducer(state.send, action),
        receive: receiveReducer(state.receive, action),
      };
  }
};

export { tokenBridgeReducer, initialState };
