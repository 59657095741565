import React, {useContext} from "react";
import "./ListElementComponent.scss";
import {
  curency_icon,
  elements_list_boarder,
  list_element_bottem_horn,
  list_elements_header_horn
} from "../../../../../assets/images/PlanetSalePagePrivate";
import CounterButton from "./ListElementsButtons/CounterButton/CounterButton";
import MintButton from "./ListElementsButtons/MintButton/MintButton";
import {PlanetSaleContext} from "../../../../../components/Context/PlanetSalePageContext";

export default function ListElementComponent(props) {

  const planetSaleContext = useContext(PlanetSaleContext);

  let priceOfNFT=(props.value).toLocaleString();

  function activeButton(){
    if (props.noNfts > 0) {
      return true;
    } else {
      return false;
    }
  }

  return <>
    <div className={"lec-horns-design"}>
      <img alt={""} src={list_elements_header_horn}/>
      <div className={"lec-principle-container"}>
        <div>
          <img alt={""} className={"lec-img-dimension"} src={props.img}/>
        </div>
        <div className={"lec-description"}>
          <p className={"lec-text-alignment"}>{props.text.toUpperCase()}</p>
          <div className={"lec-curency-text"}>
            <img className={"curency-icon"} src={curency_icon} alt=""/>
            <p className={"lec-value-alignment"}>${priceOfNFT}</p>
          </div>
        </div>
        
        {
          props.sellType === "PrivatePresale"
            ?
            <div>You have minted: {props.privateSaleMintedAmount} / {props.privateSaleMaxMintAmount}</div>
            :
            <div>Total minted: {planetSaleContext.totalMinted[props.nftId].toLocaleString()} / {planetSaleContext.totalSupply[props.nftId].toLocaleString()}</div>
        }
        <img alt={""} src={elements_list_boarder}/>
        <CounterButton sellObject={planetSaleContext.saleConfigurations[props.sellType]} numberOfElement={props.noNfts} index={props.index} numberOfPosibleElements={props.allowedNoNfts} nftType={props.text} nftId={props.nftId} sellType={props.sellType}/>
        <MintButton  activeButton={activeButton()} index={props.index} ticketId={props.ticketId} v={props.v} r={props.r} s={props.s} numberOfNFT={props.noNfts} planet={props.text} cost={props.value} nftId={props.nftId} sellType={props.sellType}/>
      </div>
      <img alt={""} className={"lec-bottem-horns"} src={list_element_bottem_horn}/>
    </div>
  </>
}
