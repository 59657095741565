import './SelectNetworkButton.scss';
import { SelectButton } from '../../Common/SelectButton/SelectButton';
import { SelectTextContent } from '../SelectTextContent/SelectTextContent';
import { PropsWithChildren } from 'react';
import { TokenBalance } from '../TokenBalance/TokenBalance';
import { TokenNetworkFormType } from '../TokenNetworkForm';

type SelectNetworkButtonProps = {
  name: string;
  formType: TokenNetworkFormType;
  hasMultipleNetworkOptions?: boolean;
  className?: string;
  imgWrapperClassName?: string;
  networkIcon?: string;
  handleSelectButton?: () => void;
  disableNetworkSwitch?: boolean;
  walletBalance?: number;
  isBalanceLoading?: boolean;
};

export function SelectNetworkButton({
  name,
  hasMultipleNetworkOptions,
  className,
  imgWrapperClassName,
  networkIcon,
  handleSelectButton,
  disableNetworkSwitch,
  walletBalance,
  isBalanceLoading,
  formType,
}: PropsWithChildren<SelectNetworkButtonProps>) {
  return (
    <SelectButton className={className} handleClick={handleSelectButton} disabled={disableNetworkSwitch}>
      <div className={'select-network__container'}>
        <p>{formType === TokenNetworkFormType.Send ? 'Transfer from' : 'Transfer to'}</p>
        <div className={'select-network__main-content'}>
          <div className={`${imgWrapperClassName ?? 'select-network__icon-wrapper'} ${networkIcon === '' || undefined ? 'none' : ''}`}>
            <img src={networkIcon} alt={name} />
          </div>
          <SelectTextContent name={name} title={''} displayArrow={hasMultipleNetworkOptions && !disableNetworkSwitch}>
            <TokenBalance walletBalance={walletBalance ?? 0} isBalanceLoading={isBalanceLoading} />
          </SelectTextContent>
        </div>
      </div>
    </SelectButton>
  );
}
