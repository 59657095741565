import React from 'react';
import './SelectButton.scss';

type SelectButtonProps = {
  children: React.ReactNode;
  className?: string;
  handleClick?: () => void;
  disabled?: boolean;
};

export function SelectButton({ children, className, handleClick, disabled }: SelectButtonProps) {
  return (
    <>
      <button className={`select__button ${className} ${disabled ? 'disabled' : ''}`} onClick={handleClick} disabled={disabled}>
        {children}
      </button>
    </>
  );
}
