import "./GenerativeStarfighterCatalyst.scss";
import {
  add_catalyst_icon,
  disabled_catalyst_icon,
  no_trait_icon,
} from "../../../assets/images/GenerativePlanetMinting";

export default function GenerativeStarfighterCatalyst({
  title,
  selected,
  onClick,
  isDisabled,
  image,
}) {
  const catalystHandler = () => {
    if (isDisabled) return;
    onClick();
  };

  const isDefaultImage = () => {
    return (
      image === add_catalyst_icon ||
      image === no_trait_icon ||
      image === disabled_catalyst_icon
    );
  };

  const getImageClass = () => {
    let className = "";
    if (isDefaultImage()) {
      className += "catalyst-trait-image";
      if (image === disabled_catalyst_icon) {
        className += " disabled-icon";
      }
    } else className += "catalyst-trait-image";
    if (isDisabled) {
      className += " disabled-image";
    }
    return className;
  };

  const catalystState = () => {
    if (isDisabled) return "disabled-catalyst";
    if (selected) return "selected-catalyst";
    else return "";
  };

  const catalystBorder = () => {
    if (isDisabled) return "planet-catalyst-border";
    if (selected) return "planet-catalyst-border selected";
    else if (!isDisabled) return "planet-catalyst-border clickable";
    else return "planet-catalyst-border";
  };

  return (
    <div className="planet-catalyst">
      <div className={catalystBorder()} onClick={catalystHandler}>
        {selected && !isDisabled ? (
          <img
            src={image}
            className={"catalyst-trait-image"}
            alt={"catalyst"}
          />
        ) : (
          <img src={image} className={getImageClass()} alt={"catalyst"} />
        )}
      </div>
      <div
        className={`planet-catalyst-title ${catalystState()}`}
        onClick={catalystHandler}
      >
        {title?.toUpperCase()}
      </div>
    </div>
  );
}
