import './CopyAccesCodeTooltip.scss'
import React, {useContext} from "react";
import {TooltipContext} from "../../Context/TooltipContext";
import {hover_triangle_white} from "../../../assets/images/AstrafiteRushEvent";

export default function CopyAccessCodeTooltip() {
  const tooltipContext = useContext(TooltipContext);
  return <>
    {tooltipContext.copyAccessCodeStatus.visible ? <div className={'copy-code-tooltip-main-container'}>
      <img alt={""} className={"copy-code-triangle-white"} src={hover_triangle_white}/>
      <div className={'scale-copy-code'}>
        {tooltipContext.copyAccessCodeStatus.text}
      </div>
    </div> : <></>}
  </>
}
