import React, { useState} from "react";
import {
  astrafer_team_background, astrafer_team_laptop_background, astrafer_team_1024_background,
  linkedin_icon, team_advisor_1, team_advisor_2,
  team_member_1,
  team_member_2,
  team_member_frame, team_section_mobile_background, team_section_tablet_background
} from "../../assets/images/Token";
import './AstraferTeam.scss'
import PageHeader from "../PageHeader";
import RevealInfo from "../AboutSection/RevealInfo/RevealInfo";
import {animoca_logo, blow_fish} from "../../assets/images/AboutSection";
import {revealText} from "../../utils/GeneralUtils";

export default function AstraferTeam() {


  const [dropDownState, setDropDownState] = useState([{blowFish: "blowFish", state: false}, {
    animoca: "animoca",
    state: false
  }]);

  function backgroundAnimation(value) {
    if (value.blowFish !== undefined) {

      const newState = dropDownState.map(obj => {
        if (obj.blowFish !== undefined) {
          return {...obj, state: !obj.state};
        }
        return obj;
      });

      setDropDownState(newState);
    }
    if (value.animoca !== undefined) {
      const newState = dropDownState.map(obj => {
        if (obj.animoca !== undefined) {
          return {...obj, state: !obj.state};
        }
        return obj;
      });

      setDropDownState(newState);
    }

  }

  return (<>
    <div className={true?"team-main-container":"animation-team-main-container"}>
      <img className={"team-background"} src={astrafer_team_background} alt={""}/>
      <img className={"team-mobile-background"} alt={""} src={team_section_mobile_background}/>
      <img className={"team-tablet-background"} alt={""} src={team_section_tablet_background}/>
      <img className={"team-laptop-background"} src={astrafer_team_laptop_background} alt={""}/>
      <img className={"team-1024-background"} src={astrafer_team_1024_background} alt={""}/>

      <div className={"team-members-main-container"}>

        <div className={"development-team-container"}>
          <div className={"team-title"}>
            <PageHeader headerText="TEAM" classname="page-mobile-header-text-no-margin"/>
          </div>

          <div className={"team-section"}>
            <div className={"team-member"}>
              <div className={"member-element"}>
                <img className={"member-frame"} src={team_member_frame} alt={""}/>
                <img className={"member-image"} src={team_member_1} alt={""}/>
              </div>
              <a href={"https://www.linkedin.com/in/benjamin-r-lee/"} target={"_blank"} rel={"noreferrer"}>
                <img className={"linkedin-icon"} src={linkedin_icon} alt={""}/>
              </a>
              <div className={"team-member-name"}>Benjamin Lee</div>
              <div className={"team-member-description"}>Blowfish Studios <div>&nbsp; Co-Founder</div></div>
            </div>


            <div className={"team-member"}>
              <div className={"member-element"}>
                <img className={"member-frame"} src={team_member_frame} alt={""}/>
                <img className={"member-image"} src={team_member_2} alt={""}/>
              </div>
              <a href={"https://au.linkedin.com/in/aarongrove"} target={"_blank"} rel={"noreferrer"}>
                <img className={"linkedin-icon"} src={linkedin_icon} alt={""}/>
              </a>
              <div className={"team-member-name"}>Aaron Grove</div>
              <div className={"team-member-description"}>Blowfish Studios <div>&nbsp; Co-Founder</div></div>
            </div>
          </div>
        </div>

        <div className={"advisors-member-main-controller"}>
          <div className={"team-title"}>
            <PageHeader headerText="ADVISORS" classname="page-mobile-header-text-no-margin"/>
          </div>

          <div className={"team-section"}>
            <div className={"team-member"}>
              <div className={"member-element"}>
                <img className={"member-frame"} src={team_member_frame} alt={""}/>
                <img className={"member-image"} src={team_advisor_1} alt={""}/>
              </div>
              <a href={"https://www.linkedin.com/in/yatsiu/"} target={"_blank"} rel={"noreferrer"}>
                <img className={"linkedin-icon"} src={linkedin_icon} alt={""}/>
              </a>
              <div className={"team-member-name"}>Yat Siu</div>
              <div className={"team-member-description"}>Co-founder and executive chairman<br/>of Animoca Brands</div>
            </div>

            <div className={"team-member"}>
              <div className={"member-element"}>
                <img className={"member-frame"} src={team_member_frame} alt={""}/>
                <img className={"member-image"} src={team_advisor_2} alt={""}/>
              </div>
              <a href={"https://www.linkedin.com/in/chenghtmark/"} target={"_blank"} rel={"noreferrer"}>
                <img className={"linkedin-icon"} src={linkedin_icon} alt={""}/>
              </a>
              <div className={"team-member-name"}>Mark Cheng</div>
              <div className={"team-member-description"}>GameFi Ventures Advisor</div>
              <p><br/></p>
            </div>
          </div>

        </div>

        <div className={"about-content-container-from-mobile-resolution"}>
          <div className={"about-title"}>
            <PageHeader headerText="ABOUT" classname="page-mobile-header-text-no-margin"/>
          </div>

          <div className={"reveal-info-size-container"}>
            <div className={!dropDownState[0].state?"reveal-info-adjustments":"reveal-info-adjustments-animation"}>
              <RevealInfo imgToShow={blow_fish} textToShow={revealText.paragraph1}
                          heightAnimationLoader={() => backgroundAnimation(dropDownState[0])}
                          onLoade={dropDownState[0].state}/>
            </div>
            <div className={!dropDownState[1].state?"reveal-info-adjustments-margin":"animation-reveal-info-adjustments-margin"}>
              <RevealInfo imgToShow={animoca_logo} textToShow={revealText.paragraph2}
                          heightAnimationLoader={() => backgroundAnimation(dropDownState[1])}
                          onLoade={dropDownState[1].state}/>
            </div>
          </div>


        </div>
      </div>

    </div>
  </>)
}
