
import './SafetyFirst.scss'
import {terms_check_icon, terms_icon} from "../../../assets/images/CarouselModal";
import CarouselModalButton from "../CarouselModalButton/CarouselModalButton";
import {carouselText, legalAndPolicyLinks} from "../../../utils/GeneralUtils";

export default function SafetyFirst(){

  function checkTermsAndConditions(){
    window.open(legalAndPolicyLinks.termsAndConditionsAfr, '_blank', 'noopener,noreferrer');
  }

  return <>
    <div className={'safety-first-container'}>
      <p className={'safety-first-title'}>SAFETY FIRST</p>
      <p className={'safety-first-title-375'}>SAFETY</p>
      <p className={'safety-first-second-title-375'}>FIRST</p>
      <div className={'safety-first-content'}>
        <img alt={''} src={terms_icon} className={'terms-icon-image'}/>
        <img alt={''} src={terms_check_icon} className={'terms-check-icon-image'}/>
      </div>
      <div className={'safety-first-button'}>
        <CarouselModalButton text={'CLICK TO READ'} toExecute={checkTermsAndConditions}/>
      </div>
      <div className={'safety-first-description'}>
        {carouselText.paragraph5}
      </div>
    </div>
  </>
}
