import './GenericButton.scss';

type GenericButtonProps = {
  handleClick?: () => void,
  buttonText: string,
  buttonClass?: string,
  disabled?: boolean,
  borderClass?: string,
  bordered?: boolean,
}

export default function GenericButton({ handleClick, buttonText, buttonClass, disabled=false, borderClass, bordered=false }: GenericButtonProps) {
  if (bordered)
  return (
    <div className='generic-button-container'>
      {bordered && <div className={`generic-button-border ${borderClass}`} />}
      <div className={buttonClass ? `button generic-button-bordered ${buttonClass} ${disabled ? 'disabled' : ''}` : `button generic-button-bordered ${disabled ? 'disabled' : ''}`}>
        <div className="button-text" onClick={handleClick}>
          {buttonText}
        </div>
      </div>
    </div>
  );

  return (
    <div className={buttonClass ? `button ${buttonClass} ${disabled ? 'disabled' : ''}` : `button ${disabled ? 'disabled' : ''}`}>
      <div className="button-text" onClick={handleClick}>
        {buttonText}
      </div>
    </div>
  );
}
