import {boarder_detail_bottom, boarder_detail_top} from "../../../assets/images/AstrafiteRushEvent";
import CopyAccessCodeTooltip from "../../Tooltip/CopyAccesCodeTooltip/CopyAccesCodeTooltip";
import copy from "copy-to-clipboard";
import React from "react";
import {TooltipContext} from "../../Context/TooltipContext";

export default class AccessCode extends React.Component {
  static contextType = TooltipContext;

  constructor(props) {
    super(props);
    this.state = {
      accessToken: null
    };
  }

  componentDidMount() {
    this.setState({
      accessToken: this.props.alphaAccessToken
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.activeTab === "alpha" && this.props.alphaAccessToken !== this.state.accessToken) {
      this.setState({
        accessToken: this.props.alphaAccessToken
      })
    } else if (this.props.activeTab === "beta" && this.props.betaAccessToken !== this.state.accessToken) {
      this.setState({
        accessToken: this.props.betaAccessToken
      })
    }
  }
  resetCopy() {
    this.context.setCopyAccessCodeStatus(prevState => ({
      ...prevState,
      ...{text: 'Copy access code.'}
    }));
  }

  setCopyTooltipVisible(visibility){
    this.context.setCopyAccessCodeStatus(prevState => ({
      ...prevState,
      ...{visible: visibility}
    }));
  }
  copyTextToClipboard() {
    copy(this.state.accessToken);
    this.context.setCopyAccessCodeStatus(prevState => ({
      ...prevState,
      ...{text: 'Copied!'}
    }));
  }
  handleMouseLeave(){
    this.resetCopy()
    this.setCopyTooltipVisible(false)
  }

  handleMouseEnter(){
    this.setCopyTooltipVisible(true)
  }



  render() {
    return (
      <div className={"download-code-panel"}>
        <img alt={""} className={"top-crown"} src={boarder_detail_top}/>
        <div className={"line-separator"}/>

        {this.state.accessToken &&
          <>
            <div className="content-of-the-code-element">
              <p className={"set-the-download-text-design"}>{this.props.successMessage}</p>
              <a href={"#/"} rel="noreferrer"  id="copy-access-code" className="accessToken"
                 onMouseLeave={() => {this.handleMouseLeave()}} onClick={() => {this.copyTextToClipboard()}} onMouseEnter={() => {this.handleMouseEnter()}}>{this.state.accessToken}</a>
            </div>
            <CopyAccessCodeTooltip/>
          </>
        }

        {this.props.children}

        <div className={"line-separator"}/>
        <img alt={""} className={"bottom-crown"} src={boarder_detail_bottom}/>
      </div>
    )
  }
}

