import './PlanetDetail.scss';
import HeaderComponent from '../components/Header/HeaderComponent';
import { useCallback, useEffect, useState } from 'react';
import { isValidGuid } from '../utils/GeneralUtils';
import FighterLady from '../components/Footer/FooterElements/FighterLady/FighterLady';
import Footer from '../components/Footer/Footer';
import { ShareButton } from '../components/Common/ShareComponent/ShareButton';
import { PlanetResourceData, PlanetResourceList } from '../components/PlanetResourceList/PlanetResourceList';
import { PGAccordion } from '../components/Common/Accordion/PGAccordion';
import { Accordion } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
  getPlanetByGuid,
  getPlanetByChainIdContractAddressAndTokenId,
  getPlanetRewardStatusByChainIdContractAddressAndTokenId,
} from '../utils/ApiCalls';
import LoadingSpinner from '../components/LoadingSpinner';
import {
  PlanetWithImageUrls,
  Size,
  BiomeString,
  DistanceFromSunString,
  BedrockFirmnessStringLabel,
  SurfaceRoughnessStringLabel,
  ResourceAbundanceStringLabel,
  NebulaString,
  MoonsString,
  RingSystemString,
  OverallHostilityStringLabel,
  StratosphereTurbulenceStringLabel,
  ElectromagneticInterferenceStringLabel,
  VisibilityStringLabel,
  HostilityTrait,
} from '@pg/planet-api';
import { planet_terraforming } from '../assets/images/Planets';
import { ImageFallback } from '../components/Common/Image/ImageFallback';
import { AssetNotFound } from '../components/Common/AssetNotFound/AssetNotFound';
import { ASSET_NOT_FOUND_MESSAGES } from '../utils/Globals';
import { planet_not_found } from '../assets/images/AssetNotFound';
import { useFetchMissions } from '../hooks/useFetchMissions';

const HostilityTraitStringLabel = {
  [HostilityTrait.UNSTABLE_TOPOGRAPHY]: 'UNSTABLE TOPOGRAPHY',
  [HostilityTrait.ACID_POOLS]: 'ACID POOLS',
  [HostilityTrait.MOLTEN_POOLS]: 'MOLTEN POOLS',
  [HostilityTrait.ICE_SHEET_FISSURE]: 'ICE SHEET FISSURE',
};

// Size value to name mapping
const sizeMapStringLabel = {
  [Size.SMALL_ASTEROID]: 'SMALL ASTEROID',
  [Size.LARGE_ASTEROID]: 'LARGE ASTEROID',
  [Size.SMALL_PLANET]: 'SMALL PLANET',
  [Size.MEDIUM_PLANET]: 'MEDIUM PLANET',
  [Size.LARGE_PLANET]: 'LARGE PLANET',
};

export interface MissionRewardsStatus {
  missionId: number;
  missionName: string;
  eligible: boolean;
}

export interface Mission {
  checkpointCount: number;
  completionCopy: string;
  createdAt: string;
  duration: number;
  id: number;
  informationCopy: string;
  name: string;
  phase: string;
  updatedAt: string;
  validFrom: string;
  validTo: string;
}

export enum ClaimStatus {
  CLAIMED = 'CLAIMED',
  UNCLAIMED = 'UNCLAIMED',
}

const PlanetDetails = () => {
  const { guid, chainId, contractAddress, tokenId } = useParams();
  const [planet, setPlanet] = useState<PlanetWithImageUrls | null>();
  const [planetImageUrl, setPlanetImageUrl] = useState<string>('');
  const [planetResource, setPlanetResource] = useState<PlanetResourceData>();
  const [planetTraits, setPlanetTraits] = useState<string[]>();
  const [planetHazards, setPlanetHazards] = useState<string[]>();
  const [planetArtifacts, setPlanetArtifacts] = useState<string[]>();
  const [contractScanUrl, setContractScanUrl] = useState<string>('');
  const [planetOpenSeaLink, setPlanetOpenSeaLink] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [planetRewardStatus, setPlanetRewardStatus] = useState('');
  const location = useLocation();
  const { mission, missionLoadError } = useFetchMissions(location.state?.loadedMission);
  const typedMission = mission !== null ? (mission as Mission) : null;

  const updatePlanetRewardStatus = useCallback(
    async (chainId: string, contractAddress: string, tokenId: string) => {
        try {
          const planetsRewardStatus: MissionRewardsStatus[] = await getPlanetRewardStatusByChainIdContractAddressAndTokenId(
            chainId,
            contractAddress,
            tokenId,
          );
          const missionId = typedMission?.id;
          const planetRewardStatusForMission = planetsRewardStatus.find(planetReward => planetReward.missionId === missionId);
          const status = planetRewardStatusForMission?.eligible ? ClaimStatus.UNCLAIMED : ClaimStatus.CLAIMED;
          setPlanetRewardStatus(status);
        } catch (e) {
          setError(true);
        }
    },
    [typedMission],
  );
    
  useEffect(() => {
    const fetchData = async () => {
      // Handle the case for path="/planets/:guid"
      if (guid && isValidGuid(guid)) {
        try {
          const response = await getPlanetByGuid(guid);
          await updatePlanetRewardStatus(response.chainId, response.contractAddress, response.tokenId);
          setPlanet(response);
          setPlanetImageUrl(response.fullImageUrl);
        } catch (e) {
          setError(true);
        }
      }

      // Handle the case for path="/planets/:chainId/:contractAddress/:tokenId"
      if (chainId && contractAddress && tokenId) {
        // Handle the 'chainId', 'contractAddress', and 'tokenId' parameters here
        try {
            const getPlanetPromise = getPlanetByChainIdContractAddressAndTokenId(chainId, contractAddress, tokenId);
            const updatePromise = updatePlanetRewardStatus(chainId, contractAddress, tokenId);
            const [response] = await Promise.all([getPlanetPromise, updatePromise]);
          const planetImageUrl = `${
            process.env.REACT_APP_PLANET_SERVICE_METADATA_BASE_URL
          }/${chainId}/${contractAddress.toLowerCase()}/${tokenId}.2048.png`;
          setPlanet(response);
          setPlanetImageUrl(planetImageUrl);
        } catch (e) {
          setError(true);
        }
      }

      // Handle cases when no parameters match (optional)
      if (!guid && (!chainId || !contractAddress || !tokenId || !planet)) {
        setError(true);
      }
      if (planet) {
        createResourceState(planet);
        createTraitsData(planet);
        createHazardsData(planet);
        createArtifactsData(planet);
        createOpenSeaLink(planet);
        setContractScanUrl(`${process.env.REACT_APP_CONTRACT_ADDRESS_SCAN_BASEURL}/${planet.contractAddress}`);
      }
      setIsLoading(false);
      setError(false);
    };

    if (isLoading) {
      fetchData();
    }
  }, [planet, guid, chainId, contractAddress, tokenId, isLoading, updatePlanetRewardStatus]);

  const createResourceState = (planet: PlanetWithImageUrls) => {
    setPlanetResource({
      resourceValue1: planet.resourceValue1,
      resourceValue2: planet.resourceValue2,
      resourceValue3: planet.resourceValue3,
      resourceValue4: planet.resourceValue4,
      resourceValue5: planet.resourceValue5,
      resourceValue6: planet.resourceValue6,
      resourceValue7: planet.resourceValue7,
      resourceValue8: planet.resourceValue8,
      resourceValue9: planet.resourceValue9,
    });
  };

  const createTraitsData = (planet: PlanetWithImageUrls) => {
    setPlanetTraits(
      [
        `ECOSYSTEM : ${BiomeString[planet.biome].toUpperCase() || 'NIL'}`,
        `SURFACE ROUGHNESS : ${SurfaceRoughnessStringLabel.getStringLabel(planet.surfaceRoughness).toUpperCase() || 'NIL'}`,
        `BEDROCK FIRMNESS : ${BedrockFirmnessStringLabel.getStringLabel(planet.bedrockFirmness).toUpperCase() || 'NIL'}`,
        `ABUNDANCE : ${ResourceAbundanceStringLabel.getStringLabel(planet.abundance).toUpperCase() || 'NIL'}`,
        `NEBULA : ${NebulaString[planet.nebula].toUpperCase() || 'NIL'}`,
        `DISTANCE FROM SUN : ${DistanceFromSunString[planet.distanceFromSun].toUpperCase() || 'NIL'}`,
        `MOONS : ${planet.moons ? MoonsString[planet.moons].toUpperCase() : 'NIL'}`,
        `RING SYSTEM : ${planet.ringSystem ? RingSystemString[planet.ringSystem].toUpperCase() : 'NIL'}`,
      ].filter(trait => trait.split(' : ')[1] !== 'NIL'),
    );
  };

  const createHazardsData = (planet: PlanetWithImageUrls) => {
    setPlanetHazards(
      [
        `OVERALL HOSTILITY : ${planet.overallHostility ? OverallHostilityStringLabel.getStringLabel(planet.overallHostility).toUpperCase() : 'NIL'}`,
        `STRATOSPHERE TURBULENCE : ${
          planet.stratosphereTurbulence ? StratosphereTurbulenceStringLabel.getStringLabel(planet.stratosphereTurbulence).toUpperCase() : 'NIL'
        }`,
        `ELECTROMAGNETIC INTERFERENCE : ${
          planet.electromagneticInterference
            ? ElectromagneticInterferenceStringLabel.getStringLabel(planet.electromagneticInterference).toUpperCase()
            : 'NIL'
        }`,
        `VISIBILITY : ${planet.visibility ? VisibilityStringLabel.getStringLabel(planet.visibility).toUpperCase() : 'NIL'}`,
        `HOSTILITY TRAIT : ${
          planet.unstableTopography
            ? HostilityTraitStringLabel[HostilityTrait.UNSTABLE_TOPOGRAPHY]
            : planet.acidPools
            ? HostilityTraitStringLabel[HostilityTrait.ACID_POOLS]
            : planet.moltenPools
            ? HostilityTraitStringLabel[HostilityTrait.MOLTEN_POOLS]
            : planet.iceSheetFissure
            ? HostilityTraitStringLabel[HostilityTrait.ICE_SHEET_FISSURE]
            : 'NIL'
        }`,
      ].filter(trait => trait.split(' : ')[1] !== 'NIL'),
    );
  };
  const createArtifactsData = (planet: PlanetWithImageUrls) => {
    setPlanetArtifacts(
      [
        `ANCIENT MAP (${planet.ancientMapValue || 'NIL'})`,
        `UNDERGROUND TUNNEL (${planet.undergroundTunnelValue || 'NIL'})`,
        `NATIVE CAVE PAINTING (${planet.nativeCavePaintingValue || 'NIL'})`,
        `MYSTERIOUS OBELISK (${planet.mysteriousObeliskValue || 'NIL'})`,
      ].filter(trait => trait.split(' (')[1] !== 'NIL'),
    );
  };
  const createOpenSeaLink = (planet: PlanetWithImageUrls) => {
    const url = `${process.env.REACT_APP_ASSET_TOKEN_OPENSEA_BASEURL}/${planet.tokenId}`;
    setPlanetOpenSeaLink(url);
  };

  return (
    <div>
      <HeaderComponent noBackground={true} />
      {isLoading ? (
        <LoadingSpinner />
      ) : planet ? (
        <div className="planet-detail">
          {error && <div className="planet-detail__error">No planet found</div>}
          {planet && (
            <h1 className="planet-detail__planet-id-type">
              ID - {planet.name} - {sizeMapStringLabel[planet.size]}
            </h1>
          )}
          <div className="planet-image-container">
            {planet && (
              <ImageFallback url={planetImageUrl} altImageText={planet.name ?? ''} fallback={planet_terraforming ?? ''} className="planet-image" />
            )}
            <ShareButton url={window.location.href} />
          </div>
          {!missionLoadError && (
            <p>
              TCQ: {typedMission?.name.toUpperCase()} {typedMission?.id} REWARDS STATUS: <span className="reward-status">{planetRewardStatus}</span>
            </p>
          )}
          <div className="planet-info">
            <PGAccordion classNameExtension={'pg-planet-accordion'}>
              <Accordion.Item eventKey="0">
                <div className="accordion-list">
                  <Accordion.Header>TRAITS</Accordion.Header>
                  <Accordion.Body>
                    <ul className="accordion-list__items">
                      {planetTraits &&
                        planetTraits.map((trait, index) => (
                          <li key={index}>
                            <span className="title">{trait.split(' : ')[0]}</span> : {trait.split(' : ')[1]}
                          </li>
                        ))}
                    </ul>
                  </Accordion.Body>
                </div>
              </Accordion.Item>
            </PGAccordion>
            <PGAccordion classNameExtension={'pg-planet-accordion'}>
              <Accordion.Item eventKey="1">
                <div className="accordion-list">
                  <Accordion.Header>RESOURCE STATES</Accordion.Header>
                  <Accordion.Body>{planetResource && <PlanetResourceList resourceData={planetResource} />}</Accordion.Body>
                </div>
              </Accordion.Item>
            </PGAccordion>
            <PGAccordion classNameExtension={'pg-planet-accordion'}>
              <Accordion.Item eventKey="2">
                <div className="accordion-list">
                  <Accordion.Header>ARTIFACTS</Accordion.Header>
                  <Accordion.Body>
                    <ul className="accordion-list__items">
                      {Array.isArray(planetArtifacts) && planetArtifacts.map((item, index) => <li key={index}>{item}</li>)}
                    </ul>
                  </Accordion.Body>
                </div>
              </Accordion.Item>
            </PGAccordion>

            <PGAccordion classNameExtension={'pg-planet-accordion'}>
              <Accordion.Item eventKey="3">
                <div className="accordion-list">
                  <Accordion.Header>HAZARDS</Accordion.Header>
                  <Accordion.Body>
                    <ul className="accordion-list__items">
                      {planetHazards &&
                        planetHazards.map((trait, index) => (
                          <li key={index}>
                            <span className="title">{trait.split(' : ')[0]}</span> : {trait.split(' : ')[1]}
                          </li>
                        ))}
                    </ul>
                  </Accordion.Body>
                </div>
              </Accordion.Item>
            </PGAccordion>

            <PGAccordion classNameExtension={'pg-planet-accordion'}>
              <Accordion.Item eventKey="4">
                <div className="accordion-list">
                  <Accordion.Header>DETAILS</Accordion.Header>
                  <Accordion.Body>
                    <ul className="accordion-list__items">
                      <li>CHAIN - POLYGON</li>
                      <li>
                        CONTRACT ADDRESS -
                        <Link className="accordion-list__link" to={contractScanUrl} target={'_blank'}>
                          {planet?.contractAddress}
                        </Link>
                      </li>

                      <li>TOKEN ID - {planet?.tokenId}</li>
                    </ul>
                  </Accordion.Body>
                </div>
              </Accordion.Item>
            </PGAccordion>
          </div>
          <Link className="planet-info__link" to={planetOpenSeaLink} target={'_blank'}>
            GO TO OPENSEA
          </Link>
        </div>
      ) : (
        <AssetNotFound
          mainText={ASSET_NOT_FOUND_MESSAGES.PLANET_NOT_FOUND}
          imageURL={planet_not_found}
          fallbackImage={planet_not_found}
          altImageText={'Orange rocky planet'}
        />
      )}
      <Footer>
        <FighterLady />
      </Footer>
    </div>
  );
};

export default PlanetDetails;
