import { createContext, useEffect, useState } from 'react';
import { useProvider } from '../hooks/useProvider';
import { getBlockchainContract } from '../utils/providerObject';
import afrNFTABI from '../assets/abi/AFRNFT.json';
import { ethers } from 'ethers';
import { getAfrKycCheck } from '../utils/ApiCalls';
import { walletContext } from '../utils/WalletContext';
import { useIsCorrectChain } from '../hooks/useIsCorrectChain';

/**
 * The Afr (Astrafite Rush) data is used on 2 separate routes /astrafite-rush-event and /rewards-claim.
 *
 * /rewards-claim page is only accessible if the user has reward claim data.
 *
 * This ensures that data is readily available when going from the /astrafite-rush-event page to the /rewards-claim page.
 */
export const AfrContext = createContext();

function AfrProvider({ children }) {
  const provider = useProvider();
  const isCorrectChain = useIsCorrectChain();

  const [afrContract, setAfrContract] = useState();
  const [hasClaimData, setHasClaimData] = useState(false);
  const [isClaimDataLoading, setIsClaimDataLoading] = useState(true);
  const [claimDates, setClaimDates] = useState();
  const [currentClaimIndex, setCurrentClaimIndex] = useState();
  const [nextClaimDisplayDate, setNextClaimDisplayDate] = useState();
  const [nftCollection, setNftCollection] = useState();
  const [primaryNftIndex, setPrimaryNftIndex] = useState(0);
  const [totalAvailableAmount, setTotalAvailableAmount] = useState();
  const [totalRemainingAmount, setTotalRemainingAmount] = useState();
  const [claimSignature, setClaimSignature] = useState();
  const [kycStatus, setKycStatus] = useState();

  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  useEffect(() => {
    if (!provider || !isCorrectChain) {
      return;
    }

    if (!hasClaimData) {
      setIsClaimDataLoading(true);
      const retrieveClaimData = async () => {
        const walletAddress = walletContext.currentWallet;

        const afrNFTContract = getBlockchainContract(process.env.REACT_APP_AFR_NFT_ADDRESS, afrNFTABI, provider);

        setAfrContract(afrNFTContract);

        const claimStartDateUnix = (await afrNFTContract.claimStartDate()).toNumber();

        let claimStartDate = new Date(claimStartDateUnix * 1000);

        if (claimStartDateUnix === 0) {
          claimStartDate = new Date(Date.UTC(2023, 0, 4, 23, 0));
        }

        const afrNftPayoutDayGaps = [0, 30, 60, 90, 120, 150, 180, 210, 240, 270]; //Default in contract is every 30 days
        const afrNftPayoutPercentages = [10, 10, 10, 10, 10, 10, 10, 10, 10, 10]; //Default in contract is 10% per payment
        const afrNftClaimDates = [];
        const currentDate = Date.now();
        let afrNftCurrentClaimIndex = 0; //Calculate based off dates

        //Pull payout day gaps and percentages from blockchain (optional only if we change them)
        //Skipping this as its coded to defaults above, but uncomment this if we want it dynamic from the contract (a bit slower every page load)
        // for (let i = 0; i < 10; i++) {
        //   const days = (await afrNFTContract.payoutDays(i)).toNumber();
        //   afrNftPayoutDayGaps.push(days);

        //   const percentage = (await afrNFTContract.payoutPercentages(i));
        //   afrNftPayoutPercentages.push(Number(percentage));
        // }

        for (let i = 0; i < 10; i++) {
          let thisClaimDate = addDays(claimStartDate, afrNftPayoutDayGaps[i]);

          afrNftClaimDates.push(thisClaimDate);

          if (currentDate > thisClaimDate.getTime()) {
            afrNftCurrentClaimIndex = i + 1;
          }
        }

        setCurrentClaimIndex(afrNftCurrentClaimIndex);
        setClaimDates(afrNftClaimDates);

        if (afrNftCurrentClaimIndex < 10) {
          setNextClaimDisplayDate(afrNftClaimDates[afrNftCurrentClaimIndex].toLocaleDateString());
        } else {
          //Set it to final claim date
          setNextClaimDisplayDate(afrNftClaimDates[9].toLocaleDateString());
        }
        //Get my first tokenID as primary
        const afrNftBalance = (await afrNFTContract.balanceOf(walletAddress)).toNumber();

        const afrNftCollection = [];

        let afrNftTotalAvailableAmount = ethers.BigNumber.from(0);
        let afrNftTotalRemainingAmount = ethers.BigNumber.from(0);

        if (afrNftBalance > 0) {
          for (let i = 0; i < afrNftBalance; i++) {
            const afrNftTokenId = (await afrNFTContract.tokenOfOwnerByIndex(walletAddress, i)).toNumber();

            let afrNftAmountToClaim = ethers.BigNumber.from(0);
            let afrNftAmountRemainingToBeClaimed = ethers.BigNumber.from(0);

            const nextIndexToClaim = Number(await afrNFTContract.TokenIdToIndexClaimed(afrNftTokenId));

            const totalRewards = await afrNFTContract.TokenIdToRewards(afrNftTokenId); //BigNumber
            // let claimedRewards = (await afrNFTContract.TokenIdToClaimedRewards(afrNftTokenId)); //BigNumber

            for (let j = nextIndexToClaim; j < afrNftCurrentClaimIndex && j < 10; j++) {
              const amountForThisClaim = totalRewards.div(100).mul(afrNftPayoutPercentages[j]);
              afrNftAmountToClaim = afrNftAmountToClaim.add(amountForThisClaim);
            }

            for (let j = nextIndexToClaim; j < 10; j++) {
              const amountForThisClaim = totalRewards.div(100).mul(afrNftPayoutPercentages[j]);
              afrNftAmountRemainingToBeClaimed = afrNftAmountRemainingToBeClaimed.add(amountForThisClaim);
            }

            afrNftTotalAvailableAmount = afrNftTotalAvailableAmount.add(afrNftAmountToClaim);
            afrNftTotalRemainingAmount = afrNftTotalRemainingAmount.add(afrNftAmountRemainingToBeClaimed);

            const isClaimedToDate = totalRewards.gt(0) && afrNftAmountToClaim.isZero();

            const afrNftTokenData = {
              index: i,
              tokenId: afrNftTokenId,
              amountToClaim: afrNftAmountToClaim,
              amountToClaimDisplay: ethers.utils.commify(ethers.utils.formatUnits(afrNftAmountToClaim)),
              amountRemainingToBeClaimed: afrNftAmountRemainingToBeClaimed,
              amountRemainingToBeClaimedDisplay: ethers.utils.commify(ethers.utils.formatUnits(afrNftAmountRemainingToBeClaimed)),
              nextIndexToClaim: nextIndexToClaim,
              totalRewards: totalRewards,
              isClaimed: isClaimedToDate,
            };

            afrNftCollection.push(afrNftTokenData);
          }
        }

        setTotalAvailableAmount(afrNftTotalAvailableAmount);
        setTotalRemainingAmount(afrNftTotalRemainingAmount);

        setNftCollection(afrNftCollection);

        const response = await getAfrKycCheck();

        setClaimSignature(response.signature);
        setKycStatus(response.kycStatus);

        setIsClaimDataLoading(false);
        if (afrNftCollection.length > 0) setHasClaimData(true);
      };

      retrieveClaimData().then();
    }
  }, [hasClaimData, provider, isCorrectChain]);

  return (
    <AfrContext.Provider
      value={{
        afrContract,
        primaryNftIndex,
        setPrimaryNftIndex,
        nftCollection,
        hasClaimData,
        claimDates,
        currentClaimIndex,
        nextClaimDisplayDate,
        totalAvailableAmount,
        totalRemainingAmount,
        kycStatus,
        claimSignature,
        isClaimDataLoading,
      }}
    >
      {children}
    </AfrContext.Provider>
  );
}

export { AfrProvider };
