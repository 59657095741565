import { PGAccordion } from '../../../Common/Accordion/PGAccordion';
import PageHeader from '../../../PageHeader';
import { Accordion, Container } from 'react-bootstrap';

export const AvatarDashboardInfo = () => (
  <>
    <PageHeader headerText="INFO" headerTextSecondLine={undefined} classname="planet-dashboard-info" />
    <Container>
      <PGAccordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>BOUND AVATAR</Accordion.Header>
          <Accordion.Body>
            <p>
              Avatars can be Bound and Unbound by first selecting an Avatar and then selecting the subsequent ‘Bind’ or ‘Unbind’ option. An Avatar is
              Bound or Unbound on the blockchain, and the NFT cannot be transferred or moved from your wallet while Bound.
            </p>
            <p>
              An Avatar is game-locked when it is blockchain Bound, AND actively being used by Phantom Galaxies or an affiliated game. For example,
              when an Avatar is Bound to an account which is actively playing Phantom Galaxies, it is considered ‘game-locked’ for the duration of the
              gameplay session.
            </p>
            <p>
              Bound Avatars that are game-locked cannot be Unbound or transferred until released from the game lock by ending the current gameplay
              session.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>OTHER QUESTIONS ABOUT BINDING</Accordion.Header>
          <Accordion.Body>
            <dl>
              <dt>Q: Why can’t I Unbind my Avatar?</dt>
              <dd>
                A: If your Avatar is Bound to an account which has an active game session of Phantom Galaxies, it cannot be Unbound until it is no
                longer game-locked. In order to remove the game lock, you will need to end the current game session and try again.
              </dd>
              <dt>Q: Why do I need to bind my Avatars?</dt>
              <dd>
                A: Avatars must be Bound in order to be accessible in the Phantom Galaxies game. If not Bound, Avatars will not be displayed as
                playable in the character selection screen.
              </dd>
              <dt>Q: Why can’t I see my Avatar in game</dt>
              <dd>
                A: If you are in a current play session when you Bind your Avatar, you will need to exit to the main menu and ‘Enter World’ again for
                it to become available.
              </dd>
            </dl>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>HOW DO I UNBIND?</Accordion.Header>
          <Accordion.Body>
            <p>If your Avatar is not game-locked, it can be Unbound simply by selecting the Avatar in question and clicking the ‘Unbind’ button.</p>
          </Accordion.Body>
        </Accordion.Item>
      </PGAccordion>
    </Container>
  </>
);
