import {PropsWithoutRef} from "react";
import "./PageHeader.scss";
import {kyc_header_boarder} from "../assets";
import {mini_screen_title_background, mobile_title_background_img, tablet_title_background} from "../assets/images/NFT";

type PageHeaderProps = {
  headerText: string;
  headerTextSecondLine?: string;
  classname?: string;
}

export default function PageHeader({headerText, headerTextSecondLine, classname}: PropsWithoutRef<PageHeaderProps>) {
  return (
    <div className={"page-header"}>

      <img alt={""} className={'psg-border-img-mobile'}
           src={mobile_title_background_img}/>

        <img alt={""} className={'psg-border-img-tablet'}
             src={tablet_title_background}/>

      <img alt={""} className={'psg-border-img-mini-screen'}
           src={mini_screen_title_background}/>

        <img alt={""} className={'psg-border-img-pc'}
             src={kyc_header_boarder}/>


      <div className={classname ? `${classname} page-mobile-header-text-no-margin` : `${classname} page-mobile-header-text`}>
        {headerText}<br/>
        {headerTextSecondLine}
      </div>


    </div>
  )
}
