export const CHAINS = {
  // This Polygon chain data has been copied from https://chainid.network/chains.json
  polygon: {
    name: "Polygon Mainnet",
    chain: "Polygon",
    rpc: [
      "https://polygon-rpc.com/",
      "https://rpc-mainnet.matic.network",
      "https://matic-mainnet.chainstacklabs.com",
      "https://rpc-mainnet.maticvigil.com",
      "https://rpc-mainnet.matic.quiknode.pro",
      "https://matic-mainnet-full-rpc.bwarelabs.com"
    ],
    faucets: [],
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18
    },
    infoURL: "https://polygon.technology/",
    shortName: "MATIC",
    chainId: 137,
    networkId: 137,
    slip44: 966,
    explorers: [
      {
        name: "polygonscan",
        url: "https://polygonscan.com",
        standard: "EIP3091"
      }
    ]
  },
  rinkeby: {
    name: "Rinkeby",
    title: "Ethereum Testnet Rinkeby",
    chain: "ETH",
    network: "testnet",
    rpc: [
      `https://rinkeby.infura.io/v3/${process.env.REACT_APP_INFURAAPIKEY}`,
      `wss://rinkeby.infura.io/ws/v3/${process.env.REACT_APP_INFURAAPIKEY}`,
    ],
    faucets: [
      "http://fauceth.komputing.org?chain=4&address=",
      "https://faucet.rinkeby.io",
    ],
    nativeCurrency: {
      name: "Rinkeby Ether",
      symbol: "RIN",
      decimals: 18,
    },
    infoURL: "https://www.rinkeby.io",
    shortName: "rin",
    chainId: 4,
    networkId: 4,
    ens: {
      registry: "0xe7410170f87102df0055eb195163a03b7f2bff4a",
    },
    explorers: [
      {
        name: "etherscan-rinkeby",
        url: "https://rinkeby.etherscan.io",
        standard: "EIP3091",
      },
    ],
  },
  mumbai: {
    name: "Mumbai",
    title: "Polygon Testnet Mumbai",
    chain: "Polygon",
    rpc: [
      "https://matic-mumbai.chainstacklabs.com",
      "https://rpc-mumbai.maticvigil.com",
      "https://matic-testnet-archive-rpc.bwarelabs.com"
    ],
    faucets: [
      "https://faucet.polygon.technology/"
    ],
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18
    },
    infoURL: "https://polygon.technology/",
    shortName: "maticmum",
    chainId: 80001,
    networkId: 80001,
    explorers: [
      {
        name: "polygonscan",
        url: "https://mumbai.polygonscan.com",
        standard: "EIP3091"
      }
    ]
  },
  fuji: {
    name: "Fuji",
    title: "Avalanche Fuji Testnet",
    chain: "Avalanche",
    rpc: [
      "https://api.avax-test.network/ext/bc/C/rpc",
      "https://avalanchetestapi.terminet.io/ext/bc/C/rpc",
      "https://ava-testnet.public.blastapi.io/ext/bc/C/rpc"
    ],
    faucets: [
      "https://faucet.avax.network/"
    ],
    nativeCurrency: {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18
    },
    infoURL: "https://www.avax.network/",
    shortName: "fuji",
    chainId: 43113,
    networkId: 43113,
    explorers: [
      {
        name: "snowtrace",
        url: "https://testnet.snowtrace.io/",
        standard: "EIP3091"
      }
    ]
  },
  goerli: {
    name: "Görli",
    title: "Ethereum Testnet Görli",
    chain: "ETH",
    rpc: [
      `https://goerli.infura.io/v3/${process.env.REACT_APP_INFURAAPIKEY}`,
      `wss://goerli.infura.io/v3/${process.env.REACT_APP_INFURAAPIKEY}`,
      "https://rpc.goerli.mudit.blog/"
    ],
    faucets: [
      "https://faucet.goerli.mudit.blog"
    ],
    nativeCurrency: {
      name: "Görli Ether",
      symbol: "ETH",
      decimals: 18
    },
    infoURL: "https://goerli.net/#about",
    shortName: "gor",
    chainId: 5,
    networkId: 5,
    ens: {
      registry: "0x112234455c3a32fd11230c42e7bccd4a84e02010"
    },
    explorers: [
      {
        name: "etherscan-goerli",
        url: "https://goerli.etherscan.io",
        standard: "EIP3091"
      }
    ]
  },
}


export const toHex = (num) => {
  return '0x' + num.toString(16);
};
