import './Lock&Load.scss'
import {asteroid_onboarding, pirateship_onboarding} from "../../../assets/images/OnboardingModal";
import CarouselModalButton from "../CarouselModalButton/CarouselModalButton";
import {carouselText} from "../../../utils/GeneralUtils";

export default function LockLoad() {
  return <>
    <div className={'lock-load-maine-container'}>
      <p className={'lock-load-title'}>LOCK & LOAD</p>
      <div className={'lock-load-content'}>
        <div className={'mine-content'}>
          <img alt={""} src={asteroid_onboarding} className={'asteroid-onboarding'}/>
          <div className={'lock-load-button-container'}>
            <CarouselModalButton text={'MINE'}/>
          </div>
        </div>
        <div className={'destroy-content'}>
          <img alt={""} src={pirateship_onboarding} className={'pirateship-onboarding'}/>
          <div className={'lock-load-button-container'}>
            <CarouselModalButton text={'DESTROY'}/>
          </div>
        </div>
      </div>


      <div className={'lock-load-description'}>
        {carouselText.paragraph2}
      </div>
    </div>
  </>
}
