import './GenerativeStarfighterMintingContent.scss';
import { useEffect, useRef, useState } from 'react';
import GenerativeStarfighterMintpassGrid from '../GenerativeStarfighterMintpassGrid/GenerativeStarfighterMintpassGrid';
import GenerativeStarfighterCatalystGrid from '../GenerativeStarfighterCatalystGrid/GenerativeStarfighterCatalystGrid';
import GenerativeTraitGrid from '../GenerativeTraitGrid/GenerativeTraitGrid';
import {
  buyOnOpenSea,
  CELESTIAL_BODIES_TRAIT_TITLE,
  BUILD_TRAIT_TITLE,
  EMPTY_STRING,
  MAXIMUM_PLANET_MINTS,
  NO_TRAIT,
} from '../../../utils/GeneralUtils';
import { add_catalyst_icon, random_catalyst } from '../../../assets/images/GenerativePlanetMinting';
import GenerativeStarfighterMintPopupWithoutCatalyst from '../GenerativeStarfighterMintPopupWithoutCatalyst/GenerativeStarfighterMintPopupWithoutCatalyst';
import GenerativeStarfighterMintPopupWithCatalyst from '../GenerativeStarfighterMintPopupWithCatalyst/GenerativeStarfighterMintPopupWithCatalyst';
import BaseButton from '../../BaseButton/BaseButton';
import GenerativeStarfighterMintingInfo from '../GenerativeStarfighterMintingInfo/GenerativeStarfighterMintingInfo';
import StarfighterTransferPopup from '../StarfighterTransferPopup/StarfighterTransferPopup';
import GenerativeStarfighterMintCongratulationsPopup from '../GenerativeStarfighterMintCongratulationsPopup/GenerativeStarfighterMintCongratulationsPopup';
import { getStarfighterCatalystBalances, getStarfighterCatalysts, getStarfighterMintpassBalances } from '../../../utils/ApiCalls';
import { walletContext } from '../../../utils/WalletContext';
import {
  pristine_1st_class_350_token_id_1,
  pristine_2nd_class_350_token_id_11,
  pristine_3rd_class_350_token_id_21,
  standard_issue_350_token_id_100,
  starfighter_catalyst_class_assault_1001,
  starfighter_catalyst_class_breacher_1002,
  starfighter_catalyst_class_buster_1003,
  starfighter_catalyst_class_lancer_1004,
  veteran_1st_class_350_token_id_2,
  veteran_2nd_class_350_token_id_12,
  veteran_3rd_class_350_token_id_22,
} from '../../../assets/images/GenerativeStarfighterMinting';
import { useProvider } from '../../../hooks/useProvider';

export const STARFIGHTER_MINTPASSES = [
  {
    tokenId: 1,
    name: 'Pristine Fleet Mintpass: First Class',
    image: pristine_1st_class_350_token_id_1,
    acceptsCelestialBodies: false,
    openseaUrl: buyOnOpenSea.pristineMintPassFirstClass,
  },
  {
    tokenId: 2,
    name: 'Veteran Fleet Mintpass: First Class',
    image: veteran_1st_class_350_token_id_2,
    acceptsCelestialBodies: false,
    openseaUrl: buyOnOpenSea.veteranMintPassFirstClass,
  },
  {
    tokenId: 11,
    name: 'Pristine Fleet Mintpass: Second Class',
    image: pristine_2nd_class_350_token_id_11,
    acceptsCelestialBodies: false,
    openseaUrl: buyOnOpenSea.pristineMintPassSecondClass,
  },
  {
    tokenId: 12,
    name: 'Veteran Fleet Mintpass: Second Class',
    image: veteran_2nd_class_350_token_id_12,
    acceptsCelestialBodies: false,
    openseaUrl: buyOnOpenSea.veteranMintPassSecondClass,
  },
  {
    tokenId: 21,
    name: 'Pristine Fleet Mintpass: Third Class',
    image: pristine_3rd_class_350_token_id_21,
    acceptsCelestialBodies: false,
    openseaUrl: buyOnOpenSea.pristineMintPassThirdClass,
  },
  {
    tokenId: 22,
    name: 'Veteran Fleet Mintpass: Third Class',
    image: veteran_3rd_class_350_token_id_22,
    acceptsCelestialBodies: false,
    openseaUrl: buyOnOpenSea.veteranMintPassThirdClass,
  },
  {
    tokenId: 100,
    name: 'Standard Issue Fleet Mintpass',
    image: standard_issue_350_token_id_100,
    acceptsCelestialBodies: false,
    openseaUrl: buyOnOpenSea.standardIssueFleetMintPass,
  },
];

export const STARFIGHTER_CATALYST_IMAGES = [
  {
    tokenId: 1001,
    image: starfighter_catalyst_class_assault_1001,
  },
  {
    tokenId: 1002,
    image: starfighter_catalyst_class_breacher_1002,
  },
  {
    tokenId: 1003,
    image: starfighter_catalyst_class_buster_1003,
  },
  {
    tokenId: 1004,
    image: starfighter_catalyst_class_lancer_1004,
  },
];

export const MINT_TYPE = {
  batch: 'batch',
  single: 'single',
};

export default function GenerativeStarfighterMintingContent() {
  const ref = useRef(null);
  const provider = useProvider();

  const defaultStarfighterMintpasses = STARFIGHTER_MINTPASSES.map(sfMintPass => {
    return {
      tokenId: sfMintPass.tokenId,
      title: sfMintPass.name,
      image: sfMintPass.image,
      stock: 0,
      count: 0,
      acceptsCelestialBodies: sfMintPass.acceptsCelestialBodies,
      openseaUrl: sfMintPass.openseaUrl,
    };
  });

  const [transferPopup, setTransferPopup] = useState(false);
  const [congratulationsPopup, setCongratulationsPopup] = useState(false);
  const [totalStarfighterCount, setTotalStarfighterCount] = useState(0);
  const [traits, setTraits] = useState([]);
  const [catalysts, setCatalysts] = useState([]);
  const [selectedStarfighter, setSelectedStarfighter] = useState(EMPTY_STRING);
  const [showPopupWithoutCatalyst, setShowPopupWithoutCatalyst] = useState(false);
  const [showPopupWithCatalyst, setShowPopupWithCatalyst] = useState(false);
  const [isBatch, setIsBatch] = useState(true);
  const [starfighterMintpasses, setStarfighterMintpasses] = useState(defaultStarfighterMintpasses);
  const [batchPopupValues, setBatchPopupValues] = useState(defaultStarfighterMintpasses);
  const [catalystPopupValues, setCatalystPopupValues] = useState({
    selectedStarfighter: {
      title: EMPTY_STRING,
      image: EMPTY_STRING,
    },
    build: {
      title: EMPTY_STRING,
      image: random_catalyst,
    },
  });
  const [mintSelection, setMintSelection] = useState({});

  useEffect(() => {
    const formatCatalystsWithBalances = (catalystsFromAPI, catalystBalances) => {
      const formattedCatalysts = catalystsFromAPI.map(catalystGroup => {
        const formattedTraits = catalystGroup.types.map(type => {
          let tokenBalance = catalystBalances.tokens.find(token => token.tokenId === type.tokenId);

          let traitImage = STARFIGHTER_CATALYST_IMAGES.find(trait => trait.tokenId === type.tokenId).image;

          // Format each trait/catalyst type with the following fields
          return {
            tokenId: type.tokenId,
            title: type.name,
            selected: false,
            image: traitImage,
            stock: tokenBalance.balance,
            openseaUrl: type.openseaUrl,
          };
        });

        // Format each catalyst category with the following fields
        return {
          category: catalystGroup.category,
          title: catalystGroup.displayName,
          image: add_catalyst_icon,
          selectedImage: add_catalyst_icon,
          selected: false,
          traitSelected: false,
          traits: formattedTraits,
        };
      });
      return formattedCatalysts;
    };

    const fetchStarfighterCatalysts = async () => {
      const catalysts = await getStarfighterCatalysts();
      if (walletContext.signed && !congratulationsPopup) {
        let balances;
        balances = await getStarfighterCatalystBalances(walletContext.currentWallet);
        setCatalysts(formatCatalystsWithBalances(catalysts, balances));
        setSelectedStarfighter(EMPTY_STRING);
        setTraits([]);
        setTotalStarfighterCount(0);
      }
    };

    fetchStarfighterCatalysts();
  }, [congratulationsPopup]);

  // prevents page from scrolling while modal is open
  useEffect(() => {
    if (showPopupWithCatalyst || showPopupWithoutCatalyst) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [showPopupWithCatalyst, showPopupWithoutCatalyst]);

  const resetCatalystSelectibility = () => {
    let updatedCatalysts = catalysts.map(catalyst => {
      catalyst.selectableCatalyst = true;
      return catalyst;
    });
    setCatalysts(updatedCatalysts);
  };

  const disableCatalystSelectibility = title => {
    let updatedCatalysts = catalysts.map(catalyst => {
      catalyst.selectableCatalyst = catalyst.title !== title;
      if (!catalyst.selectableCatalyst) {
        // Deselect any traits for the disabled catalyst
        catalyst.traits.forEach(trait => (trait.selected = false));
        // Reset the catalyst image as the trait is now deselected
        catalyst.image = add_catalyst_icon;
        // If the disabled catalyst was selected, deselect catalyst and clear displayed traits
        if (catalyst.selected) {
          catalyst.selected = false;
          setTraits([]);
        }
      }
      return catalyst;
    });
    setCatalysts(updatedCatalysts);
  };

  useEffect(() => {
    const formatMintPassesWithBalances = balances => {
      return starfighterMintpasses.map(mintpass => {
        let tokenBalance = balances.tokens.find(token => token.tokenId === mintpass.tokenId);

        return {
          ...mintpass,
          count: 0,
          stock: tokenBalance.balance,
        };
      });
    };

    if (walletContext.signed && !congratulationsPopup) {
      getStarfighterMintpassBalances(walletContext.currentWallet).then(balances => {
        const formatted = formatMintPassesWithBalances(balances);
        if (JSON.stringify(formatted) !== JSON.stringify(starfighterMintpasses)) {
          setStarfighterMintpasses(formatted);
          setBatchPopupValues(formatted);
        }
      });
    }
  }, [starfighterMintpasses, congratulationsPopup]);

  const batchPopupValuesSetter = values => {
    setBatchPopupValues(values);
    let totalStarfighters = 0;
    batchPopupValues.forEach(mintpass => (totalStarfighters += mintpass.count));
    setTotalStarfighterCount(totalStarfighters);
  };

  const starfighterClickHandler = tokenId => {
    let selectedMintpass = starfighterMintpasses.find(mintpass => mintpass.tokenId === tokenId);

    setSelectedStarfighter(tokenId);

    setCatalystPopupValues({
      ...catalystPopupValues,
      selectedStarfighter: {
        tokenId: selectedMintpass.tokenId,
        title: selectedMintpass.title,
        image: selectedMintpass.image,
      },
    });

    if (!selectedMintpass.acceptsCelestialBodies) {
      disableCatalystSelectibility(CELESTIAL_BODIES_TRAIT_TITLE);
    } else {
      resetCatalystSelectibility();
    }
  };

  const catalystClickHandler = title => {
    let selectedCatalyst;

    const updatedCatalysts = catalysts.map(catalyst => {
      const isSelected = catalyst.title === title;
      catalyst.selected = isSelected;
      if (isSelected) {
        selectedCatalyst = catalyst;
      }
      return catalyst;
    });

    setCatalysts(updatedCatalysts);
    setTraits(selectedCatalyst.traits);
  };
  const traitClickHandler = title => {
    let updatedTraits = traits.map(trait => {
      if (trait.title === title) {
        if (!trait.selected) {
          trait.selected = true;
        }
      } else {
        trait.selected = false;
      }
      return trait;
    });

    let trait = updatedTraits.find(trait => trait.selected === true);
    let updatedCatalysts = catalysts.map(catalyst => {
      if (catalyst.selected) {
        if (title !== NO_TRAIT) {
          catalyst.image = trait.image;
          catalyst.selectedImage = trait.image;
          catalyst.traitSelected = false;
        } else {
          catalyst.image = add_catalyst_icon;
          catalyst.selectedImage = add_catalyst_icon;
          catalyst.traitSelected = true;
        }
      }
      return catalyst;
    });
    let updatedCatalystPopupValues = catalystPopupValues;
    updatedCatalysts.forEach(catalyst => {
      if (catalyst.selected) {
        switch (catalyst.title) {
          case BUILD_TRAIT_TITLE:
            if (title !== NO_TRAIT) {
              updatedCatalystPopupValues.build.tokenId = trait.tokenId;
              updatedCatalystPopupValues.build.title = trait.title;
              updatedCatalystPopupValues.build.image = trait.image;
            } else {
              updatedCatalystPopupValues.build.title = EMPTY_STRING;
              updatedCatalystPopupValues.build.image = random_catalyst;
            }
            break;
          default:
            break;
        }
      }
    });
    setCatalysts(updatedCatalysts);
    setTraits(updatedTraits);
    setCatalystPopupValues(updatedCatalystPopupValues);
  };

  const switchHandler = () => {
    setIsBatch(!isBatch);
  };

  const showPopupWithoutCatalystHandler = () => {
    setShowPopupWithoutCatalyst(true);
  };

  const handleNoWithoutCatalyst = () => {
    setShowPopupWithoutCatalyst(false);
  };

  const handleYesWithoutCatalyst = () => {
    setShowPopupWithoutCatalyst(false);

    setMintSelection({
      type: MINT_TYPE.batch,
      mintpasses: batchPopupValues,
    });

    transferHandler();
  };

  const showPopupWithCatalystHandler = () => {
    setShowPopupWithCatalyst(true);
  };

  const handleNoWithCatalyst = () => {
    setShowPopupWithCatalyst(false);
  };

  const handleYesWithCatalyst = () => {
    setShowPopupWithCatalyst(false);

    const selectedCatalysts = [];

    catalysts.forEach(category => {
      category.traits.forEach(trait => {
        if (trait.selected) {
          selectedCatalysts.push(trait.tokenId);
        }
      });
    });

    setMintSelection({
      type: MINT_TYPE.single,
      mintpass: catalystPopupValues.selectedStarfighter.tokenId,
      catalysts: selectedCatalysts,
    });

    transferHandler();
  };

  const scrollToInfo = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const transferHandler = () => {
    setTransferPopup(!transferPopup);
  };

  const congratulationsHandler = () => {
    setCongratulationsPopup(!congratulationsPopup);
  };

  return (
    <div className="planet-minting-content">
      <div className="planet-minting-switch">
        <div className={`planet-minting-switch-title ${!isBatch ? 'disabled-title' : ''}`}>BATCH SELECT</div>
        <label className="minting-switch">
          <input type="checkbox" />
          <span className="slider round" onClick={switchHandler} />
        </label>
        <div className={`planet-minting-switch-title ${isBatch ? 'disabled-title' : ''}`}>ADD CATALYST</div>
      </div>
      {isBatch ? (
        <>
          <div>
            <GenerativeStarfighterMintpassGrid
              scrollToInfo={scrollToInfo.bind(this)}
              isCatalyst={false}
              selected={EMPTY_STRING}
              selectHandler={batchPopupValuesSetter.bind(this)}
              mintpasses={batchPopupValues}
              isBatch={isBatch}
            />
            {showPopupWithoutCatalyst ? (
              <GenerativeStarfighterMintPopupWithoutCatalyst
                mintpasses={batchPopupValues}
                handleNo={handleNoWithoutCatalyst}
                handleYes={handleYesWithoutCatalyst}
              />
            ) : (
              EMPTY_STRING
            )}
          </div>
          <div className={'planet-minting-button-container'}>
            <BaseButton
              onClick={showPopupWithoutCatalystHandler}
              disabled={totalStarfighterCount < 1 || totalStarfighterCount > MAXIMUM_PLANET_MINTS}
              filled={true}
              scale={true}
              text={'MINT STARFIGHTERS'}
            />
          </div>
        </>
      ) : (
        <>
          <div>
            <GenerativeStarfighterMintpassGrid
              scrollToInfo={scrollToInfo.bind(this)}
              isCatalyst={true}
              selected={selectedStarfighter}
              selectHandler={starfighterClickHandler.bind(this)}
              mintpasses={batchPopupValues}
              isBatch={isBatch}
            />
            <GenerativeStarfighterCatalystGrid
              disabled={selectedStarfighter === EMPTY_STRING}
              isCatalystFlow={true}
              selectHandler={catalystClickHandler.bind(this)}
              catalysts={catalysts}
            />
            <GenerativeTraitGrid
              catalysts={catalysts}
              selectHandler={traitClickHandler.bind(this)}
              isCatalystFlow={true}
              isDisabled={traits.length === 0}
              traits={traits}
            />
            {showPopupWithCatalyst ? (
              <GenerativeStarfighterMintPopupWithCatalyst
                popupValues={catalystPopupValues}
                handleNo={handleNoWithCatalyst}
                handleYes={handleYesWithCatalyst}
              />
            ) : (
              ''
            )}
          </div>
          <div className={'planet-minting-button-container'}>
            <BaseButton
              onClick={showPopupWithCatalystHandler}
              disabled={selectedStarfighter ? false : true}
              filled={true}
              scale={true}
              text={'MINT STARFIGHTER'}
            />
          </div>
        </>
      )}

      {isBatch && (
        <div className={'planet-minting-total-counter'}>
          {totalStarfighterCount}/{MAXIMUM_PLANET_MINTS}
        </div>
      )}
      <div ref={ref} className={'generative-planet-info'}>
        <GenerativeStarfighterMintingInfo />
      </div>

      {transferPopup && (
        <StarfighterTransferPopup
          show={transferPopup}
          mintSelection={mintSelection}
          catalystCheck={!isBatch ? false : undefined}
          handleClose={transferHandler.bind(this)}
          handleCongratulations={congratulationsHandler}
          provider={provider}
        />
      )}
      {congratulationsPopup && <GenerativeStarfighterMintCongratulationsPopup handleClose={congratulationsHandler.bind(this)} />}
    </div>
  );
}
