import React from 'react';
import { walletContext } from '../../utils/WalletContext';
import GenericButton from '../Button/GenericButton';
import { useNavigate } from 'react-router-dom';

const DEFAULT_TEXT = 'Connect your wallet to Phantom Galaxies';

export default function ConnectWalletSection({ text = DEFAULT_TEXT, origin }) {
  const navigate = useNavigate();

  return !walletContext.signed || !walletContext.currentWallet ? (
    <div className="mx-auto code-panel">
      <p className="connect-wallet-general-text-design">{text}</p>
      <div className="home-connect-wallet-button">
        <div className="home-connect-wallet-button-mobile">
          <GenericButton
            buttonText="CONNECT WALLET"
            handleClick={() => navigate('/connect-wallet', { state: { origin } })}
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
