// This modal will show the steps required to purchase a planet, with a status against each of them
// The steps are:
//    1. Approve the address for consuming tickets (if Presale A or Presale B)
//    2. Increase transfer limit to cover purchase
//    3. Accept T&Cs
//    4. Buy planet(s) - pay currency and ticket(s) for chosen planet(s)

import React, {useContext, useEffect, useState} from "react";
import "./PlanetPurchaseModal.scss";
import {PlanetSaleContext} from "../../Context/PlanetSalePageContext";
import {Container, Modal, Row} from "react-bootstrap";
import {
  currency_bottom_horn,
  currency_header_horn,
} from "../../../assets/images/PlanetSalePagePrivate";

import ModalCloseButton from "../ModalCloseButton/ModalCloseButton";
import ModalButton from "../ModalButton/ModalButton";
import {ethers} from "ethers";
import {
  CONTRACT_APPROVAL_STATUS, nullAddress,
  payableMinterV2Address,
  paymentTokenAddress,
  PGERC1155Address_PlanetSale,
  weiInEthereum
} from "../../../utils/Globals";
import PGERC1155Abi from "../../../assets/abi/PGERC1155.json";
import PayableMinterV2 from "../../../assets/abi/PayableMinterV2.json";
import {
  additionalAllowanceRequired,
  hasApproval,
  increaseERC20Allowance,
  requestApproval
} from "../../../utils/ContractUtils";
import ERC20Abi from "../../../assets/abi/ERC20.json";
import PurchaseStepButton from "./PurchaseStepButton";
import PurchaseStepRow from "./PurchaseStepRow";
import PurchaseModalError from "./PurchaseModalError";
import PlanetPurchaseTCsModal from "./PlanetPurchaseTCs/PlanetPurchaseTCsModal";
import {getBlockchainContract} from "../../../utils/providerObject";

export const planetPurchaseStepStatus = {
  DISABLED: 'DISABLED',
  LOADING: 'LOADING',
  PENDING: 'PENDING',
  INCOMPLETE: 'INCOMPLETE',
  COMPLETE: 'COMPLETE'
}

export default function PlanetPurchaseModal(props) {

  const planetSaleContext = useContext(PlanetSaleContext);
  const {currentWallet} = JSON.parse(sessionStorage.getItem("pg.wallet")) != null ? JSON.parse(sessionStorage.getItem("pg.wallet")) : {};

  const getPaymentTokenAddress = () => {
    return planetSaleContext.selectedCurrency === "USDC" ? paymentTokenAddress.USDCAddress : paymentTokenAddress.USDTAddress;
  }

  const BLOCKCHAIN_ERROR_MAP = [
    { blockchainMessage: 'MetaMask Tx Signature: User denied transaction signature.', displayMessage: `Request rejected. You must approve the blockchain request to continue`},
    { blockchainMessage: 'execution reverted: ERC20: transfer amount exceeds balance', displayMessage: `You have insufficient ${planetSaleContext.selectedCurrency} available to make this purchase.`},
    { blockchainMessage: 'execution reverted: PGERC1155: exceeding supply', displayMessage: 'Unfortunately this planet type has sold out.' }
  ]

  const DEFAULT_ERROR_MESSAGE = 'Sorry, something went wrong. Please try again later.';

  let pgerc1155_PlanetSale = getBlockchainContract(PGERC1155Address_PlanetSale, PGERC1155Abi, props.provider);
  let erc20Contract = getBlockchainContract(getPaymentTokenAddress(), ERC20Abi, props.provider);
  let payableMinterV2Functions = getBlockchainContract(payableMinterV2Address, PayableMinterV2, props.provider);

  const [contractHasApproval, setContractHasApproval] = useState(false);
  const [contractApprovalIsLoading, setContractApprovalIsLoading] = useState(false);
  const [allowanceIncreaseRequired, setAllowanceToAdd] = useState(null);
  const [allowanceIsLoading, setAllowanceIsLoading] = useState(false);
  const [transactionComplete, setTransactionComplete] = useState(null);
  const [requestError, setRequestError] = useState(null);
  const [contractApprovalMandatory, setContractApprovalMandatory] = useState(false);
  const [termsConditionsModalFlag, setTermsConditionsModalFlag] = useState(false);
  const [termsConditionsAccepted, setTermsConditionsAccepted] = useState(false);

  const loadAdditionalAllowanceRequired = async () => {
    setAllowanceIsLoading(true);

    let nftContractPrice = calcTransactionValue();

    let allowanceToAdd = await additionalAllowanceRequired(erc20Contract, currentWallet, nftContractPrice, payableMinterV2Address);
    setAllowanceToAdd(allowanceToAdd);
    setAllowanceIsLoading(false);

    return allowanceToAdd;
  }

  // Set contract approval to required if minting from Presale A or Presale B
  useEffect(() => {
    setContractApprovalMandatory(planetSaleContext.sellType === 'PresaleA' || planetSaleContext.sellType === 'PresaleB');
  }, [planetSaleContext.sellType]);

  // When the modal is opened, refresh the values
  useEffect(() => {
    async function fetchContractApprovalStatus() {
      return await hasApproval(pgerc1155_PlanetSale, currentWallet, payableMinterV2Address);
    }

    if (planetSaleContext.planetPurchaseModalShow) {
      // Reset T&Cs acceptance (must be accepted for each transaction)
      setTermsConditionsAccepted(false);

      // Reset transaction completion state
      setTransactionComplete(null);

      // Reset error message
      setRequestError(null);

      // Load contract approval status, if required
      if (contractApprovalMandatory) {
        setContractApprovalIsLoading(true);
        fetchContractApprovalStatus().then(result => {
          setContractHasApproval(result);
          setContractApprovalIsLoading(false);
        });
      }

      // Load additional allowance required
      loadAdditionalAllowanceRequired();
    }
    // eslint-disable-next-line
  }, [planetSaleContext.planetPurchaseModalShow, currentWallet]);


  // Refresh the page when the purchase successful modal is closed
  // This is so that the number of mintable planets allowed updates to take into account the tickets just used
  useEffect(() => {
    if (!planetSaleContext.planetPurchaseModalShow && transactionComplete) {
      window.location.reload();
    }
  }, [planetSaleContext.planetPurchaseModalShow, transactionComplete])

  const handleRequestError = (e) => {
    let errorMessage;
    let error = BLOCKCHAIN_ERROR_MAP.find(error => (e?.data?.message || e?.message) === error.blockchainMessage);

    errorMessage = error ? error.displayMessage : DEFAULT_ERROR_MESSAGE;
    setRequestError({errorMessage});
  }

  const requestContractApproval = async () => {
    try {
      let result = await requestApproval(pgerc1155_PlanetSale, payableMinterV2Address);
      setContractHasApproval(result);
    } catch (e) {
      handleRequestError(e);    }
  }

  const requestAllowanceIncrease = async () => {
    let nftContractPrice = calcTransactionValue();

    try {
      let result = await increaseERC20Allowance(erc20Contract, currentWallet, nftContractPrice, payableMinterV2Address);
      await loadAdditionalAllowanceRequired();

      if (result !== CONTRACT_APPROVAL_STATUS.APPROVED) {
          handleRequestError('generic error')
      }
    } catch (e) {
      handleRequestError(e);
    }
  }

  const calcTransactionValue = () => {
    let nftContractPrice = ethers.BigNumber.from(planetSaleContext.NFTCost);
    nftContractPrice = nftContractPrice.mul(weiInEthereum);
    nftContractPrice = nftContractPrice.mul(planetSaleContext.numberOfNFTToMin);

    return nftContractPrice;
  }

  const processPlanetPurchase = async () => {
    let nftContractPrice = calcTransactionValue();

    const paymentTokenAddress = getPaymentTokenAddress();

    let mintingResult;

    try {
      switch (planetSaleContext.sellType) {
        case "PresaleA":
          if (paymentTokenAddress === nullAddress.nullAddress) {
            mintingResult = await payableMinterV2Functions.mintPresaleA(planetSaleContext.nftId, paymentTokenAddress, planetSaleContext.numberOfNFTToMin, planetSaleContext.ticket, "0x", planetSaleContext.V, planetSaleContext.R, planetSaleContext.S, {value: nftContractPrice.toString()});
          } else {
            mintingResult = await payableMinterV2Functions.mintPresaleA(planetSaleContext.nftId, paymentTokenAddress, planetSaleContext.numberOfNFTToMin, planetSaleContext.ticket, "0x", planetSaleContext.V, planetSaleContext.R, planetSaleContext.S);
          }
          break;
        case "PresaleB":
          if (paymentTokenAddress === nullAddress.nullAddress) {
            mintingResult = await payableMinterV2Functions.mintPresaleB(planetSaleContext.nftId, paymentTokenAddress, planetSaleContext.numberOfNFTToMin, planetSaleContext.ticket, "0x", planetSaleContext.V, planetSaleContext.R, planetSaleContext.S, {value: nftContractPrice.toString()});
          } else {
            mintingResult = await payableMinterV2Functions.mintPresaleB(planetSaleContext.nftId, paymentTokenAddress, planetSaleContext.numberOfNFTToMin, planetSaleContext.ticket, "0x", planetSaleContext.V, planetSaleContext.R, planetSaleContext.S);
          }
          break;
        case "PublicSale":
        default:
          if (paymentTokenAddress === nullAddress.nullAddress) {
            mintingResult = await payableMinterV2Functions.mintPublicSale(planetSaleContext.nftId, paymentTokenAddress, planetSaleContext.numberOfNFTToMin, "0x", planetSaleContext.V, planetSaleContext.R, planetSaleContext.S, {value: nftContractPrice.toString()});
          } else {
            mintingResult = await payableMinterV2Functions.mintPublicSale(planetSaleContext.nftId, paymentTokenAddress, planetSaleContext.numberOfNFTToMin, "0x", planetSaleContext.V, planetSaleContext.R, planetSaleContext.S);
          }
          break;
        case "PrivatePresale":
          if (paymentTokenAddress === nullAddress.nullAddress) {
            mintingResult = await payableMinterV2Functions.mintPrivateSale(planetSaleContext.nftId, paymentTokenAddress, planetSaleContext.numberOfNFTToMin, "0x", planetSaleContext.V, planetSaleContext.R, planetSaleContext.S, {value: nftContractPrice.toString()});
          } else {
            mintingResult = await payableMinterV2Functions.mintPrivateSale(planetSaleContext.nftId, paymentTokenAddress, planetSaleContext.numberOfNFTToMin, "0x", planetSaleContext.V, planetSaleContext.R, planetSaleContext.S);
          }
          break;
      }
      setTransactionComplete(false);

      await mintingResult.wait();

      if (mintingResult) {
        setTransactionComplete(true);
        planetSaleContext.afterMintDecreaseNFT(planetSaleContext.indexOfNFT, planetSaleContext.sellType);
      } else {
        // Generic 'try again' error. Not sure if this scenario would happen (i.e. no result returned, but also no errors thrown)
        setRequestError('minting failed')
      }
    } catch (e) {
      console.error(e);
      handleRequestError(e);
    }
  }

  let handleClick = async (clickAction) => {
    setRequestError(null);
    await clickAction();
  }

  const showTermsAndConditionsModal = () => {
    setTermsConditionsModalFlag(true);
  }

  const hideTermsAndConditionsModal = () => {
    setTermsConditionsModalFlag(false);
  }

  const acceptTermsAndConditions = () => {
    setTermsConditionsAccepted(true);
    setTermsConditionsModalFlag(false);
  }

  return <>
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName='planet-purchase-modal-container'>
      <ModalCloseButton clickEvent={() => planetSaleContext.setPlanetPurchaseModalShow(false)}/>
      {transactionComplete !== true && <div className={"planet-purchase-principle-container"}>
        {/*TODO: Get better horns images for larger scale*/}
        <img className={"planet-purchase-header-horns"} src={currency_header_horn} alt=""/>
        <div className={"planet-purchase-text-description"}>
          {/*TODO: tweak copy*/}
          Please complete these steps to purchase your planets:
        </div>
        <div id={"planet-purchase-steps"} className={"planet-purchase-steps"}>

          <Container fluid className={'planet-purchase-steps-container'}>

            {/* Only show this step if contract approval is required (i.e. Presale A or Presale B) */}
            { contractApprovalMandatory &&
              <PurchaseStepRow
                title={'Approve Presale Ticket Transfer'}
                description={'This is needed so we can transfer your presale ticket(s) when making a planet purchase'}
                button={
                  <PurchaseStepButton
                    status={contractApprovalIsLoading ? planetPurchaseStepStatus.LOADING
                      : contractHasApproval ? planetPurchaseStepStatus.COMPLETE
                        : planetPurchaseStepStatus.INCOMPLETE}
                    loadingText={'Checking if approved...'}
                    buttonText={'Approve for Transfer'}
                    onClick={() => handleClick(requestContractApproval)}
                  />
                  }
              />
            }
            <PurchaseStepRow
              title={`Increase ${planetSaleContext.selectedCurrency} allowance`}
              description={`The ${planetSaleContext.selectedCurrency} allowance needs to be high enough to cover your purchase cost`}
              button={
              <PurchaseStepButton
                status={allowanceIsLoading ? planetPurchaseStepStatus.LOADING
                  : allowanceIncreaseRequired?.lte(0) ? planetPurchaseStepStatus.COMPLETE
                    : planetPurchaseStepStatus.INCOMPLETE }
                loadingText={'Checking your allowance limit...'}
                buttonText={`Increase Allowance`}
                onClick={() => handleClick(requestAllowanceIncrease)}
                />
            }
            />
            <PurchaseStepRow
              title={'Accept T&Cs'}
              description={'You must read and accept the T&Cs before purchasing planets'}
              button={
              <PurchaseStepButton
                status={termsConditionsAccepted ? planetPurchaseStepStatus.COMPLETE : planetPurchaseStepStatus.INCOMPLETE}
                buttonText={'Review T&Cs'}
                onClick={() => handleClick(showTermsAndConditionsModal)}
                />}
            />
            <PurchaseStepRow
              title={'Purchase planets'}
              description={'This will process your purchase, exchanging your payment for freshly minted planets'}
              button={
              <PurchaseStepButton
                status={((contractApprovalMandatory && !contractHasApproval) || allowanceIncreaseRequired?.gt(0) || !termsConditionsAccepted) ? planetPurchaseStepStatus.DISABLED
                  : transactionComplete === null ? planetPurchaseStepStatus.INCOMPLETE
                    : transactionComplete === false ? planetPurchaseStepStatus.PENDING
                      : planetPurchaseStepStatus.COMPLETE }
                buttonText={'Make Purchase'}
                loadingText={'Awaiting approval...'}
                pendingText={'Transaction processing on blockchain...'}
                onClick={() => handleClick(processPlanetPurchase)}
              />}
            />
            <PurchaseModalError errorMessage={requestError?.errorMessage || null}/>
          </Container>
          <Row>
            <ModalButton buttonText={'CANCEL'} onClick={() => planetSaleContext.setPlanetPurchaseModalShow(false)}/>
          </Row>
        </div>
        <img className={"planet-purchase-bottom-horns"} src={currency_bottom_horn} alt=""/>
      </div>
      }

      {transactionComplete === true &&
        <div className={"planet-purchase-principle-container"}>
          <div className={"planet-purchase-text-description"}>
            Congrats! Your planet purchase was successful.
          </div>
          <ModalButton buttonText={"OK"} onClick={() => {
            planetSaleContext.setPlanetPurchaseModalShow(false);
          }}/>
        </div>
      }
    </Modal>
    <PlanetPurchaseTCsModal show={termsConditionsModalFlag} onHide={hideTermsAndConditionsModal} setTermsConditionsAccepted={acceptTermsAndConditions} props />
    </>
}
