import './GenerativePlanetMintingInfo.scss'
import PageHeader from "../../PageHeader";
import {episode_dropdown_arrow} from "../../../assets/images/NFT";
import {generativePlanetMintingInfoText} from "../../../utils/GeneralUtils";
import {useState} from "react";

export default function GenerativePlanetMintingInfo() {
  const [mintPlanetInfo, setMintPlanetInfo] = useState(false);
  const [catalystInfo, setCatalystInfo] = useState(false);
  const [planetInfo, setPlanetInfo] = useState(false);

  const toggleMintPlanetInfo = () => {
    setMintPlanetInfo(!mintPlanetInfo)
  }

  const toggleCatalystInfo = () => {
    setCatalystInfo(!catalystInfo)
  }

  const togglePlanetInfo = () => {
    setPlanetInfo(!planetInfo)
  }

  return <div className={'generative-planet-info-container'}>
    <PageHeader headerText={'INFO'}/>
    <div className={'mint-planet-info-container'}>
      <div onClick={toggleMintPlanetInfo} className={'mint-planet-info-header'}>
        <img className={mintPlanetInfo ? 'mint-info-arrow rotate' : 'mint-info-arrow'} alt={'>'} src={episode_dropdown_arrow}/>
        <div className={'mint-planet-info-header-text'}>
          HOW DO I MINT A PLANET?
        </div>
      </div>
      {mintPlanetInfo && <div className={'mint-planet-info-text'}>
        {generativePlanetMintingInfoText.planetMint}
      </div>}
    </div>
    <div className={'catalyst-info-container'}>
      <div onClick={toggleCatalystInfo} className={'catalyst-info-header'}>
        <img className={catalystInfo ? 'mint-info-arrow rotate' : 'mint-info-arrow'} alt={'>'} src={episode_dropdown_arrow}/>
        <div className={'catalyst-info-header-text'}>
          CATALYSTS & TRAITS
        </div>
      </div>
      {catalystInfo && <div className={'catalyst-info-text'}>
        {generativePlanetMintingInfoText.catalyst}
      </div>}
    </div>
    <div className={'catalyst-info-container'}>
      <div onClick={togglePlanetInfo} className={'catalyst-info-header'}>
        <img className={planetInfo ? 'mint-info-arrow rotate' : 'mint-info-arrow'} alt={'>'} src={episode_dropdown_arrow}/>
        <div className={'catalyst-info-header-text'}>
          WHERE ARE MY PLANETS?
        </div>
      </div>
      {planetInfo && <div className={'catalyst-info-text'}>
        {generativePlanetMintingInfoText.planets}
      </div>}
    </div>
  </div>
}
