import "./IconCard.scss";
import GenericButton from "../../Button/GenericButton";

type IconCardProps = {
  imagePath: string;
  imageAltText: string;
  imageBackgroundColour?: string;
  mainText: string;
  subText: string;
  buttonText?: string;
  clickHandler?: () => void;
  disabled?: boolean
}
export function IconCard({imagePath, imageAltText, imageBackgroundColour, mainText, subText, buttonText, clickHandler, disabled}: IconCardProps) {
  return (
      <div className="icon-card">
        <img src={imagePath} className="icon-card__image"
             alt={imageAltText}
             style={imageBackgroundColour ? {backgroundColor: imageBackgroundColour} : {border: "1px solid rgba(255, 255, 255, 0.1)"}}/>
        <p className="icon-card__sub-text">{subText}</p>
        <p className="icon-card__main-text">{mainText}</p>
        {
          buttonText && <GenericButton handleClick={clickHandler} buttonText={buttonText} buttonClass={"icon-card__button"} disabled={disabled}></GenericButton>
        }
      </div>
  )
}
