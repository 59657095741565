import './PlanetsPanels.scss'
import PlanetPanelFilter from "../PlanetPanelFilter/PlanetPanelFilter";
import React, {useCallback} from "react";

export default function PlanetsPanels({allPlanets, filteredPlanets, setPlanetsCallback}) {
  let planetsMapper = (planets, updatedPlanets, title) => {
    return planets.map(planet => {
      if (planet.title === title) {
        return {
          ...planet,
          planets: updatedPlanets
        }
      }
      return planet
    })
  }

  let planetSelectedHandler = useCallback((title, updatedPlanets) => {
    let selectedPlanets = planetsMapper(filteredPlanets, updatedPlanets, title)
    let updatedAllPlanets = planetsMapper(allPlanets, updatedPlanets, title)
    setPlanetsCallback(selectedPlanets, updatedAllPlanets)
  },[filteredPlanets,setPlanetsCallback, allPlanets])

  return (
    <div className={'planets-panels-container'}>
      {
        filteredPlanets.length !== 0 ?
          <div className={'planets-panels'}>
            {filteredPlanets.map((filteredPlanet, index) => (
              <PlanetPanelFilter
                title={filteredPlanet.title}
                key={index}
                planets={filteredPlanet.planets}
                planetSelectedHandler={planetSelectedHandler.bind(this,filteredPlanet.title)}
                showSelectAll={filteredPlanet.showSelectAll}
              />
            ))}
          </div>
          : <></>
      }
    </div>
  )
}
