import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import LoadingSpinner from "../../LoadingSpinner";
import "./PurchaseStepButton.scss";
import {tickInHex} from "../../../assets";
import {planetPurchaseStepStatus} from "./PlanetPurchaseModal";

export default function PurchaseStepButton({status, buttonText, onClick, loadingText, pendingText}) {
  let className;

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (isLoading) {
      onClick().then(() => {
        setLoading(false);
      });
    }
    // eslint-disable-next-line
  }, [isLoading]);

  const handleClick = () => setLoading(true);

  if (status === planetPurchaseStepStatus.LOADING) {
    return (
      <div className={"purchase-step-button-container"}>
        <LoadingSpinner size={'sm'} marginBottom={'0px'}/>
        <p>{loadingText || 'Loading...'}</p>
      </div>
    )
  } else if (status === planetPurchaseStepStatus.DISABLED) {
    className = 'purchase-step-button';

    return (
      <div className={"purchase-step-button-container"}>
          <Button className={`button ${className}`} disabled>
            <div>{buttonText}</div>
          </Button>
      </div>
    )
  } else if (status === planetPurchaseStepStatus.PENDING) {
    return (
      <div className={"purchase-step-button-container"}>
        <LoadingSpinner size={'sm'} marginBottom={'0px'}/>
        <p>{pendingText || 'Loading...'}</p>
      </div>
    )
  } else if (status === planetPurchaseStepStatus.COMPLETE) {
    return (
      <div className={"purchase-step-button-container"}>
        <div><img alt="" className={"purchase-step-check-icon"} src={tickInHex}/></div>
      </div>
    )
  } else {
    // status === 'incomplete' + default state
    className = 'purchase-step-button'

    return (
      <div className={"purchase-step-button-container"}>
        <Button className={className} onClick={!isLoading ? handleClick : null} disabled={isLoading}>
          <div>{isLoading ? 'Awaiting approval...' : buttonText}</div>
        </Button>
      </div>
    )
  }
}
