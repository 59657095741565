import { ethers } from 'ethers';
import AstraferAbi from '@fugu/base-contracts/dist/abis/Tokens/Astrafer.ERC20.sol/Astrafer.json';
import { ethereum_icon, polygon_icon } from '../assets';

export const WALLET_OLD_AUTH_SIGN_MESSAGE =
  'Sign this message to authenticate your wallet. This request will not trigger a blockchain transaction or cost any gas fees.';

const PGERC1155Address = process.env.REACT_APP_PGERC1155Address;
const converterAddress = process.env.REACT_APP_ConverterAddress;
const mintPassConverterAddress = process.env.REACT_APP_MintPassConverterAddress;
const layerZeroEthereumChainId = process.env.REACT_APP_layerZeroEthereumChainId;
const layerZeroEthereumId = process.env.REACT_APP_LayerZeroEthereumId;
const layerZeroPolygonId = process.env.REACT_APP_layerZeroPolygonId;
export const polygonId = process.env.REACT_APP_POLYGON_CHAIN_ID;
export const ethereumId = process.env.REACT_APP_ETHEREUM_CHAIN_ID;

export const layerZeroConfig = {
  [polygonId]: {
    name: process.env.REACT_APP_POLYGON_NAME,
    abi: require('@fugu/base-contracts/dist/abis/Test/LayerZeroV2.ERC20.sol/AstraferBridge.json').abi,
    proxyAddress: process.env.REACT_APP_LayerZeroPolygonAstraferProxyAddress,
    layerZeroChainId: process.env.REACT_APP_LayerZeroPolygonId,
    etherscanUrl: process.env.REACT_APP_POLYGON_SCAN_URL,
    astraferAddress: process.env.REACT_APP_ASTRAFER_ERC20_ADDRESS,
    rpcUrl: process.env.REACT_APP_POLYGON_RPC_URL,
    astraferAbi: AstraferAbi.abi,
    tokenName: 'MATIC',
    imageSrc: polygon_icon,
    altImageText: 'Polygon icon',
    imageClassName: 'polygon-icon',
  },
  [ethereumId]: {
    name: process.env.REACT_APP_ETHEREUM_NAME,
    abi: require('@fugu/base-contracts/dist/abis/Test/LayerZeroV2.ERC20.sol/BridgedAstrafer.json').abi,
    proxyAddress: process.env.REACT_APP_LayerZeroEthereumAstraferAddress,
    layerZeroChainId: process.env.REACT_APP_LayerZeroEthereumId,
    rpcUrl: process.env.REACT_APP_ETHEREUM_RPC_URL,
    etherscanUrl: process.env.REACT_APP_ETHEREUM_SCAN_URL,
    tokenName: 'ETH',
    imageSrc: ethereum_icon,
    altImageText: 'Ethereum icon',
    imageClassName: 'ethereum-icon',
  },
};

const ENABLE_KYC_PAGE = process.env.REACT_APP_FEATURE_FLAG_KYC_PAGE_ENABLED === 'true';
const ENABLE_PRIVATE_PLANET_SALE = process.env.REACT_APP_FEATURE_FLAG_PRIVATE_PLANET_SALE_ENABLED === 'true';
const ENABLE_PRESALE_TICKETS = process.env.REACT_APP_FEATURE_FLAG_PRESALE_TICKETS_ENABLED === 'true';
const FORCE_PLANET_SALE_TIMERS_ONLY = process.env.REACT_APP_FEATURE_FLAG_PLANET_SALE_FORCE_TIMERS_ONLY === 'true';
const ENABLE_COMPENSATION_PAGE = process.env.REACT_APP_FEATURE_FLAG_COMPENSATION_PAGE_ENABLED === 'true';
const PUBLIC_PLANET_SALE_FINISHED = process.env.REACT_APP_FEATURE_FLAG_PUBLIC_PLANET_SALE_FINISHED === 'true';
const ASTRAFITE_RUSH_PAGE_ENABLED = process.env.REACT_APP_FEATURE_FLAG_ASTRAFITE_RUSH_PAGE_ENABLED === 'true';
const REACT_APP_MOCK_API = process.env.REACT_APP_MOCK_API === 'true';

export const PGERC1155Address_PlanetSale = process.env.REACT_APP_PLANET_SALE_PGERC1155_ADDRESS;
export const payableMinterV2Address = process.env.REACT_APP_PLANET_SALE_PAYABLE_MINTER_ADDRESS;
export const catalystPGERC1155Address = process.env.REACT_APP_CATALYST_PGERC1155_ADDRESS;
export const planetSuperMintConverterAddress = process.env.REACT_APP_PLANET_SUPER_MINT_CONVERTER_ADDRESS;
export const PGERC1155Address_Avatar_Starfighter = process.env.REACT_APP_AVATAR_STARFIGHTER_PGERC1155_ADDRESS;
export const avatarSuperMintConverterAddress = process.env.REACT_APP_AVATAR_SUPER_MINT_CONVERTER;
export const starfighterSuperMintConverterAddress = process.env.REACT_APP_STARFIGHTER_SUPER_MINT_CONVERTER_ADDRESS;
export const weiInEthereum = ethers.BigNumber.from(10).pow(process.env.REACT_APP_PLANET_SALE_PRICE_DECIMALS);

export const NFTIdCost = {
  id1: process.env.REACT_APP_PLANET_SALE_TOKEN_ID_1,
  costid1: ethers.BigNumber.from(process.env.REACT_APP_PLANET_SALE_TOKEN_PRICE_1).mul(weiInEthereum),
  id2: process.env.REACT_APP_PLANET_SALE_TOKEN_ID_2,
  costid2: ethers.BigNumber.from(process.env.REACT_APP_PLANET_SALE_TOKEN_PRICE_2).mul(weiInEthereum),
  id3: process.env.REACT_APP_PLANET_SALE_TOKEN_ID_3,
  costid3: ethers.BigNumber.from(process.env.REACT_APP_PLANET_SALE_TOKEN_PRICE_3).mul(weiInEthereum),
  id4: process.env.REACT_APP_PLANET_SALE_TOKEN_ID_4,
  costid4: ethers.BigNumber.from(process.env.REACT_APP_PLANET_SALE_TOKEN_PRICE_4).mul(weiInEthereum),
  id5: process.env.REACT_APP_PLANET_SALE_TOKEN_ID_5,
  costid5: ethers.BigNumber.from(process.env.REACT_APP_PLANET_SALE_TOKEN_PRICE_5).mul(weiInEthereum),
};

export const paymentTokenAddress = {
  USDCAddress: process.env.REACT_APP_PLANET_SALE_USDC_ADDRESS,
  USDTAddress: process.env.REACT_APP_PLANET_SALE_USDT_ADDRESS,
};

export const nullAddress = {
  nullAddress: '0x0000000000000000000000000000000000000000',
};

const episodeIds = {
  EPISODE_1: 1,
  EPISODE_2: 2,
  EPISODE_1_BADGE: 101,
  EPISODE_1_REDEEMED: 11,
  EPISODE_2_BADGE: 102,
  EPISODE_2_REDEEMED: 12,
  EPISODE_3: 3,
  EPISODE_3_BADGE: 103,
  EPISODE_3_REDEEMED: 13,
  EPISODE_4: 4,
  EPISODE_4_BADGE: 104,
  EPISODE_4_REDEEMED: 14,
};

const MINT_PASS_IDS = {
  PRISTINE_FLEET_MINTPASS: process.env.REACT_APP_PRISTINE_GENESIS_MINT_PASS_ID,
  VETERAN_FLEET_MINTPASS: process.env.REACT_APP_VETERAN_GENESIS_MINT_PASS_ID,
  ZVETA_GENE_SAMPLE_MINTPASS: process.env.REACT_APP_ZVETA_GENE_GENESIS_MINT_PASS_ID,
  STANDARD_ISSUE_STARFIGHTER_MINTPASS: process.env.REACT_APP_STANDARD_ISSUE_STARFIGHTER,
  UNSPECIFIED_ORIGIN_GENESIS_MINTPASS: process.env.REACT_APP_UNSPECIFIED_ORIGIN_GENE_SAMPLE,
};

const CLAIMING_EPISODE = {
  EPISODE_1: 'EPISODE_1',
  EPISODE_2: 'EPISODE_2',
  EPISODE_3: 'EPISODE_3',
  EPISODE_4: 'EPISODE_4',
};

const BUTTON_ACTION = {
  CLOSE: 'CLOSE',
  CONVERTING_YES: 'CONVERTING_YES',
  CONVERTING_EPISODE_YES: 'CONVERTING_EPISODE_YES',
};

const CONTRACT_APPROVAL_STATUS = {
  APPROVED: 'APPROVED',
  UNAPPROVED: 'UNAPPROVED',
};

const T_AND_C_SIGNING_MESSSGE = 'I agree to the license terms and conditions hosted at https://phantomgalaxies.com/license';

const AFR_TNC_SIGNING_MESSAGE = 'I agree to the license terms and conditions hosted at https://phantomgalaxies.com/astrafite-rush-event';

export const KYC_VERIFIED = {
  NOT_APPROVED: 0,
  APPROVED: 1,
};

export const TICKET_TYPE = {
  TICKET_TYPE_A: 'A',
  TICKET_TYPE_B: 'B',
};

export {
  PGERC1155Address,
  converterAddress,
  mintPassConverterAddress,
  ENABLE_KYC_PAGE,
  ENABLE_PRIVATE_PLANET_SALE,
  ENABLE_PRESALE_TICKETS,
  FORCE_PLANET_SALE_TIMERS_ONLY,
  ENABLE_COMPENSATION_PAGE,
  PUBLIC_PLANET_SALE_FINISHED,
  ASTRAFITE_RUSH_PAGE_ENABLED,
  episodeIds,
  BUTTON_ACTION,
  CONTRACT_APPROVAL_STATUS,
  MINT_PASS_IDS,
  T_AND_C_SIGNING_MESSSGE,
  CLAIMING_EPISODE,
  REACT_APP_MOCK_API,
  AFR_TNC_SIGNING_MESSAGE,
  layerZeroEthereumChainId,
  layerZeroEthereumId,
  layerZeroPolygonId,
};

export const RECON_MISSION_NAME = 'recon mission';
export const MissionStatus = {
  ACTIVE: 'active',
};

export const PlanetStatus = {
  IN_MISSION: 'IN_MISSION',
  NOT_IN_MISSION: 'NOT_IN_MISSION',
  COMPLETED_MISSION: 'COMPLETED_MISSION',
};

export const PG_ICON_PREFIX = 'pg-icon-';

export const ERROR_TEXT = {
  GENERIC_ERROR: 'Something went wrong, please try again later.',
  WALLET_MISMATCH: 'The wallet used to sign the transaction did not match the connected wallet. Please reconnect your wallet and try again.',
  WALLET_NOT_ELIGIBLE: 'Wallet is not eligible.',
  WALLET_DOESNT_MATCH: 'Connected wallet address does not match the wallet linked to your account',
};

export const TOKEN_ID_EP1_POSTER = process.env.REACT_APP_TOKEN_ID_EP1_POSTER;
export const TOKEN_ID_EP1_BADGE = process.env.REACT_APP_TOKEN_ID_EP1_BADGE;
export const TOKEN_ID_EP1_REDEEMED_POSTER = process.env.REACT_APP_TOKEN_ID_EP1_REDEEMED_POSTER;

export const TOKEN_ID_EP2_POSTER = process.env.REACT_APP_TOKEN_ID_EP2_POSTER;
export const TOKEN_ID_EP2_BADGE = process.env.REACT_APP_TOKEN_ID_EP2_BADGE;
export const TOKEN_ID_EP2_REDEEMED_POSTER = process.env.REACT_APP_TOKEN_ID_EP2_REDEEMED_POSTER;

export const TOKEN_ID_EP3_POSTER = process.env.REACT_APP_TOKEN_ID_EP3_POSTER;
export const TOKEN_ID_EP3_BADGE = process.env.REACT_APP_TOKEN_ID_EP3_BADGE;
export const TOKEN_ID_EP3_REDEEMED_POSTER = process.env.REACT_APP_TOKEN_ID_EP3_REDEEMED_POSTER;

export const TOKEN_ID_EP4_POSTER = process.env.REACT_APP_TOKEN_ID_EP4_POSTER;
export const TOKEN_ID_EP4_BADGE = process.env.REACT_APP_TOKEN_ID_EP4_BADGE;
export const TOKEN_ID_EP4_REDEEMED_POSTER = process.env.REACT_APP_TOKEN_ID_EP4_REDEEMED_POSTER;

export const TOKEN_ID_HOPEFUL = process.env.REACT_APP_TOKEN_ID_HOPEFUL;

export const ASSET_NOT_FOUND_MESSAGES = {
  PLANET_NOT_FOUND: 'Might need to reconsider your intergalactic holiday plans, Ranger - that Planet doesn’t exist.',
  STARFIGHTER_NOT_FOUND: 'We’ve checked each hangar and swept all the systems, Ranger - that Starfighter doesn’t exist.',
  AVATAR_NOT_FOUND: 'We’ve scoured the enlistment records, but you should check your source, Ranger - that Pilot doesn’t exist.',
};
