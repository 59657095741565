import './TitleHeader.scss';
import { question_hover } from '../../../assets/images/ClaimReward';
import React, { useState } from 'react';
import ConnectButton from './ConnectButton/ConnectButton';
import KYCModal from '../../Modal/KYCModal/KYCModal';
import { rewardsClaim } from '../../../utils/GeneralUtils';

export default function TitleHeader({ kycStatus }) {
  const KYCStatus = {
    INCOMPLETE: 'incomplete',
    PENDING: 'pending',
    FAILED: 'failed',
    SUCCESS: 'passed',
    BLOCKED: 'blocked',
  };

  const KYCButtonStates = {
    INCOMPLETE: 'CONNECT WITH BLOCKPASS',
    PENDING: 'KYC CHECK PENDING',
    FAILED: 'CONNECT WITH BLOCKPASS',
    SUCCESS: 'KYC COMPLETE',
    BLOCKED: 'KYC FAILED',
  };

  const [show, setShow] = useState(false);

  function getButtonText() {
    if (kycStatus === KYCStatus.PENDING) {
      return KYCButtonStates.PENDING;
    } else if (kycStatus === KYCStatus.FAILED) {
      return KYCButtonStates.FAILED;
    } else if (kycStatus === KYCStatus.SUCCESS) {
      return KYCButtonStates.SUCCESS;
    } else if (kycStatus === KYCStatus.BLOCKED) {
      return KYCButtonStates.BLOCKED;
    } else {
      return KYCButtonStates.INCOMPLETE;
    }
  }

  function getButtonStatus() {
    if (kycStatus === KYCStatus.PENDING) {
      return false;
    } else if (kycStatus === KYCStatus.FAILED) {
      return true;
    } else if (kycStatus === KYCStatus.SUCCESS) {
      return false;
    } else if (kycStatus === KYCStatus.BLOCKED) {
      return false;
    } else {
      return true;
    }
  }

  function getDescriptionText() {
    if (kycStatus === KYCStatus.PENDING) {
      return rewardsClaim.endOfDescriptionPending;
    } else if (kycStatus === KYCStatus.FAILED) {
      return rewardsClaim.endOfDescriptionFailed;
    } else if (kycStatus === KYCStatus.SUCCESS) {
      return rewardsClaim.endOfDescriptionSuccess;
    } else if (kycStatus === KYCStatus.BLOCKED) {
      return rewardsClaim.endOfDescriptionBlocked;
    } else {
      return rewardsClaim.endOfDescriptionNotStarted;
    }
  }

  const showModal = () => {
    setShow(true);
  };

  return (
    <>
      <KYCModal show={show} setShow={bool => setShow(bool)} />
      <div className={'title-description-container'}>
        <div className={'description-container'}>
          <div className={'description-text'}>
            <img src={question_hover} alt="" className={'question-hover'} onClick={showModal} />
            {rewardsClaim.paragraph1}
            {getDescriptionText()}
          </div>
        </div>
        <div className={'button-container'}>
          <ConnectButton text={getButtonText()} status={getButtonStatus()} />
        </div>
      </div>
    </>
  );
}
