import React, {useState} from "react";
import './SwapButtons.scss';
import {
  coinstore,
  dragon_logo,
  ethereum_icon,
  mainnet_img,
  plus_decoration_img,
  quickswap_big_background,
  sushi_swap_logo,
  uni_swap_logo,
} from '../../assets/images/Token';
import {boarder_detail_bottom, boarder_detail_top, hover_triangle} from "../../assets/images/AstrafiteRushEvent";
import {exchangeAddress, SWAP_url} from "../../utils/GeneralUtils";

export default function SwapButtons() {
  function openQUICKSWAP() {
    return window.open(SWAP_url.quickSwapUrl, '_blank');
  }

  function openSUSHISWAP() {
    return window.open(SWAP_url.sushiSwap, '_blank');
  }

  function openUNISWAP() {
    return window.open(SWAP_url.uniSwapUrl, '_blank');
  }

  const [copyValue, setCopyValue] = useState('Copy');

  function resetCopy() {
    setCopyValue('Copy');
  }

  function copyText(textToCopy) {
    navigator.clipboard.writeText(textToCopy);
    setCopyValue('Copied!');
  }

  return (
    <>
      <div className={'sb-quickswap-main-container'}>
        <div className={'sb-swap-background-img-controller'}>
          <img alt={''} src={quickswap_big_background} className={'sb-decorative-background-from-quickswap'} />
        </div>
        <div className={'sb-quickswap-content'}>
          <img alt={''} src={boarder_detail_top} className={'sb-token-boarder_detail_top'} />
          <div className={'line-separator'} />
          <div className={'sb-center-shadow-element'} />
          <div className={'sb-quickswap-value-container'}>
            <p className={'sb-quickswap-text'}>ASTRAFER IS AVAILABLE ON:</p>
            <img alt={''} src={ethereum_icon} className={'sb-ethereum-icon-adjustment'} />
            <p className={'sb-coin-description-text'}>ETHEREUM MAINNET</p>
            <div className={'address-for-exchange'}>
              <p className={'toke-description-text'}>Astrafer Token address: &nbsp;</p>
              <div className={'content-of-address'} onMouseLeave={() => resetCopy()} onClick={() => copyText(exchangeAddress.ethereum)}>
                {' '}
                {exchangeAddress.ethereum}
                <div className="token-page-copy-text-main-container">
                  <div className={'token-page-copy-text-content-container'}>
                    {copyValue}
                    <img alt={''} className={'adjust-hover-triangle-bottom'} src={hover_triangle} />
                  </div>
                </div>
              </div>
            </div>

            <div className={'sb-swap-button'} onClick={() => openSUSHISWAP()}>
              <img alt={''} src={sushi_swap_logo} className={'sb-coin-logo-adjustment'} />
              SUSHISWAP
            </div>
            <img alt={''} src={plus_decoration_img} className={'sb-plus-decoration-img'} />

            <img alt={''} src={mainnet_img} className={'sb-infinite-coin-logo-adjustments'} />
            <p className={'sb-coin-description-text mobile-margin-bottom'}>MATIC MAINNET</p>
            <div className={'address-for-exchange'}>
              <p className={'toke-description-text'}>Astrafer Token address: &nbsp;</p>
              <div className={'content-of-address'} onMouseLeave={() => resetCopy()} onClick={() => copyText(exchangeAddress.matic)}>
                {' '}
                {exchangeAddress.matic}
                <div className="token-page-copy-text-main-container">
                  <div className={'token-page-copy-text-content-container'}>
                    {copyValue}
                    <img alt={''} className={'adjust-hover-triangle-bottom'} src={hover_triangle} />
                  </div>
                </div>
              </div>
            </div>
            <div className={'sb-matic-buttons-container'}>
              <div className={'sb-swap-button'} onClick={() => openQUICKSWAP()}>
                <img alt={''} src={dragon_logo} className={'sb-coin-logo-adjustment'} />
                QUICKSWAP
              </div>
              <div className={'sb-swap-button'} onClick={() => openUNISWAP()}>
                <img alt={''} src={uni_swap_logo} className={'sb-coin-logo-adjustment'} />
                UNISWAP
              </div>
              <div className={'sb-swap-button'} onClick={() => window.open('https://www.coinstore.com/#/spot/ASTRAFERUSDT', '_blank')}>
                <img alt={''} src={coinstore} className={'sb-coin-logo-adjustment'} />
                COINSTORE
              </div>
            </div>
          </div>
          <div className={'line-separator'} />
          <img alt={''} src={boarder_detail_bottom} className={'sb-token-boarder_detail_top'} />
        </div>
      </div>
    </>
  );
}
