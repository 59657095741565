import React from "react";
import "./BottomComponent.scss";
import {
  animoca_logo_new,
  blowfish_logo_new,
} from "../../../../assets/images/Footer";
import { footerLinks } from "../../../../utils/GeneralUtils";
import { legalAndPolicyLinks } from "../../../../utils/GeneralUtils";


export default function BottomComponent() {
  return (
    <>
      <div className={"bottom-component-main-container"}>
        <a
          href={footerLinks.animoca}
          target="_blank"
          rel="noreferrer"
          className={"animcoa-link-adjustment"}
        >
          <img alt={""} className={"animoca-logo-img"} src={animoca_logo_new} />
        </a>
        <div className={"footer-buttons-container"}>
          <div className={"footer-link-buttons"}>
            <a
              href={legalAndPolicyLinks.credits}
              target="_blank"
              rel="noreferrer"
              className={"underline-elimination"}
            >
              Credits
            </a>
            <a
              href={"/license"}
              className={"underline-elimination"}
            >
              NFT License
            </a>
            <a
              href={legalAndPolicyLinks.privacyPolicy}
              target="_blank"
              rel="noreferrer"
              className={"underline-elimination"}
            >
              Privacy Policy
            </a>
            <a
              href={legalAndPolicyLinks.cookiePolicy}
              target="_blank"
              rel="noreferrer"
              className={"underline-elimination"}
            >
              Cookie Policy
            </a>

            <a
              href={legalAndPolicyLinks.termsAndConditions}
              target="_blank"
              rel="noreferrer"
              className={"underline-elimination"}
            >
              Terms & Conditions
            </a>
          </div>
          <div className={"footer-brand-button"}>
            <div className={"footer-development-company-row"}>
              <div className={"footer-developed-by"}>Developed by:</div>

              <a href={footerLinks.blowFish} target="_blank" rel="noreferrer">
                <img
                  alt=""
                  src={blowfish_logo_new}
                  className={"blowfish-logo-img"}
                />
              </a>
            </div>
            <div className={"footer-show-the-year"}>
              © {new Date().getFullYear()} Ultra Modern Group Ltd
            </div>
          </div>
        </div>
      </div>

      <div className={"bottom-component-main-container-mobile"}>
        <div className={"footer-link-buttons"}>
          <a
            href={legalAndPolicyLinks.credits}
            target="_blank"
            rel="noreferrer"
            className={"underline-elimination"}
          >
            Credits
          </a>
          <a
            href={"/license"}
            target="_blank"
            rel="noreferrer"
            className={"underline-elimination"}
          >
            NFT License
          </a>
          <a
            href={legalAndPolicyLinks.privacyPolicy}
            target="_blank"
            rel="noreferrer"
            className={"underline-elimination"}
          >
            Privacy Policy
          </a>
          <a
            href={legalAndPolicyLinks.cookiePolicy}
            target="_blank"
            rel="noreferrer"
            className={"underline-elimination"}
          >
            Cookie Policy
          </a>

          <a
            href={legalAndPolicyLinks.termsAndConditions}
            target="_blank"
            rel="noreferrer"
            className={"underline-elimination"}
          >
            Terms & Conditions
          </a>
        </div>
        <div className={"footer-buttons-container"}>
          <a href={footerLinks.animoca} target="_blank" rel="noreferrer">
            <img
              alt={""}
              className={"animoca-logo-img"}
              src={animoca_logo_new}
            />
          </a>
          <div className={"footer-brand-button"}>
            <div className={"footer-development-company-row"}>
              <div className={"footer-developed-by"}>Developed by:</div>

              <a href={footerLinks.blowFish} target="_blank" rel="noreferrer">
                <img
                  alt=""
                  src={blowfish_logo_new}
                  className={"blowfish-logo-img"}
                />
              </a>
              <div className={"footer-show-the-year"}>
                © {new Date().getFullYear()} Ultra Modern Group Ltd
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
