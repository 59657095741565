import React, { useEffect, useState } from 'react';
import GenericButton from 'components/Button/GenericButton';
import './VaultAstrafer.scss';
import { getMyVaultBalance, withdrawFromVault } from '../../utils/ApiCalls';
import PGModal from '../Common/PGModal/PGModal';
import LoadingSpinner from '../LoadingSpinner';
import { checkbox_checked } from '../../assets';
import SuccessMessage from '../Common/SuccessMessage/SuccessMessage';
import { getBlockchainContract } from '../../utils/providerObject';
import { useProvider } from '../../hooks/useProvider';
import PGVaultAbi from '@fugu/base-contracts/dist/abis/Vault/Previous.Versions/PGVault.v2.sol/PGVaultv2.json';

export default function VaultWithdrawAstrafer() {
  const IN_GAME_MULTIPLIER = 10 ** 5;
  const [withdrawalAmount, setWithdrawalAmount] = useState(undefined);
  const [astraferBalance, setAstraferBalance] = useState(0);
  const [showWithdrawalModal, setShowWithdrawalModal] = useState(false);
  const [isWithdrawalUserDriven, setIsWithdrawalUserDriven] = useState(false);
  const [isWithdrawalSuccess, setIsWithdrawalSuccess] = useState(false);
  const [isWithdrawalUserDrivenSuccess, setIsWithdrawalUserDrivenSuccess] = useState(false);
  const [isWithdrawalLoading, setIsWithdrawalLoading] = useState(false);
  const [withdrawSignature, setWithdrawSignature] = useState(undefined);
  const [withdrawTxHash, setWithdrawTxHash] = useState(undefined);

  const provider = useProvider();

  useEffect(() => {
    const getAstraferBalance = async () => {
      const response = await getMyVaultBalance();

      setAstraferBalance(parseInt(response?.find(balance => balance.currency.toLowerCase() === 'astrafer').amount, 10) / IN_GAME_MULTIPLIER);
    };

    getAstraferBalance();
  }, );

  const promptWithdrawalTransaction = () => {
    setShowWithdrawalModal(true);
  };

  const vaultWithdrawAstrafer = async () => {
    setIsWithdrawalLoading(true);
    try {
      const signature = await withdrawFromVault((withdrawalAmount * IN_GAME_MULTIPLIER).toString());
      setIsWithdrawalLoading(false);
      setAstraferBalance(prevState => prevState - withdrawalAmount);
      setWithdrawSignature(signature);
      
      if(signature) {
        //Just got a signature - let the user finish withdrawing this themselves right now!
        setIsWithdrawalUserDriven(true);
      } else {
        //Just got no signature, this is a manual withdrawal - added to the queue. Must be manually reviewed
        setIsWithdrawalSuccess(true);
      }
    } catch (error) {
      setIsWithdrawalLoading(false);
      console.error(`Error withdrawing ASTRAFER: ${error}`);
    }
  };

  const doWithdrawTransactionNow = async () => {
    setIsWithdrawalUserDriven(false);
    setIsWithdrawalLoading(true);
    
    //TODO trigger the blockchain transaction now...
    const vaultAddress = process.env.REACT_APP_CONTRACT_ADDRESS_VAULT;
    const contract = getBlockchainContract(vaultAddress, PGVaultAbi.abi, provider);
    const transaction = await withdrawERC20Signed(
      contract,
      withdrawSignature.structArrays[0],
      withdrawSignature.signatures[0],
    );
    setWithdrawTxHash(transaction.hash);
    
    setIsWithdrawalLoading(false);
    setIsWithdrawalUserDrivenSuccess(true);
  };

  const withdrawERC20Signed = async (contract, struct, signature) => {
    return await contract.withdrawERC20Signed(struct, signature);
  };

  return (
    <>
      {/*// deposit from blockchain take the intput and multiply by 1e18 no min*/}
      <div className={'vault-astrafer__container'}>
        <label className={'vault-astrafer__label'} htmlFor={'astraferWithdraw'}>
          WITHDRAW ASTRAFER FROM GAME
        </label>
        <div className={'vault-astrafer__form'}>
          <input
            className={'vault-astrafer__input'}
            placeholder={'Withdrawal amount'}
            type="number"
            id={'astraferWithdraw'}
            min={10}
            value={withdrawalAmount}
            onChange={e => setWithdrawalAmount(e.target.value)}
          />
          <GenericButton handleClick={() => promptWithdrawalTransaction()} buttonText="Withdraw" disabled={withdrawalAmount > astraferBalance} />
        </div>
        <p>Game Balance: {astraferBalance}</p>
        {withdrawalAmount >= 10 && withdrawalAmount > astraferBalance && (
          <>
            <p className={'vault-astrafer__error-text'}>Insufficient Astrafer to withdraw</p>
          </>
        )}
        {withdrawalAmount < 10 && (
          <>
            <p className={'vault-astrafer__error-text'}>Minimum withdrawal amount is 10</p>
          </>
        )}
      </div>
      <PGModal
        show={showWithdrawalModal}
        onHide={() => {
          setShowWithdrawalModal(false);
          setIsWithdrawalSuccess(false);
          setIsWithdrawalUserDrivenSuccess(false);
          setIsWithdrawalUserDriven(false);
        }}
        // The prompt message will display for a brief moment if we set isBoundSuccess to false as the modal is closed
        // This ensures the success message will persist until the modal has exited completely
        onExited={() => {
          setIsWithdrawalSuccess(false);
          setIsWithdrawalUserDrivenSuccess(false);
          setIsWithdrawalUserDriven(false);
        }}
      >
        <div className="vault-astrafer__modal-text-container">
          {isWithdrawalLoading && (
            <>
              <LoadingSpinner marginBottom={'16px'} />
            </>
          )}
          {!isWithdrawalLoading && !isWithdrawalSuccess && !isWithdrawalUserDriven && !isWithdrawalUserDrivenSuccess && (
            <>
              <p>Your request for withdrawal will be submitted for review.</p>
              <p>Do you wish to proceed?</p>
              <GenericButton handleClick={() => vaultWithdrawAstrafer()} buttonText={'YES'} buttonClass="planet-dashboard__modal-button" />
            </>
          )}
          {isWithdrawalSuccess && (
            <>
              <img alt="a tick inside of circle" src={checkbox_checked} className="planet-dashboard__modal-success-icon" />
              <SuccessMessage message={`Congratulations ranger, your withdrawal request has been received.`} children = {`Please allow between 7-14 days for the process to be completed.`} />
              <GenericButton handleClick={() => setShowWithdrawalModal(false)} buttonText="close" buttonClass="planet-dashboard__modal-button" />
            </>
          )}
          {isWithdrawalUserDrivenSuccess && (
            <>
              <img alt="a tick inside of circle" src={checkbox_checked} className="planet-dashboard__modal-success-icon" />
              <SuccessMessage message={`Congratulations ranger, your withdrawal request has been processed and transferred into your wallet. `}>
                <>
                  <p>See transaction <a href={`${process.env.REACT_APP_POLYGON_SCAN_URL}${withdrawTxHash}`} target="_blank" rel="noreferrer">here</a></p>
                </>
              </SuccessMessage>
              <GenericButton handleClick={() => setShowWithdrawalModal(false)} buttonText="close" buttonClass="planet-dashboard__modal-button" />
            </>
          )}
          {isWithdrawalUserDriven && (
            <>
              <SuccessMessage message={`Your withdrawal is ready to be processed immediately, please sign this transaction to complete the withdrawal.`} />
              <GenericButton handleClick={() => doWithdrawTransactionNow()} buttonText="Withdraw now" buttonClass="planet-dashboard__modal-button" />
            </>
          )}
        </div>
      </PGModal>
    </>
  );
}
