window.ShowOverlay = function (overlayName, animationName) {
  overlayName = "overlay-" + overlayName;
  var cssClasses = document.getElementById(overlayName).className.split(" ");
  var last = cssClasses.slice(-1)[0];
  if (last.lastIndexOf("animate") === -1) {
    document.getElementById(overlayName).className =
      document.getElementById(overlayName).className + " " + animationName;
  }
  if (window.loadAsyncSrc !== undefined) {
    window.loadAsyncSrc();
  }
};
window.HideOverlay = function (overlayName, animationName) {
  overlayName = "overlay-" + overlayName;
  var cssClasses = document.getElementById(overlayName).className.split(" ");
  var last = cssClasses.slice(-1)[0];
  if (last.lastIndexOf("animate") !== -1) {
    cssClasses.splice(-1);
    cssClasses.push(animationName);
    document.getElementById(overlayName).className = cssClasses.join(" ");

    cssClasses.splice(-1);
    setTimeout(function () {
      document.getElementById(overlayName).className = cssClasses.join(" ");
    }, 1100);
  }
  var vids = null;
  if (vids) {
    for (var i = 0; i < vids.length; i++) {
      var video = vids.item(i);
      video.pause();
    }
  }
};

let overlay_id = "overlay-tablet-768-galleryone";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-tablet-768-galleryone";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("tablet-768-galleryone");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("tablet-768-galleryone", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-tablet-768-gallerytwo";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-tablet-768-gallerytwo";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("tablet-768-gallerytwo");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("tablet-768-gallerytwo", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-tablet-768-gallerythree";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-tablet-768-gallerythree";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("tablet-768-gallerythree");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("tablet-768-gallerythree", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-tablet-768-galleryfour";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-tablet-768-galleryfour";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("tablet-768-galleryfour");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("tablet-768-galleryfour", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-tablet-768-galleryfive";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-tablet-768-galleryfive";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("tablet-768-galleryfive");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("tablet-768-galleryfive", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-tablet-768-gallerysix";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-tablet-768-gallerysix";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("tablet-768-gallerysix");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("tablet-768-gallerysix", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-tablet-768-galleryseven";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-tablet-768-galleryseven";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("tablet-768-galleryseven");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("tablet-768-galleryseven", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-tablet-768-galleryeight";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-tablet-768-galleryeight";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("tablet-768-galleryeight");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("tablet-768-galleryeight", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-tablet-768-gallerynine";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-tablet-768-gallerynine";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("tablet-768-gallerynine");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("tablet-768-gallerynine", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-tablet-768-galleryten";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-tablet-768-galleryten";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("tablet-768-galleryten");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("tablet-768-galleryten", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-tablet-768-galleryeleven";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-tablet-768-galleryeleven";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("tablet-768-galleryeleven");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("tablet-768-galleryeleven", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-tablet-768-gallerytwelve";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-tablet-768-gallerytwelve";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("tablet-768-gallerytwelve");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("tablet-768-gallerytwelve", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-tablet-768-gallerythirteen";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-tablet-768-gallerythirteen";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("tablet-768-gallerythirteen");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("tablet-768-gallerythirteen", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-tablet-768-galleryfourteen";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-tablet-768-galleryfourteen";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("tablet-768-galleryfourteen");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("tablet-768-galleryfourteen", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-tablet-768-galleryfifteen";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-tablet-768-galleryfifteen";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("tablet-768-galleryfifteen");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("tablet-768-galleryfifteen", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-tablet-768-gallerysixteen";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-tablet-768-gallerysixteen";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("tablet-768-gallerysixteen");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("tablet-768-gallerysixteen", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1024-galleryone";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1024-galleryone";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1024-galleryone");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1024-galleryone", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1024-gallerytwo";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1024-gallerytwo";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1024-gallerytwo");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1024-gallerytwo", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1024-gallerythree";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1024-gallerythree";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1024-gallerythree");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1024-gallerythree", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1024-galleryfour";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1024-galleryfour";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1024-galleryfour");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1024-galleryfour", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1024-galleryfive";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1024-galleryfive";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1024-galleryfive");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1024-galleryfive", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1024-gallerysix";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1024-gallerysix";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1024-gallerysix");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1024-gallerysix", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1024-galleryseven";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1024-galleryseven";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1024-galleryseven");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1024-galleryseven", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1024-galleryeight";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1024-galleryeight";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1024-galleryeight");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1024-galleryeight", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1024-gallerynine";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1024-gallerynine";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1024-gallerynine");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1024-gallerynine", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1024-galleryten";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1024-galleryten";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1024-galleryten");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1024-galleryten", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1024-galleryeleven";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1024-galleryeleven";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1024-galleryeleven");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1024-galleryeleven", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1024-gallerytwelve";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1024-gallerytwelve";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1024-gallerytwelve");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1024-gallerytwelve", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1024-gallerythirteen";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1024-gallerythirteen";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1024-gallerythirteen");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1024-gallerythirteen", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1024-galleryfourteen";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1024-galleryfourteen";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1024-galleryfourteen");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1024-galleryfourteen", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1024-galleryfifteen";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1024-galleryfifteen";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1024-galleryfifteen");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1024-galleryfifteen", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1024-gallerysixteen";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1024-gallerysixteen";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1024-gallerysixteen");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1024-gallerysixteen", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1440-galleryone";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1440-galleryone";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1440-galleryone");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1440-galleryone", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1440-gallerytwo";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1440-gallerytwo";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1440-gallerytwo");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1440-gallerytwo", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1440-gallerythree";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1440-gallerythree";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1440-gallerythree");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1440-gallerythree", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1440-galleryfour";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1440-galleryfour";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1440-galleryfour");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1440-galleryfour", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1440-galleryfive";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1440-galleryfive";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1440-galleryfive");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1440-galleryfive", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1440-gallerysix";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1440-gallerysix";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1440-gallerysix");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1440-gallerysix", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1440-galleryseven";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1440-galleryseven";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1440-galleryseven");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1440-galleryseven", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1440-galleryeight";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1440-galleryeight";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1440-galleryeight");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1440-galleryeight", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1440-gallerynine";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1440-gallerynine";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1440-gallerynine");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1440-gallerynine", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1440-galleryten";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1440-galleryten";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1440-galleryten");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1440-galleryten", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1440-galleryeleven";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1440-galleryeleven";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1440-galleryeleven");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1440-galleryeleven", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1440-gallerytwelve";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1440-gallerytwelve";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1440-gallerytwelve");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1440-gallerytwelve", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1440-gallerythirteen";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1440-gallerythirteen";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1440-gallerythirteen");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1440-gallerythirteen", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1440-galleryfourteen";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1440-galleryfourteen";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1440-galleryfourteen");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1440-galleryfourteen", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1440-galleryfifteen";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1440-galleryfifteen";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1440-galleryfifteen");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1440-galleryfifteen", "animate-disappear");
    }
  },
  false
);


overlay_id = "overlay-desktop-1440-gallerysixteen";
document.getElementById(overlay_id)?.addEventListener(
  "click",
  function (event) {
    overlay_id = "overlay-desktop-1440-gallerysixteen";
    var e = event || window.event;
    var overlayElement = document.getElementById(overlay_id);
    var overlayContainer = overlayElement.getElementsByClassName("desktop-1440-gallerysixteen");
    var clickedDiv = e.toElement || e.target;
    var dismissButton = clickedDiv.parentElement.id === overlay_id;
    var clickOutsideOverlay = false;
    if (overlayContainer.length > 0) {
      clickOutsideOverlay = !overlayContainer[0].contains(clickedDiv) || overlayContainer[0] === clickedDiv;
    }
    if (dismissButton || clickOutsideOverlay) {
      window.HideOverlay("desktop-1440-gallerysixteen", "animate-disappear");
    }
  },
  false
);

