import React from "react";
import "./IconElement.scss";


export default function IconElement(props) {
  return <>
    <div className={"converting-poster-modal-img"}>
      <div className={"converting-poster-modal-flame"}>
        <img alt="" className={"converting-poster-modal-flame-dimension"} src={props.firstIcon}/>
      </div>
      {props.secondIcom != null ?
        <div className={"converting-poster-modal-right-arrows"}>
          <img alt="" className={"converting-poster-modal-arrow-dimension"} src={props.secondIcom}/>
        </div> : <></>
      }
    </div>
  </>
}
