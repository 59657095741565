import { ChainConfig, ChainId, TokenBridgeAction, TokenInfo } from './tokenBridgeTypes';
import {
  SET_ERROR_MODAL_TEXT,
  SET_IS_TRANSACTION_LOADING,
  SET_IS_TRANSACTION_SUCCESSFUL,
  SET_RECEIVE_ADDED_TOKEN_BALANCE,
  SET_RECEIVE_BALANCE_IS_LOADING,
  SET_RECEIVE_CHAIN_CONFIG,
  SET_RECEIVE_CHAIN_NAME,
  SET_RECEIVE_TOKEN_BALANCE,
  SET_RECEIVE_TOKEN_NAME,
  SET_SEND_BALANCE_IS_LOADING,
  SET_SEND_CHAIN_CONFIG,
  SET_SEND_CHAIN_ID,
  SET_SEND_CHAIN_NAME,
  SET_SEND_INPUT_AMOUNT,
  SET_SEND_IS_CHAIN_ID_SUPPORTED,
  SET_SEND_SHOW_MODAL,
  SET_SEND_SUBTRACTED_TOKEN_BALANCE,
  SET_SEND_TOKEN_BALANCE,
  SET_SEND_TOKEN_NAME,
  SET_SHOW_ERROR_MODAL,
  SET_TRANSACTION_HASH,
} from './tokenBridgeConstants';

// SEND

export const setSendChainId = (chainId: number | null): TokenBridgeAction => ({
  type: SET_SEND_CHAIN_ID,
  payload: chainId,
});

export const setSendChainName = (chainName: string): TokenBridgeAction => ({
  type: SET_SEND_CHAIN_NAME,
  payload: chainName,
});

export const setSendChainConfig = (chainConfig: ChainConfig & ChainId): TokenBridgeAction => ({
  type: SET_SEND_CHAIN_CONFIG,
  payload: chainConfig,
});

export const setSendIsChainIdSupported = (isSupported: boolean): TokenBridgeAction => ({
  type: SET_SEND_IS_CHAIN_ID_SUPPORTED,
  payload: isSupported,
});

export const setSendTokenBalance = (tokenBalance: number): TokenBridgeAction => ({
  type: SET_SEND_TOKEN_BALANCE,
  payload: tokenBalance,
});

export const setSendInputAmount = (amount: number): TokenBridgeAction => ({
  type: SET_SEND_INPUT_AMOUNT,
  payload: amount,
});

export const setSendSubtractedTokenBalance = (transferAmount: number): TokenBridgeAction => ({
  type: SET_SEND_SUBTRACTED_TOKEN_BALANCE,
  payload: transferAmount,
});

export const setSendBalanceIsLoading = (isLoading: boolean): TokenBridgeAction => ({
  type: SET_SEND_BALANCE_IS_LOADING,
  payload: isLoading,
});

export const setSendTokenInfo = (tokenInfo: TokenInfo): TokenBridgeAction => ({
  type: SET_SEND_TOKEN_NAME,
  payload: tokenInfo,
});

export const setReceiveChainName = (chainName: string): TokenBridgeAction => ({
  type: SET_RECEIVE_CHAIN_NAME,
  payload: chainName,
});

// RECEIVE

export const setReceiveChainConfig = (chainConfig: ChainConfig & ChainId): TokenBridgeAction => ({
  type: SET_RECEIVE_CHAIN_CONFIG,
  payload: chainConfig,
});

export const setReceiveTokenBalance = (tokenBalance: number): TokenBridgeAction => ({
  type: SET_RECEIVE_TOKEN_BALANCE,
  payload: tokenBalance,
});

export const setReceiveBalanceIsLoading = (isLoading: boolean): TokenBridgeAction => ({
  type: SET_RECEIVE_BALANCE_IS_LOADING,
  payload: isLoading,
});

export const setReceiveAddedTokenBalance = (transferAmount: number): TokenBridgeAction => ({
  type: SET_RECEIVE_ADDED_TOKEN_BALANCE,
  payload: transferAmount,
});

export const setReceiveTokenInfo = (tokenInfo: TokenInfo): TokenBridgeAction => ({
  type: SET_RECEIVE_TOKEN_NAME,
  payload: tokenInfo,
});

export const setSendShowModal = (showModal: boolean): TokenBridgeAction => ({
  type: SET_SEND_SHOW_MODAL,
  payload: showModal,
});

export const setShowErrorModal = (showModal: boolean): TokenBridgeAction => ({
  type: SET_SHOW_ERROR_MODAL,
  payload: showModal,
});

export const setErrorModalText = (errorText: string): TokenBridgeAction => ({
  type: SET_ERROR_MODAL_TEXT,
  payload: errorText,
});

export const setIsTransactionLoading = (isLoading: boolean): TokenBridgeAction => ({
  type: SET_IS_TRANSACTION_LOADING,
  payload: isLoading,
});

export const setIsTransactionSuccessful = (isSuccess: boolean): TokenBridgeAction => ({
  type: SET_IS_TRANSACTION_SUCCESSFUL,
  payload: isSuccess,
});

export const setTransactionHash = (transactionHash: any): TokenBridgeAction => ({
  type: SET_TRANSACTION_HASH,
  payload: transactionHash,
});

