import './ExplorationRewardsAstrafer.scss'
import ExplorationRewardsCell from "../ExplorationRewardsCell/ExplorationRewardsCell";

export default function ExplorationRewardsAstrafer({minGain, maxGain, daysDuration, checkpoints, astraferPerCheckpoint}) {

  return <div className={'rewards-astrafer-container'}>
    <div className={'rewards-astrafer-title-container'}>
      <div className={'rewards-title-text'}>ASTRAFER REWARDS</div>
    </div>
    {<div className={'rewards-astrafer-data-container'}>
      <ExplorationRewardsCell cellTitle={'Min Haul:'} cellData={minGain || 0} cellColor={'blue'} cellSize={'small'}/>
      <ExplorationRewardsCell cellTitle={'Max Haul:'} cellData={maxGain || 0} cellColor={'blue'} cellSize={'small'}/>
      <ExplorationRewardsCell cellTitle={'Days Duration:'} cellData={daysDuration || 0} cellColor={'blue'}
                              cellSize={'small'}/>
      <ExplorationRewardsCell cellTitle={'Checkpoints:'} cellData={checkpoints || 0} cellColor={'blue'}
                              cellSize={'small'}/>
      <ExplorationRewardsCell cellTitle={'ASTRAFER per checkpoint'} cellData={astraferPerCheckpoint || 0} cellColor={'blue'}
                              cellSize={'small'}/>
    </div>}
  </div>
}
