import React from "react";
import "./PrivateSaleErrorBox.scss";
import {errorbox_header_horns, exportbox_bottem_horns} from "../../assets/images/PlanetSalePagePrivate";

export default function PrivateSaleErrorBox(props) {

  return <>
    <div className={"pseb-principel-container center-element-column"}>
      <img src={errorbox_header_horns} alt=""/>
      <div className={"pseb-elements-container center-element"}>
        <p className={"pseb-text "}>{props.text}</p>
      </div>
      <img src={exportbox_bottem_horns} alt=""/>
    </div>
  </>
}
