import './PlanetDashboard.scss';
import Footer from '../components/Footer/Footer';
import FighterLady from '../components/Footer/FooterElements/FighterLady/FighterLady';
import HeaderComponent from '../components/Header/HeaderComponent';
import PageHeader from '../components/PageHeader';
import { walletContext } from '../utils/WalletContext';
import StarfighterDashboardContent from '../components/Dashboard/Starfighter/StarfighterDashboardContent';
import { useIsCorrectChain } from '../hooks/useIsCorrectChain';
import { useNetwork } from 'wagmi';
import SwitchNetworkChainSection from '../components/SwitchNetworkChain';

export default function StarfighterDashboard() {
  const isCorrectChain = useIsCorrectChain();
  const network = useNetwork();

  return (
    <>
      <HeaderComponent noBackground={true} />
      <PageHeader headerText="STARFIGHTERS DASHBOARD" headerTextSecondLine={undefined} classname="planet-dashboard" />
      {!isCorrectChain && (
        <SwitchNetworkChainSection network={network} message={`You must be on the ${process.env.REACT_APP_CHAINID} network to mint NFTs`} />
      )}
      {walletContext.currentWallet && isCorrectChain && <StarfighterDashboardContent />}
      <Footer>
        <FighterLady />
      </Footer>
    </>
  );
}
