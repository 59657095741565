import "./GenerativeAvatarMintpassGrid.scss";
import GenerativeAvatarComponent from "../GenerativeAvatarComponent/GenerativeAvatarComponent";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";

export default function GenerativeAvatarMintpassGrid({
  scrollToInfo,
  isCatalyst,
  selected,
  selectHandler,
  planetMintpasses,
  isBatch,
}) {
  const planetCounterHandler = (tokenId, count) => {
    const toUpdate = planetMintpasses.find((type) => type.tokenId === tokenId);
    toUpdate.count = count;

    const updatedValues = [...planetMintpasses];

    selectHandler(updatedValues);
  };

  const getTotalPlanets = () => {
    let totalPlanets = 0;
    planetMintpasses.forEach((mintpass) => {
      totalPlanets += mintpass.count;
    });
    return totalPlanets;
  };

  return (
    <div className={"generative-avatar-mintpass-container"}>
      <div className={"generative-avatar-mintpass-title"}>
        SELECT AVATAR MINTPASS
      </div>
      <div
        onClick={scrollToInfo}
        className={"generative-avatar-mintpass-helper"}
      >
        (How do I mint an avatar?)
      </div>
      {
        <div
          className={
            isBatch
              ? "generative-avatar-mintpass-grid batch"
              : "generative-avatar-mintpass-grid"
          }
        >
          {planetMintpasses.map((planetMintpass) => (
            <GenerativeAvatarComponent
              key={planetMintpass.title}
              isCatalyst={isCatalyst}
              title={planetMintpass.title}
              stock={planetMintpass.stock}
              count={planetMintpass.count}
              imgSrc={planetMintpass.image}
              totalPlanets={getTotalPlanets()}
              onClick={
                isCatalyst
                  ? selectHandler.bind(this, planetMintpass.tokenId)
                  : planetCounterHandler.bind(this, planetMintpass.tokenId)
              }
              selected={selected === planetMintpass.tokenId}
              openseaUrl={planetMintpass.openseaUrl}
            />
          ))}
        </div>
      }
      {!isBatch && (
        <div className={"generative-avatar-mintpass-slider"}>
          <Swiper
            slidesPerView={3}
            slidesPerGroup={1}
            spaceBetween={-80}
            className="planet-swiper"
          >
            <SwiperSlide></SwiperSlide>
            {planetMintpasses.map((planetMintpass) => (
              <SwiperSlide>
                <GenerativeAvatarComponent
                  isCatalyst={isCatalyst}
                  title={planetMintpass.title}
                  stock={planetMintpass.stock}
                  imgSrc={planetMintpass.image}
                  totalPlanets={getTotalPlanets()}
                  onClick={
                    isCatalyst
                      ? selectHandler.bind(this, planetMintpass.tokenId)
                      : planetCounterHandler.bind(this, planetMintpass.tokenId)
                  }
                  selected={selected === planetMintpass.tokenId}
                  openseaUrl={planetMintpass.openseaUrl}
                />
              </SwiperSlide>
            ))}
            <SwiperSlide></SwiperSlide>
          </Swiper>
        </div>
      )}
    </div>
  );
}
