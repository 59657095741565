import './ClaimRewardContent.scss';
import ClaimTimer from '../ClaimTimer/ClaimTimer';
import TitleHeader from '../TitleHeader/TitleHeader';
import ClaimAstrafer from '../ClaimAstrafer/ClaimAstrafer';
import ClaimOtherNFTs from '../ClaimOtherNFTs/ClaimOtherNFTs';
import LoadingSpinner from '../../LoadingSpinner';
import SwitchNetworkChainSection from '../../../components/SwitchNetworkChain';
import PageHeader from '../../PageHeader';

import { ethers } from 'ethers';
import { walletContext } from '../../../utils/WalletContext';
import { useNetwork } from 'wagmi';
import { useIsCorrectChain } from '../../../hooks/useIsCorrectChain';
import React, { useContext } from 'react';
import { AfrContext } from '../../../context/AfrContext';

export default function ClaimRewardContent() {
  const network = useNetwork();
  const isCorrectChain = useIsCorrectChain();

  const {
    claimSignature,
    totalAvailableAmount,
    afrContract,
    setPrimaryNftIndex,
    kycStatus,
    currentClaimIndex,
    claimDates,
    nftCollection,
    nextClaimDisplayDate,
    primaryNftIndex,
    totalRemainingAmount,
    isClaimDataLoading
  } = useContext(AfrContext);

  function viewNftIndex(index) {
    setPrimaryNftIndex(index);
  }

  async function handleClaimAstrafer() {
    if (nftCollection && nftCollection.length > 0) {
      let tx = await afrContract.getRewards(nftCollection[primaryNftIndex].tokenId, claimSignature);
      await tx.wait();

      //Success - refresh page for now as a quick update
      window.location.reload();
    }
  }

  async function handleClaimAllAstrafer() {
    let tx = await afrContract.getRewardsBatch(claimSignature);
    await tx.wait();

    //Success - refresh page for now as a quick update
    window.location.reload();
  }

  return (
    <>
      <div className={'claim-reward-content-container'}>
        <div className={'title-container'}>
          <PageHeader headerText="REWARDS CLAIM" classname="page-mobile-header-text-no-margin" />
        </div>
        {walletContext.signed && !isCorrectChain && (
          <SwitchNetworkChainSection
            network={network}
            message={`You must be on the ${process.env.REACT_APP_CHAINID} network to access the Astrafite Rush Rewards Claim`}
          />
        )}
        {isCorrectChain && <TitleHeader kycStatus={kycStatus} />}
        {currentClaimIndex < 10 ? <ClaimTimer nextRewardTime={claimDates[currentClaimIndex].getTime()} /> : <></>}
        {nftCollection && nftCollection.length > 0 && isCorrectChain && (
          <>
            <ClaimAstrafer
              availableAmount={nftCollection[primaryNftIndex].amountToClaimDisplay}
              remainingAmount={nftCollection[primaryNftIndex].amountRemainingToBeClaimedDisplay}
              isFullyClaimed={nftCollection[primaryNftIndex].amountToClaim.isZero()}
              nextClaimDate={nextClaimDisplayDate}
              isFinalClaim={currentClaimIndex >= 9}
              tokenId={nftCollection[primaryNftIndex].tokenId}
              handleClaimAstrafer={handleClaimAstrafer}
              kycPassed={kycStatus === 'passed'}
            />
          </>
        )}

        {nftCollection && nftCollection?.length > 1 && isCorrectChain && (
          <ClaimOtherNFTs
            nftCollection={nftCollection}
            totalAvailableAmount={totalAvailableAmount}
            totalAvailableAmountDisplay={ethers.utils.commify(ethers.utils.formatUnits(totalAvailableAmount))}
            totalRemainingAmountDisplay={ethers.utils.commify(ethers.utils.formatUnits(totalRemainingAmount))}
            nextClaimDate={nextClaimDisplayDate}
            isFinalClaim={currentClaimIndex >= 9}
            handleClick={viewNftIndex}
            handleClaimAll={handleClaimAllAstrafer}
            kycPassed={kycStatus === 'passed'}
          />
        )}
        {isCorrectChain && isClaimDataLoading && (
          <>
            <div className={'loading-ring-position'}>
              <LoadingSpinner />
            </div>
          </>
        )}
      </div>
    </>
  );
}
