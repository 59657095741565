import React, {useState} from "react";
import "./LeaderBoardRow.scss";
import {hover_triangle} from "../../assets/images/AstrafiteRushEvent";
import copy from 'copy-to-clipboard';

export default function LeaderBoardRow(props) {

  const [copyValue, setCopyValue] = useState("Copy");

  function copyThis(textToCopy) {
    copy(textToCopy)
    setCopyValue("Copied!");
  }

  function resetCopy() {
    setCopyValue("Copy");
  }


  return (<>
    {props.rank !== props.playerRank ?
      <div className={"leader-board-row-container"}>
        <div className={"light-white-background"}/>
        <div className={"row-elements-box-for-alignment"}>
          <div className={"leader-table-element-description"}>
            <div className={"first-element-in-row"}>{props.rank}
            </div>
          </div>
          <div className={"leader-table-element-description"}>
            <div className={"player-element-in-row"} onMouseLeave={() => resetCopy()}
                 onClick={() => copyThis(props.player)}>
              <div className={"player-name"}>
                {
                  props.player.substring(0, 9)
                  + "..." +
                  props.player.substring(props.player.length - 4)

                }
              </div>
              <div className={"player-name-375"}>
                {
                  props.player.substring(0, 4)
                  + "..." +
                  props.player.substring(props.player.length - 3)

                }
              </div>

              <div className="copy-hover-element">
                <div className={"scale-copy-box"}>
                  {copyValue}
                  <img alt={""} className={"copy-progress-hover-triangle"} src={hover_triangle}/>
                </div>
              </div>
            </div>
          </div>
          <div className={"leader-table-element-description"}>
            <div className={"completion-element-in-row"}>{props.completion}%

              <div className="completion-hover-element">
                <div className={"scale-copy-box"}>
                  <div> Fire Dancers Defeated - {props.piratesKilled}/{props.fireDancersDefeated}</div>
                  <div> Astrafite Mined - {props.astrafiteBlocksMined}/{props.targetBlocks}</div>
                  <img alt={""} className={"cycle-progress-hover-triangle"} src={hover_triangle}/>
                </div>
              </div>

            </div>
          </div>
          <div className={"leader-table-element-description"}>
            <div className={"last-element-in-row"}>{props.time}</div>
          </div>
        </div>
      </div> :
      <>
        {props.children}
      </>
    }
  </>)
}
