import "./GenerativePlanetMintPopupWithoutCatalyst.scss";
import {
  popup_border_bottom_left_thin,
  popup_border_bottom_left_thin_small,
  popup_border_bottom_right_thin,
  popup_border_bottom_right_thin_small,
  popup_border_top_left_thin,
  popup_border_top_left_thin_small,
  popup_border_top_right_thin,
  popup_border_top_right_thin_small,
} from "../../../assets/images/GenerativePlanetMinting";
import BaseButton from "../../BaseButton/BaseButton";

export default function GenerativePlanetMintPopupWithoutCatalyst({
  planetMintpasses,
  handleNo,
  handleYes,
}) {
  let nrMintpasses = 0;
  planetMintpasses.forEach(
    (planetMintpass) => (nrMintpasses += planetMintpass.count)
  );

  return (
    <div className={"planet-minting-popup-container"}>
      <div className={"planet-minting-popup-border"}>
        <img
          src={popup_border_top_left_thin}
          alt={"popup-border"}
          className={"planet-minting-popup-border-icon"}
        />
        <img
          src={popup_border_top_right_thin}
          alt={"popup-border"}
          className={"planet-minting-popup-border-icon"}
        />

        <img
          src={popup_border_top_left_thin_small}
          alt={"popup-border"}
          className={"planet-minting-popup-border-icon-small"}
        />
        <img
          src={popup_border_top_right_thin_small}
          alt={"popup-border"}
          className={"planet-minting-popup-border-icon-small"}
        />
      </div>
      <div className="planet-minting-popup-content">
        <div className="planet-minting-popup-title">
          You are about to mint {nrMintpasses} Planet{" "}
          {nrMintpasses > 1 ? "NFTs" : "NFT"} with random Traits, burning the
          following {nrMintpasses > 1 ? "mintpasses" : "mintpass"}.
        </div>

        <div className={"planet-minting-popup-grid"}>
          {planetMintpasses.map(
            (planetMintpass) =>
              planetMintpass.count > 0 && (
                <div className={"generative-planet-component"}>
                  <div className={"generative-planet-border"}>
                    <img
                      className={"generative-planet-component-image"}
                      src={planetMintpass.image}
                      alt={"PLANET"}
                    />
                  </div>
                  <div className={"generative-planet-component-stock"}>
                    {planetMintpass.count}
                  </div>
                  <div className={"generative-planet-component-title"}>
                    {planetMintpass.title.toUpperCase()}
                  </div>
                </div>
              )
          )}
        </div>

        <div className="planet-minting-popup-text">Do you wish to proceed?</div>
        <div className="planet-minting-popup-buttons">
          <div className="planet-minting-popup-button">
            <BaseButton
              text={"NO"}
              scale={false}
              filled={false}
              disabled={false}
              onClick={handleNo}
            />
          </div>
          <div className="planet-minting-popup-button">
            <BaseButton
              text={"YES"}
              scale={false}
              filled={true}
              disabled={false}
              onClick={handleYes}
            />
          </div>
        </div>
      </div>
      <div className={"planet-minting-popup-border"}>
        <img
          src={popup_border_bottom_left_thin}
          alt={"popup-border"}
          className={"planet-minting-popup-border-icon"}
        />
        <img
          src={popup_border_bottom_right_thin}
          alt={"popup-border"}
          className={"planet-minting-popup-border-icon"}
        />

        <img
          src={popup_border_bottom_left_thin_small}
          alt={"popup-border"}
          className={"planet-minting-popup-border-icon-small"}
        />
        <img
          src={popup_border_bottom_right_thin_small}
          alt={"popup-border"}
          className={"planet-minting-popup-border-icon-small"}
        />
      </div>
    </div>
  );
}
