import './PlanetPanelFilter.scss'
import {useCallback, useEffect, useState} from "react";
import {nft_drop_down_arrow} from "../../../assets";
import DashboardPlanet from "../../ExplorationDashboard/DashboardPlanet/DashboardPlanet";
import { PLANET_IN_MISSION_STATUS } from "../../../utils/GeneralUtils";
import {PlanetStatus} from "../../../utils/Globals";

export default function PlanetPanelFilter({title, planets, planetSelectedHandler, showSelectAll}) {
  const [planetsState, setPlanetsState] = useState(planets);
  let isSelectAll = useCallback(() => {

    let numSelected = planetsState.reduce((accumulator, planet) => {
      return planet.isSelected ? accumulator = accumulator + 1 : accumulator;
    }, 0);

    let numPlanetsAvailable = planetsState.reduce((accumulator, planet) => {
      return planet.status === PlanetStatus.NOT_IN_MISSION ? accumulator = accumulator + 1 : accumulator
    }, 0);

    return numSelected === numPlanetsAvailable;
  }, [planetsState]);

  const [isSelectAllChecked, setIsSelectAllChecked] = useState(isSelectAll());
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  let selectAllHandler = () => {
    setIsSelectAllChecked(!isSelectAllChecked);
    const updatedPlanets = planets.map(planet => ({
      ...planet,
      isSelected: planet.status === PlanetStatus.NOT_IN_MISSION ? !isSelectAllChecked : false
    }));
    setPlanetsState(updatedPlanets);
    planetSelectedHandler(updatedPlanets);
  };

  const handlePlanetComponentClick = useCallback((planetSelected) => {
    const updatedPlanets = planetsState.map(planet => {
      if (planetSelected.status !== PLANET_IN_MISSION_STATUS && planet.id === planetSelected.id) {
        return {
          ...planet,
          isSelected: !planet.isSelected
        };
      }
      return planet;
    });
    setPlanetsState(updatedPlanets);
    planetSelectedHandler(updatedPlanets);
  },[planetsState, planetSelectedHandler])

  const mobilePanelHandler = () => {
    setIsPanelOpen(!isPanelOpen);
  }
  useEffect(() => {
    setIsSelectAllChecked(isSelectAll());
  }, [planetsState, isSelectAll]);

  useEffect(() => {
    setPlanetsState(planets);
  }, [planets]);


  return <>
    <div className={'planet-panel-container'}>
      <div className={'planet-panel-title'}>{title}</div>
      {showSelectAll && (
      <div className={'select-all-container'}>
        <div className={isSelectAllChecked ? 'select-all-text' : 'select-all-text unchecked'}>SELECT ALL</div>
        <div className={'select-all-slide'}>
          <label className="switch">
            <input type="checkbox" checked={isSelectAllChecked}
                   onChange={selectAllHandler}/>
            <span className="slider round"/>
          </label>
        </div>
      </div>
      )}
      <div className={'planets-panel'}>
        {planetsState.map((planet, index) =>
          <DashboardPlanet planet={planet} key={index} planetCallback={handlePlanetComponentClick.bind(this,planet)} isDashboard={false}/>
        )}
      </div>
    </div>
    <div className={'planet-panel-container mobile'}>
      <div onClick={mobilePanelHandler} className={!isPanelOpen ? 'planet-panel-header' : 'planet-panel-header open'}>
        <div className={'planet-panel-header-decoration'}></div>
        <div className={'planet-panel-header-content'}>
          <div className={'planet-panel-title'}>{title}</div>
          <img src={nft_drop_down_arrow}
               className={isPanelOpen ? 'planet-panel-dropdown-button' : 'planet-panel-dropdown-button right'}
               alt={'OPEN'}/>
        </div>
      </div>

      {isPanelOpen && <>
        <div className={'select-all-container'}>
          <div className={isSelectAllChecked ? 'select-all-text' : 'select-all-text unchecked'}>SELECT ALL</div>
          <div className={'select-all-slide'}>
            <label className="switch">
              <input type="checkbox" checked={isSelectAllChecked}
                     onClick={selectAllHandler}/>
              <span className="slider round"/>
            </label>
          </div>
        </div>
        <div className={'planets-panel'}>
          {planetsState.map((planet, index) =>
            <DashboardPlanet planet={planet} key={index} planetCallback={handlePlanetComponentClick.bind(this,planet)} isDashboard={false}/>
          )}
        </div>
      </>}
    </div>

  </>
}
