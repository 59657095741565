import React from 'react';

import {walletContext} from '../../utils/WalletContext'
import "./CustemConnectWalletbutton.scss";


export default class CustomConnectWalletButton extends React.Component {

  // TODO: determine where gtags are needed
  // window.gtag("event", "connectWallet");

  render() {
    return (
      <>
        {(!walletContext.signed) &&
          <div className={"custom-connect-wallet connect-wallet-button"} style={{textAlign: "center"}}
               onClick={() => window.location.href = '/account'}>
            <div className="connect-wallet-text">
             Sign in
            </div>
          </div>
        }
        {(walletContext.signed) &&
          <div className={"wallet-address"} style={{textAlign: "center"}}>
            <div className="connect-wallet-text">
              {
                walletContext.currentWallet.substring(0, 6)
                + "..." +
                walletContext.currentWallet.substring(walletContext.currentWallet.length - 4)
              }
            </div>
          </div>
        }
      </>
    );
  }
}
