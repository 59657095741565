import './GenesisNFTsDescriptionsList.scss';
import { Row } from 'react-bootstrap';
import React from 'react';
import { Tab, Col } from 'react-bootstrap';
import GenesisNFTsDescription from '../GenesisNFTsDescription/GenesisNFTsDescription';

export default function GenesisNFTsDescriptionsList({ setShowMintWithAstrafer, setShowCombinations, nfts, isSoldOut }) {
  const showCombinations = () => {
    setShowCombinations(true);
  };

  return (
    <>
      <Col sm={6}>
        <Tab.Content className={'episode-detail'}>
          {nfts.map(nft => (
            <Tab.Pane eventKey={nft.eventKey} key={nft.eventKey}>
              <Row>
                <GenesisNFTsDescription
                  setShowMintWithAstrafer={setShowMintWithAstrafer}
                  setShowCombinations={showCombinations}
                  title={nft.title}
                  imgUsed={isSoldOut ? nft.imageSoldOut : nft.image}
                  description={nft.description}
                  moreInfoLink={nft.moreInfo}
                  conversionButtonText={nft.conversionButtonText}
                  disableMintWithAstrafer={isSoldOut}
                  disableConversion={isSoldOut}
                  openseaUrl={nft.openseaUrl}
                />
              </Row>
            </Tab.Pane>
          ))}
        </Tab.Content>
      </Col>
    </>
  );
}
