import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import App from './App';
import Home from './routes/Home';
import Download from './routes/Download';
import Posters from './routes/Posters';
import Compensation from './routes/Compensation';
import AstrafiteRushEvent from './routes/AstrafiteRushEvent';
import reportWebVitals from './reportWebVitals';
import KYCPage from './routes/KYCPage/KYCPage';
import PlanetSalePagePrivate from './routes/PlanetSalePagePrivate/PlanetSalePagePrivate';
import PublicPlanetSale from './routes/PublicPlanetSale/PublicPlanetSale';
import { ASTRAFITE_RUSH_PAGE_ENABLED, ENABLE_COMPENSATION_PAGE, ENABLE_KYC_PAGE, ENABLE_PRIVATE_PLANET_SALE } from './utils/Globals';
import Token from './routes/Token';
import LeaderBoardPage from './components/LeaderBoardPage/LeaderBoardPage';
import AfrToken from './routes/AfrToken';
import ClaimReward from './routes/ClaimReward';
import License from './routes/License';
import GenerativePlanetMinting from './routes/GenerativePlanetMinting';
import ExplorationLanding from './routes/ExplorationLanding';
import ExplorationSelection from './routes/ExplorationSelection';
import PlanetExploration from './routes/PlanetExploration';
import PlanetDetails from './routes/PlanetDetails';
import Pilots from './routes/Pilots';
import Currency from './routes/Currency';
import Starfighter from './routes/Starfighter';
import PlanetDashboard from './routes/PlanetDashboard';
import GenerativeStarfighterMinting from './routes/GenerativeStarfighterMinting';
import GenerativeAvatarMinting from './routes/GenerativeAvatarMinting';
import StarfighterDashboard from './routes/StarfighterDashboard';
import { Account } from './routes/Account';
import AvatarDashboard from './routes/AvatarDashboard';
import { PrivateRoute } from './routes/common/PrivateRoute';
import { AfrProvider } from './context/AfrContext';
import PatchNotesWeb2 from './routes/PatchNotesWeb2';
import PatchNotesWeb3 from './routes/PatchNotesWeb3';
import { TokenBridge } from './routes/TokenBridge';

const LitepaperRedirectPath = () => {
  window.location.replace('/BFS_Phantom_Galaxies_Litepaper.pdf');
  return <> </>;
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/account" element={<Account />} />
          <Route
            path="/bridge"
            element={
              <PrivateRoute>
                <TokenBridge />
              </PrivateRoute>
            }
          />
          <Route path="/download" element={<Download />} />
          <Route path="/nft" element={
            <PrivateRoute>
              <Posters />
            </PrivateRoute>
          } />
          {ASTRAFITE_RUSH_PAGE_ENABLED && (
            <Route
              path="/astrafite-rush-event"
              element={
                <PrivateRoute>
                  <AfrProvider>
                    <AstrafiteRushEvent />
                  </AfrProvider>
                </PrivateRoute>
              }
            />
          )}
          <Route
            path="/rewards-claim"
            element={
              <PrivateRoute>
                <AfrProvider>
                  <ClaimReward />
                </AfrProvider>
              </PrivateRoute>
            }
          />

          {ENABLE_COMPENSATION_PAGE && <Route path="/compensation" element={<Compensation />} />}
          {/*<Route path="/connect-wallet" element={<ConnectWallet />} />*/}
          <Route
            path="/portfolio/pilots"
            element={
              <PrivateRoute>
                <Pilots />
              </PrivateRoute>
            }
          />
          <Route
            path="/portfolio/pilots/dashboard"
            element={
              <PrivateRoute>
                <AvatarDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/portfolio/starfighters"
            element={
              <PrivateRoute>
                <Starfighter />
              </PrivateRoute>
            }
          />
          <Route
            path="/portfolio/starfighters/dashboard"
            element={
              <PrivateRoute>
                <StarfighterDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/portfolio/currency"
            element={
              <PrivateRoute>
                <Currency />
              </PrivateRoute>
            }
          />
          {ENABLE_KYC_PAGE && <Route path="/kyc" element={<KYCPage />} />}
          {ENABLE_PRIVATE_PLANET_SALE && <Route path="/PlanetSalePagePrivate" element={<PlanetSalePagePrivate />} />}
          <Route path="/planet-sale" element={<PublicPlanetSale />} />
          <Route
            path="/portfolio/tcq-recon"
            element={
              <PrivateRoute>
                <ExplorationLanding />
              </PrivateRoute>
            }
          />
          <Route path="/portfolio/tcq-recon/new-mission" element={<ExplorationSelection />} />
          <Route path="/portfolio/tcq-recon/view-mission" element={<PlanetExploration />} />
          <Route
            path="/portfolio/planets/dashboard"
            element={
              <PrivateRoute>
                <PlanetDashboard />
              </PrivateRoute>
            }
          />
          <Route path="/leaderboard" element={<LeaderBoardPage />} />
          {/*<Route path="/dashboard" element={<ExplorationDashboard/>}/>*/}
          <Route path="/token" element={<Token />} />
          <Route path="/portfolio/planets/:guid" element={<PlanetDetails />} />
          <Route path="/portfolio/planets/:chainId/:contractAddress/:tokenId" element={<PlanetDetails />} />
          <Route path="/afr-token" element={<AfrToken />} />
          <Route
            path={'/portfolio/mint-planets'}
            element={
              <PrivateRoute>
                <GenerativePlanetMinting />
              </PrivateRoute>
            }
          />
          <Route
            path={'/portfolio/mint-pilots'}
            element={
              <PrivateRoute>
                <GenerativeAvatarMinting />
              </PrivateRoute>
            }
          />
          <Route
            path={'/portfolio/mint-starfighters'}
            element={
              <PrivateRoute>
                <GenerativeStarfighterMinting />
              </PrivateRoute>
            }
          />
          <Route path="/rewards-claim" element={<ClaimReward />} />
          <Route path={'/license'} element={<License />} />
          {/* Keeping old path of afrToken for any old non-updated opensea URLs */}
          <Route path="/afrToken" element={<AfrToken />} />
          {/* Redirecting old Litepaper URL to new Litepaper URL so we can delete one of the duplicate litepapers */}
          <Route path="/BFS%20Phantom%20Galaxies%20Litepaper%20v1.0.pdf" element={<LitepaperRedirectPath />} />
          <Route path="/patchnotes/latest/web2" element={<PatchNotesWeb2 />} />
          <Route path="/patchnotes/latest/web3" element={<PatchNotesWeb3 />} />
        </Routes>
      </App>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
