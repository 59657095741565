import React from 'react';
import './KYCPage.scss';
import {Container} from "react-bootstrap";
import {check_mark, kycBG, plusSeparator} from "../../assets";
import KYCBoarderHeader from "./components/KYCBoarderHeader";
import RoundedDescriptionBox from "./components/RoundedDescriptionBox/RoundedDescriptionBox";
import TiltedButton from "../../components/TiltedButton/TiltedButton";
import ConnectWalletButton from "../../components/ConnectWalletButton";
import {walletContext} from "../../utils/WalletContext";
import Blockpass from "../../components/NFTComponents/Blockpass/Blockpass";
import {getCompensationDetails} from "../../utils/GeneralApi";
import HeaderComponent from "../../components/Header/HeaderComponent";
import Footer from "../../components/Footer/Footer";
import FooterPirate from "../../components/Footer/FooterElements/FooterPirate/FooterPirate";
import initializeTimelines from "../../old/scripts/timelines";
import ShowOnScroll from "../../old/scripts/show-on-scroll";

export default class KYCPage extends React.Component{

  constructor(props) {
    super(props);
    this.props = props;
    this.kycSigned = false;
    this.pageStatus = {
      showMainDescription: true,
      showConnectWallet: !walletContext.signed,
      verifyButtonDisabled: !walletContext.signed,
      showCheckMark: walletContext.signed && this.kycSigned,
      verifyButtonText: !this.kycSigned ? 'VERIFY' : 'VERIFIED'
    }
  }

  getDisplay(status){
    return status ? 'flex' : 'none';
  }

  getVisibility(visibility){
    return visibility ? 'visible' : 'hidden';
  }

  setUserPageForState = () => {

    getCompensationDetails(walletContext.currentWallet, walletContext.signature).then(res => {
       //this.kycSigned = res === KYC_VERIFIED.APPROVED;
      this.kycSigned = false;
      if(!walletContext.signed && !this.kycSigned){
        this.pageStatus.showMainDescription = true;
        this.pageStatus.showConnectWallet = true;
        this.pageStatus.verifyButtonDisabled = true;
        this.pageStatus.showCheckMark = false;
        this.pageStatus.verifyButtonText = "VERIFY";
      }else if(walletContext.signed && !this.kycSigned){
        this.pageStatus.showMainDescription = true;
        this.pageStatus.showConnectWallet = false;
        this.pageStatus.verifyButtonDisabled = false;
        this.pageStatus.showCheckMark = false;
        this.pageStatus.verifyButtonText = "VERIFY";
      }else if(walletContext.signed && this.kycSigned){
        this.pageStatus.showMainDescription = false;
        this.pageStatus.showConnectWallet = false;
        this.pageStatus.verifyButtonDisabled = false;
        this.pageStatus.showCheckMark = true;
        this.pageStatus.verifyButtonText = "VERIFIED";
      }
      this.setState(this.pageStatus);
    })
  }

  setKycVerified = () => {
    if(this.kycSigned){
      return;
    }
    this.kycSigned = true;
    this.setUserPageForState();
  }

  componentDidMount() {
    this.setUserPageForState();
    initializeTimelines();
    setTimeout(function () {
      var instShowOnScroll = new ShowOnScroll();
      instShowOnScroll.init();
    }, 250);
  }


  render() {
    return (
      <div>
        {/*<Topbar/>*/}
        <HeaderComponent  noBackground={true}/>
        <Container className='main-kyc-container' fluid>
          {/*<Row>*/}
          {/*  <hr className="downward-vee"/>*/}
          {/*</Row>*/}

          <div className='kyc-main-body'>
            <div className="kyc-video-container">
              <video
                className='kyc-video-inside'
                src={kycBG}
                loop
                autoPlay
                playsInline
                muted></video>
            </div>

            <div className='kyc-overlay-content'>
              <KYCBoarderHeader titleText={'KYC VERIFICATION'}/>
              <div style={{visibility: this.getVisibility(this.pageStatus.showMainDescription)}} className='kyc-description-box1'>
                <RoundedDescriptionBox
                  text={'In order to participate in the Planet Sale, you will need to complete a KYC (Know Your Client) check to verify your identity.\n' +
                    '        The KYC process helps to prevent money laundering, and is a mandatory process required by many countries.'}/>
              </div>

              <div style={{visibility: this.getVisibility(this.pageStatus.showConnectWallet)}}>
                <div className='kyc-delimiter-plus-container'>
                  <img className='kyc-delimiter-plus-image' src={plusSeparator} alt=""/>
                </div>

                <div className='kyc-description-box2'>
                  <RoundedDescriptionBox
                    text={'Please connect your wallet to begin KYC verification'}/>
                </div>

                <div className='kyc-connect-wallet-button-container'>
                  <div id='connectButton'>
                    <ConnectWalletButton/>
                  </div>
                </div>
              </div>


              <div className='kyc-verify-container'>
                <div style={{visibility: this.getVisibility(this.pageStatus.showCheckMark) }} className='kyc-verify-check-mark-container'>
                  <img src={check_mark} alt=""/>
                </div>
                <div className='kyc-verify-button-container'>
                  {
                    (walletContext.signed && this.kycSigned) || (!walletContext.signed && !this.kycSigned)?
                      <TiltedButton
                        clickEvent={() => {
                          this.setKycVerified()}} disabled={this.pageStatus.verifyButtonDisabled} text={this.pageStatus.verifyButtonText}/>
                      :
                      <div id='blockpassButton'>
                        <Blockpass disabled={false} buttonText={this.pageStatus.verifyButtonText + 's'}/>
                      </div>
                  }
                </div>
                <div className='kyc-description-box2'>
                  <RoundedDescriptionBox
                    bgColor={'transparent'}
                    fontSize={'12px'}
                    text={'Connect to get KYC-verified. If you\'ve previously been \n' +
                      'verified with Blockpass, you won\'t need to re-apply.'}/>
                </div>
              </div>

            </div>



          </div>
          <Footer>
            <FooterPirate/>
          </Footer>
        </Container>
      </div>
    );
  }

}
