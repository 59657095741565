import React, {useCallback, useContext, useEffect, useState} from 'react';
import { walletContext } from '../utils/WalletContext';
import { ERROR_TEXT } from '../utils/Globals';
import { IsOnCorrectChain } from '../components/SwitchNetworkChain';
import {useAccount, useConnect, useDisconnect, useNetwork, useSignMessage} from 'wagmi';
import jwt, { JwtPayload } from 'jsonwebtoken';
import {AuthContext, AuthenticationManager} from "../utils/auth";
import {Config, ConnectArgs, watchAccount} from "@wagmi/core";
import {P as PublicClient} from "@wagmi/core/dist/index-e744bbc2";
import {UseMutateFunction} from "@tanstack/react-query";
import {AuthenticationProvider} from "@pg/auth-api";

export type AuthResponse = {
  show: boolean,
  text: string,
  success?: boolean,
}

export type UseAuthProps = {
  handleAuthentication: () => void;
  isLoading: boolean;
  isCorrectChain: boolean;
  error: AuthResponse;
  setError: React.Dispatch<React.SetStateAction<AuthResponse>>;
  connector?: NonNullable<Config<PublicClient>['connector']>,
  isConnected: boolean;
  connect: (args?: (Partial<ConnectArgs> | undefined)) => void
  connectors: NonNullable<Config<PublicClient>['connector']>[],
  isConnecting: boolean;
  disconnect: UseMutateFunction<void, Error, void, unknown>;
  /**
   * Wallet has been connected, signed and validated
   */
  walletValidated: boolean;
  signingToken: string | JwtPayload | null;
};

export function useAuthentication(): UseAuthProps {
  const [isCorrectChain, setIsCorrectChain] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AuthResponse>({ show: false, text: '' });
  const [signingToken, setSigningToken] = useState<string | JwtPayload | null>(null);
  const [walletValidated, setWalletValidated] = useState(walletContext.signed);

  const authManager = AuthenticationManager.instance;
  const authData = useContext(AuthContext);

  const network = useNetwork();

  const handleChainNetwork = useCallback(
    (interval?: NodeJS.Timeout) => {
      const isCorrectChain = IsOnCorrectChain(network);
      setIsCorrectChain(isCorrectChain ?? false);
      if (isCorrectChain) {
        clearInterval(interval);
      }
    },
    [network],
  );

  useEffect(() => {
    handleChainNetwork();
  }, [handleChainNetwork, isCorrectChain]);

  const {connector, isConnected} = useAccount(
    {
      async onConnect({ address, connector, isReconnected }) {
        if(isReconnected) {
          // we should confirm that the connected wallet matches the wallet linked to the account (if any)
          if (authData?.providers?.find(provider => provider.provider === AuthenticationProvider.Blockchain)?.providerId.toLowerCase() !== address?.toLowerCase()) {
            // the connected wallet does not match the linked wallet, so we should disconnect the wallet
            disconnect();
            setError({
              show: true,
              text: ERROR_TEXT.WALLET_DOESNT_MATCH,
            });
            return;
          }
          walletContext.setCurrentWallet(address, connector?.name);
          return;
        }

        if (!walletContext.signed && address) {
          const signingToken = await authManager.requestSigningToken(address);
          setSigningToken(signingToken);
          const decodeSigningToken = jwt.decode(signingToken);
          walletContext.setCurrentWallet(address, connector?.name);
          signMessage({message: (decodeSigningToken as any)?.challenge});
        }
      },
      onDisconnect() {
        // Wallet disconnected, clear our wallet context data
        // TODO Will we allow the option to disconnect a wallet without logging out?
        walletContext.clearPgWallet();
        // authManager.logout();
      }
    }
  );

  const { connect, connectors, isLoading: isConnecting} = useConnect();
  const { disconnect } = useDisconnect();

  const { signMessage } = useSignMessage({
    async onSuccess(data) {
      // if I'm already authenticated, and we don't have a linked wallet, we want to link the wallet to the account
      if (authManager.isAuthenticated()) {
        const accessToken = authManager.getAccessTokenPayload();
        const blockchainProvider = accessToken?.providers?.find(provider => provider.provider === AuthenticationProvider.Blockchain);
        if (!blockchainProvider) {
          await authManager.linkWallet(walletContext.currentWallet, signingToken as string, data);
          setError({ show:true, success: true, text: `Successfully linked your wallet` });
        } else {
          // confirm the wallet matches that wallet that is linked to the account
          if (blockchainProvider.providerId.toLowerCase() !== walletContext.currentWallet?.toLowerCase()) {
            disconnect();
            setError({
              show: true,
              text: ERROR_TEXT.WALLET_DOESNT_MATCH,
            });
            setSigningToken(null);
            return;
          }
        }
      } else {
        await authManager.requestAccessToken(walletContext.currentWallet, signingToken as string, data);
      }
      //Just finished signing message - save signature and navigate page on now
      walletContext.setSignature(data, true);
      setWalletValidated(true);
      setSigningToken(null);
      setIsLoading(false);
    },
    onError(error: any) {
      setIsLoading(false);
      setSigningToken(null);
      disconnect();
      if (error.response.data?.error === 'not_eligible') {
        setError({
          show: false,
          text: ERROR_TEXT.WALLET_NOT_ELIGIBLE,
        });
      } else if (error.response.data.message.includes('Wallet') && error.response.data.message.includes('mismatch')) {
        setError({
          show: true,
          text: ERROR_TEXT.WALLET_MISMATCH,
        });
      } else {
        setError({ show: true, text: ERROR_TEXT.GENERIC_ERROR });
      }
    },
  });

  const handleAuthentication = async () => {
    handleChainNetwork();

    if (!isCorrectChain) {
      return;
    }

    setIsLoading(true);

    const signingToken = await authManager.requestSigningToken(walletContext.currentWallet);
    const decodeSigningToken = jwt.decode(signingToken);

    setSigningToken(signingToken);
    signMessage({ message: (decodeSigningToken as any)?.challenge });
  };

  useEffect(() => {
    // this will listen for changes to the account in the connected wallet
    const unwatch = watchAccount(account => {
      if(account.address && account.connector) {
        //If you haven't yet signed to authenticate, then use the new wallet
        if(!walletContext.signed) {
          walletContext.setCurrentWallet(account.address, account.connector.name);
        }
      }
    });

    // Cleanup by calling unwatch to unsubscribe from the account change event
    return () => unwatch();
  }, []);

  return { handleAuthentication, isLoading, isCorrectChain, error, setError, connector, isConnected, connect, connectors, isConnecting, disconnect, walletValidated, signingToken };
}
