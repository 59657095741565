import React from "react";
import "./ConfirmationModal.scss";
import TiltedButton from "../../TiltedButton/TiltedButton";
import {Modal} from "react-bootstrap";
import {closeIcon} from "../../../assets/images/icons";
import {claimEpisode1, confirmationModal} from "../../../utils/GeneralUtils";
import {confirmation_pop_up_bottem_horns, confirmation_pop_up_header_horns} from "../../../assets/images/PopUps";

export default function ConfirmationModal(props) {


  return <Modal
    {...props}
    show={props.show}
    aria-labelledby="contained-modal-title-vcenter"
    centered
    dialogClassName='CM-Dimension'>
    <img alt="" className={"CM-header-horns"} src={confirmation_pop_up_header_horns}/>
    <div className={"CM-modal-container"}>
      <div className={"CM-img-container"}>
        <img alt="" className={"CM-close-button"} onClick={props.onHide} src={closeIcon}/>
      </div>
      <div className={"CM-text-container"}>
        <p className={"CM-transaction-text"}>{confirmationModal.transaction}</p>
        <p className={"CM-process-description"}>{claimEpisode1.processDescription}</p>
      </div>
      <div className={"CM-button-container"}>
        <div className={"CM-confirmation-button"} onClick={props.onHide}>
          <TiltedButton text={"Ok"}/>
        </div>
      </div>
    </div>
    <img alt="" className={"CM-bottem-horns"} src={confirmation_pop_up_bottem_horns}/>
  </Modal>
}
