import React from 'react';

import {walletContext} from '../utils/WalletContext'
import "../routes/ConnectWallet.scss";


export default class ConnectWalletButton extends React.Component {

  // TODO: determine where gtags are needed
  // window.gtag("event", "connectWallet");

  render() {
    return (
      <>

        {!walletContext.signed &&
          <div className={"nav_links-buttons-border"}>
            <div className={"connect-wallet-button-design"} onClick={() => window.location.href = '/connect-wallet'}>
              <div className="connect-wallet">
                CONNECT WALLET
              </div>
            </div>
          </div>
        }

        {(walletContext.signed) &&
          <>
            <div className={"nav_links-buttons-border"}>
            <div className={"connect-wallet-button-design-logged-in"}>
            <div className="">
              {
                walletContext.currentWallet.substring(0, 9)
                + "..." +
                walletContext.currentWallet.substring(walletContext.currentWallet.length - 4)
              }
            </div>
            </div>
            </div>
          </>
        }
      </>
    );
  }
}
