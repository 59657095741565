import './GenerativePlanetMintpassGrid.scss';
import GenerativePlanetComponent from '../GenerativePlanetComponent/GenerativePlanetComponent';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';

export default function GenerativePlanetMintpassGrid({ scrollToInfo, mintpassGen, isCatalyst, selected, selectHandler, planetMintpasses, isBatch }) {
  const planetCounterHandler = (tokenId, count) => {
    const toUpdate = planetMintpasses.find(type => type.tokenId === tokenId);
    toUpdate.count = count;

    const updatedValues = [...planetMintpasses];

    selectHandler(updatedValues);
  };

  const getTotalPlanets = () => {
    let totalPlanets = 0;
    planetMintpasses.forEach(mintpass => {
      totalPlanets += mintpass.count;
    });
    return totalPlanets;
  };

  return (
    <div className={'generative-planet-mintpass-container'}>
      <div className={'generative-planet-mintpass-title'}>SELECT GEN {mintpassGen} PLANET MINTPASS</div>
      <div onClick={scrollToInfo} className={'generative-planet-mintpass-helper'}>
        (How do I mint a planet?)
      </div>
      {
        <div className={isBatch ? 'generative-planet-mintpass-grid batch' : 'generative-planet-mintpass-grid'}>
          {planetMintpasses.map(planetMintpass => (
            <GenerativePlanetComponent
              isCatalyst={isCatalyst}
              title={planetMintpass.title}
              stock={planetMintpass.stock}
              count={planetMintpass.count}
              imgSrc={planetMintpass.image}
              totalPlanets={getTotalPlanets()}
              onClick={isCatalyst ? selectHandler.bind(this, planetMintpass.tokenId) : planetCounterHandler.bind(this, planetMintpass.tokenId)}
              selected={selected === planetMintpass.tokenId}
              openseaUrl={planetMintpass.openseaUrl}
              mintpassGen={mintpassGen}
            />
          ))}
        </div>
      }
      {!isBatch && (
        <div className={'generative-planet-mintpass-slider'}>
          <Swiper slidesPerView={3} slidesPerGroup={1} spaceBetween={-80} className="planet-swiper">
            <SwiperSlide></SwiperSlide>
            {planetMintpasses.map(planetMintpass => (
              <SwiperSlide>
                <GenerativePlanetComponent
                  isCatalyst={isCatalyst}
                  title={planetMintpass.title}
                  stock={planetMintpass.stock}
                  imgSrc={planetMintpass.image}
                  totalPlanets={getTotalPlanets()}
                  onClick={isCatalyst ? selectHandler.bind(this, planetMintpass.tokenId) : planetCounterHandler.bind(this, planetMintpass.tokenId)}
                  selected={selected === planetMintpass.tokenId}
                  openseaUrl={planetMintpass.openseaUrl}
                />
              </SwiperSlide>
            ))}
            <SwiperSlide></SwiperSlide>
          </Swiper>
        </div>
      )}
    </div>
  );
}
