import './PlanetsFilterButton.scss'
import {useState} from "react";

export default function PlanetsFilterButton({filterCallback, title}) {
  const [selected, setSelected] = useState(false)
  let filterButtonHandler = () => {
    setSelected(!selected)
    filterCallback();
  }

  return <div className={'planets-filter-button-container'}>
    <div onClick={filterButtonHandler}
         className={'planets-filter-button'}>
      {title}
    </div>
    <div className={!selected ? 'planets-filter-button-decoration' : 'planets-filter-button-decoration selected'}/>
  </div>
}
