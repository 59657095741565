import React from "react";
import "./FooterPirate.scss";
import {circle_pattern_lady_img, corp_pirate_img} from "../../../../assets/images/Footer";
import {astrafite_small_pirate} from "../../../../assets/images/AstrafiteRushEvent";

export default function FooterPirate() {

  return (<>
    <div className={"pirate-img-main-container"} show-on-scroll="true">
      <img alt={""} src={circle_pattern_lady_img} className={"pirate-circle-pattern"}/>
      <img alt={""} src={corp_pirate_img} className={"footer-pirate-img"}/>
      <img alt={""} src={astrafite_small_pirate} className={"footer-pirate-img-small"}/>
    </div>
  </>)
}
