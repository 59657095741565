import React, { useContext, useState } from 'react';
import './AstrafiteRushTitle.scss';
import {
  astrafite_title_mobile_background,
  astrafite_title_tablet_background,
  pirate_img,
  space_ship,
  title_img_background,
} from '../../../assets/images/AstrafiteRushEvent';
import PageHeader from '../../PageHeader';
import checkForGameRules, { astrafiteRushEvent4, astrafiteRushEvent4Ended, redirectTermsAndConditions } from '../../../utils/GeneralUtils';
import CarouselModal from '../../CarouselModal/CarouselModal';
import { AstrafiteRushContext } from '../../Context/AstrafiteRushContext';
import ClaimButton from '../../ClaimRewards/ClaimButton/ClaimButton';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../LoadingSpinner';
import { AfrContext } from '../../../context/AfrContext';

export default function AstrafiteRushTitle({ walletConnected }) {
  const navigate = useNavigate();
  const astrafiteRushContext = useContext(AstrafiteRushContext);
  const { hasClaimData, isClaimDataLoading } = useContext(AfrContext);

  const [showCarousel, setShowCarousel] = useState(false);

  function claimRewardsHandler() {
    navigate('/rewards-claim');
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }

  return (
    <>
      <CarouselModal
        show={showCarousel}
        onHide={() => {
          setShowCarousel(false);
        }}
      />

      <div className={'rush-event-title-started'}>
        <img alt={''} className={'rush-event-title-background-started'} src={title_img_background} />
        <img alt={''} className={'rush-event-title-background-started-mobile'} src={astrafite_title_mobile_background} />
        <img alt={''} className={'rush-event-title-background-started-tablet'} src={astrafite_title_tablet_background} />
        <img alt={''} className={'space-ship-adjustment'} src={space_ship} />
        <div className={'title-elements-container'}>
          <div className={'astrafite-title'}>
            <PageHeader headerText="ASTRAFITE RUSH EVENT" />
            <p className={'astrafite-episode-number'}>EPISODE 4 - ENDED</p>
          </div>
          <>
            <img alt={''} className={'pirate-img-position-event-started'} src={pirate_img} />
            <div className={'event-started-description-text '}>
              <div className={'paragraph-background-design'}>
                {astrafiteRushContext.eventStatus ? astrafiteRushEvent4.paragraph1 : astrafiteRushEvent4Ended.paragraph1}
              </div>

              {astrafiteRushContext.eventStatus ? (
                <div className={'paragraph-background-design padding-adjustment-for-text-alignment'}>{astrafiteRushEvent4.paragraph2}</div>
              ) : (
                <div className={'paragraph-background-design'}>{astrafiteRushEvent4Ended.paragraph2}</div>
              )}
              <div className={'paragraph-background-design padding-remover'}>
                {astrafiteRushContext.eventStatus
                  ? checkForGameRules(() => {
                      setShowCarousel(true);
                    })
                  : redirectTermsAndConditions()}
              </div>
            </div>
            {isClaimDataLoading && <LoadingSpinner marginTop={'54px'} />}
            {!isClaimDataLoading && (
              <div className={'claim-button-container'}>
                <ClaimButton
                  isActive={hasClaimData}
                  activeText={'CLAIM REWARDS'}
                  disabledText={'CLAIM REWARDS'}
                  handler={() => {
                    claimRewardsHandler();
                  }}
                />
              </div>
            )}
          </>
        </div>
      </div>
    </>
  );
}
