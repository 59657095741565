import React from "react";
import HeaderComponent from "../Header/HeaderComponent";
import LeaderBoardSection from "../LeaderBoardSection/LeaderBoardSection";
import {useLocation} from "react-router-dom";
import BottomComponent from "../Footer/FooterElements/BottomComponent/BottomComponent";

export default function LeaderBoardPage() {

  const search = useLocation().search;
  const cyclePage = new URLSearchParams(search).get('cycle');
  return (<>
    <HeaderComponent/>
    <LeaderBoardSection cyclePage={cyclePage}/>
    <BottomComponent/>
  </>)
}
