import React, {useContext} from "react";
import "./CounterButtonElement.scss";
import {PlanetSaleContext} from "../../../../../../../components/Context/PlanetSalePageContext";

export default function CounterButtonElement(props) {

  const planetSaleContext = useContext(PlanetSaleContext);

  function nftCounter() {
    if (props.text === "-") {
      if (props.numberOfElement > 0) {
        planetSaleContext.increaseSaleNftNo(props.index, -1, props.sellType);
      }
    } else {
      if (props.numberOfElement < props.nftAllowedToMint) {
        planetSaleContext.increaseSaleNftNo(props.index, 1, props.sellType);
      }
    }

  }

  return <>
    <div className={props.enableButton ? "counter-button-design" : "counter-button-design-inactive"}
         onClick={nftCounter}>
      <div className={"cbe-center-text"}>
        {props.text}
      </div>
    </div>
  </>
}
