import './PlanetExplorationMissionList.scss';
import MissionTab from '../../MissionTab/MissionTab';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { checkbox_checked, exclamation_icon, exploration_landing_image, missions_mobile_image_v2 } from '../../../assets';
import PlanetExplorationTimer from '../PlanetExplorationTimer/PlanetExplorationTimer';
import MicroGame from '../../MicroGame/MicroGame';
import {
  CHECKPOINT_STATUS,
  MISSION_STATUS,
  MISSION_TAB_DISPLAY_TEXT,
  MissionStatusIndex,
} from '../PlanetExplorationContent/PlanetExplorationContent';
import { findCurrentCheckpoint, findMissionTabContent, findNextCheckpoint } from '../../../utils/GeneralUtils';
import { useNavigate } from 'react-router-dom';
import PGModal from '../../Common/PGModal/PGModal';
import GenericButton from '../../Button/GenericButton';
import LoadingSpinner from '../../LoadingSpinner';
import SuccessMessage from '../../Common/SuccessMessage/SuccessMessage';
import { endReconMission } from '../../../utils/ContractUtils';
import { getBlockchainContract } from '../../../utils/providerObject';
import CeresQuadrantMissionsAbi from '@fugu/base-contracts/dist/abis/Games/CeresQuadrantMissions.sol/CeresQuadrantMissions.json';
import { sendMissionEntryClaimRewards } from '../../../utils/ApiCalls';
import { useProvider } from '../../../hooks/useProvider';

export default function PlanetExplorationMissionList({
  missionEntries,
  activeMissionEntry,
  handleActiveMission,
  hasOverlay,
  handleCheckpoint,
  isOwner,
  isPlayOnBehalf,
}) {
  const [selectedMissionEntry, setSelectedMissionEntry] = useState(null);
  const [showGame, setShowGame] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isGameModalOpen, setIsGameModalOpen] = useState(false);
  const [isGameActive, setIsGameActive] = useState(false);
  const [currentCheckpoint, setCurrentCheckpoint] = useState(null);
  const [nextCheckpoint, setNextCheckpoint] = useState(null);
  const [showRewardModal, setShowRewardModal] = useState(false);
  const [isRewardClaimLoading, setIsRewardClaimLoading] = useState(false);
  const [showRewardSuccess, setShowRewardSuccess] = useState(false);
  const [showRewardError, setShowRewardError] = useState(false);

  const provider = useProvider();
  const missionTabScrollRef = useRef(null);

  const navigate = useNavigate();

  const isMobileDevice = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);


  const handleKeyPress = event => {
    if (event.key === 'f' || event.key === 'F') {
      setIsFullScreen(prevIsFKeyPressed => !prevIsFKeyPressed);
    }

    if (event.key === 'Escape') {
      setIsFullScreen(false);
    }
  };

  const handleFullscreenChange = () => {
    // Check if we are currently in fullscreen mode
    const fullscreenElement =
      document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;
    setIsFullScreen(!!fullscreenElement);
  };

  const handleGameOpenModal = () => {
    setIsGameModalOpen(true);
  };

  const handleGameCloseModal = () => {
    setIsGameModalOpen(false);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    // Add event listener for fullscreen change when the component mounts
    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [isFullScreen]);

  // Create a function to calculate state values
  const calculateStateValues = useCallback(
    (missionEntry, checkpoints) => {
      if (!missionEntry || Object.keys(missionEntry).length <= 1) {
        return {};
      }
      const checkpoint = findCurrentCheckpoint(checkpoints);
      let nextCheckpoint;

      if (checkpoint?.checkinCompleted) {
        nextCheckpoint = findNextCheckpoint(checkpoints, checkpoint.checkpointNumber + 1);
      }

      if (!checkpoint) {
        return {
          selectedMissionEntry: missionEntry,
          showGame: false,
        };
      }

      let updatedShowGame;

      if (isOwner && !isPlayOnBehalf) {
        updatedShowGame = new Date(missionEntry?.start) < Date.now() && !checkpoint.checkinCompleted;
      } else if (!isOwner && isPlayOnBehalf) {
        updatedShowGame = new Date(missionEntry?.start) < Date.now() && !checkpoint.checkinCompleted;
      }

      return {
        selectedMissionEntry: missionEntry,
        currentCheckpoint: checkpoint,
        showGame: updatedShowGame ?? false,
        nextCheckpoint: nextCheckpoint ?? checkpoint,
      };
    },
    [isOwner, isPlayOnBehalf],
  );

  useEffect(() => {
    const stateValues = calculateStateValues(activeMissionEntry, activeMissionEntry?.checkpoints);

    if (Object.keys(stateValues).length > 0) {
      setSelectedMissionEntry(stateValues.selectedMissionEntry);
      setCurrentCheckpoint(stateValues.currentCheckpoint);
      setShowGame(stateValues.showGame);
      setNextCheckpoint(stateValues.nextCheckpoint);
    }

    scrollToActiveMissionEntryTab();
  }, [activeMissionEntry, calculateStateValues]);

  const onSelectMissionEntry = missionEntry => {
    if (isGameActive) {
      return;
    }

    // This navigate should not be visual to the user - we're updating the state in the location with the selected mission id
    navigate('/portfolio/tcq-recon/view-mission', { state: { selectedMissionId: missionEntry?.id } });
    sessionStorage.setItem('current-mission-entry', missionEntry?.id);

    handleActiveMission(missionEntry);

    const stateValues = calculateStateValues(missionEntry, missionEntry.checkpoints);

    setSelectedMissionEntry(stateValues.selectedMissionEntry);
    setCurrentCheckpoint(stateValues.currentCheckpoint);
  };

  const handleCheckpointCompletion = () => {
    handleCheckpoint(selectedMissionEntry?.missionId, selectedMissionEntry?.id, currentCheckpoint.id, true);
  };

  const missionTabContent = missionEntry => {
    if (selectedMissionEntry && selectedMissionEntry?.id === missionEntry.id) {
      if (isGameActive) {
        return MISSION_TAB_DISPLAY_TEXT.IN_PROGRESS;
      }
    }

    return findMissionTabContent(missionEntry);
  };

  const missionTabClassName = missionEntry => {
    if (selectedMissionEntry && selectedMissionEntry.id === missionEntry.id) {
      return 'planet-exploration-mission-tab selected';
    }

    if (isGameActive) {
      return 'planet-exploration-mission-tab game-active';
    }

    return 'planet-exploration-mission-tab';
  };

  // When a user has more missions than the mission tab container can fit, it will overflow with a scroll bar.
  // This will scroll to the mission tab element that the user selected, launched or last accessed.
  function scrollToActiveMissionEntryTab() {
    // Scroll to the element
    if (missionTabScrollRef && missionTabScrollRef.current) {
      const rect = missionTabScrollRef.current.getBoundingClientRect();
      const offset = rect.top;

      missionTabScrollRef?.current.scrollIntoView({
        behavior: 'smooth', // You can also use 'auto' for instant scrolling
        block: 'start', // This can be 'start', 'center', or 'end'
        inline: 'nearest', // This can be 'start', 'center', or 'end'
      });

      window.scrollBy(0, -offset);
      window.scroll(0, 450);
    }
  }

  const isLastCheckpointCompleted = () => {
    if (!selectedMissionEntry) return false;
    const totalCheckpoints = selectedMissionEntry?.checkpoints.length;
    const lastCheckpoint = selectedMissionEntry?.checkpoints.find(cp => cp.checkpointNumber === totalCheckpoints);
    return lastCheckpoint.status === CHECKPOINT_STATUS.COMPLETED;
  };

  const rewardPromptHandler = () => {
    setShowRewardModal(true);
  };

  const claimRewardHandler = async () => {
    setIsRewardClaimLoading(true);
    try {
      const cqMissionEndData = await sendMissionEntryClaimRewards(selectedMissionEntry?.id, selectedMissionEntry?.missionId);
      // Set a timeout for handleMissionRewardClaimSignature
      const transactionHash = await handleMissionRewardClaimSignature(cqMissionEndData);
      // Check if transactionHash is undefined, meaning the timeout occurred
      if (transactionHash) {
        setShowRewardSuccess(true);
      }
      setIsRewardClaimLoading(false);
    } catch (e) {
      setShowRewardError(true);
      setShowRewardSuccess(false);
    }
  };

  const handleMissionRewardClaimSignature = async blockchainData => {
    const [chainId, missionId, user, delegatedPlayerWallet, missionPlanets, missionEntryID, reward, planetMintpassRewards] =
      blockchainData.structArrays[0];
    const missionData = [chainId, missionId, missionEntryID, reward, user, delegatedPlayerWallet, missionPlanets, planetMintpassRewards];

    const blockChainContract = getBlockchainContract(process.env.REACT_APP_CERES_QUADRANT_MISSION_ADDRESS, CeresQuadrantMissionsAbi.abi, provider);
    const transactionHash = await endReconMission(blockChainContract, missionData, blockchainData.signatures[0]);
    return transactionHash;
  };

  const RewardModal = () => {
    return (
      <>
        <PGModal
          show={showRewardModal}
          onHide={() => {
            setShowRewardModal(false);
            setShowRewardSuccess(false);
          }}
        >
          <div className="ceres-quadrant__modal-content-container">
            {!isRewardClaimLoading && !showRewardSuccess && (
              <>
                <p>
                  Your Mission is complete! This strange and perilous pocket of the universe is a little brighter. Hermesium gleaned from the Ceres
                  Quadrant fetches generous prices.
                </p>
                <p>Click below to claim your rewards.</p>

                <GenericButton handleClick={() => claimRewardHandler()} buttonText={'CLAIM'} buttonClass="ceres-quadrant__modal-button" />
              </>
            )}
            {isRewardClaimLoading && !showRewardError && (
              <>
                <LoadingSpinner marginBottom={'16px'} />
              </>
            )}
            {showRewardSuccess && (
              <>
                <img alt="a tick inside of circle" src={checkbox_checked} className="ceres-quadrant__modal-success-icon" />
                <SuccessMessage>
                  <div className={'ceres-quadrant__modal-success-text-container'}>
                    <p>
                      Your claim for your rewards has been received! The transfer of your ASTRAFER may take a little time to show up in your wallet.
                    </p>
                    <p>You can now Unbind your Planets in the Planet Dashboard, so they may be moved, sold or used for further Missions.</p>
                  </div>
                </SuccessMessage>
                <GenericButton
                  handleClick={() => {
                    setShowRewardModal(false);
                    setShowRewardSuccess(false);
                  }}
                  buttonText="close"
                  buttonClass="planet-dashboard__modal-button"
                />
              </>
            )}
            {showRewardError && !showRewardSuccess && (
              <>
                <img alt="a exclamation sign inside a hexagon" src={exclamation_icon} className="modal-error-icon" />
                <p>There was an error claiming your rewards. Please try again later.</p>
                <GenericButton
                  handleClick={() => {
                    setShowRewardModal(false);
                    setShowRewardError(false);
                    setShowRewardSuccess(false);
                    setIsRewardClaimLoading(false);
                  }}
                  buttonText="close"
                />
              </>
            )}
          </div>
        </PGModal>
      </>
    );
  };

  return (
    <>
      {missionEntries && missionEntries.length >= 1 ? (
        <div className={'planet-exploration-missions-main-container'}>
          {missionEntries.length > 1 && (
            <div className={'planet-exploration-missions-left-container'}>
              {missionEntries
                .sort((a, b) => MissionStatusIndex[missionTabContent(a)] - MissionStatusIndex[missionTabContent(b)])
                .map((entry, index) => (
                  <div key={index} onClick={() => onSelectMissionEntry(entry)} className={missionTabClassName(entry)}>
                    <MissionTab
                      title={`MISSION ${entry.id}`}
                      content={missionTabContent(entry)}
                      key={index}
                      missionRef={entry.id === activeMissionEntry?.id ? missionTabScrollRef : undefined}
                    />
                  </div>
                ))}
            </div>
          )}
          <div className={hasOverlay ? 'planet-exploration-missions-right-container overlay' : 'planet-exploration-missions-right-container'}>
            {showGame && !isMobileDevice && (
              <div
                className={
                  isFullScreen ? 'planet-explorations-missions-micro-game-container-fullscreen' : 'planet-explorations-missions-micro-game-container'
                }
              >
                <MicroGame
                  key={selectedMissionEntry?.id}
                  handleIsGameActive={setIsGameActive}
                  handleCheckpointCompletion={handleCheckpointCompletion}
                />
              </div>
            )}
            {!showGame && (
              <>
                <img
                  src={exploration_landing_image}
                  style={{ width: '100%' }}
                  alt="Xanorra Slave Barge Nft"
                  className={'planet-exploration-missions-image'}
                />
              </>
            )}
            {isMobileDevice && isGameModalOpen && (
              <MicroGame
                handleIsGameActive={setIsGameActive}
                handleCheckpointCompletion={handleCheckpointCompletion}
                isMobile={isMobileDevice}
                onClose={handleGameCloseModal}
              />
            )}
            {isMobileDevice && !isGameModalOpen && (
              <>
                <img
                  src={missions_mobile_image_v2}
                  style={{ width: '100%' }}
                  alt="Xanorra Slave Barge Nft"
                  className={showGame ? 'planet-exploration-missions-image-mobile' : 'planet-exploration-missions-image-mobile-faded'}
                />
                {showGame && (
                  <span className={'planet-exploration-micro-game-mobile-launch-text'} onClick={handleGameOpenModal}>
                    Click to Play
                  </span>
                )}
              </>
            )}
            {hasOverlay && isPlayOnBehalf && isOwner && (
              <span className={'planet-exploration-missions-right-container-text'}>You have nominated a wallet to play on your behalf.</span>
            )}
            {selectedMissionEntry?.rewardsAvailable && (
              <button className={'planet-exploration-claim-button'} onClick={rewardPromptHandler}>
                CLAIM REWARDS
              </button>
            )}
            {isLastCheckpointCompleted() && !selectedMissionEntry?.rewardsAvailable && (
              <div className={'planet-exploration-timer-container'}>
                <PlanetExplorationTimer description={'Rewards available in:'} nextRewardTime={new Date(activeMissionEntry?.end)} />
              </div>
            )}
            {!showGame && selectedMissionEntry && new Date() < new Date(nextCheckpoint?.start) && (
              <div className={'planet-exploration-timer-container'}>
                <PlanetExplorationTimer description={'Next game starts in:'} nextRewardTime={new Date(nextCheckpoint?.start)} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={hasOverlay ? 'planet-exploration-missions-right-container overlay' : 'planet-exploration-missions-right-container'}>
          <img src={exploration_landing_image} style={{ width: '100%' }} alt="Xanorra Slave Barge Nft" />
          {hasOverlay && (
            <span className={'planet-exploration-missions-right-container-text'}>You have nominated a wallet to play on your behalf.</span>
          )}
          {selectedMissionEntry?.rewardsAvailable && (
            <button className={'planet-exploration-claim-button'} onClick={rewardPromptHandler}>
              CLAIM REWARDS
            </button>
          )}
          {isLastCheckpointCompleted() && !selectedMissionEntry?.rewardsAvailable && selectedMissionEntry?.status === MISSION_STATUS.ACTIVE && (
            <div className={'planet-exploration-timer-container'}>
              <PlanetExplorationTimer description={'Rewards available in:'} nextRewardTime={new Date(activeMissionEntry?.end)} />
            </div>
          )}
          {selectedMissionEntry && (
            <div className={'planet-exploration-timer-container'}>
              <PlanetExplorationTimer description={'Next game starts in:'} nextRewardTime={new Date(activeMissionEntry?.start)} />
            </div>
          )}
        </div>
      )}
      {showRewardModal && <RewardModal />}
    </>
  );
}
