import { useAccount } from 'wagmi';
import { useEffect, useState } from 'react';

export function useProvider() {
  const { connector } = useAccount();
  const [provider, setProvider] = useState(undefined);

  useEffect(() => {
    const getProvider = async () => {
      const thisProvider = await connector?.getProvider();

      setProvider(thisProvider);
    };
    getProvider()
        .catch(console.error);
  });
  return provider;
}
