import React from "react";
import "./EpisodeMedalNFT.scss"

export default function EpisodeMedalNFT(props) {

  return <>
    <div className={"nft-unlocked"}>
      <div className={"nft-unlocked-image"}>
        <img className={props.enable?"nft-disable-img set-tablet-width":"set-tablet-width"} src={props.image} alt=""/>
        <div className={"nft-unlocked-image-overlay-container"}>
          <div className={"nft-unlocked-image-overlay"}>
            <div className={"nft-unlocked-image-overlay-text"}>
              x{props.owned} Owned
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
}
