import React, { useEffect, useState } from 'react';
import './AstrafiteRushEvent.scss';
import HeaderComponent from '../components/Header/HeaderComponent';
import Footer from '../components/Footer/Footer';
import AstrafiteRushTitle from '../components/AstrafiteRushEvent/AstrafiteRushTitle/AstrafiteRushTitle';
import { AstrafiteRushProvider } from '../components/Context/AstrafiteRushContext';
import FooterPirate from '../components/Footer/FooterElements/FooterPirate/FooterPirate';
import initializeTimelines from '../old/scripts/timelines';
import ShowOnScroll from '../old/scripts/show-on-scroll';
import TermsAndConditionsModal from '../components/Modal/TermsAndConditionsModal/TermsAndConditionsModal';
import ErrorModal from '../components/Modal/ErrorModal/ErrorModal';
import { errorText } from '../utils/GeneralUtils';
import { useNavigate } from 'react-router-dom';
import { getAccountSettings } from '../utils/ApiCalls';
import { walletContext } from '../utils/WalletContext';
import { useIsCorrectChain } from '../hooks/useIsCorrectChain';
import SwitchNetworkChainSection from '../components/SwitchNetworkChain';
import { useNetwork } from 'wagmi';
import PageHeader from '../components/PageHeader';

export default function AstrafiteRushEvent() {
  const navigate = useNavigate();
  const isCorrectChain = useIsCorrectChain();
  const network = useNetwork();
  const [termsAndConditionAgreement, setTermsAndConditionAgreement] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showTable, setShowTable] = useState({ showTable: false, showErrorModal: false });

  function componentDidMount() {
    initializeTimelines();
    setTimeout(function () {
      var instShowOnScroll = new ShowOnScroll();
      instShowOnScroll.init();
    }, 250);
  }

  useEffect(() => {
    componentDidMount();
    if (walletContext.currentWallet && walletContext.signed) {
      const getTC = async () => {
        try {
          const settings = await getAccountSettings();

          if (settings && settings.length > 0) {
            const isAfrTncAccepted = settings.some(s => s.type === 'AFR_TNC');

            setTermsAndConditionAgreement(isAfrTncAccepted);
            localStorage.setItem('pg.tnc.afr.agree', isAfrTncAccepted);
          }
          setIsLoading(false);
        } catch (e) {
          console.error(e);
          setShowTable(prevState => ({
            ...prevState,
            ...{ showErrorModal: true },
          }));
        }
      };
      getTC().then();
    } else {
      let tcResponse = localStorage.getItem('pg.tnc.afr.agree');
      if (tcResponse === 'true') {
        setTermsAndConditionAgreement(true);
      }
      if (tcResponse === 'false') {
        setTermsAndConditionAgreement(false);
      }
      setIsLoading(false);
    }
  }, []);

  function hideErrorModal() {
    setShowTable(prevState => ({
      ...prevState,
      ...{ showErrorModal: false },
    }));
    navigate('/');
  }

  return (
    <>
      <HeaderComponent noBackground={true} />
      {showTable.showErrorModal && <ErrorModal onHide={() => hideErrorModal()} text={errorText.defaultError} errorType={'defaultError'} />}
      {!isCorrectChain && (
        <>
          <div className={'astrafite-title'}>
            <PageHeader headerText="ASTRAFITE RUSH EVENT" />
            <p className={'astrafite-episode-number'}>EPISODE 4 - ENDED</p>
          </div>
          <SwitchNetworkChainSection network={network} message={`You must be on the ${process.env.REACT_APP_CHAINID} network to mint NFTs`} />
        </>
      )}
      {isCorrectChain && (
        <AstrafiteRushProvider>
          <AstrafiteRushTitle />
          {!termsAndConditionAgreement && !isLoading && <TermsAndConditionsModal visible={true} setTerms={setTermsAndConditionAgreement} />}
        </AstrafiteRushProvider>
      )}
      <div className={'astrafite-footer-route'}>
        <Footer>
          <FooterPirate />
        </Footer>
      </div>
    </>
  );
}
