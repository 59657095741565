import "./GenerativeTraitGrid.scss";
import { no_trait_icon } from "../../../assets/images/GenerativePlanetMinting";
import GenerativePlanetTrait from "../GenerativeStarfighterTrait/GenerativeStarfighterTrait";
import { useEffect, useState } from "react";
import { buyOnOpenSea, NO_TRAIT } from "../../../utils/GeneralUtils";
import BuyOnOpenSea from "../../NFTComponents/BuyOnOpenSea/BuyOnOpenSea";

export default function GenerativeTraitGrid({
  traits,
  isDisabled,
  selectHandler,
  catalysts,
  isCatalystFlow,
}) {
  const isNoTraitSelected = () => {
    let selectedCatalyst = catalysts.find((catalyst) => catalyst.selected);
    return selectedCatalyst ? selectedCatalyst.traitSelected : false;
  };

  const getBiomeText = () => {
    if (!isDisabled) return "REMOVE";
  };

  const [noTraitSelected, setNoTraitSelected] = useState(isNoTraitSelected());

  useEffect(() => {
    setNoTraitSelected(isNoTraitSelected());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalysts]);

  const noTraitHandler = () => {
    if (isDisabled) return;
    selectHandler(NO_TRAIT);
  };
  return (
    <div className={"generative-traits-container"}>
      <div
        className={`generative-traits-title-container ${
          !isCatalystFlow ? "disabled-title-trait" : ""
        }`}
      >
        <div className={"generative-traits-title-text"}>SELECT TRAITS</div>
      </div>
      <div className={"generative-traits-grid"}>
        {traits.map((trait, index) => {
          return (
            <GenerativePlanetTrait
              key={index}
              imgSrc={trait.image}
              stock={trait.stock}
              title={trait.title}
              selected={trait.selected}
              isDisabled={isDisabled || trait.stock === 0}
              onClick={selectHandler.bind(this, trait.title)}
              openseaUrl={trait.openseaUrl}
            />
          );
        })}
        <div onClick={noTraitHandler} className={"no-biome-trait"}>
          <div
            className={`planet-trait-border ${!isDisabled ? "clickable" : ""}`}
          >
            <div
              className={
                noTraitSelected && !isDisabled
                  ? "planet-trait-selected-border"
                  : ""
              }
            >
              <img
                className={"planet-trait-image"}
                src={no_trait_icon}
                alt={"PLANET"}
              />
            </div>
          </div>
          <div
            className={`planet-trait-title ${
              isDisabled ? "disabled-trait" : ""
            }`}
          >
            {getBiomeText()}
          </div>
          {isDisabled ? (
            <>
              <div className={"planet-trait-open-sea"}>
                <BuyOnOpenSea
                  text={"VIEW ON OPENSEA"}
                  borderColor={"#d42758"}
                  backgroundColor={"#000"}
                  link={buyOnOpenSea.planetCatalystCollection}
                />
              </div>
              <div className={"planet-trait-open-sea mobile"}>
                <BuyOnOpenSea
                  text={"VIEW"}
                  borderColor={"#d42758"}
                  backgroundColor={"#000"}
                  link={buyOnOpenSea.planetCatalystCollection}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
