import {Modal} from "react-bootstrap";
import './AstrafiteAnnouncementModal.scss'
import React, {useEffect, useState} from "react";
import {astrafiteAnnouncementDescriptionText} from "../../../utils/GeneralUtils";
import {exit_icon} from "../../../assets/images/AstrafiteRushEvent";
import {useNavigate} from "react-router-dom";
import {announcement_asteroid} from "../../../assets/images/PopUps";

export default function AstrafiteAnnouncementModal(){

  const [popupVisibility, setPopupVisibility] = useState(true);
  const navigate = useNavigate();


  useEffect(() => {
    if(localStorage.getItem('astrafiteAnnouncemenetStatus') !== null){
      let announcementStatus = localStorage.getItem('astrafiteAnnouncemenetStatus')
      setPopupVisibility(JSON.parse(announcementStatus))
    }
  }, []);

  function triggerPopup() {
    localStorage.setItem('astrafiteAnnouncemenetStatus', 'false')
    setPopupVisibility(!popupVisibility);

  }
  function joinButtonHandler(){
    localStorage.setItem('astrafiteAnnouncemenetStatus', 'false')

    navigate('astrafite-rush-event')
  }

  return <>
    <div className={'announcement-main-container'}>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName='announcement-modal'
        show={popupVisibility}
      >
        <div className={'announcement-container'}>
          <div className={'announcement-background'}>
              <img alt={""} src={announcement_asteroid} className={'announcement-asteroid'}/>
              <div className={'announcement-content'}>
                <div onClick={triggerPopup} className="big-additional-info-exit">
                  <img alt={""} src={exit_icon} className="exit-icon-img"/>
                </div>
                <h1 className={'announcement-title'}>ASTRAFITE RUSH HAS BEGUN!</h1>
                <div className={'announcement-text'}>
                  {astrafiteAnnouncementDescriptionText.paragraph1}
                </div>
                <div  onClick={joinButtonHandler} className={'announcement-button'}>JOIN NOW</div>
              </div>
          </div>
        </div>
      </Modal>
    </div>
  </>
}
