import React from "react";
import "./ConnectWalletBox.scss";
import {connect_wallet_bottem_horn, connect_wallet_header_horn} from "../../../../assets/images/PlanetSalePagePrivate";
import ConnectWalletButton from "../../../../components/ConnectWalletButton";

export default function ConnectWalletBox(props) {

  return <>
    <div className={"cw-principle-container"}>
      <img  src={connect_wallet_header_horn} alt=""/>
      <div className={"cw-container"}>
        <div>{props.title}</div>
        <div className={"cw-center-button"}>
          <div className={"connect-wallet-dimensions"}>
            <ConnectWalletButton/>
          </div>

        </div>
      </div>
      <img  src={connect_wallet_bottem_horn} alt=""/>
    </div>
  </>
}
