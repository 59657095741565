import './GenerativeStarfighterMintPopupWithCatalyst.scss';
import {
  disabled_catalyst_icon,
  popup_border_bottom_left_thin,
  popup_border_bottom_left_thin_small,
  popup_border_bottom_right_thin,
  popup_border_bottom_right_thin_small,
  popup_border_top_left_thin,
  popup_border_top_left_thin_small,
  popup_border_top_right_thin,
  popup_border_top_right_thin_small,
  random_catalyst,
} from '../../../assets/images/GenerativePlanetMinting';
import BaseButton from '../../BaseButton/BaseButton';
import { EMPTY_STRING } from '../../../utils/GeneralUtils';

export default function GenerativeStarfighterMintPopupWithCatalyst({ popupValues, handleNo, handleYes }) {
  const getStarfighterImageClass = title => {
    if (title === EMPTY_STRING) {
      return 'generative-starfighter-component-image random';
    }
    return 'generative-starfighter-component-image';
  };

  const isDisabledImageAndText = image => {
    return image === disabled_catalyst_icon || image === random_catalyst;
  };

  return (
    <div className={'starfighter-minting-popup-container'}>
      <div className={'starfighter-minting-popup-border'}>
        <img src={popup_border_top_left_thin} alt={'popup-border'} className={'starfighter-minting-popup-border-icon'} />
        <img src={popup_border_top_right_thin} alt={'popup-border'} className={'starfighter-minting-popup-border-icon'} />

        <img src={popup_border_top_left_thin_small} alt={'popup-border'} className={'starfighter-minting-popup-border-icon-small'} />
        <img src={popup_border_top_right_thin_small} alt={'popup-border'} className={'starfighter-minting-popup-border-icon-small'} />
      </div>
      <div className="starfighter-minting-popup-content">
        <div className="starfighter-minting-popup-title-catalyst">
          You are about to mint your Starfighter NFT, burning the following mintpass and Catalyst(s).
        </div>

        <div className={'starfighter-minting-popup-list'} style={{ columnGap: '5vw' }}>
          <div className={'starfighter-minting-popup-starfighter'}>
            <div className={'generative-starfighter-component'} style={{ width: 'auto' }}>
              <div className={'generative-starfighter-border'}>
                <img className={'generative-starfighter-component-image'} src={popupValues.selectedStarfighter.image} alt={'STARFIGHTER'} />
              </div>
              <div className={'generative-starfighter-component-stock'}>{}</div>
              <div className={'generative-starfighter-component-title'}>{popupValues.selectedStarfighter.title}</div>
              <div className={'generative-trait-title-invisible'}>({popupValues.build.title})</div>
            </div>
          </div>
          <div className={'starfighter-minting-popup-trait'}>
            <div className={'generative-starfighter-component'} style={{ width: 'auto' }}>
              <div className={'generative-starfighter-border'}>
                <img
                  className={getStarfighterImageClass(popupValues.build.title)}
                  src={popupValues.build.image}
                  alt={'STARFIGHTER'}
                  style={{ width: '45%' }}
                />
              </div>
              <div className={'generative-starfighter-component-stock'}>{}</div>
              <div
                className={
                  !isDisabledImageAndText(popupValues.build.image)
                    ? 'generative-starfighter-component-title'
                    : 'generative-starfighter-component-title disabled-trait-name'
                }
              >
                {'BUILD'}
              </div>
              <div className={'generative-trait-title'}>{popupValues.build.title === '' ? 'RANDOM' : popupValues.build.title}</div>
            </div>
          </div>
        </div>
        <div className="starfighter-minting-popup-text">Do you wish to proceed?</div>
        <div className="starfighter-minting-popup-buttons">
          <div className="starfighter-minting-popup-button">
            <BaseButton text={'NO'} scale={false} filled={false} disabled={false} onClick={handleNo} />
          </div>
          <div className="starfighter-minting-popup-button">
            <BaseButton text={'YES'} scale={false} filled={true} disabled={false} onClick={handleYes} />
          </div>
        </div>
      </div>
      <div className={'starfighter-minting-popup-border'}>
        <img src={popup_border_bottom_left_thin} alt={'popup-border'} className={'starfighter-minting-popup-border-icon'} />
        <img src={popup_border_bottom_right_thin} alt={'popup-border'} className={'starfighter-minting-popup-border-icon'} />

        <img src={popup_border_bottom_left_thin_small} alt={'popup-border'} className={'starfighter-minting-popup-border-icon-small'} />
        <img src={popup_border_bottom_right_thin_small} alt={'popup-border'} className={'starfighter-minting-popup-border-icon-small'} />
      </div>
    </div>
  );
}
