import AssetCard, { AssetCardProps } from './AssetCard';
import { Col, Container, Row } from 'react-bootstrap';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import { checkbox_checked, heart_empty, heart_filled, unchecked_round_checkbox_svg } from '../../../assets';

type AssetCardListProps<T extends Omit<AssetCardProps, 'fallbackImage'>> = {
  assetListTitle: string;
  assetList: T[] | undefined;
  assetFavouriteHandler: (id: string, isFavourite: boolean) => void;
  isSelectAll: boolean;
  selectHandler: (id: string) => void;
  selectAllHandler: (isSelectAll: boolean) => void;
  fallbackImageUrl?: string;
};

export default function AssetCardList<T extends AssetCardProps>({
  assetListTitle,
  assetList,
  assetFavouriteHandler,
  isSelectAll,
  selectHandler,
  selectAllHandler,
  fallbackImageUrl
}: AssetCardListProps<T>) {

  const selectAllToggleHandler = (isSelectAll: boolean) => {
    selectAllHandler(isSelectAll);
  };

  const cardHandler = (id: string) => {
    const currentUrl = window.location.href;
    const updatedUrl = currentUrl.replace(/\/dashboard$/, `/${id}`);
    const newTab = window.open(updatedUrl, '_blank');
    if (newTab) {
      newTab.focus();
    }
  };

  return (
    <div className="asset-dashboard">
      <div className="asset-dashboard__items">
        <div className="asset-dashboard__items-header">
          <h2 className="asset-dashboard__items-title">{assetListTitle}</h2>
          <ToggleSwitch
            className={'asset-dashboard__items-toggle'}
            label={'SELECT ALL'}
            checkHandler={selectAllToggleHandler}
            isChecked={isSelectAll}
          />
        </div>
        <Container fluid>
          <Row className="asset-dashboard__items-row gy-3" xxl={5}>
            {assetList ? (
              assetList.map(asset => {
                return (
                  <Col key={asset.id} sm={6} lg={3}>
                    <AssetCard
                      id={asset.id}
                      displayName={asset.displayName}
                      thumbnailImageUrl={asset.thumbnailImageUrl}
                      altImageText={asset?.altImageText}
                      fallbackImage={fallbackImageUrl ?? ''}
                      isChecked={asset.isChecked}
                      isFavourite={asset.isFavourite}
                      status={asset.status}
                      handleIsFavourite={assetFavouriteHandler}
                      handleIsChecked={selectHandler}
                      handleCardDetails={cardHandler}
                      leftIcon={{ active: heart_filled, inactive: heart_empty }}
                      rightIcon={{
                        active: checkbox_checked,
                        inactive: unchecked_round_checkbox_svg,
                      }}
                    />
                  </Col>
                );
              })
            ) : (
              <></>
            )}
          </Row>
        </Container>
      </div>
    </div>
  );
}
