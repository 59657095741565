import { TokenInfo, TokenList } from '../TokenBridge/tokenBridgeTypes';
import { SelectTokenButton } from './SelectTokenButton/SelectTokenButton';
import { BlockchainTokenInput } from '../Common/BlockchainTokenInput/BlockchainTokenInput';
import React from 'react';
import './TokenInputForm.scss';
import {TokenNetworkFormType} from "./TokenNetworkForm";

type TokenInputFormProps = {
  formType: TokenNetworkFormType;
  tokenInfo: TokenInfo;
  tokenOptions: TokenList;
  amount: number;
  walletBalance: number;
  handleInputChange?: (event: React.FormEvent<HTMLElement>) => void;
  isBalanceLoading?: boolean;
  handleMaxInput?: () => void;
};

export const tokenOptionCount = (tokens: TokenList) => {
  return Object.keys(tokens).length;
};

export function TokenInputForm({
  tokenInfo,
  tokenOptions,
  formType,
  handleMaxInput,
  handleInputChange,
  amount,
}: TokenInputFormProps) {
  return (
    <div className={'select-token-input__container'}>
      <SelectTokenButton
        className={'select-token__button'}
        name={tokenInfo.name}
        logo={tokenInfo.logo}
        logoAltText={tokenInfo.logoAltText}
        hasMultipleTokenOptions={tokenOptionCount(tokenOptions) > 1}
        disabled={tokenOptionCount(tokenOptions) <= 1}
      />
      <BlockchainTokenInput
        inputAmount={amount}
        handleInputChange={handleInputChange}
        inputType={formType}
        handleMaxInput={handleMaxInput}
      />
    </div>
  );
}
