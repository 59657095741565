import './OtherNftTable.scss'
import OtherNFTRow from "../OtherNftRow/OtherNFTRow";

export default function  OtherNftTable({nftList, rowLength, handleClick}){
  let nftRow = [];

  return <>
      <div className={'other-nft-table-main-container'}>
        {/* eslint-disable-next-line array-callback-return */}
        {nftList.map((nft,index) => {
          if(nftRow.length === rowLength - 1 || index === nftList.length-1){
            nftRow.push(nft)
            let nfts = nftRow;
            nftRow = []
            return (
              <OtherNFTRow nftRow={nfts} handleClick={handleClick}/>
            )
          } else {
            nftRow.push(nft)
          }
        })}
      </div>
  </>
}
