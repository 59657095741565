import React from "react";
import "./AbortMissionPopup.scss";
import {
  abort_mission_top_left,
  abort_mission_top_right,
  abort_mission_bottom_left,
  abort_mission_bottom_right,
  abort_mission_icon
} from "../../assets";

export default function AbortMissionPopup({handleAbort, handleConfirm}) {

  return (<>
    <div className={'abort-mission-container'}>
      <div className={'abort-mission-top-border'}>
        <img className={'abort-mission-top-left'} src={abort_mission_top_left} alt={'border'}/>
        <img className={'abort-mission-top-right'} src={abort_mission_top_right} alt={'border'}/>
      </div>
      <div className={'abort-mission-content'}>
        <img className={'abort-mission-icon'} src={abort_mission_icon} alt={'!'}/>
        <div className={'abort-mission-text'}>
          You are about to abort mission.<br/>
          You will not be able to claim any ASTRAFER if you continue.<br/>
          ARE YOU SURE?
        </div>
        <div className={'abort-mission-buttons'}>
          <div className={'abort-mission-confirm-button-border'}>
            <button className={'abort-mission-confirm-button'} onClick={() => handleConfirm()}>YES</button>
          </div>
          <button className={'abort-mission-cancel-button'} onClick={() => handleAbort()}>NO</button>
        </div>
      </div>
      <div className={'abort-mission-bottom-border'}>
        <img className={'abort-mission-bottom-left'} src={abort_mission_bottom_left} alt={'border'}/>
        <img className={'abort-mission-bottom-right'} src={abort_mission_bottom_right} alt={'border'}/>
      </div>
    </div>
  </>)
}
