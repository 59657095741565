import React, {useContext, useState} from 'react';
import './HeaderBurgerMenu.scss';
import {
  avatar_default,
  enter_burger_menu_icon_svg,
  exit_icon_svg,
  new_phantom_logo,
  nft_drop_down_arrow
} from '../../assets';
import {contactEmail, litepaperPath, socialIconDropdown, socialIconPaths} from '../../utils/GeneralUtils';
import {ASTRAFITE_RUSH_PAGE_ENABLED} from '../../utils/Globals';
import {TooltipContext} from '../Context/TooltipContext';
import CopyTooltip from '../Tooltip/CopyTooltip/CopyTooltip';
import copy from 'copy-to-clipboard';
import {useNavigate} from 'react-router-dom';
import SocialComponent from '../SocialComponent/SocialComponent';
import {AuthenticationManager} from '../../utils/auth';

export default function HeaderBurgerMenu() {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [portfolioMenuOpen, setPortfolioMenuOpen] = useState(false);
  const [portfolioPlanetsOpen, setPortfolioPlanetsOpen] = useState(false);
  const [portfolioPilotsOpen, setPortfolioPilotsOpen] = useState(false);
  const [portfolioStarfightersOpen, setPortfolioStarfightersOpen] = useState(false);
  const [socialsOpen, setSocialsOpen] = useState(false);
  const [eventbarOpen, setEventbarOpen] = useState(false);
  const [astraferBarOpen, setAstraferBarOpen] = useState(false);

  const tooltipContext = useContext(TooltipContext);
  const navigator = useNavigate();
  const authManager = AuthenticationManager.instance;

  const navigateTo = link => {
    if (checkUrl(link)) {
      window.open(link, '_blank');
      return;
    }
    navigator(link);
  };

  const checkUrl = link => {
    let inputElement = document.createElement('input');
    inputElement.type = 'url';
    inputElement.value = link;

    return inputElement.checkValidity();
  };

  function copyThis(textToCopy) {
    copy(textToCopy);
    tooltipContext.setCopyEmailStatus(prevState => ({
      ...prevState,
      ...{ text: 'Email Copied!' },
    }));
  }

  function resetCopy() {
    tooltipContext.setCopyEmailStatus(prevState => ({
      ...prevState,
      ...{ text: 'Copy Email' },
    }));
  }

  function setCopyTooltipVisible(visibility) {
    tooltipContext.setCopyEmailStatus(prevState => ({
      ...prevState,
      ...{ visible: visibility },
    }));
  }

  function mouseLeaveEmailHandler() {
    resetCopy();
    setCopyTooltipVisible(false);
  }
  function onClickEmailHandler() {
    copyThis(contactEmail.email);
  }

  function onMouseEnterEmailHandler() {
    setCopyTooltipVisible(true);
  }

  function openBurgerMenu() {
    setNavbarOpen(!navbarOpen);
  }

  function handlePortfolioMenu() {
    setPortfolioMenuOpen(!portfolioMenuOpen);
    if (portfolioMenuOpen === false) {
      setPortfolioPlanetsOpen(false);
    }
  }

  function hanleEventMenu() {
    setEventbarOpen(!eventbarOpen);
  }

  function handlePortfolioPlanetsMenu() {
    setPortfolioPlanetsOpen(!portfolioPlanetsOpen);
  }

  function handleSocialsMenu() {
    setSocialsOpen(!socialsOpen);
  }

  function handlePortfolioPilotsMenu() {
    setPortfolioPilotsOpen(!portfolioPilotsOpen);
  }

  function handlePortfolioStarfightersMenu() {
    setPortfolioStarfightersOpen(!portfolioStarfightersOpen);
  }

  return (
    <>
      <div className={'burger-menu-container'}>
        <div onClick={openBurgerMenu} className={navbarOpen ? 'burger-exit-icon' : 'burger-menu-icon'}>
          <img className={navbarOpen ? 'exit-icon' : 'burger-icon'} src={navbarOpen ? exit_icon_svg : enter_burger_menu_icon_svg} alt="" />
        </div>
        <div className={navbarOpen ? 'burger-menu-container-opened' : 'burger-menu-container-closed'}>
          <div className={'phantom-logo'}>
            <img src={new_phantom_logo} alt="" className={'logo-image'} />
          </div>
          <ul className={'list-entries'}>
            <a href="/" className={'entries'}>
              HOME
            </a>
            <a href="/account" className={'entries'}>
              {authManager.isAuthenticated() ? (
                <>
                  ACCOUNT &nbsp; <img src={avatar_default} alt="account details" />
                </>
              ) : (
                'LOG IN'
              )}
            </a>
            <a href={socialIconPaths.discord} className={'entries'}>
              JOIN THE DISCORD
            </a>

            <div onClick={handlePortfolioMenu} className={portfolioMenuOpen ? 'entries-hover' : 'entries'}>
              PORTFOLIO
              <img src={nft_drop_down_arrow} alt="" className={portfolioMenuOpen ? 'arrow-down' : 'arrow-right'} />
            </div>

            <div
              onClick={handlePortfolioPlanetsMenu}
              className={
                portfolioMenuOpen && !portfolioPlanetsOpen
                  ? 'entries-open'
                  : portfolioMenuOpen && portfolioPlanetsOpen
                    ? 'entries-open hovered'
                    : 'entries-closed'
              }
            >
              PLANETS
              <img src={nft_drop_down_arrow} alt="" className={portfolioPlanetsOpen ? 'arrow-down' : 'arrow-right'} />
            </div>

            <a href="/portfolio/mint-planets" className={portfolioMenuOpen && portfolioPlanetsOpen ? 'submenu-entries-opened' : 'entries-closed'}>
              GENERATE PLANET
            </a>
            <a href="/portfolio/tcq-recon" className={portfolioMenuOpen && portfolioPlanetsOpen ? 'submenu-entries-opened' : 'entries-closed'}>
              THE CERES QUADRANT
            </a>
            <a
              href="/portfolio/planets/dashboard"
              className={portfolioMenuOpen && portfolioPlanetsOpen ? 'submenu-entries-opened' : 'entries-closed'}
            >
              PLANET DASHBOARD
            </a>
            <a href="/nft" className={portfolioMenuOpen ? 'entries-open' : 'entries-closed'}>
              POSTERS
            </a>
            <div
              onClick={handlePortfolioPilotsMenu}
              className={
                portfolioMenuOpen && !portfolioPilotsOpen
                  ? 'entries-open'
                  : portfolioMenuOpen && portfolioPilotsOpen
                    ? 'entries-open hovered'
                    : 'entries-closed'
              }
            >
              PILOTS
              <img src={nft_drop_down_arrow} alt="" className={portfolioPilotsOpen ? 'arrow-down' : 'arrow-right'} />
            </div>
            <a href="/portfolio/buy-pilots" className={portfolioMenuOpen && portfolioPilotsOpen ? 'submenu-entries-opened' : 'entries-closed'}>
              BUY PILOTS
            </a>
            <a href="/portfolio/mint-pilots" className={portfolioMenuOpen && portfolioPilotsOpen ? 'submenu-entries-opened' : 'entries-closed'}>
              GENERATE PILOTS
            </a>
            <a href="/portfolio/pilots/dashboard" className={portfolioMenuOpen && portfolioPilotsOpen ? 'submenu-entries-opened' : 'entries-closed'}>
              PILOTS DASHBOARD
            </a>
            <div
              onClick={handlePortfolioStarfightersMenu}
              className={
                portfolioMenuOpen && !portfolioStarfightersOpen
                  ? 'entries-open'
                  : portfolioMenuOpen && portfolioStarfightersOpen
                    ? 'entries-open hovered'
                    : 'entries-closed'
              }
            >
              STARFIGHTERS
              <img src={nft_drop_down_arrow} alt="" className={portfolioStarfightersOpen ? 'arrow-down' : 'arrow-right'} />
            </div>
            <a
              href="/portfolio/starfighters"
              className={portfolioMenuOpen && portfolioStarfightersOpen ? 'submenu-entries-opened' : 'entries-closed'}
            >
              BUY STARFIGHTERS
            </a>
            <a
              href="/portfolio/mint-starfighters"
              className={portfolioMenuOpen && portfolioStarfightersOpen ? 'submenu-entries-opened' : 'entries-closed'}
            >
              GENERATE STARFIGHTERS
            </a>
            <a
              href="/portfolio/starfighters/dashboard"
              className={portfolioMenuOpen && portfolioStarfightersOpen ? 'submenu-entries-opened' : 'entries-closed'}
            >
              STARFIGHTERS DASHBOARD
            </a>
            <a href={'/portfolio/currency'} className={portfolioMenuOpen && !portfolioPlanetsOpen ? 'entries-open' : 'entries-closed'}>
              CURRENCY
            </a>
            <div onClick={hanleEventMenu} className={eventbarOpen ? 'entries-hover' : 'entries'}>
              EVENTS
              <img src={nft_drop_down_arrow} alt="" className={eventbarOpen ? 'arrow-down' : 'arrow-right'} />
            </div>

            {ASTRAFITE_RUSH_PAGE_ENABLED ? (
              <a href="/astrafite-rush-event" className={eventbarOpen ? 'entries-open' : 'entries-closed'}>
                ASTRAFITE RUSH EVENT
              </a>
            ) : (
              <a href="#/" className={eventbarOpen ? 'entries-open disabled' : 'entries-closed'}>
                COMING SOON
              </a>
            )}

            <a href="/portfolio/tcq-recon" className={eventbarOpen ? 'entries-open' : 'entries-closed'}>
              THE CERES QUADRANT
            </a>

            <div onClick={() => setAstraferBarOpen(!astraferBarOpen)} className={astraferBarOpen ? 'entries-hover' : 'entries'}>
              ASTRAFER
              <img src={nft_drop_down_arrow} alt="" className={astraferBarOpen ? 'arrow-down' : 'arrow-right'} />
            </div>
            <a href={'/token'} className={astraferBarOpen ? 'entries-open' : 'entries-closed'}>
              TOKEN
            </a>
            <a href={litepaperPath} className={astraferBarOpen ? 'entries-open' : 'entries-closed'}>
              LITEPAPER
            </a>
            <a href={'/bridge'} className={astraferBarOpen ? 'entries-open' : 'entries-closed'}>
              BRIDGE
            </a>
            <div onClick={handleSocialsMenu} className={socialsOpen ? 'entries-hover' : 'entries'}>
              COMMUNITY
              <img src={nft_drop_down_arrow} alt="" className={socialsOpen ? 'arrow-down' : 'arrow-right'} />
            </div>

            {socialsOpen ? (
              socialIconDropdown.map((item, index) => (
                <>
                  {item.text !== 'EMAIL US' ? (
                    <div className="social-entries">
                      <SocialComponent item={item} index={index} text={'SOCIALS'} navigateTo={navigateTo} />
                    </div>
                  ) : (
                    <div
                      className="social-entries"
                      onMouseLeave={mouseLeaveEmailHandler}
                      onMouseEnter={onMouseEnterEmailHandler}
                      onClick={onClickEmailHandler}
                    >
                      <SocialComponent item={item} index={index} text={'SOCIALS'} navigateTo={navigateTo} />
                      <CopyTooltip />
                    </div>
                  )}
                </>
              ))
            ) : (
              <></>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}
