import './ClaimOtherNFTs.scss'
import PageHeader from "../../PageHeader";
import React from "react";
import ClaimButton from "../ClaimButton/ClaimButton";
import ClaimOtherInfo from "./ClaimOtherInfo/ClaimOtherInfo";
import OtherNftTable from "./OtherNftTable/OtherNftTable";


export default function ClaimOtherNFTs({nftCollection, totalAvailableAmount, totalAvailableAmountDisplay, totalRemainingAmountDisplay, nextClaimDate, handleClick, isFinalClaim, handleClaimAll, kycPassed}) {

  const canClaim = totalAvailableAmount.gt(0);

  return <>
    <div className={'claim-other-main-container'}>
      <div className={"other-title"}>
        <PageHeader headerText="OTHER NFT'S" classname="page-mobile-header-text-no-margin"/>
      </div>
      <div className={'claim-all-button'}>
        <ClaimButton activeText={'CLAIM ALL'} isActive={canClaim && kycPassed} disabledText={(kycPassed ? 'ASTRAFER CLAIMED' : 'KYC REQUIRED')} handler={handleClaimAll}/>
      </div>
      <ClaimOtherInfo totalAvailableAmountDisplay={totalAvailableAmountDisplay} totalRemainingAmountDisplay={totalRemainingAmountDisplay} nextClaimDate={nextClaimDate} isFinalClaim={isFinalClaim}/>
      <div className={'other-nft-container'}>
        <OtherNftTable nftList={nftCollection} handleClick={handleClick} rowLength={3}/>
      </div>
      <div className={'other-nft-container mobile'}>
        <OtherNftTable nftList={nftCollection} handleClick={handleClick} rowLength={2}/>
      </div>
    </div>
  </>

}
