import './CopyTooltip.scss'
import React, {useContext} from "react";
import {TooltipContext} from "../../Context/TooltipContext";
import {hover_triangle, hover_triangle_white} from "../../../assets/images/AstrafiteRushEvent";

export default function CopyTooltip() {
  const tooltipContext = useContext(TooltipContext);
  return <>
    {tooltipContext.copyEmailStatus.visible ? <div className={'copy-tooltip-main-container'}>
      <img alt={""} className={"copy-email-triangle"} src={hover_triangle}/>
      <img alt={""} className={"copy-email-triangle-white"} src={hover_triangle_white}/>
      <div className={'scale-copy-email'}>
        {tooltipContext.copyEmailStatus.text}
      </div>
    </div> : <></>}
  </>
}
