import React from "react";
import "./LiftOff.scss";
import CarouselModalButton from "../CarouselModalButton/CarouselModalButton";

export default function LiftOff({closeModal}) {

  return <>
    <div className={"liftoff-carousel-main-container"}>
      <div className={"header-title-liftoff-carousel"}>
        <div className={"liftoff-carousel-title"}>YOU’RE READY FOR</div>
        <div className={"liftoff-carousel-second-title"}>LIFT-OFF, RANGER</div>
      </div>

      <div className={"liftoff-content-text"}>ASTRAFITE RUSH AWAITS</div>

      <div className={"liftoff-main-button-container"}>
        <div className={"liftoff-button-content"}>
          <CarouselModalButton text={'CLOSE'} toExecute={closeModal} />
        </div>
      </div>
    </div>
  </>
}
