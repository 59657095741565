import './MicroGame.scss';

import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import Phaser from 'phaser';
import Main from './Game/Main';
import { useInterval } from '../../hooks/useInterval';
import { Modal } from 'react-bootstrap';

// Declaring it here instead of importing due to .eslintignore on micro game
const GameState = {
  Init: 0,
  Title: 1,
  Menu: 2,
  GamePlay: 3,
  MenuTransitionFadeToGame: 4,
  GameOver: 6,
  FlyingToPortal: 7,
  GameWin: 8,
  CollectingRewards: 9,
  HowToPlay: 10,
  Paused: 11,
  Quit: 12,
};

const configLandscape = {
  type: Phaser.AUTO,
  parent: 'phaser',
  width: 2250,
  height: 1404,
  backgroundColor: '#131313',
  scale: {
    mode: Phaser.Scale.FIT,
  },
  physics: {
    default: 'arcade',
    arcade: {
      debug: false,
    },
  },
  scene: [Main],
};

const configPortrait = {
  type: Phaser.AUTO,
  parent: 'phaser',
  width: 540,
  height: 920,
  backgroundColor: '#000000',

  scale: {
    mode: Phaser.Scale.FIT,
    autoCenter: Phaser.Scale.CENTER_HORIZONTALLY,
  },
  physics: {
    default: 'arcade',
    arcade: {
      debug: false,
    },
  },
  scene: Main,
};

const actions = {
  UPDATE_GAME_STATE: 'UPDATE_GAME_STATE',
};

// We use this reducer to track the previous and current game state
function reducer(state, action) {
  switch (action.type) {
    case actions.UPDATE_GAME_STATE:
      return { prevGameState: state.gameState, gameState: action.payload };
    default:
      throw new Error();
  }
}

export default function MicroGame({ onClose, handleIsGameActive, isMobile, handleCheckpointCompletion }) {
  const [{ gameState, prevGameState }, dispatch] = useReducer(reducer, { gameState: null, prevGameState: null });
  const mainScene = useRef(null);
  const game = useRef(null);

  const handleGameState = useCallback(
    (prev, current) => {
      if (current === GameState.GamePlay) {
        handleIsGameActive(true);
      }

      // The game is complete once player collects all the crystals, we mark the checkpoint as complete here.
      if (prev === GameState.GamePlay && current === GameState.FlyingToPortal) {
        handleIsGameActive(false);
      }

      // For mobile, we close the game automatically after claim is processed from reward screen.
      // or when the player manually chooses to quite the game
      if (prev === GameState.CollectingRewards && current === GameState.Title) {
        handleCheckpointCompletion();
        // setHasCheckpointUpdateBeenCalled(true);
        if (isMobile) {
          onClose();
        }
      }

      if (current === GameState.Quit) {
        handleIsGameActive(false);
        if (isMobile) {
          onClose();
        }
      }
    },
    [handleCheckpointCompletion, handleIsGameActive, isMobile, onClose],
  );

  const updateGameState = data => {
    dispatch({ type: actions.UPDATE_GAME_STATE, payload: data });
  };

  /*  If you try to call getScene() immediately after new Phaser.Game(config),
    the scene will still be undefined as it takes time to initialise.
    We poll every second to retrieve the game scene, so we can listen to the game state
    Polling stops when the scene is retrieved.*/
  const { clear } = useInterval(() => {
    mainScene.current = game.current.scene.getScene('Main');
    if (mainScene.current) {
      mainScene.current.events.on('game_state', updateGameState);
      stopInterval();
    }
  }, 1000);

  const stopInterval = () => clear();

  useEffect(() => {
    let config;

    if (isMobile) {
      config = configPortrait;
      window.scrollTo(0, 0);
    } else {
      config = configLandscape;
    }

    game.current = new Phaser.Game(config);
    game.current.scene.start('Main');

    return () => {
      if (game.current) {
        if (mainScene.current) {
          mainScene.current.events.off('game_state', updateGameState);
        }
        dispatch({ type: actions.UPDATE_GAME_STATE, payload: null });
        game.current.destroy(true);
      }
    };
  }, [isMobile]);

  useEffect(() => {
    handleGameState(prevGameState, gameState);
    return () => {
      if (!gameState) {
        handleIsGameActive(false);
      }
    };
  }, [gameState, handleGameState, handleIsGameActive, prevGameState]);

  if (isMobile) {
    return (
      <>
        <div className={'rotate-device-overlay'}>Please rotate your device to portrait mode.</div>
        <Modal show={true} onHide={onClose} dialogClassName={'planet-exploration-modal'} contentLabel={'Mobile micro game'}>
          <div id={`phaser`} className={'planet-exploration-micro-game-mobile'} />
        </Modal>
      </>
    );
  } else {
    return <div id={`phaser`} className={'planet-exploration-micro-game'} />;
  }
}
