import PGModal from '../../Common/PGModal/PGModal';
import GenericButton from '../../Button/GenericButton';
import LoadingSpinner from '../../LoadingSpinner';
import { checkbox_checked } from '../../../assets';
import SuccessMessage from '../../Common/SuccessMessage/SuccessMessage';
import ErrorModal from '../../Modal/ErrorModal/ErrorModal';
import { DashboardSummary } from '../Common/DashboardSummary';
import { Suspense, useEffect, useState } from 'react';
import AssetCardList from '../../Common/AssetCard/AssetCardList';
import { ERC721GameLock } from '@pg/blockchain-api';
import { LockStatus } from '../../Common/AssetCard/AssetCard';
import { createFavourite, deleteFavourite, getVaultSignatures } from '../../../utils/ApiCalls';
import { getBlockchainContract } from '../../../utils/providerObject';
import PGVaultAbi from '@fugu/base-contracts/dist/abis/Vault/Previous.Versions/PGVault.v2.sol/PGVaultv2.json';
import { metamaskErrorMap } from '../../../blockchain/errors';
import { walletContext } from '../../../utils/WalletContext';
import { AssetType } from '@pg/account-api';
import { AvatarDashboardInfo } from './Info/AvatarDashboardInfo';
import { useProvider } from '../../../hooks/useProvider';
import { Contract } from 'ethers';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import { useFetchMyAvatars, CustomAvatar as Avatar } from '../../../hooks/useFetchMyAvatars';
import { generating_avatar } from '../../../assets/images/Avatars';
import { CustomVaultSignatureLockRequest } from '../Planet/PlanetDashboardContent';

enum BindType {
  UNBIND = 'unbind',
  BIND = 'bind',
}

export default function AvatarDashboardContent() {
  const [selectedAvatars, setSelectedAvatars] = useState<Avatar[] | undefined>();
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [inMissionCount, setInMissionCount] = useState(0);
  const [boundCount, setBoundCount] = useState(0);
  const [unboundCount, setUnboundCount] = useState(0);
  const [isBindActionLoading, setIsBindActionLoading] = useState(false);
  const [boundSuccess, setIsBoundSuccess] = useState(false);
  const [showBindModal, setShowBindModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [totalUpdateAvatarsCount, setTotalUpdateAvatarsCount] = useState(0);
  const [bindStatusText, setBindStatusText] = useState('');
  const [errorModalText, setErrorModalText] = useState('');
  const [bindType, setBindType] = useState<BindType>(BindType.UNBIND);
  const { avatars, avatarsError, setAvatars } = useFetchMyAvatars();

  const provider = useProvider();

  const avatarStatus = (gameLocks?: ERC721GameLock[], isBlockchainLocked?: boolean): LockStatus => {
    if (gameLocks && gameLocks.length > 0) {
      return LockStatus.IN_MISSION;
    } else if (isBlockchainLocked && gameLocks && gameLocks.length === 0) {
      return LockStatus.BOUND;
    } else {
      return LockStatus.UNBOUND;
    }
  };

  useEffect(() => {
    if (avatars.length > 0) {
      setAvatars(prevAvatarList =>
        prevAvatarList.map(avatar => ({
          ...avatar,
          isChecked: isSelectAll,
          status: avatarStatus(avatar.gameLocks, avatar.isBlockchainLocked),
          altImageText: `An avatar with ID ${avatar.data.citizenId as string}.`,
        })),
      );
    }
  }, [isSelectAll, avatars.length, setAvatars]);

  useEffect(() => {
    setSelectedAvatars(avatars?.filter(avatar => avatar.isChecked));
    setSelectedCount(avatars.filter(asset => asset.isChecked).length);
    setInMissionCount(avatars.filter(asset => asset.isChecked && asset.status === LockStatus.IN_MISSION).length);
    setBoundCount(avatars.filter(asset => asset.isChecked && asset.status === LockStatus.BOUND).length);
    setUnboundCount(avatars.filter(asset => asset.isChecked && asset.status === LockStatus.UNBOUND).length);
  }, [avatars]);

  const selectAllHandler = (isSelectAll: boolean) => {
    setIsSelectAll(isSelectAll);
    setSelectedCount(isSelectAll ? avatars?.length || 0 : 0);
    setInMissionCount(isSelectAll ? avatars?.filter(asset => asset.status === LockStatus.IN_MISSION).length || 0 : 0);
    setBoundCount(isSelectAll ? avatars?.filter(asset => asset.status === LockStatus.BOUND).length || 0 : 0);
    setUnboundCount(isSelectAll ? avatars?.filter(asset => asset.status === LockStatus.UNBOUND).length || 0 : 0);
  };

  const selectAvatarHandler = (id: string) => {
    setAvatars(prevAvatars =>
      prevAvatars.map(avatar => {
        if (avatar.id === id) {
          avatar.isChecked = !avatar.isChecked;
        }
        return avatar;
      }),
    );
  };

  const updateAvatarFavourite = async (id: string, isFavourite: boolean) => {
    if (isFavourite) {
      await deleteFavourite(id);
    } else {
      await createFavourite(id, AssetType.AVATAR);
    }

    setAvatars(prevAvatars =>
      prevAvatars.map(avatar => {
        if (avatar.id === id) {
          avatar.isFavourite = !avatar.isFavourite;
        }
        return avatar;
      }),
    );
  };

  const handleAvatarBindingAction = async (bindType: BindType) => {
    setIsBindActionLoading(true);
    const vaultSignatureRequestList: CustomVaultSignatureLockRequest[] = [];

    if (!selectedAvatars || selectedAvatars.length < 1) {
      return;
    }

    const state = bindType === BindType.BIND;

    for (const avatar of selectedAvatars) {
      const shouldAddToRequest = (state && avatar.status === LockStatus.UNBOUND) || (!state && avatar.status === LockStatus.BOUND);

      if (shouldAddToRequest) {
        const request = formatVaultSignatureRequest(avatar, state);
        vaultSignatureRequestList.push(request);
      }
    }

    try {
      setBindStatusText('Getting signature...');
      const result = await getVaultSignatures(vaultSignatureRequestList);
      setTotalUpdateAvatarsCount(vaultSignatureRequestList.length);
      setBindStatusText('Please sign transaction to proceed');

      const transaction = await set721LockStatus(
        getBlockchainContract(process.env.REACT_APP_CONTRACT_ADDRESS_VAULT, PGVaultAbi.abi, provider) as Contract,
        result.structArrays,
        result.signatures,
      );

      // We set the text to an empty string so the element is hidden
      // Then add the status text to transition the next message
      setBindStatusText('');
      setTimeout(() => {
        setBindStatusText(
          `${bindType === BindType.BIND ? 'Bind' : 'Unbind'}ing ${getPluralizedAvatar(
            totalUpdateAvatarsCount,
          )}\\n This may take a while, please wait...`,
        );
      }, 500);

      const transactionResponse = await transaction.wait();

      if (transactionResponse) {
        setIsBoundSuccess(true);

        const updateAvatarStatus = (avatar: Avatar) => {
          const updatedAvatar = selectedAvatars.find(selectedAvatar => selectedAvatar.id === avatar.id);

          if (updatedAvatar) {
            return {
              ...avatar,
              status: state ? LockStatus.BOUND : LockStatus.UNBOUND,
              isBlockchainLocked: state,
            };
          } else {
            return avatar;
          }
        };

        const updatedAvatars = avatars.map(avatar => updateAvatarStatus(avatar));
        const updatedSelectedAvatars = selectedAvatars.map(avatar => updateAvatarStatus(avatar));

        setAvatars(updatedAvatars);
        setSelectedAvatars(selectedAvatars.map(avatar => updateAvatarStatus(avatar)));
        setBoundCount(updatedSelectedAvatars?.filter(asset => asset.status === LockStatus.BOUND).length);
        setUnboundCount(updatedSelectedAvatars?.filter(asset => asset.status === LockStatus.UNBOUND).length);
      }
    } catch (e) {
      setShowBindModal(false);
      setShowErrorModal(true);

      // throw a generic error if error code is unknown
      if (!metamaskErrorMap.some(error => error === (e as any).code)) {
        setErrorModalText('Something went wrong, please try again later');
        setIsBindActionLoading(false);
        setBindStatusText('');
        return;
      }

      if ((e as any).code === 'ACTION_REJECTED') {
        setErrorModalText('Request rejected. You must approve the blockchain request to continue');
      } else {
        setErrorModalText('Something went wrong, please try again later');
      }
    }

    setBindStatusText('');
    setIsBindActionLoading(false);
  };

  const formatVaultSignatureRequest = (avatar: Avatar, state: boolean) => {
    return {
      tokenAddress: avatar.contractAddress as string,
      playerAddress: walletContext.currentWallet,
      state: state,
      tokenId: avatar.tokenId as number,
      amount: `1`,
      deadline: 9999999999999,
      chainId: avatar.chainId as number,
      contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS_VAULT as string,
    };
  };

  const set721LockStatus = async (contract: Contract, struct: {}[], signature: string[]): Promise<TransactionResponse> => {
    return await contract.set721LockedStatusBatch(struct, signature);
  };

  const promptBindingAction = (bindType: BindType) => {
    setShowBindModal(true);
    setBindType(bindType);
  };

  const getBoundCount = () => {
    return bindType === BindType.BIND ? unboundCount : boundCount;
  };

  const getPluralizedAvatar = (count: number) => {
    return count > 1 ? 'avatars' : 'avatar';
  };

  const getBindingActionPastTense = (bindType: BindType) => {
    return bindType === BindType.BIND ? 'bound' : 'unbound';
  };

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <PGModal
        show={showBindModal}
        onHide={() => {
          setShowBindModal(false);
          setIsBoundSuccess(false);
        }}
        // The prompt message will display for a brief moment if we set isBoundSuccess to false as the modal is closed
        // This ensures the success message will persist until the modal has exited completely
        onExited={() => setIsBoundSuccess(false)}
      >
        <div className="planet-dashboard__modal-text-container">
          {!isBindActionLoading && !boundSuccess && (
            <>
              <p className={'planet-dashboard__modal-text'}>{`You are about to ${bindType} ${getBoundCount()} ${getPluralizedAvatar(
                getBoundCount(),
              )}.`}</p>
              <p>Do you wish to proceed?</p>
              <GenericButton
                handleClick={() => handleAvatarBindingAction(bindType)}
                buttonText={bindType}
                buttonClass="planet-dashboard__modal-button"
              />
            </>
          )}
          {isBindActionLoading && (
            <>
              <LoadingSpinner marginBottom={'16px'} />
            </>
          )}

          {bindStatusText.split('\\n').map((text, index) => (
            <p className={bindStatusText ? 'planet-dashboard__modal-loading-text' : 'planet-dashboard__modal-loading-text'} key={index}>
              {text}
            </p>
          ))}

          {boundSuccess && (
            <>
              <img alt="a tick inside of circle" src={checkbox_checked} className="planet-dashboard__modal-success-icon" />
              <SuccessMessage
                message={`You have successfully ${getBindingActionPastTense(bindType)} ${totalUpdateAvatarsCount} ${getPluralizedAvatar(
                  totalUpdateAvatarsCount,
                )}`}
              />
              <GenericButton handleClick={() => setShowBindModal(false)} buttonText="close" buttonClass="planet-dashboard__modal-button" />
            </>
          )}
        </div>
      </PGModal>
      {showErrorModal && (
        <ErrorModal
          onHide={() => {
            setShowErrorModal(false);
            setIsBoundSuccess(false);
          }}
          title="Error"
          text={errorModalText}
          handleConfirm={true}
        />
      )}

      <DashboardSummary
        bindClickHandler={() => promptBindingAction(BindType.BIND)}
        unbindClickHandler={() => promptBindingAction(BindType.UNBIND)}
        selectedCount={selectedCount}
        inMissionCount={inMissionCount}
        boundCount={boundCount}
        unboundCount={unboundCount}
        isBindDisabled={selectedCount < 1 || unboundCount < 1}
        isUnbindDisabled={selectedCount < 1 || boundCount < 1}
        mainText={'GAME LOCKED'}
      />
      {avatarsError && <p style={{ marginBottom: '40px', textAlign: 'center' }}>{avatarsError}</p>}
      <Suspense fallback={<LoadingSpinner />}>
        <AssetCardList
          assetListTitle={'My Pilots'}
          assetList={avatars}
          assetFavouriteHandler={updateAvatarFavourite}
          isSelectAll={isSelectAll}
          selectHandler={selectAvatarHandler}
          selectAllHandler={selectAllHandler}
          fallbackImageUrl={generating_avatar}
        />
      </Suspense>
      <AvatarDashboardInfo />
    </>
  );
}
