// Custom hook for handling send chain logic
import {
  config,
  createContract,
  getContractAddress,
  getIsChainIdSupported,
  getOtherChain,
  networkOptionCount,
  tokenList,
} from './TokenBridgeContent';
import React, { useEffect } from 'react';
import {
  setErrorModalText,
  setReceiveChainConfig,
  setReceiveTokenInfo,
  setSendBalanceIsLoading,
  setSendChainName,
  setSendIsChainIdSupported,
  setSendTokenBalance,
  setSendTokenInfo,
  setShowErrorModal,
} from './tokenBridgeActions';
import { getContractForChain, getERC20Balance } from '../../utils/ContractUtils';
import { ethers } from 'ethers';
import { ChainConfig, ChainId, TokenBridgeAction } from './tokenBridgeTypes';

export function useSendChainLogic(
  address: (`0x${string}` & {}) | `0x${string}` | undefined,
  sendConfig: ChainConfig & ChainId,
  provider: any,
  dispatch: React.Dispatch<TokenBridgeAction>,
) {
  useEffect(() => {
    if (!sendConfig.chainId || !provider || !address) {
      return;
    }

    const isChainIdSupported = getIsChainIdSupported(sendConfig.chainId);
    dispatch(setSendIsChainIdSupported(isChainIdSupported));

    const getSendingAstraferBalance = async () => {
      if (!sendConfig.chainId) {
        return;
      }

      dispatch(setSendBalanceIsLoading(true));

      const contractAddress = getContractAddress(sendConfig.chainId);
      const contract = createContract(sendConfig.chainId, contractAddress);

      let balance;

      try {
        balance = await getERC20Balance(contract, address);
        dispatch(setSendBalanceIsLoading(false));
      } catch (e: any) {
        dispatch(setSendBalanceIsLoading(false));
        dispatch(setErrorModalText(e.message));
        dispatch(setShowErrorModal(true));
      }

      if (balance) {
        // FIXME: balance is a string, we need it to be a number in case we need to compare balance with sendAmount etc.
        dispatch(setSendTokenBalance(parseInt(ethers.utils.formatEther(balance))));
      }
    };

    const runAsyncUseEffect = async () => {
      // set the token to astrafer by default
      // TODO: move to separate hook where token info updates depending on what the user selects when we have more tokens.
      const tokenInfo = tokenList['astrafer'];
      dispatch(setSendTokenInfo(tokenInfo));
      dispatch(setReceiveTokenInfo(tokenInfo));

      if (!isChainIdSupported) {
        dispatch(setSendChainName(''));
        dispatch(setSendTokenBalance(0));
        return;
      }

        try {
          const astraferContract = await getContractForChain(sendConfig.chainId, provider);

          if (!astraferContract) {
            dispatch(setSendTokenBalance(0));
          }

          if (!sendConfig) return;

          await getSendingAstraferBalance();

          if (networkOptionCount(config) === 2 && sendConfig.chainId) {
            const otherChanId = await getOtherChain(sendConfig.chainId);
            const newConfig = config[otherChanId];
            (newConfig as ChainConfig & ChainId).chainId = otherChanId;
            dispatch(setReceiveChainConfig(newConfig as ChainConfig & ChainId));
          }

          // clear any errors since we are able to fetch the balance and network for receiving chain
          dispatch(setShowErrorModal(false));
          dispatch(setErrorModalText(''));
        } catch (e: any) {
          console.error(e);
          dispatch(setErrorModalText(e));
          dispatch(setShowErrorModal(true));
        }
      }

    runAsyncUseEffect();
  }, [address, dispatch, provider, sendConfig]);
}
