import { useEffect, useState } from 'react';
import { getActiveMissions } from '../utils/ApiCalls';
import { RECON_MISSION_NAME } from '../utils/Globals';

export function useFetchMissions(validLoadedMission) {
  const [mission, setMission] = useState(null);
  const [missionLoadError, setMissionLoadError] = useState(null);
  const [isMissionLoading, setIsMissionLoading] = useState(false);

  useEffect(() => {
    const fetchReconMission = async () => {
      try {
        setMissionLoadError(null);
        setIsMissionLoading(true);
        let validMission;

        if (validLoadedMission) {
          setMission(validLoadedMission);
          setIsMissionLoading(false);
          return;
        }

        const allActiveMissions = await getActiveMissions();
        validMission = allActiveMissions.find(validMission => validMission.name.toLowerCase() === RECON_MISSION_NAME);
        setMission(validMission);
        setIsMissionLoading(false);
      } catch (e) {
        setMissionLoadError('Failed to load as Recon Mission was not found, please try again later');
        setIsMissionLoading(false);
      }
    };

    fetchReconMission();
  }, [validLoadedMission]);

  return { mission, missionLoadError, isMissionLoading };
}
