import { useEffect, useState } from 'react';
import { getMyPlanetDetails } from '../utils/ApiCalls';
import { CustomPlanet } from '../components/Dashboard/Planet/PlanetDashboardContent';

interface MyPlanets {
  planets: CustomPlanet[];
  planetsError: string | null;
  setPlanets: React.Dispatch<React.SetStateAction<CustomPlanet[]>>;
}

export function useFetchMyPlanets(): MyPlanets {
  const [planets, setPlanets] = useState<CustomPlanet[]>([]);
  const [planetsError, setPlanetsError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMyPlanetDetails = async () => {
      try {
        const planetDetails = await getMyPlanetDetails();
          const sortedPlanets: CustomPlanet[] = planetDetails.sort((a: CustomPlanet, b: CustomPlanet) => (a.isFavourite === b.isFavourite ? 0 : a.isFavourite ? -1 : 1));
          const planetsWithDisplayName = sortedPlanets.map(planet => ({...planet, displayName: `ID - ${planet.name}`}));
          setPlanets(planetsWithDisplayName);
      } catch (e: any) {
        setPlanetsError('Error: failed to fetch planets');
      }
    };

    fetchMyPlanetDetails();
  }, []);

  return { planets, planetsError, setPlanets };
}
