import "./GenerativePlanetMinting.scss";
import HeaderComponent from "../components/Header/HeaderComponent";
import Footer from "../components/Footer/Footer";
import FighterLady from "../components/Footer/FooterElements/FighterLady/FighterLady";
import PageHeader from "../components/PageHeader";
import ConnectWalletSection from "../components/ConnectWallet/ConnectWalletSection";
import { walletContext } from "../utils/WalletContext";
import GenerativeAvatarMintingContent
  from "../components/GenerativeAvatarMinting/GenerativeAvatarMintingContent/GenerativeAvatarMintingContent";
import {useIsCorrectChain} from "../hooks/useIsCorrectChain";
import {useNetwork} from "wagmi";
import SwitchNetworkChainSection from "../components/SwitchNetworkChain";
import React from "react";

export default function GenerativeAvatarMinting() {
  const isCorrectChain = useIsCorrectChain();
  const network = useNetwork();

  return (
    <div className="planet-minting-container">
      <HeaderComponent noBackground={true}/>
      <div className={"planet-minting-title"}>
        <PageHeader headerText={"GENERATIVE AVATAR MINTING"} />
      </div>
      {!isCorrectChain && (
        <SwitchNetworkChainSection network={network} message={`You must be on the ${process.env.REACT_APP_CHAINID} network to mint NFTs`} />
      )}
      {walletContext.signed && walletContext.currentWallet && isCorrectChain && (
        <GenerativeAvatarMintingContent />
      )}
      <ConnectWalletSection />
      <Footer>
        <FighterLady />
      </Footer>
    </div>
  );
}
