import './GenesisNFTsTabsList.scss'
import {Col, Nav} from "react-bootstrap";
import React from "react";
import {GenesisNFTTab} from "../GenesisNFTTab/GenesisNFTTab";


function GenesisNFTsTabsList({nfts, setActiveNft, setShowCombination}) {
  const onSelectNft = (nft) =>{
    setActiveNft(nft)
    setShowCombination(false)
  }

  return (
    <>
      <Col sm={4}>
        <Nav variant={'pills'} className={'episode-tabs'}>
          {nfts.map((nft, index) => (
            <Nav.Item key={index}>
              <Nav.Link onClick={onSelectNft.bind(this,nft)} eventKey={nft.eventKey}>
                <GenesisNFTTab title={nft.title} image={nft.image}/>
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </Col>
    </>
  )
}

export {GenesisNFTsTabsList}
