import './GenerativePlanetMintingContent.scss';
import { useMemo, useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import GenerativePlanetMintpassGrid from '../GenerativePlanetMintpassGrid/GenerativePlanetMintpassGrid';
import GenerativePlanetCatalystGrid from '../GenerativePlanetCatalystGrid/GenerativePlanetCatalystGrid';
import GenerativeTraitGrid from '../GenerativeTraitGrid/GenerativeTraitGrid';
import {
  CELESTIAL_BODIES_TRAIT_TITLE,
  DISCOVERIES_TRAIT_TITLE,
  ECOSYSTEM_TRAIT_TITLE,
  EMPTY_STRING,
  MAXIMUM_PLANET_MINTS,
  NO_TRAIT,
  PLANET_MINTPASSES,
  RESOURCE_REACTIVITY_TRAIT_TITLE,
  RESOURCE_STATE_TRAIT_TITLE,
} from '../../../utils/GeneralUtils';
import {
  add_catalyst_icon,
  artifact_lost_civilisation_icon,
  artifact_natural_phenomena_icon,
  biome_aridic_icon,
  biome_edenic_icon,
  biome_tundric_icon,
  feature_lunar_icon,
  feature_orbital_rings_icon,
  random_catalyst,
  resource_family_radioactive_icon,
  resource_family_stable_icon,
  resource_family_unstable_icon,
  resource_type_gas_icon,
  resource_type_liquid_icon,
  resource_type_solid_icon,
} from '../../../assets/images/GenerativePlanetMinting';
import GenerativePlanetMintPopupWithoutCatalyst from '../GenerativePlanetMintPopupWithoutCatalyst/GenerativePlanetMintPopupWithoutCatalyst';
import GenerativePlanetMintPopupWithCatalyst from '../GenerativePlanetMintPopupWithCatalyst/GenerativePlanetMintPopupWithCatalyst';
import BaseButton from '../../BaseButton/BaseButton';
import GenerativePlanetMintingInfo from '../GenerativePlanetMintingInfo/GenerativePlanetMintingInfo';
import PlanetTransferPopup from '../PlanetTransferPopup/PlanetTransferPopup';
import GenerativePlanetMintCongratulationsPopup from '../GenerativePlanetMintCongratulationsPopup/GenerativePlanetMintCongratulationsPopup';
import { getPlanetCatalystBalances, getPlanetCatalysts, getPlanetMintpassBalances } from '../../../utils/ApiCalls';
import { walletContext } from '../../../utils/WalletContext';
import { useProvider } from '../../../hooks/useProvider';

export const PLANET_CATALYST_IMAGES = [
  {
    tokenId: 101,
    image: biome_aridic_icon,
  },
  {
    tokenId: 102,
    image: biome_edenic_icon,
  },
  {
    tokenId: 103,
    image: biome_tundric_icon,
  },
  {
    tokenId: 201,
    image: resource_family_stable_icon,
  },
  {
    tokenId: 202,
    image: resource_family_unstable_icon,
  },
  {
    tokenId: 203,
    image: resource_family_radioactive_icon,
  },
  {
    tokenId: 301,
    image: resource_type_solid_icon,
  },
  {
    tokenId: 302,
    image: resource_type_liquid_icon,
  },
  {
    tokenId: 303,
    image: resource_type_gas_icon,
  },
  {
    tokenId: 401,
    image: artifact_lost_civilisation_icon,
  },
  {
    tokenId: 402,
    image: artifact_natural_phenomena_icon,
  },
  {
    tokenId: 501,
    image: feature_lunar_icon,
  },
  {
    tokenId: 502,
    image: feature_orbital_rings_icon,
  },
];

export const MINT_TYPE = {
  batch: 'batch',
  single: 'single',
};

export default function GenerativePlanetMintingContent() {
  const ref = useRef(null);

  const provider = useProvider();


  const defaultPlanetMintpasses = useMemo(() => {
    return PLANET_MINTPASSES.map(planetMintpass => {
      return {
        tokenId: planetMintpass.tokenId,
        title: planetMintpass.name,
        image: planetMintpass.image,
        stock: 0,
        count: 0,
        acceptsCelestialBodies: planetMintpass.acceptsCelestialBodies,
        openseaUrl: planetMintpass.openseaUrl,
      };
    });

  }, [])

  const [transferPopup, setTransferPopup] = useState(false);
  const [congratulationsPopup, setCongratulationsPopup] = useState(false);
  const [totalPlanetsCount, setTotalPlanetsCount] = useState(0);
  const [traits, setTraits] = useState([]);
  const [catalysts, setCatalysts] = useState([]);
  const [selectedPlanet, setSelectedPlanet] = useState(EMPTY_STRING);
  const [showPopupWithoutCatalyst, setShowPopupWithoutCatalyst] = useState(false);
  const [showPopupWithCatalyst, setShowPopupWithCatalyst] = useState(false);
  const [isBatch, setIsBatch] = useState(true);
  const [planetMintpasses, setPlanetMintpasses] = useState(defaultPlanetMintpasses.slice(0,5));
  const [batchPopupValues, setBatchPopupValues] = useState(defaultPlanetMintpasses.slice(0,5));
  const [mintpassGen, setMintpassGen] = useState('1');
  const [catalystPopupValues, setCatalystPopupValues] = useState({
    planetSelected: {
      title: EMPTY_STRING,
      image: EMPTY_STRING,
    },
    ecosystem: {
      title: EMPTY_STRING,
      image: random_catalyst,
    },
    resourceState: {
      title: EMPTY_STRING,
      image: random_catalyst,
    },
    resourceActivity: {
      title: EMPTY_STRING,
      image: random_catalyst,
    },
    discoveries: {
      title: EMPTY_STRING,
      image: random_catalyst,
    },
    celestialBodies: {
      title: EMPTY_STRING,
      image: random_catalyst,
    },
  });
  const [mintSelection, setMintSelection] = useState({});

  useEffect(() => {
    const formatCatalystsWithBalances = (catalystsFromAPI, catalystBalances) => {
      const formattedCatalysts = catalystsFromAPI.map(catalystGroup => {
        const formattedTraits = catalystGroup.types.map(type => {
          let tokenBalance = catalystBalances.tokens.find(token => token.tokenId === type.tokenId);

          let traitImage = PLANET_CATALYST_IMAGES.find(trait => trait.tokenId === type.tokenId).image;

          // Format each trait/catalyst type with the following fields
          return {
            tokenId: type.tokenId,
            title: type.name,
            selected: false,
            image: traitImage,
            stock: tokenBalance.balance,
            openseaUrl: type.openseaUrl,
          };
        });

        // Format each catalyst category with the following fields
        return {
          category: catalystGroup.category,
          title: catalystGroup.displayName,
          image: add_catalyst_icon,
          selectedImage: add_catalyst_icon,
          selected: false,
          traitSelected: false,
          traits: formattedTraits,
        };
      });
      return formattedCatalysts;
    };

    getPlanetCatalysts().then(catalystsRes => {
      if (walletContext.signed && !congratulationsPopup) {
        getPlanetCatalystBalances(walletContext.currentWallet).then(balanceRes => {
          // Clear selected values when congrats modal is closed after mint completion
          setSelectedPlanet(EMPTY_STRING);
          setCatalysts(formatCatalystsWithBalances(catalystsRes, balanceRes));
          setTraits([]);
          setTotalPlanetsCount(0);
        });
      }
    });
  }, [congratulationsPopup]);

  const resetCatalystSelectibility = () => {
    let updatedCatalysts = catalysts.map(catalyst => {
      catalyst.selectableCatalyst = true;
      return catalyst;
    });
    setCatalysts(updatedCatalysts);
  };

  const disableCatalystSelectibility = title => {
    let updatedCatalysts = catalysts.map(catalyst => {
      catalyst.selectableCatalyst = catalyst.title !== title;
      if (!catalyst.selectableCatalyst) {
        // Deselect any traits for the disabled catalyst
        catalyst.traits.forEach(trait => (trait.selected = false));
        // Reset the catalyst image as the trait is now deselected
        catalyst.image = add_catalyst_icon;
        // If the disabled catalyst was selected, deselect catalyst and clear displayed traits
        if (catalyst.selected) {
          catalyst.selected = false;
          setTraits([]);
        }
      }
      return catalyst;
    });
    setCatalysts(updatedCatalysts);
  };

  useEffect(() => {
    const formatPlanetMintpassWithBalances = balances => {
      const formattedMintpasses = defaultPlanetMintpasses.map(mintpass => {
        let tokenBalance = balances.tokens.find(token => token.tokenId === mintpass.tokenId);

        return {
          ...mintpass,
          count: 0,
          stock: tokenBalance.balance,
        };
      });

      return formattedMintpasses;
    };

    if (walletContext.signed && !congratulationsPopup) {
      getPlanetMintpassBalances(walletContext.currentWallet).then(balances => {

        let formatted = formatPlanetMintpassWithBalances(balances);
        formatted = mintpassGen === '1' ? formatted.filter(mintpass => mintpass.tokenId < 200) : formatted.filter(mintpass => 200 <= mintpass.tokenId)
        if (JSON.stringify(formatted) !== JSON.stringify(planetMintpasses)) {
          setPlanetMintpasses(formatted);
          setBatchPopupValues(formatted);
        }
      });
    }
  }, [planetMintpasses, congratulationsPopup, mintpassGen, defaultPlanetMintpasses]);

  const batchPopupValuesSetter = values => {
    setBatchPopupValues(values);
    let totalPlanets = 0;
    batchPopupValues.forEach(mintpass => (totalPlanets += mintpass.count));
    setTotalPlanetsCount(totalPlanets);
  };

  const planetClickHandler = tokenId => {
    let selectedMintpass = planetMintpasses.find(mintpass => mintpass.tokenId === tokenId);

    setSelectedPlanet(tokenId);

    setCatalystPopupValues({
      ...catalystPopupValues,
      planetSelected: {
        tokenId: selectedMintpass.tokenId,
        title: selectedMintpass.title,
        image: selectedMintpass.image,
        acceptsCelestialBodies: selectedMintpass.acceptsCelestialBodies,
      },
    });

    if (!selectedMintpass.acceptsCelestialBodies) {
      disableCatalystSelectibility(CELESTIAL_BODIES_TRAIT_TITLE);
    } else {
      resetCatalystSelectibility();
    }
  };

  const catalystClickHandler = title => {
    let selectedCatalyst;

    const updatedCatalysts = catalysts.map(catalyst => {
      const isSelected = catalyst.title === title;
      catalyst.selected = isSelected;
      if (isSelected) {
        selectedCatalyst = catalyst;
      }
      return catalyst;
    });

    setCatalysts(updatedCatalysts);
    setTraits(selectedCatalyst.traits);
  };
  const traitClickHandler = title => {
    let updatedTraits = traits.map(trait => {
      if (trait.title === title) {
        if (!trait.selected) {
          trait.selected = true;
        }
      } else {
        trait.selected = false;
      }
      return trait;
    });
    let trait = updatedTraits.find(trait => trait.selected === true);
    let updatedCatalysts = catalysts.map(catalyst => {
      if (catalyst.selected) {
        if (title !== NO_TRAIT) {
          catalyst.image = trait.image;
          catalyst.selectedImage = trait.image;
          catalyst.traitSelected = false;
        } else {
          catalyst.image = add_catalyst_icon;
          catalyst.selectedImage = add_catalyst_icon;
          catalyst.traitSelected = true;
        }
      }
      return catalyst;
    });
    let updatedCatalystPopupValues = catalystPopupValues;
    updatedCatalysts.forEach(catalyst => {
      if (catalyst.selected) {
        switch (catalyst.title) {
          case ECOSYSTEM_TRAIT_TITLE:
            if (title !== NO_TRAIT) {
              updatedCatalystPopupValues.ecosystem.tokenId = trait.tokenId;
              updatedCatalystPopupValues.ecosystem.title = trait.title;
              updatedCatalystPopupValues.ecosystem.image = trait.image;
            } else {
              updatedCatalystPopupValues.ecosystem.title = EMPTY_STRING;
              updatedCatalystPopupValues.ecosystem.image = random_catalyst;
            }
            break;
          case RESOURCE_STATE_TRAIT_TITLE:
            if (title !== NO_TRAIT) {
              updatedCatalystPopupValues.resourceState.tokenId = trait.tokenId;
              updatedCatalystPopupValues.resourceState.title = trait.title;
              updatedCatalystPopupValues.resourceState.image = trait.image;
            } else {
              updatedCatalystPopupValues.resourceState.title = EMPTY_STRING;
              updatedCatalystPopupValues.resourceState.image = random_catalyst;
            }
            break;
          case RESOURCE_REACTIVITY_TRAIT_TITLE:
            if (title !== NO_TRAIT) {
              updatedCatalystPopupValues.resourceActivity.tokenId = trait.tokenId;
              updatedCatalystPopupValues.resourceActivity.title = trait.title;
              updatedCatalystPopupValues.resourceActivity.image = trait.image;
            } else {
              updatedCatalystPopupValues.resourceActivity.title = EMPTY_STRING;
              updatedCatalystPopupValues.resourceActivity.image = random_catalyst;
            }
            break;
          case DISCOVERIES_TRAIT_TITLE:
            if (title !== NO_TRAIT) {
              updatedCatalystPopupValues.discoveries.tokenId = trait.tokenId;
              updatedCatalystPopupValues.discoveries.title = trait.title;
              updatedCatalystPopupValues.discoveries.image = trait.image;
            } else {
              updatedCatalystPopupValues.discoveries.title = EMPTY_STRING;
              updatedCatalystPopupValues.discoveries.image = random_catalyst;
            }
            break;
          case CELESTIAL_BODIES_TRAIT_TITLE:
            if (title !== NO_TRAIT) {
              updatedCatalystPopupValues.celestialBodies.tokenId = trait.tokenId;
              updatedCatalystPopupValues.celestialBodies.title = trait.title;
              updatedCatalystPopupValues.celestialBodies.image = trait.image;
            } else {
              updatedCatalystPopupValues.celestialBodies.title = EMPTY_STRING;
              updatedCatalystPopupValues.celestialBodies.image = random_catalyst;
            }
            break;
          default:
            break;
        }
      }
    });
    setCatalysts(updatedCatalysts);
    setTraits(updatedTraits);
    setCatalystPopupValues(updatedCatalystPopupValues);
  };

  const switchHandler = () => {
    setIsBatch(!isBatch);
  };

  const showPopupWithoutCatalystHandler = () => {
    setShowPopupWithoutCatalyst(true);
  };

  const handleNoWithoutCatalyst = () => {
    setShowPopupWithoutCatalyst(false);
  };

  const handleYesWithoutCatalyst = () => {
    setShowPopupWithoutCatalyst(false);

    setMintSelection({
      type: MINT_TYPE.batch,
      mintpasses: batchPopupValues,
    });

    transferHandler();
  };

  const showPopupWithCatalystHandler = () => {
    setShowPopupWithCatalyst(true);
  };

  const handleNoWithCatalyst = () => {
    setShowPopupWithCatalyst(false);
  };

  const handleYesWithCatalyst = () => {
    setShowPopupWithCatalyst(false);

    const selectedCatalysts = [];

    catalysts.forEach(category => {
      category.traits.forEach(trait => {
        if (trait.selected) {
          selectedCatalysts.push(trait.tokenId);
        }
      });
    });

    setMintSelection({
      type: MINT_TYPE.single,
      mintpass: catalystPopupValues.planetSelected.tokenId,
      catalysts: selectedCatalysts,
    });

    transferHandler();
  };

  const scrollToInfo = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const transferHandler = () => {
    setTransferPopup(!transferPopup);
  };

  const congratulationsHandler = () => {
    setCongratulationsPopup(!congratulationsPopup);
  };

  const handleMintpassGenChange = (event) => {
    setMintpassGen(event.target.value);
  };

  return (
    <div className="planet-minting-content">
      <div className="planet-minting-switch">
        <div className={`planet-minting-switch-title ${!isBatch ? 'disabled-title' : ''}`}>BATCH SELECT</div>
        <label className="minting-switch">
          <input type="checkbox" />
          <span className="slider round" onClick={switchHandler} />
        </label>
        <div className={`planet-minting-switch-title ${isBatch ? 'disabled-title' : ''}`}>ADD CATALYST</div>
      </div>
      <Form.Select aria-label="mintpass-gen-selection" onChange={handleMintpassGenChange}>
        <option value="1">Generation 1</option>
        <option value="2">Generation 2</option>
      </Form.Select>
      {isBatch ? (
        <>
          <div>
            <GenerativePlanetMintpassGrid
              scrollToInfo={scrollToInfo.bind(this)}
              isCatalyst={false}
              selected={EMPTY_STRING}
              selectHandler={batchPopupValuesSetter.bind(this)}
              planetMintpasses={batchPopupValues}
              isBatch={isBatch}
              mintpassGen={mintpassGen}
            />
            {showPopupWithoutCatalyst ? (
              <GenerativePlanetMintPopupWithoutCatalyst
                planetMintpasses={batchPopupValues}
                handleNo={handleNoWithoutCatalyst}
                handleYes={handleYesWithoutCatalyst}
              />
            ) : (
              EMPTY_STRING
            )}
          </div>
          <div className={'planet-minting-button-container'}>
            <BaseButton
              onClick={showPopupWithoutCatalystHandler}
              disabled={totalPlanetsCount < 1 || totalPlanetsCount > MAXIMUM_PLANET_MINTS ? true : false}
              filled={true}
              scale={true}
              text={'MINT PLANETS'}
            />
          </div>
        </>
      ) : (
        <>
          <div>
            <GenerativePlanetMintpassGrid
              scrollToInfo={scrollToInfo.bind(this)}
              isCatalyst={true}
              selected={selectedPlanet}
              selectHandler={planetClickHandler.bind(this)}
              planetMintpasses={batchPopupValues}
              isBatch={isBatch}
              mintpassGen={mintpassGen}
            />
            <GenerativePlanetCatalystGrid
              disabled={selectedPlanet === EMPTY_STRING}
              isCatalystFlow={true}
              selectHandler={catalystClickHandler.bind(this)}
              catalysts={catalysts}
            />
            <GenerativeTraitGrid
              catalysts={catalysts}
              selectHandler={traitClickHandler.bind(this)}
              isCatalystFlow={true}
              isDisabled={traits.length === 0}
              traits={traits}
            />
            {showPopupWithCatalyst ? (
              <GenerativePlanetMintPopupWithCatalyst
                popupValues={catalystPopupValues}
                handleNo={handleNoWithCatalyst}
                handleYes={handleYesWithCatalyst}
              />
            ) : (
              ''
            )}
          </div>
          <div className={'planet-minting-button-container'}>
            <BaseButton
              onClick={showPopupWithCatalystHandler}
              disabled={selectedPlanet ? false : true}
              filled={true}
              scale={true}
              text={'MINT PLANET'}
            />
          </div>
        </>
      )}

      {isBatch && (
        <div className={'planet-minting-total-counter'}>
          {totalPlanetsCount}/{MAXIMUM_PLANET_MINTS}
        </div>
      )}
      <div ref={ref} className={'generative-planet-info'}>
        <GenerativePlanetMintingInfo />
      </div>

      {transferPopup && (
        <PlanetTransferPopup
          show={transferPopup}
          mintSelection={mintSelection}
          catalystCheck={!isBatch ? false : undefined}
          handleClose={transferHandler.bind(this)}
          handleCongratulations={congratulationsHandler}
          provider={provider}
        />
      )}
      {congratulationsPopup && <GenerativePlanetMintCongratulationsPopup handleClose={congratulationsHandler.bind(this)} />}
    </div>
  );
}
