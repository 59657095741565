import "./ExplorationRewards.scss";
import ExplorationRewardsAstrafer from "../ExplorationRewardsAstrafer/ExplorationRewardsAstrafer";
import ExplorationRewardsPlanet from "../ExplorationRewardsPlanet/ExplorationRewardsPlanet";
import { boarder_detail_bottom } from "../../../assets/images/AstrafiteRushEvent";
import React, { useEffect, useState } from "react";
import { getMission } from "../../../utils/ApiCalls";
import LoadingSpinner from '../../LoadingSpinner';

const defaultRewards = {
  astrafer: {
    minGain: 0,
    maxGain: 0,
    perCheckpoint: 0
  },
  planets: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0
  }
};

export default function ExplorationRewards({mission, planets, planetsCount,handleMissionLaunch, handleError, missionLaunchText }) {

  const [missionDetails, setMissionDetails] = useState(null);
  const [rewards, setRewards] = useState(defaultRewards);
  const [selectedPlanets, setSelectedPlanets] = useState([]);

  useEffect(() => {
    const fetchMissionDetails = async () => {
      try {
        const missionDetails = await getMission(mission.id);
        if (missionDetails) {
          setMissionDetails(missionDetails);
        }
      } catch (e) {
        console.error(`Failed to fetch mission details: ${e}`);
        handleError('Something went wrong, unable to load mission details.');
      }
    };
    if (mission) {
      fetchMissionDetails();
    }
  }, [mission, handleError]);

  const formatSelectedPlanets = (planets) => {
    return planets.map(planetType =>  {
      const formattedPlanets = planetType.planets
      .filter(planet => planet.isSelected === true)
      .map(planet => {
        return {
          tokenId: planet.tokenId,
          generation: planet.generation
        };
      });

      return {
        planetSize: planetType.planetSize,
        planets: formattedPlanets
      }
    })
  };

  useEffect(() => {
    setSelectedPlanets(formatSelectedPlanets(planets));
  }, [planets])

  const calcAstraferPerCheckpoint = (astraferRewards, checkpointCount) => {
    return (astraferRewards.maxGain - astraferRewards.minGain) / checkpointCount;
  }

  // Calculate rewards
  useEffect(() => {
    const rewards = {
      astrafer: {
        minGain: 0,
        maxGain: 0,
        perCheckpoint: 0
      },
      planets: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0
      }
    };

    if (missionDetails && selectedPlanets) {
      for (const planetType of selectedPlanets) {
        let rewardDefinition = missionDetails.rewards.find(reward => reward.enteredPlanetSize === planetType.planetSize)
        if (rewardDefinition) {
          // Calculate astrafer rewards
          rewards.astrafer.minGain += planetType.planets.length * rewardDefinition.minAstrafer;
          rewards.astrafer.maxGain += planetType.planets.length * rewardDefinition.maxAstrafer;

          if (rewardDefinition.rewardedPlanetTokenId) {
            // Determine planet rewards
            rewards.planets[planetType.planetSize] = rewardDefinition.rewardedPlanetEligibilityRequirements
              // Note: only supports 'generation' eligibility rules, based on current Recon Mission requirements
              ?
              planetType.planets.filter(
                planet => rewardDefinition.rewardedPlanetEligibilityRequirements.generation ===
                  planet.generation).length
              :
              planetType.planets.length;
          }
        }
      }

      rewards.astrafer.perCheckpoint = calcAstraferPerCheckpoint(rewards.astrafer, missionDetails.checkpointCount);
      setRewards(rewards);
    }
  }, [selectedPlanets, missionDetails]);

  const countSelectedPlanets = () => selectedPlanets.reduce((total, planetType) => total + planetType.planets.length, 0);

  return <div className={'exploration-rewards-container'}>
    <div className={'exploration-rewards-elements-container'}>
      <ExplorationRewardsAstrafer minGain={rewards.astrafer.minGain} maxGain={rewards.astrafer.maxGain}
                                  daysDuration={mission.duration} checkpoints={mission.checkpointCount}
                                  astraferPerCheckpoint={rewards.astrafer.perCheckpoint}/>
      <ExplorationRewardsPlanet largePlanet={rewards.planets[5]} mediumPlanet={rewards.planets[4]}
                                smallPlanet={rewards.planets[3]} largeAsteroid={rewards.planets[2]}
                                smallAsteroid={rewards.planets[1]}/>
    </div>
    <div className={'total-planets-total-decoration'}>
      <div className={"line-separator"}/>
      <img src={boarder_detail_bottom} alt={'Bottom decoration'}/>
    </div>
    <div className={'exploration-rewards-planets-counter'}>
      TOTAL PLANETS: {planetsCount}<br/>
      (PLANETS SELECTED: {countSelectedPlanets()})
    </div>
    {missionLaunchText ? (
      <>
        <p className="exploration-launch-mission-display-text">{missionLaunchText}</p>
        <LoadingSpinner />
      </>
    ) : (
      <div
        onClick={handleMissionLaunch}
        className={countSelectedPlanets() > 0 ? 'exploration-rewards-button-container' : 'exploration-rewards-button-container disabled'}
      >
        LAUNCH
      </div>
    )}
  </div>
}
