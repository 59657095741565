import './BaseButton.scss';

export default function BaseButton({text, onClick, disabled, filled, scale}) {

  const getButtonClass = () => {
    let className = "base-button";
    if (scale) {
      className += " scale";
    } else {
      className += " no-scale";
    }
    if (disabled) {
      className += " disabled";
    }
    return className;
  }

  return (
    <div onClick={onClick} className={getButtonClass()}>
      <div className={filled ? 'base-button-filled' : 'base-button-not-filled'}>{text}</div>
    </div>
  );
}
