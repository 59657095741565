import './DashboardPlanet.scss';
import { planet_lock, robot_icon } from '../../../assets/images/ExplorationDashboard';
import { useEffect, useRef, useState } from 'react';
import { BOUND_IN_MISSION_STATUS, PLANET_IN_MISSION_STATUS } from '../../../utils/GeneralUtils';

export default function DashboardPlanet({ planet, planetCallback, isDashboard = true }) {
  const imgEl = useRef(null);
  const [planetState, setPlanetState] = useState(planet);

  const selectHandler = () => {
    if (isDashboard) {
      if (!planet.isSelected) {
        planetCallback();
      }
    } else {
      planetCallback();
    }
    let updatedPlanet = { ...planetState, isSelected: !planetState.isSelected };
    setPlanetState(updatedPlanet);
  };

  useEffect(() => {
    setPlanetState(planet);
  }, [planet]);

  let imageContainerClassName = 'dashboard-planet-image-container clickable';
  if (!isDashboard && planet.status === PLANET_IN_MISSION_STATUS) {
    imageContainerClassName = 'dashboard-planet-image-container';
  }

  useEffect(() => {
    const imgRef = imgEl.current;
    imgRef.addEventListener('error', function (event) {
      event.target.src = `${process.env.REACT_APP_PLANET_SERVICE_METADATA_BASE_URL}/static/default/image.350.png`;
      event.onerror = null;
    });
  });

  return (
    <div className="dashboard-planet-container">
      <div onClick={selectHandler} className="dashboard-planet-content">
        <div className={planet.isSelected ? `${imageContainerClassName} selected` : imageContainerClassName}>
          <img ref={imgEl} src={planetState.image} alt="PLANET" className="dashboard-planet-image" />
          {planet.status === BOUND_IN_MISSION_STATUS && <img src={robot_icon} alt="BOUND IN MISSION" className="dashboard-planet-overlay" />}
          <div className="dashboard-planet-buttons">
            {planet.status === PLANET_IN_MISSION_STATUS && <img src={planet_lock} alt="LOCKED" className="dashboard-planet-lock" />}
          </div>
        </div>
        <div className="dashboard-planet-info-container">
          <div className="dashboard-planet-info">{planetState.name}</div>
        </div>
      </div>
    </div>
  );
}
