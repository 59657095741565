import './StarfighterTransferPopup.scss';
import { checkbox_checked, checkbox_unchecked, popupBottomBorderRed, popupTopBorderRed } from '../../../assets';
import BaseButton from '../../BaseButton/BaseButton';
import React, { useEffect, useState } from 'react';
import { getBlockchainContract } from '../../../utils/providerObject';
import { catalystPGERC1155Address, PGERC1155Address_Avatar_Starfighter, starfighterSuperMintConverterAddress } from '../../../utils/Globals';
import PGERC1155 from '@fugu/base-contracts/dist/abis/Tokens/ControlledMint.ERC1155.sol/PGERC1155.json';
import superMintConverter from '@fugu/base-contracts/dist/abis/Converter/SuperMintConverter.sol/SuperMintConverter.json';
import { MINT_TYPE } from '../GenerativeStarfighterMintingContent/GenerativeStarfighterMintingContent';
import { Modal } from 'react-bootstrap';
import ModalCloseButton from '../../Modal/ModalCloseButton/ModalCloseButton';
import { hasApproval, requestApproval } from '../../../utils/ContractUtils';
import { walletContext } from '../../../utils/WalletContext';
import LoadingSpinner from '../../LoadingSpinner';
import { getStarfighterCatalystSignature } from '../../../utils/ApiCalls';

export default function StarfighterTransferPopup({ show, mintSelection, handleClose, handleCongratulations, provider }) {
  const pgERC1155StarfighterMintpass = getBlockchainContract(PGERC1155Address_Avatar_Starfighter, PGERC1155.abi, provider);
  const pgERC1155Catalyst = getBlockchainContract(catalystPGERC1155Address, PGERC1155.abi, provider);
  const starfighterSuperMintConverter = getBlockchainContract(starfighterSuperMintConverterAddress, superMintConverter.abi, provider);

  const catalystUsed = mintSelection => {
    return mintSelection.type === MINT_TYPE.single && mintSelection.catalysts.length > 0;
  };

  const setChecked = (stepName, checked = true) => {
    let stepToUpdate = mintingChecklist.find(step => step.name === stepName);
    stepToUpdate.checked = checked;

    setMintingChecklist([...mintingChecklist]);
  };

  const requestContractApproval = async mintingStep => {
    // Set checked to null so loading spinner shows while processing request with blockchain
    setChecked(mintingStep.name, null);
    let result = await requestApproval(mintingStep.contract, starfighterSuperMintConverterAddress);
    setChecked(mintingStep.name, result);
  };

  const requestCatalystApproval = async () => {
    await requestContractApproval(STARFIGHTER_MINTING_STEPS.approveCatalystTransfer);
  };

  const requestMintpassApproval = async () => {
    await requestContractApproval(STARFIGHTER_MINTING_STEPS.approveMintpassTransfer);
  };

  const processMint = async () => {
    let mintingResult;

    setChecked(STARFIGHTER_MINTING_STEPS.processMint.name, null);

    // batch mint
    if (mintSelection.type === MINT_TYPE.batch) {
      const tokenIds = [];
      const mintAmounts = [];

      mintSelection.mintpasses.forEach(mintpass => {
        if (mintpass.count > 0) {
          tokenIds.push(mintpass.tokenId);
          mintAmounts.push(mintpass.count);
        }
      });

      mintingResult = await starfighterSuperMintConverter.burnTokensMultiple(walletContext.currentWallet, tokenIds, mintAmounts);
    }
    // single mint but no catalysts selected
    else if (mintSelection.catalysts.length < 1) {
      mintingResult = await starfighterSuperMintConverter.burnTokens(walletContext.currentWallet, mintSelection.mintpass, 1);
      // single mint with at least 1 catalyst selected
    } else {
      let { signature, structArray } = await getStarfighterCatalystSignature(
        walletContext.currentWallet,
        mintSelection.mintpass,
        mintSelection.catalysts,
      );

      mintingResult = await starfighterSuperMintConverter.burnSuperToken(structArray, signature);
    }
    await mintingResult.wait();

    if (mintingResult) {
      setChecked(STARFIGHTER_MINTING_STEPS.processMint.name, true);
      handleClose();
      handleCongratulations();
    }
  };

  let handleClick = async clickAction => {
    try {
      await clickAction();
    } catch (e) {
      console.error(e);
    }
  };

  const STARFIGHTER_MINTING_STEPS = {
    approveMintpassTransfer: {
      name: 'approveMintpassTransfer',
      title: 'Approve Starfighter Mintpass transfer',
      description: 'This is required so we can transfer your Starfighter Mintpass(es) when minting your Starfighter (once per wallet).',
      checked: null,
      buttonText: 'Approve for Transfer',
      handleClick: requestMintpassApproval,
      contract: pgERC1155StarfighterMintpass,
    },
    approveCatalystTransfer: {
      name: 'approveCatalystTransfer',
      title: 'Approve Phantom Catalyst transfer',
      description: 'This is required so we can transfer your Starfighter Catalyst(s) when minting your Starfighter (once per wallet).',
      checked: null,
      buttonText: 'Approve for Transfer',
      handleClick: requestCatalystApproval,
      contract: pgERC1155Catalyst,
    },
    processMint: {
      name: 'processMint',
      title: mintSelection.type === MINT_TYPE.batch && mintSelection.mintpasses.length > 1 ? 'Mint Starfighters' : 'Mint Starfighter',
      description:
        mintSelection.type === MINT_TYPE.batch
          ? 'This will process your mint, exchanging your mintpass(es) for bespoke Starfighter NFTs!'
          : 'This will process your mint, exchanging your mintpass and catalysts for a bespoke starfighter NFT!',
      checked: false,
      buttonText: mintSelection.type === MINT_TYPE.batch && mintSelection.mintpasses.length > 1 ? 'Mint Starfighters' : 'Mint Starfighter',
      handleClick: processMint,
      contract: starfighterSuperMintConverter,
    },
  };

  const determineChecklist = () => {
    return catalystUsed(mintSelection)
      ? [STARFIGHTER_MINTING_STEPS.approveMintpassTransfer, STARFIGHTER_MINTING_STEPS.approveCatalystTransfer, STARFIGHTER_MINTING_STEPS.processMint]
      : [STARFIGHTER_MINTING_STEPS.approveMintpassTransfer, STARFIGHTER_MINTING_STEPS.processMint];
  };

  const [mintingChecklist, setMintingChecklist] = useState(determineChecklist());

  // When the modal is opened, refresh the values
  useEffect(() => {
    setMintingChecklist(determineChecklist());

    async function fetchContractApprovalStatus(stepName) {
      // Set checked to null while loading so spinner shows
      setChecked(stepName, null);

      let checklistItem = mintingChecklist.find(step => step.name === stepName);
      let approved = await hasApproval(checklistItem.contract, walletContext.currentWallet, starfighterSuperMintConverterAddress);

      return approved;
    }

    fetchContractApprovalStatus(STARFIGHTER_MINTING_STEPS.approveMintpassTransfer.name).then(result =>
      setChecked(STARFIGHTER_MINTING_STEPS.approveMintpassTransfer.name, result),
    );

    if (catalystUsed(mintSelection)) {
      fetchContractApprovalStatus(STARFIGHTER_MINTING_STEPS.approveCatalystTransfer.name).then(result => {
        setChecked(STARFIGHTER_MINTING_STEPS.approveCatalystTransfer.name, result);
      });
    }
    //eslint-disable-next-line
  }, [mintSelection, show, STARFIGHTER_MINTING_STEPS.approveMintpassTransfer.name, STARFIGHTER_MINTING_STEPS.approveCatalystTransfer.name]);

  const checkDisabledStatus = index => {
    return index !== 0 && !mintingChecklist[index - 1].checked;
  };

  const findActiveChecklistStep = () => {
    return mintingChecklist.find((step, index) => !checkDisabledStatus(index) && !step.checked);
  };

  const transferButtonHandler = () => {
    let activeStep = findActiveChecklistStep();

    handleClick(activeStep.handleClick);
  };

  return (
    <Modal show size="lg" centered className="planet-transfer-container" onHide={handleClose}>
      <img src={popupTopBorderRed} alt="BORDER" className="planet-transfer-top-border" />
      <ModalCloseButton clickEvent={handleClose} />
      <div className="planet-transfer-content">
        {mintingChecklist.map((item, index) => (
          <div key={index} className="planet-transfer-item">
            {item.checked !== null ? (
              item.checked ? (
                <img src={checkbox_checked} alt="CHECKED" className="planet-transfer-item-check" />
              ) : (
                <img src={checkbox_unchecked} alt="UNCHECKED" className="planet-transfer-item-check" />
              )
            ) : (
              <LoadingSpinner size="sm" marginBottom="0px" />
            )}
            <div className={checkDisabledStatus(index) ? 'planet-transfer-item-content grayed' : 'planet-transfer-item-content'}>
              <div className="planet-transfer-item-title">{item.title}</div>
              <div className="planet-transfer-item-description">{item.description}</div>
            </div>
          </div>
        ))}
        <div className="planet-transfer-button-container">
          <BaseButton
            text={findActiveChecklistStep()?.buttonText.toUpperCase() || 'CLOSE'}
            onClick={transferButtonHandler}
            disabled={false}
            filled={true}
            scale={false}
          />
        </div>
      </div>
      <img src={popupBottomBorderRed} alt="BORDER" className="planet-transfer-bottom-border" />
    </Modal>
  );
}
