import './HeaderStyle.scss';
import HeaderBurgerMenu from "../HeaderBurgerMenu/HeaderBurgerMenu";
import React from "react";
import TopMenu from "../TopMenu/TopMenu";

export default function HeaderComponent(props) {
  return (
    <div className={props.noBackground === true ? "topbar-no-background" : "topbar-with-background"}>
      <div id='burger-menu'>
        <HeaderBurgerMenu/>
      </div>
      <TopMenu/>
    </div>
  );

}
