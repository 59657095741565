import '../../PlanetExploration/PlanetExplorationMissionList/PlanetExplorationMissionList.scss';
import MissionTab from '../../MissionTab/MissionTab';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { findMissionTabContent } from '../../../utils/GeneralUtils';

import {MissionStatusIndex} from "../../PlanetExploration/PlanetExplorationContent/PlanetExplorationContent";

export default function ExplorationLandingMissionList({ missionEntries }) {
  const navigate = useNavigate();

  const handleMissionEntrySelection = missionEntry => {
    navigate('/portfolio/tcq-recon/view-mission', { state: { selectedMissionId: missionEntry?.id } });
  };

  return (
    <>
      <div className={'planet-exploration-missions-main-container-landing'}>
        <div className={'planet-exploration-missions-landing-container'}>
          {missionEntries
            .sort((a, b) => MissionStatusIndex[findMissionTabContent(a)] - MissionStatusIndex[findMissionTabContent(b)])
            .map((entry, index) => (
            <div key={index} className={'planet-exploration-mission-tab'} onClick={() => handleMissionEntrySelection(entry)}>
              <MissionTab title={`MISSION ${entry.id}`} content={findMissionTabContent(entry)} key={index} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
