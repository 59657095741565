import { Modal } from "react-bootstrap";
import { bottom_horns_arrow, header_horns } from "../../../assets/images/PopUps";
import React from "react";
import "./ErrorModal.scss";
import { errorTypeModalCss } from "../../../utils/GeneralUtils";
import GenericButton from "../../Button/GenericButton";
import { closeIcon, exclamation_icon } from "../../../assets";

export default function ErrorModal(props) {
  const { title, text, handleConfirm, onHide, errorType } = props;

  return (
    <Modal
      {...props}
      show={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={errorTypeModalCss[errorType ?? "defaultError"]}
    >
      <img alt="" className={"error-modal-header-horns"} src={header_horns} />
      <div className={"error-modal-close-container"} onClick={onHide}>
        <img alt={""} className={"error-modal-close-button"} src={closeIcon} />
      </div>
      <div className={"error-modal-body"}>
        <img alt={"exclamation icon"} className={"error-modal-exclamation-icon"} src={exclamation_icon} />
        {<p className={"error-modal-title"}>{title ?? "Error"}</p>}
        {text && <p className={"error-modal-text"}>{text}</p>}
        {handleConfirm && <GenericButton handleClick={onHide} buttonText={"OK"} buttonClass="error-modal-button" />}
      </div>
      <img alt="" className={"error-modal-header-horns-bottom"} src={bottom_horns_arrow} />
    </Modal>
  );
}
