import './ClaimOtherInfo.scss'
import ClaimInfoElement from "../../ClaimAstrafer/ClaimInfoElement/ClaimInfoElement";

export default function ClaimOtherInfo({totalAvailableAmountDisplay, totalRemainingAmountDisplay, nextClaimDate, isFinalClaim}){
  return <>
    <div className={'claim-other-info-main-container'}>
      <div className={'total-available-container'}>
        <ClaimInfoElement title={'TOTAL ASTRAFER AVAILABLE TO CLAIM'} text={totalAvailableAmountDisplay}/>
      </div>
      <div className={'total-remaining-container'}>
        <ClaimInfoElement title={'TOTAL ASTRAFER REMAINING'} text={totalRemainingAmountDisplay}/>
      </div>
      <div className={'next-date-container'}>
        <ClaimInfoElement title={isFinalClaim ? 'DATE OF FINAL CLAIM' : 'DATE OF NEXT CLAIM'} text={nextClaimDate}/>
      </div>

    </div>
  </>
}
