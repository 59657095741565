import React from 'react';
import './GenesisNFTsDescription.scss';
import { buy_on_opensea_icon, token_coin } from '../../../assets';

export default function GenesisNFTsDescription({
  setShowMintWithAstrafer,
  setShowCombinations,
  title,
  imgUsed,
  description,
  moreInfoLink,
  conversionButtonText,
  disableMintWithAstrafer,
  disableConversion,
  openseaUrl,
}) {
  // const showCombinations = () => {
  //   setShowCombinations()
  // }
  const astraferMintButtonHandler = () => {
    setShowMintWithAstrafer(true);
  };

  const openSeaButtonClickHandler = () => {
    window.open(openseaUrl, '_blank');
  };

  return (
    <>
      <div className={'episode-header'}>
        <div className={'genesis-nfts-title'}>
          <h2> {title}</h2>
        </div>
        <img className={'genesis-nfts-image-tbc'} src={imgUsed} alt="" />
      </div>
      <div className={'genesis-nft-body'}>
        <p className={'title'}>Description:</p>
        {description}
        <a href={moreInfoLink} target={'_blank'} rel={'noreferrer'} className={'more-info'}>
          <br />
          MORE INFO...
        </a>
      </div>
      <div className={'genesis-nfts-panel'}></div>
      <div className={'mint-buttons-container'}>
        <div
          onClick={!disableMintWithAstrafer ? astraferMintButtonHandler : undefined}
          className={disableMintWithAstrafer ? 'astrafer-mint-button disabled' : 'astrafer-mint-button'}
        >
          <img src={token_coin} alt={''} />
          MINT WITH ASTRAFER
        </div>
        {/* <div  onClick={!disableConversion ? showCombinations : undefined } className={disableConversion ? 'poster-mint-button disabled' : 'poster-mint-button'}>
        {conversionButtonText}
      </div> */}
        <div className="buy-on-opensea-button" onClick={openSeaButtonClickHandler}>
          <img src={buy_on_opensea_icon} alt={''} />
          BUY ON OPENSEA
        </div>
      </div>
    </>
  );
}
