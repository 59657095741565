import React, {useEffect} from "react";
import "./PlanetSalePagePrivate.scss"
import PlanetGenesisSale from "./components/PlanetGenesis/PlanetGenesisSale";
import PrivateSale from "./components/PrivateSale/PrivateSale";
import {PlanetSaleProvider} from "../../components/Context/PlanetSalePageContext";
import HeaderComponent from "../../components/Header/HeaderComponent";
import Footer from "../../components/Footer/Footer";
import FighterLady from "../../components/Footer/FooterElements/FighterLady/FighterLady";
import initializeTimelines from "../../old/scripts/timelines";
import ShowOnScroll from "../../old/scripts/show-on-scroll";

export default function PlanetSalePagePrivate() {
  function componentDidMount() {
    initializeTimelines();
    setTimeout(function () {
      var instShowOnScroll = new ShowOnScroll();
      instShowOnScroll.init();
    }, 250);
  }
  useEffect(() => {
    componentDidMount();
  })

  return <>
    <HeaderComponent noBackground={true}/>

    <PlanetGenesisSale/>
    <PlanetSaleProvider>
      <PrivateSale/>
    </PlanetSaleProvider>


    <Footer>
      <FighterLady/>
    </Footer>
  </>
}
