import './PGModal.scss';

import { Modal, ModalProps } from 'react-bootstrap';
import { bottom_horns_arrow, header_horns } from '../../../assets/images/PopUps';
import { PropsWithChildren } from 'react';

type PGModalProps = {
  show: boolean;
  onHide: () => void;
  onExited?: (node: HTMLElement) => void;
  bodyClassName?: string;
  preventModalClose?: boolean;
} & ModalProps;

export default function PGModal({
  children,
  show,
  onExited,
  onHide,
  dialogClassName,
  bodyClassName,
  contentClassName,
  backdrop,
  preventModalClose,
}: PropsWithChildren<PGModalProps>) {
  return (
    <Modal
      show={show}
      centered={true}
      dialogClassName={dialogClassName ?? ''}
      contentClassName={`pg-modal ${contentClassName ?? ''}`}
      onExited={node => onExited?.(node)}
      onHide={onHide}
      backdrop={backdrop ? backdrop : undefined}
    >
      <img alt="" className="pg-modal-top-horns" src={header_horns} />
      {preventModalClose && <Modal.Header style={{ borderBottom: 'none' }} />}
      {!preventModalClose && <Modal.Header closeButton closeVariant="white" style={{ borderBottom: 'none' }}></Modal.Header>}
      <div className={`pg-modal-body ${bodyClassName ?? ''}`}>{children}</div>
      <img alt="" className="pg-modal-bottom-horns" src={bottom_horns_arrow} />
    </Modal>
  );
}
