import React from "react";
import "./SubscribeComponent.scss";
import {
  brandico_facebook_icon,

  discord_icon,
  fa_paper_plane_icon,

  medium_logo_m_icon,
  simple_icons_instagram,
  small_background_from_subscribe,
  subscribe_background_image,
  subscribe_crown_img,
  subscribe_wing_left,
  subscribe_wing_right,
  twitch_large_icon, youtube_logo
} from "../../../../assets/images/Footer";
import {socialIconPaths} from "../../../../utils/GeneralUtils";
import {x_logo} from "../../../../assets";


export default function SubscribeComponent(props) {

  return <>
    <div className={"subscribe-component-main-container"}>
        <div className={"no-mobile-props-child"}>{props.children}</div>
      <div className={"subscribe-component-container"}>
        <div className={"subscribe-title"}>
          <img alt={""} src={subscribe_wing_left} className={"footer-subscribe-parenthesis"}/>
          <div className={"subscribe-title-text"}>
            <p className={"subscribe-title-text-size"}>SUBSCRIBE</p>
            <p className={"subscribe-title-slogan"}>join us in the stars</p>
          </div>
          <img alt={""} src={subscribe_wing_right} className={"footer-subscribe-parenthesis"}/>
        </div>
        <div className={"subscribe-area-design"}>
          <div className={"email-area-input"}>
            <form
              action="https://static.mailerlite.com/webforms/submit/f2s1e8"
              method="post"
              data-code="f2s1e8"
              target="_blank"
            ><div className={"control-the-input-value-container"}>
              <input className={"input-email-value"} placeholder={"Email address"} type="email" name="fields[email]"/>
              <input type="hidden" name="ml-submit" value="1"/>
              <input type="hidden" name="anticsrf" value="true"/>
              <button className={"subscribe-sign-up-button"} type="submit">SIGN UP</button>
            </div>
            </form>
          </div>
        </div>
        <div className={"media-link-buttons"}>
          <div className={"subscribe-title-slogan"}>join our community</div>
          <div className={"main-container-for-media-links"}>
            <div className={"size-container-for-media-links"}>
              <a href={socialIconPaths.discord} target="_blank" rel="noreferrer">
                <img alt=""
                     className={"icons-smoot-transition"}
                     src={discord_icon}/>

              </a>
              <a href={socialIconPaths.telegram} target="_blank" rel="noreferrer">
                <img alt=""
                     className={"icons-smoot-transition"}
                     src={fa_paper_plane_icon}/>

              </a>
              <a href={socialIconPaths.twitter} target="_blank" rel="noreferrer">
                <img alt=""
                     className={"icons-smoot-transition"}
                     src={x_logo}/>

              </a>
              <a href={socialIconPaths.youtube} target="_blank" rel="noreferrer">
                <img alt=""
                     className={"icons-smoot-transition"}
                     src={youtube_logo}/>

              </a>
              <a href={socialIconPaths.instagram} target="_blank" rel="noreferrer">
                <img alt=""
                     className={"icons-smoot-transition"}
                     src={simple_icons_instagram}/>
              </a>
              <a href={socialIconPaths.facebook}
                 target="_blank" rel="noreferrer">
                <img alt=""
                     className={"icons-smoot-transition"}
                     src={brandico_facebook_icon}/>
              </a>
              <a href={socialIconPaths.twitch}
                 target="_blank" rel="noreferrer">
                <img alt=""
                     className={"icons-smoot-transition"}
                     src={twitch_large_icon}/>
              </a>
              <a href={socialIconPaths.medium}
                 target="_blank" rel="noreferrer">
                <img alt=""
                     className={"icons-smoot-transition"}
                     src={medium_logo_m_icon}/>
              </a>
            </div>
          </div>
          <img alt={""} src={subscribe_crown_img} className={"adjust-crown-img"}/>
        </div>
      </div>
      <div className={"mobile-props-child"}>{props.children}</div>
      <div className={"subscribe-background"}>
        <img alt={""} className={"subscribe-element-background-big-img"} src={subscribe_background_image}/>
        <img alt={""} className={"subscribe-element-background-small-img"} src={small_background_from_subscribe}/>
      </div>
    </div>
  </>
}
