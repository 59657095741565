import React, {useEffect, useState} from "react";
import "./PieChart.scss";
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import {Pie} from 'react-chartjs-2';
import {hover_triangle} from "../../assets/images/AstrafiteRushEvent";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function PieChart() {

  const pve = "#176374";
  const listings_liquidity_pools = "#189FBC";
  const ecosystems_fund = "#873149";
  const planet_emissions = "#D42758";
  const launch_contributions = "#FF6B95";
  const reserve = "#93ABFF";
  const marketing = "#5574E1";
  const blowfish_team = "#3363DE";
  const PvP_rogue_planets = "#0D38A6";

  function testMobile() {
    let check = false;
    (function (a) {
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(46)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(ev)w|bumb|bw-(nu)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(cp)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(mp|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(- |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(tv)a|jbro|jemu|jigs|kddi|keji|kgt( )|klon|kpt |kwc-|kyo(ck)|le(no|xi)|lg( g|\/(klu)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| ov)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(02)|n50(025)|n7(0(01)|10)|ne((cm)-|on|tf|wf|wg|wt)|nok(6i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(adt)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-01)|47|mc|nd|ri)|sgh-|shar|sie(-m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(im)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(- )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0, 4))) check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }

  const isMobile = testMobile();

  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
  })

  const detectSize = () => {
    if (window.innerWidth > 375) {
      detectHW({
        winWidth: window.innerWidth,
      })
    } else {
      detectHW({
        winWidth: window.screen.width,
      })
    }
  }

  const colorsFromChart = [
    pve,
    listings_liquidity_pools,
    ecosystems_fund,
    planet_emissions,
    launch_contributions,
    reserve,
    marketing,
    blowfish_team,
    PvP_rogue_planets,
  ]
  const [hovering, setHovering] = useState(colorsFromChart);
  const [checkIndex, setCheckIndex] = useState();
  const [chartSpecifications, setChartSpecifications] = useState({cutOutDimension: 100, checkEvent: "onClick"});
  const hoveringColorsAlignment = [
    hovering[0],
    hovering[1],
    hovering[2],
    hovering[3],
    hovering[4],
    hovering[5],
    hovering[6],
    hovering[7],
    hovering[8],

  ]
  const chartDataValue = [
    '13.5',
    '10',
    '6.0',
    '29.1',
    '5.0',
    '17',
    '7',
    '10',
    '2.4',

  ];
  const data = {
    labels: [],
    datasets: [{
      data: chartDataValue,
      backgroundColor: hoveringColorsAlignment,
      hoverOffset: 0,
      cutout: chartSpecifications.cutOutDimension,
      borderWidth: 0,
      hoverBackgroundColor: hoveringColorsAlignment,

    }]
  };

  function disableHovering() {
    setCheckIndex(-1);
    setHovering(colorsFromChart);

  }

  const options = {
    responsive: true,
    events: ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
    plugins: {
      tooltip: {
        enabled: false,
      },

    },
    animation: {
      colors: false,
      duration: 1500
    },

    [chartSpecifications.checkEvent]: (e, elements) => {
      if (elements.length > 0) {

        if (elements[0].index !== checkIndex && checkIndex !== -1) {
          hoveringTheSelectedElement(elements[0].index);
        }
      } else {
        if (JSON.stringify(hovering) !== JSON.stringify(colorsFromChart)) {
          setCheckIndex(-2);
          setHovering(colorsFromChart);
        }
      }
    },
  };

  function hoveringTheSelectedElement(indexValue) {
    setCheckIndex(indexValue);
    let auxArray = colorsFromChart;

    for (let index = 0; index < auxArray.length; index++) {
      if (indexValue !== index) {
        if (index % 2 === 0) {
          auxArray[index] = "rgba(255, 255, 255,0.1)";
        } else {
          auxArray[index] = "rgba(255, 255, 255,0.25)";
        }
      }
    }
    setHovering(auxArray);
  }

  function setUpHover(index) {
    hoveringTheSelectedElement(index);
  }

  function setDownHover() {
    disableHovering();

  }

  function toolTipHoverControl(index) {
    hoveringTheSelectedElement(index);
  }

  useEffect(() => {
    window.addEventListener('resize', detectSize)

    return () => {
      window.removeEventListener('resize', detectSize)
    }
  }, [windowDimension])

  const [scrollTop, setScrollTop] = useState(0);
  const [showChart, setShowChart] = useState(false);
  const [showChartSecond, setShowChartSecond] = useState(false);
  const [showChartSmall, setShowChartSmall] = useState(false);
  const onScroll = (e) => {
    if (e.target.documentElement.scrollTop < window.scrollY + document.querySelector('#chartId').getBoundingClientRect().top - 850) {
      setScrollTop(e.target.documentElement.scrollTop);
    } else {
      setShowChart(true);
    }
    if (e.target.documentElement.scrollTop < window.scrollY + document.querySelector('#second-chart').getBoundingClientRect().top - 850) {
      setScrollTop(e.target.documentElement.scrollTop);
    } else {
      setShowChartSecond(true);
    }
    if (e.target.documentElement.scrollTop < window.scrollY + document.querySelector('#small-chart').getBoundingClientRect().top - 850) {
      setScrollTop(e.target.documentElement.scrollTop);
    } else {
      setShowChartSmall(true);
    }
  }
  useEffect(() => {

    window.addEventListener('scroll', onScroll);

  });

  useEffect(() => {

    if (scrollTop > window.scrollY + document.querySelector('#chartId').getBoundingClientRect().top - 900) {
      setShowChart(true);
    } else {
      setShowChart(false);
    }
    if (scrollTop > window.scrollY + document.querySelector('#second-chart').getBoundingClientRect().top - 900) {
      setShowChartSecond(true);
    } else {
      setShowChartSecond(false);
    }
    if (scrollTop > window.scrollY + document.querySelector('#small-chart').getBoundingClientRect().top - 0) {
      setShowChartSmall(true);
    } else {
      setShowChartSmall(false);
    }
  }, [scrollTop])
  useEffect(() => {

    const cutOutResolutionCalculator = (cutOut, resolution) => {
      return cutOut * windowDimension.winWidth / resolution;
    }

    if (windowDimension.winWidth < 376) {
      setChartSpecifications(prevState => ({
        ...prevState,
        ...{cutOutDimension: cutOutResolutionCalculator(55, 375)}
      }));
    }

    if (windowDimension.winWidth < 769 && windowDimension.winWidth > 375) {
      setChartSpecifications(prevState => ({
        ...prevState,
        ...{cutOutDimension: cutOutResolutionCalculator(55, 768)}
      }));
    }
    if (windowDimension.winWidth < 1025 && windowDimension.winWidth > 768) {
      setChartSpecifications(prevState => ({
        ...prevState,
        ...{cutOutDimension: cutOutResolutionCalculator(100,1024)}
      }));
    }
    if (windowDimension.winWidth < 1441 && windowDimension.winWidth > 1024) {
      setChartSpecifications(prevState => ({
        ...prevState,
        ...{cutOutDimension: cutOutResolutionCalculator(100,1440)}
      }));
    }
    if (windowDimension.winWidth > 1440) {
      setChartSpecifications(prevState => ({
        ...prevState,
        ...{cutOutDimension: 100}
      }));
    }
    if (isMobile) {
      setChartSpecifications(prevState => ({
        ...prevState,
        ...{checkEvent: "onClick"}
      }));
    } else {
      setChartSpecifications(prevState => ({
        ...prevState,
        ...{checkEvent: "onHover"}
      }));
    }
  }, [setChartSpecifications, windowDimension, isMobile])

  return <>
    <div className={"pie-chart-main-container"}>
      <div className={"second-pie-chart"} id={"second-chart"}>
        {showChartSecond && <Pie data={data}
                                 options={options}
                                 onMouseEnter={() => setCheckIndex(-2)}
                                 onMouseOut={() => disableHovering()}
        />}
      </div>
      <div className={"alignment-text-chart-container"}>
        <div className={"chart-text-main-container"}>
          <div className={`chart-text-container ${checkIndex >= 0 ? checkIndex !== 8 && "chart-text-opacity" : ""}`}
               onMouseEnter={() => {
                 setUpHover(8)
               }}
               onMouseLeave={() => {
                 setDownHover()
               }}
          >PvP / Rogue Planets <p className={"the-percentage-value-design"}> 2.4% </p>
            <div
              className={"color-hexagon dark-blue-background"}/>
          </div>
          <div className={`chart-text-container ${checkIndex >= 0 ? checkIndex !== 7 && "chart-text-opacity" : ""}`}
               onMouseEnter={() => {
                 setUpHover(7)
               }}
               onMouseLeave={() => {
                 setDownHover()
               }}
          >Blowfish Team <p className={"the-percentage-value-design"}> 10.0% </p>
            <div
              className={"color-hexagon medium-blue-background"}/>
          </div>
          <div className={`chart-text-container ${checkIndex >= 0 ? checkIndex !== 6 && "chart-text-opacity" : ""}`}
               onMouseEnter={() => {
                 setUpHover(6)
               }}
               onMouseLeave={() => {
                 setDownHover()
               }}
          >Marketing <p className={"the-percentage-value-design"}> 7.0% </p>
            <div
              className={"color-hexagon light-blue-background"}/>
          </div>
          <div className={`chart-text-container ${checkIndex >= 0 ? checkIndex !== 5 && "chart-text-opacity" : ""}`}
               onMouseEnter={() => {
                 setUpHover(5)
               }}
               onMouseLeave={() => {
                 setDownHover()
               }}
          >Reserve <p className={"the-percentage-value-design"}> 17.0% </p>
            <div
              className={"color-hexagon light-purple-background"}/>
          </div>
          <div className={`height-complementary-class `}/>

        </div>
        <div className={"pie-chart-container"} id={"chartId"}>

          {showChart && <Pie data={data}
                             options={options}
                             onMouseEnter={() => setCheckIndex(-2)}
                             onMouseOut={() => disableHovering()}
          />}

        </div>
        <div className={"chart-text-main-container-right"}>
          <div className={`chart-text-container ${checkIndex >= 0 ? checkIndex !== 0 && "chart-text-opacity" : ""}`}
               onMouseEnter={() => {
                 setUpHover(0)
               }}
               onMouseLeave={() => {
                 setDownHover()
               }}
          >
            <div className={"color-hexagon dark-green-background"}/>
            <p className={"the-percentage-value-design"}> 13.5% </p> PvE
          </div>
          <div className={`chart-text-container ${checkIndex >= 0 ? checkIndex !== 1 && "chart-text-opacity" : ""}`}
               onMouseEnter={() => {
                 setUpHover(1)
               }}
               onMouseLeave={() => {
                 setDownHover()
               }}
          >
            <div className={"color-hexagon light-green-background"}/>
            <p className={"the-percentage-value-design"}> 10.0% </p>Listings/Liquidity Pools
          </div>
          <div className={`chart-text-container ${checkIndex >= 0 ? checkIndex !== 2 && "chart-text-opacity" : ""}`}
               onMouseEnter={() => {
                 setUpHover(2)
               }}
               onMouseLeave={() => {
                 setDownHover()
               }}
          >
            <div className={`color-hexagon dark-red-background`}/>
            <p className={"the-percentage-value-design"}> 6.0%</p> Ecosystems Fund
          </div>
          <div className={`chart-text-container ${checkIndex >= 0 ? checkIndex !== 3 && "chart-text-opacity" : ""}`}
               onMouseEnter={() => {
                 setUpHover(3)
               }}
               onMouseLeave={() => {
                 setDownHover()
               }}
          >
            <div className={"color-hexagon light-red-background"}/>
            <p className={"the-percentage-value-design"}>29.1%</p> Planet Emissions
          </div>
          <div className={`chart-text-container ${checkIndex >= 0 ? checkIndex !== 4 && "chart-text-opacity" : ""}`}
               onMouseEnter={() => {
                 setUpHover(4)
               }}
               onMouseLeave={() => {
                 setDownHover()
               }}
          >
            <div
              className={"color-hexagon light-pink-background "}/>
            <p className={"the-percentage-value-design"}> 5.0% </p>Launch Contributions
          </div>

        </div>
        <div className={"low-resolution-pie-chart"}>
          <div className={"device-pie-chart"} id={"small-chart"}>
            <div className={"chart-inside-text"}>TOTAL SUPPLY:<br/> 888,077,888</div>
            {showChartSmall && <Pie data={data}
                                    options={options}
                                    onMouseEnter={() => setCheckIndex(-2)}
                                    onMouseOut={() => disableHovering()}
                                    className={"rotate-chart"}
            />}
            {checkIndex === 6 &&
              <div className={"tool-tip-box marketing-position"} onMouseEnter={() => toolTipHoverControl(6)}
                   onMouseLeave={() => disableHovering()}>
                <img alt={""} src={hover_triangle} className={"triangle-arrow-from-pie-chart"}/>
                <p className={"the-percentage-value-design"}> 7.0% </p> Marketing
              </div>}
            {checkIndex === 4 &&
              <div className={"tool-tip-box launch-contributions"} onMouseEnter={() => toolTipHoverControl(4)}
                   onMouseLeave={() => disableHovering()}>
                <img alt={""} src={hover_triangle} className={"triangle-arrow-from-pie-chart"}/>
                <p className={"the-percentage-value-design"}> 5.0% </p> Launch Contributions
              </div>}
            {checkIndex === 1 &&
              <div className={"tool-tip-box listings-liquidity-pools"} onMouseEnter={() => toolTipHoverControl(1)}
                   onMouseLeave={() => disableHovering()}>
                <img alt={""} src={hover_triangle} className={"triangle-arrow-from-pie-chart"}/>
                <div className={"align-tool-tip-text"}><b> 10.0% - </b>Listings/<br/>Liquidity Pools</div>
              </div>}
            {checkIndex === 8 &&
              <div className={"tool-tip-box PvP-rogue-planets"} onMouseEnter={() => toolTipHoverControl(8)}
                   onMouseLeave={() => disableHovering()}>
                <img alt={""} src={hover_triangle} className={"triangle-arrow-from-pie-chart"}/>
                <p className={"the-percentage-value-design"}> 2.4% </p> PvP / Rogue<br/> Planets
              </div>}
            {checkIndex === 3 &&
              <div className={"tool-tip-box planet-emissions"} onMouseEnter={() => toolTipHoverControl(3)}
                   onMouseLeave={() => disableHovering()}>
                <img alt={""} src={hover_triangle} className={"triangle-arrow-from-pie-chart"}/>
                <p className={"the-percentage-value-design"}>29.1%</p> Planet Emissions
              </div>}
            {checkIndex === 7 &&
              <div className={"tool-tip-box blowfish-team"} onMouseEnter={() => toolTipHoverControl(7)}
                   onMouseLeave={() => disableHovering()}>
                <img alt={""} src={hover_triangle} className={"triangle-arrow-from-pie-chart"}/>
                <p className={"the-percentage-value-design"}> 10.0% </p> Blowfish Team
              </div>}
            {checkIndex === 5 && <div className={"tool-tip-box reserve"} onMouseEnter={() => toolTipHoverControl(5)}
                                      onMouseLeave={() => disableHovering()}>
              <img alt={""} src={hover_triangle} className={"triangle-arrow-from-pie-chart"}/>
              <p className={"the-percentage-value-design"}> 17.0% </p>Reserve
            </div>}
            {checkIndex === 0 && <div className={"tool-tip-box PvE-chart"} onMouseEnter={() => toolTipHoverControl(0)}
                                      onMouseLeave={() => disableHovering()}>
              <img alt={""} src={hover_triangle} className={"triangle-arrow-from-pie-chart"}/>
              <p className={"the-percentage-value-design"}> 13.5% - </p>PvE
            </div>}
            {checkIndex === 2 &&
              <div className={"tool-tip-box ecosystems-fund"} onMouseEnter={() => toolTipHoverControl(2)}
                   onMouseLeave={() => disableHovering()}>
                <img alt={""} src={hover_triangle} className={"triangle-arrow-from-pie-chart"}/>
                <p className={"the-percentage-value-design"}> 6.0%</p> Ecosystems Fund
              </div>}

          </div>
          <div className={"low-resolution-chart-text"}>
            <div className={`chart-text-container ${checkIndex >= 0 ? checkIndex !== 8 && "chart-text-opacity" : ""}`}
                 onMouseEnter={() => {
                   setUpHover(8)
                 }}
                 onMouseLeave={() => {
                   setDownHover()
                 }}
            >
              <div className={"color-hexagon dark-blue-background"}/>
              <p className={"the-percentage-value-design"}> 2.4% </p>
              PvP / Rogue Planets
            </div>
            <div className={`chart-text-container ${checkIndex >= 0 ? checkIndex !== 7 && "chart-text-opacity" : ""}`}
                 onMouseEnter={() => {
                   setUpHover(7)
                 }}
                 onMouseLeave={() => {
                   setDownHover()
                 }}
            >
              <div className={"color-hexagon medium-blue-background"}/>
              <p className={"the-percentage-value-design"}> 10.0% </p> Blowfish Team

            </div>

            <div className={`chart-text-container ${checkIndex >= 0 ? checkIndex !== 6 && "chart-text-opacity" : ""}`}
                 onMouseEnter={() => {
                   setUpHover(6)
                 }}
                 onMouseLeave={() => {
                   setDownHover()
                 }}
            >
              <div
                className={"color-hexagon light-blue-background"}/>
              <p className={"the-percentage-value-design"}> 7.0% </p> Marketing
            </div>
            <div className={`chart-text-container ${checkIndex >= 0 ? checkIndex !== 5 && "chart-text-opacity" : ""}`}
                 onMouseEnter={() => {
                   setUpHover(5)
                 }}
                 onMouseLeave={() => {
                   setDownHover()
                 }}
            >
              <div
                className={"color-hexagon light-purple-background"}/>
              <p className={"the-percentage-value-design"}> 17.0% </p>
              Reserve
            </div>
            <div className={`chart-text-container ${checkIndex >= 0 ? checkIndex !== 0 && "chart-text-opacity" : ""}`}
                 onMouseEnter={() => {
                   setUpHover(0)
                 }}
                 onMouseLeave={() => {
                   setDownHover()
                 }}
            >
              <div className={"color-hexagon dark-green-background"}/>
              <p className={"the-percentage-value-design"}> 13.5% </p> PvE
            </div>
            <div className={`chart-text-container ${checkIndex >= 0 ? checkIndex !== 1 && "chart-text-opacity" : ""}`}
                 onMouseEnter={() => {
                   setUpHover(1)
                 }}
                 onMouseLeave={() => {
                   setDownHover()
                 }}
            >
              <div className={"color-hexagon light-green-background"}/>
              <p className={"the-percentage-value-design"}> 10.0% </p>Listings/Liquidity Pools
            </div>
            <div className={`chart-text-container ${checkIndex >= 0 ? checkIndex !== 2 && "chart-text-opacity" : ""}`}
                 onMouseEnter={() => {
                   setUpHover(2)
                 }}
                 onMouseLeave={() => {
                   setDownHover()
                 }}
            >
              <div className={`color-hexagon dark-red-background`}/>
              <p className={"the-percentage-value-design"}> 6.0%</p> Ecosystems Fund
            </div>
            <div className={`chart-text-container ${checkIndex >= 0 ? checkIndex !== 3 && "chart-text-opacity" : ""}`}
                 onMouseEnter={() => {
                   setUpHover(3)
                 }}
                 onMouseLeave={() => {
                   setDownHover()
                 }}
            >
              <div className={"color-hexagon light-red-background"}/>
              <p className={"the-percentage-value-design"}>29.1%</p> Planet Emissions
            </div>
            <div className={`chart-text-container ${checkIndex >= 0 ? checkIndex !== 4 && "chart-text-opacity" : ""}`}
                 onMouseEnter={() => {
                   setUpHover(4)
                 }}
                 onMouseLeave={() => {
                   setDownHover()
                 }}
            >
              <div
                className={"color-hexagon light-pink-background "}/>
              <p className={"the-percentage-value-design"}> 5.0% </p>
              Launch Contributions
            </div>

          </div>

        </div>
      </div>
    </div>
  </>
}
