import './DoubleIconCard.scss';

type DoubleIconCardProps = {
  icon1: string;
  icon2: string;
  mainText: string;
  subText: string;
  value: number;
};

export const DoubleIconCard = ({ icon1, icon2, mainText, subText, value }: DoubleIconCardProps) => {
  return (
    <li className="double-icon-card">
      <span className={icon1}></span>
      <span className={icon2}></span>
      <span className="double-icon-card__main-text">{mainText}</span>
      <span className="double-icon-card__sub-text">{subText}</span>
      <span className="double-icon-card__value">({value})</span>
    </li>
  );
};
