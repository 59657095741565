import React from "react";
import "./TokenMetrics.scss";
import PageHeader from "../PageHeader";
import {subscribe_wing_left, subscribe_wing_right} from "../../assets/images/Footer";
import PieChart from "../PieChart/PieChart";

export default function TokenMetrics() {

  return <>
    <div className={"token-metrics-main-container"}>
      <div className={"token-metrics-title"}>
        <PageHeader headerText="TOKEN METRICS" classname="page-mobile-header-text-no-margin"/>
      </div>
      <div className={"token-metrics-content-container"}>
        <div className={"token-content-title"}>
          <img alt={""} src={subscribe_wing_left}/>
          <div className={"supply-container"}>Total Supply: 888,077,888</div>
          <img alt={""} src={subscribe_wing_right}/>
        </div>

        <PieChart/>
      </div>

    </div>

  </>
}
