import React, {useEffect, useState} from 'react';
import './PublicPlanetSale.scss';
import HeaderComponent from "../../components/Header/HeaderComponent";
import PublicPlanetGenesisSale from "./components/PublicPlanetGenesisSale/PublicPlanetGenesisSale";
import PresaleA from "./components/PresaleA/PresaleA";
import PresaleB from "./components/PresaleB/PresaleB";
import PublicSale from "./components/PublicSale/PublicSale";
import PublicSaleCountdownComponent from "./components/PrivateSaleCountdown/PublicSaleCountdownComponent";
import BorderImg from "../PlanetSalePagePrivate/components/BorderImg/BorderImg";

import {getTheTime} from "../../utils/ApiCalls";
import LoadingSpinner from "../../components/LoadingSpinner";
import {PlanetSaleProvider} from "../../components/Context/PlanetSalePageContext";
import SwitchNetworkChainSection, {IsOnCorrectChain} from '../../components/SwitchNetworkChain';
import {walletContext} from '../../utils/WalletContext';
import ConnectWalletBox from '../PlanetSalePagePrivate/components/ConnecWalletBox/ConnectWalletBox';
import {PUBLIC_PLANET_SALE_FINISHED} from '../../utils/Globals';
import Footer from "../../components/Footer/Footer";
import {ConnectWalletText} from "../../utils/GeneralUtils";
import FighterLady from "../../components/Footer/FooterElements/FighterLady/FighterLady";
import initializeTimelines from "../../old/scripts/timelines";
import ShowOnScroll from "../../old/scripts/show-on-scroll";
import { useNetwork } from 'wagmi'

export default function PublicPlanetSale() {
  const network = useNetwork();

  const [saleATimer, setSaleATimer] = useState(0);
  const [saleBTimer, setSaleBTimer] = useState(0);
  const [salePublicTimer, setSalePublicTimer] = useState(0);
  const [showSaleAandB, setShowSaleAandB] = useState(false);
  const [isCorrectChain, setIsCorrectChain] = useState(undefined)

  const interval = setInterval(() => {
    if (IsOnCorrectChain(network) !== null) {
      clearInterval(interval);
      setIsCorrectChain(IsOnCorrectChain(network))
    }
  }, 100);

  function componentDidMount() {
    initializeTimelines();
    setTimeout(function () {
      var instShowOnScroll = new ShowOnScroll();
      instShowOnScroll.init();
    }, 250);
  }
  useEffect(() => {
    componentDidMount();
  })

  const getWhereIsTheTime = async () => {
    let response = await getTheTime();

    await setSaleATimer(response.data.data.preSaleAStartTime);
    await setSaleBTimer(response.data.data.preSaleBStartTime);
    await setSalePublicTimer(response.data.data.publicSaleStartTime);
  }

  function getTheTimeDifference(time) {
    let now = new Date().getTime();

    if (now - time < 0) {
      return true;
    } else {
      return false;
    }

  }

  const BSaleTime = () => {
    if (getTheTimeDifference(saleBTimer)) {
      return false;
    } else {
      return true
    }
  }

  const childToParent = (value) => {
    setShowSaleAandB(value);
  }

  useEffect(() => {
    async function getTimeAsync() {
      getWhereIsTheTime();
    }

    getTimeAsync();
  }, []);


  return (<>
    <HeaderComponent noBackground={true}/>

    <PlanetSaleProvider>
      <PublicPlanetGenesisSale/>

      {PUBLIC_PLANET_SALE_FINISHED ?
        <div className='pgs-public-sale-border-img-container' id='pgs-border-img'>
          <BorderImg text={'PLANET SALE HAS CLOSED'}/>
        </div>
        :
        <>
          {!walletContext.signed && <ConnectWalletBox title={ConnectWalletText.title}/>}
          {(walletContext.signed && !isCorrectChain) &&
            <SwitchNetworkChainSection
              network={network}
              message={`You must be on the ${process.env.REACT_APP_CHAINID} network to view this sale`}/>
          }
          {(walletContext.signed && isCorrectChain) && (BSaleTime() !== undefined ? showSaleAandB === true ?
              <>
                <PresaleA timer={saleATimer}/>
                <div className={"presaleB-component-container"}>
                  <PresaleB timer={saleBTimer}/>
                </div>
              </> :
              <></> :
            <div className={"loading-ring-position"}>
              <LoadingSpinner/>
            </div>)}
          {(walletContext.signed && isCorrectChain) &&
            <PublicSale timer={salePublicTimer} showAandBSales={childToParent}/>}
          <div className='pgs-public-sale-border-img-container' id='pgs-border-img'>
            <BorderImg text={'SALE CLOSES IN'}/>
          </div>
          <PublicSaleCountdownComponent targetTime={new Date(1654646400000).getTime()}/>
        </>
      }
    </PlanetSaleProvider>
    <Footer>
      <FighterLady/>
    </Footer>
  </>)
}
