import React from 'react';
import './TiltedButton.scss';

function TiltedButton({text, clickEvent, disabled, icon}){

  return (<>
    <div onClick={clickEvent}
         className={disabled ? 'tilted-button-container-disabled' : 'tilted-button-container'}>
      {icon && <img className={'tilted-button-icon'} src={icon} alt={""} />}
      {text}
    </div>
  </>);
}

export default TiltedButton;
