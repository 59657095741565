import './MintpassModal.scss'
import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import {claim_episode_bottem_horn, claim_episode_header_horn} from "../../../assets/images/PopUps";
import {licenseAndAgreement, MintpassPopupText} from "../../../utils/GeneralUtils";
import {MintpassModalCombinations} from "./MintpassModalCombinations/MintpassModalCombinations";
import {closeIcon, combination_equal} from "../../../assets";
import {acceptTAndCs, hasAcceptedTAndCs} from "../../../utils/ApiCalls";
import {walletContext} from "../../../utils/WalletContext";
import {T_AND_C_SIGNING_MESSSGE} from "../../../utils/Globals";
import { useSignMessage } from 'wagmi';

function MintpassModal({setShowMintWithPosters, nft, account, convertButtonHandler}) {
  const [isSigned, setIsSigned] = useState(false);
  const exitButtonHandler = () => {
    setShowMintWithPosters(false)
  }

  useEffect(() => {
    // check to see if the user has accepted the T&Cs before
    hasAcceptedTAndCs(walletContext.currentWallet, walletContext.signature)
      .then(res => {
        setIsSigned(res.data.isAccepted);
      });
  });

  const { signMessage } = useSignMessage(
    {
      async onSuccess(data) {
        //Just finished signing message
        await acceptTAndCs(walletContext.currentWallet, walletContext.signature, data);
        setIsSigned(true);

        processTransactionTandCSigned();
      },
      onError() {
        //Sign message error
        setIsSigned(false);
      }
    }
  );

  const handleYesClick = async (nft) => {
    if(!isSigned) {
      try {
        signMessage({ message: T_AND_C_SIGNING_MESSSGE });
      } catch (err) {
        // if they rejects the wallet signing, dont mint.
        return;
      }
    } else {
      processTransactionTandCSigned();
    }
  }

  const processTransactionTandCSigned = () => {
    // get the required nfts for the mintpass
    const tokenAIds = nft.nftsRequired.map((requiredNft) => requiredNft.globalsEpisodeId);
    convertButtonHandler(tokenAIds, nft.globalMintPassId);
    setShowMintWithPosters(false);
  }

  return (
    <>
      <div className={'mintpass-modal-main-container'}>
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName='mintpass-modal'
          show={setShowMintWithPosters}
        >
          <img className={'mintpass-header-decoration'} src={claim_episode_header_horn} alt={''}/>
          <div className={'mintpass-container'}>
            <img alt="" className={"mintpass-close-button"} onClick={exitButtonHandler} src={closeIcon}/>
            {MintpassPopupText(nft.title).note}
            <div className={'mintpass-combinations'}>
              <MintpassModalCombinations nftsCombination={nft.nftsRequired} account={account}/>
            </div>
            <div className={'mintpass-result-container'}>
              <img src={combination_equal} alt={''} className={'mintpass-combination-equals-table'}/>
              <img className={'mintpass-nft-image'} src={nft.image} alt={''}/>
            </div>
            <div className={'mintpass-licence-agreement-title'}>
              <p className={'mintpass-licence-agreement-title header'}> Licence Agreement </p>
              <p className={'mintpass-licence-agreement-title body'}> Unlimited Personal and Commercial Use </p>
            </div>
            <div className={'mintpass-licence-text-box-container'}>
              <div className={'mintpass-licence-text-box'}>
                <div className={'mintpass-licence-text-box-text'}>
                  {licenseAndAgreement.text}
                  <br/>
                </div>
              </div>
              <div className={'mintpass-licence-text-box-shadow'}></div>
            </div>
            <p className={'mintpass-confirmation-text'}>By minting this NFT, you sign & accept the terms of the NFT Licence Agreement</p>
            <p className={'mintpass-confirmation-text'}>Do you wish to proceed?</p>
            <div className={'mintpass-buttons-container'}>
              <div className={'mintpass-yes-button'} onClick={() => handleYesClick(nft)}>
                YES
              </div>
              <div onClick={exitButtonHandler} className={'mintpass-no-button'}>
                NO
              </div>
            </div>
          </div>
          <img className={'mintpass-bottom-decoration'} src={claim_episode_bottem_horn} alt={''}/>
        </Modal>
      </div>
    </>
  )
}



export {MintpassModal}
