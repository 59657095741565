import "./GenerativeStarfighterMintCongratulationsPopup.scss";
import {
  popup_border_bottom_left_thin,
  popup_border_bottom_left_thin_small,
  popup_border_bottom_right_thin,
  popup_border_bottom_right_thin_small,
  popup_border_top_left_thin,
  popup_border_top_left_thin_small,
  popup_border_top_right_thin,
  popup_border_top_right_thin_small,
} from "../../../assets/images/GenerativePlanetMinting";
import BaseButton from "../../BaseButton/BaseButton";

export default function GenerativeStarfighterMintCongratulationsPopup({
  handleClose,
}) {
  return (
    <div className={"congratulations-popup-container"}>
      <div className={"congratulations-popup-border"}>
        <img
          src={popup_border_top_left_thin}
          alt={"popup-border"}
          className={"congratulations-popup-border-icon"}
        />
        <img
          src={popup_border_top_right_thin}
          alt={"popup-border"}
          className={"congratulations-popup-border-icon"}
        />

        <img
          src={popup_border_top_left_thin_small}
          alt={"popup-border"}
          className={"congratulations-popup-border-icon-small"}
        />
        <img
          src={popup_border_top_right_thin_small}
          alt={"popup-border"}
          className={"congratulations-popup-border-icon-small"}
        />
      </div>
      <div className="congratulations-popup-content">
        <div className="congratulations-popup-title">Congratulations!</div>
        <div className="congratulations-popup-text">
          You have successfully completed your mint. Your Starfighter NFT(s) will
          arrive in your wallet shortly.
        </div>
        <p className="congratulations-popup-sub-text">
          Note: The image and metadata of each Starfighter NFT may not be visible
          immediately as it does take time to generate.
        </p>
        <p className="congratulations-popup-sub-text">
          You can view all your minted starfighters at any time on{" "}
          <a href="https://opensea.io/" target={"_blank"} rel="noreferrer">
            OpenSea
          </a>
          !
        </p>
        <div className="congratulations-popup-button">
          <BaseButton
            text={"CLOSE"}
            scale={false}
            filled={true}
            disabled={false}
            onClick={handleClose}
          />
        </div>
      </div>
      <div className={"congratulations-popup-border"}>
        <img
          src={popup_border_bottom_left_thin}
          alt={"popup-border"}
          className={"congratulations-popup-border-icon"}
        />
        <img
          src={popup_border_bottom_right_thin}
          alt={"popup-border"}
          className={"congratulations-popup-border-icon"}
        />

        <img
          src={popup_border_bottom_left_thin_small}
          alt={"popup-border"}
          className={"congratulations-popup-border-icon-small"}
        />
        <img
          src={popup_border_bottom_right_thin_small}
          alt={"popup-border"}
          className={"congratulations-popup-border-icon-small"}
        />
      </div>
    </div>
  );
}
