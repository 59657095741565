import { PGAccordion } from '../../../Common/Accordion/PGAccordion';
import PageHeader from '../../../PageHeader';
import { Accordion, Container } from 'react-bootstrap';

export const StarfighterDashboardInfo = () => (
  <>
    <PageHeader headerText="INFO" headerTextSecondLine={undefined} classname="planet-dashboard-info" />
    <Container>
      <PGAccordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>BOUND STARFIGHTERS:</Accordion.Header>
          <Accordion.Body>
            <p>
              Starfighters can be Bound and Unbound by first selecting a Starfighter and then selecting the subsequent ‘Bind’ or ‘Unbind’ option.
              A Starfighter is Bound or Unbound on the blockchain, and the NFT cannot be transferred or moved from your wallet while Bound.
            </p>
            <p>
              A Starfighter is game-locked when it is blockchain Bound, AND actively being used by Phantom Galaxies or an affiliated game.
              For example, when a Starfighter is Bound to an account that is actively playing Phantom Galaxies, it is considered ‘game-locked’ for the duration of the gameplay session.
            </p>
            <p>
              Bound Starfighters that are game-locked cannot be Unbound or transferred until released from the game lock by ending the current gameplay session.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>OTHER QUESTIONS ABOUT BINDING:</Accordion.Header>
          <Accordion.Body>
            <dl>
              <dt>Q: Why can’t I Unbind my Starfighter?</dt>
              <dd>A: If your Starfoghter is Bound to an account which has an active game session of Phantom Galaxies, it cannot be Unbound until it is no longer game-locked.
                In order to remove the game lock, you will need to end the current game session and try again.
              </dd>
              <dt>Q: Why do I need to Bind my Starfighters?</dt>
              <dd>
                A: Starfighters must be Bound in order to be accessible in the Phantom Galaxies game. If not Bound, Starfighters will not be displayed as playable in the character selection screen.
              </dd>
              <dt>Q: Why can’t I see my Starfighter in game?  </dt>
              <dd>
                A: If you are in a current play session when you Bind your Starfighter, you will need to exit to the main menu and ‘Enter World’ again for it to become available.
              </dd>
            </dl>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>HOW DO I UNBIND?</Accordion.Header>
          <Accordion.Body>
            <p>
              If your Starfighter is not game-locked, it can be Unbound simply by selecting the Starfighter in question and clicking the ‘Unbind’ button.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </PGAccordion>
    </Container>
  </>
)
