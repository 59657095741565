// send

const SET_SEND_CHAIN_ID = 'SET_SEND_CHAIN_ID';
const SET_SEND_CHAIN_NAME = 'SET_SEND_CHAIN_NAME';
const SET_SEND_CHAIN_CONFIG = 'SET_SEND_CHAIN_CONFIG';
const SET_SEND_TOKEN_BALANCE = 'SET_SEND_TOKEN_BALANCE';
const SET_SEND_BALANCE_IS_LOADING = 'SET_SEND_BALANCE_IS_LOADING';
const SET_SEND_INPUT_AMOUNT = 'SET_SEND_INPUT_AMOUNT';
const SET_SEND_IS_CHAIN_ID_SUPPORTED = 'SET_SEND_IS_CHAIN_ID_SUPPORTED';
const SET_SEND_SUBTRACTED_TOKEN_BALANCE = 'SET_SEND_SUBTRACTED_TOKEN_BALANCE';
const SET_SEND_TOKEN_NAME = 'SET_SEND_TOKEN_NAME';

// receive

const SET_RECEIVE_CHAIN_ID = 'SET_RECEIVE_CHAIN_ID';
const SET_RECEIVE_CHAIN_NAME = 'SET_RECEIVE_CHAIN_NAME';
const SET_RECEIVE_CHAIN_CONFIG = 'SET_RECEIVE_CHAIN_CONFIG';
const SET_RECEIVE_TOKEN_BALANCE = 'SET_RECEIVE_TOKEN_BALANCE';
const SET_RECEIVE_BALANCE_IS_LOADING = 'SET_RECEIVE_BALANCE_IS_LOADING';
const SET_RECEIVE_ADDED_TOKEN_BALANCE = 'SET_RECEIVE_ADDED_TOKEN_BALANCE';
const SET_RECEIVE_TOKEN_NAME = 'SET_RECEIVE_TOKEN_NAME;';

// common

const SET_SEND_SHOW_MODAL = 'SET_SEND_SHOW_MODAL';
const SET_SHOW_ERROR_MODAL = 'SET_SHOW_ERROR_MODAL';
const SET_ERROR_MODAL_TEXT = 'SET_SHOW_ERROR_MODAL_TEXT';
const SET_IS_TRANSACTION_SUCCESSFUL = 'SET_IS_TRANSACTION_SUCCESSFUL';
const SET_IS_TRANSACTION_LOADING = 'SET_IS_TRANSACTION_LOADING';
const SET_TRANSACTION_HASH = 'SET_TRANSACTION_HASH';
const SET_SWITCH_SEND_RECEIVE = 'SET_SWITCH_SEND_RECEIVE';

export {
  SET_SEND_CHAIN_ID,
  SET_RECEIVE_CHAIN_ID,
  SET_SEND_CHAIN_NAME,
  SET_RECEIVE_CHAIN_NAME,
  SET_IS_TRANSACTION_LOADING,
  SET_TRANSACTION_HASH,
  SET_IS_TRANSACTION_SUCCESSFUL,
  SET_SEND_SHOW_MODAL,
  SET_RECEIVE_TOKEN_BALANCE,
  SET_SEND_TOKEN_BALANCE,
  SET_SEND_BALANCE_IS_LOADING,
  SET_SEND_INPUT_AMOUNT,
  SET_RECEIVE_BALANCE_IS_LOADING,
  SET_SEND_IS_CHAIN_ID_SUPPORTED,
  SET_SEND_SUBTRACTED_TOKEN_BALANCE,
  SET_SHOW_ERROR_MODAL,
  SET_ERROR_MODAL_TEXT,
  SET_RECEIVE_ADDED_TOKEN_BALANCE,
  SET_SWITCH_SEND_RECEIVE,
  SET_SEND_TOKEN_NAME,
  SET_RECEIVE_TOKEN_NAME,
  SET_SEND_CHAIN_CONFIG,
  SET_RECEIVE_CHAIN_CONFIG
};
