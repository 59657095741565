import "./GenerativeAvatarMintPopupWithCatalyst.scss";
import {
  disabled_catalyst_icon,
  popup_border_bottom_left_thin,
  popup_border_bottom_left_thin_small,
  popup_border_bottom_right_thin,
  popup_border_bottom_right_thin_small,
  popup_border_top_left_thin,
  popup_border_top_left_thin_small,
  popup_border_top_right_thin,
  popup_border_top_right_thin_small,
  random_catalyst,
} from "../../../assets/images/GenerativePlanetMinting";
import BaseButton from "../../BaseButton/BaseButton";
import { EMPTY_STRING } from "../../../utils/GeneralUtils";

export default function GenerativeAvatarMintPopupWithCatalyst({
  popupValues,
  handleNo,
  handleYes,
}) {
  const getPlanetImageClass = (title) => {
    if (title === EMPTY_STRING) {
      return "generative-avatar-component-image random";
    }
    return "generative-avatar-component-image";
  };

  const isDisabledImageAndText = (image) => {
    return image === disabled_catalyst_icon || image === random_catalyst;
  };

  return (
    <div className={"avatar-minting-popup-container"}>
      <div className={"avatar-minting-popup-border"}>
        <img
          src={popup_border_top_left_thin}
          alt={"popup-border"}
          className={"avatar-minting-popup-border-icon"}
        />
        <img
          src={popup_border_top_right_thin}
          alt={"popup-border"}
          className={"avatar-minting-popup-border-icon"}
        />

        <img
          src={popup_border_top_left_thin_small}
          alt={"popup-border"}
          className={"avatar-minting-popup-border-icon-small"}
        />
        <img
          src={popup_border_top_right_thin_small}
          alt={"popup-border"}
          className={"avatar-minting-popup-border-icon-small"}
        />
      </div>
      <div className="avatar-minting-popup-content">
        <div className="avatar-minting-popup-title-catalyst">
          You are about to mint your Avatar NFT, burning the following mintpass
          and Catalyst(s).
        </div>

        <div className={"avatar-minting-popup-list"}>
          <div className={"avatar-minting-popup-avatar"}>
            <div className={"generative-avatar-component"}>
              <div className={"generative-avatar-border"}>
                <img
                  className={"generative-avatar-component-image"}
                  src={popupValues.avatarSelected.image}
                  alt={"PLANET"}
                />
              </div>
              <div className={"generative-avatar-component-stock"}>{}</div>
              <div className={"generative-avatar-component-title"}>
                {popupValues.avatarSelected.title}
              </div>
              <div className={"generative-trait-title-invisible"}>
                ({popupValues.gender.title})
              </div>
            </div>
          </div>
          <div className={"avatar-minting-popup-trait"}>
            <div className={"generative-avatar-component"}>
              <div className={"generative-avatar-border"}>
                <img
                  className={getPlanetImageClass(popupValues.gender.title)}
                  src={popupValues.gender.image}
                  alt={`${popupValues.gender.title} gender icon`}
                />
              </div>
              <div className={"generative-avatar-component-stock"}>{}</div>
              <div
                className={
                  !isDisabledImageAndText(popupValues.gender.image)
                    ? "generative-avatar-component-title"
                    : "generative-avatar-component-title disabled-trait-name"
                }
              >
                {"GENDER"}
              </div>
              <div
                className={
                  !isDisabledImageAndText(popupValues.gender.image)
                    ? "generative-trait-title"
                    : "generative-trait-title disabled-trait-name"
                }
              >
                {popupValues.gender.title === ""
                  ? "RANDOM"
                  : ""}
              </div>
            </div>
          </div>
          <div className={"avatar-minting-popup-trait"}>
            <div className={"generative-avatar-component"}>
              <div className={"generative-avatar-border"}>
                <img
                  className={getPlanetImageClass(
                    popupValues.geneticBase.title
                  )}
                  src={popupValues.geneticBase.image}
                  alt={`${popupValues.geneticBase.title} genetic base icon`}
                />
              </div>
              <div className={"generative-avatar-component-stock"}>{}</div>
              <div
                className={
                  !isDisabledImageAndText(popupValues.geneticBase.image)
                    ? "generative-avatar-component-title"
                    : "generative-avatar-component-title disabled-trait-name"
                }
              >
                {"GENETIC BASE"}
              </div>
              <div
                className={
                  !isDisabledImageAndText(popupValues.geneticBase.image)
                    ? "generative-trait-title"
                    : "generative-trait-title disabled-trait-name"
                }
              >
                {popupValues.geneticBase.title === ""
                  ? "RANDOM"
                  : ""}
              </div>
            </div>
          </div>
        </div>
        <div className="avatar-minting-popup-text">Do you wish to proceed?</div>
        <div className="avatar-minting-popup-buttons">
          <div className="avatar-minting-popup-button">
            <BaseButton
              text={"NO"}
              scale={false}
              filled={false}
              disabled={false}
              onClick={handleNo}
            />
          </div>
          <div className="avatar-minting-popup-button">
            <BaseButton
              text={"YES"}
              scale={false}
              filled={true}
              disabled={false}
              onClick={handleYes}
            />
          </div>
        </div>
      </div>
      <div className={"avatar-minting-popup-border"}>
        <img
          src={popup_border_bottom_left_thin}
          alt={"popup-border"}
          className={"avatar-minting-popup-border-icon"}
        />
        <img
          src={popup_border_bottom_right_thin}
          alt={"popup-border"}
          className={"avatar-minting-popup-border-icon"}
        />

        <img
          src={popup_border_bottom_left_thin_small}
          alt={"popup-border"}
          className={"avatar-minting-popup-border-icon-small"}
        />
        <img
          src={popup_border_bottom_right_thin_small}
          alt={"popup-border"}
          className={"avatar-minting-popup-border-icon-small"}
        />
      </div>
    </div>
  );
}
