import LoadingSpinner from '../../LoadingSpinner';
import './TokenBalance.scss';

type TokenBalanceProps = {
  walletBalance: number;
  isBalanceLoading?: boolean;
};

export function TokenBalance({ walletBalance, isBalanceLoading }: TokenBalanceProps) {
  return (
    <div className={'token-balance__container'}>
      {isBalanceLoading && <LoadingSpinner marginTop={'0'} marginBottom={'0'} size={'sm'} />}
      {!isBalanceLoading && (
        <>
          <span className={'token-balance__subtitle'}>Balance:</span>
          <p className={'token-balance__text'}>{walletBalance ?? 0.0}</p>
        </>
      )}
    </div>
  );
}
