import { useEffect, useState } from 'react';
import {getMyAvatars} from '../utils/ApiCalls';
import {LockStatus} from "../components/Common/AssetCard/AssetCard";
import { Avatar } from '@pg/account-api';

export interface CustomAvatar extends Avatar {
  id: string;
  thumbnailImageUrl: string;
  isFavourite: boolean;
  status: LockStatus;
  displayName: string;
  data: {
    generation: number;
    Female: boolean;
    citizenId: string;
    origin: number;
    avatarClass: number;
    geneticBase: number;
    faceMesh1: number;
    faceMesh2: number;
    facialBlend: number;
    eyebrow: number;
    eyeColour: number;
    skinTone: number;
    hairStyle: number;
    facialHair: number;
    hairColour: number;
    scar: number;
    tattoo: number;
    suit: number;
    gloves: number;
    boots: number;
    primaryColour: number;
    lightColour: number;
    background: number;
    backgroundLighting: number;
    pose: number;
  };
  // custom fields
  isChecked?: boolean;
  alt?: string;
}

interface MyAvatars {
  avatars: CustomAvatar[];
  avatarsError: string | null;
  setAvatars: React.Dispatch<React.SetStateAction<CustomAvatar[]>>;
}

export function useFetchMyAvatars(): MyAvatars {
  const [avatars, setAvatars] = useState<CustomAvatar[]>([]);
  const [avatarsError, setAvatarsError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMyAvatars = async () => {
      try {
        const avatarsResponse = await getMyAvatars();
        const blockchainAvatars = avatarsResponse.filter((avatar: CustomAvatar) => avatar.isFreeAvatar === false);
        const sortedByFavourites = blockchainAvatars.sort((a: CustomAvatar, b: CustomAvatar) =>
          a.isFavourite === b.isFavourite ? 0 : a.isFavourite ? -1 : 1,
        );
        const avatarsWithName = sortedByFavourites.map((avatar: CustomAvatar) => ({ ...avatar, displayName: avatar.data.citizenId as string }));
        setAvatars(avatarsWithName);
      } catch (e: any) {
        setAvatarsError('Error: failed to fetch avatars');
      }
    };

    fetchMyAvatars();
  }, []);

  return { avatars, avatarsError, setAvatars };
}

