import TiltedButton from "../../TiltedButton/TiltedButton";
import React from "react";
import "./ModalButton.scss";

export default function ModalButton({onClick, buttonText, disabled, icon}) {
  return (
    <div className={"modal-button-container"}>
      <div className={"modal-button"}>
        <TiltedButton text={buttonText} clickEvent={onClick} disabled={disabled} icon={icon}/>
      </div>
    </div>
  )
}

