import './AssetNotFound.scss';
import { ImageFallback } from '../Image/ImageFallback';
import { blackhole } from '../../../assets/images/AssetNotFound';

export interface AssetNotFoundProps {
  mainText: string;
  imageURL: string;
  altImageText?: string;
  fallbackImage?: string;
}

export const AssetNotFound = ({ mainText, imageURL, altImageText, fallbackImage }: AssetNotFoundProps) => {
  return (
    <div className="asset-not-found">
      <p className="asset-not-found__message">{mainText.toUpperCase()}</p>
      <img src={blackhole} alt="blackhole" className="asset-not-found__blackhole" />
      <ImageFallback url={imageURL} altImageText={altImageText ?? ''} fallback={fallbackImage ?? ''} className="asset-not-found__image" />
    </div>
  );
};
