import { LayerZeroConfig } from '../TokenBridge/tokenBridgeTypes';
import { useState } from 'react';
import PGModal from '../Common/PGModal/PGModal';
import { SelectNetworkOptionButton } from './SelectNetworkOption/SelectNetworkOptionButton';
import { SelectNetworkButton } from './SelectNetworkButton/SelectNetworkButton';
import './TokenNetworkForm.scss';
import { networkOptionCount } from '../TokenBridge/TokenBridgeContent';

type TokenNetworkFormProps = {
  walletBalance: number;
  formType: TokenNetworkFormType;
  networkName: string;
  currentChainId: number | null;
  networkOptions: LayerZeroConfig;
  handleNetworkSwitch?: (chainId: number, formType: TokenNetworkFormType) => void;
  isBalanceLoading?: boolean;
  disableNetworkSwitch?: boolean;
  networkIcon?: string;
};

export enum TokenNetworkFormType {
  Send = 'send',
  Receive = 'receive',
}

export function TokenNetworkForm({
  walletBalance,
  formType,
  networkName,
  networkOptions,
  handleNetworkSwitch,
  isBalanceLoading,
  disableNetworkSwitch,
  currentChainId,
  networkIcon,
}: TokenNetworkFormProps) {
  const [showNetworkSwitcher, setShowNetworkSwitcher] = useState(false);
  const handleSelectNetwork = () => {
    setShowNetworkSwitcher(true);
  };

  const handleNetworkOptionSelect = (chainId?: string) => {
    if (!chainId || parseInt(chainId) === currentChainId) {
      setShowNetworkSwitcher(false);
      return;
    }

    const chainIdInt = parseInt(chainId);

    if (handleNetworkSwitch) {
      handleNetworkSwitch(chainIdInt, formType);
    }

    setShowNetworkSwitcher(false);
  };

  return (
    <>
      <PGModal
        show={showNetworkSwitcher}
        onHide={() => setShowNetworkSwitcher(false)}
        dialogClassName={'token-form__modal-dialog'}
        contentClassName={'token-form__modal-content'}
        bodyClassName={'token-form__modal-body'}
      >
        <h2>Network</h2>
        <SelectNetworkOptionButton
          currentChainId={currentChainId}
          handleSelectNetworkSwitch={handleNetworkOptionSelect}
          networkOptions={networkOptions}
        />
      </PGModal>
      <SelectNetworkButton
        className={'select-network__button'}
        name={networkName}
        handleSelectButton={handleSelectNetwork}
        hasMultipleNetworkOptions={(networkOptionCount(networkOptions) ?? 0) > 1}
        disableNetworkSwitch={disableNetworkSwitch}
        imgWrapperClassName={'select-network__image-wrapper'}
        walletBalance={walletBalance}
        isBalanceLoading={isBalanceLoading}
        networkIcon={networkIcon}
        formType={formType}
      ></SelectNetworkButton>
    </>
  );
}
