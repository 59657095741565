import { SelectButton } from '../../Common/SelectButton/SelectButton';
import { SelectTextContent } from '../SelectTextContent/SelectTextContent';
import './SelectTokenButton.scss';

export type SelectTokenButtonProps = {
  name: string;
  logoAltText: string;
  hasMultipleTokenOptions?: boolean;
  logo?: string;
  className?: string;
  disabled?: boolean;
};

export function SelectTokenButton({ name, logo, disabled, logoAltText, hasMultipleTokenOptions, className }: SelectTokenButtonProps) {
  return (
    <SelectButton className={className} disabled={disabled}>
      <div className="select-token__icon-container">
        <img src={logo} className={`select-token__icon ${!logo && 'no-logo'}`} alt={logoAltText} />
      </div>
      <SelectTextContent name={name} title={'Token'} displayArrow={hasMultipleTokenOptions} />
    </SelectButton>
  );
}
