import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import "./AfrToken.scss";
import HeaderComponent from "../components/Header/HeaderComponent";
import Footer from "../components/Footer/Footer";
import AstrafiteToken from "../components/AstrafiteRushEvent/AstrafiteToken/AstrafiteToken";
import FooterPirate from "../components/Footer/FooterElements/FooterPirate/FooterPirate";


export default function AfrToken(props) {

  const search = useLocation().search;
  const tokenId = new URLSearchParams(search).get("tokenId");

  //page loads to the same scroll position of previous page - TODO: add higher order component?
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (<>
    <HeaderComponent noBackground={true}/>
    <AstrafiteToken tokenId={tokenId}/>
    <Footer>
        <FooterPirate />
    </Footer>
  </>)

}
