import './PlanetExploration.scss';
import HeaderComponent from '../components/Header/HeaderComponent';
import Footer from '../components/Footer/Footer';
import FighterLady from '../components/Footer/FooterElements/FighterLady/FighterLady';
import React, { useEffect } from 'react';
import PlanetExplorationContent from '../components/PlanetExploration/PlanetExplorationContent/PlanetExplorationContent';
import { useLocation, useNavigate } from 'react-router-dom';
import { walletContext } from '../utils/WalletContext';
import { useFetchMissions } from '../hooks/useFetchMissions';

export default function PlanetExploration() {
  const location = useLocation();
  const { mission } = useFetchMissions(location.state?.loadedMission);

  const navigate = useNavigate();

  useEffect(() => {
    if (!walletContext.currentWallet) {
      navigate('/portfolio/tcq-recon');
    }

    // useNavigate routes the user to the same scroll position on the next page
    // We want the user to begin at the top of this page
    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <>
      <HeaderComponent noBackground={true}/>
      {mission && <PlanetExplorationContent mission={mission} status="notcomplete" />}
      <Footer>
        <FighterLady />
      </Footer>
    </>
  );
}
