import React, {useContext, useEffect, useState} from "react";
import "./Private.scss";
import {ConnectWalletText, NFTsName, privateSaleErrorBoxText, PrivateSaleText} from "../../../../utils/GeneralUtils";
import {getBlockchainContract} from "../../../../utils/providerObject";
import BorderImg from "../BorderImg/BorderImg";
import ConnectWalletBox from "../ConnecWalletBox/ConnectWalletBox";
import {walletContext} from "../../../../utils/WalletContext";
import ListElement from "../ListElements/ListElement";
import {privateSaleVRS} from "../../../../utils/ApiCalls";
import CurrencyModal from "../../../../components/Modal/CurrencyModal/CurrencyModal";
import {PlanetSaleContext} from "../../../../components/Context/PlanetSalePageContext";
import PrivateSaleErrorBox from "../../../../components/PrivateSaleErrorBox/PrivateSaleErrorBox";
import {
  NFTIdCost,
  payableMinterV2Address,
  weiInEthereum
} from "../../../../utils/Globals"
import {
  asteroid_large, asteroid_small,
  planet_large,
  planet_medium,
  planet_small
} from "../../../../assets/images/PlanetSalePagePrivate";
import PayableMinterV2Abi from "../../../../assets/abi/PayableMinterV2.json";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import PlanetPurchaseModal from "../../../../components/Modal/PlanetPurchaseModal/PlanetPurchaseModal";
import SwitchNetworkChainSection, {IsOnCorrectChain} from '../../../../components/SwitchNetworkChain';
import { useAccount, useNetwork } from 'wagmi'

export default function PrivateSale() {
  const network = useNetwork();
  const { connector } = useAccount();
  const [provider, setProvider] = useState(undefined);

  useEffect( () => {
    const getProvider = async () => {
      const thisProvider = await connector?.getProvider();

      setProvider(thisProvider);
    }
    getProvider();
  });

  const planetSaleContext = useContext(PlanetSaleContext);

  const {currentWallet} = JSON.parse(sessionStorage.getItem("pg.wallet")) != null ? JSON.parse(sessionStorage.getItem("pg.wallet")) : {};
  // const [allowedNoNftsToMint, setAllowedNoNftsToMint] = useState(0);
  // const [enableSell, setEnableSell] = useState(true);
  const [enableSell, setEnableSell] = useState(undefined);
  const [isCorrectChain, setIsCorrectChain] = useState(undefined)

  const interval = setInterval(() => {
    if (IsOnCorrectChain(network) !== null) {
      clearInterval(interval);
      setIsCorrectChain(IsOnCorrectChain(network))
    }
  }, 100);

  // const chainName = CHAINS[process.env.REACT_APP_CHAINID]
  // const chainId = chainName.chainId
  // let providerObject = getProvider(chainId, chainName.rpc[0])
  // let prov = new ethers.providers.Web3Provider(providerObject);
  // let sign = prov.getSigner();
  // let pgerContract = new ethers.Contract(PGERC1155Address_PlanetSale, PGERC1155Abi, sign);

  const calculateCost = (bignumber) => {
    bignumber = bignumber.div(weiInEthereum);
    return bignumber.toNumber();
  }

  const numberOfNFTsAllowed = (maxMintPerWalet, mintedAmountPerUser) => {
    return maxMintPerWalet - mintedAmountPerUser;
  }

  const getMaxMintAmountPerUserSalePrivate = async () => {
    const minterV2Contract = getBlockchainContract(payableMinterV2Address, PayableMinterV2Abi, provider);

    let MaxMintAmountId1 = await minterV2Contract.maxMintAmountPerUser(currentWallet, NFTIdCost.id1);
    let MaxMintAmountId2 = await minterV2Contract.maxMintAmountPerUser(currentWallet, NFTIdCost.id2);
    let MaxMintAmountId3 = await minterV2Contract.maxMintAmountPerUser(currentWallet, NFTIdCost.id3);
    let MaxMintAmountId4 = await minterV2Contract.maxMintAmountPerUser(currentWallet, NFTIdCost.id4);
    let MaxMintAmountId5 = await minterV2Contract.maxMintAmountPerUser(currentWallet, NFTIdCost.id5);

    let MintedAmountId1 = await minterV2Contract.mintedAmountPerUser(currentWallet, NFTIdCost.id1);
    let MintedAmountId2 = await minterV2Contract.mintedAmountPerUser(currentWallet, NFTIdCost.id2);
    let MintedAmountId3 = await minterV2Contract.mintedAmountPerUser(currentWallet, NFTIdCost.id3);
    let MintedAmountId4 = await minterV2Contract.mintedAmountPerUser(currentWallet, NFTIdCost.id4);
    let MintedAmountId5 = await minterV2Contract.mintedAmountPerUser(currentWallet, NFTIdCost.id5);

    let privateSalePaused = await minterV2Contract.pausedPrivateSale();

    setListElementShow(privateSalePaused, (MaxMintAmountId1 + MaxMintAmountId2 + MaxMintAmountId3 + MaxMintAmountId4 + MaxMintAmountId5));

    let Id1AllowedToMint = numberOfNFTsAllowed(MaxMintAmountId1, MintedAmountId1);
    let Id2AllowedToMint = numberOfNFTsAllowed(MaxMintAmountId2, MintedAmountId2);
    let Id3AllowedToMint = numberOfNFTsAllowed(MaxMintAmountId3, MintedAmountId3);
    let Id4AllowedToMint = numberOfNFTsAllowed(MaxMintAmountId4, MintedAmountId4);
    let Id5AllowedToMint = numberOfNFTsAllowed(MaxMintAmountId5, MintedAmountId5);

    let nftId1Cost = NFTIdCost.costid1;
    nftId1Cost = calculateCost(nftId1Cost);
    let nftId2Cost = NFTIdCost.costid2;
    nftId2Cost = calculateCost(nftId2Cost);
    let nftId3Cost = NFTIdCost.costid3;
    nftId3Cost = calculateCost(nftId3Cost);
    let nftId4Cost = NFTIdCost.costid4;
    nftId4Cost = calculateCost(nftId4Cost);
    let nftId5Cost = NFTIdCost.costid5;
    nftId5Cost = calculateCost(nftId5Cost);

    let vrsResponse = await privateSaleVRS();
    let vrsData = vrsResponse["data"];


    planetSaleContext.setSaleConfigurations(data => {
      data.PrivatePresale = [
        {
          noNfts: 0,
          privateSaleMintedAmount: MintedAmountId1.toNumber(),
          privateSaleMaxMintAmount: MaxMintAmountId1.toNumber(),
          allowedNoNfts: Id1AllowedToMint,
          img: asteroid_small,
          text: NFTsName.SmallAsteroid.title,
          value: nftId1Cost,
          nftId: NFTIdCost.id1,
          v: vrsData[NFTIdCost.id1.toString()].v,
          r: vrsData[NFTIdCost.id1.toString()].r,
          s: vrsData[NFTIdCost.id1.toString()].s,
        },
        {
          noNfts: 0,
          privateSaleMintedAmount: MintedAmountId2.toNumber(),
          privateSaleMaxMintAmount: MaxMintAmountId2.toNumber(),
          allowedNoNfts: Id2AllowedToMint,
          img: asteroid_large,
          text: NFTsName.LargeAsteroid.title,
          value: nftId2Cost,
          nftId: NFTIdCost.id2,
          v: vrsData[NFTIdCost.id2.toString()].v,
          r: vrsData[NFTIdCost.id2.toString()].r,
          s: vrsData[NFTIdCost.id2.toString()].s,
        },
        {
          noNfts: 0,
          privateSaleMintedAmount: MintedAmountId3.toNumber(),
          privateSaleMaxMintAmount: MaxMintAmountId3.toNumber(),
          allowedNoNfts: Id3AllowedToMint,
          img: planet_small,
          text: NFTsName.SmallPlanet.title,
          value: nftId3Cost,
          nftId: NFTIdCost.id3,
          v: vrsData[NFTIdCost.id3.toString()].v,
          r: vrsData[NFTIdCost.id3.toString()].r,
          s: vrsData[NFTIdCost.id3.toString()].s,
        },
        {
          noNfts: 0,
          privateSaleMintedAmount: MintedAmountId4.toNumber(),
          privateSaleMaxMintAmount: MaxMintAmountId4.toNumber(),
          allowedNoNfts: Id4AllowedToMint,
          img: planet_medium,
          text: NFTsName.MediumPlanet.title,
          value: nftId4Cost,
          nftId: NFTIdCost.id4,
          v: vrsData[NFTIdCost.id4.toString()].v,
          r: vrsData[NFTIdCost.id4.toString()].r,
          s: vrsData[NFTIdCost.id4.toString()].s,
        },
        {
          noNfts: 0,
          privateSaleMintedAmount: MintedAmountId5.toNumber(),
          privateSaleMaxMintAmount: MaxMintAmountId5.toNumber(),
          allowedNoNfts: Id5AllowedToMint,
          img: planet_large,
          text: NFTsName.LargePlanet.title,
          value: nftId5Cost,
          nftId: NFTIdCost.id5,
          v: vrsData[NFTIdCost.id5.toString()].v,
          r: vrsData[NFTIdCost.id5.toString()].r,
          s: vrsData[NFTIdCost.id5.toString()].s,
        }
      ];
      return window.structuredClone(data)
    });
  }

  useEffect(() => {
    async function getMintData() {
      await getMaxMintAmountPerUserSalePrivate();
      // setEnableSell(await minterV2Contract.pausedPrivateSale());
    };

    if(provider) {
      getMintData();
    }
    
  });


  function setListElementShow(userListPermision, maxMintAmountPerUser) {
    if (userListPermision === false && maxMintAmountPerUser > 0) {
      setEnableSell(true);
    } else {
      setEnableSell(false);
    }
  }

  return <>
    <CurrencyModal show={planetSaleContext.getMintModalShow()}
                   onHide={() => planetSaleContext.setMintModalShowValue(false)}
                   planetToMint={planetSaleContext.planetToMint}
                   numberOfNFTToMin={planetSaleContext.numberOfNFTToMin}
                   NFTCost={planetSaleContext.NFTCost}
                   nftId={planetSaleContext.nftId}
                   sellType={planetSaleContext.sellType}
                   ticketId={planetSaleContext.ticket}
                   v={planetSaleContext.V}
                   r={planetSaleContext.R}
                   s={planetSaleContext.S}
                   index={planetSaleContext.indexOfNFT}
    />
    <PlanetPurchaseModal show={planetSaleContext.planetPurchaseModalShow}
                         onHide={() => planetSaleContext.setPlanetPurchaseModalShow(false)}
                         contractApprovalRequired={true}
                         provider={provider}
    />


    <div className='public-sale-main-body'>
      <div className='pgs-public-sale-border-img-container' id='pgs-border-img'>
        <BorderImg text={PrivateSaleText.title}/>
      </div>
      <div>

        {!walletContext.signed && <ConnectWalletBox title={ConnectWalletText.title}/>}
        {(walletContext.signed && !isCorrectChain) &&
          <SwitchNetworkChainSection
            network={network}
            message={`You must be on the ${process.env.REACT_APP_CHAINID} network to view this sale`}/>
        }

        {(walletContext.signed && isCorrectChain) ?


          enableSell !== undefined
            ? //Enable Sell is NOT undefined (proceed)
            enableSell === true
              ? //enableSell is TRUE, ready to show sale
              planetSaleContext.saleConfigurations["PrivatePresale"].length !== 0
                ? //Sale Configurations ready for Private Sale
                //Show the actual sale
                <ListElement ticketId={""} sellType={"PrivatePresale"}/>

                : //Sale configurations NOT ready for Private Sale
                <div className={"loading-ring-position"}><LoadingSpinner/></div>

              : //enableSell is FALSE, this sale is not yet available
              <PrivateSaleErrorBox text={privateSaleErrorBoxText.text}/>

            : //enableSell IS undefined (waiting for sale data to be loaded from the contract)
            <div className={"loading-ring-position"}><LoadingSpinner/></div>


          : <></>}
      </div>
    </div>


  </>
}
