import "./PlayOnBehalfExploration.scss";
import { boarder_detail_bottom, boarder_detail_top } from "../../../assets/images/AstrafiteRushEvent";

export default function PlayOnBehalfExploration({ textValue, handleTextChange, handleRef }) {
  return (
    <>
      <div className={"play-on-behalf-exploration-main-container"} ref={handleRef}>
        <div className={"play-on-behalf-content"}>
          <img alt={""} className={"top-crown"} src={boarder_detail_top} />
          <div className={"line-separator"} />
          <div className={"play-on-behalf-text"}>
            <span>
              <u>Wallet Linking</u>
              <br />
              Optional: Enter another Wallet to play on your behalf
            </span>
          </div>

          <div className={"wallet-address-input-container"}>
            <div className={"submit-area-input"}>
              <input className={"input-submit-value"} placeholder={"Enter Wallet"} type="text" value={textValue} onChange={handleTextChange} />
            </div>
          </div>
          <div className={"line-separator"} />
          <img alt={""} className={"bottom-crown"} src={boarder_detail_bottom} />
        </div>
      </div>
    </>
  );
}
