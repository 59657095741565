import React from 'react';

export default class LoadingSpinner extends React.Component {

  constructor(props) {
    super(props);

    this.marginTop = props.marginTop || '0';
    this.marginBottom = props.marginBottom || '100px';
    this.size = props.size || 'md'
    this.className = props.size === 'sm' ? 'lds-ripple-sm' : 'lds-ripple'
  }

  render() {
    return (
      <div style={{marginBottom: this.marginBottom, marginTop: this.marginTop, textAlign: "center"}}>
        <div className={this.className}>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
}
