import { SelectButton } from '../../Common/SelectButton/SelectButton';
import './SelectNetworkOptionButton.scss';

import { LayerZeroConfig } from '../../TokenBridge/tokenBridgeTypes';

type SelectNetworkOptionButtonProps = {
  currentChainId: number | null;
  handleSelectNetworkSwitch: (chainId?: string) => void;
  networkOptions: LayerZeroConfig;
};

export function SelectNetworkOptionButton({ currentChainId, handleSelectNetworkSwitch, networkOptions }: SelectNetworkOptionButtonProps) {
  return Object.entries(networkOptions).map(([key, network]) => (
    <SelectButton
      className={`select-network-option__button ${(currentChainId ?? 0).toString() === key ? 'selected' : ''}`}
      key={key}
      handleClick={() => handleSelectNetworkSwitch(key)}
    >
      <div className={`select-network-option__container`} onClick={() => handleSelectNetworkSwitch(key)}>
        <div className={'select-network-option-image__container'}>
          <img className={network.imageClassName} src={network.imageSrc} alt={network.altImageText} />
        </div>
        <div className={'select-network-option__text'}>
          <p>{network.name}</p>
        </div>
      </div>
    </SelectButton>
  ));
}
