import "./ExplorationRewardsPlanet.scss";
import ExplorationRewardsCell from "../ExplorationRewardsCell/ExplorationRewardsCell";

export default function ExplorationRewardsPlanet({
                                                   largePlanet,
                                                   mediumPlanet,
                                                   smallPlanet,
                                                   largeAsteroid,
                                                   smallAsteroid
                                                 }) {

  return <div className={"rewards-planet-container"}>
    <div className={"rewards-planet-title-container"}>
      <div className={"rewards-title-text"}>PLANET REWARDS</div>
    </div>
    {<div className={"rewards-planet-data-container"}>
      <ExplorationRewardsCell cellTitle={"Large Planet:"} cellData={largePlanet} cellColor={"blue"}
                              cellSize={"small"} />
      <ExplorationRewardsCell cellTitle={"Medium Planet:"} cellData={mediumPlanet}
                              cellColor={"blue"}
                              cellSize={"small"} />
      <ExplorationRewardsCell cellTitle={"Small Planet:"} cellData={smallPlanet} cellColor={"blue"}
                              cellSize={"small"} />
      <ExplorationRewardsCell cellTitle={"Large Asteroid:"} cellData={largeAsteroid}
                              cellColor={"blue"}
                              cellSize={"small"} />
      <ExplorationRewardsCell cellTitle={"Small Asteroid:"} cellData={smallAsteroid}
                              cellColor={"blue"}
                              cellSize={"small"} />
    </div>}
  </div>;
}
