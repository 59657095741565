import React from "react";
import "./GetReadyForAstrafiteRush.scss";


import CarouselModalButton from "../CarouselModalButton/CarouselModalButton";
import {console_icon, download_cloud, wallet_icon} from "../../../assets/images/CarouselModal";
import {carouselText} from "../../../utils/GeneralUtils";

export default function GetReadyForAstrafiteRush() {


  return (<>

    <div className={"start-carousel-main-container"}>
      <div className={"header-title-start-carousel"}>
        <div className={"start-carousel-title"}>GET READY FOR</div>
        <div className={"start-carousel-second-title"}>ASTRAFITE RUSH</div>
      </div>
      <div className={"start-carousel-content-container"}>

        <div className={"content-box-element"}>
          <div className={"img-box-for-alignment"}>
            <img src={wallet_icon} alt={""} className={"wallet-icon-adjustment"}/>
          </div>
          <div className={"content-container-button"}>
            <CarouselModalButton text={"CONNECT WALLET"}/>
          </div>
        </div>

        <div className={"content-box-element"}>
          <div className={"img-box-for-alignment"}>
            <img src={console_icon} alt={""} className={"console-icon"}/>
          </div>
          <div className={"content-container-button upper-1024"}>
            <CarouselModalButton text={"DOWNLOAD & PLAY"}/>
          </div>
          <div className={"content-container-button under-1024"}>
            <CarouselModalButton text={"DOWNLOAD"}/>
          </div>
        </div>
        <div className={"content-box-element"}>
          <div className={"img-box-for-alignment"}>
            <img src={download_cloud} alt={""} className={"download-cloud"}/>
          </div>
          <div className={"content-container-button"}>
            <CarouselModalButton text={"FIND EVENT"}/>
          </div>
        </div>
      </div>
      <div className={"start-carousel-description-text"}>
        {carouselText.firstCarouselText}
      </div>
    </div>
  </>);
}
