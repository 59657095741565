import React, { useEffect, useState } from "react";
import PageHeader from "../../PageHeader";
import ViewOnOpenseaButton from "./ViewOnOpenseaButton/ViewOnOpenseaButton";

import "./AstrafiteToken.scss";
import AstrafiteTokenImage from "./AstrafiteTokenImage/AstrafiteTokenImage";
import AstrafiteTokenInfo from "./AstrafiteTokenInfo/AstrafiteTokenInfo";

import { getBlockchainContract } from "../../../utils/providerObject";
import afrNFTABI from "../../../assets/abi/AFRNFT.json";
import { useAccount } from 'wagmi'


export default function AstrafiteToken(props) {
  const { tokenId } = props;

  const [ownerWalletAddress, setOwnerWalletAddress] = useState();
  const [assignedRewards, setAssignedRewards] = useState(0);
  const [claimedRewards, setClaimedRewards] = useState(0);

  const { connector } = useAccount();
  const [provider, setProvider] = useState(undefined);

  useEffect( () => {
    const getProvider = async () => {
      const thisProvider = await connector?.getProvider();

      setProvider(thisProvider);
    }
    getProvider();
  });

  useEffect(() => {
    const getOwnerWalletAddress = async () => {
      let afrNFTContract = getBlockchainContract(process.env.REACT_APP_AFR_NFT_ADDRESS, afrNFTABI, provider);

      let owner = (await afrNFTContract.ownerOf(tokenId));

      setOwnerWalletAddress(owner);

      let assignedRewards = (await afrNFTContract.TokenIdToRewards(tokenId)).toNumber();
      let claimedRewards = (await afrNFTContract.TokenIdToClaimedRewards(tokenId)).toNumber();

      setAssignedRewards(assignedRewards);
      setClaimedRewards(claimedRewards);
    };

    if(tokenId && provider) {
      getOwnerWalletAddress().then();
    }
  }, [tokenId, provider]);

  return (
    <div className={"astrafite-rush-progress-main-container"}>
      <div className={"astrafite-rush-progress-content"}>
        <div className={"astrafite-rush-progress-title"}>
          <PageHeader
            headerText={"'ASTRAFITE RUSH: "}
            headerTextSecondLine={"PROGRESS TRACKER' NFT"}
            classname={"page-mobile-header-text"}
          />
        </div>
        <div className={"astrafite-rush-progress-container"}>
          <div className={"astrafite-rush-progress-opensea-button-container"}>

          <ViewOnOpenseaButton token={tokenId} />
          </div>
          
          <div className={"astrafite-rush-token-image"}><AstrafiteTokenImage token={tokenId} /></div>
          <AstrafiteTokenInfo
            token={tokenId}
            wallet={ownerWalletAddress}
            unclaimedAstrafer={assignedRewards - claimedRewards}
          />
        </div>
      </div>
    </div>
  );
}
