import {closeIcon} from "../../../assets";
import React, {useEffect} from "react";
import "./PreviewModal.scss";

export const PreviewModalType = {
  Image: "Image",
  Video: "Video",
  None: "None"
}

export default function PreviewModal(props) {

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Escape') {
        event.preventDefault();
        handleModalClose();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  });

  const handleModalClose = () =>{
    props.handleModalClose();
  }

  let content = <div/>;
  if(props.type === PreviewModalType.Image){
    content = <img className={"preview-modal-content"} alt={"preview"} src={props.src}/>;
  }
  else if(props.type === PreviewModalType.Video){
    content = <video loop autoPlay="autoplay" playsInline muted className={"preview-modal-content-video"} src={props.src}/>;
  }
  return (
    <div className={"preview-modal"} style={{"visibility": props.visible ? "visible" :"hidden"}}>
      <div className={"preview-set-img-dimension"}>
      <img alt="" className={"modal-close-button"}
           onClick={() =>{
            handleModalClose()
           }} src={closeIcon}/>
      {content}
      </div>
    </div>
  )
}
