import React, {useState} from 'react';

const TooltipContext = React.createContext({});

function TooltipProvider(props) {

  const [copyEmailStatus, setCopyEmailStatus] = useState({text: 'Copy Email', visible: false});
  const [copyAccessCodeStatus, setCopyAccessCodeStatus] = useState({text: 'Copy access code', visible: false});

  return (
    <TooltipContext.Provider
      value={{
        copyEmailStatus,
        setCopyEmailStatus,
        copyAccessCodeStatus,
        setCopyAccessCodeStatus
      }}
    >
      {props.children}
    </TooltipContext.Provider>
  )
}

export {TooltipContext, TooltipProvider};
