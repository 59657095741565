import './PlanetExplorationMissionTask.scss'

export default function PlanetExplorationMissionTask({missionStatus}) {

  const getMissionTaskContent = () => {
    if (missionStatus !== 'complete') {
      return <><p className={'planet-exploration-mission-task-content'}>
        To complete your Check-In for today, pilot your probe ship into the perilous Serritor Asteroid Field, keep your
        ship intact, and fill your hold with valuable Hermesium.</p>
        <p className={'planet-exploration-mission-task-content list'}>
          1. Launch into the Serritor Asteroid Field with a single click! <br/>
          2. Turn your ship with the keyboard arrow keys (desktop only) or virtual joystick, and your ship will
          automatically speed in that direction. <br/>
          3. Your weapons fire automatically, their trajectories are based on the direction your ship is facing. <br/>
          4. Obliterate the asteroids with your ship's firepower, revealing valuable golden Hermesium and occasional
          weapon power-ups which you can fly through to collect. <br/>
          5. If your ship is destroyed by asteroid damage before you meet the Hermesium goal, you have failed and must begin again. <br/>
          6. Reach your Hermesium goal to complete your Check-In and automatically leave the Asteroid Field – and be richly rewarded!</p>
        <p className={'planet-exploration-mission-task-content'}>
          Hermesium is vital to successful long-distance warp travel, and will pave the way for Ceres Quadrant
          settlement. Deliver your Hermesium to Pointech, and be rewarded with an ASTRAFER haul! </p></>
    } else {
      return <><p className={'planet-exploration-mission-task-content'}>Your Mission is complete - this strange and
        perilous pocket of the universe is a little brighter, a little less unwelcoming. Hermesium gleaned from the
        Ceres Quadrant fetches generous prices, and the Commonwealth and Union are eager to reward dedicated
        explorers.</p>
        <p className={'planet-exploration-mission-task-content'}>Select ‘Claim Rewards’ above to receive your ASTRAFER
          haul and Planet rewards. With your Mission complete, you can now Unbind your Planets in the ‘Planets’ section
          of your Portfolio, so they may be moved, sold or used for further Missions - another phase of missions is
          coming soon...</p></>
    }
  }

  return <>
    <div className={'planet-exploration-mission-task-main-container'}>
      <p
        className={'planet-exploration-mission-task-title'}> {missionStatus !== 'complete' ? 'TASK' : 'MISSION COMPLETE'} </p>
      {getMissionTaskContent()}
    </div>
  </>
}
