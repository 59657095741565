import {useEffect, useRef} from "react";

/**
 * Custom React hook to create and manage an interval with optional start and stop control.
 *
 * @param {function} callback - The function to be called on each interval tick.
 * @param {number|null} delay - The time (in milliseconds) between each interval tick. If set to `null`, the interval won't start automatically.
 * @returns {object} - An object containing the `clear` function to stop the interval.
 *
 * @example
 * // Using the useInterval hook
 * function MyComponent() {
 *   const [count, setCount] = useState(0);
 *
 *   const incrementCount = () => setCount((prevCount) => prevCount + 1);
 *
 *   // Create an interval that increments the count every 1000 milliseconds (1 second).
 *   const { clear } = useInterval(incrementCount, 1000);
 *
 *   // Function to stop the interval when needed.
 *   const stopInterval = () => clear();
 *
 *   return (
 *     <div>
 *       <p>Count: {count}</p>
 *       <button onClick={stopInterval}>Stop Interval</button>
 *     </div>
 *   );
 * }
 */
export const useInterval = (callback, delay) => {
  const savedCallback = useRef();
  const intervalId = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    }

    if(delay !== null) {
      intervalId.current = setInterval(tick, delay);
    }

    return () => clearInterval(intervalId.current);
  })

  // Function to stop the interval when needed
  const clear = () => clearInterval(intervalId.current);

  return { clear };
}
