import "./GenerativeAvatarMintingContent.scss";
import { useEffect, useRef, useState } from "react";
import GenerativeAvatarMintpassGrid from "../GenerativeAvatarMintpassGrid/GenerativeAvatarMintpassGrid";
import GenerativeAvatarCatalystGrid from "../GenerativeAvatarCatalystGrid/GenerativeAvatarCatalystGrid";
import GenerativeAvatarTraitGrid from "../GenerativeTraitGrid/GenerativeAvatarTraitGrid";
import {
  AVATAR_MINTPASSES,
  CELESTIAL_BODIES_TRAIT_TITLE,
  EMPTY_STRING,
  MAXIMUM_PLANET_MINTS,
  NO_TRAIT,
} from "../../../utils/GeneralUtils";
import {
  add_catalyst_icon,
  random_catalyst,
} from "../../../assets/images/GenerativePlanetMinting";
import GenerativeAvatarMintPopupWithoutCatalyst from "../GenerativeAvatarMintPopupWithoutCatalyst/GenerativeAvatarMintPopupWithoutCatalyst";
import GenerativeAvatarMintPopupWithCatalyst from "../GenerativeAvatarMintPopupWithCatalyst/GenerativeAvatarMintPopupWithCatalyst";
import BaseButton from "../../BaseButton/BaseButton";
import GenerativeAvatarMintingInfo from "../GenerativeAvatarMintingInfo/GenerativeAvatarMintingInfo";
import AvatarTransferPopup from "../AvatarTransferPopup/AvatarTransferPopup";
import GenerativeAvatarMintCongratulationsPopup from "../GenerativeAvatarMintCongratulationsPopup/GenerativeAvatarMintCongratulationsPopup";
import {
  getAvatarCatalysts,
  getAvatarMintpassBalances,
  getAvatarCatalystBalances
} from "../../../utils/ApiCalls";
import { walletContext } from "../../../utils/WalletContext";
import {useAccount} from "wagmi";
import {
  avatar_catalyst_gender_female,
  avatar_catalyst_gender_male,
  avatar_catalyst_heritage_african,
  avatar_catalyst_heritage_asian,
  avatar_catalyst_heritage_caucasian, avatar_catalyst_heritage_latin_american
} from "../../../assets/images/GenerativeAvatarMinting";

export const AVATAR_CATALYST_IMAGES = [
  {
    tokenId: 2001,
    image: avatar_catalyst_gender_male,
  },
  {
    tokenId: 2002,
    image: avatar_catalyst_gender_female,
  },
  {
    tokenId: 2101,
    image: avatar_catalyst_heritage_caucasian,
  },
  {
    tokenId: 2102,
    image: avatar_catalyst_heritage_asian,
  },
  {
    tokenId: 2103,
    image: avatar_catalyst_heritage_african,
  },
  {
    tokenId: 2104,
    image: avatar_catalyst_heritage_latin_american,
  },
];

export const GENDER_TRAIT_TITLE = 'Gender';
export const GENETIC_BASE_TRAIT_TITLE = 'Genetic Base';

export const MINT_TYPE = {
  batch: "batch",
  single: "single",
};

export default function GenerativeAvatarMintingContent() {
  const ref = useRef(null);

  const defaultAvatarMintpasses = AVATAR_MINTPASSES.map((avatarMintpass) => {
    return {
      tokenId: avatarMintpass.tokenId,
      title: avatarMintpass.name,
      image: avatarMintpass.image,
      stock: 0,
      count: 0,
      acceptsCelestialBodies: avatarMintpass.acceptsCelestialBodies,
      openseaUrl: avatarMintpass.openseaUrl,
    };
  });

  const [transferPopup, setTransferPopup] = useState(false);
  const [congratulationsPopup, setCongratulationsPopup] = useState(false);
  const [totalPlanetsCount, setTotalPlanetsCount] = useState(0);
  const [traits, setTraits] = useState([]);
  const [catalysts, setCatalysts] = useState([]);
  const [selectedAvatar, setSelectedAvatar] = useState(EMPTY_STRING);
  const [showPopupWithoutCatalyst, setShowPopupWithoutCatalyst] =
    useState(false);
  const [showPopupWithCatalyst, setShowPopupWithCatalyst] = useState(false);
  const [isBatch, setIsBatch] = useState(true);
  const [avatarMintpasses, setAvatarMintpasses] = useState(
    defaultAvatarMintpasses
  );
  const [batchPopupValues, setBatchPopupValues] = useState(
    defaultAvatarMintpasses
  );
  const [catalystPopupValues, setCatalystPopupValues] = useState({
    avatarSelected: {
      title: EMPTY_STRING,
      image: EMPTY_STRING,
    },
    gender: {
      title: EMPTY_STRING,
      image: random_catalyst,
    },
    geneticBase: {
      title: EMPTY_STRING,
      image: random_catalyst,
    }
  });
  const [mintSelection, setMintSelection] = useState({});

  const { connector } = useAccount();
  const [provider, setProvider] = useState(undefined);

  useEffect( () => {
    const getProvider = async () => {
      const thisProvider = await connector?.getProvider();

      setProvider(thisProvider);
    }
    getProvider();
  });


  useEffect(() => {
    const formatCatalystsWithBalances = (
      catalystsFromAPI,
      catalystBalances
    ) => {
      const formattedCatalysts = catalystsFromAPI.map((catalystGroup) => {
        const formattedTraits = catalystGroup.types.map((type) => {
          let tokenBalance = catalystBalances.tokens.find(
            (token) => token.tokenId === type.tokenId
          );

          let traitImage = AVATAR_CATALYST_IMAGES.find(
            (trait) => trait.tokenId === type.tokenId
          ).image;

          // Format each trait/catalyst type with the following fields
          return {
            tokenId: type.tokenId,
            title: type.name,
            selected: false,
            image: traitImage,
            stock: tokenBalance.balance,
            openseaUrl: type.openseaUrl,
          };
        });
        // Format each catalyst category with the following fields
        return {
          category: catalystGroup.category,
          title: catalystGroup.displayName,
          image: add_catalyst_icon,
          selectedImage: add_catalyst_icon,
          selected: false,
          traitSelected: false,
          traits: formattedTraits,
        };
      });
      return formattedCatalysts;
    };

    getAvatarCatalysts().then((catalystsRes) => {
      if (walletContext.signed && !congratulationsPopup) {
        getAvatarCatalystBalances(walletContext.currentWallet).then(
          (balanceRes) => {
            // Clear selected values when congrats modal is closed after mint completion
            setSelectedAvatar(EMPTY_STRING);
            setCatalysts(formatCatalystsWithBalances(catalystsRes, balanceRes));
            setTraits([]);
            setTotalPlanetsCount(0);
          }
        );
      }
    });
  }, [congratulationsPopup]);

  const resetCatalystSelectibility = () => {
    let updatedCatalysts = catalysts.map((catalyst) => {
      catalyst.selectableCatalyst = true;
      return catalyst;
    });
    setCatalysts(updatedCatalysts);
  };

  const disableCatalystSelectibility = (title) => {
    let updatedCatalysts = catalysts.map((catalyst) => {
      catalyst.selectableCatalyst = catalyst.title !== title;
      if (!catalyst.selectableCatalyst) {
        // Deselect any traits for the disabled catalyst
        catalyst.traits.forEach((trait) => (trait.selected = false));
        // Reset the catalyst image as the trait is now deselected
        catalyst.image = add_catalyst_icon;
        // If the disabled catalyst was selected, deselect catalyst and clear displayed traits
        if (catalyst.selected) {
          catalyst.selected = false;
          setTraits([]);
        }
      }
      return catalyst;
    });
    setCatalysts(updatedCatalysts);
  };

  useEffect(() => {
    const formatAvatarMintpassWithBalances = (balances) => {
      const formattedMintpasses = avatarMintpasses.map((mintpass) => {
        let tokenBalance = balances.tokens.find(
          (token) => token.tokenId === mintpass.tokenId
        );

        return {
          ...mintpass,
          count: 0,
          stock: tokenBalance.balance,
        };
      });

      return formattedMintpasses;
    };

    if (walletContext.signed && !congratulationsPopup) {
      getAvatarMintpassBalances(walletContext.currentWallet).then(
        (balances) => {
          const formatted = formatAvatarMintpassWithBalances(balances);
          if (JSON.stringify(formatted) !== JSON.stringify(avatarMintpasses)) {
            setAvatarMintpasses(formatted);
            setBatchPopupValues(formatted);
          }
        }
      );
    }
  }, [avatarMintpasses, congratulationsPopup]);

  const batchPopupValuesSetter = (values) => {
    setBatchPopupValues(values);
    let totalPlanets = 0;
    batchPopupValues.forEach((mintpass) => (totalPlanets += mintpass.count));
    setTotalPlanetsCount(totalPlanets);
  };

  const planetClickHandler = (tokenId) => {
    let selectedMintpass = avatarMintpasses.find(
      (mintpass) => mintpass.tokenId === tokenId
    );

    setSelectedAvatar(tokenId);

    setCatalystPopupValues({
      ...catalystPopupValues,
      avatarSelected: {
        tokenId: selectedMintpass.tokenId,
        title: selectedMintpass.title,
        image: selectedMintpass.image,
        acceptsCelestialBodies: selectedMintpass.acceptsCelestialBodies,
      },
    });

    if (!selectedMintpass.acceptsCelestialBodies) {
      disableCatalystSelectibility(CELESTIAL_BODIES_TRAIT_TITLE);
    } else {
      resetCatalystSelectibility();
    }
  };

  const catalystClickHandler = (title) => {
    let selectedCatalyst;

    const updatedCatalysts = catalysts.map((catalyst) => {
      const isSelected = catalyst.title === title;
      catalyst.selected = isSelected;
      if (isSelected) {
        selectedCatalyst = catalyst;
      }
      return catalyst;
    });

    setCatalysts(updatedCatalysts);
    setTraits(selectedCatalyst.traits);
  };
  const traitClickHandler = (title) => {
    let updatedTraits = traits.map((trait) => {
      if (trait.title === title) {
        if (!trait.selected) {
          trait.selected = true;
        }
      } else {
        trait.selected = false;
      }
      return trait;
    });
    let trait = updatedTraits.find((trait) => trait.selected === true);
    let updatedCatalysts = catalysts.map((catalyst) => {
      if (catalyst.selected) {
        if (title !== NO_TRAIT) {
          catalyst.image = trait.image;
          catalyst.selectedImage = trait.image;
          catalyst.traitSelected = false;
        } else {
          catalyst.image = add_catalyst_icon;
          catalyst.selectedImage = add_catalyst_icon;
          catalyst.traitSelected = true;
        }
      }
      return catalyst;
    });
    let updatedCatalystPopupValues = catalystPopupValues;
    updatedCatalysts.forEach((catalyst) => {
      if (catalyst.selected) {
        switch (catalyst.title) {
          case GENDER_TRAIT_TITLE:
            if (title !== NO_TRAIT) {
              updatedCatalystPopupValues.gender.tokenId = trait.tokenId;
              updatedCatalystPopupValues.gender.title = trait.title;
              updatedCatalystPopupValues.gender.image = trait.image;
            } else {
              updatedCatalystPopupValues.gender.title = EMPTY_STRING;
              updatedCatalystPopupValues.gender.image = random_catalyst;
            }
            break;
          case GENETIC_BASE_TRAIT_TITLE:
            if (title !== NO_TRAIT) {
              updatedCatalystPopupValues.geneticBase.tokenId = trait.tokenId;
              updatedCatalystPopupValues.geneticBase.title = trait.title;
              updatedCatalystPopupValues.geneticBase.image = trait.image;
            } else {
              updatedCatalystPopupValues.geneticBase.title = EMPTY_STRING;
              updatedCatalystPopupValues.geneticBase.image = random_catalyst;
            }
            break;
          default:
            break;
        }
      }
    });
    setCatalysts(updatedCatalysts);
    setTraits(updatedTraits);
    setCatalystPopupValues(updatedCatalystPopupValues);
  };

  const switchHandler = () => {
    setIsBatch(!isBatch);
  };

  const showPopupWithoutCatalystHandler = () => {
    setShowPopupWithoutCatalyst(true);
  };

  const handleNoWithoutCatalyst = () => {
    setShowPopupWithoutCatalyst(false);
  };

  const handleYesWithoutCatalyst = () => {
    setShowPopupWithoutCatalyst(false);

    setMintSelection({
      type: MINT_TYPE.batch,
      mintpasses: batchPopupValues,
    });
    transferHandler();
  };

  const showPopupWithCatalystHandler = () => {
    setShowPopupWithCatalyst(true);
  };

  const handleNoWithCatalyst = () => {
    setShowPopupWithCatalyst(false);
  };

  const handleYesWithCatalyst = () => {
    setShowPopupWithCatalyst(false);

    const selectedCatalysts = [];

    catalysts.forEach((category) => {
      category.traits.forEach((trait) => {
        if (trait.selected) {
          selectedCatalysts.push(trait.tokenId);
        }
      });
    });

    setMintSelection({
      type: MINT_TYPE.single,
      mintpass: catalystPopupValues.avatarSelected.tokenId,
      catalysts: selectedCatalysts,
    });

    transferHandler();
  };

  const scrollToInfo = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const transferHandler = () => {
    setTransferPopup(!transferPopup);
  };

  const congratulationsHandler = () => {
    setCongratulationsPopup(!congratulationsPopup);
  };

  return (
    <div className="avatar-minting-content">
      <div className="avatar-minting-switch">
        <div
          className={`avatar-minting-switch-title ${
            !isBatch ? "disabled-title" : ""
          }`}
        >
          BATCH SELECT
        </div>
        <label className="minting-switch">
          <input type="checkbox" />
          <span className="slider round" onClick={switchHandler} />
        </label>
        <div
          className={`avatar-minting-switch-title ${
            isBatch ? "disabled-title" : ""
          }`}
        >
          ADD CATALYST
        </div>
      </div>
      {isBatch ? (
        <>
          <div>
            <GenerativeAvatarMintpassGrid
              scrollToInfo={scrollToInfo.bind(this)}
              isCatalyst={false}
              selected={EMPTY_STRING}
              selectHandler={batchPopupValuesSetter.bind(this)}
              planetMintpasses={batchPopupValues}
              isBatch={isBatch}
            />
            {showPopupWithoutCatalyst ? (
              <GenerativeAvatarMintPopupWithoutCatalyst
                planetMintpasses={batchPopupValues}
                handleNo={handleNoWithoutCatalyst}
                handleYes={handleYesWithoutCatalyst}
              />
            ) : (
              EMPTY_STRING
            )}
          </div>
          <div className={"avatar-minting-button-container"}>
            <BaseButton
              onClick={showPopupWithoutCatalystHandler}
              disabled={
                totalPlanetsCount < 1 ||
                totalPlanetsCount > MAXIMUM_PLANET_MINTS
                  ? true
                  : false
              }
              filled={true}
              scale={true}
              text={"MINT AVATARS"}
            />
          </div>
        </>
      ) : (
        <>
          <div>
            <GenerativeAvatarMintpassGrid
              scrollToInfo={scrollToInfo.bind(this)}
              isCatalyst={true}
              selected={selectedAvatar}
              selectHandler={planetClickHandler.bind(this)}
              planetMintpasses={batchPopupValues}
              isBatch={isBatch}
            />
            <GenerativeAvatarCatalystGrid
              disabled={selectedAvatar === EMPTY_STRING}
              isCatalystFlow={true}
              selectHandler={catalystClickHandler.bind(this)}
              catalysts={catalysts}
            />
            <GenerativeAvatarTraitGrid
              catalysts={catalysts}
              selectHandler={traitClickHandler.bind(this)}
              isCatalystFlow={true}
              isDisabled={traits.length === 0}
              traits={traits}
            />
            {showPopupWithCatalyst ? (
              <GenerativeAvatarMintPopupWithCatalyst
                popupValues={catalystPopupValues}
                handleNo={handleNoWithCatalyst}
                handleYes={handleYesWithCatalyst}
              />
            ) : (
              ""
            )}
          </div>
          <div className={"avatar-minting-button-container"}>
            <BaseButton
              onClick={showPopupWithCatalystHandler}
              disabled={selectedAvatar ? false : true}
              filled={true}
              scale={true}
              text={"MINT AVATAR"}
            />
          </div>
        </>
      )}

      {isBatch && (
        <div className={"avatar-minting-total-counter"}>
          {totalPlanetsCount}/{MAXIMUM_PLANET_MINTS}
        </div>
      )}
      <div ref={ref} className={"generative-planet-info"}>
        <GenerativeAvatarMintingInfo />
      </div>

      {transferPopup && (
        <AvatarTransferPopup
          show={transferPopup}
          mintSelection={mintSelection}
          catalystCheck={!isBatch ? false : undefined}
          handleClose={transferHandler.bind(this)}
          handleCongratulations={congratulationsHandler}
          provider={provider}
        />
      )}
      {congratulationsPopup && (
        <GenerativeAvatarMintCongratulationsPopup
          handleClose={congratulationsHandler.bind(this)}
        />
      )}
    </div>
  );
}
