import './FederatedAuthenticationHandler.scss';

import { Alert } from "react-bootstrap";

interface AuthResponseProps {
  onDismiss: () => void;
  displayText: string;
  isSuccess: boolean;
}
/**
 * Searches for the presence of the auth response action and presents the result to the user as an alert
 *
 * The AuthManager manages the state of this response (as it come be returned via an AJAX service call, or
 * alternatively as part of the result cookie from the federated authentication service)
 */
export function FederatedAuthenticationHandler({onDismiss, displayText, isSuccess}: AuthResponseProps) {
  return (
    <>
      { displayText &&
        <Alert show={true} dismissible={true} onClose={onDismiss} variant={isSuccess ? 'secondary' : 'danger'}>
          {displayText}
        </Alert>
      }
    </>
  );
}
