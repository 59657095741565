import "./GenerativePlanetMintPopupWithCatalyst.scss";
import {
  disabled_catalyst_icon,
  popup_border_bottom_left_thin,
  popup_border_bottom_left_thin_small,
  popup_border_bottom_right_thin,
  popup_border_bottom_right_thin_small,
  popup_border_top_left_thin,
  popup_border_top_left_thin_small,
  popup_border_top_right_thin,
  popup_border_top_right_thin_small,
  random_catalyst,
} from "../../../assets/images/GenerativePlanetMinting";
import BaseButton from "../../BaseButton/BaseButton";
import { EMPTY_STRING, NONE } from "../../../utils/GeneralUtils";

export default function GenerativePlanetMintPopupWithCatalyst({
  popupValues,
  handleNo,
  handleYes,
}) {
  const getPlanetImageClass = (title) => {
    if (title === EMPTY_STRING) {
      return "generative-planet-component-image random";
    }
    return "generative-planet-component-image";
  };

  const getCelestialBodyImageClass = (title) => {
    if (!popupValues.planetSelected.acceptsCelestialBodies) {
      return "generative-planet-component-image random none";
    }
    if (title === EMPTY_STRING) {
      return "generative-planet-component-image random";
    }
    return "generative-planet-component-image";
  };

  const isDisabledImageAndText = (image) => {
    return image === disabled_catalyst_icon || image === random_catalyst;
  };

  const getCelestialBodyTitle = () => {
    if (!popupValues.planetSelected.acceptsCelestialBodies) return NONE;
    if (popupValues.celestialBodies.title === EMPTY_STRING) return "RANDOM";
    return popupValues.celestialBodies.title;
  };

  const getCelestialBodyImage = () => {
    if (!popupValues.planetSelected.acceptsCelestialBodies)
      return disabled_catalyst_icon;
    return popupValues.celestialBodies.image;
  };

  return (
    <div className={"planet-minting-popup-container"}>
      <div className={"planet-minting-popup-border"}>
        <img
          src={popup_border_top_left_thin}
          alt={"popup-border"}
          className={"planet-minting-popup-border-icon"}
        />
        <img
          src={popup_border_top_right_thin}
          alt={"popup-border"}
          className={"planet-minting-popup-border-icon"}
        />

        <img
          src={popup_border_top_left_thin_small}
          alt={"popup-border"}
          className={"planet-minting-popup-border-icon-small"}
        />
        <img
          src={popup_border_top_right_thin_small}
          alt={"popup-border"}
          className={"planet-minting-popup-border-icon-small"}
        />
      </div>
      <div className="planet-minting-popup-content">
        <div className="planet-minting-popup-title-catalyst">
          You are about to mint your Planet NFT, burning the following mintpass
          and Catalyst(s).
        </div>

        <div className={"planet-minting-popup-list"}>
          <div className={"planet-minting-popup-planet"}>
            <div className={"generative-planet-component"}>
              <div className={"generative-planet-border"}>
                <img
                  className={"generative-planet-component-image"}
                  src={popupValues.planetSelected.image}
                  alt={"PLANET"}
                />
              </div>
              <div className={"generative-planet-component-stock"}>{}</div>
              <div className={"generative-planet-component-title"}>
                {popupValues.planetSelected.title}
              </div>
              <div className={"generative-trait-title-invisible"}>
                ({popupValues.ecosystem.title})
              </div>
            </div>
          </div>
          <div className={"planet-minting-popup-trait"}>
            <div className={"generative-planet-component"}>
              <div className={"generative-planet-border"}>
                <img
                  className={getPlanetImageClass(popupValues.ecosystem.title)}
                  src={popupValues.ecosystem.image}
                  alt={"PLANET"}
                />
              </div>
              <div className={"generative-planet-component-stock"}>{}</div>
              <div
                className={
                  !isDisabledImageAndText(popupValues.ecosystem.image)
                    ? "generative-planet-component-title"
                    : "generative-planet-component-title disabled-trait-name"
                }
              >
                {"ECOSYSTEM"}
              </div>
              <div
                className={
                  !isDisabledImageAndText(popupValues.ecosystem.image)
                    ? "generative-trait-title"
                    : "generative-trait-title disabled-trait-name"
                }
              >
                {popupValues.ecosystem.title === ""
                  ? "RANDOM"
                  : popupValues.ecosystem.title}
              </div>
            </div>
          </div>
          <div className={"planet-minting-popup-trait"}>
            <div className={"generative-planet-component"}>
              <div className={"generative-planet-border"}>
                <img
                  className={getPlanetImageClass(
                    popupValues.resourceActivity.title
                  )}
                  src={popupValues.resourceActivity.image}
                  alt={"PLANET"}
                />
              </div>
              <div className={"generative-planet-component-stock"}>{}</div>
              <div
                className={
                  !isDisabledImageAndText(popupValues.resourceActivity.image)
                    ? "generative-planet-component-title"
                    : "generative-planet-component-title disabled-trait-name"
                }
              >
                {"RESOURCE REACTIVITY"}
              </div>
              <div
                className={
                  !isDisabledImageAndText(popupValues.resourceActivity.image)
                    ? "generative-trait-title"
                    : "generative-trait-title disabled-trait-name"
                }
              >
                {popupValues.resourceActivity.title === ""
                  ? "RANDOM"
                  : popupValues.resourceActivity.title}
              </div>
            </div>
          </div>
          <div className={"planet-minting-popup-trait"}>
            <div className={"generative-planet-component"}>
              <div className={"generative-planet-border"}>
                <img
                  className={getPlanetImageClass(
                    popupValues.resourceState.title
                  )}
                  src={popupValues.resourceState.image}
                  alt={"PLANET"}
                />
              </div>
              <div className={"generative-planet-component-stock"}>{}</div>
              <div
                className={
                  !isDisabledImageAndText(popupValues.resourceState.image)
                    ? "generative-planet-component-title"
                    : "generative-planet-component-title disabled-trait-name"
                }
              >
                {"RESOURCE STATE"}
              </div>
              <div
                className={
                  !isDisabledImageAndText(popupValues.resourceState.image)
                    ? "generative-trait-title"
                    : "generative-trait-title disabled-trait-name"
                }
              >
                {popupValues.resourceState.title === ""
                  ? "RANDOM"
                  : popupValues.resourceState.title}
              </div>
            </div>
          </div>
          <div className={"planet-minting-popup-trait"}>
            <div className={"generative-planet-component"}>
              <div className={"generative-planet-border"}>
                <img
                  className={getPlanetImageClass(popupValues.discoveries.title)}
                  src={popupValues.discoveries.image}
                  alt={"PLANET"}
                />
              </div>
              <div className={"generative-planet-component-stock"}>{}</div>
              <div
                className={
                  !isDisabledImageAndText(popupValues.discoveries.image)
                    ? "generative-planet-component-title"
                    : "generative-planet-component-title disabled-trait-name"
                }
              >
                {"DISCOVERIES"}
              </div>
              <div
                className={
                  !isDisabledImageAndText(popupValues.discoveries.image)
                    ? "generative-trait-title"
                    : "generative-trait-title disabled-trait-name"
                }
              >
                {popupValues.discoveries.title === ""
                  ? "RANDOM"
                  : popupValues.discoveries.title}
              </div>
            </div>
          </div>
          <div className={"planet-minting-popup-trait"}>
            <div className={"generative-planet-component"}>
              <div className={"generative-planet-border"}>
                <img
                  className={getCelestialBodyImageClass(
                    popupValues.celestialBodies.title
                  )}
                  src={getCelestialBodyImage()}
                  alt={"PLANET"}
                />
              </div>
              <div className={"generative-planet-component-stock"}>{}</div>
              <div
                className={
                  !isDisabledImageAndText(getCelestialBodyImage())
                    ? "generative-planet-component-title"
                    : "generative-planet-component-title disabled-trait-name"
                }
              >
                {"CELESTIAL BODIES"}
              </div>
              <div
                className={
                  !isDisabledImageAndText(getCelestialBodyImage())
                    ? "generative-trait-title"
                    : "generative-trait-title disabled-trait-name"
                }
              >
                {getCelestialBodyTitle()}
              </div>
            </div>
          </div>
        </div>
        <div className="planet-minting-popup-text">Do you wish to proceed?</div>
        <div className="planet-minting-popup-buttons">
          <div className="planet-minting-popup-button">
            <BaseButton
              text={"NO"}
              scale={false}
              filled={false}
              disabled={false}
              onClick={handleNo}
            />
          </div>
          <div className="planet-minting-popup-button">
            <BaseButton
              text={"YES"}
              scale={false}
              filled={true}
              disabled={false}
              onClick={handleYes}
            />
          </div>
        </div>
      </div>
      <div className={"planet-minting-popup-border"}>
        <img
          src={popup_border_bottom_left_thin}
          alt={"popup-border"}
          className={"planet-minting-popup-border-icon"}
        />
        <img
          src={popup_border_bottom_right_thin}
          alt={"popup-border"}
          className={"planet-minting-popup-border-icon"}
        />

        <img
          src={popup_border_bottom_left_thin_small}
          alt={"popup-border"}
          className={"planet-minting-popup-border-icon-small"}
        />
        <img
          src={popup_border_bottom_right_thin_small}
          alt={"popup-border"}
          className={"planet-minting-popup-border-icon-small"}
        />
      </div>
    </div>
  );
}
