import React, {useState} from "react";
import "./SimpleSpecialRow.scss";

import {hover_triangle} from "../../../assets/images/AstrafiteRushEvent";

export default function SimpleSpecialRow(props) {

  const [copyValue, setCopyValue] = useState("Copy");

  function copyThis(textToCopy) {
    navigator.clipboard.writeText(textToCopy);
    setCopyValue("Copied!");
  }

  function resetCopy() {
    setCopyValue("Copy");
  }

  return <>
    <div className={"leader-board-row-container"}>
      <div className={"special-row-leader-board-section"}/>
      <div className={"row-elements-box-for-alignment"}>
        <div className={"leader-table-element-description"}>
          <div className={"first-element-in-row"}>{props.rank}
          </div>
        </div>
        <div className={"leader-table-element-description"}>
          <div className={"player-element-in-row"} onMouseLeave={() => resetCopy()}
               onClick={() => copyThis(props.player)}>{
            props.player.substring(0, 9)
            + "..." +
            props.player.substring(props.player.length - 4)

          }

            <div className="copy-hover-element-bottom">
              <div className={"scale-copy-box"}>
                <p className={"rotate-copy-text"}>{copyValue}</p>
                <img alt={""} className={"copy-progress-hover-triangle"} src={hover_triangle}/>
              </div>
            </div>

          </div>
        </div>
        <div className={"leader-table-element-description"}>
          <div className={"completion-element-in-row"}>{props.completion}%


            <div className="completion-hover-element">
              <div className={"scale-copy-box"}>
                <div> Fire Dancers Defeated - {props.piratesKilled}/500</div>
                <div> Astrafite Mined - {props.astrafiteBlocksMined}/1000</div>
                <img alt={""} className={"cycle-progress-hover-triangle"} src={hover_triangle}/>
              </div>
            </div>

          </div>
        </div>
        <div className={"leader-table-element-description"}>
          <div className={"last-element-in-row"}>{props.time}</div>
        </div>
      </div>
    </div>
  </>
}
