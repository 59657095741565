import './GenesisNFTs.scss';
import HeaderComponent from '../components/Header/HeaderComponent';
import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer/Footer';
import FighterLady from '../components/Footer/FooterElements/FighterLady/FighterLady';
import PageHeader from '../components/PageHeader';

import { walletContext } from '../utils/WalletContext';
import { IsOnCorrectChain } from '../components/SwitchNetworkChain';
import LoadingSpinner from '../components/LoadingSpinner';
import { useNetwork } from 'wagmi';
import VaultDepositAstrafer from '../components/Vault/VaultDepositAstrafer';
import { Col, Container, Row } from 'react-bootstrap';
import VaultWithdrawAstrafer from '../components/Vault/VaultWithdrawAstrafer';
import { useProvider } from '../hooks/useProvider';

export default function Currency() {
  const provider = useProvider();

  const [isCorrectChain, setIsCorrectChain] = useState(undefined);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadingAccount, setIsLoadingAccount] = useState();

  const network = useNetwork();

  // get the players NFTs
  useEffect(() => {
    const getData = async () => {
      if (!provider) {
        setIsLoaded(true);
      }

      if (provider && walletContext.signed && !isLoadingAccount) {
        setIsLoadingAccount(true);
        try {
          await getIsOnCorrectChain();
        } catch (err) {
          console.error(err);
          setIsLoaded(true);
        }
      }
    };
    getData();
  });

  const getIsOnCorrectChain = async () => {
    const isCorrectChain = IsOnCorrectChain(network);
    setIsCorrectChain(isCorrectChain);

    setIsLoaded(true);
  };

  return (
    <>
      <HeaderComponent noBackground={true} />

      <PageHeader headerText="CURRENCY" classname="page-mobile-header-text-no-margin" />
      <Container>
        {walletContext.signed && !isLoaded && <LoadingSpinner />}

        {
          //walletContext.signed && !isCorrectChain && isLoaded && (
          // <SwitchNetworkChainSection network={network} message={`You must be on the ${process.env.REACT_APP_CHAINID} network to view currencies`} />)
        }

        {walletContext.signed && isCorrectChain && isLoaded && (
          <Row className={'gap-5 mb-5'}>
            <Col className={'d-flex justify-content-center align-items-start'}>
              <VaultWithdrawAstrafer />
            </Col>
            <Col className={'d-flex justify-content-center align-items-start'}>
              <VaultDepositAstrafer />
            </Col>
          </Row>
        )}
      </Container>

      <Footer>
        <FighterLady />
      </Footer>
    </>
  );
}
