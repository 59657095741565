// Global variables here

// QA

/*
Powerups all available at start
Infinite health (switchable)
5 gems collect mode
Difficulty mode switching (when done)
*/


//var ROTATION_SPEED = 0.5 * Phaser.Math.PI2; // 0.5 turn per sec, 2 sec per turn
export const WeaponType = {
    Laser: 0,
    FireBullet: 1,
    Missile: 2,
    SideBullet: 3
}

export const DifficultyLevel = {
    Easy: 0,
    Medium: 1,
    Hard: 2,
    Extreme: 3
}

export default class Global {

    static myInstance = null;

    BENS_TEST_VAR = "THIS IS WORKING!";

    // ANALYTICS
    SESSION_START_TIME = 0;
    SESSION_END_TIME = 0;
    SESSION_DURATION = 0;
    SESSION_WIN_LOSE = 0;

    SCREEN_CENTER_PORTRAIT_X = 540;
    SCREEN_CENTER_PORTRAIT_Y = 960;
    DEBUG_METEOR_IDS = false;
    //VelocityFromRotation = Phaser.Physics.Arcade.ArcadePhysics.prototype.velocityFromRotation;
    //RotateToAngle = Phaser.Math.Angle.RotateTo;

    SHOW_DIFFICULTY_MSG = false;
    SHOW_SHIP_FLAMES = true;
    SHOW_JET_PARTICLES = false;

    TOTAL_LOADING_PROGRESS = 0;
    EXPLODE_COOLDOWN_MILLIS = 100;
    MIN_TOKENS_BEFORE_POWERUPS = 0;
    TOKENS_REQUIRED_TO_WIN = 25;//25;//7;//25;//5;//25;//25;
    ALLOW_FULLSCREEN_MODE = true;

    // ONLY ONE OF THESE CAN BE TRUE
    START_WITH_SIDE_WEAPON = false;
    START_WITH_MISSILES = false;
    START_WITH_SCATTER = false;
    SHAKE_AMOUNT = 7;
    SHAKE_AMOUNT_PORTRAIT = 3;

    TITLE_IN_FRONT_OF_CRT = false;
    CURRENT_SCENE = null;
    TEST_HEALTH_WARNING = false;

    SHOW_INGAME_PROGRESS_BARS = true;

    CANVAS_WIDTH = 0;
    CANVAS_HEIGHT = 0;
    MUSIC_VOLUME = 0.7;
    USE_WIDESCREEN = false;
    LASER_BULLET_INTERVAL = 450; // milliseconds
    MANUAL_JOYSTICK_POSITION = false;
    MISSILES_ENABLED = false;
    SHOW_METEOR_PROGRESS_BARS = true;
    METEOR_BUFFER_SIZE = 30;
    METEOR_EXPLODE_BUFFER_SIZE = 5;
    METEOR_HIT_PROGRESS_BUFFER_SIZE = 10;
    HIDDEN_X = -99999;
    GOAL_COMPLETE_FLY_DOWN = false;
    
    _userID = "";
    
    /**
     * @returns {CommonDataManager}
     */
    static getInstance() {
        if (Global.myInstance == null) {
            Global.myInstance = new Global();
        }

        return this.myInstance;
    }

    getUserID() {
        return this._userID;
    }

    setUserID(id) {
        this._userID = id;
    }
}








