import React, {useState} from "react";
import "./Episode.scss";
import { episode_dropdown_arrow, episode_dropup_arrow} from "../../../assets/images/NFT";


export default function Episode(props) {
  const [showEpisodeDetails, setShowEpisodeDetails] = useState(false);

  return <>
    {props.itIsAvailable === true ?
      showEpisodeDetails === false ?
        <div className={props.mobileDesign?"dropdown-button-container-mobile":"dropdown-button-container"}>
          <div className={"dropdown-button-content"} onClick={() => {
            setShowEpisodeDetails(true)
          }}>
            <div className={"dropdown-button-text"}>
              <p className={"episode-name"}>{props.descriptionTitle}</p>
              <img alt={""} className={"episode-dropdown-icon"} src={episode_dropdown_arrow}/>
            </div>
            <div className={props.mobileDesign?"episode-img-container-mobile":"episode-img-container"}>
              <img alt={""} className={"episode-description-img"} src={props.imgUsed}/>
            </div>
          </div>
        </div>
        : <>
          <div className={"dropdown-button-container-on"}>
            <div className={props.mobileDesign?"dropdown-button-container-on-content-mobile":"dropdown-button-container-on-content"} onClick={() => {
              setShowEpisodeDetails(false)
            }}>
              <div className={props.mobileDesign?"selected-episode-design-mobile":"selected-episode-design"}>
                <div className={props.mobileDesign?"selected-dropdown-button-text-mobile":"selected-dropdown-button-text"}>
                  <p className={"episode-name"}>{props.fullTitle}</p>
                  <img alt={""} className={"episode-dropdown-icon"} src={episode_dropup_arrow}/>
                </div>
                {props.showLineSeparator?
                <div className={"line-separator"}>
                  <div className={"line-separator-design"}></div>
                </div>:<></>}
                <div className={props.mobileDesign?"":"selected-episode-img-design"}>
                  <img alt={""} className={props.showLineSeparator?"episode-description-img-set-img-border":"episode-description-img show-img-border"} src={props.imgUsed}/>
                </div>
              </div>
            </div>
            <div className={props.mobileDesign?"selected-episode-text-design-mobile":"selected-episode-text-design"}>
              {props.showEpisodeDetails}
            </div>

          </div>
        </> : <>
        <div className={props.mobileDesign?"dropdown-button-container-mobile":"dropdown-button-container"}>
          <div className={"dropdown-button-content disable-cursor"} >
            <div className={"dropdown-button-text"}>
              <p className={"missing-episode-name"}>{props.descriptionTitle}</p>
            </div>
            <div className={props.mobileDesign?"episode-img-container-mobile":"episode-img-container"}>
              <div className={"missing-episode-text"}> Coming Soon</div>
              <img alt={""} className={"episode-description-img made-transparent"} src={props.imgUsed}/>
            </div>
          </div>
        </div>
      </>}
  </>
}
