import './PlanetExplorationContent.scss';
import PageHeader from '../../PageHeader';
import React, { useEffect, useState } from 'react';
import PlanetExplorationTimer from '../PlanetExplorationTimer/PlanetExplorationTimer';
import PlanetExplorationMissionList from '../PlanetExplorationMissionList/PlanetExplorationMissionList';
import { explorationSelectInfo } from '../../../utils/GeneralUtils';
import PlanetExplorationMissionTask from '../PlanetExplorationMissionTask/PlanetExplorationMissionTask';
import PlanetExplorationCheckInList from '../PlanetExplorationCheckInList/PlanetExplorationCheckInList';
import ExplorationRewardsCell from '../../ExplorationSelection/ExplorationRewardsCell/ExplorationRewardsCell';
import ExplorationRewardsAstrafer
  from '../../ExplorationSelection/ExplorationRewardsAstrafer/ExplorationRewardsAstrafer';
import ExplorationRewardsPlanet from '../../ExplorationSelection/ExplorationRewardsPlanet/ExplorationRewardsPlanet';
import AbortMissionPopup from '../../AbortMissionPopup/AbortMissionPopup';
import PlayOnBehalfMissions from '../PlayOnBehalfMissions/PlayOnBehalfMissions';
import InfoDropdown from '../../InfoDropdown/InfoDropdown';
import { walletContext } from '../../../utils/WalletContext';
import {
  getMissionEntries,
  getMissionEntry,
  updateCheckpoint,
  updateMissionStatusByEntryId
} from '../../../utils/ApiCalls';
import { useFetchMissionEntries } from '../../../hooks/useFetchMissionEntries';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../../LoadingSpinner';

export const MISSION_STATUS = {
  ACTIVE: 'ACTIVE',
  COMPLETED: 'COMPLETED',
  ABORTED: 'ABORTED',
};

export const CHECKPOINT_STATUS = {
  LOCKED: 'LOCKED',
  AVAILABLE: 'AVAILABLE',
  COMPLETED: 'COMPLETED',
  MISSED: 'MISSED',
};

export const MISSION_TAB_DISPLAY_TEXT = {
  IN_PROGRESS: 'GAME IN PROGRESS',
  AVAILABLE: 'CHECK IN AVAILABLE',
  UNAVAILABLE: 'CHECK IN COMPLETE',
  REWARDS_AVAILABLE: 'REWARDS AVAILABLE',
  COMPLETE: 'COMPLETE',
};

export const MissionStatusIndex = {
  [MISSION_TAB_DISPLAY_TEXT.IN_PROGRESS]: 0,
  [MISSION_TAB_DISPLAY_TEXT.REWARDS_AVAILABLE]: 1,
  [MISSION_TAB_DISPLAY_TEXT.AVAILABLE]: 2,
  [MISSION_TAB_DISPLAY_TEXT.UNAVAILABLE]: 3,
  [MISSION_TAB_DISPLAY_TEXT.COMPLETE]: 4,
};

export default function PlanetExplorationContent({ mission, status }) {
  const [activeMissionEntry, setActiveMissionEntry] = useState({});
  const [missionStatus, setMissionStatus] = useState(status);
  const [abortMissionPopupVisibility, setAbortMissionPopupVisibility] = useState(false);

  const [isPlayOnBehalf, setIsPlayOnBehalf] = useState(false);
  const [hasOverlay, setHasOverlay] = useState(false);
  const [isOwner, setIsOwner] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [delegatedWallet, setDelegatedWallet] = useState('');
  const [largePlanet, setLargePlanet] = useState(0);
  const [mediumPlanet, setMediumPlanet] = useState(0);
  const [smallPlanet, setSmallPlanet] = useState(0);
  const [largeAsteroid, setLargeAsteroid] = useState(0);
  const [smallAsteroid, setSmallAsteroid] = useState(0);
  const [totalAstraferReward, setTotalAstraferReward] = useState(0);

  const location = useLocation();
  const navigate = useNavigate();

  const { missionEntries, setMissionEntries, isMissionEntriesLoading, missionEntriesLoadError } = useFetchMissionEntries(
    mission,
    walletContext.currentWallet,
    location.state?.loadedMissionEntries,
  );

  useEffect(() => {
    let selectedMissionEntryId;
    // We determine which mission tab to display as selected mission
    if (location.state?.launchedMissionId) {
      // When a user launches a mission, they are navigated to the /view-missions page. Let's show the selected mission as the newly launched mission.
      selectedMissionEntryId = location.state?.launchedMissionId;
      sessionStorage.setItem('current-mission-entry', location.state?.launchedMissionId);
    } else if (location.state?.selectedMissionId) {
      // The user can select the mission entry from the TCQ landing page (/portfolio/tcq-recon)
      selectedMissionEntryId = location.state?.selectedMissionId;
      sessionStorage.setItem('current-mission-entry', location.state?.selectedMissionId);
    } else {
      // If there are no other options, we display what is stored in session storage
      selectedMissionEntryId = sessionStorage.getItem(JSON.stringify('current-mission-entry'));
    }

    let selectedMissionEntry;

    if (selectedMissionEntryId) {
      selectedMissionEntry = missionEntries?.find(entry => entry.id === selectedMissionEntryId);
    }

    setActiveMissionEntry(selectedMissionEntry ?? missionEntries?.[0]);
    setMissionStatus(activeMissionEntry?.status);
  }, [mission, missionEntries, location, activeMissionEntry?.status]);

  useEffect(() => {
    const setBehalfWalletAddress = () => {
      const currentWallet = walletContext.currentWallet;
      let delegatedWalletAddress;

      if (activeMissionEntry?.delegatedWallets && activeMissionEntry?.delegatedWallets.length > 0) {
        delegatedWalletAddress = activeMissionEntry?.delegatedWallets?.[0]?.walletAddress;
      }

      if (!currentWallet || !activeMissionEntry || !activeMissionEntry?.walletAddress) {
        return;
      }

      if (!delegatedWalletAddress) {
        setIsOwner(true);
        setHasOverlay(false);
        setIsLoading(false);
        setIsPlayOnBehalf(false);
        return;
      }

      const isOwner = currentWallet.toLowerCase() !== delegatedWalletAddress.toLowerCase();

      setIsOwner(isOwner);

      setHasOverlay(true);
      setIsPlayOnBehalf(true);
      setDelegatedWallet(delegatedWalletAddress);
      setIsLoading(false);
    };

    const setPlanetAmounts = () => {
      const planets = activeMissionEntry?.rewards?.planetRewards;
      if (!planets) {
        return;
      }
      const planetTokenIds = {
        smallAsteroid: 200,
        largeAsteroid: 201,
        smallPlanet: 202,
        mediumPlanet: 203,
        largePlanet: 204,
      };

      const planetSizeQuantities = {
        [planetTokenIds.largePlanet]: 0,
        [planetTokenIds.mediumPlanet]: 0,
        [planetTokenIds.smallPlanet]: 0,
        [planetTokenIds.largeAsteroid]: 0,
        [planetTokenIds.smallAsteroid]: 0,
      };

      planets.forEach(planet => {
        const tokenId = planet.tokenId;
        if (planetSizeQuantities.hasOwnProperty(tokenId)) {
          planetSizeQuantities[tokenId] = planet.quantity;
        }
      });

      setLargePlanet(planetSizeQuantities[planetTokenIds.largePlanet]);
      setMediumPlanet(planetSizeQuantities[planetTokenIds.mediumPlanet]);
      setSmallPlanet(planetSizeQuantities[planetTokenIds.smallPlanet]);
      setLargeAsteroid(planetSizeQuantities[planetTokenIds.largeAsteroid]);
      setSmallAsteroid(planetSizeQuantities[planetTokenIds.smallAsteroid]);
      setTotalAstraferReward(activeMissionEntry?.rewards?.astraferRewards?.astraferEarned);
    };

    setBehalfWalletAddress();
    setPlanetAmounts();
  }, [activeMissionEntry, mission?.checkpointCount, mission.validTo, missionEntries]);


  const handleActiveMissionEntry = value => {
    setActiveMissionEntry(value);
  };

  const openAbortPopup = () => {
    setAbortMissionPopupVisibility(true);
  };

  const handleConfirmAbortMission = async () => {
    const walletAddress = walletContext.currentWallet;
    const { id: entryId, missionId } = activeMissionEntry;
    const newStatus = MISSION_STATUS.ABORTED;

    try {
      const currentEntry = await getMissionEntry(missionId, entryId);
      const isValid = currentEntry.status === MISSION_STATUS.ACTIVE && currentEntry.walletAddress.toLowerCase() === walletAddress.toLowerCase();

      if (isValid) {
        const updateResponse = await updateMissionStatusByEntryId(entryId, missionId, newStatus);

        if (updateResponse) {
          setAbortMissionPopupVisibility(false);
          setMissionStatus(MISSION_STATUS.ABORTED);
          // remove the mission entry from the list
          const updatedMissionEntries = missionEntries.filter(entry => entry.id !== entryId);
          setMissionEntries(updatedMissionEntries);
          navigate('/portfolio/tcq-recon');
        } else {
          // Handle error when the updateResponse is not 204
          console.error('An unknown error occurred');
          setAbortMissionPopupVisibility(false);
        }
      } else {
        // Handle case when the mission is not active or the wallet is not valid
        console.error('An unknown error occurred');
        setAbortMissionPopupVisibility(false);
      }
    } catch (error) {
      // Handle error when there is an issue with fetching or updating the mission entry
      console.error('An unknown error occurred');
      setAbortMissionPopupVisibility(false);
    }
  };
  const handleCancelAbortMission = () => {
    setAbortMissionPopupVisibility(false);
  };

  const handleUpdateCheckpoint = async (missionId, missionEntryId, checkpointId, isCheckinCompleted) => {
    try {
      await updateCheckpoint(missionId, missionEntryId, checkpointId, isCheckinCompleted);
      const updatedMissionEntries = await getMissionEntries(missionId);
      const updatedActiveMissionEntry = updatedMissionEntries.find(missionEntry => missionEntry.id === activeMissionEntry.id);
      setMissionEntries(updatedMissionEntries);
      setActiveMissionEntry(updatedActiveMissionEntry);
      setTotalAstraferReward(activeMissionEntry?.rewards?.astraferRewards?.astraferEarned);
    } catch (error) {
      console.error('An error occurred while updating the checkpoint:', error.message);
    }
  };

  return (
    <>
      {abortMissionPopupVisibility && <AbortMissionPopup handleAbort={handleCancelAbortMission} handleConfirm={handleConfirmAbortMission} />}
      <div className={'planet-exploration-main-container'}>
        <div className={'planets-mission-title-container'}>
          <PageHeader headerText={'RECON - MISSIONS'} />
        </div>
        {missionEntriesLoadError ? (
          <div
            style={{
              marginBottom: '40px',
              width: '500px',
              textAlign: 'center',
            }}
          >
            {missionEntriesLoadError}
          </div>
        ) : isMissionEntriesLoading ? (
          <div className={'planet-exploration-missions-main-container loading-spinner'}>
            <LoadingSpinner marginBottom={'0'} />
          </div>
        ) : (
          <>
            <PlanetExplorationMissionList
              missionEntries={missionEntries}
              activeMissionEntry={activeMissionEntry}
              handleActiveMission={handleActiveMissionEntry}
              hasOverlay={hasOverlay}
              hasTimer={isOwner && missionStatus !== MISSION_STATUS.COMPLETED}
              handleCheckpoint={handleUpdateCheckpoint}
              isOwner={isOwner}
              isPlayOnBehalf={isPlayOnBehalf}
            />

            <PlanetExplorationMissionTask missionStatus={missionStatus} />
            <PlanetExplorationCheckInList checkInList={activeMissionEntry?.checkpoints} />

            <>
              <div className={'planet-exploration-mission-progress-title'}>MISSION PROGRESS</div>
              <ExplorationRewardsCell cellTitle={'ASTRAFER Haul:'} cellData={totalAstraferReward} cellColor={'pink'} cellSize={'small'} />
              <div className={'exploration-rewards-elements-container'}>
                <ExplorationRewardsAstrafer
                  minGain={activeMissionEntry?.rewards?.astraferRewards?.minGain}
                  maxGain={activeMissionEntry?.rewards?.astraferRewards?.maxGain}
                  daysDuration={activeMissionEntry?.rewards?.daysDuration}
                  checkpoints={activeMissionEntry?.rewards?.totalCheckpoints}
                  astraferPerCheckpoint={activeMissionEntry?.rewards?.astraferRewards?.astraferPerCheckpoint}
                />
                <ExplorationRewardsPlanet
                  largePlanet={largePlanet}
                  mediumPlanet={mediumPlanet}
                  smallPlanet={smallPlanet}
                  largeAsteroid={largeAsteroid}
                  smallAsteroid={smallAsteroid}
                />
              </div>
              <div className={'planet-exploration-mission-timer-container'}>
                <PlanetExplorationTimer description={'Current Mission ends in:'} nextRewardTime={new Date(activeMissionEntry?.end)} />
              </div>
              {isOwner && !isLoading && (
                <div className={'planet-exploration-mission-progress-button-border'}>
                  <button className={'planet-exploration-mission-progress-button'} onClick={() => openAbortPopup()}>
                    ABORT MISSION
                  </button>
                </div>
              )}
              {isPlayOnBehalf && (
                <PlayOnBehalfMissions walletAddress={isOwner ? delegatedWallet : activeMissionEntry?.walletAddress} isOwner={isOwner} />
              )}
            </>
          </>
        )}
        <div className={'exploration-info-container'}>
          <div className={'exploration-info-title'}>
            <PageHeader headerText={'INFO'} />
          </div>
          {explorationSelectInfo.map((item, index) => (
            <InfoDropdown key={index} infoTitle={item.title} infoDescription={item.description} />
          ))}
        </div>
      </div>
    </>
  );
}
