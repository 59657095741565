import React, {useState} from 'react';

const PlanetSaleContext = React.createContext({});

function PlanetSaleProvider(props) {

  const [mintModalShow, setMintModalShow] = useState(false);
  const [mintInformativeModalShow, setMintInformativeModalShow] = useState(false);
  const [planetPurchaseModalShow, setPlanetPurchaseModalShow] = useState(false);

  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [planetToMint, setPlanetToMint] = useState("");
  const [numberOfNFTToMin, setNumberOfNFTToMin] = useState(0);
  const [NFTCost, setNFTCost] = useState(0);
  const [nftId, setNFTId] = useState("");
  const [sellType, setSellType] = useState("");
  const [ticket, setTicket] = useState(0);
  const [V, setV] = useState("");
  const [R, setR] = useState("");
  const [S, setS] = useState("");
  const [indexOfNFT, setIndexOfNFT] = useState(0);

  const [largePlanetAllowedToMint, setLargePlanetAllowedToMint] = useState(0);
  const [mediumPlanetAllowedToMint, setMediumPlanetAllowedToMint] = useState(0);
  const [smallPlanetAllowedToMint, setSmallPlanetAllowedToMint] = useState(0);
  const [largeAsteroidAllowedToMint, setLargeAsteroidAllowedToMint] = useState(0);
  const [smallAsteroidAllowedToMint, setSmallAsteroidAllowedToMint] = useState(0);

  const [nrTicketA, setNrTicketA] = useState(0);
  const [nrTicketB, setNrTicketB] = useState(0);

  const [saleConfigurations, setSaleConfigurations] = useState({
    PresaleA: [],
    PresaleB: [],
    PublicSale: [],
    PrivatePresale: []
  })

  const [totalMinted, setTotalMinted] = useState({
    100: 300,
    101: 300,
    102: 417,
    103: 30,
    104: 3
  })

  const [totalSupply, setTotalSupply] = useState({
    100: 7000,
    101: 3000,
    102: 417,
    103: 30,
    104: 3
  })

  const setTotalSupplyForPlanetId = (tokenId, tokenTotalSupply) => {
    let tempTotalSupply = totalSupply;

    tempTotalSupply[tokenId] = tokenTotalSupply;

    setTotalSupply({
      ...tempTotalSupply
    });
  }

  const setTotalMintedForPlanetId = (tokenId, tokenTotalMinted) => {
    let tempTotalMinted = totalMinted;

    tempTotalMinted[tokenId] = tokenTotalMinted;

    setTotalMinted({
      ...tempTotalMinted
    });
  }


  const afterMintDecreaseNFT = (index, sellType) => {
    const numberOfNfts = saleConfigurations[sellType][index].noNfts;
    const allowedToMint = saleConfigurations[sellType][index].allowedNoNfts;
    if(sellType==="PresaleA"){
      setNrTicketA(allowedToMint-numberOfNfts);
    }
    if(sellType==="PresaleB"){
      setNrTicketB(allowedToMint-numberOfNfts);
    }
    setSaleConfigurations(data => {
      data[sellType][index].noNfts = 0;
      data[sellType][index].allowedNoNfts = allowedToMint-numberOfNfts;
      return {...data};
    });
  }

  const increaseSaleNftNo = (index, counterSwitch, sellType) => {
    const numberOfNfts = saleConfigurations[sellType][index].noNfts + counterSwitch;
    setSaleConfigurations(data => {
      data[sellType][index].noNfts = numberOfNfts;
      return {...data};
    });
  }

  const getMintModalShow = () => {
    return mintModalShow;
  }

  const setMintModalShowValue = (modalShow) => {
    return setMintModalShow(modalShow);
  }

  return (
    <PlanetSaleContext.Provider
      value={{
        getMintModalShow,
        setMintModalShowValue,
        largePlanetAllowedToMint,
        setLargePlanetAllowedToMint,
        mediumPlanetAllowedToMint,
        setMediumPlanetAllowedToMint,
        smallPlanetAllowedToMint,
        setSmallPlanetAllowedToMint,
        largeAsteroidAllowedToMint,
        setLargeAsteroidAllowedToMint,
        smallAsteroidAllowedToMint,
        setSmallAsteroidAllowedToMint,
        planetToMint,
        setPlanetToMint,
        numberOfNFTToMin,
        setNumberOfNFTToMin,
        NFTCost,
        setNFTCost,
        totalSupply,
        setTotalSupply,
        setTotalSupplyForPlanetId,
        totalMinted,
        setTotalMinted,
        setTotalMintedForPlanetId,
        nftId,
        setNFTId,
        mintInformativeModalShow,
        setMintInformativeModalShow,
        planetPurchaseModalShow,
        setPlanetPurchaseModalShow,
        selectedCurrency,
        setSelectedCurrency,
        sellType,
        setSellType,
        increaseSaleNftNo,
        saleConfigurations, setSaleConfigurations,
        ticket,setTicket,V,setV,R,setR,S,setS,
        afterMintDecreaseNFT,
        setIndexOfNFT,
        indexOfNFT,
        nrTicketA,
        setNrTicketA,
        nrTicketB,
        setNrTicketB,
      }}
    >
      {props.children}
    </PlanetSaleContext.Provider>
  )
}

export {PlanetSaleContext, PlanetSaleProvider};
