import './PlanetExplorationTimer.scss'
import React from "react";
import {boarder_detail_top} from "../../../assets/images/AstrafiteRushEvent";
import PrivateSaleCountdown
  from "../../../routes/PublicPlanetSale/components/PrivateSaleCountdown/PrivateSaleCountdown";

export default function PlanetExplorationTimer({description, nextRewardTime}) {

  return <>
    <div className={'planet-exploration-timer-counter-main-container'}>
      <div className={'planet-exploration-timer-counter-container'}>
        <div className={'top-decoration'}>
          <img alt={""} className={"top-crown-decoration"} src={boarder_detail_top}/>
          <div className={"line-separator"}></div>
        </div>
        <p className={'next-claim-text'}> {description} </p>
        <PrivateSaleCountdown targetTime={nextRewardTime}/>
      </div>
    </div>
  </>

}
