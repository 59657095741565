import './PlanetsFilter.scss'
import PlanetsFilterButton from "../PlanetsFilterButton/PlanetsFilterButton";
import { planetSizes } from "../../../utils/GeneralUtils";
import { useState } from "react";

export default function PlanetsFilter({filterCallback}) {
  const [categories, setCategories] = useState([])

  const onPlanetFilterButtonClick = (selectedPlanetSize) => {
    let updatedCategories;
    if (categories.includes(selectedPlanetSize)) {
      updatedCategories = categories.filter(category => category !== selectedPlanetSize);
    } else {
      updatedCategories = [...categories, selectedPlanetSize];
    }
    setCategories(updatedCategories);
    filterCallback(updatedCategories);
  };

  return <div className={'planers-filter-container'}>
    <div className={'planets-filter-buttons-container'}>
      <PlanetsFilterButton filterCallback={() => onPlanetFilterButtonClick(planetSizes.smallAsteroid)} title={'SMALL ASTEROID'}/>
      <PlanetsFilterButton filterCallback={() => onPlanetFilterButtonClick(planetSizes.largeAsteroid)} title={'LARGE ASTEROID'}/>
      <PlanetsFilterButton filterCallback={() => onPlanetFilterButtonClick(planetSizes.smallPlanet)} title={'SMALL PLANET'}/>
      <PlanetsFilterButton filterCallback={() => onPlanetFilterButtonClick(planetSizes.mediumPlanet)} title={'MEDIUM PLANET'}/>
      <PlanetsFilterButton filterCallback={() => onPlanetFilterButtonClick(planetSizes.largePlanet)} title={'LARGE PLANET'}/>
    </div>
  </div>
}
