import {Col, Container, Modal, Row} from "react-bootstrap";
import ModalCloseButton from "../../ModalCloseButton/ModalCloseButton";
import {currency_bottom_horn, currency_header_horn} from "../../../../assets/images/PlanetSalePagePrivate";
import ModalButton from "../../ModalButton/ModalButton";
import React from "react";
import PlanetPurchaseTCsContent from "./PlanetPurchaseTCsContent";
import "./PlanetPurchaseTCsModal.scss";

export default function PlanetPurchaseTCsModal(props) {
  return (
    <Modal
      {...props}
      // scrollable
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName='planet-purchase-tcs-modal-container'>
      <ModalCloseButton clickEvent={props.onHide}/>
      <div className={"planet-purchase-tcs-principle-container"}>
        {/*TODO: Get better horns images for larger scale*/}
        <img className={"planet-purchase-tcs-header-horns"} src={currency_header_horn} alt=""/>
        <div className={"planet-purchase-tcs-header"}>
          Please read the Terms and Conditions:
        </div>
          <Container fluid>
            <Row>
              <PlanetPurchaseTCsContent />
            </Row>
            <Row className={'planet-purchase-tcs-buttons-row'}>
              <Col>
                <ModalButton buttonText={"CANCEL"} onClick={props.onHide}/>
              </Col>
              <Col>
                <ModalButton buttonText={"I AGREE"} onClick={() => props.setTermsConditionsAccepted(true)}/>
              </Col>
            </Row>
          </Container>
          <img className={"planet-purchase-tcs-bottom-horns"} src={currency_bottom_horn} alt=""/>
        </div>
    </Modal>
  )
}
