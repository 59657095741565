import AudioManager from './AudioManager.js';
import Global,{DifficultyLevel, WeaponType} from './Global.js';
import Meteor, {MeteorType} from './Meteor.js';
import Player from './Player.js';

import Phaser from 'phaser';

var TOTAL_LOADING_PROGRESS_LOCAL = 0;
export default class Main extends Phaser.Scene
{
  constructor() {
    super({ key: 'Main'});
  }
    preload()
    {
        this.globals = Global.getInstance();
        this.audioManager = AudioManager.getInstance();
        TOTAL_LOADING_PROGRESS_LOCAL = 0.0;
        currentGameState = GameState.Init;
        this.prevGameStateBeforePause = currentGameState;
        this.load.image('loadingProgressBar', '/img/loading_progress_bar.png');
        this.load.image('loadingProgressBg', '/img/loading_progress_bg.png');
        this.load.image('loadingText', '/img/loading_text.png');

        loadFont("oswald", "Oswald-Light.ttf");
    }

    preloadProgress ()
    {

         // virtual dpad / joystick
        var rexJoystickUrl = '/rexjoystick.js';
        this.load.plugin('rexvirtualjoystickplugin', rexJoystickUrl, true);
        this.load.image('base', '/img/base.png');
        this.load.image('thumb', '/img/thumb.png');

        // ************ AUDIO / MP3 ASSETS ************

        this.load.audio('music2', ['/sound/game_music.mp3']);
        this.load.audio('menuMusic', ['/sound/menu_music.mp3']);
        this.load.audio('soundShoot', ['/sound/weapon_main.mp3']);
        this.load.audio('soundStingerVictory', ['/sound/stinger_victory.mp3']);
        this.load.audio('soundStingerDefeat', ['/sound/stinger_defeat.mp3']);
        this.load.audio('soundFireShoot', ['/sound/weapon_scatter.mp3']);
        this.load.audio('soundShipExplode', ['/sound/player_destroyed.mp3']);
        this.load.audio('soundStartGame', ['/sound/ui_start_game.mp3']);
        this.load.audio('soundExplode', ['/sound/meteor_explode.mp3']);
        this.load.audio('soundAsteroidHit', ['/sound/asteroid_hit.mp3']);
        this.load.audio('soundAmbience', ['/sound/ambience.mp3']);
        this.load.audio('soundWarning', ['/sound/warning_beep.mp3']);
        this.load.audio('soundWeaponMissile', ['/sound/weapon_missile.mp3']);
        this.load.audio('soundWeaponSide', ['/sound/weapon_side.mp3']);
        this.load.audio('soundPlayerDamage', ['/sound/player_damage.mp3']);
        this.load.audio('soundCollectToken', ['/sound/pickup_shard.mp3']);
        this.load.audio('soundCashOut', ['/sound/cash_out.mp3']);
        this.load.audio('soundPickupUpgrade', ['/sound/pickup_upgrade.mp3']);
        this.load.audio('buttonClick', ['/sound/ui_select.mp3']);

        // ************ UI IMAGE ASSETS ************

        //this.load.atlas('space', '/img/space.png', '/img/space.json');
        this.load.image('smoke', '/img/smoke.png'); //'/img/smoke_blue.png');
        this.load.image('titleBg', '/img/title_portrait2.png');
        this.load.image('titleBgLandscape', '/img/title_landscape2.png');

        //this.load.image('pauseButton', '/img/pause_button.png');
        this.load.spritesheet('pauseButtonFrames', '/img/pause_button_frames.png', { frameWidth: 141, frameHeight: 144 });
        this.load.image('pauseMenuBackground', '/img/pause_menu_background.png');
        this.load.image('pauseMenuQuit', '/img/pause_menu_quit_button.png');
        this.load.image('pauseMenuResume', '/img/pause_menu_resume_button.png');

        this.load.image('clickToStart', '/img/click_to_start.png');
        this.load.image('scanlines', '/img/overlay_screen_portrait4.png');
        this.load.image('scanlinesWidescreen', '/img/overlay_screen_landscape_nosides4.png');
        this.load.image('uiRedLight', '/img/red_flash_three.png');
        this.load.image('hudRadar', '/img/radar.png');
        //this.load.image('titleBg', '/img/title_portrait.png');
        //this.load.image('titleBgLandscape', '/img/title_landscape2.png');
        this.load.image('starfield', '/img/starfield7.png');
        this.load.image('starfield_foreground', '/img/starfield_foreground5.png'); // '/img/portal.png');

        this.load.spritesheet('soundOnOfFrames', '/img/sound_on_off_button_frames.png', { frameWidth: 141, frameHeight: 144 });
        //this.load.spritesheet('soundOnOfFrames', '/img/sound_on_off_button_frames.png', { frameWidth: 256, frameHeight: 256 });
        this.load.image('collectProgressBarBg', '/img/collect_empty.png');
        this.load.image('collectProgressBar', '/img/collect_full.png');

        this.load.spritesheet('shipFlamesFrames', '/img/ship_flames.png', { frameWidth: 272, frameHeight: 56 });

        this.load.image('healthProgressBarBg', '/img/health_progress_bg.png');
        this.load.image('healthProgressBar', '/img/health_progress.png');
        this.load.image('healthProgressBarRed', '/img/health_progress_red.png');
        //this.load.spritesheet('powerbar', '/img/powerbar_frames_better.png', { frameWidth: 288, frameHeight: 37 });

        this.load.image('healthProgressBarOLD', '/img/health_bar_red6.png');
        this.load.image('tokenCollect', '/img/collect_token.png');
        this.load.image('astraferBalance', '/img/astrafer_balance.png');
        this.load.image('gameOverText', '/img/GameOver_h.png');
        this.load.image('gemIconSmall', '/img/gem_small.png');

        this.load.image('goalCompleteText', '/img/goal_complete_text.png');

        // FULL SCREEN COLOR FILLS
        this.load.image('blackOverlay', '/img/black_overlay.png');
        this.load.image('redFill', '/img/red_fill.png');
        this.load.image('transparentImage', '/img/transparent.png');

        // POPUP PANELS
        this.load.image('howToPlayPanelPortrait', '/img/MissionGoal_V.png');
        this.load.image('howToPlayPanelLandscape', '/img/MissionGoal_H.png');
        //this.load.image('howToPlayPanelLandscape', '/img/GameComplete_H.png');
        this.load.image('goalCompletePanelPortrait', '/img/GameComplete_V.png');
        this.load.image('goalCompletePanelLandscape', '/img/GameComplete_H.png');
        this.load.spritesheet('claimButtonFrames', '/img/claim_button3.png', { frameWidth: 358, frameHeight: 158 });
        this.load.spritesheet('playButtonFrames', '/img/play_button_v2.png', { frameWidth: 200, frameHeight: 100 });
        this.load.spritesheet('replayButtonFrames', '/img/replay_button_frames.png', { frameWidth: 397, frameHeight: 158 });

        // ************ INGAME IMAGE ASSETS ************

        // WEAPON / BULLET SPRITES
        this.load.image('laser_bullet', '/img/laser_double_bullet.png');
        this.load.image('fire_bullet', '/img/fire_shot.png');
        this.load.image('sideBullet', '/img/side_bullet.png');
        this.load.spritesheet('missileFrames', '/img/missile_frames_4.png', { frameWidth: 245, frameHeight: 92 });

        // POWERUP PROGRESS ICONS
        this.load.spritesheet('progressRing', '/img/powerup_progress_ring4.png', { frameWidth: 256, frameHeight: 256 });
        this.load.image('iconScatter', '/img/icon_scatter.png');
        this.load.image('iconMissile', '/img/icon_missile.png');
        this.load.image('iconSideBullet', '/img/icon_side_bullet3.png');

        this.load.spritesheet('ship', '/img/ship_shadow_frames3.png', { frameWidth: 512, frameHeight: 512 });
        this.load.spritesheet('meteor_spinning_1', '/img/asteroid_1.png', { frameWidth: 256, frameHeight: 256 });
        this.load.spritesheet('meteor_spinning_2', '/img/asteroid_2.png', { frameWidth: 256, frameHeight: 256 });

        this.load.spritesheet('collect_spinning', '/img/collect_frames3.png', { frameWidth: 256, frameHeight: 256 });

        this.load.spritesheet('powerUpCellFramesYellow', '/img/canister_yellow.png', { frameWidth: 256, frameHeight: 256 });
        this.load.spritesheet('powerUpCellFramesRed', '/img/canister_red.png', { frameWidth: 256, frameHeight: 256 });
        this.load.spritesheet('powerUpCellFramesBlue', '/img/canister_green.png', { frameWidth: 256, frameHeight: 256 });
        this.load.spritesheet('asteroid_explode', '/img/asteroid_explode.png', { frameWidth: 256, frameHeight: 256 });
        this.load.spritesheet('meteorHitProgressFrames', '/img/meteor_progress_bar_frames.png', { frameWidth: 187, frameHeight: 24 });

        // RADAR
        this.load.image('radarDot', '/img/blue_dot.png');
        this.load.image('gemDot', '/img/yellow_dot.png');
        this.load.image('radarShip', '/img/ship_radar.png');


        this.load.on('progress', function (value) {
            TOTAL_LOADING_PROGRESS_LOCAL = value;
        });


        this.load.start();

        // NORMAL MAPS WORK WELL WITH LIGHTS!!!
        //this.load.image('brick', [ 'assets/normal-maps/brick.jpg', 'assets/normal-maps/brick_n.png' ]);
        //this.load.image('sonic', 'assets/sprites/sonic_havok_sanity.png');
    }

    create()
    {
        var canvas = this.sys.game.canvas;
        var width = window.innerWidth;
        var height = window.innerHeight;
        var wratio = width / height;
        var canvasRatio = canvas.width / canvas.height;
        if(canvasRatio < 1)
        {
            // portrait mode
            this.globals.USE_WIDESCREEN = false;
        }
        else
        {
            this.globals.USE_WIDESCREEN = true;
        }

        this.globals.CANVAS_WIDTH = this.sys.game.canvas.width;
        this.globals.CANVAS_HEIGHT = this.sys.game.canvas.height;

        if(this.globals.USE_WIDESCREEN)
        {
            this.cameras.main.setZoom(1.69);
        }
        else
        {
            this.cameras.main.setZoom(0.85);
            this.globals.SHAKE_AMOUNT = this.globals.SHAKE_AMOUNT_PORTRAIT;
        }

        if(this.globals.USE_WIDESCREEN)
        {
            this.loadingProgressBg = this.add.existing(new Phaser.GameObjects.Sprite(this, this.globals.CANVAS_WIDTH / 2,this.globals.CANVAS_HEIGHT / 2 + 50,"loadingProgressBg"));
            this.loadingProgressBg.setScale(0.52);
            this.loadingProgressBg.setScrollFactor(0);

        }
        else
        {
            this.loadingProgressBg = this.add.existing(new Phaser.GameObjects.Sprite(this, this.globals.CANVAS_WIDTH / 2 ,this.globals.CANVAS_HEIGHT / 2 + 50,"loadingProgressBg"));
            this.loadingProgressBg.setScale(0.32);
            this.loadingProgressBg.setScrollFactor(0);
        }

        this.loadingProgressBar = this.add.existing(new Phaser.GameObjects.Sprite(this, this.loadingProgressBg.x,this.loadingProgressBg.y,"loadingProgressBar"));
        this.loadingProgressBar.setScale(this.loadingProgressBg.scale);
        this.loadingProgressBar.setScrollFactor(0);

        this.loadingGameText = this.add.existing(new Phaser.GameObjects.Sprite(this, 127,650,"loadingText"));
        if(this.globals.USE_WIDESCREEN)
        {
            this.loadingGameText.scale = 0.8;
            this.loadingGameText.x = this.globals.CANVAS_WIDTH / 2;
            this.loadingGameText.y = this.globals.CANVAS_HEIGHT / 2;

        }
        else
        {
            this.loadingGameText.scale = 0.6;
            this.loadingGameText.x = this.globals.CANVAS_WIDTH / 2;
            this.loadingGameText.y = this.globals.CANVAS_HEIGHT / 2;

        }

        this.preloadProgress();
    }


    async queryServerOnStartup()
    {
        // THIS VALUE NEEDS TO COME FROM SERVER / CALCULATE FROM SERVER DATA
        this.setDifficultyLevel(DifficultyLevel.Hard);

    }

    setDifficultyLevel(newDifficultyLevel)
    {
        this.currentDifficulty = newDifficultyLevel;
        if(this.currentDifficulty == DifficultyLevel.Easy)
        {
            this.globals.TOKENS_REQUIRED_TO_WIN = 10;
            this.player.body.maxSpeed = 250;
            this.goalToCollectText.text = "COLLECT:" + this.globals.TOKENS_REQUIRED_TO_WIN;
            this.tokensToEarn = this.globals.TOKENS_REQUIRED_TO_WIN;
            //this.currentDifficultyMenuText.text = "Difficulty: EASY";
        }
        else if(this.currentDifficulty == DifficultyLevel.Medium)
        {
            this.globals.TOKENS_REQUIRED_TO_WIN = 15;
            this.player.body.maxSpeed = 275;
            this.goalToCollectText.text = "COLLECT:" + this.globals.TOKENS_REQUIRED_TO_WIN;
            this.tokensToEarn = this.globals.TOKENS_REQUIRED_TO_WIN;
            //this.currentDifficultyMenuText.text = "Difficulty: MEDIUM";
        }
        else if(this.currentDifficulty == DifficultyLevel.Hard)
        {
            this.globals.TOKENS_REQUIRED_TO_WIN = 20;
            this.player.body.maxSpeed = 285;
            this.goalToCollectText.text = "COLLECT:" + this.globals.TOKENS_REQUIRED_TO_WIN;
            this.tokensToEarn = this.globals.TOKENS_REQUIRED_TO_WIN;
            //this.currentDifficultyMenuText.text = "Difficulty: HARD";
        }
        else if(this.currentDifficulty == DifficultyLevel.Extreme)
        {
            this.globals.TOKENS_REQUIRED_TO_WIN = 25;
            this.player.body.maxSpeed = 300;
            this.goalToCollectText.text = "COLLECT:" + this.globals.TOKENS_REQUIRED_TO_WIN;
            this.tokensToEarn = this.globals.TOKENS_REQUIRED_TO_WIN;
            //this.currentDifficultyMenuText.text = "Difficulty: EXTREME";

        }
    }
    createGame ()
    {
        //this.createInit();
        this.loadingProgressBar.visible = false;
        this.loadingProgressBg.visible = false;
        this.sound.pauseOnBlur = false;
        this.soundLaserShoot = this.sound.add('soundShoot', { loop: false });
        this.soundLaserShoot.volume = 0.8;
        this.soundStingerVictory = this.sound.add('soundStingerVictory', { loop: false });
        this.soundMusic = this.sound.add('music2', { loop: true });
        //this.soundMusic.volume = 1;
        this.soundMenuMusic = this.sound.add('menuMusic', { loop: true });
        this.soundMenuMusic.volume = 0.8;
        //this.soundMenuMusic.volume = 1;
        this.soundButtonClick = this.sound.add('buttonClick', { loop: false });
        //this.soundFireShoot = this.sound.add('soundShoot', { loop: false, detune: 1200 });
        this.soundStingerDefeat = this.sound.add('soundStingerDefeat', { loop: false });
        this.soundFireShoot = this.sound.add('soundShoot', { loop: false});
        this.soundPlayerDamage = this.sound.add('soundPlayerDamage', { loop: false });
       // this.soundFireShoot.setDetune(800); // detune: -1200 to 1200
        //this.soundFireShoot.pitch = 34.5;
        this.soundExplode = this.sound.add('soundExplode', { loop: false, volume: 1 });
        this.soundStartGame = this.sound.add('soundStartGame', { loop: false, volume: 1 });
        this.soundWarning = this.sound.add('soundWarning', { loop: false });
        this.soundCollectToken = this.sound.add('soundCollectToken', { loop: false });
        this.soundCashOut = this.sound.add('soundCashOut', { loop: false });
        this.soundPickupUpgrade = this.sound.add('soundPickupUpgrade', { loop: false });
        this.soundWeaponMissile = this.sound.add('soundWeaponMissile', { loop: false });
        this.soundWeaponSide = this.sound.add('soundWeaponSide', { loop: false });
        this.soundShipExplode = this.sound.add('soundShipExplode', { loop: false });
        this.soundAmbience = this.sound.add('soundAmbience', { loop: true });

        this.soundExplode.overlap = true;
        this.soundCollectToken.overlap = true;
        this.soundAmbience.volume = 0.75;
        this.soundMenuMusic.volume = this.globals.MUSIC_VOLUME;
        this.audioManager.soundEnabled = true;
        this.mouseClicksEnabled = true;
        this.healthWarningTtimer = 0;
        this.healthWarningDelay = 1000;
        this.tokensCollected = 0;
        this.starField = this.add.tileSprite(540, 960, 1080, 1920, 'starfield').setScrollFactor(0, 0);
        //this.starField.setPipeline('Light2D');

        this.starFieldForeground = this.add.tileSprite(540, 960, 1080, 1920, 'starfield_foreground').setScrollFactor(0, 0);
       // this.starFieldForeground.setPipeline('Light2D');

        if(this.globals.USE_WIDESCREEN)
        {
            this.starField.x = this.globals.CANVAS_WIDTH / 2;
            this.starField.setScale(1.1);
            this.starFieldForeground.x = this.globals.CANVAS_WIDTH / 2;
            this.starFieldForeground.setScale(1.1);
        }
        this.powerUpDurationTimer = 0;
        this.powerUpDurationDelay = 10000;

        this.missileTarget = null;

        this.currentAstraferBalance = 10000;
        //this.initTokensToEarn = 1337;
        this.tokensToEarn = this.globals.TOKENS_REQUIRED_TO_WIN;
        this.tokensEarnt = 0;
        this.collectChunkAmt = 1;//Math.floor(this.tokensToEarn / 20);
        this.collectRewardTimer = 0;
        this.collectRewardDelay = 180;

        this.frameCounter = 0;
        this.meteorBuffer = [];
        this.meteorRadarDotBuffer = [];
        this.gemRadarDotBuffer = [];
        this.tokenCollectBuffer = [];
        //this.powerUpsBuffer = [];

        this.powerUpsBufferRed = [];
        this.powerUpsBufferYellow = [];
        this.powerUpsBufferBlue = [];

        this.meteorDebugTexts = [];
        this.meteorBlastBuffer = [];

        this.laserBulletBuffer = [];
        this.fireBulletBuffer = [];
        this.sideBulletBuffer = [];
        this.meteorExplodeBuffer = [];
        this.meteorProgressBuffer = [];

        this.clickToStartFlashTimer = 0;
        this.clickToStartFlashDelay = 1200;
        this.missileFired = false;
        this.START_SPEED = 100;//1100;
        this.MISSILE_MAX_SPEED = 800;
        this.MISSILE_ACCELERATION = 20;
        this.START_ROTATION_SPEED = 0.1 * Phaser.Math.PI2;
        this.MISSILE_SPEED = 800;//800;
        this.ROTATION_SPEED = 0.1 * Phaser.Math.PI2; // 0.5 turn per sec, 2 sec per turn

        this.tokenCollectBufferSize = 20;

        this.currentLaserBulletBufferIndex = 0;
        this.currentMeteorBlastBufferIndex = 0;
        this.currentTokenCollectBufferIndex = 0;
        this.currentFireBulletBufferIndex = 0;
        this.currenSideBulletBufferIndex = 0;
        this.currentMeteorExplodeBufferIndex = 0;
        //this.currentPowerUpBufferIndex = 0;
        this.currentPowerUpYellowBufferIndex = 0;
        this.currentPowerUpRedBufferIndex = 0;
        this.currentPowerUpBlueBufferIndex = 0;

        this.laserBulletTimer = 0.0;
        this.laserBulletDelay = this.globals.LASER_BULLET_INTERVAL;
        this.fireBulletTimer = 0;
        this.fireBulletDelay = 450;//300;
        this.sideBulletTimer = 0;
        this.sideBulletDelay = 300;
        this.autoShooting = false;

        this.flameTimer = 0;
        this.currentFlameDelay = 1000; // milliseconds
        this.flamesExtending = true;
        this.flameExtensionMaxScale = 0.85;
        this.flameExtensionMinScale = 0.35;
        //this.shipDirectionMarker = this.add.existing(new Phaser.GameObjects.Sprite(this, HIDDEN_X,HIDDEN_X,));
        this.shipDirectionMarker = this.add.existing(new Phaser.GameObjects.Sprite(this, 0,0,"thumb"));
        this.shipDirectionMarker.visible = false;

        //this.maxTokensCollect = this.globals.TOKENS_REQUIRED_TO_WIN;

        // PORTAL / MARKER OBJECT FOR FLYING OFF SCREEN / ROTATING TO FACE NORTH or SOUTH
        this.portalImage = this.add.existing(new Phaser.GameObjects.Sprite(this, 500,500 ,"transparentImage"));
        this.portalImage.setPosition(this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2 - 120);
        this.portalImage.alpha = 0.9;
        this.portalImage.setScale(0.75);
        this.portalImage.visible = false;

        for(var i=0; i < 2; i++)
        {
            this.laserBulletBuffer[i] = this.add.existing(new Phaser.GameObjects.Sprite(this, this.globals.HIDDEN_X,this.globals.HIDDEN_X,"laser_bullet"));
            this.physics.add.existing(this.laserBulletBuffer[i]);
            this.laserBulletBuffer[i].setVisible(false);
            this.laserBulletBuffer[i].name = "bullet_" + i;
            this.laserBulletBuffer[i].setScale(0.4);
            this.laserBulletBuffer[i].body.onCollide = true;
            this.laserBulletBuffer[i].body.setSize(this.laserBulletBuffer[i].width * 0.5, this.laserBulletBuffer[i].height * 0.7, true);

        }

        for(var i=0; i < 10; i++)
        {
            this.fireBulletBuffer[i] = this.add.existing(new Phaser.GameObjects.Sprite(this, this.globals.HIDDEN_X,this.globals.HIDDEN_X,"fire_bullet"));
            this.physics.add.existing(this.fireBulletBuffer[i]);
            this.fireBulletBuffer[i].setVisible(false);
            this.fireBulletBuffer[i].name = "fire_bullet_" + i;
            this.fireBulletBuffer[i].setScale(0.4);
            this.fireBulletBuffer[i].body.onCollide = true;
        }

        for(var i=0; i < 10; i++)
        {
            this.sideBulletBuffer[i] = this.add.existing(new Phaser.GameObjects.Sprite(this, this.globals.HIDDEN_X,this.globals.HIDDEN_X,"sideBullet"));
            this.physics.add.existing(this.sideBulletBuffer[i]);
            this.sideBulletBuffer[i].setVisible(false);
            this.sideBulletBuffer[i].name = "side_bullet" + i;
            this.sideBulletBuffer[i].setScale(0.1);
            this.sideBulletBuffer[i].body.onCollide = true;
        }
        if(this.globals.SHOW_SHIP_FLAMES)
        {
            this.shipFlames = this.add.existing(new Phaser.GameObjects.Sprite(this, this.globals.SCREEN_CENTER_PORTRAIT_X,this.globals.SCREEN_CENTER_PORTRAIT_Y + 100 ,"shipFlamesFrames"));

            this.shipFlames.anims.create({
                   key: "flames",
                   frameRate: 30,
                   frames: this.anims.generateFrameNumbers("shipFlamesFrames", { start: 0, end: 3 }),
                   repeat: -1
               });

            this.shipFlames.setScale(0.7);
            this.shipFlames.setFrame(0);
            this.shipFlames.alpha = 0.75;
            this.shipFlames.anims.play('flames');
        }


        // ADD THE METEOR BLAST PARTICLES
        for(var i=0; i < 40; i++)
        {
            //var meteorType = Phaser.Math.FloatBetween(1,3);
            var meteorScale = 1;//Phaser.Math.FloatBetween(0.2,0.6);

            var theMeteorParticle = this.add.existing(new Phaser.GameObjects.Sprite(this, this.globals.HIDDEN_X,this.globals.HIDDEN_X ,"meteor_spinning_1"));
            this.meteorBlastBuffer[i] = theMeteorParticle;
            this.physics.add.existing(this.meteorBlastBuffer[i]);
            this.meteorBlastBuffer[i].setScale(meteorScale);
            this.meteorBlastBuffer[i].visible = false;
            this.meteorBlastBuffer[i].alpha = 0.5;
            this.meteorBlastBuffer[i].name = "explode_" + i;
            var meteorStartFramerate = Phaser.Math.Between(9,12);

            theMeteorParticle.anims.create({
                key: "spin",
                frameRate: meteorStartFramerate,
                frames: this.anims.generateFrameNumbers("meteor_spinning_1", { start: 0, end: 58 }),
                repeat: -1
            });
            var meteorStartFrame = Phaser.Math.Between(1,15);
            theMeteorParticle.setFrame(meteorStartFrame);
            theMeteorParticle.setScale(0.3);
            theMeteorParticle.anims.play('spin');
        }

        this.missileMaster = this.add.existing(new Phaser.GameObjects.Sprite(this, 500,500 ,"missileFrames"));
        this.physics.add.existing(this.missileMaster);
        this.missileMaster.setFrame(0);
        this.missileMaster.setScale(0.53);
        this.missileMaster.body.onCollide = true;
        this.missileMaster.setVisible(false);

        // ADD THE METEORS
        for(var i=0; i < this.globals.METEOR_BUFFER_SIZE; i++)
        {
            var xPos = i * Phaser.Math.FloatBetween(0, 1000);
            var yPos = i * Phaser.Math.FloatBetween(0, 1000);

            var meteorType = Phaser.Math.Between(0,2);

            var newMeteor = addMeteor(this, xPos, yPos, "meteor_spinning_1", meteorType);
            //newMeteor.body.checkCollision.none;
            newMeteor.body.setVelocity (0,0);
            newMeteor.x = this.globals.HIDDEN_X;
            newMeteor.y = this.globals.HIDDEN_X;
            newMeteor.visible = false;
            this.meteorDebugTexts[i] = this.add.text(this.globals.HIDDEN_X, this.globals.HIDDEN_X);
            this.meteorDebugTexts[i].text = "meteor_" + i;
            this.meteorBuffer[i] = newMeteor;
            this.meteorBuffer[i].name = "meteor_" + i;


            // Add the meteor progress bars for damage

            var theMeteorHitProgressBar = this.add.existing(new Phaser.GameObjects.Sprite(this, this.globals.HIDDEN_X,this.globals.HIDDEN_X ,"meteorHitProgressFrames"));
            theMeteorHitProgressBar.setScale(0.55);
            this.meteorProgressBuffer[i] = theMeteorHitProgressBar;
            this.meteorProgressBuffer[i].setFrame(0);

            if(this.globals.SHOW_METEOR_PROGRESS_BARS)
            {
                newMeteor.addProgressBar(theMeteorHitProgressBar);
            }


            for(var c = 0; c < this.laserBulletBuffer.length; c++)
            {
                // add collider between every bullet and every meteor
                this.physics.add.overlap(this.meteorBuffer[i],this.laserBulletBuffer[c], (meteor, bullet) =>
                {
                    if(meteor.visible && bullet.visible)
                    {
                        // hit meteor
                        meteor.onHit(this,WeaponType.Laser);
                        bullet.visible = false;
                        bullet.body.checkCollision.none = true;
                        bullet.x = this.globals.HIDDEN_X;
                        bullet.y = this.globals.HIDDEN_X;

                        if(meteor.getHitCount() == 0)
                        {
                            meteor.body.checkCollision.none = true;
                            this.explodeMeteor(meteor.x, meteor.y, true, meteor.scale);
                            meteor.x = this.globals.HIDDEN_X;
                            bullet.x = this.globals.HIDDEN_X;
                        }
                        else
                        {
                            this.audioManager.playSoundOverlap(this, 'soundAsteroidHit',1);
                            //this.sound.play('soundAsteroidHit', { volume: 1 });

                        }
                    }
                });

            }

            for(var c = 0; c < this.fireBulletBuffer.length; c++)
            {
                // add collider between every bullet and every meteor
                this.physics.add.overlap(this.meteorBuffer[i],this.fireBulletBuffer[c], (meteor, bullet) =>
                {
                    if(meteor.visible && bullet.visible)
                    {
                        meteor.onHit(this, WeaponType.FireBullet);
                        bullet.visible = false;
                        bullet.body.checkCollision.none = true;
                        bullet.x = this.globals.HIDDEN_X;
                        bullet.y = this.globals.HIDDEN_X;

                        if(meteor.getHitCount() == 0)
                        {
                            meteor.body.checkCollision.none = true;
                            this.explodeMeteor(meteor.x, meteor.y, true, meteor.scale);
                            meteor.x = this.globals.HIDDEN_X;
                            bullet.x = this.globals.HIDDEN_X;
                        }
                        else
                        {
                            this.audioManager.playSoundOverlap(this, 'soundAsteroidHit',1);
                            //this.sound.play('soundAsteroidHit', { volume: 1 });
                        }
                    }
                });

            }

            for(var c = 0; c < this.sideBulletBuffer.length; c++)
            {
                // add collider between every bullet and every meteor
                this.physics.add.overlap(this.meteorBuffer[i],this.sideBulletBuffer[c], (meteor, bullet) =>
                {
                    if(meteor.visible && bullet.visible)
                    {
                        meteor.onHit(this, WeaponType.SideBullet);
                        bullet.visible = false;
                        bullet.body.checkCollision.none = true;
                        bullet.x = this.globals.HIDDEN_X;
                        bullet.y = this.globals.HIDDEN_X;

                        if(meteor.getHitCount() == 0)
                        {
                            meteor.body.checkCollision.none = true;
                            this.explodeMeteor(meteor.x, meteor.y, true, meteor.scale);
                            meteor.x = this.globals.HIDDEN_X;
                            bullet.x = this.globals.HIDDEN_X;
                        }
                        else
                        {
                            this.audioManager.playSoundOverlap(this, 'soundAsteroidHit',1);
                            //this.sound.play('soundAsteroidHit', { volume: 1 });
                        }
                    }
                });

            }
        }




       /*
            alpha: { start: 1, end: 0 },
            tint: 0x00ff00,
            scale: { start: 0.5, end: 1.5 },
            speed: {random: [20, 100] },
            accelerationY:  {random: [-100, 200] },
            rotate: { min: -180, max: 180 },
            lifespan: { min: 300, max: 800 },
            frequency: 120,
            maxParticles: 10,
            x: p.x + 100,
            y: p.y

       */
        if(this.globals.SHOW_JET_PARTICLES)
        {
            /*
            this.emitter = this.add.particles(0, 0, 'smoke', {
                lifespan: 400,
                alpha: { start: 0.3, end: 0 },
                scale: { start: 0.1, end: 1.2 },
                speed: 5,
                frequency: 10,
                pivot: { x:5,y:-400},
                // maxParticles: 10,
                blendMode: 'ADD'
            });
            */

            this.emitter = this.add.particles(0, 0, 'smoke', {
                lifespan: 200,
                alpha: { start: 0.2, end: 0 },
                scale: { start: 0.1, end: 1.2 },
                speed: 15,
                frequency: 10,
                pivot: { x:5,y:-400},
                // maxParticles: 10,
                blendMode: 'ADD'
            });
        }


        this.player = new Player(this, this.globals.SCREEN_CENTER_PORTRAIT_X, this.globals.SCREEN_CENTER_PORTRAIT_Y, 'ship');
        this.add.existing(this.player);
        this.physics.add.existing(this.player);
        this.player.setScale(0.22);//(0.39);//(0.34);
        this.player.setFrame(0);

        this.player.configure();
        this.player.body.setSize(240, 240, true);

        if(this.globals.SHOW_JET_PARTICLES)
        {
            this.emitter.stop();
            this.emitter.startFollow(this.player);
        }

        //this.emitter.followOffset.x = 0;
        //this.emitter.followOffset.y = 40;
        //this.emitter.setDepth(this.player.depth);


        // ADD THE EXPLOSION ANIMATIONS FOR METEORS
        for(var i=0; i < this.globals.METEOR_EXPLODE_BUFFER_SIZE; i++)
        {
            var theMeteorExplodeParticle = this.add.existing(new Phaser.GameObjects.Sprite(this, this.globals.HIDDEN_X,this.globals.HIDDEN_X ,"asteroid_explode"));
            theMeteorExplodeParticle.setScale(1.75);
            this.meteorExplodeBuffer[i] = theMeteorExplodeParticle;
            theMeteorExplodeParticle.anims.create({
                key: "blast",
                frameRate: 90,
                frames: this.anims.generateFrameNumbers("asteroid_explode", { start: 0, end: 63 }),
                repeat: 0
            });
        }

        // multi bullet powerup
         for(var i=0; i < 1; i++)
         {
             var powerUpObj = this.add.existing(new Phaser.GameObjects.Sprite(this, 500,500 ,"powerUpCellFramesYellow"));

             powerUpObj.x = this.globals.HIDDEN_X;
             powerUpObj.y = this.globals.HIDDEN_X;
             this.physics.add.existing(powerUpObj);
             powerUpObj.body.setSize(180, 180, true);
             powerUpObj.setScale(0.54);
             powerUpObj.setFrame(0);
             powerUpObj.body.setVelocity (0,0);
             powerUpObj.body.checkCollision.none = false;

             powerUpObj.anims.create({
                key: "pupspin",
                frameRate: 30,
                frames: this.anims.generateFrameNumbers("powerUpCellFramesYellow", { start: 0, end: 58 }),
                repeat: -1
            });

            powerUpObj.setFrame(0);
            powerUpObj.anims.play('pupspin');
             this.powerUpsBufferYellow[i] = powerUpObj;

         }






         // missiles powerup
         for(var i=0; i < 1; i++)
         {
             var powerUpObj = this.add.existing(new Phaser.GameObjects.Sprite(this, 500,500 ,"powerUpCellFramesRed"));

             powerUpObj.x = this.globals.HIDDEN_X;
             powerUpObj.y = this.globals.HIDDEN_X;
             this.physics.add.existing(powerUpObj);
             powerUpObj.body.setSize(180, 180, true);
             powerUpObj.setScale(0.54);
             powerUpObj.setFrame(0);
             powerUpObj.body.setVelocity (0,0);
             powerUpObj.body.checkCollision.none = false;

             powerUpObj.anims.create({
                key: "pupspin",
                frameRate: 30,
                frames: this.anims.generateFrameNumbers("powerUpCellFramesRed", { start: 0, end: 58 }),
                repeat: -1
            });

            powerUpObj.setFrame(0);
            powerUpObj.anims.play('pupspin');
             this.powerUpsBufferRed[i] = powerUpObj;

         }

         // side bullet powerup
         for(var i=0; i < 1; i++)
         {
             var powerUpObj = this.add.existing(new Phaser.GameObjects.Sprite(this, 500,500 ,"powerUpCellFramesBlue"));

             powerUpObj.x = this.globals.HIDDEN_X;
             powerUpObj.y = this.globals.HIDDEN_X;
             this.physics.add.existing(powerUpObj);
             powerUpObj.body.setSize(180, 180, true);
             powerUpObj.setScale(0.54);
             powerUpObj.setFrame(0);
             powerUpObj.body.setVelocity (0,0);
             powerUpObj.body.checkCollision.none = false;

             powerUpObj.anims.create({
                key: "pupspin",
                frameRate: 30,
                frames: this.anims.generateFrameNumbers("powerUpCellFramesBlue", { start: 0, end: 58 }),
                repeat: -1
            });

            powerUpObj.setFrame(0);
            powerUpObj.anims.play('pupspin');
             this.powerUpsBufferBlue[i] = powerUpObj;
         }



         // ADD the collectible tokens
         for(var i=0; i < this.tokenCollectBufferSize; i++)
         {
             var tokenCollectObj = this.add.existing(new Phaser.GameObjects.Sprite(this, 500,500 ,"collect_spinning"));

             tokenCollectObj.anims.create({
                key: "spin",
                frameRate: 30,
                frames: this.anims.generateFrameNumbers("collect_spinning", { start: 0, end: 58 }),
                repeat: -1
            });

            tokenCollectObj.anims.play('spin');
            tokenCollectObj.x = this.globals.HIDDEN_X;
            tokenCollectObj.y = this.globals.HIDDEN_X;
            this.physics.add.existing(tokenCollectObj);
            tokenCollectObj.body.setSize(180, 180, true);
            var collectScale = Phaser.Math.FloatBetween(0.4,0.5);
            tokenCollectObj.setScale(collectScale);
            var startFrame = Phaser.Math.Between(0,58);
            var angleAdjust = Phaser.Math.Between(-20,20);
            tokenCollectObj.angle = tokenCollectObj.angle + angleAdjust;
            tokenCollectObj.setFrame(startFrame);
            tokenCollectObj.body.setVelocity (0,0);
            tokenCollectObj.body.checkCollision.none = false;
            this.tokenCollectBuffer[i] = tokenCollectObj;
         }

         for(var i=0; i < this.tokenCollectBuffer.length; i++)
         {
            this.physics.add.overlap(this.tokenCollectBuffer[i], this.player, (token, player ) =>
            {
                if(currentGameState == GameState.GamePlay)
                {
                    token.body.checkCollision.none = true;
                    token.x = this.globals.HIDDEN_X;
                    token.y = this.globals.HIDDEN_X;
                    this.audioManager.playSoundOverlap(this, 'soundCollectToken',1);
                    this.tokensCollected++;
                    this.updateCollectProgressBar();
                    this.floatCollectText();
                }
            });
         }

         for(var i=0; i < this.powerUpsBufferYellow.length; i++)
         {
            this.physics.add.overlap(this.powerUpsBufferYellow[i], this.player, (pup, player ) =>
            {
                if(currentGameState == GameState.GamePlay)
                {
                    pup.body.checkCollision.none = true;
                    pup.x = this.globals.HIDDEN_X;
                    pup.y = this.globals.HIDDEN_X;

                    this.audioManager.playSound(this.soundPickupUpgrade);
                    currentWeaponType = WeaponType.FireBullet;

                    this.hidePowerUpProgress();

                    // enable the scatter weapon
                    this.progressRing.visible = true;
                    this.iconScatter.visible = true;
                }
            });
         }

         for(var i=0; i < this.powerUpsBufferRed.length; i++)
         {
            this.physics.add.overlap(this.powerUpsBufferRed[i], this.player, (pup, player ) =>
            {
                if(currentGameState == GameState.GamePlay)
                {
                    pup.body.checkCollision.none = true;
                    pup.x = this.globals.HIDDEN_X;
                    pup.y = this.globals.HIDDEN_X;
                    this.audioManager.playSound(this.soundPickupUpgrade);
                    currentWeaponType = WeaponType.Missile;
                    this.hidePowerUpProgress();

                    // enable the missile weapon
                    this.iconMissile.visible = true;
                    this.progressRing.visible = true;
                }
            });
         }

         for(var i=0; i < this.powerUpsBufferBlue.length; i++)
         {
            this.physics.add.overlap(this.powerUpsBufferBlue[i], this.player, (pup, player ) =>
            {
                if(currentGameState == GameState.GamePlay)
                {
                    pup.body.checkCollision.none = true;
                    pup.x = this.globals.HIDDEN_X;
                    pup.y = this.globals.HIDDEN_X;
                    this.audioManager.playSound(this.soundPickupUpgrade);
                    currentWeaponType = WeaponType.SideBullet;
                    this.hidePowerUpProgress();

                    // enable the side bullet weapon
                    this.iconSideBullet.visible = true;
                    this.progressRing.visible = true;
                }
            });
         }

         // CHECK FOR PLAYER -> METEOR COLLISIONS
        for(var i=0; i < this.meteorBuffer.length; i++)
        {
            this.physics.add.overlap(this.meteorBuffer[i],this.player, (meteor, player) =>
            {
                if(currentGameState == GameState.GamePlay && (!this.redFill.visible))
                {
                    meteor.body.checkCollision.none = true;
                    this.healthCounter-=2;
                    if(this.globals.USE_WIDESCREEN)
                    {
                        this.shakeCamera();
                    }
                    else
                    {
                        this.shakeCamera();
                    }

                    this.redFill.visible = true;

                    if(this.healthCounter <= 0)
                    {
                        this.healthCounter = 0;

                        this.onGameOver();
                    }
                    this.updateHealthBar();
                    this.shipIsRed = true;
                    this.time.addEvent({ delay: 500, callback: this.resetShipColor, callbackScope: this, loop: false });
                    this.explodeMeteor(meteor.x, meteor.y, true, meteor.scale);
                    this.audioManager.playSound(this.soundPlayerDamage);
                    meteor.x = this.globals.HIDDEN_X;
                }
            });
        }


        this.cursorKeys = this.input.keyboard.createCursorKeys();

        this.cameras.main.startFollow(this.player);
        const colors = [
            0xffffff, 0xff0000, 0x00ff00, 0x00ffff, 0xff00ff, 0xffff00
        ];


        //this.lights.enable();
        //this.lights.setAmbientColor(0x808080);

        /*
        this.spotlight = this.lights.addLight(400, 300, 280).setIntensity(3);

        this.spotlight.x = this.player.x;
        this.spotlight.y = this.player.y;
        this.spotlight.setColor(colors[3]);
        */
        /*
        this.input.on('pointermove', pointer =>
        {

            spotlight.x = pointer.x;
            spotlight.y = pointer.y;

        });
        */


        let currentColor = 0;

        if(this.globals.MANUAL_JOYSTICK_POSITION)
        {
            this.input.on('pointerdown', pointer =>
            {
                this.autoShooting = false;
                this.joyStick.x = pointer.x;
                this.joyStick.y = pointer.y;
            });
        }
        else
        {
            this.input.on('pointerdown', pointer =>
            {
                // check if it overlaps the screen region?
                if(currentGameState == GameState.GamePlay)
                {
                    if(this.globals.USE_WIDESCREEN)
                    {
                        if(pointer.x < 2000)
                        {
                            this.showJoystick();
                        }
                    }
                    else
                    {
                        if(pointer.x < 470)
                        {
                            this.showJoystick();
                        }
                    }

                }


            });
        }


        this.joystickBase = this.add.existing(new Phaser.GameObjects.Sprite(this, 0,0,"base"));
        this.joystickThumb = this.add.existing(new Phaser.GameObjects.Sprite(this, 0,0,"thumb"));

        this.joystickThumb.alpha = 0.7;
        this.startHealthCounter = 16;
        this.healthCounter = this.startHealthCounter;
        if(this.globals.USE_WIDESCREEN)
        {
            this.joystickBase.setScale(0.8);
            this.joystickThumb.setScale(0.8);
        }
        else
        {
            this.joystickBase.setScale(0.8);
            this.joystickThumb.setScale(0.8);
        }
        //this.hudRadar.visible = false;
        //this.hudPowerBar = this.add.existing(new Phaser.GameObjects.Sprite(this, 230,0,"powerbar"));
        //this.hudPowerBar.setFrame(0);
        //this.hudPowerBar.alpha = 0.8;//0.75;//0.8;//0.7;
        //this.hudPowerBar.setScale(1.25);

        //    this.hudPowerBar.visible = true;

        this.hudHealthProgressBarBg = this.add.existing(new Phaser.GameObjects.Sprite(this, this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2,"healthProgressBarBg"));
        this.hudHealthProgressBarBg.setScale(0.6);
        this.hudHealthProgressBarBg.setScrollFactor(0);
        this.hudHealthProgressBarBg.visible = true;

        this.hudHealthProgressBar = this.add.existing(new Phaser.GameObjects.Sprite(this, this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2,"healthProgressBar"));
        this.hudHealthProgressBar.setScale(0.6);
        this.hudHealthProgressBar.setScrollFactor(0);
        this.hudHealthProgressBar.visible = true;
        this.hudHealthProgressBar.setCrop(0,0,340,60);

        if(this.globals.USE_WIDESCREEN)
        {
            this.hudHealthProgressBar.setScale(0.497);
            this.hudHealthProgressBarBg.setScale(0.497);
            this.hudHealthProgressBarBg.setPosition(this.globals.CANVAS_WIDTH / 2 - 38, this.globals.CANVAS_HEIGHT / 2 - 331);
            this.hudHealthProgressBar.x = this.hudHealthProgressBarBg.x;
            this.hudHealthProgressBar.y = this.hudHealthProgressBarBg.y;

        }
        else
        {
            this.hudHealthProgressBar.setScale(0.447);
            this.hudHealthProgressBarBg.setScale(0.447);
            this.hudHealthProgressBarBg.setPosition(this.globals.CANVAS_WIDTH / 2 - 27, this.globals.CANVAS_HEIGHT / 2 - 448);
            this.hudHealthProgressBar.x = this.hudHealthProgressBarBg.x;
            this.hudHealthProgressBar.y = this.hudHealthProgressBarBg.y;

        }

        this.hudHealthProgressBar.alpha = 0.8;
            this.hudHealthProgressBarBg.alpha = 0.8;

        this.hudCollectProgressBarBg = this.add.existing(new Phaser.GameObjects.Sprite(this, 265,17,"collectProgressBarBg"));
        this.hudCollectProgressBarBg.setScale(0.45);
        this.hudCollectProgressBarBg.setScrollFactor(0);
        this.hudCollectProgressBarBg.visible = true;
        this.hudCollectProgressBarBg.alpha = 0.8;

        this.hudCollectProgressBar = this.add.existing(new Phaser.GameObjects.Sprite(this, 265,17,"collectProgressBar"));
        this.hudCollectProgressBar.setScale(0.45);
        this.hudCollectProgressBar.setScrollFactor(0);
        this.hudCollectProgressBar.visible = true;
        this.hudCollectProgressBar.alpha = 0.8;


        if(this.globals.USE_WIDESCREEN)
        {
            var ingameProgressBarsAnchorXPos = this.globals.CANVAS_WIDTH / 2 - 27;
            var ingameProgressBarsAnchorYPos = this.globals.CANVAS_HEIGHT / 3.5 - 21;
            this.hudCollectProgressBar.setScale(0.5);
            this.hudCollectProgressBarBg.setScale(0.5);
            //this.hudPowerBar.setPosition(ingameProgressBarsAnchorXPos - 45, ingameProgressBarsAnchorYPos - 15);
            this.hudCollectProgressBarBg.setPosition(ingameProgressBarsAnchorXPos, ingameProgressBarsAnchorYPos);
            this.hudCollectProgressBar.setPosition(ingameProgressBarsAnchorXPos, ingameProgressBarsAnchorYPos);
        }
        else
        {
            var ingameProgressBarsAnchorXPos = this.globals.CANVAS_WIDTH / 2 - 18;
            var ingameProgressBarsAnchorYPos = this.globals.CANVAS_HEIGHT / 2 - 440;

            //this.hudPowerBar.setPosition(ingameProgressBarsAnchorXPos - 45, ingameProgressBarsAnchorYPos - 15);
            this.hudCollectProgressBarBg.setPosition(ingameProgressBarsAnchorXPos, ingameProgressBarsAnchorYPos);
            this.hudCollectProgressBar.setPosition(ingameProgressBarsAnchorXPos, ingameProgressBarsAnchorYPos);
        }
        this.hudCollectProgressBar.setCrop(0,0,300,100);

        this.progressRing = this.add.existing(new Phaser.GameObjects.Sprite(this, 60,750 ,"progressRing"));
        this.progressRing.setScrollFactor(0);
        this.progressRing.alpha = 0.8;
        this.progressRing.visible = true;

        this.iconScatter = this.add.existing(new Phaser.GameObjects.Sprite(this, 60,750 ,"iconScatter"));
        this.iconScatter.setScrollFactor(0);
        this.iconScatter.setScale(0.2);
        this.iconScatter.alpha = 0.8;
        this.iconScatter.visible = true;

        this.iconMissile = this.add.existing(new Phaser.GameObjects.Sprite(this, 60,750 ,"iconMissile"));
        this.iconMissile.setScrollFactor(0);
        this.iconMissile.setScale(0.2);
        this.iconMissile.alpha = 0.8;
        this.iconMissile.visible = true;

        this.iconSideBullet = this.add.existing(new Phaser.GameObjects.Sprite(this, 60,750 ,"iconSideBullet"));
        this.iconSideBullet.setScrollFactor(0);
        this.iconSideBullet.setScale(0.6);
        this.iconSideBullet.alpha = 0.8;
        this.iconSideBullet.visible = true;

        if(this.globals.USE_WIDESCREEN)
        {
            //var vialX = this.globals.CANVAS_WIDTH / 2 - 480;
            //var vialY = this.globals.CANVAS_HEIGHT / 2 + 170;
            var vialX = this.globals.CANVAS_WIDTH / 2 - 490;
            var vialY = this.globals.CANVAS_HEIGHT / 2 - 275;

            this.progressRing.setPosition(vialX, vialY);
            this.progressRing.setScale(0.5);
            this.iconScatter.x = this.progressRing.x;
            this.iconScatter.y = this.progressRing.y;
            this.iconScatter.setScale(0.5);
            this.iconMissile.x = this.progressRing.x;
            this.iconMissile.y = this.progressRing.y;
            this.iconMissile.setScale(0.5);
            this.iconSideBullet.x = this.progressRing.x;
            this.iconSideBullet.y = this.progressRing.y;
            this.iconSideBullet.setScale(1.3);
        }
        else
        {
            var vialX = 80;
            var vialY = 790;
            this.progressRing.setPosition(vialX, vialY);
            this.progressRing.setScale(0.4);
            this.iconScatter.x = this.progressRing.x;
            this.iconScatter.y = this.progressRing.y;
            this.iconScatter.setScale(0.4);
            this.iconScatter.visible = false;
            this.iconMissile.x = this.progressRing.x;
            this.iconMissile.y = this.progressRing.y;
            this.iconMissile.setScale(0.4);
            this.iconMissile.visible = false;
            this.iconSideBullet.x = this.progressRing.x;
            this.iconSideBullet.y = this.progressRing.y;
            this.iconSideBullet.setScale(1.3);
            this.iconSideBullet.visible = false;
        }

       // this.hudPowerBar.setScrollFactor(0);

       this.joyStick = this.plugins.get('rexvirtualjoystickplugin');

        this.joyStick = this.plugins.get('rexvirtualjoystickplugin').add(this, {
            x: 270,
            y: 800,
            radius: 80,
            base: this.joystickBase, //this.add.circle(0, 0, 100, 0x888888),
            thumb: this.joystickThumb, //this.add.circle(0, 0, 50, 0xcccccc),
            dir: '8dir',   // 'up&down'|0|'left&right'|1|'4dir'|2|'8dir'|3
            // forceMin: 16,
            enable: true
        });

        this.timeUntilNextGame = this.add.text (155, 650 , 'NEXT MISSION IN:\n     23h 59m 44s ', {fontSize: 24, fontStyle: 'bold', stroke: '#ff00ff', strokeThickness: 3, fontFamily: 'Arial', fill: '#ffffff'});
        this.timeUntilNextGame.setScrollFactor(0);
        this.timeUntilNextGame.visible = false;
        this.text = this.add.text(500, 500);
        if(!this.globals.DEBUG_METEOR_IDS)
        {
            this.text.visible = false;
        }

        this.updateJoystickState(0);

        this.events.on('postupdate', (time, delta) =>
        {
            this.onPostUpdate(time, delta);
        });

        // add red dots for mini map
        for(var i=0; i < this.globals.METEOR_BUFFER_SIZE; i++)
        {
            var theDot = this.add.existing(new Phaser.GameObjects.Sprite(this, this.globals.HIDDEN_X,this.globals.HIDDEN_X ,"radarDot"));

            this.meteorRadarDotBuffer[i] = theDot;
            this.meteorRadarDotBuffer[i].x = 500;
            this.meteorRadarDotBuffer[i].y = 500;
            this.meteorRadarDotBuffer[i].setScrollFactor(0);

            this.meteorRadarDotBuffer[i].alpha = 0.55;
            if(this.meteorBuffer[i].getMeteorType() == MeteorType.Large)
            {
                this.meteorRadarDotBuffer[i].setScale(0.07);
            }
            else if(this.meteorBuffer[i].getMeteorType() == MeteorType.Medium)
            {
                this.meteorRadarDotBuffer[i].setScale(0.055);
            }
            else
            {
                this.meteorRadarDotBuffer[i].setScale(0.04);
            }

        }

        for(var i=0; i < this.tokenCollectBuffer.length; i++)
        {
            var theDot = this.add.existing(new Phaser.GameObjects.Sprite(this, this.globals.HIDDEN_X,this.globals.HIDDEN_X ,"gemDot"));
            this.gemRadarDotBuffer[i] = theDot;
            this.gemRadarDotBuffer[i].x = 500;
            this.gemRadarDotBuffer[i].y = 500;
            this.gemRadarDotBuffer[i].setScrollFactor(0);
            this.gemRadarDotBuffer[i].setScale(0.05);
            this.gemRadarDotBuffer[i].alpha = 0.55;

        }


        //this.gameOverReplayButton = this.goalToCollectText = this.add.text (CANVAS_WIDTH / 2 - 69, CANVAS_HEIGHT / 2 , 'REPLAY', {fontSize: 56.3, fontFamily: 'oswald', fill: '#aabbbb'});
        this.gameOverReplayButton= this.add.existing(new Phaser.GameObjects.Sprite(this, this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2 + 55 ,"replayButtonFrames"));
        this.gameOverReplayButton.setScrollFactor(0);
        this.gameOverReplayButton.visible = false;
        this.gameOverReplayButton.setScale(0.45);

        if(this.globals.USE_WIDESCREEN)
        {
            this.gameOverReplayButton.setScale(0.35);
        }
        else
        {
            this.gameOverReplayButton.setScale(0.45);
        }

        this.gameOverReplayButton.setInteractive().on('pointerdown', function(pointer, localX, localY, event){
            // ...
            this.replayNewGame();
        }, this);

        this.gameOverText= this.add.existing(new Phaser.GameObjects.Sprite(this, 270,430,"gameOverText"));
        this.gameOverText.setScale(0.4);
        this.gameOverText.visible = false;
        this.gameOverText.setScrollFactor(0);

        if(this.globals.USE_WIDESCREEN)
        {
            this.gameOverText.setPosition(this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2 - 25);
            this.gameOverReplayButton.setPosition(this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2 + 55);
        }

        this.goalCompleteText = this.add.existing(new Phaser.GameObjects.Sprite(this, 270,430,"goalCompleteText"));
        this.goalCompleteText.setScale(0.22);
        this.goalCompleteText.alpha = 0.8;
        this.goalCompleteText.visible = false;
        this.goalCompleteText.setScrollFactor(0);

        this.goalCompleteStartX = this.globals.CANVAS_WIDTH / 2;
        this.goalCompleteStartY = this.globals.CANVAS_HEIGHT / 2 - 85;

        if(this.globals.GOAL_COMPLETE_FLY_DOWN)
        {
            this.goalCompleteStartY = this.globals.CANVAS_HEIGHT / 2 - 85;
        }
        else
        {
            this.goalCompleteStartY = this.globals.CANVAS_HEIGHT / 2 + 85;
        }

        this.goalCompleteText.setPosition(this.goalCompleteStartX, this.goalCompleteStartY);

        this.redFill = this.add.existing(new Phaser.GameObjects.Sprite(this, 270,500,"redFill"));
        this.redFill.setScrollFactor(0);
        this.redFill.setScale(2,4);
        this.redFill.alpha = 0.3;
        this.redFill.visible = false;

        if(this.globals.USE_WIDESCREEN)
        {
            this.redFill.x = this.globals.CANVAS_WIDTH / 2;
            this.redFill.y = this.globals.CANVAS_HEIGHT / 2;
            this.redFill.setScale(5,5);
        }

        if(this.globals.USE_WIDESCREEN)
        {
            this.goalCompletePanel = this.add.existing(new Phaser.GameObjects.Sprite(this, 270,437,"goalCompletePanelLandscape"));
            this.goalCompletePanel.setScrollFactor(0);
            this.goalCompletePanel.setScale(1.02);
            this.goalCompletePanel.visible = false;
            this.goalCompletePanel.setPosition(this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2 - 38);
            this.goalCompletePanel.setScale(0.8);
        }
        else
        {
            this.goalCompletePanel = this.add.existing(new Phaser.GameObjects.Sprite(this, 270,197,"goalCompletePanelPortrait"));
            this.goalCompletePanel.setScrollFactor(0);
            this.goalCompletePanel.setScale(1.02);
            this.goalCompletePanel.visible = false;
            this.goalCompletePanel.setPosition(this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2 - 78);
            this.goalCompletePanel.setScale(0.8);
        }

        if(this.globals.USE_WIDESCREEN)
        {
            this.howToPlayPanel = this.add.existing(new Phaser.GameObjects.Sprite(this, 270,437,"howToPlayPanelLandscape"));
            this.howToPlayPanel.setScrollFactor(0);
            this.howToPlayPanel.visible = false;
            this.howToPlayPanel.setPosition(this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2 - 38);
            this.howToPlayPanel.setScale(0.7);

        }
        else
        {
            this.howToPlayPanel = this.add.existing(new Phaser.GameObjects.Sprite(this, 270,437,"howToPlayPanelPortrait"));
            this.howToPlayPanel.setScrollFactor(0);
            this.howToPlayPanel.visible = false;
            this.howToPlayPanel.setPosition(this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2 - 38);
            this.howToPlayPanel.setScale(0.9);
        }

        // popup panels collect token animation

        this.popupMenuCollectToken = this.add.existing(new Phaser.GameObjects.Sprite(this, 500,500 ,"collect_spinning"));

        this.popupMenuCollectToken.anims.create({
               key: "spin",
               frameRate: 30,
               frames: this.anims.generateFrameNumbers("collect_spinning", { start: 0, end: 57 }),
               repeat: -1
           });
        this.popupMenuCollectToken.anims.play('spin');

        if(this.globals.USE_WIDESCREEN)
        {
            this.popupMenuCollectToken.setPosition(this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2 - 50);
        }
        else
        {
            this.popupMenuCollectToken.setPosition(this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2 - 30);
        }
        //this.popupMenuCollectToken.setPosition(this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2 - 50);
        this.popupMenuCollectToken.setScrollFactor(0);
        this.popupMenuCollectToken.setScale(0.8);

        this.popupMenuCollectToken.visible = false;

        /*


        */


        //this.goalToCollectText = this.add.text(100, 600, 'Test Text', {
        //    font: '300 50px Oswald' // 600 equivalent to Semi Bold
       //   })

        this.goalToCollectText = this.add.text (500, 449 , 'COLLECT: x' + this.globals.TOKENS_REQUIRED_TO_WIN, {fontSize: 34.3, fontFamily: 'oswald', fill: '#ffffff'});
        this.goalToCollectText.setOrigin(0.5);
        if(this.globals.USE_WIDESCREEN)
        {
            this.goalToCollectText.setPosition(this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2 + 70);
        }
        else
        {
            this.goalToCollectText.setPosition(this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2 + 75);
            this.goalToCollectText.setScale(0.9);
        }

        this.goalToCollectText.alpha = 0.85;
        this.goalToCollectText.setScrollFactor(0);
        this.goalToCollectText.visible = false;

        this.playButton = this.add.existing(new Phaser.GameObjects.Sprite(this, 265,620,"playButtonFrames"));
        this.playButton.setFrame(0);
        this.playButton.setScrollFactor(0);
        this.playButton.visible = false;
        this.playButton.setInteractive().on('pointerdown', function(pointer, localX, localY, event){
            // ...
            this.pressPlayButton();
        }, this);
        if(this.globals.USE_WIDESCREEN)
        {
            this.playButton.setScale(1.0);
            this.playButton.setPosition(this.globals.CANVAS_WIDTH / 2 + 5, this.globals.CANVAS_HEIGHT / 2 + 200);
        }
        else
        {
            this.playButton.setScale(1.0);
            this.playButton.setPosition(this.globals.CANVAS_WIDTH / 2 + 5, this.globals.CANVAS_HEIGHT / 2 + 135);
        }

        this.astraferBalanceCaption = this.add.existing(new Phaser.GameObjects.Sprite(this, 75,20,"astraferBalance"));
        //this.astraferBalanceCaption.setAlign('left');
        this.astraferBalanceCaption.setScrollFactor(0);
        this.astraferBalanceCaption.setScale(0.7);
        this.astraferBalanceCaption.visible = false;

        this.astraferBalanceText = this.add.text (65, 15 , '4364332', {fontSize: 20, fontFamily: 'Arial', fontStyle: 'bold', fill: '#ffffff'});
        this.astraferBalanceText.setScrollFactor(0);
        this.astraferBalanceText.visible = false;

        this.claimButton = this.add.existing(new Phaser.GameObjects.Sprite(this, 265,720,"claimButtonFrames"));
        this.claimButton.setFrame(0);
        this.claimButton.setScrollFactor(0);
        this.claimButton.visible = false;
        this.claimButton.setInteractive().on('pointerdown', function(pointer, localX, localY, event){
            // ...
            this.pressClaimButton();
        }, this);

        if(this.globals.USE_WIDESCREEN)
        {
            this.claimButton.setScale(0.4);
            this.claimButton.setPosition(this.globals.CANVAS_WIDTH / 2 + 5, this.globals.CANVAS_HEIGHT / 2 + 220);
        }
        else
        {
            this.claimButton.setScale(0.4);
            this.claimButton.setPosition(this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2 + 220);
        }

        this.claimAmountText = this.add.text (400, 449 , 'xx / xx', {fontSize: 34.3, fontFamily: 'oswald', fill: '#ffffff'});

        this.claimAmountText.setScrollFactor(0);
        this.claimAmountText.visible = false;


        this.missionCaptionText = this.add.text (this.globals.CANVAS_WIDTH / 2 + 11, 507 , 'Collect Hermesium Crystals to Exchange for ASTRAFER', {fontSize: 24, fontFamily: 'oswald', fill: '#c1a746'});
        this.missionCaptionText.setOrigin(0.5);
        this.missionCaptionText.setScrollFactor(0);
        this.missionCaptionText.alpha = 0.9;
        this.missionCaptionText.visible = false;

        if(this.globals.USE_WIDESCREEN)
        {
            this.missionCaptionText.setPosition(this.globals.CANVAS_WIDTH / 2 + 11,507);
        }
        else
        {
            this.missionCaptionText.setPosition(this.globals.CANVAS_WIDTH / 2,327);
        }
        this.claimCaptionText = this.add.text (895, 789 , 'Deliver Hermesium to Pointech to complete your Check-In', {fontSize: 24, fontFamily: 'oswald', fill: '#c1a746'});
        this.claimCaptionText.setOrigin(0.5);
        this.claimCaptionText.setScrollFactor(0);
        this.claimCaptionText.alpha = 0.9;
        this.claimCaptionText.visible = false;

        if(this.globals.USE_WIDESCREEN)
        {
            this.claimAmountText.setPosition(this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2 + 60);
            this.claimCaptionText.setPosition(this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2 + 115);
            this.claimAmountText.setOrigin(0.5);
        }
        else
        {
            this.claimAmountText.setPosition(this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2 + 113);
            this.claimAmountText.setScale(0.85);
            this.claimAmountText.setOrigin(0.5);
            this.claimCaptionText.setPosition(this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2 + 72);
            this.claimAmountText.setScale(0.75);
        }

        /*
        this.ingameTokensCollectedText = this.add.text (242, 269 , 'xxx / xxx', {fontSize: 20, fontFamily: 'Arial', fontStyle: 'bold', fill: '#dddddf'}).setOrigin(0.5, 0.5);
        this.ingameTokensCollectedText.setScrollFactor(0);
        this.ingameTokensCollectedText.visible = false;

        if(USE_WIDESCREEN)
        {
            this.ingameTokensCollectedText.setScale(0.95);
            this.ingameTokensCollectedText.setPosition(CANVAS_WIDTH / 2, CANVAS_HEIGHT / 2 - 168);
        }
        */

        // need to move these up the zindex as they were loaded before loading bar screen
        if(this.globals.USE_WIDESCREEN)
        {
            this.titleBg = this.add.existing(new Phaser.GameObjects.Sprite(this, 530,990,"titleBgLandscape"));
            this.titleBg.x = this.globals.CANVAS_WIDTH / 2;
            this.titleBg.y = this.globals.CANVAS_HEIGHT / 2 - 50;
            this.titleBg.setScrollFactor(0);
            this.titleBg.setScale(0.62);
        }
        else
        {
            this.titleBg = this.add.existing(new Phaser.GameObjects.Sprite(this, 545,990,"titleBg"));
            this.titleBg.x = this.globals.CANVAS_WIDTH / 2 - 10;
            this.titleBg.y = this.globals.CANVAS_HEIGHT / 2 + 40;
            this.titleBg.setScale(0.54);
            this.titleBg.setScrollFactor(0);
            //this.titleBg.y = 985;
        }

        this.startGameText = this.add.existing(new Phaser.GameObjects.Sprite(this, 127,700,"clickToStart"));

        if(this.globals.USE_WIDESCREEN)
        {
            this.startGameText.setScale(0.8);
            this.startGameText.x = this.globals.CANVAS_WIDTH / 2 + 280;
            this.startGameText.y = this.globals.CANVAS_HEIGHT / 3 * 2 - 110;
        }
        else
        {
            this.startGameText.setScale(0.8);
            this.startGameText.x = this.globals.CANVAS_WIDTH / 2 + 7;
            this.startGameText.y = this.globals.CANVAS_HEIGHT / 3 * 2.35;
        }

        this.currentDifficultyMenuText = this.add.text (this.globals.CANVAS_WIDTH / 2 + 480, this.globals.CANVAS_HEIGHT / 2 + 240 , 'Difficulty: EASY', {fontSize: 18, fontFamily: 'oswald', fill: '#ddffff'});
        this.currentDifficultyMenuText.setScrollFactor(0);
        this.currentDifficultyMenuText.setOrigin(0.5);
        this.currentDifficultyMenuText.alpha = 0.8;
        this.currentDifficultyMenuText.visible = false;

        if(!this.globals.USE_WIDESCREEN)
        {
            this.currentDifficultyMenuText.setPosition(this.globals.CANVAS_WIDTH / 2 - 180, this.globals.CANVAS_HEIGHT / 2 + 380);
        }
        this.startGameText.setScrollFactor(0);
        this.startGameText.visible = false;

        // this.blackOverlay
        this.blackOverlay = this.add.existing(new Phaser.GameObjects.Sprite(this, this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2,"blackOverlay"));
        this.blackOverlay.setScrollFactor(0);
        this.blackOverlay.setScale(5,4);

        this.blackOverlay.alpha = 0;
        this.blackOverlay.visible = false;

        this.pauseBlackOverlay = this.add.existing(new Phaser.GameObjects.Sprite(this, this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2,"blackOverlay"));
        this.pauseBlackOverlay.setScrollFactor(0);
        this.pauseBlackOverlay.setScale(5,4);

        this.pauseBlackOverlay.alpha = 0.7;
        this.pauseBlackOverlay.visible = false;



        this.hudRadar = this.add.existing(new Phaser.GameObjects.Sprite(this, 504,-10,"hudRadar"));
        this.hudRadar.alpha = 0.85;
        this.hudRadar.setScrollFactor(0);
        this.hudRadar.setVisible(false);

        this.collectProgressText = this.add.text (this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2 , 'Text here! 4/20', {fontSize: 28, fontFamily: 'oswald', fill: '#ffffff'});
        //this.collectProgressText.setOrigin(0.5);
        this.collectProgressText.setScrollFactor(0);
        this.collectProgressText.visible = false;

        this.gemSmallIcon= this.add.existing(new Phaser.GameObjects.Sprite(this, this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2,"gemIconSmall"));
        this.gemSmallIcon.setScale(0.12);
        this.gemSmallIcon.visible = false;
        this.gemSmallIcon.setScrollFactor(0);

        this.menuClickRegion = this.add.existing(new Phaser.GameObjects.Sprite(this, this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2 - 50, "transparentImage"));//"redFill"));
        if(this.globals.USE_WIDESCREEN)
        {
            this.menuClickRegion.setScale(4.5,2.6);
            this.menuClickRegion.setScrollFactor(0);
        }
        else
        {
            this.menuClickRegion.setScale(1.9,3.6);
            this.menuClickRegion.setScrollFactor(0);
        }
        this.menuClickRegion.alpha = 1;
        //this.menuClickRegion.setDepth(2000);

        this.menuClickRegion.setInteractive().on('pointerdown', function(pointer, localX, localY, event){
            // ...
            this.pressMenuScreen();
        }, this);

        // Pause menu functionality
        this.pauseMenuBackground = this.add.existing(new Phaser.GameObjects.Sprite(this, this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2 - 45, "pauseMenuBackground"));
        this.pauseMenuResume = this.add.existing(new Phaser.GameObjects.Sprite(this, this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2 - 10, "pauseMenuResume"));
        this.pauseMenuQuit = this.add.existing(new Phaser.GameObjects.Sprite(this, this.globals.CANVAS_WIDTH / 2, this.globals.CANVAS_HEIGHT / 2 + 70, "pauseMenuQuit"));


        this.pauseMenuBackground.setScrollFactor(0);
        this.pauseMenuQuit.setScrollFactor(0);
        this.pauseMenuResume.setScrollFactor(0);

        this.pauseMenuBackground.setScale(0.7);
        this.pauseMenuQuit.setScale(0.4);
        this.pauseMenuResume.setScale(0.4);

        this.pauseMenuBackground.visible = false;
        this.pauseMenuQuit.visible = false;
        this.pauseMenuResume.visible = false;

        this.pauseMenuResume.setInteractive().on('pointerdown', function(pointer, localX, localY, event){
            // ...
            this.pauseMenuResume.tint = 0xccbbbb;//0xff7788;
            this.audioManager.playSound(this.soundButtonClick);
            this.time.addEvent({ delay: 200, callback: this.hidePauseMenu, callbackScope: this, loop: false });
        }, this);

        this.pauseMenuQuit.setInteractive().on('pointerdown', function(pointer, localX, localY, event){
            // ...
            this.pauseMenuQuit.tint = 0xccbbbb;//0xff7788;
            this.audioManager.playSound(this.soundButtonClick);
            this.time.addEvent({ delay: 200, callback: this.quitGame, callbackScope: this, loop: false });

        }, this);

        if(this.globals.USE_WIDESCREEN)
        {
            this.scanlines = this.add.existing(new Phaser.GameObjects.Sprite(this, this.globals.CANVAS_WIDTH / 2,this.globals.CANVAS_HEIGHT / 2,"scanlinesWidescreen"));
            this.scanlines.alpha = 1;//0.5;
            this.scanlines.setScale(0.435,0.435);//0.51,0.51);
            //this.scanlines.setScale(0.68,0.7);
            this.scanlines.setScrollFactor(0);
            this.scale.displaySize.width = 960;
            this.scale.displaySize.height = 540;
            this.scale.refresh();
        }
        else
        {
            this.scanlines = this.add.existing(new Phaser.GameObjects.Sprite(this, 270,488,"scanlines"));
            this.scanlines.alpha = 1;//0.5;
            this.scanlines.setScale(0.6,0.6);//0.51,0.51);
            //this.scanlines.setScale(0.68,0.7);
            this.scanlines.setScrollFactor(0);
        }

        this.pauseButton = this.add.existing(new Phaser.GameObjects.Sprite(this, this.globals.CANVAS_WIDTH / 2 - 612, this.globals.CANVAS_HEIGHT / 2 - 380, "pauseButtonFrames"));
        this.pauseButton.setScale(0.34);
        this.pauseButton.setFrame(0);
        this.pauseButton.setScrollFactor(0);
        this.pauseButton.setInteractive().on('pointerdown', function(pointer, localX, localY, event){
            // ...
            if(currentGameState == GameState.Paused)
            {
                this.hidePauseMenu();
            }
            else
            {
                this.showPauseMenu();
            }

        }, this);

        if(this.globals.USE_WIDESCREEN)
        {
            this.pauseButton.setPosition(this.globals.CANVAS_WIDTH / 2 - 612, this.globals.CANVAS_HEIGHT / 2 - 380);
        }
        else
        {
            this.pauseButton.setPosition(this.globals.CANVAS_WIDTH / 2 - 287, this.globals.CANVAS_HEIGHT / 2 - 505);
        }


        // need to move this up the zindex as they were loaded before loading bar screen
        //this.scanlines.setDepth(this.blackOverlay.depth + 0.1);
        this.soundOnOffButton = this.add.existing(new Phaser.GameObjects.Sprite(this, this.globals.CANVAS_WIDTH / 2 - 590,this.globals.CANVAS_HEIGHT / 2 + 357,"soundOnOfFrames"));
        this.soundOnOffButton.setFrame(1);
        this.soundOnOffButton.alpha = 0.6;
        this.soundOnOffButton.setScale(0.34);
        this.soundOnOffButton.setScrollFactor(0);
        //this.soundOnOffButton.setDepth(1000);
        this.soundOnOffButton.setInteractive().on('pointerdown', function(pointer, localX, localY, event){
            // ...
            this.pressSoundOnOffButton();
        }, this);

        if(this.globals.USE_WIDESCREEN)
        {
            //this.soundOnOffButton.setScale(0.18, 0.18);
            this.soundOnOffButton.setPosition(this.globals.CANVAS_WIDTH / 2 + 634,this.globals.CANVAS_HEIGHT / 2 - 250);
        }
        else
        {
            //this.soundOnOffButton.setScale(0.18, 0.18);
            this.soundOnOffButton.setPosition(this.globals.CANVAS_WIDTH / 2 + 280,this.globals.CANVAS_HEIGHT / 2 - 365);
        }

        if(this.globals.USE_WIDESCREEN)
        {
            this.hudRadar.x = this.globals.CANVAS_WIDTH / 2 + 588;
            this.hudRadar.y = this.globals.CANVAS_HEIGHT / 2 - 342;
        }

        this.radarShip = this.add.existing(new Phaser.GameObjects.Sprite(this, this.globals.HIDDEN_X,this.globals.HIDDEN_X ,"radarShip"));
        this.radarShip.setScale(0.05);
        this.radarShip.alpha = 0.8;
        //this.radarShip.setDepth(1000);
        this.radarShip.setScrollFactor(0);
        this.uiRedLight = this.add.existing(new Phaser.GameObjects.Sprite(this, 401,400,"uiRedLight"));
        this.uiRedLight.alpha = 1;//0.5;
        this.uiRedLight.setScale(0.6);
        this.uiRedLight.setScrollFactor(0);
       // this.uiRedLight.setDepth(1000);
        this.uiRedLight.visible = false;

        if(this.globals.USE_WIDESCREEN)
        {
            this.uiRedLight.setScale(0.435);
            this.uiRedLight.setPosition(this.globals.CANVAS_WIDTH / 2 + 481, this.globals.CANVAS_HEIGHT / 2 + 351);
        }
        else
        {
            this.uiRedLight.setScale(0.6);
            this.uiRedLight.setPosition(this.globals.CANVAS_WIDTH / 2 + 128, this.globals.CANVAS_HEIGHT / 2 + 512);
        }

        this.sys.game.scale.on(Phaser.Scale.Events.LEAVE_FULLSCREEN, () => {
            this.onLeftFullscreenMode();
          });

          this.sys.game.scale.on(Phaser.Scale.Events.ENTER_FULLSCREEN, () => {
            this.onEnteredFullscreenMode();
          });


        this.input.keyboard.on('keydown', this.anyKeyDown, this);
        this.input.keyboard.on('keyup', this.anyKeyUp, this);

        currentGameState = GameState.Title;

        this.globals.CURRENT_SCENE = this;
        //this.joyStick.x = 270;
       // this.joyStick.y = 730;

        if(this.globals.USE_WIDESCREEN)
        {
            this.joyStick.x = this.globals.HIDDEN_X;
            this.joyStick.y = this.globals.HIDDEN_X;
        }
        else
        {
            this.showJoystick();
        }

        this.cameraStartPosX = this.cameras.main.x;
        this.cameraStartPosX = this.cameras.main.y;
        this.cameraShaking = false;
        this.shakeTimer = 0;
        this.shakeDelay = 200;



        this.queryServerOnStartup();
    } // END create()

    replayNewGame()
    {
        this.gameOverReplayButton.setFrame(1);

        //this.gameOverReplayButton.visible = false;
        this.audioManager.playSound(this.soundButtonClick);

        this.time.addEvent({ delay: 500, callback: this.showHowToPlayMenu, callbackScope: this, loop: false });

        // soundMenuMusic
    }

    pressMenuScreen()
    {
        //playSound(this.soundCollectToken);
        if (this.mouseClicksEnabled)
        {
            this.audioManager.playSound(this.soundButtonClick);
            this.startGameText.visible = false;
            this.clickToStartFlashTimer = 0;
            currentGameState = GameState.HowToPlay;
            this.hideHowToPlayUIParts();
            this.titleBg.visible = false;
            //this.currentDifficultyMenuText.visible = false;
            this.menuClickRegion.visible = false;
            this.showHowToPlayMenu();
        }
    }

    showPauseMenu()
    {
        this.pauseBlackOverlay.visible = true;
        this.pauseButton.setFrame(1);
        if(this.soundMenuMusic.isPlaying)
        {
            this.audioManager.pauseSound(this.soundMenuMusic);
        }
        else if(this.soundMusic.isPlaying)
        {
            this.audioManager.pauseSound(this.soundMusic);
        }

        this.mouseClicksEnabled = false;

        this.audioManager.playSound(this.soundButtonClick);
        // pause all physics
        this.physics.pause();
        this.prevGameStateBeforePause = currentGameState;
        currentGameState = GameState.Paused;
        this.pauseMenuBackground.visible = true;
        this.pauseMenuQuit.visible = true;
        this.pauseMenuResume.visible = true;

        for(var i = 0; i < this.meteorBuffer.length; i++)
        {
            this.meteorBuffer[i].setAnimating(false);
        }
    }

    hidePauseMenu()
    {
        this.pauseBlackOverlay.visible = false;
        this.pauseMenuResume.tint = 0xffffff;
        this.pauseMenuQuit.tint = 0xffffff;
        if(this.soundMenuMusic.isPaused)
        {
            this.audioManager.resumeSound(this.soundMenuMusic);
        }
        if(this.soundMusic.isPaused)
        {
            this.audioManager.resumeSound(this.soundMusic);
        }
        this.pauseButton.setFrame(0);
        this.mouseClicksEnabled = true;
        //this.audioManager.playSound(this.soundButtonClick);
        this.physics.resume();
        currentGameState = this.prevGameStateBeforePause;
        this.pauseMenuBackground.visible = false;
        this.pauseMenuQuit.visible = false;
        this.pauseMenuResume.visible = false;
        for(var i = 0; i < this.meteorBuffer.length; i++)
        {
            this.meteorBuffer[i].setAnimating(true);
        }
    }

    quitGame()
    {
        this.hidePauseMenu();
        this.blowUpAllAsteroids(false);
        this.hideAllCollectTokens();
        this.hideMeteorProgressBars();
        this.hidePowerUpCannisters();
        this.hidePowerUpProgress();
        this.hideRadarDots();
        this.hideMeteorExplosionParticles();
        this.player.visible = false;

        this.joyStick.x = this.globals.HIDDEN_X;
        this.joyStick.y = this.globals.HIDDEN_X;
        this.player.body.checkCollision.none = true;
        this.player.body.setVelocity(0);
        this.mouseClicksEnabled = false;

        this.time.addEvent({ delay: 1200, callback: this.enableMouseClicks, callbackScope: this, loop: false });

        if(this.globals.SHOW_JET_PARTICLES)
        {
            this.emitter.stop();
        }
        if(this.globals.SHOW_SHIP_FLAMES)
        {
            this.shipFlames.visible = false;
        }
        this.navigateToMainMenu(false);
        if(this.globals.USE_WIDESCREEN)
        {
            // if desktop / widescreen mode then we just return them to main menu instead of closing modal
            // TODO: BEN - REMOVE THIS! ONLY FOR TESTING PURPOSES
            //currentGameState = GameState.Quit;
        }
        else
        {
            // close the modal / set state to Quit which the website will listen for
            currentGameState = GameState.Quit;
        }
    }
    showHowToPlayUIParts()
    {
        this.player.visible = true;
        if(this.globals.SHOW_SHIP_FLAMES)
        {
            this.shipFlames.visible = true;
        }

        this.hudCollectProgressBar.visible = true;
        this.hudCollectProgressBarBg.visible = true;
        this.hudHealthProgressBar.visible = true;
       // this.hudPowerBar.visible = true;
        this.hudHealthProgressBar.visible = true;
        this.hudHealthProgressBarBg.visible = true;
        this.progressRing.visible = true;
        this.iconMissile.visible = true;
        this.iconScatter.visible = true;
        this.iconSideBullet.visible = true;

    }

    hideHowToPlayUIParts(hidePlayerShip = true)
    {
        this.joyStick.x = this.globals.HIDDEN_X;
        this.joyStick.y = this.globals.HIDDEN_X;
        if(hidePlayerShip)
        {
            this.player.visible = false;
            if(this.globals.SHOW_JET_PARTICLES)
            {
                this.emitter.stop();

            }
            if(this.globals.SHOW_SHIP_FLAMES)
            {
                this.shipFlames.visible = false;
            }
            //
        }

        this.hudCollectProgressBar.visible = false;
        this.hudCollectProgressBarBg.visible = false;
        this.hudHealthProgressBar.visible = false;
        this.hudHealthProgressBarBg.visible = false;
        this.hidePowerUpProgress();

    }

    showJoystick()
    {
        if(this.globals.USE_WIDESCREEN)
        {
            this.joyStick.x = this.globals.CANVAS_WIDTH / 2;
            this.joyStick.y = this.globals.CANVAS_HEIGHT / 3 * 1.9;
        }
        else
        {
            this.joyStick.x = 270;
            this.joyStick.y = 730;
        }
    }

    onLeftFullscreenMode()
    {
        var phaserContainer = document.getElementById("phaserContainer");
        if(phaserContainer != null)
        {
            phaserContainer.style.cssText = this.inlinePhaserContainerStyle;
        }

    }

    onEnteredFullscreenMode()
    {
    }

    pressClaimButton()
    {
        if(currentGameState == GameState.GameWin)
        {
            this.claimButton.setFrame(1);
            currentGameState = GameState.Title;
            this.cameras.main.startFollow(this.player);
            this.player.angle = -90;
            this.player.visible = false;
            if(this.globals.SHOW_JET_PARTICLES)
            {
                this.emitter.stop();
            }
            if(this.globals.SHOW_SHIP_FLAMES)
            {
                this.shipFlames.visible = false;
            }
            this.audioManager.playSound(this.soundButtonClick);
            //this.soundButtonClick.play();
            currentGameState = GameState.CollectingRewards;
        }
    }

    pressSoundOnOffButton()
    {
        if(currentGameState == GameState.Paused)
            return;

        if(this.audioManager.soundEnabled)
        {
            this.audioManager.playSound(this.soundButtonClick);
            this.audioManager.soundEnabled = false;
            this.soundOnOffButton.setFrame(0);
            this.soundAmbience.stop();
            this.soundMenuMusic.stop();
            this.soundMusic.pause();
        }
        else
        {
            this.audioManager.soundEnabled = true;
            this.audioManager.playSound(this.soundButtonClick);
            this.soundOnOffButton.setFrame(1);
            this.audioManager.playSound(this.soundAmbience);
            if(currentGameState == GameState.GamePlay)
            {
                this.audioManager.playSound(this.soundMusic);
            }
            else if(currentGameState == GameState.Title || currentGameState == GameState.HowToPlay)
            {

                this.soundMenuMusic.volume = this.globals.MUSIC_VOLUME;
                this.audioManager.playSound(this.soundMenuMusic);
            }
        }
    }

    pressPlayButton()
    {
        if(currentGameState == GameState.HowToPlay)
        {
            this.playButton.setFrame(1);
            this.audioManager.playSound(this.soundButtonClick);
            currentGameState = GameState.MenuTransitionFadeToGame;
            this.audioManager.playSound(this.soundStartGame);
            this.blackOverlay.visible = true;
            this.blackOverlay.alpha = 0;
        }
    }


    launchPGWebPortal()
    {
        window.location.href = "https://phantomgalaxies.com";
    }


    blowUpAllAsteroids(playExplosionSound = true)
    {
        var visibleExplosion = false;
        //var blewUpAMeteor = false;
        for(var i = 0; i < this.meteorBuffer.length; i++)
        {
            var meteor = this.meteorBuffer[i];

            if(this.cameras.main.worldView.contains(meteor.x, meteor.y))
            {
                visibleExplosion = true;
                meteor.body.checkCollision.none = true;
                this.explodeMeteor(meteor.x, meteor.y, playExplosionSound, meteor.scale);
                meteor.x = this.globals.HIDDEN_X;
            }
            else
            {
                meteor.body.checkCollision.none = true;
                meteor.x = this.globals.HIDDEN_X;
            }
        }

        if(visibleExplosion && playExplosionSound)
        {
            this.audioManager.playSoundOverlap(this,'soundExplode',1 , this.globals.EXPLODE_COOLDOWN_MILLIS);
        }
    }

    findNextMissileTarget()
    {
        for(var i=0; i < this.meteorBuffer.length; i++)
        {
            var theMeteor = this.meteorBuffer[i];

            if(this.cameras.main.worldView.contains(theMeteor.x, theMeteor.y))
            {
                // found a visible missile so return it
                return theMeteor;
            }
        }
    }

    shootMissile()
    {
        if (this.missileFired) return;

        this.missileTarget = this.findNextMissileTarget();

        if (this.missileTarget) {
            this.missileFired = true;
            this.MISSILE_SPEED = this.START_SPEED;
            this.ROTATION_SPEED = 0;

            this.missileMaster.x = this.player.x;
            this.missileMaster.y = this.player.y;
            this.missileMaster.rotation = this.player.rotation;
            this.physics.velocityFromRotation(this.player.rotation, 1000, this.missileMaster.body.velocity) ;
            this.missileMaster.visible = true;

            this.audioManager.playSound(this.soundWeaponMissile);
      }
    }

    updateMissile(deltaTime)
    {
        if (!this.missileFired) return;

        if (this.missileMaster) {
            this.MISSILE_SPEED += this.MISSILE_ACCELERATION * deltaTime;

            if(this.MISSILE_SPEED > this.MISSILE_MAX_SPEED)
            {
                this.MISSILE_SPEED = this.MISSILE_MAX_SPEED;
            }
            this.ROTATION_SPEED += (0.0005 * deltaTime);

            var angleToPointer = Phaser.Math.Angle.Between(this.missileMaster.x, this.missileMaster.y, this.missileTarget.x, this.missileTarget.y);

            if (
                Phaser.Math.Distance.Between(
                this.missileMaster.x,
                this.missileMaster.y,
                this.player.x,
                this.player.y
                ) > 600
            )
            {
                this.missileFired = false;
                this.missileMaster.visible = false;
                this.missileMaster.body.velocity.x = 0;
                this.missileMaster.body.velocity.y = 0;
                this.missileTarget.body.checkCollision.none = true;
                this.missileTarget.body.x = this.globals.HIDDEN_X;
                this.missileTarget.body.x = this.globals.HIDDEN_X;
                this.missileTarget = null;
                return;
            }

            this.missileMaster.rotation = Phaser.Math.Angle.RotateTo(
              this.missileMaster.rotation,
              angleToPointer,
              this.ROTATION_SPEED
            );

            // TODO: Port to Phaser 3
            this.physics.velocityFromRotation(
              this.missileMaster.rotation,
              this.MISSILE_SPEED,
              this.missileMaster.body.velocity
            );
            for(var i=0; i < this.meteorBuffer.length; i++)
            {
                var currentMeteorTarget = this.meteorBuffer[i];
                if (
                Phaser.Math.Distance.Between(
                    this.missileMaster.x,
                    this.missileMaster.y,
                    currentMeteorTarget.x,
                    currentMeteorTarget.y
                ) < 100
                )
                {
                    // HIT THE ENEMY!!!!!
                    this.missileFired = false;
                    this.missileMaster.visible = false;
                    this.soundWeaponMissile.stop();
                    this.missileMaster.body.velocity.x = 0;
                    this.missileMaster.body.velocity.y = 0;
                    currentMeteorTarget.body.checkCollision.none = true;
                    this.explodeMeteor(currentMeteorTarget.x, currentMeteorTarget.y, true, currentMeteorTarget.scale);
                    currentMeteorTarget.body.x = this.globals.HIDDEN_X;
                    currentMeteorTarget.body.x = this.globals.HIDDEN_X;
                    currentMeteorTarget.visible = false;
                    this.missileTarget = null;
                    currentMeteorTarget = null;

                }
            }
        }
      }

    anyKeyUp(event)
    {
        var code = event.keyCode;
        if (code === Phaser.Input.Keyboard.KeyCodes.F)
        {
            this.fKeyDown = false;
        }
    }

    anyKeyDown(event)
    {
        // hide the joystick
        this.joyStick.x = this.globals.HIDDEN_X;
        this.joyStick.y = this.globals.HIDDEN_X;
        var code = event.keyCode;
        if (code === Phaser.Input.Keyboard.KeyCodes.F)
        {
            if(this.globals.ALLOW_FULLSCREEN_MODE)
            {
                var phaserContainer = document.getElementById("phaserContainer");

                if (this.scale.isFullscreen) {
                    // On stop fulll screen
                    this.scale.stopFullscreen();
                    if(phaserContainer != null)
                    {
                        phaserContainer.style.cssText = this.inlinePhaserContainerStyle;
                    }
                }
                else
                {
                    if(phaserContainer != null)
                    {
                        this.inlinePhaserContainerStyle = phaserContainer.style.cssText;
                        phaserContainer.style.cssText = "height: 100%; margin-left: 0px; margin-top: 0px;";
                    }

                    this.scale.startFullscreen();
                    // On start fulll screen
                }
                this.fKeyDown = true;
            }
        }
    }
    // reset all game params for a new game
    startNewGame(bypassSounds = false)
    {

        this.globals.SESSION_START_TIME = this.time.now;

        if(!this.globals.USE_WIDESCREEN)
        {
            this.showJoystick();
        }
        this.currentDifficultyMenuText.visible = false;
        this.player.visible = true;
        if(this.globals.SHOW_JET_PARTICLES)
        {
            this.emitter.start();
        }
        if(this.globals.SHOW_SHIP_FLAMES)
        {
            this.shipFlames.visible = true;
        }
        //this.cameras.main.startFollow(this.player);
        //this.player.angle = -90;

        this.showHowToPlayUIParts();
        currentWeaponType = WeaponType.Laser;
        this.hudHealthProgressBar.setTexture("healthProgressBar");
        currentGameState = GameState.GamePlay;
        this.soundMenuMusic.stop();
        if(!bypassSounds)
        {
            this.audioManager.playSound(this.soundMusic, this.globals.MUSIC_VOLUME,true);
        }

        this.player.body.checkCollision.none = false;
        for(var i=0; i < this.meteorRadarDotBuffer.length; i++)
        {
            this.meteorRadarDotBuffer[i].visible = true;
        }

        for(var i=0; i < this.gemRadarDotBuffer.length; i++)
        {
            this.gemRadarDotBuffer[i].visible = true;
        }

        this.missileFired = false;
        // TODO: REMOVE THIS! CAN ONLY EARN TOKENS ONCE PER DAY
        //this.tokensToEarn = this.initTokensToEarn;
        this.tokensEarnt = 0;
        this.titleBg.visible = false;
        this.currentDifficultyMenuText.visible = false;
        this.startGameText.visible = false;
        this.enableMouseClicks();
        this.player.setFrame(0);
        this.frameCounter = 0;
        this.powerUpDurationTimer = 0;
        this.collectRewardTimer = 0;
        this.tokensCollected = 0;
        this.updateCollectProgressBar();

        this.player.body.checkCollision.none = false;
        this.hidePowerUpProgress();
        this.howToPlayPanel.visible = false;
        this.playButton.visible = false;
        this.missionCaptionText.visible = false;
        this.goalToCollectText.visible = false;
        this.playButton.setFrame(0);
        this.healthCounter = this.startHealthCounter;

        this.updateHealthBar();
        for(var i=0; i < this.meteorBuffer.length; i++)
        {
            this.meteorBuffer[i].body.checkCollision.none = true;
            this.meteorBuffer[i].x = this.globals.HIDDEN_X;
            this.meteorBuffer[i].visible = false;
            // spawn
        }
        //this.hudPowerBar.setFrame(0); // full bar
        this.progressRing.visible = false;
        this.iconScatter.visible = false;
        this.iconMissile.visible = false;
        this.iconSideBullet.visible = false;
        if(this.globals.START_WITH_SIDE_WEAPON)
        {
            currentWeaponType = WeaponType.SideBullet;
            this.progressRing.visible = true;
            this.iconSideBullet.visible = true;
        }
        else if(this.globals.START_WITH_MISSILES)
        {
            currentWeaponType = WeaponType.Missile;
            this.progressRing.visible = true;
            this.iconMissile.visible = true;
        }
        else if(this.globals.START_WITH_SCATTER)
        {
            currentWeaponType = WeaponType.FireBullet;
            this.progressRing.visible = true;
            this.iconScatter.visible = true;

        }

        if(!bypassSounds)
        {
            this.spawnRandomMeteor();
        }


        this.player.angle = -90;
        this.update(1,1);
    }

    // GameState.GameOver event handler
    onGameOver()
    {
        this.globals.SESSION_END_TIME = this.time.now;
        this.globals.SESSION_DURATION = this.globals.SESSION_END_TIME - this.globals.SESSION_START_TIME;
        this.globals.SESSION_WIN_LOSE = 0;


        this.registerAnalyticsEvent(this.globals.SESSION_DURATION,this.globals.SESSION_WIN_LOSE);


        this.explodeMeteor(this.player.x, this.player.y);
        this.joyStick.x = this.globals.HIDDEN_X;
        this.joyStick.y = this.globals.HIDDEN_X;
        this.player.body.checkCollision.none = true;
        this.player.body.setVelocity(0);
        this.mouseClicksEnabled = false;
        this.audioManager.playSound(this.soundStingerDefeat);
        this.soundMusic.stop();
        this.time.addEvent({ delay: 1200, callback: this.enableMouseClicks, callbackScope: this, loop: false });
        this.blowUpAllAsteroids();
        this.hideAllCollectTokens();
        this.hideMeteorProgressBars();
        this.hidePowerUpCannisters();
        this.hidePowerUpProgress();

        //this.time.addEvent({ delay: 1200, callback: this.blowUpAllAsteroids, callbackScope: this, loop: false });
        currentGameState = GameState.GameOver;
        this.gameOverText.visible = true;
        this.gameOverReplayButton.visible = true;
        this.gameOverReplayButton.setFrame(0);
        this.player.visible = false;
        if(this.globals.SHOW_JET_PARTICLES)
        {
            this.emitter.stop();
        }
        if(this.globals.SHOW_SHIP_FLAMES)
        {
            this.shipFlames.visible = false;
        }
        this.hideRadarDots();
        this.hideMeteorProgressBars();
        // sometimes they werent being removed so remove them after
        this.time.addEvent({ delay: 500, callback: this.hideMeteorProgressBars, callbackScope: this, loop: false });
    }

    // stop ship flashing red after being hit / reset its color
    resetShipColor()
    {
        this.redFill.visible = false;
    }

    enableMouseClicks()
    {
        this.mouseClicksEnabled = true;
    }

    showHowToPlayMenu()
    {
        if(!this.soundMenuMusic.isPlaying)
        {
            this.soundMenuMusic.volume = this.globals.MUSIC_VOLUME;
            this.audioManager.playSound(this.soundMenuMusic);
        }
        this.hudCollectProgressBar.visible = false;
        this.hudCollectProgressBarBg.visible = false;
        //this.hudPowerBar.visible = false;
        this.hudHealthProgressBar.visible = false;
        this.hudHealthProgressBarBg.visible = false;
        this.gameOverReplayButton.setFrame(0);
        this.gameOverReplayButton.visible = false;
        this.gameOverText.visible = false;
        this.howToPlayPanel.visible = true;
        this.popupMenuCollectToken.visible = true;
        this.goalToCollectText.visible = true;
        this.playButton.visible = true;
        this.missionCaptionText.visible = true;
        currentGameState = GameState.HowToPlay;
    }

    // called after render update, needed for accurate tracking
    onPostUpdate(time, delta)
    {
        if(currentGameState == GameState.GamePlay)
        {
            this.text.x = this.player.x + 50;
            this.text.y = this.player.y - 100;

            this.shootCurrentWeapon(delta);
        }

        if(this.globals.SHOW_SHIP_FLAMES)
        {
            this.shipFlames.x = this.player.x;
            this.shipFlames.y = this.player.y;
            this.shipFlames.rotation = this.player.rotation;
        }

        // check if bullets are off screen, if so then recycle them
        if(this.cameraShaking)
        {
            this.updateCameraShake(delta);
        }
    }

    getCurrentAstraferBalance()
    {
        // TODO: Get the users real astrafer balance here (and cache it so we dont have to do it repeatedlyx)
        this.currentAstraferBalance = 10000;
        return this.currentAstraferBalance;
    }

    onCollectedAllGems()
    {
        this.portalImage.x = this.player.x;
        if(this.globals.GOAL_COMPLETE_FLY_DOWN)
        {
            this.portalImage.y = this.player.y + 520;
        }
        else
        {
            this.portalImage.y = this.player.y - 520;
        }
        this.portalImage.visible = true;
        this.blowUpAllAsteroids();
        this.hideAllCollectTokens();
        this.hidePowerUpCannisters();
        this.hideMeteorProgressBars();
        this.ROTATION_SPEED = this.START_ROTATION_SPEED / 3;
        currentGameState = GameState.FlyingToPortal;
        //this.hudCollectProgressBar.visible = false;
        //this.hudCollectProgressBarBg.visible = false;
        //this.hudHealthProgressBar.visible = false;
        //this.hudHealthProgressBarBg.visible = false;
        this.physics.velocityFromRotation(
            this.player.rotation,
            this.MISSILE_MAX_SPEED / 4,
            this.player.body.velocity
          );
        this.player.throttle = 1024;
        this.audioManager.playSound(this.soundStingerVictory);
        this.soundMusic.stop();

        this.time.addEvent({ delay: 2000, callback: this.onGameWin, callbackScope: this, loop: false });

        //this.goalCompleteText.visible = true;

    }

    updateFloatingCollectText(deltaTime)
    {
        if(this.floatingCollectText)
        {
            this.collectProgressText.y -= deltaTime / 5;
            this.collectProgressText.alpha -= deltaTime / 1200;
            this.gemSmallIcon.alpha -= deltaTime / 1200;
            this.gemSmallIcon.y = this.collectProgressText.y + 20;
            if(this.collectProgressText.y < this.globals.CANVAS_HEIGHT / 2 - 200)
            {
                this.floatingCollectText = false;
                this.collectProgressText.visible = false;
                this.gemSmallIcon.visible = false;
            }
        }
    }

    floatCollectText()
    {
        this.floatingCollectText = true;
        this.gemSmallIcon.visible = true;

        this.collectProgressText.visible = true;

        this.gemSmallIcon.alpha = 1;
        this.collectProgressText.alpha = 1;

        this.collectProgressText.x = this.globals.CANVAS_WIDTH / 2;
        this.collectProgressText.y = this.globals.CANVAS_HEIGHT / 2 - 60;
        this.gemSmallIcon.x = this.collectProgressText.x - 25;
        this.gemSmallIcon.y = this.collectProgressText.y + 20;
        this.collectProgressText.text = "" + this.tokensCollected + "/" + this.globals.TOKENS_REQUIRED_TO_WIN;
    }

    registerAnalyticsEvent(duration, winLose)
    {

    }
    onGameWin()
    {
        this.globals.SESSION_END_TIME = this.time.now;
        this.globals.SESSION_DURATION = this.globals.SESSION_END_TIME - this.globals.SESSION_START_TIME;
        this.globals.SESSION_WIN_LOSE = 1;


        this.registerAnalyticsEvent(this.globals.SESSION_DURATION,this.globals.SESSION_WIN_LOSE);

        if(this.globals.SHOW_DIFFICULTY_MSG)
        {
            this.currentDifficultyMenuText.visible = true;
        }

        this.cameras.main.stopFollow(this.player);
        this.goalCompleteText.setScale(0.22);
        this.goalCompleteText.alpha = 0.8;
        this.goalCompleteText.visible = false;
        this.goalCompleteText.setScrollFactor(0);

        this.goalCompleteText.setPosition(this.goalCompleteStartX, this.goalCompleteStartY);

        currentGameState = GameState.GameWin;
        this.goalCompletePanel.visible = true;
        this.popupMenuCollectToken.visible = true;
        this.claimButton.visible = false;
        this.claimButton.setFrame(0);
        this.claimAmountText.visible = true;
        this.claimCaptionText.visible = true;

        this.goalCompletePanel.alpha = 0;
        this.popupMenuCollectToken.alpha = 0;
        this.claimButton.alpha = 0;
        this.claimAmountText.alpha = 0;
        this.claimCaptionText.alpha = 0;

        //this.ingameTokensCollectedText.text = this.maxTokensCollect;
        this.claimAmountText.text = "COLLECTED: " + this.globals.TOKENS_REQUIRED_TO_WIN;;
        this.tokensToEarn = this.globals.TOKENS_REQUIRED_TO_WIN;;
        //this.player.visible = false;
        //this.player.body.checkCollision.none = true;
        //this.player.body.setVelocity(0);
        this.mouseClicksEnabled = false;
        this.time.addEvent({ delay: 1200, callback: this.enableMouseClicks, callbackScope: this, loop: false });
        this.soundMusic.stop();
        this.audioManager.playSound(this.soundAmbience);
        this.hideRadarDots();
        this.time.addEvent({ delay: 2000, callback: this.showClaimButton, callbackScope: this, loop: false });

        this.hideHowToPlayUIParts(false);


    }

    showClaimButton()
    {
        this.claimButton.visible = true;

    }

    hideMeteorExplosionParticles()
    {
        for(var i=0; i < this.meteorBlastBuffer.length; i++)
        {
            this.meteorBlastBuffer[i].visible = false;
        }
    }


    hideMeteorProgressBars()
    {
        for(var i=0; i < this.meteorBuffer.length; i++)
        {
            this.meteorBuffer[i].progressBar.visible = false;
        }
    }

    hideRadarDots()
    {
        for(var i=0; i < this.meteorRadarDotBuffer.length; i++)
        {
            this.meteorRadarDotBuffer[i].visible = false;
        }

        for(var i=0; i < this.gemRadarDotBuffer.length; i++)
        {
            this.gemRadarDotBuffer[i].visible = false;
        }
    }

    updateLoadingBar()
    {
        var cropFactor = TOTAL_LOADING_PROGRESS_LOCAL * this.loadingProgressBg.width;
        this.loadingProgressBar.setCrop(0,0,cropFactor,97);
    }
    updateCollectProgressBar()
    {
        var completeRatio = this.tokensCollected / this.globals.TOKENS_REQUIRED_TO_WIN;;

        // take off the
        var cropFactor = completeRatio * (this.hudCollectProgressBar.width * 0.88);
        this.hudCollectProgressBar.setCrop(0,0,cropFactor,this.hudCollectProgressBar.height);

        if(this.tokensCollected >= this.globals.TOKENS_REQUIRED_TO_WIN)
        {
            // game won!
            this.onCollectedAllGems();
            this.hudCollectProgressBar.setCrop(0,0,this.hudCollectProgressBar.width,this.hudCollectProgressBar.height);

        }
    }



    updatePowerUps(delta) {
        if(currentWeaponType == WeaponType.FireBullet)
        {
            var completeRatio = this.powerUpDurationTimer / this.powerUpDurationDelay;
            var currentFrame = 16 - (completeRatio * 16);

            this.progressRing.setFrame(Math.floor(currentFrame));
            this.powerUpDurationTimer += delta;
            if(this.powerUpDurationTimer > this.powerUpDurationDelay)
            {
                // powerup expired
                this.powerUpDurationTimer = 0;
                currentWeaponType = WeaponType.Laser;
                this.progressRing.visible = false;
                this.iconScatter.visible = false;
            }
        }
        else if(currentWeaponType == WeaponType.Missile)
        {
            var completeRatio = this.powerUpDurationTimer / this.powerUpDurationDelay;
            var currentFrame = 16 - (completeRatio * 16);

            this.progressRing.setFrame(Math.floor(currentFrame));
            this.powerUpDurationTimer += delta;
            if(this.powerUpDurationTimer > this.powerUpDurationDelay)
            {
                // powerup expired
                this.powerUpDurationTimer = 0;
                currentWeaponType = WeaponType.Laser;
                this.progressRing.visible = false;
                this.iconMissile.visible = false;
            }
        }
        else if(currentWeaponType == WeaponType.SideBullet)
        {
            var completeRatio = this.powerUpDurationTimer / this.powerUpDurationDelay;
            var currentFrame = 16 - (completeRatio * 16);

            this.progressRing.setFrame(Math.floor(currentFrame));
            this.powerUpDurationTimer += delta;
            if(this.powerUpDurationTimer > this.powerUpDurationDelay)
            {
                // powerup expired
                this.powerUpDurationTimer = 0;
                currentWeaponType = WeaponType.Laser;
                this.progressRing.visible = false;
                this.iconSideBullet.visible = false;
            }
        }
    }

    updateHealthBar()
    {

        var completeRatio = this.healthCounter / this.startHealthCounter;
        var cropFactor = completeRatio * this.hudHealthProgressBar.width;
        this.hudHealthProgressBar.setCrop(0,0,cropFactor,this.hudHealthProgressBar.height);

        //this.hudPowerBar.setFrame(16 - this.healthCounter);
        if(this.tokensCollected >= this.globals.TOKENS_REQUIRED_TO_WIN)
        {
            // game won!
            this.onCollectedAllGems();
        }
    }

    recycleMeteors()
    {
        var radarCenterX = this.hudRadar.x;
        var radarCenterY = this.hudRadar.y;
        for(var i=0; i < this.globals.METEOR_BUFFER_SIZE; i++)
        {
            var radarRange = 500.0;
            var currentMeteor = this.meteorBuffer[i];
            var meteorDistanceFromPlayer = Phaser.Math.Distance.Between(this.player.x, this.player.y, currentMeteor.x, currentMeteor.y);
            if(meteorDistanceFromPlayer > 2000)
            {
                // recycle this so a new one can spawn
                this.meteorBuffer[i].x = this.globals.HIDDEN_X;
                this.meteorBuffer[i].y = this.globals.HIDDEN_X;
                this.meteorBuffer[i].body.checkCollision.none = true;
                this.meteorBuffer[i].visible = false;
            }
        }
    }

    updateMeteors(delta)
    {
        for(var i=0; i < this.meteorBuffer.length; i++)
        {
            this.meteorBuffer[i].updateMeteor(delta);
        }
    }

    updateRadar() {

        var radarCenterX = this.hudRadar.x;
        var radarCenterY = this.hudRadar.y;
        var radarRange = 500.0;

        for(var i=0; i < this.globals.METEOR_BUFFER_SIZE; i++)
        {
            var currentMeteor = this.meteorBuffer[i];
            var currentDot = this.meteorRadarDotBuffer[i];
            var meteorDistanceFromPlayer = Phaser.Math.Distance.Between(this.player.x, this.player.y, currentMeteor.x, currentMeteor.y);
            if(meteorDistanceFromPlayer < 1600)
            {
                var xWorldDelta = this.player.x - currentMeteor.x;
                var yWorldDelta = this.player.y - currentMeteor.y;
                var radarNormalisedXDelta = xWorldDelta / radarRange * 20.0;
                var radarNormalisedYDelta = yWorldDelta / radarRange * 20.0;
                currentDot.x = radarCenterX - radarNormalisedXDelta;
                currentDot.y = radarCenterY - radarNormalisedYDelta;

                currentDot.visible = true;
                currentDot.setDepth(1000);
            }
            else
            {
                currentDot.x = this.globals.HIDDEN_X;
                currentDot.y = this.globals.HIDDEN_X;
            }
        }
        for(var i=0; i < this.gemRadarDotBuffer.length; i++)
        {
            var currentGem = this.tokenCollectBuffer[i];
            var currentDot = this.gemRadarDotBuffer[i];
            var gemDistanceFromPlayer = Phaser.Math.Distance.Between(this.player.x, this.player.y, currentGem.x, currentGem.y);
            if(gemDistanceFromPlayer < 1600)
            {
                var xWorldDelta = this.player.x - currentGem.x;
                var yWorldDelta = this.player.y - currentGem.y;
                var radarNormalisedXDelta = xWorldDelta / radarRange * 20.0;
                var radarNormalisedYDelta = yWorldDelta / radarRange * 20.0;
                currentDot.x = radarCenterX - radarNormalisedXDelta;
                currentDot.y = radarCenterY - radarNormalisedYDelta;

                currentDot.visible = true;
                currentDot.setDepth(1000);
            }
            else
            {
                currentDot.x = this.globals.HIDDEN_X;
                currentDot.y = this.globals.HIDDEN_X;
            }

        }
        this.radarShip.x = radarCenterX;
        this.radarShip.y = radarCenterY;
        this.radarShip.angle = this.player.angle;
    }

    updateJoystickState(delta) {
        if(this.globals.DEBUG_METEOR_IDS)
        {
            this.text.visible = true;
        }

        var joystickAngle = Math.floor(this.joyStick.angle * 100) / 100;
        var playerThrottle = Math.floor(this.joyStick.force * 100) / 100;

        if(joystickAngle != 0)
        {
            var targetAngleRads = Phaser.Math.DegToRad(joystickAngle);
            var currentAngleRads = Phaser.Math.DegToRad(this.player.angle);
            var newAngle = Phaser.Math.Angle.RotateTo(currentAngleRads,targetAngleRads, 0.2);
            newAngle = Phaser.Math.RadToDeg(newAngle);
            this.player.angle = newAngle;
        }

        if(playerThrottle != 0)
        {
            this.player.setThrottle(playerThrottle);
        }

        var cursorKeys = this.joyStick.createCursorKeys();

        var s = 'Key down: ';
        for (var name in cursorKeys) {
            if (cursorKeys[name].isDown) {
                s += `${name} `;
            }
        }

        s += `
Force: ${Math.floor(this.joyStick.force * 100) / 100}
Angle: ${Math.floor(this.joyStick.angle * 100) / 100}
`;

        s += '\nTimestamp:\n';
        for (var name in cursorKeys) {
            var key = cursorKeys[name];
            s += `${name}: duration=${key.duration / 1000}\n`;
        }
        this.text.setText(s);

    }

    // Spawn a random meteor around the player and aim its velocity at player
    spawnRandomMeteor()
    {
        var hardDifficulty = false;
        if(this.tokensCollected  > this.globals.TOKENS_REQUIRED_TO_WIN / 2)
        {
            hardDifficulty = true;
        }

        for(var i=0; i < this.meteorBuffer.length; i++)
        {
            if(this.meteorBuffer[i].body.checkCollision.none)
            {
                // TODO - Spawn Meteors in the direction you are flying..
                var playerAngle = this.player.body.angle;
                var randomSpawnAngle = Phaser.Math.Angle.Random();
                var spawnInDirectionOfFlight = false;

                if(true)
                {
                    if(Phaser.Math.FloatBetween(0,2) == 1)
                    {
                        spawnInDirectionOfFlight = true;
                    }
                }

                var spawnDistanceMin = 400;
                var spawnDistanceMax = 1200;

                var spawnPlayerXOffset = Phaser.Math.FloatBetween(this.player.x + spawnDistanceMin, this.player.x + spawnDistanceMax);
                var spawnPlayerYOffset = Phaser.Math.FloatBetween(this.player.y + spawnDistanceMin, this.player.y + spawnDistanceMax);

                if(spawnInDirectionOfFlight)
                {
                    var spawnPoint = Phaser.Math.RotateTo(this.meteorBuffer[i], this.player.x, this.player.y, Phaser.Math.DegToRad(this.player.angle), 5000);
                    this.meteorBuffer[i].x = spawnPoint.x;
                    this.meteorBuffer[i].y = spawnPoint.y;
                    // get a vector in the direction we are flying
                    var meteorSpeed = 150;
                    if(this.currentDifficulty == DifficultyLevel.Easy)
                    {
                        meteorSpeed = Phaser.Math.FloatBetween(100,150);
                    }
                    else if(this.currentDifficulty == DifficultyLevel.Medium)
                    {
                        meteorSpeed = Phaser.Math.FloatBetween(125,175);
                    }
                    else
                    {
                        meteorSpeed = Phaser.Math.FloatBetween(150,200);
                    }

                    var velocityVector = new Phaser.Math.Vector2( this.player.x - this.meteorBuffer[i].x, this.player.y - this.meteorBuffer[i].y );
                    velocityVector.setLength(meteorSpeed);

                    this.meteorBuffer[i].body.setVelocity(velocityVector.x, velocityVector.y);
                }
                else
                {
                    this.meteorBuffer[i].x = spawnPlayerXOffset;
                    this.meteorBuffer[i].y = spawnPlayerYOffset;

                    Phaser.Actions.RotateAround([this.meteorBuffer[i]], { x: this.player.x, y: this.player.y }, randomSpawnAngle);
                    /*
                    var meteorSpeed = Phaser.Math.FloatBetween(50,200);

                    if(hardDifficulty)
                    {
                        meteorSpeed = Phaser.Math.FloatBetween(150,200);
                    }
                    */
                    var meteorSpeed = 150;
                    if(this.currentDifficulty == DifficultyLevel.Easy)
                    {
                        meteorSpeed = Phaser.Math.FloatBetween(100,150);
                    }
                    else if(this.currentDifficulty == DifficultyLevel.Medium)
                    {
                        meteorSpeed = Phaser.Math.FloatBetween(110,175);
                    }
                    else if(this.currentDifficulty == DifficultyLevel.Hard)
                    {
                        meteorSpeed = Phaser.Math.FloatBetween(125,180);
                    }
                    else
                    {
                        meteorSpeed = Phaser.Math.FloatBetween(125,190);
                    }
                    // get a direction vector to point at players current position
                    var velocityVector = new Phaser.Math.Vector2( this.player.x - this.meteorBuffer[i].x, this.player.y - this.meteorBuffer[i].y );
                    velocityVector.setLength(meteorSpeed);

                    this.meteorBuffer[i].body.setVelocity(velocityVector.x, velocityVector.y);
                }
                this.meteorBuffer[i].body.checkCollision.none = false;
                this.meteorBuffer[i].progressBar.visible = false;
                this.meteorBuffer[i].hitCount = this.meteorBuffer[i].totalHitCount;
                this.meteorBuffer[i].visible = true;
            }
        }
    }

    checkAnyVialsVisible()
    {
        for(var i=0; i < this.powerUpsBufferBlue.length; i++)
        {
            if(this.cameras.main.worldView.contains(this.powerUpsBufferBlue[i].x, this.powerUpsBufferBlue[i].y))
            {
                return true;
            }
        }
        for(var i=0; i < this.powerUpsBufferRed.length; i++)
        {
            if(this.cameras.main.worldView.contains(this.powerUpsBufferRed[i].x, this.powerUpsBufferRed[i].y))
            {
                return true;
            }
        }
        for(var i=0; i < this.powerUpsBufferYellow.length; i++)
        {
            if(this.cameras.main.worldView.contains(this.powerUpsBufferYellow[i].x, this.powerUpsBufferYellow[i].y))
            {
                return true;
            }
        }

        return false;
    }

    onFinishedCollectingRewards()
    {
        /*
        if(this.currentDifficulty == DifficultyLevel.Easy)
        {
            this.setDifficultyLevel(DifficultyLevel.Medium);
        }
        else if(this.currentDifficulty == DifficultyLevel.Medium)
        {
            this.setDifficultyLevel(DifficultyLevel.Hard);
        }
        else if(this.currentDifficulty == DifficultyLevel.Hard)
        {
            this.setDifficultyLevel(DifficultyLevel.Extreme);

        }
        */
        this.navigateToMainMenu();
    }

    navigateToMainMenu(playButtonSound = true)
    {
        this.menuClickRegion.visible = true;
        this.portalImage.visible = false;
        this.astraferBalanceCaption.visible = false;
        this.astraferBalanceText.visible = false;
        this.claimAmountText.visible = false;
        this.claimCaptionText.visible = false;
        //this.ingameTokensCollectedText.visible = false;
        this.claimButton.visible = false;
        this.goalCompletePanel.visible = false;
        this.popupMenuCollectToken.visible = false;
        this.titleBg.visible = true;
        this.audioManager.stopSound(this.soundMusic);
        if(this.globals.SHOW_DIFFICULTY_MSG)
        {
            this.currentDifficultyMenuText.visible = true;
        }
        this.timeUntilNextGame.visible = false;
        this.startGameText.visible = true;
        if(playButtonSound)
        {
            this.audioManager.playSound(this.soundButtonClick);
        }

        currentGameState = GameState.Title;
        this.soundMenuMusic.volume = this.globals.MUSIC_VOLUME;
        this.audioManager.playSound(this.soundMenuMusic);
        this.mouseClicksEnabled = false;
        this.time.addEvent({ delay: 800, callback: this.enableMouseClicks, callbackScope: this, loop: false });
        this.gameOverText.visible = false;
        this.gameOverReplayButton.visible = false;
        this.goalCompleteText.visible = false;
        this.player.visible = true;
        this.player.x = this.globals.SCREEN_CENTER_PORTRAIT_X;
        this.player.y = this.globals.SCREEN_CENTER_PORTRAIT_Y;
        if(this.globals.SHOW_SHIP_FLAMES)
        {
            this.shipFlames.visible = true;
        }
        //
    }

    shakeCamera()
    {
        if(!this.cameraShaking)
        {
            this.cameraStartPosX = this.cameras.main.x;
            this.cameraStartPosY = this.cameras.main.y;
            this.cameraShaking = true;
        }
    }
    // trigger a meteor explosion at x,y pos
    explodeMeteor(explodeX, explodeY, playSFX = true, targetScale = 1)
    {
        if(currentGameState == GameState.GamePlay || currentGameState == GameState.FlyingToPortal)
        {
            if(currentWeaponType == WeaponType.Laser)
            {
                if(playSFX)
                {
                    this.audioManager.playSoundOverlap(this,'soundExplode',1 , this.globals.EXPLODE_COOLDOWN_MILLIS);
                }
            }
            else if(currentWeaponType == WeaponType.FireBullet)
            {
                if(playSFX)
                {
                    this.audioManager.playSoundOverlap(this,'soundExplode',1 , this.globals.EXPLODE_COOLDOWN_MILLIS);
                }
            }
            else
            {
                if(playSFX)
                {
                    this.audioManager.playSoundOverlap(this,'soundExplode',1 , this.globals.EXPLODE_COOLDOWN_MILLIS);
                }
            }

            for(var i =0; i < 10; i++)
            {
                var meteorBlast = this.getMeteorBlastFromBuffer();
                meteorBlast.x = explodeX;
                meteorBlast.y = explodeY;
                meteorBlast.angle = Phaser.Math.FloatBetween(-179,179);
                var particleSpeed = Phaser.Math.FloatBetween(300,600);
                this.physics.velocityFromRotation(meteorBlast.rotation, 600, meteorBlast.body.velocity) ;
                meteorBlast.visible = true;
            }

            var currentExplodeAnim = this.getMeteorExplodeFromBuffer();
            currentExplodeAnim.x = explodeX;
            currentExplodeAnim.y = explodeY;
            currentExplodeAnim.visible = true;
            currentExplodeAnim.anims.play('blast');
            currentExplodeAnim.setScale(targetScale * 1.5);
            currentExplodeAnim.angle = Phaser.Math.FloatBetween(-179,179);

            var randValue = Phaser.Math.Between(0,10);
            var extraChanceRandValue = -1;
            var extraChanceAtPowerup = false;
            if(this.checkAnyVialsVisible())
            {
                randValue = Phaser.Math.Between(3,10);
            }
            else if(this.currentDifficulty == DifficultyLevel.Easy)
            {
                extraChanceRandValue = Phaser.Math.Between(0,8);
            }
            if((randValue == 0 || extraChanceRandValue == 0) && currentWeaponType == WeaponType.Laser && this.tokensCollected >= this.globals.MIN_TOKENS_BEFORE_POWERUPS)
            {
                // add a powerup
                var pupToAdd = this.getPowerUpMultiShotYellowFromBuffer();
                pupToAdd.x = explodeX;
                pupToAdd.y = explodeY;
                var randomXVelocity = Phaser.Math.Between(-100,100);
                var randomYVelocity = Phaser.Math.Between(-100,100);
                pupToAdd.body.setVelocity(randomXVelocity,randomYVelocity);
                pupToAdd.body.checkCollision.none = false;
                pupToAdd.visible = true;
            }
            else if((randValue == 1 || extraChanceRandValue == 1) && currentWeaponType == WeaponType.Laser && this.tokensCollected >= this.globals.MIN_TOKENS_BEFORE_POWERUPS)
            {
                // add a powerup
                var pupToAdd = this.getPowerUpMissilesRedFromBuffer();
                pupToAdd.x = explodeX;
                pupToAdd.y = explodeY;
                var randomXVelocity = Phaser.Math.Between(-100,100);
                var randomYVelocity = Phaser.Math.Between(-100,100);
                pupToAdd.body.setVelocity(randomXVelocity,randomYVelocity);
                pupToAdd.body.checkCollision.none = false;
                pupToAdd.visible = true;
            }
            else if((randValue == 2 || extraChanceRandValue == 2) && currentWeaponType == WeaponType.Laser && this.tokensCollected >= this.globals.MIN_TOKENS_BEFORE_POWERUPS)
            {
                // add a powerup
                var pupToAdd = this.getPowerUpSideShotBlueFromBuffer();
                pupToAdd.x = explodeX;
                pupToAdd.y = explodeY;
                var randomXVelocity = Phaser.Math.Between(-100,100);
                var randomYVelocity = Phaser.Math.Between(-100,100);
                pupToAdd.body.setVelocity(randomXVelocity,randomYVelocity);
                pupToAdd.body.checkCollision.none = false;
                pupToAdd.visible = true;
            }
            else if(randValue > 7)
            {
                // add a token
                var tokenToAdd = this.getTokenCollectFromBuffer();
                tokenToAdd.x = explodeX;
                tokenToAdd.y = explodeY;
                var randomXVelocity = Phaser.Math.Between(-100,100);
                var randomYVelocity = Phaser.Math.Between(-100,100);
                tokenToAdd.body.setVelocity(randomXVelocity,randomYVelocity);
                tokenToAdd.body.checkCollision.none = false;
                tokenToAdd.visible = true;
            }
            else
            {
                // do nothing
            }
        }
    }

    shootCurrentWeapon(delta)
    {
        if(currentWeaponType == WeaponType.Laser)
        {
            this.laserBulletTimer += delta;
            if(this.laserBulletTimer > this.laserBulletDelay)
            {
                this.laserBulletTimer = 0;
                var laserShoot = this.getLaserFromBuffer();
                laserShoot.visible = true;
                laserShoot.body.checkCollision.none = false;
                this.player.shootLaser(this,laserShoot);

                if(this.globals.MISSILES_ENABLED)
                {
                    this.shootMissile();
                }
                this.audioManager.playSound(this.soundLaserShoot);
            }
        }
        else if(currentWeaponType == WeaponType.FireBullet)
        {
            this.fireBulletTimer += delta;
            if(this.fireBulletTimer > this.fireBulletDelay)
            {
                this.fireBulletTimer = 0;
                var fireBullet1 = this.getFireBulletFromBuffer();
                var fireBullet2 = this.getFireBulletFromBuffer();
                var fireBullet3 = this.getFireBulletFromBuffer();
                var fireBullet4 = this.getFireBulletFromBuffer();
                var fireBullet5 = this.getFireBulletFromBuffer();
                fireBullet1.angle = this.player.angle - 50;
                fireBullet2.angle = this.player.angle - 25;
                fireBullet3.angle = this.player.angle;
                fireBullet4.angle = this.player.angle + 25;
                fireBullet5.angle = this.player.angle + 50;

                this.player.shootFireBullet(this, fireBullet1);
                this.player.shootFireBullet(this, fireBullet2);
                this.player.shootFireBullet(this, fireBullet3);
                this.player.shootFireBullet(this, fireBullet4);
                this.player.shootFireBullet(this, fireBullet5);

                this.audioManager.playSound(this.soundFireShoot);

                fireBullet1.visible = true;
                fireBullet1.body.checkCollision.none = false;

                fireBullet2.visible = true;
                fireBullet2.body.checkCollision.none = false;

                fireBullet3.visible = true;
                fireBullet3.body.checkCollision.none = false;

                fireBullet4.visible = true;
                fireBullet4.body.checkCollision.none = false;

                fireBullet5.visible = true;
                fireBullet5.body.checkCollision.none = false;

                if(this.globals.MISSILES_ENABLED)
                {
                    this.shootMissile();
                }
            }
        }
        else if(currentWeaponType == WeaponType.Missile)
        {
            this.laserBulletTimer += delta;
            if(this.laserBulletTimer > this.laserBulletDelay)
            {
                this.laserBulletTimer = 0;
                var laserShoot = this.getLaserFromBuffer();
                laserShoot.visible = true;
                laserShoot.body.checkCollision.none = false;
                this.player.shootLaser(this,laserShoot);
                this.shootMissile();
                this.audioManager.playSound(this.soundLaserShoot);
            }
        }
        else if(currentWeaponType == WeaponType.SideBullet)
        {
            this.sideBulletTimer += delta;
            if(this.sideBulletTimer > this.sideBulletDelay)
            {
                this.sideBulletTimer = 0;
                var sideBullet1 = this.getSideBulletFromBuffer();
                var sideBullet2 = this.getSideBulletFromBuffer();

                sideBullet1.angle = this.player.angle - 90;
                sideBullet2.angle = this.player.angle + 90;

                this.player.shootSideBullet(this, sideBullet1);
                this.player.shootSideBullet(this, sideBullet2);

                this.time.addEvent({ delay: 200, callback: this.hideActiveSideBullets, callbackScope: this, loop: false });

                this.audioManager.playSound(this.soundWeaponSide);
                sideBullet1.visible = true;
                sideBullet1.body.checkCollision.none = false;
                sideBullet2.visible = true;
                sideBullet2.body.checkCollision.none = false;

                if(this.globals.MISSILES_ENABLED)
                {
                    this.shootMissile();
                }
            }

            this.laserBulletTimer += delta;
            if(this.laserBulletTimer > this.laserBulletDelay)
            {
                this.laserBulletTimer = 0;
                var laserShoot = this.getLaserFromBuffer();
                laserShoot.visible = true;
                laserShoot.body.checkCollision.none = false;
                this.player.shootLaser(this,laserShoot);

                if(this.globals.MISSILES_ENABLED)
                {
                    this.shootMissile();
                }
                this.audioManager.playSound(this.soundLaserShoot);
            }
        }
    }

    hideActiveLaserBullets(laserToHide)
    {
        laserToHide.visible = false;
        laserToHide.x = this.globals.HIDDEN_X;
        laserToHide.y = this.globals.HIDDEN_X;
        laserToHide.body.checkCollision.none = true;
    }

    updateShipShadowFrames(delta)
    {
        var shipRot = this.player.angle;
        var angle0to360 = shipRot + 180;
        var numberOfFrames = 16;
        var startFrameIndex =  8; // facing west shadow (needs to be changed if we change number of frames)
        var frameSegmentSize = 360 / numberOfFrames;
        var currentFrame = angle0to360 / frameSegmentSize;
        var currentFrameInt = Math.round(currentFrame);
        currentFrameInt += startFrameIndex;
        currentFrameInt = currentFrameInt % numberOfFrames;
        this.player.setFrame(currentFrameInt);
    }

    hideAllCollectTokens()
    {
        for(var i=0; i < this.tokenCollectBuffer.length; i++)
        {
            var currentToken = this.tokenCollectBuffer[i];
            currentToken.x = this.globals.HIDDEN_X;
        }
    }

    hidePowerUpProgress()
    {
        this.powerUpDurationTimer = 0;
        this.progressRing.visible = false;
        this.iconScatter.visible = false;
        this.iconMissile.visible = false;
        this.iconSideBullet.visible = false;
    }

    hidePowerUpCannisters()
    {
        for(var i=0; i < this.powerUpsBufferYellow.length; i++)
        {
            var currentPUP = this.powerUpsBufferYellow[i];
            currentPUP.x = this.globals.HIDDEN_X;
        }
        for(var i=0; i < this.powerUpsBufferRed.length; i++)
        {
            var currentPUP = this.powerUpsBufferRed[i];
            currentPUP.x = this.globals.HIDDEN_X;
        }
        for(var i=0; i < this.powerUpsBufferBlue.length; i++)
        {
            var currentPUP = this.powerUpsBufferBlue[i];
            currentPUP.x = this.globals.HIDDEN_X;
        }
    }

    hideActiveSideBullets()
    {
        for(var i=0; i < this.sideBulletBuffer.length; i++)
        {
            if(this.sideBulletBuffer[i].body.checkCollision.none == false)
            {
                // active side bullet so kill it
                this.sideBulletBuffer[i].visible = false;
                this.sideBulletBuffer[i].body.checkCollision.none = true;
            }
        }
    }

    hideWarningLight()
    {
        this.hudHealthProgressBar.visible = false;
        this.uiRedLight.visible = false;
    }

    getMeteorBlastFromBuffer()
    {
        var meteorObj = this.meteorBlastBuffer[this.currentMeteorBlastBufferIndex];
        this.currentMeteorBlastBufferIndex++;
        if(this.currentMeteorBlastBufferIndex >= this.meteorBlastBuffer.length)
        {
            this.currentMeteorBlastBufferIndex = 0;
        }
        return meteorObj;
    }

    getTokenCollectFromBuffer()
    {
        var tokenObj = this.tokenCollectBuffer[this.currentTokenCollectBufferIndex];

        this.currentTokenCollectBufferIndex++;
        if(this.currentTokenCollectBufferIndex >= this.tokenCollectBuffer.length)
        {
            this.currentTokenCollectBufferIndex = 0;
        }
        return tokenObj;
    }

    getPowerUpMissilesRedFromBuffer()
    {
        var pupObj = this.powerUpsBufferRed[this.currentPowerUpRedBufferIndex];

        this.currentPowerUpRedBufferIndex++;
        if(this.currentPowerUpRedBufferIndex >= this.powerUpsBufferRed.length)
        {
            this.currentPowerUpRedBufferIndex = 0;
        }
        return pupObj;
    }

    getPowerUpMultiShotYellowFromBuffer()
    {
        var pupObj = this.powerUpsBufferYellow[this.currentPowerUpYellowBufferIndex];

        this.currentPowerUpYellowBufferIndex++;
        if(this.currentPowerUpYellowBufferIndex >= this.powerUpsBufferYellow.length)
        {
            this.currentPowerUpYellowBufferIndex = 0;
        }
        return pupObj;
    }

    getPowerUpSideShotBlueFromBuffer()
    {
        var pupObj = this.powerUpsBufferBlue[this.currentPowerUpBlueBufferIndex];

        this.currentPowerUpBlueBufferIndex++;
        if(this.currentPowerUpBlueBufferIndex >= this.powerUpsBufferBlue.length)
        {
            this.currentPowerUpBlueBufferIndex = 0;
        }
        return pupObj;
    }

    getLaserFromBuffer()
    {
        var laserObj = this.laserBulletBuffer[this.currentLaserBulletBufferIndex];
        this.currentLaserBulletBufferIndex++;
        if(this.currentLaserBulletBufferIndex >= this.laserBulletBuffer.length)
        {
            this.currentLaserBulletBufferIndex = 0;
        }
        return laserObj;
    }

    getFireBulletFromBuffer()
    {
        var fireBulletObj = this.fireBulletBuffer[this.currentFireBulletBufferIndex];
        this.currentFireBulletBufferIndex++;
        if(this.currentFireBulletBufferIndex >= this.fireBulletBuffer.length)
        {
            this.currentFireBulletBufferIndex = 0;
        }
        return fireBulletObj;
    }

    getSideBulletFromBuffer()
    {
        var sideBulletObj = this.sideBulletBuffer[this.currenSideBulletBufferIndex];
        this.currenSideBulletBufferIndex++;
        if(this.currenSideBulletBufferIndex >= this.sideBulletBuffer.length)
        {
            this.currenSideBulletBufferIndex = 0;
        }
        return sideBulletObj;
    }

    getMeteorExplodeFromBuffer()
    {
        var meteorBlastObj = this.meteorExplodeBuffer[this.currentMeteorExplodeBufferIndex];
        this.currentMeteorExplodeBufferIndex++;
        if(this.currentMeteorExplodeBufferIndex >= this.meteorExplodeBuffer.length)
        {
            this.currentMeteorExplodeBufferIndex = 0;
        }
        return meteorBlastObj;
    }

    getMeteorProgressFromBuffer()
    {
        var meteorProgressObj = this.meteorProgressBuffer[this.currentMeteorProgressBufferIndex];
        this.currentMeteorProgressBufferIndex++;
        if(this.currentMeteorProgressBufferIndex >= this.meteorProgressBuffer.length)
        {
            this.currentMeteorProgressBufferIndex = 0;
        }
        return meteorProgressObj;
    }

    rotatePlayerToDueNorth(delta)
    {
        //var angleToPointer = Phaser.Math.Angle.Between(this.player.x, this.player.y, this.portalImage.x, this.portalImage.y);
        var angleToPointer = Phaser.Math.DegToRad(-90);
        this.player.rotation = Phaser.Math.Angle.RotateTo(
            this.player.rotation,
            angleToPointer,
            this.ROTATION_SPEED / 7
          );

        this.physics.velocityFromRotation(
        this.player.rotation,
        this.MISSILE_MAX_SPEED / 4,
        this.player.body.velocity
        );
    }
    updateCameraShake(delta)
    {
        var cameraShakeOffsetX = Phaser.Math.Between(this.cameras.main.x - this.globals.SHAKE_AMOUNT, this.cameras.main.x + this.globals.SHAKE_AMOUNT);
        this.cameras.main.x = cameraShakeOffsetX;

        var cameraShakeOffsetY = Phaser.Math.Between(this.cameras.main.y - this.globals.SHAKE_AMOUNT, this.cameras.main.y + this.globals.SHAKE_AMOUNT);
        this.cameras.main.y = cameraShakeOffsetY;

        this.shakeTimer += delta;
        if(this.shakeTimer > this.shakeDelay)
        {
            this.shakeTimer = 0;
            this.cameraShaking = false;
            this.cameras.main.x = this.cameraStartPosX;
            this.cameras.main.y = this.cameraStartPosY;
        }
    }

    updateShipFlames(delta)
    {
        this.flameTimer += delta;
        if(this.flamesExtending)
        {
            this.shipFlames.setScale(this.shipFlames.scale + (delta/ 1000))
            if(this.shipFlames.scale >= 0.85)
            {
                this.shipFlames.scale = 0.85;
                if(this.flameTimer > this.currentFlameDelay)
                {
                    this.flamesExtending = false;
                    this.flameTimer = 0;
                    this.currentFlameDelay = Phaser.Math.Between(500, 3000);
                }

            }
        }
        else
        {
            this.shipFlames.setScale(this.shipFlames.scale - (delta / 1000))
            if(this.shipFlames.scale <= 0.75)
            {
                this.shipFlames.scale = 0.75;
                if(this.flameTimer > this.currentFlameDelay)
                {
                    this.flamesExtending = true;
                    this.flameTimer = 0;
                    this.currentFlameDelay = Phaser.Math.Between(500, 3000);
                }
            }
        }
        //this.shipFlames.setScale(Phaser.Math.FloatBetween(0.4,0.85));
    }
    updateGameElements(delta)
    {
        const { scrollX, scrollY } = this.cameras.main;
        this.starField.setTilePosition(scrollX / 8, scrollY / 8);
        //this.starField.visible = false;
        this.starFieldForeground.setTilePosition(scrollX / 4, scrollY / 4);
        this.player.update(delta, this.cursorKeys);
        this.text.x = this.player.x + 50;
        this.text.y = this.player.y - 100;

        this.updateRadar();
        this.recycleMeteors();
        this.updateMeteors(delta);
        this.updatePowerUps(delta);
        this.updateJoystickState(delta);
        this.updateMissile(delta);
        this.updateShipShadowFrames(delta);
    }
    // ******************************************************
    //
    // MAIN UPDATE LOOP
    //
    // ******************************************************
    update (time, delta)
    {
      this.events.emit('game_state', currentGameState);

        if(currentGameState == GameState.Paused)
        {
            return;
        }


        if(currentGameState == GameState.Init)
        {
            this.updateLoadingBar();
            if(TOTAL_LOADING_PROGRESS_LOCAL ==  1)
            {
                if(!this.load.isLoading())
                {
                    this.createGame();
                }
            }
            return;
        }
        //return;

        this.updateFloatingCollectText(delta);

        if(currentGameState == GameState.Title)
        {
            this.clickToStartFlashTimer += delta;
            var checkDelay = this.clickToStartFlashDelay;
            if(!this.startGameText.visible)
            {
                checkDelay /= 2;
            }
            if(this.clickToStartFlashTimer >= checkDelay)
            {
                this.clickToStartFlashTimer = 0;
                this.startGameText.visible = !this.startGameText.visible;
            }
            var pointer = this.input.activePointer;
            /*
            if (pointer.isDown && this.mouseClicksEnabled && this.startGameText.visible)
            {
                playSound(this.soundButtonClick);
                this.startGameText.visible = false;
                this.clickToStartFlashTimer = 0;
                currentGameState = GameState.HowToPlay;
                this.titleBg.visible = false;
                this.showHowToPlayMenu();
            }
            */
        }
        else if(currentGameState == GameState.MenuTransitionFadeToGame)
        {
            this.blackOverlay.alpha += delta / 2000;
            this.soundMenuMusic.volume = (1 - this.blackOverlay.alpha) * this.globals.MUSIC_VOLUME;
            if(this.blackOverlay.alpha >= 1)
            {
                this.blackOverlay.alpha = 1;
                this.howToPlayPanel.visible = false;
                this.popupMenuCollectToken.visible = false;
                this.playButton.visible = false;
                this.missionCaptionText.visible = false;
                this.startNewGame();
            }
        }
        else if(currentGameState == GameState.CollectingRewards)
        {
            if(this.tokensToEarn > 0)
            {
                this.collectRewardTimer += delta;
                if(this.collectRewardTimer >= this.collectRewardDelay)
                {
                    this.audioManager.playSoundOverlap(this, 'soundCashOut', 1);
                    this.tokensToEarn -= this.collectChunkAmt;
                    this.tokensEarnt += this.collectChunkAmt;
                    this.astraferBalanceText.text = this.currentAstraferBalance + this.tokensEarnt;

                    if(this.tokensToEarn <= 0)
                    {
                        this.tokensToEarn = 0;
                        this.time.addEvent({ delay: 600, callback: this.onFinishedCollectingRewards, callbackScope: this, loop: false });
                    }
                    this.claimAmountText.text = "COLLECTED: " + this.tokensToEarn;
                    this.collectRewardTimer = 0;
                }
            }
            /*
            else if(this.tokensToEarn == 0)
            {
                this.tokensToEarn = -1;
                this.time.addEvent({ delay: 600, callback: this.navigateToMainMenu, callbackScope: this, loop: false });
            }
            */
        }
        else if(currentGameState == GameState.FlyingToPortal)
        {
            var { scrollX, scrollY } = this.cameras.main;
            this.starField.setTilePosition(scrollX / 8, scrollY / 8);
            this.starFieldForeground.setTilePosition(scrollX / 4, scrollY / 4);

            this.portalImage.x = this.player.x;

            this.rotatePlayerToDueNorth(delta);

              /*
            if (
                Phaser.Math.Distance.Between(
                this.portalImage.x,
                this.portalImage.y,
                this.player.x,
                this.player.y
                ) < 50
            ) */
            if(true)
            {
                // reached portal
                if(this.globals.GOAL_COMPLETE_FLY_DOWN)
                {
                    this.tweens.add({
                        targets: this.goalCompleteText,
                        x: '-=0',
                        y: '+=40',
                        scaleX: '+=0.03',
                        scaleY: '+=0.03',
                        duration: 900,
                        ease: 'Sine.easeInOut',
                        repeat:0,
                        yoyo: false
                     });

                     this.portalImage.y = this.player.y + 1000;
                }
                else
                {
                    this.tweens.add({
                        targets: this.goalCompleteText,
                        x: '-=0',
                        y: '-=120',
                        scaleX: '+=0.03',
                        scaleY: '+=0.03',
                        duration: 900,
                        ease: 'Sine.easeInOut',
                        repeat:0,
                        yoyo: false
                     });

                     this.portalImage.y = this.player.y - 1000;
                }

                //this.goalCompleteText.visible = true;
                //this.cameras.main.stopFollow(this.player);


            }

            if(this.globals.GOAL_COMPLETE_FLY_DOWN)
            {
                if(this.goalCompleteText.visible)
                {
                    this.player.body.velocity.y += 5000 * delta;
                }
            }
            else
            {
                if(this.goalCompleteText.visible)
                {
                    this.player.body.velocity.y -= 5000 * delta;
                }
            }
        }

        else if(currentGameState == GameState.GameWin)
        {
            // not used
            this.rotatePlayerToDueNorth(delta);
            var alphaFadeSpeed = delta / 2500.0;
            this.goalCompletePanel.alpha += alphaFadeSpeed;
            this.popupMenuCollectToken.alpha += alphaFadeSpeed;
            this.claimButton.alpha += alphaFadeSpeed;
            this.claimAmountText.alpha += alphaFadeSpeed;
            this.claimCaptionText.alpha += alphaFadeSpeed;
            if(this.goalCompletePanel.alpha > 1)
            {
                //this.goalCompletePanel.alpha = 1;
            }

        }
        else if(currentGameState == GameState.GameOver)
        {
            scrollX += delta / 5;
            scrollY += delta / 5;
            this.starField.setTilePosition(scrollX / 8, scrollY / 8);
            this.starFieldForeground.setTilePosition(scrollX / 4, scrollY / 4);

            var pointer = this.input.activePointer;
            if (pointer.isDown && this.mouseClicksEnabled) {
                //this.navigateToMainMenu();
            }
        }
        else if(currentGameState == GameState.HowToPlay)
        {
            // not used
            scrollX += delta / 5;
            scrollY += delta / 5;
            this.starField.setTilePosition(scrollX / 8, scrollY / 8);
            this.starFieldForeground.setTilePosition(scrollX / 4, scrollY / 4);
        }
        else
        {
            if(this.blackOverlay.visible)
            {
                this.blackOverlay.alpha -= delta / 300;
                if(this.blackOverlay.alpha <= 0)
                {
                    this.blackOverlay.alpha = 0;
                    this.blackOverlay.visible = false;
                }
            }
            if(this.healthCounter < 5 || this.globals.TEST_HEALTH_WARNING)
            {
                this.healthWarningTtimer += delta;
                if(this.healthWarningTtimer > this.healthWarningDelay)
                {
                    this.healthWarningTtimer = 0;
                    this.audioManager.playSound(this.soundWarning);
                    //this.hudPowerBar.setFrame(16);
                    this.uiRedLight.visible = true;
                    this.hudHealthProgressBar.setTexture("healthProgressBarRed");
                    this.hudHealthProgressBar.visible = true;
                    this.time.addEvent({ delay: 800, callback: this.hideWarningLight, callbackScope: this, loop: false });
                    // play warning
                }
                else if(this.healthWarningTtimer > this.healthWarningDelay /3)
                {
                    this.updateHealthBar();
                }
            }
            this.spawnRandomMeteor();
            if(this.globals.SHOW_SHIP_FLAMES)
            {
                this.updateShipFlames(delta);
            }



            this.frameCounter ++;
            if(this.globals.DEBUG_METEOR_IDS)
            {
                for(var i=0; i < this.meteorBuffer.length; i++)
                {
                    this.meteorDebugTexts[i].x = this.meteorBuffer[i].x;
                    this.meteorDebugTexts[i].y = this.meteorBuffer[i].y;

                    this.meteorDebugTexts[i].text = "meteor_" + i + " visible:" + this.meteorDebugTexts[i].visible;
                }
            }
            for(var i=0; i < this.laserBulletBuffer.length; i++)
            {
                if(!this.cameras.main.worldView.contains(this.laserBulletBuffer[i].x, this.laserBulletBuffer[i].y))
                {
                    this.laserBulletBuffer[i].body.velocity.x = 0;
                    this.laserBulletBuffer[i].body.velocity.y = 0;
                    this.laserBulletBuffer[i].x = this.globals.HIDDEN_X;
                    this.laserBulletBuffer[i].y = this.globals.HIDDEN_X;
                    this.laserBulletBuffer[i].body.checkCollision.none = true;
                }
            }

            for(var i=0; i < this.fireBulletBuffer.length; i++)
            {
                if(!this.cameras.main.worldView.contains(this.fireBulletBuffer[i].x, this.fireBulletBuffer[i].y))
                {
                    this.fireBulletBuffer[i].body.velocity.x = 0;
                    this.fireBulletBuffer[i].body.velocity.y = 0;
                    this.fireBulletBuffer[i].x = this.globals.HIDDEN_X;
                    this.fireBulletBuffer [i].y = this.globals.HIDDEN_X;
                }
            }

            for(var i=0; i < this.sideBulletBuffer.length; i++)
            {
                if(!this.cameras.main.worldView.contains(this.sideBulletBuffer[i].x, this.sideBulletBuffer[i].y))
                {
                    this.sideBulletBuffer[i].body.velocity.x = 0;
                    this.sideBulletBuffer[i].body.velocity.y = 0;
                    this.sideBulletBuffer[i].x = this.globals.HIDDEN_X;
                    this.sideBulletBuffer [i].y = this.globals.HIDDEN_X;
                }
            }

            this.updateGameElements(delta);
        }
    }
}

function loadFont(name, url) {
    var newFont = new FontFace(name, `url(${url})`);
    newFont.load().then(function (loaded) {
        document.fonts.add(loaded);
    }).catch(function (error) {
        return error;
    });
}


const GameState = {
    Init: 0,
	Title: 1,
	Menu: 2,
	GamePlay: 3,
    MenuTransitionFadeToGame: 4,
	GameOver: 6,
    FlyingToPortal: 7,
    GameWin: 8,
    CollectingRewards: 9,
    HowToPlay: 10,
    Paused: 11,
    Quit: 12
}

var ID_COUNTER = 0;
var currentWeaponType = WeaponType.Laser;
var currentGameState = GameState.Init;

var START_SPEED = 1100;
//var START_ROTATION_SPEED = 0.75 * Phaser.Math.PI2;
var SPEED = 800;



// Helper function to create new meteors
function addMeteor(scene, xPos, yPos, img, meteorType)
{
    var theMeteor = scene.add.existing(new Meteor(scene, xPos,yPos,img));
    scene.add.existing(theMeteor);
    scene.physics.add.existing(theMeteor);
    theMeteor.body.setSize(124, 124, true);
    var meteorType = Phaser.Math.Between(0,2);
    var meteorFrameType = Phaser.Math.Between(1,2);
    var meteorStartFramerate = Phaser.Math.Between(28, 30);//18,24);
    meteorStartFramerate = 30;
    if(meteorFrameType == 1)
    {
        // meteor type 1 is smoother / can handle lower framerates than meteor 2
        meteorStartFramerate = Phaser.Math.Between(18, 24);
    }

    theMeteor.anims.create({
        key: "spin",
        frameRate: meteorStartFramerate,
        frames: scene.anims.generateFrameNumbers("meteor_spinning_" + meteorFrameType, { start: 0, end: 58 }),
        repeat: -1
    });

    var meteorScale = 0.7;
    theMeteor.meteorType = meteorType;

    if(meteorType == MeteorType.Small)
    {
        meteorScale = Phaser.Math.FloatBetween(0.5, 0.6);
        theMeteor.hitCount = 1;
        theMeteor.totalHitCount = 1;
    }
    else if(meteorType == MeteorType.Medium)
    {
        meteorScale = Phaser.Math.FloatBetween(0.7, 0.9);
        theMeteor.hitCount = 2;
        theMeteor.totalHitCount = 2;
    }
    else if(meteorType == MeteorType.Large)
    {
        meteorScale = Phaser.Math.FloatBetween(1.0, 1.2);
        theMeteor.hitCount = 3;
        theMeteor.totalHitCount = 3;
    }
    theMeteor.setScale(meteorScale);
    var meteorStartFrame = Phaser.Math.Between(1,15);
    theMeteor.setFrame(meteorStartFrame);
    theMeteor.anims.play('spin');
    return theMeteor;
}

// Phaser Config / Setup
const configLandscape = {
    type: Phaser.AUTO,
    parent: 'phaser',
    width: 2250,
    height: 1404,
    backgroundColor: '#131313',


    scale: {
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_HORIZONTALLY
    },

    physics: {
        default: 'arcade',
        arcade: {
            debug: false
        }
    },


    scene: Main
};

const configPortrait = {
    type: Phaser.AUTO,
    parent: 'phaser',
    width: 540,
    height: 920,
    backgroundColor: '#000000',

    scale: {
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_HORIZONTALLY },
    physics: {
        default: 'arcade',
        arcade: {
            debug: false
        }
    },
    scene: Main
};




