import React from "react";
import "./SalvageTheProgressTracker.scss";
import {frame_all_nft} from "../../../assets/images/CarouselModal";
import {
  ReactComponent as LeftParachute
} from "../../../assets/images/CarouselModal/drop-parachute-left.svg";
import {
  ReactComponent as RightTopSmallParachute
} from "../../../assets/images/CarouselModal/right-top-small-parachute.svg";
import {
  ReactComponent as RightBottomBigParachute
} from "../../../assets/images/CarouselModal/right-bottom-big-parachute.svg";
import CarouselModalButton from "../CarouselModalButton/CarouselModalButton";
import {carouselText} from "../../../utils/GeneralUtils";

export default function SalvageTheProgressTracker() {


  return (<>
    <div className={"salvage-carousel-main-container"}>
      <div className={"header-title-salvage-carousel"}>
        <div className={"salvage-carousel-title"}>SALVAGE THE</div>
        <div className={"salvage-carousel-second-title"}>PROGRESS TRACKER</div>
        <div className={"salvage-carousel-second-title-mobile"}>PROGRESS</div>
        <div className={"salvage-carousel-third-title-mobile"}>TRACKER</div>
      </div>

      <div className={"salvage-drop-container"}>
        <LeftParachute className={"left-parachute"}/>
        <div className={"main-salvage-drop-container"}>
          <img src={frame_all_nft} alt={""} className={"adjust-salvage-main-drop-img"}/>
        </div>
        <RightTopSmallParachute className={"right-top-small-parachute"}/>
        <RightBottomBigParachute className={"right-bottom-big-parachute"}/>
      </div>
      <div className={"airdropped-button-container"}>
        <div className={"airdropped-content-container"}>
          <CarouselModalButton text={'AIRDROPPED'}/>
        </div>
      </div>
      <div className={"salvage-container-content-text"}>{carouselText.paragraph4}</div>
    </div>
  </>)
}
