import React, { useEffect, useReducer } from 'react';
import GenericButton from 'components/Button/GenericButton';
import '../Vault/VaultAstrafer.scss';
import AstraferAbi from '@fugu/base-contracts/dist/abis/Tokens/Astrafer.ERC20.sol/Astrafer.json';
import PGModal from '../Common/PGModal/PGModal';
import LoadingSpinner from '../LoadingSpinner';
import { astrafer_icon, caret_down, checkbox_checked, exclamation_icon } from '../../assets';
import SuccessMessage from '../Common/SuccessMessage/SuccessMessage';
import { ContractInterface, ethers } from 'ethers';
import { sendTokenCrossChains } from '../../utils/ContractUtils';
import { useAccount, useChainId } from 'wagmi';
import { switchNetwork, SwitchNetworkResult } from '@wagmi/core';
import { ethereumId, layerZeroConfig, polygonId } from '../../utils/Globals';
import './TokenBridgeContent.scss';
import { useProvider } from '../../hooks/useProvider';
import { initialState, tokenBridgeReducer } from './tokenBridgeReducer';
import {
  setErrorModalText,
  setIsTransactionLoading,
  setIsTransactionSuccessful,
  setReceiveAddedTokenBalance,
  setReceiveChainConfig,
  setSendChainConfig,
  setSendInputAmount,
  setSendShowModal,
  setSendSubtractedTokenBalance,
  setShowErrorModal,
  setTransactionHash,
} from './tokenBridgeActions';
import { ChainConfig, ChainId, LayerZeroConfig, TokenList } from './tokenBridgeTypes';
import { useReceiveChainBalance } from './useReceiveChainBalance';
import { useSendChainLogic } from './useSendChainLogic';
import { TokenInputForm } from '../TokenForm/TokenInputForm';
import { TokenNetworkForm, TokenNetworkFormType } from '../TokenForm/TokenNetworkForm';

const polygonIdInt = Number(polygonId);
const ethereumIdInt = Number(ethereumId);
export const config: LayerZeroConfig = layerZeroConfig;

export const getChainConfig = (chainId: number): ChainConfig & ChainId => {
  return { ...config[chainId], chainId: chainId };
};

export const getContractAddress = (chainId: number): string => {
  let contractAddress = config[chainId].proxyAddress;

  if (contractAddress && chainId === Number(polygonId)) {
    contractAddress = config[chainId].astraferAddress;
  }

  return contractAddress;
};

export const createContract = (chainId: number, contractAddress: string) => {
  const provider = new ethers.providers.JsonRpcProvider(config[chainId].rpcUrl);
  return new ethers.Contract(contractAddress, AstraferAbi.abi as unknown as ContractInterface, provider);
};

export const getOtherChain = async (currentChainId: number): Promise<number> => {
  let receivingChainId: number;

  // Toggle the receivingChainId based on the currentChainId
  if (currentChainId === polygonIdInt) {
    receivingChainId = ethereumIdInt;
  } else if (currentChainId === ethereumIdInt) {
    receivingChainId = polygonIdInt;
  } else {
    // Handle the scenario where currentChainId is neither 43113 nor 97
    throw new Error('Unsupported currentChainId');
  }

  return receivingChainId;
};

export const getIsChainIdSupported = (chainId: number) => {
  return Object.keys(layerZeroConfig).includes(chainId.toString());
};

// test token list
export const tokenList: TokenList = {
  astrafer: {
    name: 'Astrafer',
    logo: astrafer_icon,
    logoAltText: 'Astrafer logo',
  },
};

export const tokenOptionCount = (tokens: TokenList) => {
  return Object.keys(tokens).length;
};
export const networkOptionCount = (config: LayerZeroConfig | undefined) => {
  if (!config) return;
  return Object.keys(config).length;
};

export default function TokenBridgeContent() {
  const provider = useProvider();
  const currentChainId = useChainId();
  const { address, connector } = useAccount();

  const [state, dispatch] = useReducer(tokenBridgeReducer, initialState);

  const {
    chainConfig: sendChainConfig,
    tokenInfo: sendTokenInfo,
    isChainIdSupported,
    tokenBalance: sendTokenBalance,
    inputAmount: sendInputAmount,
    isBalanceLoading: isSendBalanceLoading,
  } = state.send;

  const { chainConfig: receiveChainConfig, tokenBalance: receiveTokenBalance, isBalanceLoading: isReceiveBalanceLoading } = state.receive;

  const { showSendModal, isTransactionLoading, isTransactionSuccessful, transactionHash, showErrorModal, errorModalText } = state;

  useReceiveChainBalance(address, receiveChainConfig, dispatch);
  useSendChainLogic(address, sendChainConfig, provider, dispatch);

  useEffect(() => {
    // set the send form network to the network selected in wallet
    if (currentChainId === Number(polygonId) || currentChainId === Number(ethereumId)) {
      const newConfig = getChainConfig(currentChainId);
      dispatch(setSendChainConfig(newConfig));
    }
  }, [currentChainId]);

  const promptSendCrossChainTransaction = () => {
    dispatch(setSendShowModal(true));
  };

  const SendCrossChain = async () => {
    if (currentChainId === receiveChainConfig.chainId) return;

    dispatch(setIsTransactionLoading(true));

    try {
      const txHash = await sendTokenCrossChains(
        sendChainConfig.chainId,
        receiveChainConfig.chainId,
        address,
        ethers.utils.parseEther((sendInputAmount ?? 0).toString()),
        provider,
      );

      dispatch(setTransactionHash(txHash));
      dispatch(setIsTransactionLoading(false));
      dispatch(setSendSubtractedTokenBalance(sendInputAmount ?? 0));
      dispatch(setReceiveAddedTokenBalance(sendInputAmount ?? 0));

      // only set the success message if modal is true (user may exit modal during transaction)
      if (showSendModal) dispatch(setIsTransactionSuccessful(true));
    } catch (e: any) {
      // Metamask error transaction still goes through
      if (e.message.includes('JsonRpcEngine: Response has no error or result for request:')) {
        const scannerURL = sendChainConfig.etherscanUrl.replace('/tx/', '/address/') + address;
        dispatch(setTransactionHash(scannerURL));
        dispatch(setIsTransactionLoading(false));
        dispatch(setSendSubtractedTokenBalance(sendInputAmount ?? 0));
        dispatch(setReceiveAddedTokenBalance(sendInputAmount ?? 0));
        if (showSendModal) dispatch(setIsTransactionSuccessful(true));
      } else {
        dispatch(setIsTransactionLoading(false));
        dispatch(setSendShowModal(false));
        dispatch(setErrorModalText('Something went wrong, please try again later.'));
        dispatch(setShowErrorModal(true));
      }
    }
  };

  const handleSendInputChange = (event: React.FormEvent<HTMLElement>) => {
    const target = event.target as HTMLInputElement;
    const result = target.value.replace(/\D/g, '');
    dispatch(setSendInputAmount(parseInt(result)));
  };

  /**
   * Process and handle the network switch errors.
   *
   * Only display error modal when wallet cannot be found.
   * */
  const processNetworkSwitch = async (chainId: number): Promise<SwitchNetworkResult | undefined> => {
    let result;

    try {
      result = await switchNetwork({ chainId });
    } catch (e: any) {
      if (e.message === 'Connector not found') {
        dispatch(setErrorModalText('Unable to locate network, please check if wallet is connected.'));
        dispatch(setShowErrorModal(true));
      }
      console.error(e);
    }

    return result;
  };

  const handleNetworkSwitch = async (chainId: number, formType: TokenNetworkFormType) => {
    const newChainConfig = getChainConfig(chainId);

    if (formType === TokenNetworkFormType.Send) {
      const result = await processNetworkSwitch(chainId);
      if (result) {
        dispatch(setSendChainConfig(newChainConfig));
      }
    }
    if (formType === TokenNetworkFormType.Receive) {
      dispatch(setReceiveChainConfig(newChainConfig));
    }
  };

  const handleMaxInput = () => {
    dispatch(setSendInputAmount(sendTokenBalance));
  };

  const BlockchainExplorerLink = () => (
    <a className={'token-bridge__success-link'} href={transactionHash} target={'_blank'} rel={'noreferrer nofollow'}>
      View transaction details
    </a>
  );

  const handleErrorModalClose = () => {
    dispatch(setShowErrorModal(false));

    // TODO: can we do this without reloading page using the wagmi hooks?
    // reloading page will attempt to find the connected wallet
    if (!connector) {
      window.location.reload();
    }
  };

  const getValidReceiveNetworkOptions = (config: LayerZeroConfig, sendChainConfig: ChainConfig & ChainId): LayerZeroConfig => {
    return Object.entries(config)
      .filter(([_, value]) => value.name !== sendChainConfig.name)
      .reduce((acc, [key, value]) => {
        acc[Number(key)] = value;
        return acc;
      }, {} as LayerZeroConfig);
  };

  return (
    <>
      <div className={'bridge-container'}>
        <TokenInputForm
          formType={TokenNetworkFormType.Send}
          tokenInfo={sendTokenInfo}
          tokenOptions={tokenList}
          amount={sendInputAmount ?? 0}
          walletBalance={sendTokenBalance}
          handleInputChange={event => handleSendInputChange(event)}
          isBalanceLoading={isSendBalanceLoading}
          handleMaxInput={handleMaxInput}
        />
        <div className={'token-bridge-network__container'}>
          <TokenNetworkForm
            walletBalance={sendTokenBalance}
            formType={TokenNetworkFormType.Send}
            networkName={sendChainConfig.name}
            currentChainId={currentChainId}
            networkOptions={config}
            handleNetworkSwitch={handleNetworkSwitch}
            networkIcon={sendChainConfig.imageSrc}
            isBalanceLoading={isSendBalanceLoading}
          />
          <div className={'token-bridge-form__divider'}>
            <img className={'token-bridge-form__arrow very-faded'} src={caret_down} alt={'arrow pointing right'} />
            <img className={'token-bridge-form__arrow faded'} src={caret_down} alt={'arrow pointing right'} />
            <img className={'token-bridge-form__arrow opaque'} src={caret_down} alt={'arrow pointing right'} />
          </div>
          <TokenNetworkForm
            walletBalance={receiveTokenBalance}
            formType={TokenNetworkFormType.Receive}
            networkName={receiveChainConfig.name}
            currentChainId={receiveChainConfig.chainId}
            networkOptions={getValidReceiveNetworkOptions(config, sendChainConfig)}
            handleNetworkSwitch={handleNetworkSwitch}
            networkIcon={receiveChainConfig.imageSrc}
            isBalanceLoading={isReceiveBalanceLoading}
          />
        </div>
        {(sendInputAmount ?? 0) >= 10 && (sendInputAmount ?? 0) > sendTokenBalance && (
          <p className={'token-bridge__error-text'}>Insufficient Astrafer to send</p>
        )}
        {!isChainIdSupported && <p className={'token-bridge__error-text'}>Please choose a network to send tokens from</p>}
        <GenericButton
          handleClick={() => promptSendCrossChainTransaction()}
          buttonText="Transfer"
          disabled={(sendInputAmount ?? 0) > sendTokenBalance || !isChainIdSupported || currentChainId === receiveChainConfig.chainId}
          buttonClass={'token-bridge__send-button'}
        />
      </div>
      <PGModal
        show={showSendModal}
        onHide={() => {
          dispatch(setSendShowModal(false));
          dispatch(setIsTransactionSuccessful(false));
        }}
        // The prompt message will display for a brief moment if we set isBoundSuccess to false as the modal is closed
        // This ensures the success message will persist until the modal has exited completely
        onExited={() => dispatch(setIsTransactionSuccessful(false))}
        bodyClassName={'token-bridge__modal-text-container'}
        backdrop={'static'}
        preventModalClose={isTransactionLoading}
      >
        {isTransactionLoading && (
          <>
            <p>Please check your connected wallet for any transactions that need to be approved. </p>
            <LoadingSpinner marginBottom={'16px'} />
          </>
        )}
        {!isTransactionLoading && !isTransactionSuccessful && (
          <>
            <p>
              You are sending {sendInputAmount} ASTRAFER to wallet address {address} on the {receiveChainConfig.name} chain.
            </p>
            {currentChainId === polygonIdInt && <p>Note: This requires 2 transactions</p>}
            <p>Do you wish to proceed?</p>
            <GenericButton handleClick={() => SendCrossChain()} buttonText={'YES'} buttonClass="planet-dashboard__modal-button" />
          </>
        )}
        {isTransactionSuccessful && (
          <>
            <img alt="a tick inside of circle" src={checkbox_checked} className="planet-dashboard__modal-success-icon" />
            <SuccessMessage message={`Congratulations ranger, your request has been processed.`}>
              <BlockchainExplorerLink />
            </SuccessMessage>
            <GenericButton handleClick={() => dispatch(setSendShowModal(false))} buttonText="close" buttonClass="planet-dashboard__modal-button" />
          </>
        )}
      </PGModal>
      <PGModal show={showErrorModal} onHide={handleErrorModalClose} onExited={handleErrorModalClose} bodyClassName={'token-bridge__error-modal-body'}>
        <img alt={'exclamation icon'} className={'token-bridge__error-modal-icon'} src={exclamation_icon} />
        <p className={'token-bridge__error-modal-title'}>Error</p>
        <p className={'token-bridge__error-modal-text'}>{errorModalText}</p>
      </PGModal>
    </>
  );
}
