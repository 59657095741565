import React, {useCallback, useEffect, useState} from "react";
import "./LeaderBoardSection.scss";
import {
  loading_hexagon,
} from "../../assets/images/LeaderBoard";
import PageHeader from "../PageHeader";
import LeaderBoardRow from "../LeaderBoardRow/LeaderBoardRow";
import SimpleSpecialRow from "../LeaderBoardRow/SimpleSpecialRow/SimpleSpecialRow";
import LeaderBoardSpecialRow from "../LeaderBoardRow/LeaderBoardSpecialRow/LeaderBoardSpecialRow";
import {boarder_detail_bottom, boarder_detail_top} from "../../assets/images/AstrafiteRushEvent";
import {ReactComponent as ScrollDownSvg} from "../../assets/images/LeaderBoard/scroll-down-arrow.svg";
import {
  getCycleData,
  getLeaderBoardSection,
  getMiniLeaderBoard,
  getTermsAndConditionStatus
} from "../../utils/GeneralApi";
import LoadingSpinner from "../LoadingSpinner";
import ErrorModal from "../Modal/ErrorModal/ErrorModal";
import {errorText, leaderBoardNoData} from "../../utils/GeneralUtils";
import {walletContext} from "../../utils/WalletContext";
import TermsAndConditionsModal from "../Modal/TermsAndConditionsModal/TermsAndConditionsModal";

export default function LeaderBoardSection({cyclePage}) {

  const [listToLoad, setListToLoad] = useState([]);
  const [player, setPlayer] = useState();
  const [cycleData, setCycleData] = useState();
  const [changeToLoading, setChangeToLoading] = useState(false);
  const [alwaysTheLastToken, setAlwaysTheLastToken] = useState();
  const [fullLeaderboardList, setFullLeaderboardList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [termsAndConditionAgreement, setTermsAndConditionAgreement] = useState(false);
  const [isLoading, setIsLoading] = useState(true)

  const [showTable, setShowTable] = useState({showTable: false, showErrorModal: false});

  function pushElementsOnList(listOfElements, elements) {
    for (let index = 0; index < elements.length; index++) {
      listOfElements.push(elements[index]);
    }
  }

  useEffect(() => {
    if (sessionStorage.getItem('tc.agree') === null) {
      const getTC = async () => {
        try {
          let response = await getTermsAndConditionStatus().then();
          setTermsAndConditionAgreement(response.isAccepted)
          sessionStorage.setItem('tc.agree', response.isAccepted)
          setIsLoading(false)
        } catch (e) {
          console.error(e)
          setShowTable(prevState => ({
            ...prevState,
            ...{showErrorModal: true}
          }));
        }
      }
      getTC().then()
    } else {
      let tcResponse = sessionStorage.getItem('tc.agree');
      if(tcResponse === 'true'){
        setTermsAndConditionAgreement(true)
      }
      if(tcResponse==='false'){
        setTermsAndConditionAgreement(false)
      }
      setIsLoading(false)
    }
  },[])

  function checkCycleElement(response, cyclePage) {
    if (response !== undefined && response !== null && Object.keys(response).length > 0) {
      let selectedCycle = response.cycles.find(element => {
        return element.cycleId === parseInt(cyclePage);
      });
      if (selectedCycle.startDate === undefined) {
        throw new Error("'startDate' element do not exist");
      }
      if (selectedCycle.targetPirates === undefined) {
        throw new Error("'targetPirates' element do not exist");
      }
      if (selectedCycle.targetBlocks === undefined) {
        throw new Error("'targetBlocks' element do not exist");
      }
    }
  }

  function checkForPlayer(player) {
    if (player === undefined || player === null || Object.keys(player).length < 1) {
      return {

        "walletAddress": walletContext.currentWallet,
        "rank": "-",
        "epsilon": "-",
        "astrafiteBlocksMined": "-",
        "piratesKilled": "-",
        "progress": "-",
        "timestamp": "-"

      }
    } else {
      return player;
    }
  }

  const callPageElements = useCallback(async (lastToken) => {
    let response;
    let itemsElementList = [];

    response = await getLeaderBoardSection(cyclePage, lastToken);
    if (Object.keys(response).length < 1) {
      return []
    }
    pushElementsOnList(itemsElementList, response.items);
    if (response.pageToken !== undefined && response.pageToken !== null && response.pageToken.length >= 30) {
      setAlwaysTheLastToken(response.pageToken);
    } else {
      setAlwaysTheLastToken("");
    }
    processLeaderboardFullList(itemsElementList);
    return itemsElementList;
  }, [cyclePage]);

  useEffect(() => {

    const leaderboardData = async () => {
      try {
        let response = await getCycleData();
        checkCycleElement(response, cyclePage);
        setCycleData(response);
        let miniLeaderboard = await getMiniLeaderBoard(cyclePage);
        setPlayer(checkForPlayer(miniLeaderboard.player));
        await callPageElements("").then(r => {
          setListToLoad(r);
        });
        setShowTable(prevState => ({
          ...prevState,
          ...{showTable: true}
        }));
      } catch (e) {
        console.error(e);
        setShowTable(prevState => ({
          ...prevState,
          ...{showErrorModal: true}
        }));
      }
    }

    if (sessionStorage.getItem("pg.wallet") === null) {
      sessionStorage.setItem("pg.wallet", localStorage.getItem("pg.wallet"));
      localStorage.removeItem("pg.wallet");
      window.location.reload(false);
    }
    if (sessionStorage.getItem("pg.wallet") !== null && sessionStorage.getItem("pg.wallet") !== undefined) {
      leaderboardData().then();
    }
  }, [cyclePage, callPageElements]);


  function findCycle(cyclePage, cycleData) {
    if (cycleData === undefined || cycleData === null || Object.keys(cycleData).length < 1) {
      return "-";
    } else {

      let selectedCycle = cycleData.cycles.find(element => {
        return element.cycleId === parseInt(cyclePage);
      });
      return new Date(selectedCycle.startDate).valueOf();

    }
  }

  function findCycleEndDate(cyclePage, cycleData) {
    if (cycleData === undefined || cycleData === null || Object.keys(cycleData).length < 1) {
      return "-";
    } else {

      let selectedCycle = cycleData.cycles.find(element => {
        return element.cycleId === parseInt(cyclePage);
      });
      return new Date(selectedCycle.endDate).valueOf();
    }
  }

  function targetBlocks(cyclePage, cycleData) {
    if (cycleData === undefined || cycleData === null || Object.keys(cycleData).length < 1) {
      return "-";
    } else {

      let selectedCycle = cycleData.cycles.find(element => {
        return element.cycleId === parseInt(cyclePage);
      });
      return selectedCycle.targetBlocks;

    }
  }

  function fireDancersDefeated(cyclePage, cycleData) {
    if (cycleData === undefined || cycleData === null || Object.keys(cycleData).length < 1) {
      return "-";
    } else {

      let selectedCycle = cycleData.cycles.find(element => {
        return element.cycleId === parseInt(cyclePage);
      });
      return selectedCycle.targetPirates;

    }
  }


  function timeConvertor(endTime, completion, cycleStart, cycleEndDate) {

    let day;
    let hours;
    let minutes;
    let seconds;
    let now = new Date().getTime();
    let endCycleDistance = cycleEndDate - now;

    if (endTime === "-") {
      return "--:--:--";
    }

    if (cycleStart === "-" || cycleStart === undefined || isNaN(cycleStart) || isNaN(endTime) || endTime === null) {
      if (endCycleDistance < 0) {
        return "DNF";
      } else {
        return "--:--:--";
      }
    }
    if (completion < 100) {

      if (endCycleDistance < 0) {
        return "DNF";
      } else {
        return "--:--:--";
      }
    } else {
      let distance = new Date(endTime).valueOf() - cycleStart;
      if (distance < 0) {
        return "--:--:--";
      } else {
        day = Math.floor(distance / (1000 * 60 * 60 * 24));
        hours = (Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        hours = (day * 24) + hours
        minutes = (Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
        seconds = (Math.floor((distance % (1000 * 60)) / 1000));

        return fillTheValue(hours) + ":" + fillTheValue(minutes) + ":" + fillTheValue(seconds);
      }
    }
  }

  function fillTheValue(number) {
    if (number > 9) {
      return number;
    } else {
      return "0" + number;
    }
  }

  function hideErrorModal() {
    setShowTable(prevState => ({
      ...prevState,
      ...{showErrorModal: false}
    }));
    setChangeToLoading(false);
  }


  const processLeaderboardFullList = (itemsElementList) => {
    setFullLeaderboardList(current => [...current, itemsElementList]);
  }

  const goToNextPage = async () => {
    try {
      if (currentPage + 1 < fullLeaderboardList.length) {
        setListToLoad(fullLeaderboardList[currentPage + 1]);
      } else {
        setChangeToLoading(true);
        await callPageElements(alwaysTheLastToken).then(r => {
          setListToLoad(r);
          setChangeToLoading(false);
        });
      }
      setCurrentPage(currentPage + 1);
    } catch (e) {
      console.error(e);
      setShowTable(prevState => ({
        ...prevState,
        ...{showErrorModal: true}
      }));
    }


  }

  const prevButtonStatus = (pageNumber, loadingStatus) => {
    if (loadingStatus) {
      return false;
    } else {
      return pageNumber >= 2;
    }
  }
  const nextButtonStatus = (pageNumber, loadingStatus, fullListLength, lastToken) => {

    if (loadingStatus) {
      return false;
    }
    if (pageNumber < fullListLength) {
      return true;
    }
    if (lastToken !== undefined && lastToken !== null && lastToken !== "") {
      return true;
    }
  }

  const goToPrevPage = () => {

    setListToLoad(fullLeaderboardList[currentPage - 1]);
    setCurrentPage(currentPage - 1);
  }

  return (<>
    <div className={"main-leaderboard-container"}>

      <div className={"leader-board-page-background"}/>

      {showTable.showErrorModal &&
        < ErrorModal onHide={() => hideErrorModal()}
                     text={errorText.defaultError}
                     errorType={'defaultError'}/>
      }
      {!termsAndConditionAgreement && !isLoading ? <><TermsAndConditionsModal visible={true} setTerms={setTermsAndConditionAgreement}/></>
        : <></>}
      <div className={"leaderboard-header-distance"}>

        <div className={"leader-board-section-title"}>
          <PageHeader headerText={`CYCLE ${cyclePage} LEADERBOARD`} classname="page-mobile-header-text-no-margin"/>
        </div>


        {showTable.showTable && termsAndConditionAgreement ?
          <div className={"leaderboard-page-container-alignment"}>
            <div className={"content-of-the-leaderboard-page"}>
              <div className={"leader-board-page-table-background"}/>

              <div className={"leader-board-ornament"}>
                <div className={"leader-board-ornament-width"}>
                  <div className={"line-separator"}/>
                </div>
                <img alt={""} src={boarder_detail_top} className={"leader-board-decoration-crown-top"}/>
              </div>

              <div className={"leaderboard-background-control"}>
                <>
                  <LeaderBoardSpecialRow rank={player.rank}
                                         player={player.walletAddress}
                                         piratesKilled={player.piratesKilled}
                                         astrafiteBlocksMined={player.astrafiteBlocksMined}
                                         completion={player.progress}
                                         time={timeConvertor(player.timestamp, player.progress, findCycle(cyclePage, cycleData), findCycleEndDate(cyclePage, cycleData))}
                                         fireDancersDefeated={fireDancersDefeated(cyclePage, cycleData)}
                                         targetBlocks={targetBlocks(cyclePage, cycleData)}/>
                </>

                {listToLoad.length > 0 &&
                  <div className={"leader-board-page-table-header-container"}>
                    <div className={"center-the-elements"}>
                      <p className={"rank-header-element"}>RANK</p>
                    </div>
                    <div className={"center-the-elements"}>
                      <p className={"player-header-element"}>PLAYER</p>
                    </div>
                    <div className={"center-the-elements"}>
                      <p className={"completion-header-element"}>COMPLETION</p>
                    </div>
                    <div className={"center-the-elements"}>
                      <p className={"time-header-element"}>TIME</p>
                    </div>
                  </div>}


                <div className={"leader-board-page-row-container"}>
                  {listToLoad.length > 0 ? listToLoad.map((data, index) => <>
                    <div>
                      <LeaderBoardRow rank={data.rank} key={data.walletAddress} player={data.walletAddress}
                                      piratesKilled={data.piratesKilled}
                                      astrafiteBlocksMined={data.astrafiteBlocksMined}
                                      completion={data.progress}
                                      time={timeConvertor(data.timestamp, data.progress, findCycle(cyclePage, cycleData), findCycleEndDate(cyclePage, cycleData))}
                                      playerRank={player.rank}
                                      index={index} targetBlocks={targetBlocks(cyclePage, cycleData)}
                                      fireDancersDefeated={fireDancersDefeated(cyclePage, cycleData)}>
                        <SimpleSpecialRow rank={data.rank} key={data.walletAddress} player={data.walletAddress}
                                          completion={data.progress}
                                          piratesKilled={data.piratesKilled}
                                          astrafiteBlocksMined={data.astrafiteBlocksMined}
                                          targetBlocks={targetBlocks(cyclePage, cycleData)}
                                          fireDancersDefeated={fireDancersDefeated(cyclePage, cycleData)}
                                          time={timeConvertor(data.timestamp, data.progress, findCycle(cyclePage, cycleData), findCycleEndDate(cyclePage, cycleData))}
                                          index={index}/>
                      </LeaderBoardRow>
                      {listToLoad.length - 1 > index && <div className={"leader-bord-margin-box"}/>}
                    </div>
                  </>) : <>
                    <div className={"leaderboard-page-no-data-main-container"}>
                      <div> {leaderBoardNoData.paragraph1}</div>
                      <div className={"please-check-again-later"}>  {leaderBoardNoData.paragraph2}</div>
                    </div>
                  </>}
                </div>

                {listToLoad.length > 0 &&
                  <div className={"center-container-button"}>
                    <ScrollDownSvg
                      className={prevButtonStatus(currentPage + 1, changeToLoading) ? "prev-page active-pagination-button" : "prev-page "}
                      onClick={() => prevButtonStatus(currentPage + 1, changeToLoading) && goToPrevPage()}/>
                    {changeToLoading ?
                      <div className={"page-number-design"}><img alt={""} src={loading_hexagon}
                                                                 className={"loading-hexagon"}/></div>
                      : <div className={"page-number-design"}>{currentPage + 1}</div>
                    }
                    <ScrollDownSvg
                      className={nextButtonStatus(currentPage + 1, changeToLoading, fullLeaderboardList.length, alwaysTheLastToken) ? "next-page active-pagination-button" : "next-page"}
                      onClick={() => nextButtonStatus(currentPage + 1, changeToLoading, fullLeaderboardList.length, alwaysTheLastToken) && goToNextPage()}/>
                  </div>}

              </div>

              <div className={"leader-board-ornament"}>
                <div className={"leader-board-ornament-width"}>
                  <div className={"line-separator"}/>
                </div>
                <img alt={""} src={boarder_detail_bottom} className={"leader-board-decoration-crown"}/>
              </div>
            </div>
          </div>
          : <>
            <div className={"infinite-loading-background"}>
              <LoadingSpinner/>
            </div>
          </>
        }
      </div>
    </div>
  </>)
}
