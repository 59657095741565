import './SelectTextContent.scss';
import { PropsWithChildren } from 'react';
import { arrow_bottom } from '../../../assets/images/AstrafiteRushEvent';

type SelectTextContentProps = {
  name: string;
  title: string;
  displayArrow?: boolean;
  disabled?: 'disabled';
};

export function SelectTextContent({ name, title, displayArrow, disabled, children }: PropsWithChildren<SelectTextContentProps>) {
  return (
    <div className={'select__text-container'}>
      <div className={'select__title'}>{title}</div>
      <div className={'select__name'}>
        {name && <span>{name}</span>}
        {!name && !disabled && (
          <>
            <span>{name}</span>
            <span>Select</span>
          </>
        )}
        {displayArrow && !disabled && name && <img src={arrow_bottom} className="select__caret" alt={'Caret pointing down'} />}
      </div>
      {children}
    </div>
  );
}
