import React, { useState } from "react";
import { hover_triangle } from "../../../../assets/images/AstrafiteRushEvent";
import "./AstrafiteTokenInfo.scss";

export default function AstrafiteTokenInfo(props) {
  const { token, wallet, unclaimedAstrafer } = props;

  const [copyValue, setCopyValue] = useState("Copy");

  function copyThis(textToCopy) {
    navigator.clipboard.writeText(textToCopy);
    setCopyValue("Copied!");
  }

  function resetCopy() {
    setCopyValue("Copy");
  }

  return (
    <div className="astrafite-token-info-container">
      <div className="astrafite-token-info-group">
        <div className="astrafite-token-info-box">
          <div className="astrafite-token-info-box-border">
            <div className="astrafite-token-info-box-value">
              {token ? token : "-"}
            </div>
          </div>
        </div>
        <div className="astrafite-token-info-box-label">TOKEN ID</div>
      </div>
      <div className="astrafite-token-info-group">
        <div
          className="astrafite-token-info-box"
          onMouseLeave={() => resetCopy()}
          onClick={() => copyThis(wallet)}
        >
          <div className="astrafite-token-info-box-border">
            <div className="astrafite-token-info-box-value">
              {wallet
                ? wallet.substring(0, 8) +
                  "..." +
                  wallet.substring(wallet.length - 4)
                : "-"}
            </div>
          </div>
          <div className="copy-hover-element">
            <div className={"scale-copy-box"}>
              {copyValue}
              <img
                alt={"hover triangle"}
                className={"copy-progress-hover-triangle"}
                src={hover_triangle}
              />
            </div>
          </div>
        </div>
        <div className="astrafite-token-info-box-label">WALLET ADDRESS</div>
      </div>
      <div className="astrafite-token-info-group">
        <div className="astrafite-token-info-box">
          <div className="astrafite-token-info-box-border">
            <div className="astrafite-token-info-box-value">
              {unclaimedAstrafer ? unclaimedAstrafer : "-"}
            </div>
          </div>
        </div>
        <div className="astrafite-token-info-box-label">UNCLAIMED ASTRAFER</div>
      </div>
    </div>
  );
}
