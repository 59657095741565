import './TopMenuDropdown.scss'
import {nft_drop_down_arrow} from "../../../assets";
import {useNavigate} from "react-router-dom";
import React, {useContext} from "react";
import {TooltipContext} from "../../Context/TooltipContext";
import CopyTooltip from "../../Tooltip/CopyTooltip/CopyTooltip";
import {contactEmail} from "../../../utils/GeneralUtils";
import copy from "copy-to-clipboard";

export default function TopMenuDropdown({text, dropdownList}) {
  const navigator = useNavigate();

  const tooltipContext = useContext(TooltipContext);

  function setCopyTooltipVisible(visibility){
    tooltipContext.setCopyEmailStatus(prevState => ({
      ...prevState,
      ...{visible: visibility}
    }));
  }

  function resetCopy() {
    tooltipContext.setCopyEmailStatus(prevState => ({
      ...prevState,
      ...{text: 'Copy Email'}
    }));
  }

  function copyThis(textToCopy) {
    copy(textToCopy);
    tooltipContext.setCopyEmailStatus(prevState => ({
      ...prevState,
      ...{text: 'Email Copied!'}
    }));
  }

  function onMouseEnterEmailHandler() {
    setCopyTooltipVisible(true)
  }

  function mouseLeaveEmailHandler(){
    resetCopy();
    setCopyTooltipVisible(false)
  }

  function onClickEmailHandler() {
    copyThis(contactEmail.email)
  }

  const checkUrl = (link) => {
    let inputElement = document.createElement('input');
    inputElement.type = 'url';
    inputElement.value = link;

    return inputElement.checkValidity();
  }

  const navigateTo = (link) => {
    if (checkUrl(link)) {
      window.open(link, '_blank');
      return;
    }
    navigator(link);
  }

  const checkForSocialIcons = () => {
    if (text !== 'COMMUNITY')
      return dropdownList.map((item, index) => (
        "children" in item ? <div key={index}>
            <div onClick={navigateTo.bind(this, item.link)} key={index} className={`top-menu-dropdown-list-item ${text}`}>
              <div className={'top-menu-dropdown-list-text'}>{item.text}</div>
            </div>
            {item.children.map((child, index2) => (
              <div onClick={navigateTo.bind(this, child.link)} key={index2}
                   className={`top-menu-dropdown-list-item ${text} child`}>
                <div className={'top-menu-dropdown-list-text'}>{child.text}</div>
              </div>
            ))} </div>
          :
          <div onClick={navigateTo.bind(this, item.link)}
               key={index} className={`top-menu-dropdown-list-item ${text}`}>
              <div className={'top-menu-dropdown-list-text'}>{item.text}</div>
          </div>
      ))
    else {
      return dropdownList.map((item, index) => (
        <div key={index}>
          {item.text !== 'EMAIL US' ?
            <div onClick={ item.link !== '' ? navigateTo.bind(this, item.link) : <></>}
                 key={index} className={`top-menu-dropdown-list-item ${text}`}>
              <img src={item.image} alt={'ICON'} className={'top-menu-dropdown-list-text'}/>
              <span className="top-menu-dropdown-lists-icon-text">{ item.text }</span>
            </div>            :
            <div onMouseLeave={mouseLeaveEmailHandler} onMouseEnter={onMouseEnterEmailHandler}
                 onClick={onClickEmailHandler}>
              <div onClick={ item.link !== '' ? navigateTo.bind(this, item.link) : <></>}
                   key={index} className={`top-menu-dropdown-list-item ${text}`}>
                <img src={item.image} alt={'ICON'} className={'top-menu-dropdown-list-text'}/>
                <span className="top-menu-dropdown-lists-icon-text">{ item.text }</span>
              </div>
              <CopyTooltip/>
            </div>
          }
        </div>
        ))
    }
  }

  return (<div className={'top-menu-dropdown-container'}>
    <div className={'top-menu-button-dropdown'}>
      <img src={nft_drop_down_arrow} alt={'ARROW'} className={'top-menu-dropdown-arrow'}/>
      <div className={'top-menu-dropdown-text-container'}>
        <div className={'top-menu-dropdown-text'}>{text}</div>
        <div className={'top-menu-dropdown-underline'}/>
      </div>
    </div>
    <div className={`top-menu-dropdown-list`}>
      {checkForSocialIcons()}
    </div>
  </div>)
}
