import axios from "axios";
import {walletContext} from "./WalletContext";
import {REACT_APP_MOCK_API} from "./Globals";

export const getCompensationDetails = async (currentWallet, signature) => {
  let response = await axios.post(
    'https://bc.phantomgalaxies.com/api/v1/checkCompensation',
    {
      walletAddress: currentWallet,
      signatureVerify: signature,
    }
  ).catch(error => {
    throw new Error("Compensation fetch error");
  });

  if (response.data.result == null) {
    throw new Error('Data is null!');
  } else {
    return response.data.result;
  }
}

export const getRewardsData = async (currentWallet, signature) => {

  let response = await axios.get(
    'https://6274f2c16d3bc09e10679ef1.mockapi.io/api/v1/cevreaueu',
    {
      walletAddress: currentWallet,
      signatureVerify: signature,
    }
  ).catch(error => {
    throw new Error("Rewards fetch error");
  });

  if (response.data === null) {
    throw new Error('Data is null!');
  } else {
    return response;
  }
}


export const getCycleData = async () => {
  if (REACT_APP_MOCK_API) {
    let response = await axios.get(
      `https://632c4c541aabd83739987fa9.mockapi.io/mockcycles`,
      {
        walletAddress: walletContext.currentWallet,
        signatureVerify: walletContext.signature,
      }
    ).catch(error => {
      throw new Error("Cycles fetch error");
    });

    if (response.data === null) {
      throw new Error('Data is null!');
    } else {
       return response.data[0];
    }
  } else {
    let response = await axios.post(
      `${process.env.REACT_APP_BASEURL_OLD}/api/getaspost/v1/cycles2/planetholder`,
      {
        walletAddress: walletContext.currentWallet,
        signatureVerify: walletContext.signature,
      }
    ).catch(error => {
      throw new Error("Cycles fetch error");
    });
    if (response.data === null) {
      throw new Error('Data is null!');
    } else {
      return response.data;
    }
  }
}
export const getMiniLeaderBoard = async (cyclePage) => {
  if (REACT_APP_MOCK_API) {

    let response = await axios.get(
      `https://632c4c541aabd83739987fa9.mockapi.io/minileaderboard`,
    ).catch(error => {
      throw new Error("Mini leaderboard fetch error");
    });

    if (response.data === null) {
      throw new Error('Data is null!');
    } else {
      return response.data[0];
    }
  } else {
    let response = await axios.post(
      `${process.env.REACT_APP_BASEURL_OLD}/api/getaspost/v1/leaderboard/me?cycle=${cyclePage}`,
      {
        walletAddress: walletContext.currentWallet,
        signatureVerify: walletContext.signature,
      }
    ).catch(error => {
      throw new Error("Mini leaderboard fetch error");
    });
    if (response.data === null) {
      throw new Error('Data is null!');
    } else {
      return response.data;
    }
  }

}

export const getLeaderBoardSection = async (cycleNumber, page) => {
  let response = await axios.get(
    `${process.env.REACT_APP_BASEURL_OLD}/api/v1/leaderboard?cycle=${cycleNumber}&page=${page}`,
  ).catch(error => {
    throw new Error("Leaderboard fetch error");
  });
  if (response.data === null) {
    throw new Error('Data is null!');
  } else {
    return response.data;
  }
}

export const getTermsAndConditionStatus = async () => {
  if (REACT_APP_MOCK_API) {
    let response = await axios.get(
      `https://632c4c541aabd83739987fa9.mockapi.io/mockterms`,
      {
        walletAddress: walletContext.currentWallet,
        signatureVerify: walletContext.signature,
      }
    ).catch(error => {
      throw new Error("Terms and Conditions fetch error");
    });
    if (response.data === null) {
      throw new Error('Data is null!');
    } else {
      return response.data[0]
    }
  } else {
    let response = await axios.post(
      `${process.env.REACT_APP_BASEURL_OLD}/api/getaspost/v1/afr/tnc`,
      {
        walletAddress: walletContext.currentWallet,
        signatureVerify: walletContext.signature,
      }
    ).catch(error => {
      throw new Error("Terms and Conditions fetch error!")
    });
    if(response.data ===null){
      throw new Error("Data is null!");
    } else {
      return response.data;
    }
  }
}

export const setTermsAndConditionsStatus = async (tcResponse) => {
  if (REACT_APP_MOCK_API){
    await axios.post(
      `https://632c4c541aabd83739987fa9.mockapi.io/mockterms`,
      {isAccepted : tcResponse,
        walletAddress: walletContext.currentWallet,
        signatureVerify: walletContext.signature,
      },
    ).catch(error => {
      throw new Error("Terms and Conditions post error!");
    });
  } else {
    await axios.post(
      `${process.env.REACT_APP_BASEURL_OLD}/api/v1/afr/tnc`,
      {
        isAccepted: tcResponse,
        walletAddress: walletContext.currentWallet,
        signatureVerify: walletContext.signature,
      }
    ).catch(error => {
      throw new Error("Terms and Conditions post error!")
    });
  }
}

export const getAfrKYCCheck = async () => {
  if (REACT_APP_MOCK_API) {
    let response = await axios.get(
      `https://632c4c541aabd83739987fa9.mockapi.io/mockterms`,
      {
        walletAddress: walletContext.currentWallet,
        signatureVerify: walletContext.signature,
      }
    ).catch(error => {
      throw new Error("AFR KYC Check fetch error");
    });
    if (response.data === null) {
      throw new Error('Data is null!');
    } else {
      return response.data[0]
    }
  } else {
    let response = await axios.post(
      `${process.env.REACT_APP_BASEURL_OLD}/api/getaspost/v1/afr/kyccheck`,
      {
        walletAddress: walletContext.currentWallet,
        signatureVerify: walletContext.signature,
      }
    ).catch(error => {
      throw new Error("AFR KYC Check fetch error!")
    });
    if(response.data ===null){
      throw new Error("Data is null!");
    } else {
      return response.data;
    }
  }
}
