import React from "react";
import PageHeader from "../PageHeader";
import "./Astrafer.scss";
import { astraferDescriptionText } from "../../utils/GeneralUtils";
import {
  astrafer_circle_pattern,
  token_animation_video,
} from "../../assets/images/Token";


import SwapButtons from "../SwapButtons/SwapButtons";


export default function Astrafer() {

  return <>
    <div className={"astrafer-main-container"}>
      <div className={"astrafer-title"}>
        <PageHeader headerText="ASTRAFER" classname="page-mobile-header-text-no-margin" />
      </div>
      <div className={"astrafer-content-container"}>
        <div className={"astrafer-description-main-container"}>
          <div className={"description-text-container"}>
            <img alt={""} src={astrafer_circle_pattern} className={"circle-patern-adjustment"} />
            <div className={"description-text-title"}>
              {astraferDescriptionText.title}
              {astraferDescriptionText.mobileTitle}
            </div>
            <div className={"description-text-content"}>
              <p className={"astrafer-paragraph-alignment"}>{astraferDescriptionText.paragraph1}</p>
              {astraferDescriptionText.paragraph2}
            </div>
          </div>
          <div className={"description-img-container"}>
            <div className={"video-shadow-border"} />
            <video
              src={token_animation_video}
              loop
              className={"token-animation-video"}
              autoPlay="autoplay"
              playsInline
              muted></video>
          </div>
        </div>
        <SwapButtons />
        {/*<Quickswap/>*/}
      </div>
    </div>
  </>
}
