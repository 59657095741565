import {Modal} from "react-bootstrap";
import './KYCModal.scss'
import { } from "../../../assets";
import {close_kyc_modal, upper_border_kyc_modal, lower_border_kyc_modal} from "../../../assets"
import {rewardsClaim} from "../../../utils/GeneralUtils";

export default function KYCModal({show, setShow}) {

  function onExitButtonClicked() {
    setShow(!show);
  }

  return <>
    <div className={'kyc-modal-main-container'}>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName='kyc-dialog'
        show={show}
        className={'kyc-modal'}
      >
        <div className={'popup-container'}>
          <img src={upper_border_kyc_modal} alt="" className={"upper-border"}/>
          <img src={close_kyc_modal} alt="" className={"kyc-close"} onClick={onExitButtonClicked}/>
          <div className={'kyc-popup-text'}>
            <div className={'popup-title-container'}>
              What is KYC?
            </div>
            <div className={'popup-description-container'}>
              {rewardsClaim.kycPopupText1}
            </div>
            <div className={'popup-title-container'}>
              Why do I need to complete KYC?
            </div>
            <div className={'popup-description-container'}>
              {rewardsClaim.kycPopupText2}
            </div>
            <div className={'popup-title-container'}>
              What is Blockpass?
            </div>
            <div className={'popup-description-container'}>
              {rewardsClaim.kycPopupText3}
            </div>
            <div className={'popup-title-container'}>
              Legal note
            </div>
            <div className={'popup-description-container'}>
              {rewardsClaim.kycPopupText4}
            </div>
          </div>
          <img src={lower_border_kyc_modal} alt="" className={"lower-border"}/>
        </div>
      </Modal>
    </div>
  </>
}
