import React from "react";
import "./PlygonBackgrounds.scss";
import Multiple from "../Multiple/Multiple";

export default function PolygonBackgrounds(props) {

  return <>
      <div className={"drow-background"} style={{"background-color": props.backgroundColor}}>
        <div className={"polygon-content-container"}>
          {props.multipleValue!=null ? <Multiple multipleValue={props.multipleValue}/> : <></>}
          <img alt="" className={"polygon-img-dimension"} src={props.img}/>
          <p className={"plygon-text-adjustment"}>{props.text}</p>
        </div>
    </div>
  </>
}
