import './CaimInfo.scss'
import ClaimInfoElement from "../ClaimInfoElement/ClaimInfoElement";

export default function ClaimInfo({availableAmount, remainingAmount, nextClaimDate, isFinalClaim}){
  return <>
    <div className={'claim-info-container'}>
      <div className={'available-amount-container'}>
        <ClaimInfoElement title={'ASTRAFER AVAILABLE TO CLAIM'} text={availableAmount}/>
      </div>
      <div className={'remaining-amount-main-container'}>
        <ClaimInfoElement title={'ASTRAFER REMAINING'} text={remainingAmount}/>
      </div>
      <div className={'remaining-amount-main-container mobile'}>
        <ClaimInfoElement title={'ASTRAFER REMAINING'} text={remainingAmount}/>
      </div>
      <div className={'next-date-main-container'}>
        <ClaimInfoElement title={isFinalClaim ? 'DATE OF FINAL CLAIM' : 'DATE OF NEXT CLAIM'} text={nextClaimDate}/>
      </div>
    </div>
  </>
}
