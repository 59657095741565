// Custom hook for handling receive chain balance
import { setErrorModalText, setReceiveBalanceIsLoading, setReceiveTokenBalance, setShowErrorModal } from './tokenBridgeActions';
import React, { useEffect } from 'react';
import { createContract, getContractAddress } from './TokenBridgeContent';
import { getERC20Balance } from '../../utils/ContractUtils';
import { ethers } from 'ethers';
import { ChainConfig, ChainId, TokenBridgeAction } from './tokenBridgeTypes';

export function useReceiveChainBalance(
  address: (`0x${string}` & {}) | `0x${string}` | undefined,
  receiveConfig: ChainConfig & ChainId,
  dispatch: React.Dispatch<TokenBridgeAction>,
) {
  useEffect(() => {
    const getBalanceOnReceivingChain = async () => {
      if (!receiveConfig.chainId) {
        dispatch(setReceiveTokenBalance(0));
        return;
      }

      dispatch(setReceiveBalanceIsLoading(true));

      const contractAddress = getContractAddress(receiveConfig.chainId);

      const receivingContract = createContract(receiveConfig.chainId, contractAddress);
      let balance;

      try {
        balance = await getERC20Balance(receivingContract, address);
      } catch (e: any) {
        dispatch(setErrorModalText(e.message));
        dispatch(setShowErrorModal(true));
      }

      // FIXME: convert balance to number with 18 decimals?
      dispatch(setReceiveTokenBalance(parseInt(ethers.utils.formatEther(balance ?? 0))));
      dispatch(setReceiveBalanceIsLoading(false));
    };

    getBalanceOnReceivingChain();
  }, [address, receiveConfig, dispatch]);

  return;
}
