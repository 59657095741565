import './AssetCard.scss';
import { Card, Overlay } from 'react-bootstrap';
import React, { memo, useRef, useState } from 'react';
import { checkbox_checked, heart_empty, heart_filled, unchecked_round_checkbox_svg } from '../../../assets';
import { ImageFallback } from '../Image/ImageFallback';

export enum LockStatus {
  BOUND = 'BOUND',
  UNBOUND = 'UNBOUND',
  IN_MISSION = 'IN_MISSION',
}

export type AssetIcon = {
  active: React.JSX.Element;
  inactive: React.JSX.Element;
};

export type ToolTipProps = {
  title: string;
};

export type AssetCardProps = {
  id: string;
  thumbnailImageUrl: string;
  status: LockStatus;
  isFavourite: boolean;
  altImageText?: string;
  isChecked?: boolean;
  fallbackImage?: string;
  handleIsFavourite?: (id: string, isFavourite: boolean) => void;
  handleIsChecked?: (id: string) => void;
  handleCardDetails?: (imageUrl: string) => void;
  leftIcon?: AssetIcon;
  rightIcon?: AssetIcon;
  displayName: string;
};

export function AssetCard({
  id,
  thumbnailImageUrl,
  fallbackImage,
  altImageText,
  isChecked,
  status,
  isFavourite,
  handleIsFavourite,
  handleIsChecked,
  handleCardDetails,
  leftIcon,
  rightIcon,
  displayName,
}: AssetCardProps) {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipTarget = useRef(null);

  const handleLockStatusIcon = () => {
    if (status === LockStatus.BOUND) {
      return 'locked';
    } else if (status === LockStatus.UNBOUND) {
      return 'unlocked';
    } else {
      return 'game-locked';
    }
  };

  const favouriteClickHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (handleIsFavourite) {
      handleIsFavourite(id, isFavourite);
    }
  };

  const checkHandler = (e: React.MouseEvent<HTMLElement, MouseEvent>, id: string) => {
    e.stopPropagation();
    if (handleIsChecked) {
      handleIsChecked(id);
    }
  };

  // This allows the user to touch the image with padlock icon without it navigating to planet details page
  // for the purpose of displaying the lock status tooltip.
  const imageTouchHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const ToolTipLink = ({ title }: ToolTipProps) => (
    <Overlay target={tooltipTarget.current} show={showTooltip} placement="top" transition={true}>
      {({
        placement: _placement,
        arrowProps: _arrowProps,
        show: _show,
        popper: _popper,
        hasDoneInitialMeasure: _hasDoneInitialMeasure,
        ...props
      }) => (
        <div
          {...props}
          onClick={e => e.stopPropagation}
          className={'asset-card-tooltip-text'}
          style={{
            position: 'absolute',
            border: '1px solid #fff',
            backgroundColor: 'rgba(0 ,0 ,0)',
            color: '#fff',
            borderRadius: '3px',
            padding: '10px',
            ...props.style,
          }}
        >
          {title}
        </div>
      )}
    </Overlay>
  );

  return (
    <>
      <Card className={`asset-card${isChecked ? '--selected' : ''}`} onClick={() => (handleCardDetails ? handleCardDetails(id) : undefined)}>
        <img
          className="asset-card__fav"
          // locked icons are fallback images
          src={leftIcon ? (isFavourite ? leftIcon.active : leftIcon.inactive) : isFavourite ? heart_filled : heart_empty}
          alt="A heart icon."
          onClick={e => favouriteClickHandler(e)}
        />
        <div className={`asset-card__image-container--${handleLockStatusIcon()}`}>
          <ImageFallback url={thumbnailImageUrl} altImageText={altImageText ?? ''} fallback={fallbackImage ?? ''} className="asset-card__image" />
          <div
            className="asset-card-tooltip-mask"
            ref={tooltipTarget}
            onClick={e => imageTouchHandler(e)}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            onTouchStart={() => setShowTooltip(prevState => !prevState)}
            onTouchMove={() => setShowTooltip(false)}
          >
            <ToolTipLink title={`Asset is currently ${handleLockStatusIcon()}`} />
          </div>
        </div>
        <p className="asset-card__id">{displayName}</p>
        <div className="asset-card__status">
          <span className="asset-card__status-label">STATUS:</span>
          <span className="asset-card__status-value">{status}</span>
        </div>
        <img
          className="asset-card__checkbox"
          // locked icons are fallback images
          src={rightIcon ? (isChecked ? rightIcon.active : rightIcon.inactive) : isChecked ? checkbox_checked : unchecked_round_checkbox_svg}
          alt={isChecked ? 'a round red circle with a tick' : 'a round empty circle'}
          onClick={e => checkHandler(e, id)}
        ></img>
      </Card>
    </>
  );
}

export default memo(AssetCard, arePropsEqual);

function arePropsEqual(oldProps: AssetCardProps, newProps: AssetCardProps) {
  return oldProps.status === newProps.status && oldProps.isChecked === newProps.isChecked && oldProps.isFavourite === newProps.isFavourite;
}

