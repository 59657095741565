import './GenerativePlanetMinting.scss';
import HeaderComponent from '../components/Header/HeaderComponent';
import Footer from '../components/Footer/Footer';
import FighterLady from '../components/Footer/FooterElements/FighterLady/FighterLady';
import PageHeader from '../components/PageHeader';
import GenerativePlanetMintingContent from '../components/GenerativePlanetMinting/GenerativePlanetMintingContent/GenerativePlanetMintingContent';
import ConnectWalletSection from '../components/ConnectWallet/ConnectWalletSection';
import { walletContext } from '../utils/WalletContext';
import SwitchNetworkChainSection from '../components/SwitchNetworkChain';
import React from 'react';
import { useIsCorrectChain } from '../hooks/useIsCorrectChain';
import { useNetwork } from 'wagmi';

export default function GenerativePlanetMinting() {
  const isCorrectChain = useIsCorrectChain();
  const network = useNetwork();

  return (
    <div className="planet-minting-container">
      <HeaderComponent noBackground={true} />
      <div className={'planet-minting-title'}>
        <PageHeader headerText={'GENERATIVE PLANET MINTING'} />
      </div>
      {!isCorrectChain && (
        <SwitchNetworkChainSection network={network} message={`You must be on the ${process.env.REACT_APP_CHAINID} network to mint NFTs`} />
      )}
      {walletContext.signed && walletContext.currentWallet && isCorrectChain && <GenerativePlanetMintingContent />}
      <ConnectWalletSection />
      <Footer>
        <FighterLady />
      </Footer>
    </div>
  );
}
