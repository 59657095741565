import { useEffect, useState } from 'react';
import { getMyStarfighters } from '../utils/ApiCalls';
import { Starfighter } from "@pg/account-api";
import { LockStatus } from '../components/Common/AssetCard/AssetCard';

export interface CustomStarfighter extends Starfighter {
  id: string;
  thumbnailImageUrl: string;
  isFavourite: boolean;
  status: LockStatus;
  data: {
    name: string;
  };
  displayName: string;
  isChecked?: boolean;
}

interface MyStarfighters {
  starfighters: CustomStarfighter[];
  starfightersError: string | null;
  setStarfighters: React.Dispatch<React.SetStateAction<CustomStarfighter[]>>;
}

export function useFetchMyStarfighters(): MyStarfighters {
  const [starfighters, setStarfighters] = useState<CustomStarfighter[]>([]);
  const [starfightersError, setStarfightersError] = useState<string | null>(null);

  useEffect(() => {
    const fetchMyStarfighters = async () => {
      try {
        const starfightersResponse = await getMyStarfighters();
        const blockchainStarfighters = starfightersResponse.filter((starfighter: CustomStarfighter) => starfighter.isFreeStarfighter === false);

        const sortedByFavourites: CustomStarfighter[] = blockchainStarfighters.sort((a: Starfighter, b: Starfighter) => (a.isFavourite === b.isFavourite ? 0 : a.isFavourite ? -1 : 1));
        const starfightersWithName = sortedByFavourites.map((starfighter) => ({...starfighter, displayName: starfighter.data.name as string}));
        setStarfighters(starfightersWithName);

      } catch (e: any) {
        setStarfightersError('Error: failed to fetch starfighters');
      }
    };

    fetchMyStarfighters();
  }, []);

  return { starfighters, starfightersError, setStarfighters };
}
