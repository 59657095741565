import {closeIcon} from "../../../assets";
import React from "react";
import "./ModalCloseButton.scss";

export default function ModalCloseButton({clickEvent}) {
  return (
    <div className={"modal-close-button"}>
      <img alt="" className={"modal-close-button-icon"}
           onClick={clickEvent} src={closeIcon}/>
    </div>
  )
}
