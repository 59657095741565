import './MintpassModalCombinations.scss'
import {combination_plus} from "../../../../assets";
import React from "react";

function MintpassModalCombinations({nftsCombination,account}) {
  return (
    <>
      <div className={'mintpass-combinations-container'}>
        <div className={'mintpass-combinations-nfts'}>
          {nftsCombination.map((nft) => (
            <>
              <div className={'mintpass-nft-container'} key={nft.globalsEpisodeIdKey}>
                <div className={'mintpass-nft-title-container'}>
                  <div className={'mintpass-nft-title'}>
                    {nft.name.split('\n').map((line, index) => (
                      <div key={index}>{line}</div>
                    ))}
                  </div>
                </div>
                <div className={'mintpass-nft-image-container'}>
                  <img src={nft.image} alt={''}/>
                </div>
                <p className={'mintpass-nft-owned'}>x{account[nft.accountDataKey]} Owned</p>
              </div>
              <img src={combination_plus} alt={''} className={'mintpass-nft-combination-plus'}/>
            </>
          ))}
        </div>
      </div>
    </>
  )
}

export {MintpassModalCombinations}
