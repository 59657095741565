import {Col, Row} from "react-bootstrap";
import React from "react";
import "./PurchaseStepRow.scss";


export default function PurchaseStepRow({ title, description, button }) {

  return (
    <Row className={'purchase-step-row'}>
      <Col xs={8}>
        <p><b>{title}</b></p>
        {description}
      </Col>
      <Col xs={4}>
        {button}
      </Col>
    </Row>
  )
}
