import './ClaimAstrafer.scss'
import ClaimInfo from "./ClaimInfo/ClaimInfo";
import ClaimInfoElement from "./ClaimInfoElement/ClaimInfoElement";
import ClaimButton from "../ClaimButton/ClaimButton";

export default function ClaimAstrafer({availableAmount, remainingAmount, isFullyClaimed, nextClaimDate, tokenId, isFinalClaim, handleClaimAstrafer, kycPassed}) {

  const imageUrl = `${process.env.REACT_APP_AFR_TOKEN_IMAGE_BASEURL}/${tokenId}.png`;

  return <>
    <div className={'claim-astrafer-main-container'}>
      <div className={'claim-astrafer-button-container'}>
        <ClaimButton isActive={!isFullyClaimed && kycPassed} activeText={'CLAIM ASTRAFER'} disabledText={(kycPassed ? 'ASTRAFER CLAIMED' : 'KYC REQUIRED')} handler={handleClaimAstrafer}/>
      </div>
      <ClaimInfo availableAmount={availableAmount} remainingAmount={remainingAmount} nextClaimDate={nextClaimDate} isFinalClaim={isFinalClaim}/>
      <img alt={''} src={imageUrl} className={'claim-astrafer-image'}/>
      <div className={'token-id-container'}>
        <ClaimInfoElement title={'TOKEN ID'} text={tokenId}/>
      </div>
    </div>
  </>
}
