import PGModal from '../../Common/PGModal/PGModal';
import GenericButton from '../../Button/GenericButton';
import LoadingSpinner from '../../LoadingSpinner';
import { checkbox_checked } from '../../../assets';
import SuccessMessage from '../../Common/SuccessMessage/SuccessMessage';
import ErrorModal from '../../Modal/ErrorModal/ErrorModal';
import { DashboardSummary } from '../Common/DashboardSummary';
import { Suspense, useEffect, useState } from 'react';
import AssetCardList from '../../Common/AssetCard/AssetCardList';
import { ERC721GameLock } from '@pg/blockchain-api';
import { LockStatus } from '../../Common/AssetCard/AssetCard';
import { createFavourite, deleteFavourite, getVaultSignatures } from '../../../utils/ApiCalls';
import { getBlockchainContract } from '../../../utils/providerObject';
import PGVaultAbi from '@fugu/base-contracts/dist/abis/Vault/Previous.Versions/PGVault.v2.sol/PGVaultv2.json';
import { metamaskErrorMap } from '../../../blockchain/errors';
import { walletContext } from '../../../utils/WalletContext';
import { AssetType } from '@pg/account-api';
import { StarfighterDashboardInfo } from './Info/StarfighterDashboardInfo';
import { useProvider } from '../../../hooks/useProvider';
import { Contract } from 'ethers';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import { useFetchMyStarfighters, CustomStarfighter } from '../../../hooks/useFetchMyStarfighters';
import { generating_starfighter } from '../../../assets/images/Starfighters';
import { CustomVaultSignatureLockRequest } from '../Planet/PlanetDashboardContent';

enum BindType {
  UNBIND = 'unbind',
  BIND = 'bind',
}

export default function StarfighterDashboardContent() {
  const [selectedStarfighters, setSelectedStarfighters] = useState<CustomStarfighter[] | undefined>();
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [inMissionCount, setInMissionCount] = useState(0);
  const [boundCount, setBoundCount] = useState(0);
  const [unboundCount, setUnboundCount] = useState(0);
  const [isBindActionLoading, setIsBindActionLoading] = useState(false);
  const [boundSuccess, setIsBoundSuccess] = useState(false);
  const [showBindModal, setShowBindModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [totalUpdateStarfightersCount, setTotalUpdateStarfightersCount] = useState(0);
  const [bindStatusText, setBindStatusText] = useState('');
  const [errorModalText, setErrorModalText] = useState('');
  const [bindType, setBindType] = useState<BindType>(BindType.UNBIND);
  const { starfighters, starfightersError, setStarfighters } = useFetchMyStarfighters();

  const provider = useProvider();

  const starfighterStatus = (gameLocks?: ERC721GameLock[], isBlockchainLocked?: boolean): LockStatus => {
    if (gameLocks && gameLocks.length > 0) {
      return LockStatus.IN_MISSION;
    } else if (isBlockchainLocked && gameLocks && gameLocks.length === 0) {
      return LockStatus.BOUND;
    } else {
      return LockStatus.UNBOUND;
    }
  };

  useEffect(() => {
    if (starfighters.length > 0) {
      setStarfighters(prevStarfighterList =>
        prevStarfighterList.map(starfighter => ({
          ...starfighter,
          isChecked: isSelectAll,
          status: starfighterStatus(starfighter.gameLocks, starfighter.isBlockchainLocked),
          altImageText: `An Starfighter with ID ${starfighter.data.name}.`,
        })),
      );
    }
  }, [isSelectAll, starfighters.length, setStarfighters]);

  useEffect(() => {
    setSelectedStarfighters(starfighters?.filter(starfighter => starfighter.isChecked));
    setSelectedCount(starfighters.filter(asset => asset.isChecked).length);
    setInMissionCount(starfighters.filter(asset => asset.isChecked && asset.status === LockStatus.IN_MISSION).length);
    setBoundCount(starfighters.filter(asset => asset.isChecked && asset.status === LockStatus.BOUND).length);
    setUnboundCount(starfighters.filter(asset => asset.isChecked && asset.status === LockStatus.UNBOUND).length);
  }, [starfighters]);

  const selectAllHandler = (isSelectAll: boolean) => {
    setIsSelectAll(isSelectAll);
    setSelectedCount(isSelectAll ? starfighters?.length || 0 : 0);
    setInMissionCount(isSelectAll ? starfighters?.filter(asset => asset.status === LockStatus.IN_MISSION).length || 0 : 0);
    setBoundCount(isSelectAll ? starfighters?.filter(asset => asset.status === LockStatus.BOUND).length || 0 : 0);
    setUnboundCount(isSelectAll ? starfighters?.filter(asset => asset.status === LockStatus.UNBOUND).length || 0 : 0);
  };

  const selectStarfighterHandler = (id: string) => {
    setStarfighters(prevStarfighters =>
      prevStarfighters.map(starfighter => {
        if (starfighter.id === id) {
          starfighter.isChecked = !starfighter.isChecked;
        }
        return starfighter;
      }),
    );
  };

  const updateFavourite = async (id: string, isFavourite: boolean) => {
    if (isFavourite) {
      await deleteFavourite(id);
    } else {
      await createFavourite(id, AssetType.STARFIGHTER);
    }

    setStarfighters(prevStarfighters =>
      prevStarfighters.map(starfighter => {
        if (starfighter.id === id) {
          starfighter.isFavourite = !starfighter.isFavourite;
        }
        return starfighter;
      }),
    );
  };

  const handleBindingAction = async (bindType: BindType) => {
    setIsBindActionLoading(true);
    const vaultSignatureRequestList: CustomVaultSignatureLockRequest[] = [];

    if (!selectedStarfighters || selectedStarfighters.length < 1) {
      return;
    }

    const state = bindType === BindType.BIND;

    for (const starfighter of selectedStarfighters) {
      const shouldAddToRequest = (state && starfighter.status === LockStatus.UNBOUND) || (!state && starfighter.status === LockStatus.BOUND);

      if (shouldAddToRequest) {
        const request = formatVaultSignatureRequest(starfighter, state);
        vaultSignatureRequestList.push(request);
      }
    }

    try {
      setBindStatusText('Getting signature...');
      const result = await getVaultSignatures(vaultSignatureRequestList);
      setTotalUpdateStarfightersCount(vaultSignatureRequestList.length);
      setBindStatusText('Please sign transaction to proceed');

      const transaction = await set721LockStatus(
        getBlockchainContract(process.env.REACT_APP_CONTRACT_ADDRESS_VAULT, PGVaultAbi.abi, provider) as Contract,
        result.structArrays,
        result.signatures,
      );

      // We set the text to an empty string so the element is hidden
      // Then add the status text to transition the next message
      setBindStatusText('');
      setTimeout(() => {
        setBindStatusText(
          `${bindType === BindType.BIND ? 'Bind' : 'Unbind'}ing ${getPluralizedText(
            totalUpdateStarfightersCount,
          )}\\n This may take a while, please wait...`,
        );
      }, 500);

      const transactionResponse = await transaction.wait();

      if (transactionResponse) {
        setIsBoundSuccess(true);

        const updateStarfighterStatus = (starfighter: CustomStarfighter) => {
          const updatedStarfighter = selectedStarfighters.find(selectedStarfighter => selectedStarfighter.id === starfighter.id);

          if (updatedStarfighter) {
            return {
              ...starfighter,
              status: state ? LockStatus.BOUND : LockStatus.UNBOUND,
              isBlockchainLocked: state,
            };
          } else {
            return starfighter;
          }
        };

        const updatedStarfighters = starfighters.map(starfighter => updateStarfighterStatus(starfighter));
        const updatedSelectedStarfighters = selectedStarfighters.map(starfighter => updateStarfighterStatus(starfighter));

        setStarfighters(updatedStarfighters);
        setSelectedStarfighters(selectedStarfighters.map(starfighter => updateStarfighterStatus(starfighter)));
        setBoundCount(updatedSelectedStarfighters?.filter(asset => asset.status === LockStatus.BOUND).length);
        setUnboundCount(updatedSelectedStarfighters?.filter(asset => asset.status === LockStatus.UNBOUND).length);
      }
    } catch (e) {
      setShowBindModal(false);
      setShowErrorModal(true);

      // throw a generic error if error code is unknown
      if (!metamaskErrorMap.some(error => error === (e as any).code)) {
        setErrorModalText('Something went wrong, please try again later');
        setIsBindActionLoading(false);
        setBindStatusText('');
        return;
      }

      if ((e as any).code === 'ACTION_REJECTED') {
        setErrorModalText('Request rejected. You must approve the blockchain request to continue');
      } else {
        setErrorModalText('Something went wrong, please try again later');
      }
    }

    setBindStatusText('');
    setIsBindActionLoading(false);
  };

  const formatVaultSignatureRequest = (starfighter: CustomStarfighter, state: boolean) => {
    return {
      tokenAddress: starfighter.contractAddress as string,
      playerAddress: walletContext.currentWallet,
      state: state,
      tokenId: starfighter.tokenId as number,
      amount: `1`,
      deadline: 9999999999999,
      chainId: starfighter.chainId as number,
      contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS_VAULT as string,
    };
  };

  const set721LockStatus = async (contract: Contract, struct: {}[], signature: string[]): Promise<TransactionResponse> => {
    return await contract.set721LockedStatusBatch(struct, signature);
  };

  const promptBindingAction = (bindType: BindType) => {
    setShowBindModal(true);
    setBindType(bindType);
  };

  const getBoundCount = () => {
    return bindType === BindType.BIND ? unboundCount : boundCount;
  };

  const getPluralizedText = (count: number) => {
    return count > 1 ? 'starfighters' : 'starfighter';
  };

  const getBindingActionPastTense = (bindType: BindType) => {
    return bindType === BindType.BIND ? 'bound' : 'unbound';
  };

  // @ts-ignore
  // @ts-ignore
  return (
    <>
      <PGModal
        show={showBindModal}
        onHide={() => {
          setShowBindModal(false);
          setIsBoundSuccess(false);
        }}
        // The prompt message will display for a brief moment if we set isBoundSuccess to false as the modal is closed
        // This ensures the success message will persist until the modal has exited completely
        onExited={() => setIsBoundSuccess(false)}
      >
        <div className="planet-dashboard__modal-text-container">
          {!isBindActionLoading && !boundSuccess && (
            <>
              <p className={'planet-dashboard__modal-text'}>{`You are about to ${bindType} ${getBoundCount()} ${getPluralizedText(
                getBoundCount(),
              )}.`}</p>
              <p>Do you wish to proceed?</p>
              <GenericButton handleClick={() => handleBindingAction(bindType)} buttonText={bindType} buttonClass="planet-dashboard__modal-button" />
            </>
          )}
          {isBindActionLoading && (
            <>
              <LoadingSpinner marginBottom={'16px'} />
            </>
          )}

          {bindStatusText.split('\\n').map((text, index) => (
            <p className={bindStatusText ? 'planet-dashboard__modal-loading-text' : 'planet-dashboard__modal-loading-text'} key={index}>
              {text}
            </p>
          ))}

          {boundSuccess && (
            <>
              <img alt="a tick inside of circle" src={checkbox_checked} className="planet-dashboard__modal-success-icon" />
              <SuccessMessage
                message={`You have successfully ${getBindingActionPastTense(bindType)} ${totalUpdateStarfightersCount} ${getPluralizedText(
                  totalUpdateStarfightersCount,
                )}`}
              />
              <GenericButton handleClick={() => setShowBindModal(false)} buttonText="close" buttonClass="planet-dashboard__modal-button" />
            </>
          )}
        </div>
      </PGModal>
      {showErrorModal && (
        <ErrorModal
          onHide={() => {
            setShowErrorModal(false);
            setIsBoundSuccess(false);
          }}
          title="Error"
          text={errorModalText}
          handleConfirm={true}
        />
      )}

      <DashboardSummary
        bindClickHandler={() => promptBindingAction(BindType.BIND)}
        unbindClickHandler={() => promptBindingAction(BindType.UNBIND)}
        selectedCount={selectedCount}
        inMissionCount={inMissionCount}
        boundCount={boundCount}
        unboundCount={unboundCount}
        isBindDisabled={selectedCount < 1 || unboundCount < 1}
        isUnbindDisabled={selectedCount < 1 || boundCount < 1}
        mainText={'GAME LOCKED'}
      />
      {starfightersError && <p style={{ marginBottom: '40px', textAlign: 'center' }}>{starfightersError}</p>}
      <Suspense fallback={<LoadingSpinner />}>
        <AssetCardList
          assetListTitle={'My Starfighters'}
          assetList={starfighters}
          assetFavouriteHandler={updateFavourite}
          isSelectAll={isSelectAll}
          selectHandler={selectStarfighterHandler}
          selectAllHandler={selectAllHandler}
          fallbackImageUrl={generating_starfighter}
        />
      </Suspense>
      <StarfighterDashboardInfo />
    </>
  );
}
