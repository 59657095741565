import './ExplorationLanding.scss';
import HeaderComponent from '../components/Header/HeaderComponent';
import FighterLady from '../components/Footer/FooterElements/FighterLady/FighterLady';
import Footer from '../components/Footer/Footer';
import React from 'react';
import ExplorationLandingContent
  from '../components/ExplorationLanding/ExplorationLandingContent/ExplorationLandingContent';

export default function ExplorationLanding() {
  return (
    <>
      <HeaderComponent noBackground={true}/>
      <ExplorationLandingContent />
      <Footer>
        <FighterLady />
      </Footer>
    </>
  );
}
