import './ExplorationSelection.scss'
import HeaderComponent from "../components/Header/HeaderComponent";
import Footer from "../components/Footer/Footer";
import FighterLady from "../components/Footer/FooterElements/FighterLady/FighterLady";
import React from "react";
import ExplorationSelectionContent
  from "../components/ExplorationSelection/ExplorationSelectionContent/ExplorationSelectionContent";

export default function ExplorationSelection(){
  return <>
    <HeaderComponent noBackground={true}/>
    <ExplorationSelectionContent/>
    <Footer>
      <FighterLady/>
    </Footer>
  </>
}
