import {crossInHex} from "../../../assets";
import {Col, Row} from "react-bootstrap";
import React from "react";
import './PlanetPurchaseError.scss';

export default function PurchaseModalError({errorMessage}) {
  return (
    <Row>
      {/* TODO: Implement fade after x seconds ??*/}
      {errorMessage !== null &&
        <>
          <Col xs={1}>
            <img alt="" className={"purchase-step-check-icon"} src={crossInHex}/>
          </Col>
          <Col>
            <p className={'planet-purchase-error-text'}>{errorMessage}</p>
          </Col>
        </>
      }
    </Row>
  )
}
