import './MintpassAstraferModal.scss'
import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import {claim_episode_bottem_horn, claim_episode_header_horn} from "../../../assets/images/PopUps";
import {closeIcon} from "../../../assets";
import {licenseAndAgreement, MintpassAstraferText} from "../../../utils/GeneralUtils";
// import {acceptTAndCs, hasAcceptedTAndCs} from "../../../utils/ApiCalls";
// import {walletContext} from "../../../utils/WalletContext";
import {T_AND_C_SIGNING_MESSSGE} from "../../../utils/Globals";
import { useSignMessage } from 'wagmi';

function MintpassAstraferModal({astraferBalance, setShowMintWithAstrafer, astraferAmount, activeNFT, purchaseConfirmHandler}) {
  const [popupVisibility, setPopupVisibility] = useState(true);
  const [isSigned, setIsSigned] = useState(false);

  // TODO restore this at some point after Early Access
  // useEffect(() => {
  //   // check to see if the user has accepted the T&Cs before
  //   hasAcceptedTAndCs(walletContext.currentWallet, walletContext.signature)
  //     .then(res => {
  //       setIsSigned(res.data.isAccepted);
  //     });
  // });
  const exitButtonHandler = () => {
    setPopupVisibility(false)
    setShowMintWithAstrafer(false)
  }

  const { signMessage } = useSignMessage(
    {
      async onSuccess(data) {
        //Just finished signing message
        // TODO restore the license check after Early Access
        // await acceptTAndCs(walletContext.currentWallet, walletContext.signature, data);
        setIsSigned(true);

        processTransactionTandCSigned();
      },
      onError() {
        //Sign message error
        setIsSigned(false);
      }
    }
  );

  const handleYesClick = async () => {
    if(!isSigned) {
      try {
        signMessage({ message: T_AND_C_SIGNING_MESSSGE });
      } catch (err) {
        // if they rejects the wallet signing, dont mint.
        return;
      }
    } else {
      processTransactionTandCSigned();
    }
  }

  const processTransactionTandCSigned = () => {
    purchaseConfirmHandler(activeNFT.globalMintPassId);
  }

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName='mintpass-astrafer-modal'
        show={popupVisibility}
      >
        <img className={'mintpass-astrafer-header-decoration'} src={claim_episode_header_horn} alt={''}/>
        <div className={'mintpass-astrafer-content'}>
          <img alt="" className={"mintpass-astrafer-close-button"} onClick={exitButtonHandler} src={closeIcon}/>
          <div className={"mintpass-astrafer-balance-text"}>

              Your balance:
              {astraferBalance.formatted} ASTRAFER

          </div>
          {MintpassAstraferText(astraferAmount, activeNFT.title).note}
          <div className={'mintpass-licence-agreement-title'}>
            <p className={'mintpass-licence-agreement-title header'}> Licence Agreement </p>
            <p className={'mintpass-licence-agreement-title body'}> Unlimited Personal and Commercial Use </p>
          </div>
          <div className={'mintpass-licence-text-box-container'}>
            <div className={'mintpass-licence-text-box'}>
              <div className={'mintpass-licence-text-box-text'}>
                {licenseAndAgreement.text}
                <br/>
              </div>
            </div>
            <div className={'mintpass-licence-text-box-shadow'}></div>
          </div>
          <p className={'mintpass-astrafer-confirmation'}>
            This will require you to sign 2 transactions.
            Do you wish to proceed?
          </p>
          <div className={'mintpass-astrafer-buttons-container'}>
            {astraferAmount <= astraferBalance.value ?
              <div className={'mintpass-astrafer-yes-button'} onClick={handleYesClick}>
                YES
              </div>
              : <div className={'mintpass-astrafer-yes-button disabled'}>
                YES
              </div>
            }

            <div onClick={exitButtonHandler} className={'mintpass-astrafer-no-button'}>
              NO
            </div>
          </div>
        </div>
        <img className={'mintpass-astrafer-bottom-decoration'} src={claim_episode_bottem_horn} alt={''}/>

      </Modal>
    </>
  );
}

export {MintpassAstraferModal}
