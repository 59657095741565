// Meteor class
import {WeaponType, Global} from './Global.js';
import Phaser from 'phaser';
//import {Phaser} from './phaser360.js';

export const MeteorType = {
    Small: 0,
    Medium: 1,
    Large: 2,
}

export default class Meteor extends Phaser.Physics.Arcade.Sprite
{
    throttle = 0;
    
    constructor(game, x, y, img) {
        super(game, x, y, img);

        this.hitCount = 1;
        this.totalHitCount = 1;
        //this.meteorID = ID_COUNTER;
        //ID_COUNTER++;
    }

    configure ()
    {
        this.angle = -90;
        this.meteorType = MeteorType.Small;
        this.body.angularDrag = 10;//120;
        this.body.maxSpeed = 1024;
        //this.body.setSize(64, 64, true);
    }

    getHitCount()
    {
        return this.hitCount;
    }
    
    resetTint()
    {
        this.clearTint();
    }

    getMeteorType()
    {
        return this.meteorType;
    }
    
    onHit(theScene, theWeapon)
    {
        this.tint = 0xff7788;
        theScene.time.addEvent({ delay: 100, callback: this.resetTint, callbackScope: this, loop: false });
        //console.log("METEOR theWeapon:" + theWeapon + " WeaponType.Laser:" + WeaponType.Laser);
        if(theWeapon == WeaponType.Laser)
        {
            //console.log("------> METEOR HIT WITH LASER: this.hitCount:" + this.hitCount);
            this.hitCount--;
        }
        else if(theWeapon == WeaponType.FireBullet)
        {
            //console.log("------> METEOR HIT WITH FIREBULLET: this.hitCount:" + this.hitCount);
            this.hitCount--;
        }
        else if(theWeapon == WeaponType.Missile)
        {
            //console.log("------> METEOR HIT WITH MISSILE: this.hitCount:" + this.hitCount);
            this.hitCount-= 3;
        }
        else if(theWeapon == WeaponType.SideBullet)
        {
            //console.log("------> METEOR HIT WITH SIDEBULLET: this.hitCount:" + this.hitCount);
            this.hitCount-= 3;
        }
        if(this.hitCount < 0)
        {
            this.hitCount = 0;
        }

        //console.log("METEOR HIT COUNT:" + this.hitCount);
        //if(SHOW_METEOR_PROGRESS_BARS)
        //{
            this.updateProgressBar();
        //}
    }

    updateProgressBar()
    {
        if(this.hitCount > 0)
        {
            this.progressBar.visible = true;
        }
        else
        {
            this.progressBar.visible = false;
        }
        if(this.meteorType == MeteorType.Small)
        {
            this.progressBar.visible = false;
        }
        else
        {
            this.progressBar.visible = true;
        }
        var progressRatio = this.hitCount / this.totalHitCount;
        var frameSet = 6- (progressRatio * 6);
        frameSet = Math.floor(frameSet);
        //console.log("SETTING PROGRESS BAR frameSet:" + frameSet + " hitCount:" + this.hitCount + " totalHitCount:" + this.totalHitCount + " progressRatio:" + progressRatio);
        //console.log("progressRatio:" + progressRatio + "frameSet:" + frameSet + " hitCount:" + this.hitCount + " totalHitCount:" + this.totalHitCount);
        //if(frameSet >= 6)

        if(frameSet < 6)
        {
            
            this.progressBar.setFrame(frameSet);
        }
        
    }

    setAnimating(isAnimating)
    {
        if(isAnimating)
        {
            this.anims.play('spin');
        }
        else
        {
            this.anims.stop('spin');
        }
    }
    
    addProgressBar(theProgressBar)
    {
        this.progressBar = theProgressBar;
        this.progressBar.visible = false;
    }

    updateMeteor (delta)
    {
        //if(SHOW_METEOR_PROGRESS_BARS)
        //{
            this.progressBar.x = this.x;
            this.progressBar.y = this.y - 100;
        //}
        this.body.maxAngular = Phaser.Math.Clamp(90 * this.body.speed / 1024, 0, 90);
    }
}


