import React from 'react';

import { walletContext } from '../utils/WalletContext';

import ShowOnScroll from '../old/scripts/show-on-scroll';
import initializeTimelines from '../old/scripts/timelines.js';
import '../old/scripts/overlay.js';
import '../old/scripts/utils.js';

import './Home.scss';
import HeaderBurgerMenu from '../components/HeaderBurgerMenu/HeaderBurgerMenu';
import CustomConnectWalletButton from '../components/CustomeConnectWalletButton/CustomeConnectWalletButton';
import {
  xs_roadmap,
  s_roadmap,
  m_roadmap,
  l_roadmap,
  epic_games_logo,
  home_flare_background,
  kyc_header_boarder,
  pink_button_shape, steam_logo, hyper_play,
} from '../assets';
import Footer from "../components/Footer/Footer";
import PreviewModal, {PreviewModalType} from "../components/Modal/PreviewModal/PreviewModal";
import HeaderComponent from "../components/Header/HeaderComponent";
import FighterLady from "../components/Footer/FooterElements/FighterLady/FighterLady";
import AstrafiteAnnouncementModal from "../components/Modal/AstrafiteAnnouncementModal/AstrafiteAnnouncementModal";
import AnnouncementPopup from "../components/AnnouncementPopup/AnnouncementPopup";
import Gam3AnnouncementModal from "../components/Modal/Gam3Modal/Gam3AnnouncementModal";
import { Roadmap } from "./Roadmap.js";
import { PartnerList } from '../components/PartnerList/PartnerList';

export default class Home extends React.Component {
  static contextType = walletContext.context;

  constructor() {
    super();
    this.state = {
      previewModal: {
        visible: false,
        type: PreviewModalType.None,
        src: '',
      },
    };
  }

  componentDidMount() {
    initializeTimelines();
    setTimeout(function () {
      var instShowOnScroll = new ShowOnScroll();
      instShowOnScroll.init();
    }, 250);
  }

  submitForm(name) {
    document.forms[name].submit();
  }

  handleModalClose() {
    this.setState(
      prevState => {
        return {
          previewModal: {
            visible: false,
            type: PreviewModalType.None,
            src: '',
          },
        };
      },
      () => {},
    );
  }

  showPreview(src, previewType) {
    this.setState(
      prevState => {
        return {
          previewModal: {
            visible: true,
            type: previewType,
            src: src,
          },
        };
      },
      () => {},
    );
  }

  homeModalFactory(modalType) {
    switch (modalType) {
      case 'astrafiteAnnouncementModal':
        return <AstrafiteAnnouncementModal />;
      case 'poster4AnouncementModal':
        return <AnnouncementPopup />;
      case 'gam3AnnouncementModal':
        return <Gam3AnnouncementModal />;
      default:
        return <AstrafiteAnnouncementModal />;
    }
  }

  render() {
    // This HTML was salvaged from the original Figma export and gently massaged
    // until it could be usable inside a React component. It should probably be
    // replaced with something written from scratch.
    return (
      <div>
        <link rel="stylesheet" type="text/css" href="/old/css/styleguide.css" />
        <link rel="stylesheet" type="text/css" href="/old/css/globals.css" />

        <input type="hidden" id="anPageName" name="page" value="tablet-768" />
        <>{this.homeModalFactory('poster4AnouncementModal')}</>
        <div className="mobile-375 screen mobile-screen-adjustment add-blackground-color">
          <div className="x4th-behind-the-scenes-t5gFFk">
            <div className="background-zRcRml">
              <img alt="" className="vector-k6ux6r" src="/old/img/third---vector@1x.png" />
              <div className="background-k6ux6r"></div>
            </div>
            <div className="behind-the-scen-s-anchor-parent-zRcRml">
              <div className="gallery-bW5A25">
                <div className="x1-82uE0g bp4-animate-enter3" show-on-scroll="true">
                  <video src="https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/01.mp4" loop autoPlay="autoplay" playsInline muted></video>
                </div>
                <div className="x2-82uE0g bp4-animate-enter4" show-on-scroll="true">
                  <video src="https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/02.mp4" loop autoPlay="autoplay" playsInline muted></video>
                </div>
                <div className="x3-82uE0g bp4-animate-enter5" show-on-scroll="true">
                  <video src="https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/05.mp4" loop autoPlay="autoplay" playsInline muted></video>
                </div>
                <div className="x4-82uE0g bp4-animate-enter6" show-on-scroll="true">
                  <video src="https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/07.mp4" loop autoPlay="autoplay" playsInline muted></video>
                </div>
                <img alt="" className="gallery-fivebig-82uE0g bp4-animate-enter7" show-on-scroll="true" src="/old/img/galleryfive-big.jpg" />
                <img alt="" className="gallery-sixbig-82uE0g bp4-animate-enter8" show-on-scroll="true" src="/old/img/gallerysix-big.jpg" />
                <img alt="" className="gallery-sevenbig-82uE0g bp4-animate-enter9" show-on-scroll="true" src="/old/img/galleryseven-big.jpg" />
                <img alt="" className="gallery-eightbig-82uE0g bp4-animate-enter10" show-on-scroll="true" src="/old/img/galleryeight-big.jpg" />
                <img alt="" className="gallery-ninebig-82uE0g bp4-animate-enter11" show-on-scroll="true" src="/old/img/gallerynine-big.jpg" />
                <img alt="" className="gallery-tenbig-82uE0g bp4-animate-enter12" show-on-scroll="true" src="/old/img/galleryten-big.jpg" />
                <img alt="" className="gallery-elevenbig-82uE0g bp4-animate-enter13" show-on-scroll="true" src="/old/img/galleryeleven-big.jpg" />
                <img alt="" className="gallery-twelvebig-82uE0g bp4-animate-enter14" show-on-scroll="true" src="/old/img/gallerytwelve-big.jpg" />
                <img alt="" className="gallery-thirteenbig-82uE0g bp4-animate-enter15" show-on-scroll="true" src="/old/img/gallerythirteen-big.jpg" />
                <img alt="" className="gallery-fourteenbig-82uE0g bp4-animate-enter16" show-on-scroll="true" src="/old/img/galleryfourteen-big.jpg" />
                <img alt="" className="gallery-fifteenbig-82uE0g bp4-animate-enter17" show-on-scroll="true" src="/old/img/galleryfifteen-big.jpg" />
                <img alt="" className="gallery-sixteenbig-82uE0g bp4-animate-enter18" show-on-scroll="true" src="/old/img/gallerysixteen-big.jpg" />
              </div>
              <div className="header-boarder-bW5A25">
                <img alt={''} className="psg-border-img" src={kyc_header_boarder} />
              </div>
              <div className="behind-the-scen-art-sneak-peeks-bW5A25 oswald-normal-white-45px">
                <div className="span0-gpLKCj oswald-normal-white-45px">
                  BEHIND THE SCENES
                  <br />
                </div>
                <div className="span1-gpLKCj sofiapro-regular-normal-white-20px">
                  screenshots + <br />
                  concept art + sneak peeks
                </div>
              </div>
            </div>
            <Roadmap
              container="x3rd-roadmap-t5gFFk"
              header="header-boarder-xW0mzL"
              roadmap="roadmap-xW0mzL"
              footer="all-dates-are-e-bject-to-change-xW0mzL"
              image={xs_roadmap}
            />
          </div>
            <div className="x2nd-features-t5gFFk">
              <img alt="" className="features-mobile-ackground-image-JdnJ3Z"
                   src={home_flare_background}/>
              <div className="features-JdnJ3Z">
                <div className="x4168313 bp4-animate-enter24 component component-wrapper not-ready" show-on-scroll="true">
                  <div className="title-L3Nh11">
                    <div className="action-i41683133111685-3AVmTA">ACTION</div>
                  </div>
                  <div className="mobile-image-L3Nh11">
                    <img alt="" className="actionthumb-asXozm"
                         src="/old/img/action-thumb.jpg"/></div>
                  <div className="description-L3Nh11">
                    <div className="pulse-pounding-i41683133111689-2lfl7q">Pulse-pounding mech combat</div>
                  </div>
                </div>

                <div className="x4168314 bp4-animate-enter25 component component-wrapper not-ready" show-on-scroll="true">
                  <div className="title-hv6H5V">
                    <div className="nf-ts-i41683143111699-gdqta6">NFTs</div>
                  </div>
                  <div className="mobile-image-hv6H5V">
                    <img alt="" className="nftthumb-sxZv2Q"
                         src="/old/img/nft-thumb.jpg"/>
                  </div>
                  <div className="description-hv6H5V">
                    <div className="generative-avat-i41683143111703-YWB2hu">Generative avatars and upgradable mechs</div>
                  </div>
                </div>

                <div className="x4168315 bp4-animate-enter26 component component-wrapper not-ready" show-on-scroll="true">
                  <div className="title-CbbaL2">
                    <div className="discover-i41683153111692-dGRpDr">DISCOVER</div>
                  </div>
                  <div className="mobile-image-CbbaL2">
                    <img alt="" className="discoverthumb-THphTU"
                         src="/old/img/discover-thumb.jpg"/></div>
                  <div className="description-CbbaL2">
                    <div className="explore-the-uni-i41683153111696-iXGF7W">Explore the universe and encounter alien
                      species
                    </div>
                  </div>
                </div>

                <div className="x4168316 bp4-animate-enter27 component component-wrapper not-ready" show-on-scroll="true">
                  <div className="title-qUo9It">
                    <div className="guilds-i41683163111706-JOIXYY">GUILDS</div>
                  </div>
                  <div className="mobile-image-qUo9It">
                    <img alt="" className="guildsthumb-Zrknoe" src="/old/img/guilds-thumb.jpg"/>
                    <div className="frame-23-Zrknoe"></div>
                  </div>
                  <div className="description-qUo9It">
                    <div className="govern-the-gala-i41683163111710-EpAHIt">
                      Govern the galaxy with player organisations and corporations
                    </div>
                  </div>
                </div>

              </div>
              <div className="header-boarder-JdnJ3Z">
                <img alt={""} className='psg-border-img'
                     src={kyc_header_boarder}/></div>
              <div className="features-lnlBD1 oswald-normal-white-45px">CELESTIAL BODIES</div>
            </div>
            <img alt="" className="second-vector-t5gFFk" src="/old/img/second---vector@1x.png"/>
            <div className="x1st-overview-t5gFFk">
              <div className="background-CjlwMD">
                <img alt="" className="vector-oztY4K" src="/old/img/third---vector@1x.png"/>
                <div className="background-oztY4K"></div>
              </div>
              <div className="overview-anchor-parent-CjlwMD">
                <div className="content-Ps4lxs">
                  <div className="logo-v1m28y bp4-animate-enter28" show-on-scroll="true">
                    <div className="pgin-3-d-logo-VAtsLH">
                      <img alt="" className="pgin-3-d-logo-xDXux4"
                           src="/old/img/pgin-3d-logo@2x.png"/></div>
                  </div>
                  <div className="text-v1m28y">
                    <div className="top-para-X70AS3">
                      <div className="phantom-galaxie-ptivating-story-bQA0s2 valign-text-middle sofiapro-bold-white-18px">
                    <span className="span-phantom-galaxies-captivating-story">
                      <span className="span0-5o3xN9 sofiapro-bold-white-18px">PHANTOM GALAXIES </span>
                      <span className="span1-5o3xN9 sofiapro-regular-normal-white-18px">
                        combines open-world space sim with fast-paced mech shooter and a captivating story.
                      </span>
                    </span>
                      </div>
                    </div>
                    <div className="mid-para-X70AS3">
                      <div
                        className="once-enemies-th-cestral-planets-W6ViEP valign-text-middle sofiapro-regular-normal-white-18px">
                        Once enemies, the factions of Neoterra now share a dangerous alien foe, the Sha’Kari. The
                        Sha’Kari Zealots are the priest cast of the Sha’Har race, intent on destroying mankind for
                        desecrating
                        their ancestral planets.
                      </div>
                    </div>
                    <div className="bottom-para-X70AS3">
                      <div className="choose-the-tran-to-the-sha-kari-XFP82Q sofiapro-regular-normal-white-18px">
                        Choose the transforming Mecha Starfighter that suits your play style - Lancer, Buster, Assault,
                        and
                        Breacher classes and take the fight to the Sha’Kari!
                      </div>
                    </div>
                  </div>
                  <div className="button-v1m28y">
                    <div className="frame-13-iHvpsa">
                      <div className="circle-pattern-F1TFEW">
                        <img alt="" className="circle-pattern-x5JALL" src="/old/img/circle-pattern-1@2x.png"/>
                      </div>
                      <a href="/download">
                        <div className="watch-trailer-button-F1TFEW smart-layers-pointers">
                          <img alt="" className="button-sJcPJa" src={pink_button_shape}/>
                          <div className="text-i4168340453-sJcPJa oswald-normal-white-20px">PLAY NOW</div>
                        </div>

                      </a>
                      <div className={'mint-buttons-container wishlist-button-container'}>
                        <a href={'https://store.steampowered.com/app/1272550/Phantom_Galaxies/'} target="_blank"
                           rel="noreferrer" className={'astrafer-mint-button home_play_button'}>
                          <img src={steam_logo} alt={'steam logo'} />
                          PLAY ON STEAM
                        </a>
                        <a href={'https://store.epicgames.com/p/phantom-galaxies-8f4ca5'} target="_blank"
                           rel="noreferrer" className={'astrafer-mint-button home_play_button'}>
                          <img src={epic_games_logo} alt={'epic games logo'} />
                          PLAY ON EPIC
                        </a>
                        <a href={'https://store.hyperplay.xyz/game/phantomgalaxies'} target="_blank" rel="noreferrer" className={"astrafer-mint-button home_play_button"}>
                          <img src={hyper_play} alt={'play with hyper play badge'} />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="images-v1m28y bp4-animate-enter29" show-on-scroll="true">
                    <div className="frame-13-IHMWSm">
                    <div className="space-xlC5e3">
                        <img alt="" className="space-cgI8nn" src="/old/img/space@1x.png"/>
                      </div>
                      <div className="mecha-xlC5e3">
                        <img alt="" className="mecha-gjb8Dx" src="/old/img/mecha@1x.png"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="top-video-t5gFFk">
              <div className="video-banner-placeholder-E2cZe2">
                <video
                  src="https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/PhantomGalaxiesWebBG3.mp4"
                  loop
                  autoPlay="autoplay"
                  playsInline
                  muted></video>
              </div>
              <a href="/download">
                <div className="watch-trailer-button-E2cZe2 smart-layers-pointers">
                  <img alt="" className="button-PJToem" src={pink_button_shape}/>
                  <div className="text-i4168355453-PJToem oswald-normal-white-20px">PLAY NOW</div>
                </div>

              </a>
              <HeaderBurgerMenu/>
              <div className="small-top-bar-E2cZe2">
                <div className="pgin-2-tone-logo-jxN8YD">
                  <img alt="" className="pgin-2-tone-logo-VZvFmn" src="/old/img/pgin-2tone-logo@2x.png"/>
                </div>
              </div>
            </div>
            <img alt="" className="first-vector-t5gFFk" src="/old/img/third---vector@1x.png"/>
            <div className="connect-wallet-subscribe-t5gFFk">
              <div className="background-43D5WV"></div>
              <div className="content-43D5WV bp4-animate-enter30" show-on-scroll="true">
                <div className="boarder-7YoFaY">
                  <img alt="" className="boarderdetail-voqnr2" src="/old/img/boarder-detail@2x.png"/>
                  <img alt="" className="line-5-voqnr2" src="/old/img/line-5-1@2x.png"/>
                </div>
                <div className="connect-your-wa-ree-nft-airdrop-7YoFaY sofiapro-regular-normal-white-18px"
                     style={{left: "calc(50.00% - 90px)"}}>
                  {walletContext.signed ? (
                    <>Your wallet is connected</>
                  ) : (
                    <>Connect your wallet to <br/> Phantom Galaxies</>
                  )}
                </div>
                <div className={"home-connect-wallet-button"}>
                  <div className={"home-connect-wallet-button-mobile"}>
                <CustomConnectWalletButton/>
                  </div>
                </div>
                <div className="how-to-connect-wallet-7YoFaY">
                  <div className="group-15-vx1KRW">
                    <img alt="" className="curved-boarder-TgXUwK" src="/old/img/curved-boarder-2@2x.png"/>
                    <img alt="" className="curved-boarder-DGh5P5" src="/old/img/curved-boarder-3@2x.png"/>
                  </div>
                  <p className="to-connect-your-gh-the-app-menu-vx1KRW">
                    To connect your wallet <br/>on tablets and mobiles, <br/>please use the built-in browser <br/>in the
                    MetaMask app. <br/>It is accessed through the app menu.
                  </p>
                </div>
                <div className="plus-seperator-7YoFaY">
                  <img alt="" className="elplus-QR935a" src="/old/img/el-plus@2x.png"/>
                  <img alt="" className="elplus-NXq5wb" src="/old/img/el-plus@2x.png"/>
                  <img alt="" className="elplus-OHsvtp" src="/old/img/el-plus@2x.png"/>
                  <img alt="" className="elplus-OTUtDf" src="/old/img/el-plus@2x.png"/>
                </div>
                <div className="join-our-online-d-airdrop-dates-7YoFaY sofiapro-regular-normal-white-18px">
                  Join our online community to<br/>get notified of all<br/>upcoming news and dates!
                </div>
                <div className={"discord-button-alignment"}>
                  <a href="https://discord.gg/PhantomGalaxies" target="_blank" rel="noreferrer">
                    <div className="center-discord-button">
                      <div className={"discord-button-design"}>
                        <div className="discord-button-text-design">JOIN THE DISCORD</div>
                      </div>
                    </div>
                  </a>
                </div>

                <div className="boarder-FGgTwt" style={{top: "535px"}}>
                  <img alt="" className="boarderdetail-VCEXXq" src="/old/img/boarder-detail-1@2x.png"/>
                  <img alt="" className="line-5-voqnr2" src="/old/img/line-5-1@2x.png"/>
                </div>
              </div>
            </div>
          </div>
          <div className="tablet-768 screen adjust-page-dimension">

            <div className="x4th-behind-the-scenes-rNEo8r">
              <img alt="" className="vector-qTHYK5" src="/old/img/third---vector-1@1x.png"/>
              <div className="background-qTHYK5"></div>
              <div className="behind-the-scen-s-anchor-parent-qTHYK5">
                <div className="gallery-TJQIRx">
                  <div className="row-1-IgWiOS bp3-animate-enter3" show-on-scroll="true">
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    <a onClick={() =>{this.showPreview("https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/01.mp4", PreviewModalType.Video)}}>
                      <div className="x1-i9rxnA">
                        <video
                          src="https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/01.mp4"
                          loop
                          autoPlay="autoplay"
                          playsInline
                          muted></video>
                      </div>

                    </a>
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    <a onClick={() =>{this.showPreview("https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/02.mp4", PreviewModalType.Video)}}>
                      <div className="x2-i9rxnA">
                        <video
                          src="https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/02.mp4"
                          loop
                          autoPlay="autoplay"
                          playsInline
                          muted></video>
                      </div>

                    </a>
                  </div>
                  <div className="row-2-IgWiOS bp3-animate-enter4" show-on-scroll="true">
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    <a onClick={() =>{this.showPreview("https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/05.mp4", PreviewModalType.Video)}}>
                      <div className="x3-DdJbbb">
                        <video
                          src="https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/05.mp4"
                          loop
                          autoPlay="autoplay"
                          playsInline
                          muted></video>
                      </div>

                    </a>
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    <a onClick={() =>{this.showPreview("https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/07.mp4", PreviewModalType.Video)}}>
                      <div className="x4-DdJbbb">
                        <video
                          src="https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/07.mp4"
                          loop
                          autoPlay="autoplay"
                          playsInline
                          muted></video>
                      </div>

                    </a>
                  </div>
                  <div className="row-3-IgWiOS bp3-animate-enter5" show-on-scroll="true">
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    <a onClick={() =>{this.showPreview("/old/img/galleryfive-big.jpg", PreviewModalType.Image)}}>
                      <img alt="" className="gallery-five-ZvxrkK" src="/old/img/gallery-five.jpg"/>

                    </a>
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    <a onClick={() =>{this.showPreview("/old/img/gallerysix-big.jpg", PreviewModalType.Image)}}>
                      <img alt="" className="gallery-six-ZvxrkK" src="/old/img/gallery-six.jpg"/>

                    </a>
                  </div>
                  <div className="row-4-IgWiOS bp3-animate-enter6" show-on-scroll="true">
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    <a onClick={() =>{this.showPreview("/old/img/galleryseven-big.jpg", PreviewModalType.Image)}}>
                      <img alt="" className="gallery-seven-mkppSb" src="/old/img/gallery-seven.jpg"/>

                    </a>
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    <a onClick={() =>{this.showPreview("/old/img/galleryeight-big.jpg", PreviewModalType.Image)}}>
                      <img alt="" className="gallery-eight-mkppSb" src="/old/img/gallery-eight.jpg"/>

                    </a>
                  </div>
                  <div className="row-5-IgWiOS bp3-animate-enter7" show-on-scroll="true">
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    <a onClick={() =>{this.showPreview("/old/img/gallerynine-big.jpg", PreviewModalType.Image)}}>
                      <img alt=""
                           className="gallery-nine-12uf5c"
                           src="/old/img/gallery-nine.jpg"/>

                    </a>
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    <a onClick={() =>{this.showPreview("/old/img/galleryten-big.jpg", PreviewModalType.Image)}}>
                      <img alt=""
                           className="gallery-ten-12uf5c"
                           src="/old/img/gallery-ten.jpg"/>

                    </a>
                  </div>
                  <div className="row-6-IgWiOS bp3-animate-enter8" show-on-scroll="true">
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    <a onClick={() =>{this.showPreview("/old/img/galleryeleven-big.jpg", PreviewModalType.Image)}}>
                      <img alt=""
                           className="gallery-eleven-fDPKW7"
                           src="/old/img/gallery-eleven.jpg"/>

                    </a>
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    <a onClick={() =>{this.showPreview("/old/img/gallerytwelve-big.jpg", PreviewModalType.Image)}}>
                      <img alt=""
                           className="gallery-twelve-fDPKW7"
                           src="/old/img/gallery-twelve.jpg"/>

                    </a>
                  </div>
                  <div className="row-7-IgWiOS bp3-animate-enter9" show-on-scroll="true">
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    <a onClick={() =>{this.showPreview("/old/img/gallerythirteen-big.jpg", PreviewModalType.Image)}}>
                      <img alt=""
                           className="gallery-thirteen-Ctekbz"
                           src="/old/img/gallery-thirteen.jpg"/>

                    </a>
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    <a onClick={() =>{this.showPreview("/old/img/galleryfourteen-big.jpg", PreviewModalType.Image)}}>
                      <img alt=""
                           className="gallery-fourteen-Ctekbz"
                           src="/old/img/gallery-fourteen.jpg"/>

                    </a>
                  </div>
                  <div className="row-8-IgWiOS bp3-animate-enter10" show-on-scroll="true">
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    <a onClick={() =>{this.showPreview("/old/img/galleryfifteen-big.jpg", PreviewModalType.Image)}}>
                      <img alt=""
                           className="gallery-fifteen-gsw2e0"
                           src="/old/img/gallery-fifteen.jpg"/>

                    </a>
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                    <a onClick={() =>{this.showPreview("/old/img/gallerysixteen-big.jpg", PreviewModalType.Image)}}>
                      <img alt=""
                           className="gallery-sixteen-gsw2e0"
                           src="/old/img/gallery-sixteen.jpg"/>

                    </a>
                  </div>
                </div>
                <div className="header-boarder-TJQIRx">
                  <img alt={""} className='psg-border-img'
                       src={kyc_header_boarder}/></div>
                <div className="behind-the-scen-art-sneak-peeks-TJQIRx oswald-normal-white-45px">
                  <span className="span0-IrKcKQ oswald-normal-white-45px">BEHIND THE SCENES<br/></span><span
                  className="span1-IrKcKQ sofiapro-regular-normal-white-20px">screenshots + concept art + sneak peeks</span>
                </div>
              </div>
            </div>
            <Roadmap
              container="x3rd-roadmap-rNEo8r"
              header="header-boarder-9GI6Hr"
              roadmap="roadmap-9GI6Hr"
              footer="all-dates-are-e-bject-to-change-9GI6Hr"
              image={s_roadmap}
            />
            <div className="x2nd-features-rNEo8r">
              <img alt="" className="features-mobile-ackground-image-JFmZwf"
                   src={home_flare_background}/>
              <div className="features-JFmZwf">
                <div className="x4166046 bp3-animate-enter16 component component-wrapper not-ready" show-on-scroll="true">
                  <div className="title-a5i8QU">
                    <div className="action-i41660463111656-Rzz40P">ACTION</div>
                  </div>
                  <div className="tablet-image-a5i8QU">
                    <img alt="" className="actionthumb-kdOMAz"
                         src="/old/img/action-thumb.jpg"/></div>
                  <div className="description-a5i8QU">
                    <div className="pulse-pounding-i41660463111660-0FRSKY">Pulse-pounding mech combat</div>
                  </div>
                </div>

                <div className="x4166047 bp3-animate-enter17 component component-wrapper not-ready" show-on-scroll="true">
                  <div className="title-QPXGiZ">
                    <div className="nf-ts-i41660473111670-iiggtJ">NFTs</div>
                  </div>
                  <div className="tablet-image-QPXGiZ">
                    <img alt="" className="nftthumb-cImcEF"
                         src="/old/img/nft-thumb.jpg"/>
                  </div>
                  <div className="description-QPXGiZ">
                    <div className="generative-avat-i41660473111674-2iCBfx">Generative avatars and upgradable mechs</div>
                  </div>
                </div>

                <div className="x4166048 bp3-animate-enter18 component component-wrapper not-ready" show-on-scroll="true">
                  <div className="title-AKTdJa">
                    <div className="discover-i41660483111663-7s9s3K">DISCOVER</div>
                  </div>
                  <div className="tablet-image-AKTdJa">
                    <img alt="" className="discoverthumb-2KtTdt" src="/old/img/discover-thumb.jpg"/>
                  </div>
                  <div className="description-AKTdJa">
                    <div className="explore-the-uni-i41660483111667-UHvHYF">Explore the universe and encounter alien
                      species
                    </div>
                  </div>
                </div>

                <div className="x4166049 bp3-animate-enter19 component component-wrapper not-ready" show-on-scroll="true">
                  <div className="title-rrthZx">
                    <div className="guilds-i41660493111677-6aPOBP">GUILDS</div>
                  </div>
                  <div className="tablet-image-rrthZx">
                    <img alt="" className="guildsthumb-m1cEHf"
                         src="/old/img/guilds-thumb.jpg"/></div>
                  <div className="description-rrthZx">
                    <div className="govern-the-gala-i41660493111681-fYpuEp">
                      Govern the galaxy with player organisations and corporations
                    </div>
                  </div>
                </div>

              </div>
              <div className="header-boarder-JFmZwf">
                <img alt={""} className='psg-border-img'
                     src={kyc_header_boarder}/></div>
              <div className="features-eubMuc oswald-normal-white-45px">CELESTIAL BODIES</div>
            </div>
            <img alt="" className="second-vector-rNEo8r" src="/old/img/second---vector-1@1x.png"/>
            <div className="top-video-rNEo8r">
              <div className="video-banner-placeholder-jr6cB2">
                <video
                  src="https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/PhantomGalaxiesWebBG3.mp4"
                  loop
                  autoPlay="autoplay"
                  playsInline
                  muted></video>
              </div>
              <a href="/download">
                <div className="watch-trailer-button-jr6cB2 smart-layers-pointers">
                  <img alt="" className="button-ZPlq1b" src={pink_button_shape}/>
                  <div className="text-i4166057453-ZPlq1b oswald-normal-white-20px">PLAY NOW</div>
                </div>

              </a>
              <HeaderBurgerMenu/>
              <div className="small-top-bar-jr6cB2">
                <div className="pgin-2-tone-logo-RXxrLp">
                  <img alt="" className="pgin-2-tone-logo-VgNFJp" src="/old/img/pgin-2tone-logo@2x.png"/>
                </div>
              </div>
            </div>
            <img alt="" className="first-vector-rNEo8r" src="/old/img/first---vector-1@1x.png"/>
            <div className="x1st-overview-rNEo8r">
              <img alt="" className="vector-2THyM8" src="/old/img/vector-5@1x.png"/>
              <div className="background-2THyM8"></div>
              <div className="frame-15-2THyM8">
                <div className="space-Jxkf53">
                  <img alt="" className="space-XfMJx5" src="/old/img/space-1@1x.png"/></div>
                <div className="mecha-Jxkf53">
                  <img alt="" className="mecha-qUoGRd" src="/old/img/mecha-1@1x.png"/></div>
              </div>
              <div className="text-and-button-2THyM8">
                <div className="text-and-button-08vhKw">
                  <div className="text-jxgxhP">
                    <div className="top-para-Qxv98s">
                      <div className="phantom-galaxie-ptivating-story-cl5Ukp valign-text-middle sofiapro-bold-white-18px">
                    <span><span className="span0-PGLsaW sofiapro-bold-white-18px">PHANTOM GALAXIES </span><span
                      className="span1-PGLsaW sofiapro-regular-normal-white-18px">
                        combines open-world space sim with fast-paced mech shooter and a captivating story.</span>
                    </span>
                      </div>
                    </div>
                    <div className="mid-para-Qxv98s">
                      <div
                        className="once-enemies-th-cestral-planets-c2a4zr valign-text-middle sofiapro-regular-normal-white-18px">
                        Once enemies, the factions of Neoterra now share a dangerous alien foe, the Sha’Kari. The
                        Sha’Kari Zealots are the priest cast of the Sha’Har race, intent on destroying mankind for
                        desecrating
                        their ancestral planets.
                      </div>
                    </div>
                    <div className="bottom-para-Qxv98s">
                      <div className="choose-the-tran-to-the-sha-kari-Tf4aiI sofiapro-regular-normal-white-18px">
                        Choose the transforming Mecha Starfighter that suits your play style - Lancer, Buster, Assault,
                        and
                        Breacher classes and take the fight to the Sha’Kari!
                      </div>
                    </div>
                  </div>
                  <div className="button-jxgxhP">
                    <div className="frame-13-93vPf0">
                      <div className="circle-pattern-7vA0oV">
                        <img alt="" className="circle-pattern-8iyU8l" src="/old/img/circle-pattern-3@2x.png"/>
                      </div>
                      <a href="/download">
                        <div className="watch-trailer-button-7vA0oV smart-layers-pointers">
                          <img alt="" className="button-kXF3KV" src={pink_button_shape}/>
                          <div className="text-i4166080453-kXF3KV oswald-normal-white-20px">PLAY NOW</div>
                        </div>

                      </a>

                    </div>
                  </div>
                </div>
              </div>
              <div className="pgin-3-d-logo-2THyM8 bp3-animate-enter20" show-on-scroll="true">
                <img alt="" className="pgin-3-d-logo-Rcfc8Y" src="/old/img/pgin-3d-logo-1@1x.png"/>
              </div>
              <div className={'mint-buttons-container wishlist-button-container'}>
                <a href={'https://store.steampowered.com/app/1272550/Phantom_Galaxies/'} target="_blank"
                   rel="noreferrer" className={'astrafer-mint-button home_play_button'}>
                  <img src={steam_logo} alt={'steam logo'} />
                  PLAY ON STEAM
                </a>
                <a href={'https://store.epicgames.com/p/phantom-galaxies-8f4ca5'} target="_blank" rel="noreferrer"
                   className={'astrafer-mint-button home_play_button'}>
                  <img src={epic_games_logo} alt={'epic games logo'} />
                  PLAY ON EPIC
                </a>
                <a href={'https://store.hyperplay.xyz/game/phantomgalaxies'} target="_blank" rel="noreferrer"
                   className={"astrafer-mint-button home_play_button"}>
                  <img src={hyper_play} alt={'play with hyper play badge'} />
                </a>
              </div>
            </div>
            <div className="connect-wallet-subscribe-rNEo8r">
              <div className="background-k9bRI5"></div>
              <div className="side-dots-k9bRI5">
                <img alt="" className="sidedots-xwKtef" src="/old/img/side-dots@2x.png"/>
                <img alt="" className="sidedots-KVkrER" src="/old/img/side-dots-1@2x.png"/>
              </div>
              <div className="content-k9bRI5">
                <div className="boarder-LT4jn6">
                  <img alt="" className="boarderdetail-xqVq7n" src="/old/img/boarder-detail-2@2x.png"/>
                  <img alt="" className="border-line" src="/old/img/line-5-4@1x.png"/>
                </div>
                <div className="connect-your-wa-ree-nft-airdrop-LT4jn6 sofiapro-regular-normal-white-18px"
                     style={{left: "calc(50.00% - 160px)"}}>
                  {walletContext.signed ? (
                    <>Your wallet is connected</>
                  ) : (
                    <p className={"connect-wallet-general-text-design"}>Connect your wallet to Phantom Galaxies</p>
                  )}
                </div>
                <div className={"home-connect-wallet-button"}>
                  <div className={"home-connect-wallet-button-tablet"}>
                <CustomConnectWalletButton/>
                  </div>
                </div>
                <div className="how-to-connect-wallet-LT4jn6">
                  <img alt="" className="curved-boarder-XlYfku" src="/old/img/curved-boarder-6@2x.png"/>
                  <p className="to-connect-your-gh-the-app-menu-XlYfku">
                    To connect your wallet on tablets and mobiles, <br/>please use the built-in browser in the MetaMask
                    app.
                    <br/>It is accessed through the app menu.
                  </p>
                  <img alt="" className="curved-boarder-AWdgdO" src="/old/img/curved-boarder-7@2x.png"/>
                </div>
                <div className="plus-seperator-LT4jn6">
                  <img alt="" className="elplus-TPGjmp" src="/old/img/el-plus-4@2x.png"/>
                  <img alt="" className="elplus-ZfQWze" src="/old/img/el-plus-4@2x.png"/>
                  <img alt="" className="elplus-WgsR4O" src="/old/img/el-plus-4@2x.png"/>
                  <img alt="" className="elplus-0L3Af1" src="/old/img/el-plus-4@2x.png"/>
                </div>
                <div className="join-our-online-d-airdrop-dates-LT4jn6 sofiapro-regular-normal-white-18px">
                  Join our online community to get notified of all<br/>upcoming news and dates!
                </div>
                <div className={"discord-button-alignment"}>
                <a href="https://discord.gg/PhantomGalaxies" target="_blank" rel="noreferrer">
                  <div className="center-discord-button">
                    <div className={"discord-button-design"}>
                    <div className="discord-button-text-design">JOIN THE DISCORD</div>
                    </div>
                  </div>
                </a>
                </div>
                <div className="boarder-B5pHq9" style={{top: "515px"}}>
                  <img alt="" className="boarderdetail-xqVq7n" src="/old/img/boarder-detail-2@2x.png"/>
                  <img alt="" className="border-line" src="/old/img/line-5-4@1x.png"/>
                </div>
              </div>
            </div>
          </div>
          <div className="desktop-1024 screen set-the-page-dimension">
            <Roadmap
              container="x3rd-roadmap-6S8wPY"
              header="header-boarder-Whsi8J"
              roadmap="roadmap-HFhJsM"
              footer="all-dates-are-e-bject-to-change-Whsi8J"
              image={m_roadmap}
            />
            <div className="x4th-behind-the-scenes-6S8wPY">
              <div className="background-sxDsFF"></div>
              <div className="gallery-sxDsFF">
                <div className="row-1-X9nsZm bp2-animate-enter8" show-on-scroll="true">
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/01.mp4", PreviewModalType.Video)}}>
                    <div className="x1-No1TJh">
                      <video
                        src="https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/01.mp4"
                        loop
                        autoPlay="autoplay"
                        playsInline
                        muted></video>
                    </div>

                  </a>
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/02.mp4", PreviewModalType.Video)}}>
                    <div className="x2-No1TJh">
                      <video
                        src="https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/02.mp4"
                        loop
                        autoPlay="autoplay"
                        playsInline
                        muted></video>
                    </div>

                  </a>
                </div>
                <div className="row-2-X9nsZm bp2-animate-enter9" show-on-scroll="true">
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/05.mp4", PreviewModalType.Video)}}>
                    <div className="x3-uxyd1O">
                      <video
                        src="https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/05.mp4"
                        loop
                        autoPlay="autoplay"
                        playsInline
                        muted></video>
                    </div>

                  </a>
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/07.mp4", PreviewModalType.Video)}}>
                    <div className="x4-uxyd1O">
                      <video
                        src="https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/07.mp4"
                        loop
                        autoPlay="autoplay"
                        playsInline
                        muted></video>
                    </div>

                  </a>
                </div>
                <div className="row-3-X9nsZm bp2-animate-enter10" show-on-scroll="true">
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("/old/img/galleryfive-big.jpg", PreviewModalType.Image)}}>
                    <img alt=""
                         className="gallery-five-zxmUg3"
                         src="/old/img/gallery-five.jpg"/>

                  </a>
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("/old/img/gallerysix-big.jpg", PreviewModalType.Image)}}>
                    <img alt=""
                         className="gallery-six-zxmUg3"
                         src="/old/img/gallery-six.jpg"/>

                  </a>
                </div>
                <div className="row-4-X9nsZm bp2-animate-enter11" show-on-scroll="true">
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("/old/img/galleryseven-big.jpg", PreviewModalType.Image)}}>
                    <img alt=""
                         className="gallery-seven-vArlVR"
                         src="/old/img/gallery-seven.jpg"/>

                  </a>
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("/old/img/galleryeight-big.jpg", PreviewModalType.Image)}}>
                    <img alt=""
                         className="gallery-eight-vArlVR"
                         src="/old/img/gallery-eight.jpg"/>

                  </a>
                </div>
                <div className="row-5-X9nsZm bp2-animate-enter12" show-on-scroll="true">
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("/old/img/gallerynine-big.jpg", PreviewModalType.Image)}}>
                    <img alt=""
                         className="gallery-nine-UiGCqi"
                         src="/old/img/gallery-nine.jpg"/>

                  </a>
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("/old/img/galleryten-big.jpg", PreviewModalType.Image)}}>
                    <img alt=""
                         className="gallery-ten-UiGCqi"
                         src="/old/img/gallery-ten.jpg"/>

                  </a>
                </div>
                <div className="row-6-X9nsZm bp2-animate-enter13" show-on-scroll="true">
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("/old/img/galleryeleven-big.jpg", PreviewModalType.Image)}}>
                    <img alt=""
                         className="gallery-eleven-hoWSuP"
                         src="/old/img/gallery-eleven.jpg"/>

                  </a>
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("/old/img/gallerytwelve-big.jpg", PreviewModalType.Image)}}>
                    <img alt=""
                         className="gallery-twelve-hoWSuP"
                         src="/old/img/gallery-twelve.jpg"/>

                  </a>
                </div>
                <div className="row-7-X9nsZm bp2-animate-enter14" show-on-scroll="true">
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("/old/img/gallerythirteen-big.jpg", PreviewModalType.Image)}}>
                    <img alt=""
                         className="gallery-thirteen-woVKLb"
                         src="/old/img/gallery-thirteen.jpg"/>

                  </a>
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("/old/img/galleryfourteen-big.jpg", PreviewModalType.Image)}}>
                    <img alt=""
                         className="gallery-fourteen-woVKLb"
                         src="/old/img/gallery-fourteen.jpg"/>

                  </a>
                </div>
                <div className="row-8-X9nsZm bp2-animate-enter15" show-on-scroll="true">
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("/old/img/galleryfifteen-big.jpg", PreviewModalType.Image)}}>
                    <img alt=""
                         className="gallery-fifteen-PAYW3k"
                         src="/old/img/gallery-fifteen.jpg"/>

                  </a>
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("/old/img/gallerysixteen-big.jpg", PreviewModalType.Image)}}>
                    <img alt=""
                         className="gallery-sixteen-PAYW3k"
                         src="/old/img/gallery-sixteen.jpg"/>

                  </a>
                </div>
              </div>
              <div className="header-boarder-sxDsFF">
                <img alt={""} className='psg-border-img'
                     src={kyc_header_boarder}/></div>
              <div className="behind-the-scen-art-sneak-peeks-sxDsFF oswald-normal-white-45px">
                <span className="span0-1gPSal oswald-normal-white-45px">BEHIND THE SCENES<br/></span><span
                className="span1-1gPSal sofiapro-regular-normal-white-20px">screenshots + concept art + sneak peeks</span>
              </div>
            </div>
            <div className="x2nd-features-6S8wPY">
              <div className="features-background-UZqxIZ">
                <img alt="" className="features-background-image-GZV0xp" src="/old/img/featuresbackgroundimage.png"/>
              </div>
              <div className="features-UZqxIZ">
                <div className="x4163776 bp2-animate-enter16 component component-wrapper not-ready" show-on-scroll="true">
                  <div className="feature-DoBmaJ">
                    <div className="title-i41637763111611-l4FFgU">ACTION</div>
                    <div className="image-l4FFgU">
                      <img alt="" className="actionthumb-xNkHo7"
                           src="/old/img/action-thumb.jpg"/>
                    </div>
                  </div>
                  <div className="description-i41637763111614-DoBmaJ">Pulse-pounding mech <br/> combat</div>
                </div>

                <div className="x4163777 bp2-animate-enter17 component component-wrapper not-ready" show-on-scroll="true">
                  <div className="feature-lEPKNs">
                    <div className="title-i41637773111622-wHGcb2">NFTs</div>
                    <div className="image-wHGcb2">
                      <img alt="" className="nftthumb-VmARa6" src="/old/img/nft-thumb.jpg"/>
                    </div>
                  </div>
                  <div className="description-i41637773111625-lEPKNs">Generative avatars and<br/>upgradable mechs</div>
                </div>

                <div className="x4163778 bp2-animate-enter18 component component-wrapper not-ready" show-on-scroll="true">
                  <div className="feature-1D3m1x">
                    <div className="title-i41637783111628-dGGbV2">DISCOVER</div>
                    <div className="image-dGGbV2">
                      <img alt="" className="discoverthumb-VxiBdG"
                           src="/old/img/discover-thumb.jpg"/></div>
                  </div>
                  <div className="description-i41637783111631-1D3m1x">Explore the universe and<br/>encounter alien species
                  </div>
                </div>

                <div className="x4163779 bp2-animate-enter19 component component-wrapper not-ready" show-on-scroll="true">
                  <div className="feature-zG7ubQ">
                    <div className="title-i41637793111634-S4rofQ">GUILDS</div>
                    <div className="image-S4rofQ">
                      <img alt="" className="guildsthumb-CsIPi2"
                           src="/old/img/guilds-thumb.jpg"/>
                    </div>
                  </div>
                  <div className="description-i41637793111637-zG7ubQ">
                    Govern the galaxy with<br/>player organisations and<br/>corporations
                  </div>
                </div>

              </div>
              <div className="header-boarder-UZqxIZ">
                <img alt={""} className='psg-border-img'
                     src={kyc_header_boarder}/></div>
              <div className="features-89Ixma oswald-normal-white-45px">CELESTIAL BODIES</div>
            </div>
            <img alt="" className="third-vector-6S8wPY" src="/old/img/third---vector-2@1x.png"/>
            <div className="top-video-6S8wPY">
              <div className="video-banner-placeholder-FEo3J7">
                <video
                  src="https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/PhantomGalaxiesWebBG3.mp4"
                  loop
                  autoPlay="autoplay"
                  playsInline
                  muted></video>
              </div>
              <a href="/download">
                <div className="watch-trailer-button-FEo3J7 smart-layers-pointers">
                  <img alt="" className="button-lR7L6j" src={pink_button_shape}/>
                  <div className="text-i4163787453-lR7L6j oswald-normal-white-20px">PLAY NOW</div>
                </div>

              </a>
              <HeaderComponent noBackground={true}></HeaderComponent>
              <HeaderBurgerMenu/>
            </div>
            <img alt="" className="second-vector-6S8wPY" src="/old/img/fourth---vector@1x.png"/>
            <img alt="" className="first-vector-6S8wPY" src="/old/img/fourth---vector@1x.png"/>
            <div className="x1st-overview-6S8wPY">
              <div className="background-yTCy34"></div>
              <div className="overview-anchor-parent-yTCy34">
                <div className="circle-pattern-Uivlbx">
                  <div className="circle-pattern-sL1zpj">
                    <img alt="" className="circle-pattern-nPpKDN" src="/old/img/circle-pattern-5@2x.png"/>
                  </div>
                </div>
                <div className="pgin-3-d-logo-Uivlbx bp2-animate-enter20" show-on-scroll="true">
                  <img alt="" className="pgin-3-d-logo-cUkqqm" src="/old/img/pgin-3d-logo-2@1x.png"/>
                </div>
                <div className="mecha-and-space-Uivlbx bp2-animate-enter21" show-on-scroll="true">
                  <div className="space-l2vKxk">
                    <img alt="" className="space-QVaAvA" src="/old/img/space-2@1x.png"/></div>
                  <div className="mecha-l2vKxk">
                    <img alt="" className="mecha-8sKa7p" src="/old/img/mecha-2@1x.png"/></div>
                </div>
                <div className="text-and-button-Uivlbx">
                  <div className="text-r7Mzwb">
                    <div className="top-para-stFDrI">
                      <p className="phantom-galaxie-ptivating-story-5KtSMi valign-text-middle sofiapro-bold-white-16px">
                    <span><span className="span0-zgJhCl sofiapro-bold-white-16px">PHANTOM GALAXIES </span><span
                      className="span1-zgJhCl sofiapro-regular-normal-white-16px">
                        combines open-world space sim with fast-paced mech shooter and a captivating story.</span>
                    </span>
                      </p>
                    </div>
                    <div className="mid-para-stFDrI">
                      <p
                        className="once-enemies-th-cestral-planets-2TRDv7 valign-text-middle sofiapro-regular-normal-white-16px">
                        Once enemies, the factions of Neoterra now share a dangerous alien foe, the Sha’Kari. The
                        Sha’Kari Zealots are the priest cast of the Sha’Har race, intent on destroying mankind for
                        desecrating
                        their ancestral planets.
                      </p>
                    </div>
                    <div className="bottom-para-stFDrI">
                      <p className="choose-the-tran-to-the-sha-kari-kNNzLL sofiapro-regular-normal-white-16px">
                        Choose the transforming Mecha Starfighter that suits your play style - Lancer, Buster, Assault,
                        and
                        Breacher classes and take the fight to the Sha’Kari!
                      </p>
                    </div>
                  </div>
                  <a href="/download">
                    <div className="watch-trailer-button-r7Mzwb smart-layers-pointers">
                      <img alt="" className="button-5G2WCG" src={pink_button_shape}/>
                      <div className="text-i4163809453-5G2WCG oswald-normal-white-20px">PLAY NOW</div>
                    </div>

                  </a>
                  <div className={'mint-buttons-container wishlist-button-container'}>
                    <a href={'https://store.steampowered.com/app/1272550/Phantom_Galaxies/'} target="_blank"
                       rel="noreferrer" className={'astrafer-mint-button home_play_button'}>
                      <img src={steam_logo} alt={'steam logo'} />
                      PLAY ON STEAM
                    </a>
                    <a href={'https://store.epicgames.com/p/phantom-galaxies-8f4ca5'} target="_blank" rel="noreferrer"
                       className={'astrafer-mint-button home_play_button'}>
                      <img src={epic_games_logo} alt={'epic games logo'} />
                      PLAY ON EPIC
                    </a>
                    <a href={'https://store.hyperplay.xyz/game/phantomgalaxies'} target="_blank" rel="noreferrer"
                       className={"astrafer-mint-button home_play_button"}>
                      <img src={hyper_play} alt={'play with hyper play badge'} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="connect-wallet-subscribe-6S8wPY">
              <div className="background-LKd7Es"></div>
              <div className="side-dots-LKd7Es">
              <img alt="" className="sidedots-MhhvKr" src="/old/img/side-dots-2@2x.png"/>
                <img alt="" className="sidedots-tuDke5" src="/old/img/side-dots-3@2x.png"/>
              </div>
              <div className="buttons-and-blurbs-LKd7Es bp2-animate-enter22" show-on-scroll="true">
                <div className="boarder-XttvJo">
                  <img alt="" className="boarderdetail-y2HUJu" src="/old/img/boarder-detail-4@2x.png"/>
                  <img alt="" className="line-5-y2HUJu" src="/old/img/line-5-7@1x.png"/>
                </div>
                <div className="boarder-riyljx">
                  <img alt="" className="boarderdetail-bEfezU" src="/old/img/boarder-detail@2x.png"/>
                  <img alt="" className="line-5-bEfezU" src="/old/img/line-5-7@1x.png"/>
                </div>
                <div className="join-discord-XttvJo">
                  <a href="https://discord.gg/PhantomGalaxies" target="_blank" rel="noreferrer">
                    <div className="join-the-discord-button-xrGERy smart-layers-pointers">
                      <div className="text-GDAqTp oswald-normal-white-20px">JOIN THE DISCORD</div>
                    </div>

                  </a>
                  <p className="join-our-online-d-airdrop-dates-xrGERy sofiapro-regular-normal-white-16px"
                     style={{left: "calc(50.00% - 265px)"}}>
                    Join our online community to get notified of all upcoming news and dates!
                  </p>
                </div>
                <div className="plus-seperator-XttvJo">
                  <img alt="" className="elplus-gmXh6a" src="/old/img/el-plus-8@2x.png"/>
                  <img alt="" className="elplus-jrX0t0" src="/old/img/el-plus-8@2x.png"/>
                  <img alt="" className="elplus-OxycEV" src="/old/img/el-plus-8@2x.png"/>
                  <img alt="" className="elplus-G1T7t9" src="/old/img/el-plus-8@2x.png"/>
                </div>
                <div className="connect-wallet-XttvJo">

                  <p className="connect-your-wa-ree-nft-airdrop-IHoPxM sofiapro-regular-normal-white-16px"
                     style={{left: "calc(50.00% - 150px)"}}>
                    {walletContext.signed ? (
                      <>Your wallet is connected</>
                    ) : (
                      <p className={"connect-wallet-general-text-design"}>Connect your wallet to Phantom Galaxies</p>
                    )}
                  </p>
                  <div className={"home-connect-wallet-button"}>
                    <div className={"home-connect-wallet-button-1920"}>
                    <CustomConnectWalletButton/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="desktop-1440 screen set-page-dimensions">
            <Roadmap
              container="x3rd-roadmap-I66pki"
              header="header-boarder-O78C7y"
              roadmap="roadmap-LGRhoP"
              footer="all-dates-are-e-bject-to-change-O78C7y"
              image={l_roadmap}
            />
            <div className="x4th-behind-the-scenes-I66pki">
              <div className="background-L4H7e9"></div>
              <div className="gallery-L4H7e9">
                <div className="row-1-cEK656 bp1-animate-enter8" show-on-scroll="true">
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/01.mp4", PreviewModalType.Video)}}>
                    <div className="x1-vH2jS7">
                      <video
                        src="https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/01.mp4"
                        loop
                        autoPlay="autoplay"
                        playsInline
                        muted></video>
                    </div>

                  </a>
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/02.mp4", PreviewModalType.Video)}}>
                    <div className="x2-vH2jS7">
                      <video
                        src="https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/02.mp4"
                        loop
                        autoPlay="autoplay"
                        playsInline
                        muted></video>
                    </div>

                  </a>
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/05.mp4", PreviewModalType.Video)}}>
                    <div className="x3-vH2jS7">
                      <video
                        src="https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/05.mp4"
                        loop
                        autoPlay="autoplay"
                        playsInline
                        muted></video>
                    </div>

                  </a>
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/07.mp4", PreviewModalType.Video)}}>
                    <div className="x4-vH2jS7">
                      <video
                        src="https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/07.mp4"
                        loop
                        autoPlay="autoplay"
                        playsInline
                        muted></video>
                    </div>

                  </a>
                </div>
                <div className="row-2-cEK656 bp1-animate-enter9" show-on-scroll="true">
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("/old/img/galleryfive-big.jpg", PreviewModalType.Image)}}>
                    <img alt=""
                         className="gallery-five-sxxCNU"
                         src="/old/img/gallery-five.jpg"/>

                  </a>
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("/old/img/gallerysix-big.jpg", PreviewModalType.Image)}}>
                    <img alt=""
                         className="gallery-six-sxxCNU"
                         src="/old/img/gallery-six.jpg"/>

                  </a>
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("/old/img/galleryseven-big.jpg", PreviewModalType.Image)}}>
                    <img alt=""
                         className="gallery-seven-sxxCNU"
                         src="/old/img/gallery-seven.jpg"/>

                  </a>
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("/old/img/galleryeight-big.jpg", PreviewModalType.Image)}}>
                    <img alt=""
                         className="gallery-eight-sxxCNU"
                         src="/old/img/gallery-eight.jpg"/>

                  </a>
                </div>
                <div className="row-3-cEK656 bp1-animate-enter10" show-on-scroll="true">
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("/old/img/gallerynine-big.jpg", PreviewModalType.Image)}}>
                    <img alt=""
                         className="gallery-nine-Wjjpxa"
                         src="/old/img/gallery-nine.jpg"/>

                  </a>
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("/old/img/galleryten-big.jpg", PreviewModalType.Image)}}>
                    <img alt=""
                         className="gallery-ten-Wjjpxa"
                         src="/old/img/gallery-ten.jpg"/>

                  </a>
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("/old/img/galleryeleven-big.jpg", PreviewModalType.Image)}}>
                    <img alt=""
                         className="gallery-eleven-Wjjpxa"
                         src="/old/img/gallery-eleven.jpg"/>

                  </a>
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("/old/img/gallerytwelve-big.jpg", PreviewModalType.Image)}}>
                    <img alt=""
                         className="gallery-twelve-Wjjpxa"
                         src="/old/img/gallery-twelve.jpg"/>

                  </a>
                </div>
                <div className="row-4-cEK656 bp1-animate-enter11" show-on-scroll="true">
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() =>{this.showPreview("/old/img/gallerythirteen-big.jpg", PreviewModalType.Image)}}>
                    <img alt=""
                         className="gallery-thirteen-SwQTLt"
                         src="/old/img/gallery-thirteen.jpg"/>

                  </a>
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() => this.showPreview("/old/img/galleryfourteen-big.jpg", PreviewModalType.Image)}>
                    <img alt=""
                         className="gallery-fourteen-SwQTLt"
                         src="/old/img/gallery-fourteen.jpg"/>

                  </a>
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() => this.showPreview("/old/img/galleryfifteen-big.jpg", PreviewModalType.Image)}>
                    <img alt=""
                         className="gallery-fifteen-SwQTLt"
                         src="/old/img/gallery-fifteen.jpg"/>

                  </a>
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  {/* eslint-disable-next-line no-script-url,jsx-a11y/anchor-is-valid */}
                  <a onClick={() => this.showPreview("/old/img/gallerysixteen-big.jpg", PreviewModalType.Image)}>
                    <img alt=""
                         className="gallery-sixteen-SwQTLt"
                         src="/old/img/gallery-sixteen.jpg"/>

                  </a>
                </div>
              </div>
              <div className="header-boarder-L4H7e9">
                <img alt={""} className='psg-border-img'
                     src={kyc_header_boarder}/>
              </div>
              <div className="behind-the-scen-art-sneak-peeks-L4H7e9 oswald-normal-white-45px">
                <span className="span0-nNHiGL oswald-normal-white-45px">BEHIND THE SCENES<br/></span>
                <span
                className="span1-nNHiGL sofiapro-regular-normal-white-20px">screenshots + concept art + sneak peeks</span>
              </div>
            </div>
            <div className="x2nd-features-I66pki">
              <div className="features-background-aRqiKV">
                <img alt="" className="features-background-image-OxrZAs" src="/old/img/featuresbackgroundimage.png"/>
              </div>
              <div className="features-aRqiKV">
                <div className="x53106167 bp1-animate-enter12 component component-wrapper not-ready"
                     show-on-scroll="true">
                  <div className="feature-Uo0xQR">
                    <div className="title-i531061673111611-gHY9aS">ACTION</div>
                    <div className="image-gHY9aS">
                      <img alt="" className="actionthumb-SdElto"
                           src="/old/img/action-thumb.jpg"/>
                    </div>
                  </div>
                  <div className="description-i531061673111614-Uo0xQR">Pulse-pounding mech combat</div>
                </div>

                <div className="x53106168 bp1-animate-enter13 component component-wrapper not-ready"
                     show-on-scroll="true">
                  <div className="feature-ECrGHK">
                    <div className="title-i531061683111622-zjGOAy">NFTs</div>
                    <div className="image-zjGOAy">
                      <img alt="" className="nftthumb-HkqwQV" src="/old/img/nft-thumb.jpg"/>
                    </div>
                  </div>
                  <div className="description-i531061683111625-ECrGHK">Generative avatars and<br/>upgradable mechs</div>
                </div>

                <div className="x53106169 bp1-animate-enter14 component component-wrapper not-ready"
                     show-on-scroll="true">
                  <div className="feature-N5ZPMv">
                    <div className="title-i531061693111628-KySSXE">DISCOVER</div>
                    <div className="image-KySSXE">
                      <img alt="" className="discoverthumb-BtnsVP"
                           src="/old/img/discover-thumb.jpg"/></div>
                  </div>
                  <div className="description-i531061693111631-N5ZPMv">Explore the universe and<br/>encounter alien
                    species
                  </div>
                </div>

                <div className="x53106170 bp1-animate-enter15 component component-wrapper not-ready"
                     show-on-scroll="true">
                  <div className="feature-Eu0whD">
                    <div className="title-i531061703111634-LfONO6">GUILDS</div>
                    <div className="image-LfONO6">
                      <img alt="" className="guildsthumb-oxWgwF"
                           src="/old/img/guilds-thumb.jpg"/>
                    </div>
                  </div>
                  <div className="description-i531061703111637-Eu0whD">
                    Govern the galaxy with player<br/>organisations and corporations
                  </div>
                </div>

              </div>
              <div className="header-boarder-aRqiKV">

                <img alt={""} className='psg-border-img'
                     src={kyc_header_boarder}/>
              </div>
              <div className="features-CtoNpl oswald-normal-white-45px">CELESTIAL BODIES</div>
            </div>
            <img alt="" className="third-vector-I66pki" src="/old/img/third---vector-3@1x.png"/>
            <div className="top-video-I66pki">
              <div className="video-banner-placeholder-S4B1R7">
                <video
                  src="https://d3a30419ih6t5.cloudfront.net/phantomgalaxies/PhantomGalaxiesWebBG3.mp4"
                  loop
                  autoPlay="autoplay"
                  playsInline
                  muted></video>
              </div>
              <a href="/download">
                <div className="watch-trailer-button-S4B1R7 smart-layers-pointers">
                  <img alt="" className="button-TxwGUr" src={pink_button_shape}/>
                  <div className="text-i53106175453-TxwGUr oswald-normal-white-20px">PLAY NOW</div>
                </div>

              </a>
              <HeaderComponent noBackground={true}/>
            </div>
            <img alt="" className="second-vector-I66pki" src="/old/img/second---vector-3@1x.png"/>
            <img alt="" className="first-vector-I66pki" src="/old/img/first---vector-3@1x.png"/>
            <div className="x1st-overview-I66pki">
              <div className="background-m0ZktO"></div>
              <div className="overview-anchor-parent-m0ZktO">
                <div className="circle-pattern-7Awswi">
                  <div className="circle-pattern-o0lWzC">
                    <img alt="" className="circle-pattern-M26z6i" src="/old/img/circle-pattern-7@2x.png"/>
                  </div>
                </div>
                <div className="pgin-3-d-logo-7Awswi bp1-animate-enter16" show-on-scroll="true">
                  <img alt="" className="pgin-3-d-logo-dfyuhQ" src="/old/img/pgin-3d-logo-3@1x.png"/>
                </div>
                <div className="mecha-and-space-7Awswi bp1-animate-enter17" show-on-scroll="true">
                  <div className="space-xSJHs4">
                    <img alt="" className="space-Dhxd53" src="/old/img/space-3@1x.png"/></div>
                  <div className="mecha-xSJHs4">
                    <img alt="" className="mecha-H038zO" src="/old/img/mecha-3@1x.png"/></div>
                </div>
                <div className="text-and-button-7Awswi">
                  <div className="text-az9eFl">
                    <div className="top-para-Af4GlA">
                      <p className="phantom-galaxie-ptivating-story-ck7L37 valign-text-middle sofiapro-bold-white-16px">
                    <span><span className="span0-FxgXJH sofiapro-bold-white-16px">PHANTOM GALAXIES&nbsp;</span><span
                      className="span1-FxgXJH sofiapro-regular-normal-white-16px">
                        combines open-world space sim with fast-paced mech shooter and a captivating story.</span>
                    </span>
                      </p>
                    </div>
                    <div className="mid-para-Af4GlA">
                      <p
                        className="once-enemies-th-cestral-planets-BEWVGs valign-text-middle sofiapro-regular-normal-white-16px">
                        Once enemies, the factions of Neoterra now share a dangerous alien foe, the Sha’Kari. The
                        Sha’Kari Zealots are the priest cast of the Sha’Har race, intent on destroying mankind for
                        desecrating
                        their ancestral planets.
                      </p>
                    </div>
                    <div className="bottom-para-Af4GlA">
                      <p className="choose-the-tran-to-the-sha-kari-l4Pew1 sofiapro-regular-normal-white-16px">
                        Choose the transforming Mecha Starfighter that suits your play style - Lancer, Buster, Assault,
                        and
                        Breacher classes and take the fight to the Sha’Kari!
                      </p>
                    </div>
                  </div>
                  <a href="/download">
                    <div className="watch-trailer-button-az9eFl smart-layers-pointers">
                      <img alt="" className="button-INg8ox" src={pink_button_shape}/>
                      <div className="text-i53106189453-INg8ox oswald-normal-white-20px">PLAY NOW</div>
                    </div>

                  </a>
                  <div className={'mint-buttons-container wishlist-button-container'}>
                    <a href={'https://store.steampowered.com/app/1272550/Phantom_Galaxies/'} target="_blank"
                       rel="noreferrer" className={'astrafer-mint-button home_play_button'}>
                      <img src={steam_logo} alt={'steam logo'} />
                      PLAY ON STEAM
                    </a>
                    <a href={'https://store.epicgames.com/p/phantom-galaxies-8f4ca5'} target="_blank" rel="noreferrer"
                       className={'astrafer-mint-button home_play_button'}>
                      <img src={epic_games_logo} alt={'epic games logo'} />
                      PLAY ON EPIC
                    </a>
                    <a href={'https://store.hyperplay.xyz/game/phantomgalaxies'} target="_blank" rel="noreferrer"
                       className={"astrafer-mint-button home_play_button"}>
                      <img src={hyper_play} alt={'play with hyper play badge'} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="connect-wallet-subscribe-I66pki">
              <div className="background-iFCqX9"></div>
              <div className="side-dots-iFCqX9">
              <img alt="" className="sidedots-QjDjhg" src="/old/img/side-dots-2@2x.png"/>
                <img alt="" className="sidedots-GXnegt" src="/old/img/side-dots-3@2x.png"/>
              </div>
              <div className="buttons-and-blurbs-iFCqX9 bp1-animate-enter18" show-on-scroll="true">
                <div className="boarder-fK6t2y">
                  <img alt="" className="boarderdetail-UDxODE" src="/old/img/boarder-detail-4@2x.png"/>
                  <img alt="" className="line-5-UDxODE" src="/old/img/line-5-7@1x.png"/>
                </div>
                <div className="boarder-9tRCe2">
                  <img alt="" className="boarderdetail-KTqvAY" src="/old/img/boarder-detail@2x.png"/>
                  <img alt="" className="line-5-KTqvAY" src="/old/img/line-5-7@1x.png"/>
                </div>
                <div className="join-discord-fK6t2y">
                  <a href="https://discord.gg/PhantomGalaxies" target="_blank" rel="noreferrer">
                    <div className="join-the-discord-button-USP7F8 smart-layers-pointers">
                      <div className="text-0Xgyka oswald-normal-white-20px">JOIN THE DISCORD</div>
                    </div>

                  </a>
                  <p className="join-our-online-d-airdrop-dates-USP7F8 sofiapro-regular-normal-white-16px"
                     style={{left: "calc(50.00% - 265px)"}}>
                    Join our online community to get notified of all upcoming news and dates!
                  </p>
                </div>
                <div className="plus-seperator-fK6t2y">
                  <img alt="" className="elplus-l9cFbz" src="/old/img/el-plus-8@2x.png"/>
                  <img alt="" className="elplus-Q3l4ay" src="/old/img/el-plus-8@2x.png"/>
                  <img alt="" className="elplus-UCSQt5" src="/old/img/el-plus-8@2x.png"/>
                  <img alt="" className="elplus-lcFJZc" src="/old/img/el-plus-8@2x.png"/>
                </div>
                <div className="connect-wallet-fK6t2y">
                  <p className="connect-your-wa-ree-nft-airdrop-KK8u9g sofiapro-regular-normal-white-16px"
                     style={{left: "calc(50.00% - 145px)", marginBottom: "20px"}}>
                    {walletContext.signed ? (
                      <p className={"connect-wallet-general-text-design"}>Your wallet is connected</p>
                    ) : (
                      <p className={"connect-wallet-general-text-design"}>Connect your wallet to Phantom Galaxies</p>
                    )}
                  </p>
                  <div className={"home-connect-wallet-button"}>
                    <div className={"home-connect-wallet-button-1920"}>
                    <CustomConnectWalletButton />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PartnerList />
        <Footer>
          <FighterLady />
        </Footer>
        <PreviewModal
          visible={this.state.previewModal.visible}
          type={this.state.previewModal.type}
          src={this.state.previewModal.src}
          handleModalClose={() => this.handleModalClose()}
        />
      </div>
    );
  }
}
