import React, {useContext} from "react";
import "./ListElement.scss";
import ListElementComponent from "./ListElementComponent/ListElementComponent";
import {PlanetSaleContext} from "../../../../components/Context/PlanetSalePageContext";

export default function ListElement(props) {

  const planetSaleContext = useContext(PlanetSaleContext);

  return <>
    <div className={"le-principle-container"}>
      {
        planetSaleContext.saleConfigurations[props.sellType].map((item,index) => (
          <div className={"le-margin"} key={item.nftId +index+ "le-margin"}>
          <ListElementComponent
            key={item.nftId}
            nftId={item.nftId}
            img={item.img}
            privateSaleMintedAmount={item.privateSaleMintedAmount}
            privateSaleMaxMintAmount={item.privateSaleMaxMintAmount}
            allowedNoNfts={item.allowedNoNfts}
            text={item.text}
            value={item.value}
            noNfts={item.noNfts}
            sellType={props.sellType}
            index={index}
            ticketId={props.ticketId}
            v={item.v}
            r={item.r}
            s={item.s}
          />
          </div>
        ))
      }
    </div>
  </>
}
