//import {Phaser} from './phaser360.js';
// Player class
import Phaser from 'phaser';

export default class Player extends Phaser.Physics.Arcade.Image
{
    throttle = 0;
    prevAngle = 0;
    configure ()
    {
        this.angle = -90;
        this.body.angularDrag = 50;// SPEED THAT THE CONTROLLER ROTATION SLOWS TO DUE STRAIGHT AHEAD
        this.body.maxSpeed = 300;//300;
        
    }

    shootLaser(scene,laserDoubleBullet)
    {
        laserDoubleBullet.x = this.x;
        laserDoubleBullet.y = this.y;
        laserDoubleBullet.rotation = this.rotation;
        scene.physics.velocityFromRotation(this.rotation, 1000, laserDoubleBullet.body.velocity) ;
        laserDoubleBullet.visible = true;
    }

    shootFireBullet(scene,fireBullet)
    {
        fireBullet.x = this.x;
        fireBullet.y = this.y;
        scene.physics.velocityFromRotation(fireBullet.rotation, 1000, fireBullet.body.velocity) ;
        fireBullet.visible = true;
    }

    shootSideBullet(scene,sideBullet)
    {
        sideBullet.x = this.x;
        sideBullet.y = this.y;
        scene.physics.velocityFromRotation(sideBullet.rotation, 1000, sideBullet.body.velocity) ;
        sideBullet.visible = true;
    }

    setThrottle(force)
    {
        force *= 20;
        this.throttle = force;
    }

    update (delta, cursorKeys)
    {
        const { left, right, up, down } = cursorKeys;

        if (up.isDown)
        {
            this.throttle += 0.5 * delta;
        }
        else if (down.isDown)
        {
            this.throttle -= 2 * delta;
        }

        this.throttle = 1024;//1024;//     Phaser.Math.Clamp(this.throttle, -64, 1024);

        // angular acceleration
        if (left.isDown)
        {
            this.angle -= 0.3 * delta;
        }
        else if (right.isDown)
        {
           this.angle += 0.3 * delta;
        }
        else
        {
            this.body.setAngularAcceleration(0);
        }
        
        Phaser.Physics.Arcade.ArcadePhysics.prototype.velocityFromRotation(this.rotation, this.throttle, this.body.velocity); 
    }
}