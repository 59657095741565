import "./PlayOnBehalfMissions.scss";
import {
  boarder_detail_bottom,
  boarder_detail_top,
} from "../../../assets/images/AstrafiteRushEvent";

export default function PlayOnBehalfMissions({walletAddress, isOwner}) {

  return <>
     <div className={"play-on-behalf-missions-main-container"}>
      <img alt={""} className={"top-crown"} src={boarder_detail_top}/>
      <div className={"line-separator"}/>
      <div className={"play-on-behalf-text"}>
        {isOwner ? <span> Wallet playing on your behalf </span> : <span> Playing on behalf of Wallet</span>}
      </div>
      <div className={"wallet-address-border"}>
        <div className={"wallet-address"}>
          {walletAddress}
        </div>
      </div>
      <div className={"line-separator"}/>
      <img alt={""} className={"bottom-crown"} src={boarder_detail_bottom}/>
    </div>
  </>
}
