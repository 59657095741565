import React, { useEffect, useState } from 'react';
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap';

import LoadingSpinner from '../components/LoadingSpinner';
import { walletContext } from '../utils/WalletContext';
import imgHalberd001 from '../assets/images/001_HALBERD-001_1k.png';
import imgShiningStar002 from '../assets/images/002_SHINING-STAR_1k.png';
import imgThermopylae003 from '../assets/images/003_THEMOPYLAE_1k.png';
import imgXanorraSlaveBarge004 from '../assets/images/004_XANORRA_1k.png';
import './Posters.scss';
import PageHeader from '../components/PageHeader';
import ConvertingPosterModal from '../components/Modal/ConvertingPosterModal/ConvertingPosterModal';
import ClaimingEpisodeModal from '../components/Modal/ClaimingEpisodeModal/ClaimingEpisodeModal';
import { buyOnOpenSea, claimEpisode1, claimEpisode2, claimEpisode3, claimEpisode4, convertingPoster1forPoster2 } from '../utils/GeneralUtils';
import {
  completion_achievement_ep1,
  completion_achievement_ep2,
  completion_achievement_ep3,
  completion_achievement_ep4,
  EP01_Medal_NFT0,
  EP02_Medal_NFT0,
  EP03_Medal_NFT0,
  EP04_Medal_NFT0,
  episode_1_faded,
  episode_1_png,
  episode_2_faded,
  episode_2_png,
  episode_3_faded,
  episode_3_png,
  episode_4_faded,
  episode_4_png,
} from '../assets/images/PopUps';
import ConfirmationModal from '../components/Modal/ConfirmationModal/ConfirmationModal';
import PayableMinterV2Abi from '../assets/abi/PayableMinterV2.json';
import PGERC1155Abi from '../assets/abi/PGERC1155.json';
import converterAbi from '../assets/abi/Converter.json';
import {
  BUTTON_ACTION,
  CLAIMING_EPISODE,
  converterAddress,
  episodeIds,
  PGERC1155Address,
  TOKEN_ID_EP1_BADGE,
  TOKEN_ID_EP1_POSTER,
  TOKEN_ID_EP1_REDEEMED_POSTER,
  TOKEN_ID_EP2_BADGE,
  TOKEN_ID_EP2_POSTER,
  TOKEN_ID_EP2_REDEEMED_POSTER,
  TOKEN_ID_EP3_BADGE,
  TOKEN_ID_EP3_POSTER,
  TOKEN_ID_EP3_REDEEMED_POSTER,
  TOKEN_ID_EP4_BADGE,
  TOKEN_ID_EP4_POSTER,
  TOKEN_ID_EP4_REDEEMED_POSTER,
  TOKEN_ID_HOPEFUL,
} from '../utils/Globals';
import { EP01_Medal_NFT0_ep1, EP02_Medal_NFT0_ep2, EP03_Medal_NFT0_ep3, EP04_Medal_NFT0_ep4, NFT_EP1 } from '../assets/images/NFT';
import ImgOverlay from '../components/NFTComponents/ImgOverlay/ImgOverlay';
import BuyOnOpenSea from '../components/NFTComponents/BuyOnOpenSea/BuyOnOpenSea';
import EpisodeMedalNFT from '../components/NFTComponents/EpisodeMedalNFT/EpisodeMedalNFT';
import SwitchNetworkChainSection from '../components/SwitchNetworkChain';
import TiltedButton from '../components/TiltedButton/TiltedButton';
import { getBlockchainContract, getBlockchainContractV2 } from '../utils/providerObject';
import Episode from '../components/Episodes/Episode1/Episode';
import HeaderComponent from '../components/Header/HeaderComponent';
import Footer from '../components/Footer/Footer';
import CustomConnectWalletButton from '../components/CustomeConnectWalletButton/CustomeConnectWalletButton';
import FighterLady from '../components/Footer/FooterElements/FighterLady/FighterLady';
import initializeTimelines from '../old/scripts/timelines';
import ShowOnScroll from '../old/scripts/show-on-scroll';
import { useIsCorrectChain } from '../hooks/useIsCorrectChain';
import { useProvider } from '../hooks/useProvider';
import { useNetwork } from 'wagmi';

export default function Posters() {
  const network = useNetwork();
  const provider = useProvider();

  const SALE_TYPES = {
    public: 'Public',
    private: 'Private',
  };

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [account, setAccount] = useState(null);
  const [hasConverterContractApproval, setHasConverterContractApproval] = useState(false);
  const [posterModalShow, setPosterModalShow] = useState(false);
  const [showEpisodePopup, setShowEpisodePopup] = useState(false);
  const [showEpisodePopup2, setShowEpisodePopup2] = useState(false);
  const [showEpisodePopup3, setShowEpisodePopup3] = useState(false);
  const [showEpisodePopup4, setShowEpisodePopup4] = useState(false);
  const [confirmationModalShow, setConfirmationModalShow] = useState(false);
  const [pgerContractState, setPgerContractState] = useState(null);
  const [converterContractState, setConverterContractState] = useState(null);
  const [posterEpisodes, setPosterEpisodes] = useState({});

  const nft_to_phantom_galaxies_guide = 'https://medium.com/@PhantomGalaxies/a-guide-to-phantom-galaxies-new-nft-page-c42d743eeac4';
  const nft_ste_by_step_Guide =
    'https://medium.com/@PhantomGalaxies/a-step-by-step-guide-to-phantom-galaxies-origin-collector-and-genesis-nfts-54fd53017ebd';

  const walletAddress = '0xbfCB8d2813104f62D68c7670b138dDeDB68859a7';

  // This was added when poster 3 was released, so doesn't include poster 1 and 2 (already developed)
  useEffect(() => {
    if (provider) {
      setPosterEpisodes({
        3: {
          nftId: episodeIds.EPISODE_3,
          payableMinterAddress: process.env.REACT_APP_POSTER_3_PAYABLE_MINTER_ADDRESS,
          payableMinterContract: getBlockchainContract(process.env.REACT_APP_POSTER_3_PAYABLE_MINTER_ADDRESS, PayableMinterV2Abi, provider),
        },
        4: {
          nftId: episodeIds.EPISODE_4,
          payableMinterAddress: process.env.REACT_APP_POSTER_4_PAYABLE_MINTER_ADDRESS,
          payableMinterContract: getBlockchainContract(process.env.REACT_APP_POSTER_4_PAYABLE_MINTER_ADDRESS, PayableMinterV2Abi, provider),
        },
      });

      let pgerContract;
      let converterContract;

      const fetchContracts = async () => {
        try {
          pgerContract = getBlockchainContractV2(PGERC1155Address, PGERC1155Abi, provider);
          converterContract = getBlockchainContractV2(converterAddress, converterAbi, provider);
          setPgerContractState(pgerContract);
          setConverterContractState(converterContract);
        } catch (e) {
          console.error('Blockchain Contract error: ' + e);
          setIsLoaded(true);
          setError('Unable to fetch account data. Please try again later.');
        }
      };

      fetchContracts();

      // return early if unable to fetch contract
      if (!pgerContract) {
        return;
      }

      const checkHasApproval = async () => {
        const hasApproval = await pgerContract.isApprovedForAll(walletContext.currentWallet, converterAddress);
        setHasConverterContractApproval(hasApproval);
      };

      const balances = async () => {
        const tokenIdsToFetch = [
          TOKEN_ID_EP1_POSTER,
          TOKEN_ID_EP1_BADGE,
          TOKEN_ID_EP1_REDEEMED_POSTER,

          TOKEN_ID_EP2_POSTER,
          TOKEN_ID_EP2_BADGE,
          TOKEN_ID_EP2_REDEEMED_POSTER,

          TOKEN_ID_EP3_POSTER,
          TOKEN_ID_EP3_BADGE,
          TOKEN_ID_EP3_REDEEMED_POSTER,

          TOKEN_ID_EP4_POSTER,
          TOKEN_ID_EP4_BADGE,
          TOKEN_ID_EP4_REDEEMED_POSTER,

          TOKEN_ID_HOPEFUL,
        ];

        // fill an array with the user's wallet address to match the number of tokens
        const userWalletAddresses = new Array(tokenIdsToFetch.length).fill(walletAddress);

        const rawTokenBalancesResponse = await pgerContract.balanceOfBatch(userWalletAddresses, tokenIdsToFetch);
        const parsedTokenBalances = rawTokenBalancesResponse.map(e => e.toNumber());

        setAccount({
          ep1_posters: parsedTokenBalances[0],
          ep1_badges: parsedTokenBalances[1],
          ep1_has_completion_flag: parsedTokenBalances[2],
          ep2_posters: parsedTokenBalances[3],
          ep2_badges: parsedTokenBalances[4],
          ep2_has_completion_flag: parsedTokenBalances[5],
          ep3_posters: parsedTokenBalances[6],
          ep3_badges: parsedTokenBalances[7],
          ep3_has_completion_flag: parsedTokenBalances[8],
          ep4_posters: parsedTokenBalances[9],
          ep4_badges: parsedTokenBalances[10],
          ep4_has_completion_flag: parsedTokenBalances[11],

          hopeful: parsedTokenBalances[12],
        });

        setIsLoaded(true);
      };

      balances();
      checkHasApproval();
    }
  }, [provider]);

  const isCorrectChain = useIsCorrectChain();

  function MoreInfoButton(url) {
    return (
      <div className="nft-more-info-button">
        <TiltedButton clickEvent={() => window.open(url, '_blank')} text={'MORE INFO'} />
      </div>
    );
  }

  useEffect(() => {
    initializeTimelines();
    setTimeout(function () {
      var instShowOnScroll = new ShowOnScroll();
      instShowOnScroll.init();
    }, 250);
  }, [posterEpisodes, SALE_TYPES.private, SALE_TYPES.public, account, provider]);

  function Episode1DataFunction(mobileDesign) {
    return (
      <>
        <div>
          <div className={'episode-body'}>
            <h3>Space station</h3>
            <br />
            <h4>Location:</h4>
            <p>Near Asterion, the outermost planet in the Ionia system</p>
            <br />
            <h4>Description:</h4>
            <p>
              In the time after the Union and the Commonwealth formed a peace treaty, the No-Colonisation Area (NCA) has been overrun by thieves,
              pirates, mercenaries, slave traders, and human traffickers. Former Iron Marshall of the Union’s Military, Aleksey Drachma, proposed and
              founded a new joint effort: the Space Ranger Program (SRP). They would recruit talented pilots, both veterans as well as trainees,
              uniting together to keep the NCA a peaceful place for traders and travellers. Named after the weapon requiring two hands to wield
              (representing the collaboration of the Union and the Commonwealth), Halberd-001 became their first headquarters, supporting both
              training and operations.
            </p>
            <br />
            {mobileDesign ? (
              <div className={'align-more-info-button'}>{MoreInfoButton(nft_ste_by_step_Guide)}</div>
            ) : (
              <>{MoreInfoButton(nft_ste_by_step_Guide)}</>
            )}
          </div>
        </div>
        {/*TODO don't delete*/}
        {/*<div className={"nft-more-info-button"}>*/}
        {/*  <TiltedButton text={"MORE INFO"}/>*/}
        {/*</div>*/}
        <div className={mobileDesign === false ? 'nfts-panel-data-container nfts-panel' : 'nfts-panel-data-mobile-container nfts-panel'}>
          <div className={'nft-container-adjustment'}>
            <div className={'nft-container'}>
              <div className={'nft-type'}>
                <h4>POSTER NFT</h4>
              </div>
              <div className={'poster-nft-resolution-design'}>
                <div className={'nft-unlocked'}>
                  <div className={'nft-unlocked-image nft-unlocked-image-adjustment'}>
                    <img src={NFT_EP1} alt="" className={account.ep1_posters <= 0 ? 'nft-disable-img set-tablet-width' : 'set-tablet-width'} />
                    <div className={'overlay-design-orientation'}>
                      <ImgOverlay nrOfPosters={account.ep1_posters} />
                    </div>
                  </div>
                </div>
              </div>
              <div className={'claim-medal-dimension'}>
                <BuyOnOpenSea link={buyOnOpenSea.episode1PosterNFT} />
              </div>
            </div>
          </div>
          <div className={'nft-container-adjustment'}>
            <div className={'nft-container'}>
              <div className={'nft-type'}>
                <h4>EPISODE MEDAL NFT</h4>
              </div>
              <div className={'poster-nft-resolution-design'}>
                <div className={'overlay-design-orientation'}>
                  {account.ep1_badges > 0 ? (
                    <EpisodeMedalNFT image={EP01_Medal_NFT0_ep1} owned={account.ep1_badges} enable={false} />
                  ) : (
                    <EpisodeMedalNFT image={EP01_Medal_NFT0_ep1} owned={0} enable={true} />
                  )}
                </div>
              </div>

              <div className={'claim-medal-alignment'}>
                <div className={'buy-on-opensea-medal'}>
                  <BuyOnOpenSea link={buyOnOpenSea.episode1Medal} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function Episode2DataFunction(mobileDesign) {
    return (
      <>
        <div>
          <div className={'episode-body'}>
            <h3>Space Trading Station</h3>
            <br />
            <h4>Location:</h4>
            <p>Near the planet Hades in the Styx System</p>
            <br />
            <h4>Description:</h4>
            <p>
              The Shining Star Trading Post is a beacon of trade and safety within the NCA. It is owned and run by the Merchant Zorran, although the
              first “friendly” face you encounter may be that of the barkeep, Olaf. The Shining Star represents the future which the Space Ranger
              Program desires: a place where no well-intentioned soul would be turned away; respite for the struggling; and a gateway to a second
              chance. Located near the planet Hades, in the Styx Solar System, the Shining Star is a great place to visit to trade resources, items,
              or information, conduct meetings and introductions, or simply to have a drink and a game of Arat's 21.
            </p>
            <br />
            {mobileDesign ? (
              <div className={'align-more-info-button'}>{MoreInfoButton(nft_ste_by_step_Guide)}</div>
            ) : (
              <>{MoreInfoButton(nft_ste_by_step_Guide)}</>
            )}
          </div>
        </div>

        <div className={mobileDesign === false ? 'nfts-panel-data-container nfts-panel' : 'nfts-panel-data-mobile-container nfts-panel'}>
          <div className={'nft-container-adjustment'}>
            <div className={'nft-container'}>
              <div className={'nft-type'}>
                <h4>POSTER NFT</h4>
              </div>
              <div className={'poster-nft-resolution-design'}>
                <div className={'nft-unlocked'}>
                  <div className={'nft-unlocked-image nft-unlocked-image-adjustment'}>
                    <img
                      src={imgShiningStar002}
                      alt=""
                      className={account.ep2_posters <= 0 ? 'nft-disable-img set-tablet-width' : 'nft-enable-img-dimension set-tablet-width'}
                    />
                    <ImgOverlay nrOfPosters={account.ep2_posters} />
                  </div>
                </div>
              </div>

              <div className={'claim-medal-alignment'}>
                <div className={'buy-poster-opensea'}>
                  <BuyOnOpenSea link={buyOnOpenSea.episode2PosterNFT} />
                </div>
              </div>
            </div>
          </div>
          <div className={'nft-container-adjustment'}>
            <div className={'nft-container'}>
              <div className={'nft-type'}>
                <h4>EPISODE MEDAL NFT</h4>
              </div>
              <div className={'nft-image-container'}>
                <div className={'poster-nft-resolution-design'}>
                  <div className={'overlay-design-orientation'}>
                    {account.ep2_badges > 0 ? (
                      <EpisodeMedalNFT image={EP02_Medal_NFT0_ep2} owned={account.ep2_badges} enable={false} />
                    ) : (
                      <>
                        <EpisodeMedalNFT image={EP02_Medal_NFT0_ep2} owned={0} enable={true} />
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className={'claim-medal-alignment'}>
                <div className={'buy-poster-opensea'}>
                  <BuyOnOpenSea link={buyOnOpenSea.episode2Medal} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function Episode3DataFunction(mobileDesign) {
    return (
      <>
        <Row>
          <div className={'episode-body'}>
            <h3>City</h3>
            <br />
            <h4>Location:</h4>
            <p>On the planet Leonidas in the Sparta System</p>
            <br />
            <h4>Description:</h4>
            <p>
              Located in the Sparta system, planet Leonidas has become an oasis of criminal enterprise. The planet’s central metropolis, Thermopylae,
              is a hellish conflagration of crime, wickedness, and greed. The streets of Thermopylae are a bastardized hodge-podge of old war vessels,
              space stations, and any other helpful wreckage, swallowing many unsuspecting visitors. The city is home to illegal chop shops, slave
              trading, and drug peddling and is a focal point for trade and commerce amongst many pirate factions. These include the Chareux Junkers,
              the Xanorra Collective, and the Brooksea Company. Each faction maintains bases of operations in this former bastion of the Commonwealth
              while preserving a barely functioning truce with each other. Blood being spilled matters less than the money to be made in the wilds of
              the Non-Colonization Area (NCA), where planet Leonidas is located.
            </p>
            <br />
            {mobileDesign ? (
              <div className={'align-more-info-button'}>{MoreInfoButton(nft_to_phantom_galaxies_guide)}</div>
            ) : (
              <>{MoreInfoButton(nft_to_phantom_galaxies_guide)}</>
            )}
          </div>
        </Row>

        <div className={mobileDesign === false ? 'nfts-panel-data-container nfts-panel' : 'nfts-panel-data-mobile-container nfts-panel'}>
          <div className={'nft-container-adjustment'}>
            <div className={'nft-container'}>
              <div className={'nft-type'}>
                <h4>POSTER NFT</h4>
              </div>
              <div className={'poster-nft-resolution-design'}>
                <div className={'nft-unlocked'}>
                  <div className={'nft-unlocked-image nft-unlocked-image-adjustment'}>
                    <img
                      src={imgThermopylae003}
                      alt=""
                      className={account.ep3_posters <= 0 ? 'nft-disable-img set-tablet-width' : 'set-tablet-width'}
                    />
                    <ImgOverlay nrOfPosters={account.ep3_posters} />
                  </div>
                </div>
              </div>

              <div className={'claim-medal-alignment'}>
                <div className={'buy-poster-opensea'}>
                  <BuyOnOpenSea link={buyOnOpenSea.episode3PosterNFT} />
                </div>
              </div>
            </div>
          </div>
          <div className={'nft-container-adjustment'}>
            <div className={'nft-container'}>
              <div className={'nft-type'}>
                <h4>EPISODE MEDAL NFT</h4>
              </div>
              <div className={'nft-image-container'}>
                <div className={'poster-nft-resolution-design'}>
                  <div className={'overlay-design-orientation'}>
                    {account.ep3_badges > 0 ? (
                      <EpisodeMedalNFT image={EP03_Medal_NFT0_ep3} owned={account.ep3_badges} enable={false} />
                    ) : (
                      <>
                        <EpisodeMedalNFT image={EP03_Medal_NFT0_ep3} owned={0} enable={true} />
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className={'claim-medal-alignment'}>
                <div className={'buy-on-opensea-medal'}>
                  <BuyOnOpenSea link={buyOnOpenSea.episode3Medal} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function Episode4DataFunction(mobileDesign) {
    return (
      <>
        <Row>
          <div className={'episode-body'}>
            <h3>Transport Vessel</h3>
            <br />
            <h4>Location:</h4>
            <p>Near the asteroid ring of the third planet in the Krasa System</p>
            <br />
            <h4>Description:</h4>
            <p>
              The Xanorra Slave Barge slithers through the wilds of space like a heart-wrenching monstrosity of nightmares. These practitioners of the
              human bondage trade sail the stars on a voyage of the damned, destroying the wills of the many unfortunates they come across. The ship
              packs as many doomed souls into its holds as space will allow, shuttling them to illegal mining operations and pleasure dens across the
              universe. If you see the Xanorra Slave Barge anywhere in your vicinity, make it a point to flee as fast as you can in the other
              direction. They’re always on the prowl for new “cargo.”
            </p>
            <br />
            {mobileDesign ? (
              <div className={'align-more-info-button'}>{MoreInfoButton(nft_to_phantom_galaxies_guide)}</div>
            ) : (
              <>{MoreInfoButton(nft_to_phantom_galaxies_guide)}</>
            )}
          </div>
        </Row>

        <div className={mobileDesign === false ? 'nfts-panel-data-container nfts-panel' : 'nfts-panel-data-mobile-container nfts-panel'}>
          <div className={'nft-container-adjustment'}>
            <div className={'nft-container'}>
              <div className={'nft-type'}>
                <h4>POSTER NFT</h4>
              </div>
              <div className={'poster-nft-resolution-design'}>
                <div className={'nft-unlocked'}>
                  <div className={'nft-unlocked-image nft-unlocked-image-adjustment'}>
                    <img
                      src={imgXanorraSlaveBarge004}
                      alt=""
                      className={account.ep4_posters <= 0 ? 'nft-disable-img set-tablet-width' : 'set-tablet-width'}
                    />
                    <ImgOverlay nrOfPosters={account.ep4_posters} />
                  </div>
                </div>
              </div>

              <div className={'claim-medal-alignment'}>
                <div className={'buy-poster-opensea'}>
                  <BuyOnOpenSea link={buyOnOpenSea.episode4PosterNFT} />
                </div>
              </div>
            </div>
          </div>
          <div className={'nft-container-adjustment'}>
            <div className={'nft-container'}>
              <div className={'nft-type'}>
                <h4>EPISODE MEDAL NFT</h4>
              </div>
              <div className={'nft-image-container'}>
                <div className={'poster-nft-resolution-design'}>
                  <div className={'overlay-design-orientation'}>
                    {account.ep4_badges > 0 ? (
                      <EpisodeMedalNFT image={EP04_Medal_NFT0_ep4} owned={account.ep4_badges} enable={false} />
                    ) : (
                      <>
                        <EpisodeMedalNFT image={EP04_Medal_NFT0_ep4} owned={0} enable={true} />
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className={'claim-medal-alignment'}>
                <div className={'buy-on-opensea-medal'}>
                  <BuyOnOpenSea link={buyOnOpenSea.episode4Medal} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <ClaimingEpisodeModal
        show={showEpisodePopup}
        onHide={() => setShowEpisodePopup(false)}
        episode={claimEpisode1.episode}
        episodeImg={episode_1_png}
        accountData={account}
        converterContract={converterContractState}
        pgerContract={pgerContractState}
        currentEpisode={CLAIMING_EPISODE.EPISODE_1}
        badge={claimEpisode1.Badge}
        badgeImg={completion_achievement_ep1}
        redeemed={claimEpisode1.Redeemed}
        redeemedImg={episode_1_faded}
        medal={claimEpisode1.Medal}
        medalImg={EP01_Medal_NFT0}
        isFirstTimeMinting={!hasConverterContractApproval}
        buttonClick={event => {
          if (event === BUTTON_ACTION.CONVERTING_EPISODE_YES) {
            setConfirmationModalShow(true);
            setShowEpisodePopup(false);
            setHasConverterContractApproval(true);
          } else {
            showEpisodePopup(false);
          }
        }}
      />

      <ClaimingEpisodeModal
        show={showEpisodePopup2}
        onHide={() => setShowEpisodePopup2(false)}
        episode={claimEpisode2.episode}
        accountData={account}
        converterContract={converterContractState}
        pgerContract={pgerContractState}
        currentEpisode={CLAIMING_EPISODE.EPISODE_2}
        episodeImg={episode_2_png}
        badge={claimEpisode2.Badge}
        badgeImg={completion_achievement_ep2}
        redeemed={claimEpisode2.Redeemed}
        redeemedImg={episode_2_faded}
        medal={claimEpisode2.Medal}
        medalImg={EP02_Medal_NFT0}
        isFirstTimeMinting={!hasConverterContractApproval}
        buttonClick={event => {
          if (event === BUTTON_ACTION.CONVERTING_EPISODE_YES) {
            setConfirmationModalShow(true);
            setShowEpisodePopup2(false);
            setHasConverterContractApproval(true);
          } else {
            showEpisodePopup2(false);
          }
        }}
      />

      {/*CLAIMING EPISODE MODAL - EPISODE 3*/}
      <ClaimingEpisodeModal
        show={showEpisodePopup3}
        onHide={() => setShowEpisodePopup3(false)}
        episode={claimEpisode3.episode}
        accountData={account}
        converterContract={converterContractState}
        pgerContract={pgerContractState}
        currentEpisode={CLAIMING_EPISODE.EPISODE_3}
        episodeImg={episode_3_png}
        badge={claimEpisode3.Badge}
        badgeImg={completion_achievement_ep3}
        redeemed={claimEpisode3.Redeemed}
        redeemedImg={episode_3_faded}
        medal={claimEpisode3.Medal}
        medalImg={EP03_Medal_NFT0}
        isFirstTimeMinting={!hasConverterContractApproval}
        buttonClick={event => {
          if (event === BUTTON_ACTION.CONVERTING_EPISODE_YES) {
            setConfirmationModalShow(true);
            setShowEpisodePopup3(false);
            setHasConverterContractApproval(true);
          } else {
            setShowEpisodePopup3(false);
          }
        }}
      />

      {/*CLAIMING EPISODE MODAL - EPISODE 4*/}
      <ClaimingEpisodeModal
        show={showEpisodePopup4}
        onHide={() => setShowEpisodePopup4(false)}
        episode={claimEpisode4.episode}
        accountData={account}
        converterContract={converterContractState}
        pgerContract={pgerContractState}
        currentEpisode={CLAIMING_EPISODE.EPISODE_4}
        episodeImg={episode_4_png}
        badge={claimEpisode4.Badge}
        badgeImg={completion_achievement_ep4}
        redeemed={claimEpisode4.Redeemed}
        redeemedImg={episode_4_faded}
        medal={claimEpisode4.Medal}
        medalImg={EP04_Medal_NFT0}
        isFirstTimeMinting={!hasConverterContractApproval}
        buttonClick={event => {
          if (event === BUTTON_ACTION.CONVERTING_EPISODE_YES) {
            setConfirmationModalShow(true);
            setShowEpisodePopup4(false);
            setHasConverterContractApproval(true);
          } else {
            setShowEpisodePopup4(false);
          }
        }}
      />

      {posterModalShow ? (
        <ConvertingPosterModal
          onHide={() => {
            setPosterModalShow(false);
          }}
          multipleValue={5}
          contract={converterContractState}
          pgerContract={pgerContractState}
          episodeId={episodeIds.EPISODE_2}
          episode1Img={episode_1_png}
          epsiode1Text={convertingPoster1forPoster2.episode1}
          epsiode2Img={episode_2_png}
          episode2Text={convertingPoster1forPoster2.episode2}
          isFirstTimeMinting={!hasConverterContractApproval}
          buttonClick={event => {
            if (event === BUTTON_ACTION.CONVERTING_YES) {
              setConfirmationModalShow(true);
              setPosterModalShow(false);
              setHasConverterContractApproval(true);
            } else {
              setPosterModalShow(false);
            }
          }}
          show={posterModalShow}
        />
      ) : (
        <></>
      )}

      <ConfirmationModal show={confirmationModalShow} onHide={() => setConfirmationModalShow(false)} />

      <HeaderComponent noBackground={true} />

      <div className={'nft-desktop-1440 screen'}>
        <Container fluid>
          <Row>
            <Col className="d-flex align-items-center main">
              <Container className={'nft-container-width'}>
                <PageHeader headerText="ORIGIN SERIES NFTS" classname="page-mobile-header-text-no-margin" />
                {!walletContext.signed && (
                  <div className="mx-auto code-panel">
                    <p className={'connect-wallet-general-text-design'}>Connect your wallet to Phantom Galaxies</p>

                    <div className={'home-connect-wallet-button'}>
                      <div className={'home-connect-wallet-button-mobile'}>
                        <CustomConnectWalletButton />
                      </div>
                    </div>
                  </div>
                )}

                {walletContext.signed && !isLoaded && <LoadingSpinner />}

                {walletContext.signed && !isCorrectChain && isLoaded && (
                  <SwitchNetworkChainSection network={network} message={`You must be on the ${process.env.REACT_APP_CHAINID} network to mint NFTs`} />
                )}

                {walletContext.signed && isCorrectChain && isLoaded && !error && (
                  <Tab.Container id="nft-tabs" defaultActiveKey="episode1">
                    <Row className={'nft-row-add-margin'}>
                      <Col sm={4}>
                        <Nav variant="pills" className="flex-column episode-tabs">
                          <Nav.Item>
                            <Nav.Link eventKey="episode1">
                              <div className={'episode-tab-container'}>
                                <div className={'episode-tab-header'}>
                                  <h2>Episode 1</h2>
                                </div>
                                <div className={'episode-tab-image'}>
                                  <img src={imgHalberd001} alt="" />
                                </div>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="episode2">
                              <div className={'episode-tab-container'}>
                                <div className={'episode-tab-header'}>
                                  <h2>Episode 2</h2>
                                </div>
                                <div className={'episode-tab-image'}>
                                  <img src={imgShiningStar002} alt="" />
                                </div>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="episode3">
                              <div className={'episode-tab-container'}>
                                <div className={'episode-tab-header'}>
                                  <h2>Episode 3</h2>
                                </div>
                                <div className={'episode-tab-image'}>
                                  <img src={imgThermopylae003} alt="" />
                                </div>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="episode4">
                              <div className={'episode-tab-container'}>
                                <div className={'episode-tab-header'}>
                                  <h2>Episode 4</h2>
                                </div>
                                <div className={'episode-tab-image'}>
                                  <img src={imgXanorraSlaveBarge004} alt="" />
                                </div>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>

                      <Col sm={2} className={'vertical-rule-container'}>
                        <div className={'vertical-rule-sticky-out-thingy'}>$nbsp;</div>
                        <div className={'vertical-rule'}>&nbsp;</div>
                      </Col>

                      <Col sm={6}>
                        <Tab.Content className={'episode-detail'}>
                          <Tab.Pane eventKey="episode1">
                            <Row>
                              <div className={'episode-header'}>
                                <h2>Halberd-001</h2>
                                <img className={'episode-header-image'} src={imgHalberd001} alt="" />
                              </div>
                            </Row>
                            <Row>
                              <div className={'episode-body'}>
                                <h3>Space station</h3>

                                <h4>Location:</h4>
                                <p>Near Asterion, the outermost planet in the Ionia system</p>

                                <h4>Description:</h4>
                                <p>
                                  In the time after the Union and the Commonwealth formed a peace treaty, the No-Colonisation Area (NCA) has been
                                  overrun by thieves, pirates, mercenaries, slave traders, and human traffickers. Former Iron Marshall of the Union’s
                                  Military, Aleksey Drachma, proposed and founded a new joint effort: the Space Ranger Program (SRP). They would
                                  recruit talented pilots, both veterans as well as trainees, uniting together to keep the NCA a peaceful place for
                                  traders and travellers. Named after the weapon requiring two hands to wield (representing the collaboration of the
                                  Union and the Commonwealth), Halberd-001 became their first headquarters, supporting both training and operations.
                                </p>
                                <br />
                                {MoreInfoButton(nft_ste_by_step_Guide)}
                              </div>
                            </Row>
                            {/*TODO don't delete*/}
                            {/*<div className={"nft-more-info-button"}>*/}
                            {/*  <TiltedButton text={"MORE INFO"}/>*/}
                            {/*</div>*/}
                            <Row className={'nfts-panel'}>
                              <Col className={'nft-container'}>
                                <div className={'nft-type'}>
                                  <h4>POSTER NFT</h4>
                                </div>
                                <div className={'nft-image-container'}>
                                  <div className={'nft-unlocked'}>
                                    <div className={'nft-unlocked-image nft-unlocked-image-adjustment'}>
                                      <img src={NFT_EP1} alt="" className={account.ep1_posters <= 0 ? 'nft-disable-img' : ''} />
                                      <ImgOverlay nrOfPosters={account.ep1_posters} />
                                    </div>
                                  </div>
                                </div>
                                <div className={'claim-medal-desing'}>
                                  <div className={'claim-medal-dimension'}>
                                    <BuyOnOpenSea link={buyOnOpenSea.episode1PosterNFT} />
                                  </div>
                                </div>
                              </Col>

                              <Col className={'nft-container'}>
                                <div className={'nft-type'}>
                                  <h4>Episode Medal NFT</h4>
                                </div>
                                <div className={'nft-image-container'}>
                                  {account.ep1_badges > 0 ? (
                                    <EpisodeMedalNFT image={EP01_Medal_NFT0_ep1} owned={account.ep1_badges} enable={false} />
                                  ) : (
                                    <EpisodeMedalNFT image={EP01_Medal_NFT0_ep1} owned={0} enable={true} />
                                  )}
                                </div>

                                <div className={'claim-medal-alignment'}>
                                  <div className={'buy-on-opensea-medal'}>
                                    <BuyOnOpenSea link={buyOnOpenSea.episode1Medal} />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Tab.Pane>

                          <Tab.Pane eventKey="episode2">
                            <Row>
                              <div className={'episode-header'}>
                                <h2>Shining Star</h2>
                                <img className={'episode-header-image'} src={imgShiningStar002} alt="" />
                              </div>
                            </Row>
                            <Row>
                              <div className={'episode-body'}>
                                <h3>Space Trading Station</h3>

                                <h4>Location:</h4>
                                <p>Near the planet Hades in the Styx System</p>

                                <h4>Description:</h4>
                                <p>
                                  The Shining Star Trading Post is a beacon of trade and safety within the NCA. It is owned and run by the Merchant
                                  Zorran, although the first “friendly” face you encounter may be that of the barkeep, Olaf. The Shining Star
                                  represents the future which the Space Ranger Program desires: a place where no well-intentioned soul would be turned
                                  away; respite for the struggling; and a gateway to a second chance. Located near the planet Hades, in the Styx Solar
                                  System, the Shining Star is a great place to visit to trade resources, items, or information, conduct meetings and
                                  introductions, or simply to have a drink and a game of Arat's 21.
                                </p>
                                <br />
                                {MoreInfoButton(nft_ste_by_step_Guide)}
                              </div>
                            </Row>

                            <Row className={'nfts-panel'}>
                              <Col className={'nft-container'}>
                                <div className={'nft-type'}>
                                  <h4>Poster NFT</h4>
                                </div>
                                <div className={'nft-image-container'}>
                                  <div className={'nft-unlocked'}>
                                    <div className={'nft-unlocked-image'}>
                                      <img src={imgShiningStar002} alt="" className={account.ep2_posters <= 0 ? 'nft-disable-img' : ''} />
                                      <ImgOverlay nrOfPosters={account.ep2_posters} />
                                    </div>
                                  </div>
                                </div>

                                <div className={'claim-medal-alignment'}>
                                  <div className={'buy-poster-opensea'}>
                                    <BuyOnOpenSea link={buyOnOpenSea.episode2PosterNFT} />
                                  </div>
                                </div>
                              </Col>

                              <Col className={'nft-container'}>
                                <div className={'nft-type'}>
                                  <h4>Episode Medal NFT</h4>
                                </div>
                                <div className={'nft-image-container'}>
                                  {account.ep2_badges > 0 ? (
                                    <EpisodeMedalNFT image={EP02_Medal_NFT0_ep2} owned={account.ep2_badges} enable={false} />
                                  ) : (
                                    <>
                                      <EpisodeMedalNFT image={EP02_Medal_NFT0_ep2} owned={0} enable={true} />
                                    </>
                                  )}
                                </div>
                                <div className={'claim-medal-alignment'}>
                                  <div className={'buy-poster-opensea'}>
                                    <BuyOnOpenSea link={buyOnOpenSea.episode2Medal} />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Tab.Pane>

                          <Tab.Pane eventKey="episode3">
                            <Row>
                              <div className={'episode-header'}>
                                <h2>Thermopylae</h2>
                                <img className={'episode-header-image'} src={imgThermopylae003} alt="" />
                              </div>
                            </Row>
                            <Row>
                              <div className={'episode-body'}>
                                <h3>City</h3>

                                <h4>Location:</h4>
                                <p>On the planet Leonidas in the Sparta System</p>

                                <h4>Description:</h4>
                                <p>
                                  Located in the Sparta system, planet Leonidas has become an oasis of criminal enterprise. The planet’s central
                                  metropolis, Thermopylae, is a hellish conflagration of crime, wickedness, and greed. The streets of Thermopylae are
                                  a bastardized hodge-podge of old war vessels, space stations, and any other helpful wreckage, swallowing many
                                  unsuspecting visitors. The city is home to illegal chop shops, slave trading, and drug peddling and is a focal point
                                  for trade and commerce amongst many pirate factions. These include the Chareux Junkers, the Xanorra Collective, and
                                  the Brooksea Company. Each faction maintains bases of operations in this former bastion of the Commonwealth while
                                  preserving a barely functioning truce with each other. Blood being spilled matters less than the money to be made in
                                  the wilds of the Non-Colonization Area (NCA), where planet Leonidas is located.
                                </p>
                                <br />
                                {MoreInfoButton(nft_to_phantom_galaxies_guide)}
                              </div>
                            </Row>

                            <Row className={'nfts-panel'}>
                              <Col className={'nft-container'}>
                                <div className={'nft-type'}>
                                  <h4>Poster NFT</h4>
                                </div>
                                <div className={'nft-image-container'}>
                                  <div className={'nft-unlocked'}>
                                    <div className={'nft-unlocked-image'}>
                                      <img src={imgThermopylae003} alt="" className={account.ep3_posters <= 0 ? 'nft-disable-img' : ''} />
                                      <ImgOverlay nrOfPosters={account.ep3_posters} />
                                    </div>
                                  </div>
                                </div>

                                <div className={'claim-medal-alignment'}>
                                  <div className={'buy-poster-opensea'}>
                                    <BuyOnOpenSea link={buyOnOpenSea.episode3PosterNFT} />
                                  </div>
                                </div>
                              </Col>

                              <Col className={'nft-container'}>
                                <div className={'nft-type'}>
                                  <h4>Episode Medal NFT</h4>
                                </div>
                                <div className={'nft-image-container'}>
                                  {account.ep3_badges > 0 ? (
                                    <EpisodeMedalNFT image={EP03_Medal_NFT0_ep3} owned={account.ep3_badges} enable={false} />
                                  ) : (
                                    <>
                                      <EpisodeMedalNFT image={EP03_Medal_NFT0_ep3} owned={0} enable={true} />
                                    </>
                                  )}
                                </div>
                                <div className={'claim-medal-alignment'}>
                                  <div className={'buy-on-opensea-medal'}>
                                    <BuyOnOpenSea link={buyOnOpenSea.episode3Medal} />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Tab.Pane>

                          <Tab.Pane eventKey="episode4">
                            <Row>
                              <div className={'episode-header'}>
                                <h2>Xanorra Slave Barge</h2>
                                <img className={'episode-header-image'} src={imgXanorraSlaveBarge004} alt="" />
                              </div>
                            </Row>
                            <Row>
                              <div className={'episode-body'}>
                                <h3>Transport Vessel</h3>

                                <h4>Location:</h4>
                                <p>Near the asteroid ring of the third planet in the Krasa System</p>

                                <h4>Description:</h4>
                                <p>
                                  The Xanorra Slave Barge slithers through the wilds of space like a heart-wrenching monstrosity of nightmares. These
                                  practitioners of the human bondage trade sail the stars on a voyage of the damned, destroying the wills of the many
                                  unfortunates they come across. The ship packs as many doomed souls into its holds as space will allow, shuttling
                                  them to illegal mining operations and pleasure dens across the universe. If you see the Xanorra Slave Barge anywhere
                                  in your vicinity, make it a point to flee as fast as you can in the other direction. They’re always on the prowl for
                                  new “cargo.”
                                </p>
                                <br />
                                {MoreInfoButton(nft_to_phantom_galaxies_guide)}
                              </div>
                            </Row>

                            <Row className={'nfts-panel'}>
                              <Col className={'nft-container'}>
                                <div className={'nft-type'}>
                                  <h4>Poster NFT</h4>
                                </div>
                                <div className={'nft-image-container'}>
                                  <div className={'nft-unlocked'}>
                                    <div className={'nft-unlocked-image'}>
                                      <img src={imgXanorraSlaveBarge004} alt="" className={account.ep4_posters <= 0 ? 'nft-disable-img' : ''} />
                                      <ImgOverlay nrOfPosters={account.ep4_posters} />
                                    </div>
                                  </div>
                                </div>

                                <div className={'claim-medal-alignment'}>
                                  <div className={'buy-poster-opensea'}>
                                    <BuyOnOpenSea link={buyOnOpenSea.episode4PosterNFT} />
                                  </div>
                                </div>
                              </Col>

                              <Col className={'nft-container'}>
                                <div className={'nft-type'}>
                                  <h4>Episode Medal NFT</h4>
                                </div>
                                <div className={'nft-image-container'}>
                                  {account.ep4_badges > 0 ? (
                                    <EpisodeMedalNFT image={EP04_Medal_NFT0_ep4} owned={account.ep4_badges} enable={false} />
                                  ) : (
                                    <>
                                      <EpisodeMedalNFT image={EP04_Medal_NFT0_ep4} owned={0} enable={true} />
                                    </>
                                  )}
                                </div>
                                <div className={'claim-medal-alignment'}>
                                  <div className={'buy-on-opensea-medal'}>
                                    <BuyOnOpenSea link={buyOnOpenSea.episode4Medal} />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                )}
              </Container>
            </Col>
          </Row>
        </Container>
      </div>

      <div className={'nft-tablet-1024 screen'}>
        <PageHeader headerText="ORIGIN SERIES NFTS" classname="page-mobile-header-text-no-margin" />

        {!walletContext.signed && (
          <div className="mx-auto code-panel">
            <p className={'connect-wallet-general-text-design'}> Connect your wallet to Phantom Galaxies</p>
            <div className={'home-connect-wallet-button'}>
              <div className={'home-connect-wallet-button-mobile'}>
                <CustomConnectWalletButton />
              </div>
            </div>
          </div>
        )}

        {walletContext.signed && !isLoaded && <LoadingSpinner />}

        {walletContext.signed && !isCorrectChain && isLoaded && (
          <SwitchNetworkChainSection network={network} message={`You must be on the ${process.env.REACT_APP_CHAINID} network to claim NFTs`} />
        )}

        {walletContext.signed && isCorrectChain && isLoaded && !error && (
          <>
            <div className={'episodes-left-alignment'}>
              <div className={'episode-elements-distance'}>
                <Episode
                  descriptionTitle={'Episode 1'}
                  fullTitle={'Episode 1: Halberd-001'}
                  imgUsed={imgHalberd001}
                  showLineSeparator={true}
                  showEpisodeDetails={Episode1DataFunction(false)}
                  itIsAvailable={true}
                />
              </div>
              <div className={'episode-elements-distance'}>
                <Episode
                  descriptionTitle={'Episode 2'}
                  fullTitle={'Episode 2: Shining Star'}
                  imgUsed={imgShiningStar002}
                  showLineSeparator={true}
                  showEpisodeDetails={Episode2DataFunction(false)}
                  itIsAvailable={true}
                />
              </div>
              <div className={'episode-elements-distance'}>
                <Episode
                  descriptionTitle={'Episode 3'}
                  fullTitle={'Episode 3: Thermopylae'}
                  imgUsed={imgThermopylae003}
                  showLineSeparator={true}
                  showEpisodeDetails={Episode3DataFunction(false)}
                  itIsAvailable={true}
                />
              </div>
              <div className={'episode-elements-distance'}>
                <Episode
                  descriptionTitle={'Episode 4'}
                  fullTitle={'Episode 4: Xanorra Slave Barge'}
                  imgUsed={imgXanorraSlaveBarge004}
                  showLineSeparator={true}
                  showEpisodeDetails={Episode4DataFunction(false)}
                  itIsAvailable={true}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div className={'nft-small-tablet-768 screen'}>
        <PageHeader headerText="ORIGIN SERIES NFTS" classname="page-mobile-header-text-no-margin" />
        {!walletContext.signed && (
          <div className="mx-auto code-panel">
            <p className={'connect-wallet-general-text-design'}> Connect your wallet to Phantom Galaxies</p>

            <div className={'home-connect-wallet-button'}>
              <div className={'home-connect-wallet-button-mobile'}>
                <CustomConnectWalletButton />
              </div>
            </div>
          </div>
        )}
        {walletContext.signed && !isLoaded && <LoadingSpinner />}

        {walletContext.signed && !isCorrectChain && isLoaded && (
          <SwitchNetworkChainSection network={network} message={`You must be on the ${process.env.REACT_APP_CHAINID} network to claim NFTs`} />
        )}
        {walletContext.signed && isCorrectChain && isLoaded && !error && (
          <>
            <div className={'episodes-left-alignment'}>
              <div className={'episode-elements-distance'}>
                <Episode
                  descriptionTitle={'Episode 1'}
                  fullTitle={'Episode 1: Halberd-001'}
                  imgUsed={imgHalberd001}
                  showLineSeparator={true}
                  showEpisodeDetails={Episode1DataFunction(false)}
                  itIsAvailable={true}
                />
              </div>
              <div className={'episode-elements-distance'}>
                <Episode
                  descriptionTitle={'Episode 2'}
                  fullTitle={'Episode 2: Shining Star'}
                  imgUsed={imgShiningStar002}
                  showLineSeparator={true}
                  showEpisodeDetails={Episode2DataFunction(false)}
                  itIsAvailable={true}
                />
              </div>
              <div className={'episode-elements-distance'}>
                <Episode
                  descriptionTitle={'Episode 3'}
                  fullTitle={'Episode 3: Thermopylae'}
                  imgUsed={imgThermopylae003}
                  showLineSeparator={true}
                  showEpisodeDetails={Episode3DataFunction(false)}
                  itIsAvailable={true}
                />
              </div>
              <div className={'episode-elements-distance'}>
                <Episode
                  descriptionTitle={'Episode 4'}
                  fullTitle={'Episode 4: Xanorra Slave Barge'}
                  imgUsed={imgXanorraSlaveBarge004}
                  showLineSeparator={true}
                  showEpisodeDetails={Episode4DataFunction(false)}
                  itIsAvailable={true}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div className={'nft-mobile-375 screen'}>
        <PageHeader imgSize={true} headerText="ORIGIN SERIES NFTS" classname="page-mobile-header-text-no-margin" />

        {!walletContext.signed && (
          <div className="mx-auto code-panel">
            <p className={'connect-wallet-general-text-design'}>Connect your wallet to Phantom Galaxies</p>
            <div className={'home-connect-wallet-button'}>
              <div className={'nft-page-connect-wallet-button'}>
                <CustomConnectWalletButton />
              </div>
            </div>
          </div>
        )}
        {walletContext.signed && !isLoaded && <LoadingSpinner />}

        {walletContext.signed && !isCorrectChain && isLoaded && (
          <SwitchNetworkChainSection network={network} message={`You must be on the ${process.env.REACT_APP_CHAINID} network to claim NFTs`} />
        )}
        {walletContext.signed && isCorrectChain && isLoaded && !error && (
          <>
            <div className={'episodes-left-alignment mobile-left-alignment'}>
              <div className={'episode-elements-distance-mobile'}>
                <Episode
                  descriptionTitle={'Episode 1'}
                  fullTitle={'Episode 1: Halberd-001'}
                  imgUsed={imgHalberd001}
                  showEpisodeDetails={Episode1DataFunction(true)}
                  itIsAvailable={true}
                  showLineSeparator={false}
                  mobileDesign={true}
                />
              </div>
              <div className={'episode-elements-distance-mobile'}>
                <Episode
                  descriptionTitle={'Episode 2'}
                  fullTitle={'Episode 2: Shining Star'}
                  imgUsed={imgShiningStar002}
                  showEpisodeDetails={Episode2DataFunction(true)}
                  itIsAvailable={true}
                  showLineSeparator={false}
                  mobileDesign={true}
                />
              </div>
              <div className={'episode-elements-distance-mobile'}>
                <Episode
                  descriptionTitle={'Episode 3'}
                  fullTitle={'Episode 3: Thermopylae'}
                  imgUsed={imgThermopylae003}
                  showEpisodeDetails={Episode3DataFunction(true)}
                  itIsAvailable={true}
                  showLineSeparator={false}
                  mobileDesign={true}
                />
              </div>
              <div className={'episode-elements-distance-mobile'}>
                <Episode
                  descriptionTitle={'Episode 4'}
                  fullTitle={'Episode 4: Xanorra Slave Barge'}
                  imgUsed={imgXanorraSlaveBarge004}
                  showEpisodeDetails={Episode4DataFunction(true)}
                  itIsAvailable={true}
                  showLineSeparator={false}
                  mobileDesign={true}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <div className={'nft-error-container'}>{error && <p>{error}</p>}</div>

      <Footer>
        <FighterLady />
      </Footer>
    </>
  );
}
