import './ClaimInfoElement.scss'
import React from "react";

export default function ClaimInfoElement({title, text}) {
  return <>
    <div className={'claim-info-element-container'}>
      <p className={'claim-info-title'}>{title}</p>
      <div className={'claim-info-text'}>
        {text}
      </div>
      <div className={'claim-info-text-border'}></div>

    </div>
  </>
}
