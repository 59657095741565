
function initializeTimelines() {
  let wrapper;

  wrapper = document.querySelector(".x4168254.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=bottom",
      root_element: ".x4168254",
      states_flow: {
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".blowfishlogo-xcIo0e": {
              left: "0px",
              top: "-0px",
            },
            ".bottom-bar-jl3GLF": {
              width: "375px",
            },
            ".group-12-jl3GLF": {
              left: "208px",
            },
          },
        },
        "Property-1=bottom": {
          listeners: [],
          overrides: {},
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4168299.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Roadmap5",
      root_element: ".x4168299",
      states_flow: {
        "Property-1=Default": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap1": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap2": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap3": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap4": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap5": {
          listeners: [],
          overrides: {},
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4168301.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Roadmap4",
      root_element: ".x4168301",
      states_flow: {
        "Property-1=Default": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap1": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap2": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap3": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap4": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap5": {
          listeners: [],
          overrides: {},
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4168303.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Roadmap3",
      root_element: ".x4168303",
      states_flow: {
        "Property-1=Default": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap1": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap2": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap3": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap4": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap5": {
          listeners: [],
          overrides: {},
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4168305.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Roadmap2",
      root_element: ".x4168305",
      states_flow: {
        "Property-1=Default": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap1": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap2": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap3": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap4": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap5": {
          listeners: [],
          overrides: {},
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4168307.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Roadmap1",
      root_element: ".x4168307",
      states_flow: {
        "Property-1=Default": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap1": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap2": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap3": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap4": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap5": {
          listeners: [],
          overrides: {},
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4168313.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Action",
      root_element: ".x4168313",
      states_flow: {
        "Property-1=Action": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Discover": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Guilds": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=NFTs": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4168314.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=NFTs",
      root_element: ".x4168314",
      states_flow: {
        "Property-1=Action": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Discover": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Guilds": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=NFTs": {
          listeners: [],
          overrides: {},
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4168315.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Discover",
      root_element: ".x4168315",
      states_flow: {
        "Property-1=Action": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Discover": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Guilds": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=NFTs": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4168316.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Guilds",
      root_element: ".x4168316",
      states_flow: {
        "Property-1=Action": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Discover": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Guilds": {
          listeners: [],
          overrides: {},
        },
        "Property-1=NFTs": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4165981.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Default",
      root_element: ".x4165981",
      states_flow: {
        "Property-1=Default": {
          listeners: [],
          overrides: {},
        },
        "Property-1=bottom": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".blowfishlogo-Kq8wuK": {
              left: "0px",
            },
            ".bottom-bar-iVQxx3": {
              width: "768px",
            },
            ".group-12-iVQxx3": {
              left: "541px",
            },
          },
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4166027.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Roadmap5",
      root_element: ".x4166027",
      states_flow: {
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap1": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap2": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap3": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap4": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap5": {
          listeners: [],
          overrides: {},
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4166031.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Roadmap4",
      root_element: ".x4166031",
      states_flow: {
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap1": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap2": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap3": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap4": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap5": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4166033.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Roadmap3",
      root_element: ".x4166033",
      states_flow: {
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap1": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap2": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap3": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap4": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap5": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4166037.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Roadmap2",
      root_element: ".x4166037",
      states_flow: {
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap1": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap2": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap3": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap4": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap5": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4166039.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Roadmap1",
      root_element: ".x4166039",
      states_flow: {
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap1": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap2": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap3": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap4": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap5": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4166046.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Action",
      root_element: ".x4166046",
      states_flow: {
        "Property-1=Action": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Discover": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Guilds": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=NFTs": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4166047.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=NFTs",
      root_element: ".x4166047",
      states_flow: {
        "Property-1=Action": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Discover": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Guilds": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=NFTs": {
          listeners: [],
          overrides: {},
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4166048.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Discover",
      root_element: ".x4166048",
      states_flow: {
        "Property-1=Action": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Discover": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Guilds": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=NFTs": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4166049.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Guilds",
      root_element: ".x4166049",
      states_flow: {
        "Property-1=Action": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Discover": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Guilds": {
          listeners: [],
          overrides: {},
        },
        "Property-1=NFTs": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4163710.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Default",
      root_element: ".x4163710",
      states_flow: {
        "Property-1=Default": {
          listeners: [],
          overrides: {},
        },
        "Property-1=bottom": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".blowfishlogo-a6mBBc": {
              left: "0px",
            },
            ".bottom-bar-fXcEhu": {
              width: "1024px",
            },
            ".group-12-fXcEhu": {
              left: "797px",
            },
          },
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4163727.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Roadmap5",
      root_element: ".x4163727",
      states_flow: {
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap1": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap2": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap3": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap4": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap5": {
          listeners: [],
          overrides: {},
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4163731.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Roadmap4",
      root_element: ".x4163731",
      states_flow: {
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap1": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap2": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap3": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap4": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap5": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4163733.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Roadmap3",
      root_element: ".x4163733",
      states_flow: {
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap1": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap2": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap3": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap4": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap5": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4163737.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Roadmap2",
      root_element: ".x4163737",
      states_flow: {
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap1": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap2": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap3": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap4": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap5": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4163739.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Roadmap1",
      root_element: ".x4163739",
      states_flow: {
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap1": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap2": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap3": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap4": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap5": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4163776.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Action",
      root_element: ".x4163776",
      states_flow: {
        "Property-1=Action": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i41637763111614-DoBmaJ": {
              height: "auto",
              top: "246px",
              width: "210px",
            },
            ".feature-DoBmaJ": {
              height: "266px",
              width: "210px",
            },
            ".image-l4FFgU": {
              left: "0px",
              top: "56px",
            },
            ".title-i41637763111611-l4FFgU": {
              height: "auto",
              left: "64px",
              width: "auto",
            },
          },
        },
        "Property-1=Discover": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i41637763111614-DoBmaJ": {
              height: "auto",
              top: "246px",
              width: "210px",
            },
            ".feature-DoBmaJ": {
              height: "266px",
              width: "210px",
            },
            ".image-l4FFgU": {
              left: "0px",
              top: "56px",
            },
            ".title-i41637763111611-l4FFgU": {
              height: "auto",
              left: "64px",
              width: "auto",
            },
          },
        },
        "Property-1=Guilds": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i41637763111614-DoBmaJ": {
              height: "auto",
              top: "246px",
              width: "210px",
            },
            ".feature-DoBmaJ": {
              height: "266px",
              width: "210px",
            },
            ".image-l4FFgU": {
              left: "0px",
              top: "56px",
            },
            ".title-i41637763111611-l4FFgU": {
              height: "auto",
              left: "64px",
              width: "auto",
            },
          },
        },
        "Property-1=NFTs": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i41637763111614-DoBmaJ": {
              height: "auto",
              top: "246px",
              width: "210px",
            },
            ".feature-DoBmaJ": {
              height: "266px",
              width: "210px",
            },
            ".image-l4FFgU": {
              left: "0px",
              top: "56px",
            },
            ".title-i41637763111611-l4FFgU": {
              height: "auto",
              left: "64px",
              width: "auto",
            },
          },
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4163777.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=NFTs",
      root_element: ".x4163777",
      states_flow: {
        "Property-1=Action": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i41637773111625-lEPKNs": {
              height: "auto",
              top: "246px",
              width: "211px",
            },
            ".feature-lEPKNs": {
              height: "266px",
              width: "211px",
            },
            ".image-wHGcb2": {
              left: "0px",
              top: "56px",
            },
            ".title-i41637773111622-wHGcb2": {
              height: "auto",
              left: "80px",
              width: "auto",
            },
          },
        },
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i41637773111625-lEPKNs": {
              height: "auto",
              top: "246px",
              width: "211px",
            },
            ".feature-lEPKNs": {
              height: "266px",
              width: "211px",
            },
            ".image-wHGcb2": {
              left: "0px",
              top: "56px",
            },
            ".title-i41637773111622-wHGcb2": {
              height: "auto",
              left: "80px",
              width: "auto",
            },
          },
        },
        "Property-1=Discover": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i41637773111625-lEPKNs": {
              height: "auto",
              top: "246px",
              width: "211px",
            },
            ".feature-lEPKNs": {
              height: "266px",
              width: "211px",
            },
            ".image-wHGcb2": {
              left: "0px",
              top: "56px",
            },
            ".title-i41637773111622-wHGcb2": {
              height: "auto",
              left: "80px",
              width: "auto",
            },
          },
        },
        "Property-1=Guilds": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i41637773111625-lEPKNs": {
              height: "auto",
              top: "246px",
              width: "211px",
            },
            ".feature-lEPKNs": {
              height: "266px",
              width: "211px",
            },
            ".image-wHGcb2": {
              left: "0px",
              top: "56px",
            },
            ".title-i41637773111622-wHGcb2": {
              height: "auto",
              left: "80px",
              width: "auto",
            },
          },
        },
        "Property-1=NFTs": {
          listeners: [],
          overrides: {},
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4163778.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Discover",
      root_element: ".x4163778",
      states_flow: {
        "Property-1=Action": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i41637783111631-1D3m1x": {
              height: "auto",
              top: "246px",
              width: "210px",
            },
            ".feature-1D3m1x": {
              height: "266px",
              width: "210px",
            },
            ".image-dGGbV2": {
              left: "0px",
              top: "56px",
            },
            ".title-i41637783111628-dGGbV2": {
              height: "auto",
              left: "49px",
              width: "auto",
            },
          },
        },
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i41637783111631-1D3m1x": {
              height: "auto",
              top: "246px",
              width: "210px",
            },
            ".feature-1D3m1x": {
              height: "266px",
              width: "210px",
            },
            ".image-dGGbV2": {
              left: "0px",
              top: "56px",
            },
            ".title-i41637783111628-dGGbV2": {
              height: "auto",
              left: "49px",
              width: "auto",
            },
          },
        },
        "Property-1=Discover": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Guilds": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i41637783111631-1D3m1x": {
              height: "auto",
              top: "246px",
              width: "210px",
            },
            ".feature-1D3m1x": {
              height: "266px",
              width: "210px",
            },
            ".image-dGGbV2": {
              left: "0px",
              top: "56px",
            },
            ".title-i41637783111628-dGGbV2": {
              height: "auto",
              left: "49px",
              width: "auto",
            },
          },
        },
        "Property-1=NFTs": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i41637783111631-1D3m1x": {
              height: "auto",
              top: "246px",
              width: "210px",
            },
            ".feature-1D3m1x": {
              height: "266px",
              width: "210px",
            },
            ".image-dGGbV2": {
              left: "0px",
              top: "56px",
            },
            ".title-i41637783111628-dGGbV2": {
              height: "auto",
              left: "49px",
              width: "auto",
            },
          },
        },
      },
    },
  ];

  wrapper = document.querySelector(".x4163779.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Guilds",
      root_element: ".x4163779",
      states_flow: {
        "Property-1=Action": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i41637793111637-zG7ubQ": {
              height: "auto",
              top: "246px",
              width: "211px",
            },
            ".feature-zG7ubQ": {
              height: "266px",
              width: "211px",
            },
            ".image-S4rofQ": {
              left: "0px",
              top: "56px",
            },
            ".title-i41637793111634-S4rofQ": {
              height: "auto",
              left: "64px",
              width: "auto",
            },
          },
        },
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i41637793111637-zG7ubQ": {
              height: "auto",
              top: "246px",
              width: "211px",
            },
            ".feature-zG7ubQ": {
              height: "266px",
              width: "211px",
            },
            ".image-S4rofQ": {
              left: "0px",
              top: "56px",
            },
            ".title-i41637793111634-S4rofQ": {
              height: "auto",
              left: "64px",
              width: "auto",
            },
          },
        },
        "Property-1=Discover": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i41637793111637-zG7ubQ": {
              height: "auto",
              top: "246px",
              width: "211px",
            },
            ".feature-zG7ubQ": {
              height: "266px",
              width: "211px",
            },
            ".image-S4rofQ": {
              left: "0px",
              top: "56px",
            },
            ".title-i41637793111634-S4rofQ": {
              height: "auto",
              left: "64px",
              width: "auto",
            },
          },
        },
        "Property-1=Guilds": {
          listeners: [],
          overrides: {},
        },
        "Property-1=NFTs": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i41637793111637-zG7ubQ": {
              height: "auto",
              top: "246px",
              width: "211px",
            },
            ".feature-zG7ubQ": {
              height: "266px",
              width: "211px",
            },
            ".image-S4rofQ": {
              left: "0px",
              top: "56px",
            },
            ".title-i41637793111634-S4rofQ": {
              height: "auto",
              left: "64px",
              width: "auto",
            },
          },
        },
      },
    },
  ];

  wrapper = document.querySelector(".x2851348.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Default",
      root_element: ".x2851348",
      states_flow: {
        "Property-1=Default": {
          listeners: [],
          overrides: {},
        },
        "Property-1=bottom": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".blowfishlogo-UCPAXH": {
              left: "0px",
            },
            ".bottom-bar-xTJb45": {
              width: "1440px",
            },
            ".group-12-xTJb45": {
              left: "1213px",
            },
          },
        },
      },
    },
  ];

  wrapper = document.querySelector(".x53106158.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Roadmap5",
      root_element: ".x53106158",
      states_flow: {
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap1": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap2": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap3": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap4": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap5": {
          listeners: [],
          overrides: {},
        },
      },
    },
  ];

  wrapper = document.querySelector(".x53106156.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Roadmap4",
      root_element: ".x53106156",
      states_flow: {
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap1": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap2": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap3": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap4": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap5": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
      },
    },
  ];

  wrapper = document.querySelector(".x53106146.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Roadmap3",
      root_element: ".x53106146",
      states_flow: {
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap1": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap2": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap3": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap4": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap5": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
      },
    },
  ];

  wrapper = document.querySelector(".x53106150.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Roadmap2",
      root_element: ".x53106150",
      states_flow: {
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap1": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap2": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap3": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap4": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap5": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
      },
    },
  ];

  wrapper = document.querySelector(".x53106152.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Roadmap1",
      root_element: ".x53106152",
      states_flow: {
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap1": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Roadmap2": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap3": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap4": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
        "Property-1=Roadmap5": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
          },
        },
      },
    },
  ];

  wrapper = document.querySelector(".x53106167.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Action",
      root_element: ".x53106167",
      states_flow: {
        "Property-1=Action": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i531061673111614-Uo0xQR": {
              height: "auto",
              top: "246px",
              width: "314px",
            },
            ".feature-Uo0xQR": {
              height: "266px",
              width: "314px",
            },
            ".image-gHY9aS": {
              left: "0px",
              top: "56px",
            },
            ".title-i531061673111611-gHY9aS": {
              height: "auto",
              left: "116px",
              width: "auto",
            },
          },
        },
        "Property-1=Discover": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i531061673111614-Uo0xQR": {
              height: "auto",
              top: "246px",
              width: "314px",
            },
            ".feature-Uo0xQR": {
              height: "266px",
              width: "314px",
            },
            ".image-gHY9aS": {
              left: "0px",
              top: "56px",
            },
            ".title-i531061673111611-gHY9aS": {
              height: "auto",
              left: "116px",
              width: "auto",
            },
          },
        },
        "Property-1=Guilds": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i531061673111614-Uo0xQR": {
              height: "auto",
              top: "246px",
              width: "314px",
            },
            ".feature-Uo0xQR": {
              height: "266px",
              width: "314px",
            },
            ".image-gHY9aS": {
              left: "0px",
              top: "56px",
            },
            ".title-i531061673111611-gHY9aS": {
              height: "auto",
              left: "116px",
              width: "auto",
            },
          },
        },
        "Property-1=NFTs": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i531061673111614-Uo0xQR": {
              height: "auto",
              top: "246px",
              width: "314px",
            },
            ".feature-Uo0xQR": {
              height: "266px",
              width: "314px",
            },
            ".image-gHY9aS": {
              left: "0px",
              top: "56px",
            },
            ".title-i531061673111611-gHY9aS": {
              height: "auto",
              left: "116px",
              width: "auto",
            },
          },
        },
      },
    },
  ];

  wrapper = document.querySelector(".x53106168.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=NFTs",
      root_element: ".x53106168",
      states_flow: {
        "Property-1=Action": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i531061683111625-ECrGHK": {
              height: "auto",
              top: "246px",
              width: "315px",
            },
            ".feature-ECrGHK": {
              height: "266px",
              width: "315px",
            },
            ".image-zjGOAy": {
              left: "0px",
              top: "56px",
            },
            ".title-i531061683111622-zjGOAy": {
              height: "auto",
              left: "132px",
              width: "auto",
            },
          },
        },
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i531061683111625-ECrGHK": {
              height: "auto",
              top: "246px",
              width: "315px",
            },
            ".feature-ECrGHK": {
              height: "266px",
              width: "315px",
            },
            ".image-zjGOAy": {
              left: "0px",
              top: "56px",
            },
            ".title-i531061683111622-zjGOAy": {
              height: "auto",
              left: "132px",
              width: "auto",
            },
          },
        },
        "Property-1=Discover": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i531061683111625-ECrGHK": {
              height: "auto",
              top: "246px",
              width: "315px",
            },
            ".feature-ECrGHK": {
              height: "266px",
              width: "315px",
            },
            ".image-zjGOAy": {
              left: "0px",
              top: "56px",
            },
            ".title-i531061683111622-zjGOAy": {
              height: "auto",
              left: "132px",
              width: "auto",
            },
          },
        },
        "Property-1=Guilds": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i531061683111625-ECrGHK": {
              height: "auto",
              top: "246px",
              width: "315px",
            },
            ".feature-ECrGHK": {
              height: "266px",
              width: "315px",
            },
            ".image-zjGOAy": {
              left: "0px",
              top: "56px",
            },
            ".title-i531061683111622-zjGOAy": {
              height: "auto",
              left: "132px",
              width: "auto",
            },
          },
        },
        "Property-1=NFTs": {
          listeners: [],
          overrides: {},
        },
      },
    },
  ];

  wrapper = document.querySelector(".x53106169.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Discover",
      root_element: ".x53106169",
      states_flow: {
        "Property-1=Action": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i531061693111631-N5ZPMv": {
              height: "auto",
              top: "246px",
              width: "314px",
            },
            ".feature-N5ZPMv": {
              height: "266px",
              width: "314px",
            },
            ".image-KySSXE": {
              left: "0px",
              top: "56px",
            },
            ".title-i531061693111628-KySSXE": {
              height: "auto",
              left: "101px",
              width: "auto",
            },
          },
        },
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i531061693111631-N5ZPMv": {
              height: "auto",
              top: "246px",
              width: "314px",
            },
            ".feature-N5ZPMv": {
              height: "266px",
              width: "314px",
            },
            ".image-KySSXE": {
              left: "0px",
              top: "56px",
            },
            ".title-i531061693111628-KySSXE": {
              height: "auto",
              left: "101px",
              width: "auto",
            },
          },
        },
        "Property-1=Discover": {
          listeners: [],
          overrides: {},
        },
        "Property-1=Guilds": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i531061693111631-N5ZPMv": {
              height: "auto",
              top: "246px",
              width: "314px",
            },
            ".feature-N5ZPMv": {
              height: "266px",
              width: "314px",
            },
            ".image-KySSXE": {
              left: "0px",
              top: "56px",
            },
            ".title-i531061693111628-KySSXE": {
              height: "auto",
              left: "101px",
              width: "auto",
            },
          },
        },
        "Property-1=NFTs": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i531061693111631-N5ZPMv": {
              height: "auto",
              top: "246px",
              width: "314px",
            },
            ".feature-N5ZPMv": {
              height: "266px",
              width: "314px",
            },
            ".image-KySSXE": {
              left: "0px",
              top: "56px",
            },
            ".title-i531061693111628-KySSXE": {
              height: "auto",
              left: "101px",
              width: "auto",
            },
          },
        },
      },
    },
  ];

  wrapper = document.querySelector(".x53106170.component-wrapper")
  if (wrapper) wrapper.timeline_data = [
    {
      initial_state_name: "Property-1=Guilds",
      root_element: ".x53106170",
      states_flow: {
        "Property-1=Action": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i531061703111637-Eu0whD": {
              height: "auto",
              top: "246px",
              width: "315px",
            },
            ".feature-Eu0whD": {
              height: "266px",
              width: "315px",
            },
            ".image-LfONO6": {
              left: "0px",
              top: "56px",
            },
            ".title-i531061703111634-LfONO6": {
              height: "auto",
              left: "116px",
              width: "auto",
            },
          },
        },
        "Property-1=Default": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i531061703111637-Eu0whD": {
              height: "auto",
              top: "246px",
              width: "315px",
            },
            ".feature-Eu0whD": {
              height: "266px",
              width: "315px",
            },
            ".image-LfONO6": {
              left: "0px",
              top: "56px",
            },
            ".title-i531061703111634-LfONO6": {
              height: "auto",
              left: "116px",
              width: "auto",
            },
          },
        },
        "Property-1=Discover": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i531061703111637-Eu0whD": {
              height: "auto",
              top: "246px",
              width: "315px",
            },
            ".feature-Eu0whD": {
              height: "266px",
              width: "315px",
            },
            ".image-LfONO6": {
              left: "0px",
              top: "56px",
            },
            ".title-i531061703111634-LfONO6": {
              height: "auto",
              left: "116px",
              width: "auto",
            },
          },
        },
        "Property-1=Guilds": {
          listeners: [],
          overrides: {},
        },
        "Property-1=NFTs": {
          listeners: [],
          overrides: {
            "": {
              position: "relative",
            },
            ".description-i531061703111637-Eu0whD": {
              height: "auto",
              top: "246px",
              width: "315px",
            },
            ".feature-Eu0whD": {
              height: "266px",
              width: "315px",
            },
            ".image-LfONO6": {
              left: "0px",
              top: "56px",
            },
            ".title-i531061703111634-LfONO6": {
              height: "auto",
              left: "116px",
              width: "auto",
            },
          },
        },
      },
    },
  ];
}

export default initializeTimelines;
