import React, {useContext, useEffect} from 'react';
import {NFT_PlanetGenesis_MediumPlanet, planet_genesis_circle} from "../../../../assets/images/PlanetSalePagePrivate";
import BorderImg from "../../../PlanetSalePagePrivate/components/BorderImg/BorderImg";
import {PlanetGenesisSaleText} from "../../../../utils/GeneralUtils";
import '../../../PlanetSalePagePrivate/components/PlanetGenesis/PlanetGenesisSale.scss'


import {PlanetSaleContext} from "../../../../components/Context/PlanetSalePageContext";

import {ethers} from "ethers";
import PGERC1155Abi from "../../../../assets/abi/PGERC1155.json";
import {PGERC1155Address_PlanetSale} from "../../../../utils/Globals";

let pgerContract;

const planets = [{
  id: 100,
  updateInterval: 10000, // 10s
}, {
  id: 101,
  updateInterval: 10000, // 10s
}, {
  id: 102,
  updateInterval: 60000, // 1m
}, {
  id: 103,
  updateInterval: 120000, // 2m
}, {
  id: 104,
  updateInterval: 120000, // 2m
}]

export default function PublicPlanetGenesisSale() {

  const planetSaleContext = useContext(PlanetSaleContext);

  useEffect(() => {
    getMintedCountAndSupply();

    // Schedule a check of the latest minted count for each planet type. If the
    // supply of a planet type is exhausted, stop checking.
    for (let planet of planets) {
      if (!planet.interval) {

        refreshMintedCount(planet)
        planet.interval = setInterval(() => refreshMintedCount(planet), planet.updateInterval)
      }
    }
    // eslint-disable-next-line
  }, []);

  async function getMintedCountAndSupply() {
    let prov = new ethers.providers.Web3Provider(window.ethereum);
    let sign = prov.getSigner();
    pgerContract = new ethers.Contract(PGERC1155Address_PlanetSale, PGERC1155Abi, sign);

    await getTotalMintedCounts();
  }

  async function refreshMintedCount(planet) {

    if (!planet.totalSupply) {
      planet.totalSupply = await getTotalSupplyForPlanetId(planet.id)
    }

    let minted = await getTotalMintedForPlanetId(planet.id)
    if (planet.totalSupply.eq(minted)) {
      clearInterval(planet.interval)
    }
  }

  async function getTotalMintedCounts() {
    for (let planet of planets) {
      await getTotalMintedForPlanetId(planet.id);
    }
  }

  async function getTotalSupplyForPlanetId(planetId) {
    let supply = await pgerContract.supplies(planetId);
    planetSaleContext.setTotalSupplyForPlanetId(planetId, supply);
    return supply
  }

  async function getTotalMintedForPlanetId(planetId) {
    let minted = await pgerContract.minted(planetId);
    planetSaleContext.setTotalMintedForPlanetId(planetId, minted);
    return minted
  }

  return (<>
    <div className={"pspp-main-body"}>
      <img alt={""} className={"pgs-planet"} src={NFT_PlanetGenesis_MediumPlanet}/>
      <div id='pgs-border-img'>
        <BorderImg text={PlanetGenesisSaleText.title}/>
      </div>
      <div className={"gsp-text-container-verify"}>
        <div className={"gsp-text-section"}>
          <p className={"gsp-text-adjustment"}>{PlanetGenesisSaleText.paragraph1}</p>
          <p className={"gsp-text-adjustment"}>{PlanetGenesisSaleText.paragraph2}</p>
          <p className={"gsp-text-adjustment"}>{PlanetGenesisSaleText.paragraph3}</p>
          <p className={"gsp-text-adjustment"}>{PlanetGenesisSaleText.paragraph4}</p>
          <p className={"gsp-text-verify"}>{PlanetGenesisSaleText.paragraph5_verify}</p>
        </div>
      </div>
      <img alt={""} className={"psg-left-shape"} src={planet_genesis_circle}/>
    </div>
  </>);
}
