import './ToggleSwitch.scss';

type ToggleSwitchProps = {
  label: string;
  isChecked: boolean;
  className?: string;
  checkHandler: (state: boolean) => void;
};

export default function ToggleSwitch({ label, isChecked, className, checkHandler }: ToggleSwitchProps) {
  const toggleHandler = () => {
    checkHandler(!isChecked);
  };

  return (
    <div className={className ? `${className} toggle-switch-container` : 'toggle-switch-container'} onChange={toggleHandler}>
      <span className="toggle-switch-label">{label}</span>
      <label className="toggle-switch" htmlFor="checkbox">
        <input type="checkbox" id="checkbox" defaultChecked={isChecked} />
        <div className="toggle-slider toggle-round"></div>
      </label>
    </div>
  );
}
