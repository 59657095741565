import {
  avatar_default,
  blockchain_coinbase_icon,
  blockchain_metamask_icon,
  blockchain_wallet_icon,
  chain_link_icon,
  ended_icon,
  epic_games_logo_white,
  steam_logo_white,
} from "../assets";
import axios, {AxiosResponse} from "axios";
import jwt, {JwtPayload} from "jsonwebtoken";
import Cookies from 'universal-cookie';
import {createContext} from "react";
import {walletContext} from "./WalletContext";
import {AccountProvider, AuthButtonProps} from "../routes/Account";
import {AuthResponse} from "../hooks/useAuthentication";
import {Connector, disconnect} from '@wagmi/core'
import {
  AccessTokenResponse,
  AuthenticationProvider,
  AuthenticationSource,
  AuthResult,
  LinkedProvider,
  PGJwtPayload,
  SigningTokenResponse,
  TokenRequest,
  TokenRequestV2,
  TokenType
} from "@pg/auth-api";

interface AuthenticationData {
  token: AccessTokenResponse;
}

// Link types should be exported from account service
enum LinkAction {
  Link = 'link',
  Unlink = 'unlink',
}

interface AccountLinkRequest {
  action: LinkAction;
  accountDetails: LinkedProvider;
}

const MAX_INTEGER = 2147483647;

/**
 * Manages authentication for the application
 *
 * Responsible for the following:
 * - Obtaining access tokens
 * - Refreshing access tokens
 * - Storing and retrieving access tokens
 * - Syncing access tokens with session cookie and vice versa
 * - Authentication operations: Login, Link, Unlink
 * - Account link / unlink operations
 */
export class AuthenticationManager {
  /**
   * The key used to store the authentication data in session storage
   * @private
   */
  private readonly storageKey = 'pg.authentication';
  /**
   * The name of the cookie used to store the session token
   * @private
   */
  private readonly sessionCookie = 'authToken';
  private readonly federatedAuthResponseKey = 'pg.authResponse';

  private static singletonInstance?: AuthenticationManager;

  private authenticationData?: AuthenticationData;
  private refreshInterval?: NodeJS.Timeout;

  static get instance(): AuthenticationManager {
    if (!AuthenticationManager.singletonInstance) {
      AuthenticationManager.singletonInstance = new AuthenticationManager();
      AuthenticationManager.singletonInstance.initialise();
      // attempt to load the authentication data
      AuthenticationManager.singletonInstance.loadAuthenticationData();
    }
    return AuthenticationManager.singletonInstance;
  }

  public async logout(): Promise<void> {
    localStorage.removeItem(this.storageKey);
    clearTimeout(this.refreshInterval);
    await disconnect();
    walletContext.clearPgWallet();
    this.authenticationData = undefined;
    this.authStatePublisher.setState(undefined);
    this.clearFederatedAuthResponse();
  }

  private loadAuthenticationData(): AuthenticationData | undefined {
    if (this.authenticationData) return this.authenticationData;

    const authSessionData = localStorage.getItem(this.storageKey);
    if (!authSessionData) return undefined;

    this.authenticationData = JSON.parse(authSessionData) as AuthenticationData;
    // we also need to call saveAccessToken to setup the refresh interval
    this.saveAccessToken(this.authenticationData.token);

    return this.authenticationData;
  }

  private initialise() {
    // This will only run on a page reload, so we should clear any federated auth responses
    this.clearFederatedAuthResponse();
    // Check for a new session cookie on page reload (typically via a redirect)
    this.syncAccessTokenFromSessionCookie();
  }

  private getAccessToken(): string | undefined {
    this.loadAuthenticationData();
    return this.authenticationData?.token.accessToken;
  }

  private getRefreshToken(): string | undefined {
    if (!this.loadAuthenticationData()) return undefined;
    return this.authenticationData?.token.refreshToken;
  }

  public getAccessTokenPayload(): PGJwtPayload & JwtPayload | undefined {
    const accessToken = this.getAccessToken();
    if (!accessToken) return undefined;

    // otherwise, decode the token
    const payload = jwt.decode(accessToken, { json: true }) as PGJwtPayload & JwtPayload;
    return payload;
  }

  public isAuthenticated(): boolean {
    const payload = this.getAccessTokenPayload();
    if (!payload) return false;

    // otherwise, lets check the expiration
    return !this.isExpired();
  }

  /**
   * Returns true if the access token is expired
   */
  public isExpired(): boolean {
    const payload = this.getAccessTokenPayload();

    // otherwise, lets check the expiration
    const now = Date.now();
    const jwtExpiration = (payload?.exp ?? 0) * 1000;
    return jwtExpiration < now;
  }

  /**
   * Use the refresh token to obtain a new access token
   */
  public async refreshAccessToken(): Promise<AccessTokenResponse | undefined> {
    const payload = this.getAccessTokenPayload();
    if (!payload) throw new Error('No access token found');
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) throw new Error('No refresh token found');

    console.debug('DEBUG: Refreshing access token');
    const body: TokenRequestV2 = {
      authSource: AuthenticationSource.RefreshToken,
      authRequest: {
        accountId: payload.accountId,
        refreshToken: refreshToken,
      }
    }

    try {
      const url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_AUTH}/v2/token`;
      const res = await axios.post<AccessTokenResponse, AxiosResponse<AccessTokenResponse>, TokenRequestV2>(url, body);
      this.saveAccessToken(res.data);
      return res.data;
    } catch (e) {
      console.error(`Expired refresh token, logging out`);
      await this.logout();
    }
    return;
  }

  public async requestSigningToken(walletAddress: string): Promise<string> {
    const body: TokenRequestV2 = {
      authSource: AuthenticationSource.Blockchain,
      authRequest: {
        type: TokenType.SIGNING,
        walletAddress: walletAddress,
      }
    }

    const response = await axios.post<SigningTokenResponse, AxiosResponse<SigningTokenResponse>, TokenRequestV2>(`${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_AUTH}/v2/token`, body);
    return response.data.signingToken;
  }

  public async requestAccessToken(walletAddress: string, signingToken: string, signature: string): Promise<AccessTokenResponse> {
    const body: TokenRequestV2 = {
      authSource: AuthenticationSource.Blockchain,
      authRequest: {
        type: TokenType.ACCESS,
        walletAddress: walletAddress,
        signingToken: signingToken,
        signature: signature,
      }
    };

    const response = await axios.post<AccessTokenResponse, AxiosResponse<AccessTokenResponse>, TokenRequestV2>(`${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_AUTH}/v2/token`, body);
    this.saveAccessToken(response.data);
    return response.data;
  }

  public saveAccessToken(accessToken: AccessTokenResponse): void {
    clearTimeout(this.refreshInterval);
    this.authenticationData = { token: accessToken };
    // lets set an interval to refresh the access token prior to expiration
    const payload = this.getAccessTokenPayload();

    // lets update the wallet context
    const blockchainProvider = payload?.providers.find((provider) => provider.provider === AuthenticationProvider.Blockchain);
    if (blockchainProvider) {
      if (walletContext.currentWallet !== blockchainProvider.providerId){
        // TODO Do we still need the wallet provider?
        walletContext.setCurrentWallet(blockchainProvider.providerId, null);
      }
    } else {
      // clear the wallet context
      walletContext.clearPgWallet();
    }

    localStorage.setItem(this.storageKey, JSON.stringify(this.authenticationData));

    if (!payload?.exp) return;
    const now = Date.now();
    const jwtExpiration = payload.exp * 1000;
    const timeToExpiration = jwtExpiration - now;
    let refreshTime = timeToExpiration - (5 * 1000);  // refresh 5 seconds prior to expiration
    // max supported timeout is 2147483647 (2^32)
    if (refreshTime > MAX_INTEGER) refreshTime = MAX_INTEGER;
    if (refreshTime < 0) {
      this.refreshAccessToken();  // fire and forget to update in the background
      return;
    }
    this.refreshInterval = setTimeout(this.refreshAccessToken.bind(this), refreshTime);
    this.authStatePublisher.setState(payload);
  }

  public authHeader() {
    const token = this.getAccessToken();
    return { headers: { Authorization: `Bearer ${token}` } };
  }

  /**
   * Unlink a provider from an account
   * @param provider
   */
  public async unlinkProvider(provider: LinkedProvider): Promise<AuthResponse> {
    const config = this.authHeader();
    const body: AccountLinkRequest = {
      action: LinkAction.Unlink,
      accountDetails: provider,
    };

    const url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_AUTH}/v1/auth/unlink`;

    // we expect a 200 response with an updated access token
    try {
      const response = await axios.post<AccessTokenResponse, AxiosResponse<AccessTokenResponse>, AccountLinkRequest>(url, body, config);
      // if successful, lets refresh the access token
      this.saveAccessToken(response.data);
      const authResponse: AuthResponse = {
        success: true,
        text: `Successfully unlinked provider ${provider.provider}`,
        show: true,
      };
      return authResponse;
    } catch (e) {
      const err: any = e;
      console.error(`Error unlinking provider ${provider.provider}: ${err?.response?.data?.message ?? err?.message ?? 'unknown error'}`);
      const authResponse: AuthResponse = {
        success: false,
        text: `Error unlinking provider ${provider.provider}: ${err?.response?.data?.message ?? err?.message ?? 'unknown error'}`,
        show: true,
      }
      return authResponse;
    }
  }

  /**
   * Link a federated provider to an account (this isn't for use with blockchain auth)
   * This is a two step process:
   *  - Initiate the link by calling this method
   *  - Redirect the user to the link url
   *  - Once the user has authenticated, the link will be completed
   *  - The user will be redirected back to the application (which must then process the updated access token from the session cookie)
   *
   * @param provider
   */
  public generateLinkUrl(provider: AuthenticationProvider): string {
    if (provider === AuthenticationProvider.Blockchain) throw new Error('Cannot link blockchain provider this way, use the link wallet method');
    // prior to linking, we need to ensure the state of the session cookie is up to date
    this.syncAccessTokenToSessionCookie();
    return `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_AUTH}/v1/auth/init?provider=${provider}&link=true`;
  }

  public async linkWallet(walletAddress: string, signingToken: string, signature: string): Promise<void> {
    const config = this.authHeader();
    const body: TokenRequest = {
      type: TokenType.ACCESS,
      walletAddress: walletAddress,
      signingToken: signingToken,
      signature: signature,
    };

    const url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_AUTH}/v1/auth/link`;

    // we expect a 204 response
    const res = await axios.post<AccessTokenResponse, AxiosResponse<AccessTokenResponse>, TokenRequest>(url, body, config);
    // if successful, lets update the access token
    this.saveAccessToken(res.data);
    return;
  }

  /**
   * To be called after an OAuth type authentication flow has completed
   */
  public syncAccessTokenFromSessionCookie(): AuthResult | undefined {
    const cookies = new Cookies();
    const cookie = cookies.get(this.sessionCookie);
    if (!cookie) return;

    const cookieData = cookie as AuthResult;
    this.saveFederatedAuthResponse(cookieData);

    if (cookieData.success && cookieData?.token) {
      this.saveAccessToken(cookieData.token);
    }
    // now lets clear the cookie
    cookies.remove(this.sessionCookie, { path: '/', domain: this.cookieDomain() });
    return cookieData;
  }

  public saveFederatedAuthResponse(authCookie: AuthResult): void {
    // lets store any response messages in the session storage
    const federatedAuthResponse: AuthResponse = {
      success: authCookie.success,
      text: authCookie.message ?? '',
      show: !!authCookie.message,
    };
    sessionStorage.setItem(this.federatedAuthResponseKey, JSON.stringify(federatedAuthResponse));
    this.authResponsePublisher.setState(federatedAuthResponse);
  }

  public getFederatedAuthResponse(): AuthResponse | undefined {
    const data = sessionStorage.getItem(this.federatedAuthResponseKey);
    if (!data) return undefined;
    return JSON.parse(data) as AuthResponse;
  }

  public clearFederatedAuthResponse(): void {
    sessionStorage.removeItem(this.federatedAuthResponseKey);
    this.authResponsePublisher.setState(undefined);
  }

  public syncAccessTokenToSessionCookie() {
    const cookies = new Cookies(null, { path: '/', sameSite: true });
    // we need to create a new cookie
    const accessToken = this.getAccessToken();
    if (!accessToken) return;
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) return;

    const cookieData: AuthResult = {
      success: true,
      token: { accessToken: accessToken, refreshToken: refreshToken },
    };

    // cookies.set(this.sessionCookie, JSON.stringify(cookieData), { path: '/', sameSite: true });
    // we set the session cookie to lax to allow it to be included with the OAuth redirect back to the auth server
    cookies.set(this.sessionCookie, cookieData, { path: '/', sameSite: 'lax', domain: this.cookieDomain() });
  }

  private cookieDomain(): string {
    const searchStr = '//api.';
    const envUrl = process.env.REACT_APP_BASEURL ?? '';
    const apiIndex = envUrl.indexOf(searchStr);
    if (apiIndex === -1) return '/';
    return envUrl.substring(apiIndex + searchStr.length);
  }

  // React state hooks
  public authStatePublisher: ReactStatePublisher<PGJwtPayload & JwtPayload> = new ReactStatePublisher<PGJwtPayload & JwtPayload>();
  public authResponsePublisher: ReactStatePublisher<AuthResponse> = new ReactStatePublisher<AuthResponse>();
}

/**
 * Helper class to publish state updates to React components
 * This is used to trigger a rebuild of the React component tree
 *
 * This can be used in a component with the useSyncExternalStore hook as follows:
 * const response = useSyncExternalStore<T>(ReactStatePublisher<T>.setStateSetter.bind(ReactStatePublisher), ReactStatePublisher<T>.bind(ReactStatePublisher));
 *
 * @param T The type of the state
 */
class ReactStatePublisher<T> {
  // React hooks
  private stateSetter?: (payload: T | undefined) => void;

  public setStateSetter(setter: (payload: T | undefined) => void) {
    this.stateSetter = setter;
    return () => this.stateSetter = undefined;
  }

  /**
   * Trigger a state update for the react context
   * This will trigger a rebuild for all React component that are consuming this context
   * {@code const context = useContext(T)}
   *
   * @param value
   * @private
   */
  public setState(value: T | undefined) {
    if (this.stateSetter !== undefined) this.stateSetter(value);
  }

  public get isListening(): boolean {
    return this.stateSetter !== undefined;
  }
}

export const AuthContext: React.Context<(PGJwtPayload & jwt.JwtPayload) | undefined> = createContext(AuthenticationManager.instance.getAccessTokenPayload());

interface ProviderIcon {
  name: string;
  icon: string;
}

export const defaultProviderIcon = ended_icon;
export const providerIconMap: ProviderIcon[] = [
  { name: 'MetaMask',  icon: blockchain_metamask_icon },
  { name: 'Coinbase Wallet',  icon: blockchain_coinbase_icon },
  { name: 'WalletConnect',  icon: blockchain_wallet_icon },
  { name: AuthenticationProvider.Steam,  icon: steam_logo_white },
  { name: AuthenticationProvider.Epic,  icon: epic_games_logo_white },
];

// New options for dedicated page
function unauthenticatedAccountOptions(connectors: AuthButtonProps[]): AccountProvider[] {
  // TODO if a wallet is already connected, we can bypass the wallet connection step
  // TODO how do I best hookup the signature / access token requests on wallet auth?
  const options: AccountProvider[] = connectors.map((button) => {
    return {
      label: `SIGN IN WITH ${button.label.toUpperCase()}`,
      icon: button.icon!,
      provider: AuthenticationProvider.Blockchain,
      button: { ...button, label: 'SIGN IN' }
    };
  });

  return [
    ...options,
    {
      label: 'SIGN IN WITH EPIC',
      provider: AuthenticationProvider.Epic,
      icon: providerIconMap.find((icon) => icon.name === AuthenticationProvider.Epic)?.icon ?? defaultProviderIcon,
      button: {
        label: 'SIGN IN',
        link: `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_AUTH}/v1/auth/init?provider=${AuthenticationProvider.Epic}`,
      }
    },
    {
      label: 'SIGN IN WITH STEAM',
      icon: providerIconMap.find((icon) => icon.name === AuthenticationProvider.Steam)?.icon ?? defaultProviderIcon,
      provider: AuthenticationProvider.Steam,
      button: {
        label: 'SIGN IN',
        link: `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_AUTH}/v1/auth/init?provider=${AuthenticationProvider.Steam}`,
      }
    },
  ];
}

/**
 * Returns the navigation items for the authenticated user
 */
export const authenticatedAccountOptions = (accessTokenPayload: (PGJwtPayload & JwtPayload) | undefined, blockchainButtons: AuthButtonProps[], isSigned: boolean, connector: Connector | undefined): AccountProvider[] => {
  const authManager: AuthenticationManager = AuthenticationManager.instance;
  const navItems: AccountProvider[] = [];
  let label = 'NOT CONNECTED';

  if(accessTokenPayload?.accountCode) {
    // code will be PG-111222333444
    const accountCodeParts = accessTokenPayload?.accountCode.split('-');
    // split the number part into lots of 3
    const accountCodeChunks = accountCodeParts[1].match(/.{1,3}/g);
    label = `PG-${accountCodeChunks?.join('-')}`;
  }

  navItems.push({
    label: label,
    icon: avatar_default,
    button: authManager.isAuthenticated() ? {
      label: `LOGOUT`,
      onClick: authManager.logout.bind(authManager),
      class: 'account-auth-button--unlink'
    } : undefined,
  });

  if (!accessTokenPayload) {
    navItems.push(...unauthenticatedAccountOptions(blockchainButtons));
    return navItems;
  }

  const linkedProviders: LinkedProvider[] = accessTokenPayload.providers ?? [];

  const blockchainProvider = linkedProviders.find((provider) => provider.provider === AuthenticationProvider.Blockchain);
  const isLinked = blockchainProvider !== undefined;
  const canUnlink = linkedProviders.length > 1 && isLinked;
  // TODO re add this back in once we flesh out the unlink functionality
  // const disabled = isLinked ? !canUnlink : false;

  if (isLinked) {
    // TODO re add this back in once we flesh out the unlink functionality
    // navItems.push({
    //   icon: chain_link_icon,
    //   label: `WALLET${isLinked ? ` (${blockchainProvider?.providerId})` : ''}`,
    //   provider: AuthenticationProvider.Blockchain,
    //   confirmAction: canUnlink,
    //   button: {
    //     label: isLinked ? 'UNLINK' : 'LINK',
    //     onClick: isLinked ? authManager.unlinkProvider.bind(authManager, blockchainProvider!) : undefined,
    //     children: isLinked ? undefined : blockchainButtons,
    //     disabled: disabled,
    //     class: isLinked ? 'account-auth-button--unlink' : ''
    //   },
    // });
    navItems.push({
      icon: chain_link_icon,
      label: `WALLET${isLinked ? ` (${blockchainProvider?.providerId})` : ''}`,
      provider: AuthenticationProvider.Blockchain,
      confirmAction: canUnlink,
    });
  } else {
    // we need to provide options for each supported provider to connect / link
    const walletOptions: AccountProvider[] = blockchainButtons.map((button) => {
      return {
        label: `LINK WITH ${button.label.toUpperCase()}`,
        icon: button.icon!,
        provider: AuthenticationProvider.Blockchain,
        button: { ...button, label: 'LINK' }
      };
    });
    navItems.push(...walletOptions);
  }

  // if we have a linked wallet, but not presently connected, lets offer the option to connect
  if (isLinked) {
    if (!isSigned) {
      // lets provide a list of supported providers with an option to connect
      navItems.push(...blockchainButtons.map((button) => {
        return {
          label: `${button.label.toUpperCase()}`,
          icon: button.icon!,
          provider: AuthenticationProvider.Blockchain,
          button: { ...button, label: 'CONNECT', class: 'account-auth-button--link' },
          isBusy: connector?.name === button.label && !isSigned,
        };
      }));
    }
  }

  navItems.push(generateAuthenticatedProviderEntry(linkedProviders, AuthenticationProvider.Epic));
  navItems.push(generateAuthenticatedProviderEntry(linkedProviders, AuthenticationProvider.Steam));

  return navItems;
}

function generateAuthenticatedProviderEntry(linkedProviders: LinkedProvider[], authenticationProvider: AuthenticationProvider): AccountProvider {
  const authManager: AuthenticationManager = AuthenticationManager.instance;
  const provider = linkedProviders.find((provider) => provider.provider === authenticationProvider);
  const isLinked = provider !== undefined;
  const canUnlink = linkedProviders.length > 1 && isLinked;
  const disabled = isLinked ? !canUnlink : false;
  let providerLabel = authenticationProvider.toUpperCase();
  if(isLinked) {
    providerLabel = `${providerLabel} ACCOUNT IS LINKED`;
  } else {
    providerLabel = `LINK WITH ${providerLabel}`
  }

  const accountProvider: AccountProvider = {
    label: providerLabel,
    icon: providerIconMap.find((icon) => icon.name === authenticationProvider)?.icon ?? defaultProviderIcon,
    provider: authenticationProvider,
    confirmAction: canUnlink,
  }

  if(!isLinked) {
    accountProvider.button =  {
      label: 'LINK',
      linkBuilder: authManager.generateLinkUrl.bind(authManager, authenticationProvider),
      onClick: undefined,
      disabled: disabled,
      class: ''
    };
  }

  return accountProvider;
  // TODO re add this back in once we flesh out the unlink functionality
  // return {
  //   label: authenticationProvider.toUpperCase(),
  //   icon: providerIconMap.find((icon) => icon.name === authenticationProvider)?.icon ?? defaultProviderIcon,
  //   provider: authenticationProvider,
  //   confirmAction: canUnlink,
  //   button: {
  //     label: isLinked ? 'UNLINK' : 'LINK',
  //     linkBuilder: isLinked ? undefined : authManager.generateLinkUrl.bind(authManager, authenticationProvider),
  //     onClick: isLinked ? authManager.unlinkProvider.bind(authManager, provider!) : undefined,
  //     disabled: disabled,
  //     class: isLinked ? 'account-auth-button--unlink' : ''
  //   },
  // };
}
