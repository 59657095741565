import { useEffect, useState } from 'react';
import { useNetwork } from 'wagmi';
import { IsOnCorrectChain } from '../components/SwitchNetworkChain';

export function useIsCorrectChain() {
  const network = useNetwork();
  const [isCorrectChain, setIsCorrectChain] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const isChainCorrect = IsOnCorrectChain(network);
    setIsCorrectChain(isChainCorrect);
  }, [network]);

  return isCorrectChain;
}
