import './PlanetResourceList.scss';

import { DoubleIconCard } from '../Common/DoubleIconCard/DoubleIconCard';
import { PG_ICON_PREFIX } from '../../utils/Globals';

type PlanetResourceListProps = {
  resourceData: PlanetResourceData;
};

export type PlanetResourceData = {
  resourceValue1: number;
  resourceValue2: number;
  resourceValue3: number;
  resourceValue4: number;
  resourceValue5: number;
  resourceValue6: number;
  resourceValue7: number;
  resourceValue8: number;
  resourceValue9: number;
};

type ResourceValue =
  | 'Solid / Stable - CARBITE'
  | 'Liquid / Stable - COLLERONE'
  | 'Gas / Stable - XENINE'
  | 'Solid / Unstable - ARRATITE'
  | 'Liquid / Unstable - JAXONE'
  | 'Gas / Unstable - MOXALINE'
  | 'Solid / Radioactive - ZETRONITE'
  | 'Liquid / Radioactive - TETRONE'
  | 'Gas / Radioactive - NEOLINE';

type ResourceKeyName =
  | 'resourceValue1'
  | 'resourceValue2'
  | 'resourceValue3'
  | 'resourceValue4'
  | 'resourceValue5'
  | 'resourceValue6'
  | 'resourceValue7'
  | 'resourceValue8'
  | 'resourceValue9';

// Tuple type
type ResourceIcons = [string, string];

export const PlanetResourceList = ({ resourceData }: PlanetResourceListProps) => {
  const resourceValueMap: Record<ResourceKeyName, ResourceValue> = {
    resourceValue1: 'Solid / Stable - CARBITE',
    resourceValue2: 'Liquid / Stable - COLLERONE',
    resourceValue3: 'Gas / Stable - XENINE',
    resourceValue4: 'Solid / Unstable - ARRATITE',
    resourceValue5: 'Liquid / Unstable - JAXONE',
    resourceValue6: 'Gas / Unstable - MOXALINE',
    resourceValue7: 'Solid / Radioactive - ZETRONITE',
    resourceValue8: 'Liquid / Radioactive - TETRONE',
    resourceValue9: 'Gas / Radioactive - NEOLINE',
  };

  const getResourceIcons = (resourceValue: ResourceValue): ResourceIcons => {
    const [icon1, icon2] = resourceValue.split(' / ').map(part => `${PG_ICON_PREFIX}resource-${part.trim().toLowerCase()}`);
    return [icon1, icon2];
  };

  return (
    <div className="planet-resource-list">
      {(Object.keys(resourceData) as ResourceKeyName[]).map((key: ResourceKeyName) => {
        const [icon1, icon2] = getResourceIcons(resourceValueMap[key]);
        return (
          <DoubleIconCard
            key={key}
            icon1={icon1}
            icon2={icon2}
            mainText={resourceValueMap[key].split(' - ')[0].toUpperCase()}
            subText={resourceValueMap[key].split(' - ')[1].toUpperCase()}
            value={resourceData[key]}
          />
        );
      })}
    </div>
  );
};
