import { useEffect, useState } from 'react';
import { getPlanetDetails } from '../utils/ApiCalls';

export function useFetchPlanets(mission) {
  const [planets, setPlanets] = useState(null);
  const [planetsError, setPlanetsError] = useState(null);
  const [arePlanetsLoading, setArePlanetsLoading] = useState(false);

  useEffect(() => {
    const fetchPlanetDetails = async () => {
      setArePlanetsLoading(true);
      try {
        const planetDetails = await getPlanetDetails(mission.id);
        setPlanets(planetDetails);
        setArePlanetsLoading(false);
      } catch (e) {
        setArePlanetsLoading(false);
        setPlanetsError(e);
      }
    };
    if (mission && mission.id) {
      fetchPlanetDetails();
    }
  }, [mission]);
  return { planetDetails: planets, planetsError, arePlanetsLoading };
}
