import React from 'react';
import './ClaimingEpisodeModal.scss';
import {Modal} from "react-bootstrap";
import {closeIcon} from "../../../assets";
import TiltedButton from "../../TiltedButton/TiltedButton";
import {claim_episode_bottem_horn, claim_episode_header_horn, flame, right_arrows} from "../../../assets/images/PopUps";
import {agreement, claimEpisode1, claimEpisode2, claimEpisode3, claimEpisode4, colors} from "../../../utils/GeneralUtils";
import BackgroundBanner from "../PolygonBackgrounds/BackgroundBanner/BackgroundBanner";
import PolygonBackgrounds from "../PolygonBackgrounds/PolygonBackground/PlygonBackgrounds";
import Badge from "../PolygonBackgrounds/Badge/Badge";
import IconElement from "../PolygonBackgrounds/IconElements/IconElement";
import {plusIcon} from "../../../assets/images/icons";
import PlusIconElement from "../PolygonBackgrounds/IconElements/PlusIconElement/PlusIconElement";
import Medal from "../PolygonBackgrounds/Medal/Medal";
import {BUTTON_ACTION, CLAIMING_EPISODE, converterAddress, episodeIds} from "../../../utils/Globals";
import {checkForApproval, mintBadge} from "../../../utils/ContractUtils";

function ClaimingEpisodeModal(props) {

  const {currentWallet} = JSON.parse(sessionStorage.getItem("pg.wallet")) != null ? JSON.parse(sessionStorage.getItem("pg.wallet")) : {};

  function mintToken(){
      checkForApproval(props.pgerContract, currentWallet, converterAddress).then(res => {
        mintEpisodeBadge();
      })
  }

  function mintEpisodeBadge(){
    switch (props.currentEpisode){
      case CLAIMING_EPISODE.EPISODE_1:
        const {r: r1,s: s1,v: v1} = props.accountData.ep1_convert_signature;
        mintBadge(props.converterContract,episodeIds.EPISODE_1, episodeIds.EPISODE_1_REDEEMED, episodeIds.EPISODE_1_BADGE, v1, r1, s1).then(res => {
          props.buttonClick(BUTTON_ACTION.CONVERTING_EPISODE_YES);
        }).catch(err => {
          //error handling
        })
        break;
      case CLAIMING_EPISODE.EPISODE_2:
        const {r: r2,s: s2,v: v2} = props.accountData.ep2_convert_signature;
        mintBadge(props.converterContract,episodeIds.EPISODE_2, episodeIds.EPISODE_2_REDEEMED, episodeIds.EPISODE_2_BADGE, v2, r2, s2).then(res => {
          props.buttonClick(BUTTON_ACTION.CONVERTING_EPISODE_YES);
        }).catch(err => {
          //error handling
        })
        break;
      case CLAIMING_EPISODE.EPISODE_3:
        const {r: r3,s: s3,v: v3} = props.accountData.ep3_convert_signature;
        mintBadge(props.converterContract,episodeIds.EPISODE_3, episodeIds.EPISODE_3_REDEEMED, episodeIds.EPISODE_3_BADGE, v3, r3, s3).then(res => {
          props.buttonClick(BUTTON_ACTION.CONVERTING_EPISODE_YES);
        }).catch(err => {
          //error handling
        })
        break;
      case CLAIMING_EPISODE.EPISODE_4:
        const {r: r4,s: s4,v: v4} = props.accountData.ep4_convert_signature;
        mintBadge(props.converterContract,episodeIds.EPISODE_4, episodeIds.EPISODE_4_REDEEMED, episodeIds.EPISODE_4_BADGE, v4, r4, s4).then(res => {
          props.buttonClick(BUTTON_ACTION.CONVERTING_EPISODE_YES);
        }).catch(err => {
          //error handling
        })
        break;
      default:
        console.error('Unknown episode ' + props.currentEpisode + ', cannot mint badge.');
    }
  }

  function claimEpisodeDataObject() {
    switch (props.currentEpisode){
      case CLAIMING_EPISODE.EPISODE_1:
      default:
        return claimEpisode1;
      case CLAIMING_EPISODE.EPISODE_2:
        return claimEpisode2;
      case CLAIMING_EPISODE.EPISODE_3:
        return claimEpisode3;
      case CLAIMING_EPISODE.EPISODE_4:
        return claimEpisode4;
    }
  }

  return (<>
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName='nft-boost-dialog-content'>

      <img alt="" className={"cem-header-horn"} src={claim_episode_header_horn}/>

      <div className='claiming-episode-main-modal'>
        <img alt="" className='claiming-episode-close-button'
             onClick={props.onHide}
             src={closeIcon}/>
        <div className='claiming-episode-modal-header'>
          <p className='claiming-episode-modal-header-text'>
            {claimEpisodeDataObject().Note}
          </p>
          <p
            className='claiming-episode-modal-header-text claiming-episode-modal-header-text-subtext'>{claimEpisodeDataObject().processDescription}</p>
        </div>
        <div className='claiming-episode-modal-body'>
          <div className={"cem-banner"}>
            <BackgroundBanner/>
          </div>
          <PolygonBackgrounds img={props.episodeImg} text={props.episode}
                              backgroundColor={colors.cherry}/>
          <PlusIconElement icon={plusIcon}/>
          <Badge img={props.badgeImg} text={props.badge}/>
          <IconElement firstIcon={flame} secondIcom={right_arrows}/>
          <PolygonBackgrounds img={props.redeemedImg} text={props.redeemed}
                              backgroundColor={colors.sandbrown} redeemed={true}/>
          <PlusIconElement icon={plusIcon}/>
          <Medal img={props.medalImg} text={props.medal}/>
        </div>
        <div className='claiming-episode-modal-footer'>
          <p className='claiming-episode-modal-header-text adjust-confirmation-text-size'>{claimEpisodeDataObject().agreement}</p>
          {props.isFirstTimeMinting ? (
            <p className='claiming-episode-modal-header-text'>{claimEpisodeDataObject().firstMintMessage}</p>
          ) : (
            <></>
          )}

          <div className='claiming-episode-modal-footer-buttons'>
            <div className='claiming-episode-modal-tilted-button'>
              <TiltedButton clickEvent={() => mintToken()} text={agreement.Yes}/>
            </div>
            <div className='claiming-episode-modal-tilted-button'>
              <TiltedButton clickEvent={() => props.buttonClick(BUTTON_ACTION.CLOSE)} text={agreement.No}/>
            </div>
          </div>
        </div>
      </div>
      <img alt="" className={"cem-bottem-horn"} src={claim_episode_bottem_horn}/>
    </Modal>
  </>)
}

export default ClaimingEpisodeModal;
