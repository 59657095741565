import React from "react";
import "./BorderImg.scss";
import {kyc_header_boarder, ticketLineSeparator} from "../../../../assets/images";
import {ticketA, ticketB} from "../../../../assets/images/PlanetSalePagePrivate";
import BuyOnOpenSea from "../../../../components/NFTComponents/BuyOnOpenSea/BuyOnOpenSea";
import {TICKET_TYPE} from "../../../../utils/Globals";

export default function BorderImg({ticketType = TICKET_TYPE.TICKET_TYPE_A,
                                    text,
                                    owned = 0,
                                    showTicket = false,
                                  link = ""}){

  function TicketComponent({owned = 0}){

    return(<>
    <div className='ticket-container'>
      <div className='ticket-container-inner-container'>
        <img className='ticket-img' src={ticketType === TICKET_TYPE.TICKET_TYPE_A ? ticketA : ticketB} alt=""/>

        <div className='ticket-owned-container'>
          <div className='ticket-owned-inner-container'>
            <p className='ticket-owned-text'>x{owned} Owned</p>
          </div>
          <img className='ticket-separator-picture' src={ticketLineSeparator} alt=""/>
        </div>
        <div className='ticket-opensea-button'>
          <BuyOnOpenSea link={link}/>
        </div>
      </div>
    </div>
    </>)
  }

  return<>
    <div className={"pgs-circle-container"}>
      <div style={{visibility: showTicket ? 'visible' : 'hidden'}}>
        <TicketComponent owned={owned}/>
      </div>
      <img alt={""} className='psg-border-img'
           src={kyc_header_boarder}/>
      <p  className='psg-vorder-titel'>{text}</p>
    </div>
  </>
}
