import React from "react";

export default class DownloadCodeContent extends React.Component {
  render() {
    return (
      this.props.activeTab === this.props.id ? <div className={"download-code-panel"}>
        {this.props.children}
      </div>
        : null
    )
  }
}
