import './TopMenuNavigation.scss'
import TopMenuDropdown from "../TopMenuDropdown/TopMenuDropdown";
import TopMenuButton from "../TopMenuButton/TopMenuButton";
import {astraferDropdown, eventsDropdown, portfolioDropdown, socialIconDropdown} from "../../../utils/GeneralUtils";
import React from "react";
import {AuthenticationManager,} from "../../../utils/auth";
import {avatar_default} from "../../../assets";

export default function TopMenuNavigation() {
  const authManager = AuthenticationManager.instance;
  return(
    <>
      <div className={'top-menu-navigation-container'}>
        <TopMenuButton text={'PLAY NOW'} link={'/download'}/>
        <TopMenuDropdown text={'PORTFOLIO'} dropdownList={portfolioDropdown}/>
        <TopMenuDropdown text={'EVENTS'} dropdownList={eventsDropdown}/>
        <TopMenuDropdown text={'COMMUNITY'} dropdownList={socialIconDropdown}/>
        <TopMenuDropdown text={'ASTRAFER'} dropdownList={astraferDropdown}/>
        <TopMenuButton text={authManager.isAuthenticated() ? 'ACCOUNT' : 'LOG IN'} link={'/account'} icon={authManager.isAuthenticated() && avatar_default} iconAlt='account details'/>
      </div>
    </>
  );
}
