import React, {useCallback, useContext, useEffect, useState} from "react";
import "./CurencyModal.scss";
import {Modal} from "react-bootstrap";
import {PlanetSaleContext} from "../../Context/PlanetSalePageContext";
import {
  currency_bottom_horn,
  currency_header_horn,
  USDC_icon,
  USDT_icon
} from "../../../assets/images/PlanetSalePagePrivate";
import {CurrencyModalText} from "../../../utils/GeneralUtils";
import PayWithCurrency from "./components/USDC/PayWithCurrency";
import ModalCloseButton from "../ModalCloseButton/ModalCloseButton";
import ModalButton from "../ModalButton/ModalButton";
import { getBlockchainContract } from "../../../utils/providerObject";
import ERC20Abi from "../../../assets/abi/ERC20.json";
import { ethers } from "ethers";
import { walletContext } from "../../../utils/WalletContext";
import { useAccount } from 'wagmi'

export default function CurrencyModal(props) {

  const planetSaleContext = useContext(PlanetSaleContext);
  const [userBalanceUSDTProp, setUserBalanceUSDTProp] = useState(null)
  const [userBalanceUSDT, setUserBalanceUSDT] = useState(null)
  const [userBalanceUSDCProp, setUserBalanceUSDCProp] = useState(null)
  const [userBalanceUSDC, setUserBalanceUSDC] = useState(null)

  const { connector } = useAccount();
  const [provider, setProvider] = useState(undefined);

  useEffect( () => {
    const getProvider = async () => {
      const thisProvider = await connector?.getProvider();

      setProvider(thisProvider);
    }
    getProvider();
  });

  const getUSDTBalance = useCallback(async () => {
    let erc20ContractUSDT = getBlockchainContract(process.env.REACT_APP_PLANET_SALE_USDT_ADDRESS, ERC20Abi, provider);
    let walletAddress = await walletContext.currentWallet
    let balanceUSDT = await erc20ContractUSDT.balanceOf(walletAddress);
    setUserBalanceUSDTProp(parseInt(ethers.utils.formatUnits(balanceUSDT, "mwei")))
    setUserBalanceUSDT(parseInt(ethers.utils.formatUnits(balanceUSDT, "mwei")).toLocaleString(undefined, { maximumFractionDigits: 2 }))
  }, [provider])

  const getUSDCBalance = useCallback(async () => {
    let erc20ContractUSDC = getBlockchainContract(process.env.REACT_APP_PLANET_SALE_USDC_ADDRESS, ERC20Abi, provider);
    let walletAddress = await walletContext.currentWallet
    let balanceUSDC = await erc20ContractUSDC.balanceOf(walletAddress);
    setUserBalanceUSDCProp(parseInt(ethers.utils.formatUnits(balanceUSDC, "mwei")))
    setUserBalanceUSDC(parseInt(ethers.utils.formatUnits(balanceUSDC, "mwei")).toLocaleString(undefined, { maximumFractionDigits: 2 }))

  }, [provider])
  
  let totalSume = props.numberOfNFTToMin * Number(props.NFTCost);
  totalSume = (Math.round(totalSume * 100) / 100).toFixed(3);


  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  useEffect(() => {
    if(provider) {
      getUSDTBalance()
      getUSDCBalance()
    }
  }, [getUSDTBalance,getUSDCBalance,userBalanceUSDT,userBalanceUSDC,userBalanceUSDTProp,userBalanceUSDCProp,provider])
  
  return <>
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName='currency-modal-container'>
      <ModalCloseButton clickEvent={() => planetSaleContext.setMintModalShowValue(false)}/>
      <img className={"currency-header-horns"} src={currency_header_horn} alt=""/>
      <div className={"currency-principle-container"}>
        <div className={"currency-text-description"}>
          {CurrencyModalText.description} {props.numberOfNFTToMin}x {props.planetToMint} for<br/>{formatter.format(totalSume)}.<br/>
          {CurrencyModalText.paymentDetails}
        </div>
      </div>
      <div className={"currency-buttons"}>
        <div className={"currency-usdc"}>
          <div className="currency-usdc-balance">Your balance: </div>
          <p className="currency-usdc-balance">${userBalanceUSDC} USDC</p>
          <PayWithCurrency currencyImg={USDC_icon} ticketId={props.ticketId} v={props.v} r={props.r} s={props.s}
                           currencyText={"USDC"} nftCost={props.NFTCost} nftId={props.nftId}
                           numberOfNFT={props.numberOfNFTToMin} currency={"USDC"} sellType={props.sellType} index={props.index} userBalance={userBalanceUSDCProp}/>
        </div>
        <div className={"currency-usdt"}>
          <div className="currency-usdt-balance">Your balance :</div>
          <p className="currency-usdt-balance">${userBalanceUSDT} USDT</p>
          <PayWithCurrency currencyImg={USDT_icon} ticketId={props.ticketId} v={props.v} r={props.r} s={props.s}
                           currencyText={"USDT"} nftCost={props.NFTCost} nftId={props.nftId}
                           numberOfNFT={props.numberOfNFTToMin} currency={"USDT"} sellType={props.sellType} index={props.index} userBalance={userBalanceUSDTProp}/>
        </div>
      </div>

      <div className={"currency-cancel"}>
        <ModalButton buttonText={'CANCEL'} onClick={() => planetSaleContext.setMintModalShowValue(false)}/>
      </div>
      <img className={"currency-bottem-horns"} src={currency_bottom_horn} alt=""/>
    </Modal>
  </>
}
