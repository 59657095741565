import React from "react";
import "./AstrafiteTokenImage.scss";

export default function AstrafiteTokenImage(props) {
  const { token } = props;

  const imageUrl = `${process.env.REACT_APP_AFR_TOKEN_IMAGE_BASEURL}/${token}.png`;

  return (
    <div className="token-image-container">
      <img
        src={imageUrl}
        alt="A token with range of Event information, including the player’s Astrafite Mined, Fire Dancers Defeated, and Leaderboard Rank in each Cycle."
      ></img>
    </div>
  );
}
