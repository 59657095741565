import React from 'react';

//Web3 patch notes (aka Blockchain notes included)
export default function PatchNotesWeb3() {
  return (
    <>
      <h1>Phantom Galaxies Version 5.7.4</h1>
      <p>Released 30/10/2024</p>
      <h2>General Gameplay</h2>
      <ul>
        <li>New Patrol: We’ve added a new patrol to the roster; Crystal Caverns (affectionately dubbed internally as ‘Meatballs’). Take to the skies Ranger!</li>
        <li>New Patrol Modifier - Shield Overload! A new modifier called ‘Shield Overload’ has been added which can be dynamically enabled on any patrol. This modifier will amp your shield’s durability but prevents it from recharging. Be sure to check all active Patrol modifiers and gear up accordingly before heading out. Can you survive the Xanorra without relying on regen?</li>
      </ul>
      <h2>Player Experience and Balancing</h2>
      <ul>
        <li>Increased Patrol rewards: we’ve significantly bumped up the amount of XP awarded from patrols. Grind those levels up faster and why not pop a consumable while you’re at it.</li>
      </ul>
      <h2>Fixes & Improvements</h2>
      <ul>
        <li>Fixed login issues. Long story short, there was a certificate update in one of the external server vendors used which caused the failure to occur. Running the commands provided in the support channel forced your computer to get the latest certificate, solving the issue. This is all fixed now to occur when running the game.</li>
        <li>No more party sparklers! Fixed an issue where ship damage particle effects from Zappy Boi (the laser frigate in patrols) would persist.</li>
        <li>Audio updates to Halberd-001. Added some life and ambience into the station.</li>
        <li>Fixed an issue with all patrol modifiers that would not remain on respawn.</li>
        <li>Improved performance of loot drops.</li>
      </ul>
      <h2>Known Issues</h2>
      <ul>
        <li>Talking to Arman to select his wingman mission is not a good idea and will cause…unintended consequences. If you want Arman, stand as far back behind him as you can while still being able to interact with him and you shouldn’t encounter any issues (but we all know Charleanne is the best anyway).</li>
      </ul>
    </>
  );
}
