import { useState } from "react";
import "./AnnouncementPopup.scss";
import {Modal} from "react-bootstrap";
import {close_button, baby_in_test_tube} from "../../assets/images/AnnouncementPopup";
import {genesisNFTAvailable} from "../../utils/GeneralUtils";
import {useNavigate} from "react-router-dom";

export default function AnnouncementPopup() {
  const navigate = useNavigate()
  const [popupVisibility, setPopupVisibility] = useState(true);

  function triggerPopup() {
    setPopupVisibility(!popupVisibility);
  }

  const mintAvatars = () => {
    navigate('/portfolio/pilots');
  };

  const mintStarfighters = () => {
    navigate('/portfolio/starfighters');
  };

  return (
    <>
      <Modal show={popupVisibility} aria-labelledby="contained-modal-title-vcenter" centered dialogClassName={'genesis-nft-available-modal'}>
        <div className={'genesis-nft-available-modal-content'}>
          <div className={'for-nft-main-content'}>
            <img src={close_button} alt={''} onClick={() => triggerPopup()} className={'close-button-element-alignment'} />
            <div className={'adjust-z-index-from-alignment'}>
              <div className={'announcement-popup-title'}>Get your own Pilot and Starfighter now!</div>
              <div className={'for-nft-content-container'}>
                <div className={'main-text-container-posters'}>{genesisNFTAvailable.paragraph1}</div>
              </div>
              <div className={'mint-button-container'}>
                <div className={'mint-button-holder'}>
                  <div className={'mint-now-poster-button'} onClick={() => mintAvatars()}>
                    BUY PILOTS
                  </div>
                </div>
                <div className={'mint-button-holder'}>
                  <div className={'mint-now-poster-button starfighters'} onClick={() => mintStarfighters()}>
                    BUY STARFIGHTERS
                  </div>
                </div>
              </div>
            </div>
            <div className={'featured-image-container'}>
              <div className={'featured-image-overlay'}>
                <img src={baby_in_test_tube} alt={'Wires connected to baby with genetic pattern in test tube'} className={'featured-image'} />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
