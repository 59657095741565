import axios from 'axios';
import { walletContext } from './WalletContext';
import { CHAINS } from './ChainHelper';
import { catalystPGERC1155Address, MissionStatus, PGERC1155Address_Avatar_Starfighter, PGERC1155Address_PlanetSale } from './Globals';
import { MISSION_STATUS } from '../components/PlanetExploration/PlanetExplorationContent/PlanetExplorationContent';
import { AuthenticationManager } from './auth';

export const getTheTime = async () => {
  return await axios.get(`${process.env.REACT_APP_BASEURL_OLD}/api/v1/saledates`);
};
export const presaleAVRS = async () => {
  return await axios.post(`${process.env.REACT_APP_BASEURL_OLD}/api/v1/presalea`, {
    walletAddress: walletContext.currentWallet,
    signatureVerify: walletContext.signature,
  });
};
export const presaleBVRS = async () => {
  return await axios.post(`${process.env.REACT_APP_BASEURL_OLD}/api/v1/presaleb`, {
    walletAddress: walletContext.currentWallet,
    signatureVerify: walletContext.signature,
  });
};
export const publicSaleVRS = async () => {
  return await axios.post(`${process.env.REACT_APP_BASEURL_OLD}/api/v1/publicsale`, {
    walletAddress: walletContext.currentWallet,
    signatureVerify: walletContext.signature,
  });
};
export const privateSaleVRS = async () => {
  return await axios.post(`${process.env.REACT_APP_BASEURL_OLD}/api/v1/privatesale`, {
    walletAddress: walletContext.currentWallet,
    signatureVerify: walletContext.signature,
  });
};

export const getPair = async () => {
  return await axios.post(`${process.env.REACT_APP_BASEURL_OLD}/api/getaspost/v1/wallets/pair`, {
    walletAddress: walletContext.currentWallet,
    signatureVerify: walletContext.signature,
  });
};
export const getWallets = async () => {
  return axios.post(`${process.env.REACT_APP_BASEURL_OLD}/api/getaspost/v1/wallets`, {
    walletAddress: walletContext.currentWallet,
    signatureVerify: walletContext.signature,
  });
};

export async function submitLinkRequest(walletAddress) {
  return await axios.post(`${process.env.REACT_APP_BASEURL_OLD}/api/v1/wallets/pair`, {
    walletAddress: walletContext.currentWallet,
    signatureVerify: walletContext.signature,
    playerWalletAddress: walletAddress,
  });
}

export async function processRequest(walletAddress, isApproved) {
  return await axios.post(`${process.env.REACT_APP_BASEURL_OLD}/api/v1/wallets/pair/request`, {
    walletAddress: walletContext.currentWallet,
    signatureVerify: walletContext.signature,
    planetHolderWalletAddress: walletAddress,
    isApproved: isApproved,
  });
}

export async function hasAcceptedTAndCs(walletAddress, signatureVerify) {
  return await axios.post(`${process.env.REACT_APP_BASEURL_OLD}/api/getaspost/v1/nft/license`, {
    walletAddress: walletAddress,
    signatureVerify: signatureVerify,
  });
}

export async function createFavourite(assetId, assetType) {
  const config = AuthenticationManager.instance.authHeader();
  const body = { assetId, assetType };

  let url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_ACCOUNT}/v1/favourites`;

  const res = await axios.post(url, body, config);
  return res.data;
}

export async function deleteFavourite(assetId) {
  const config = AuthenticationManager.instance.authHeader();
  config.params = { assetId };
  let url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_ACCOUNT}/v1/favourites`;

  const res = await axios.delete(url, config);
  return res.data;
}

export async function getMyPlanetDetails() {
  const config = AuthenticationManager.instance.authHeader();

  let url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_PLANET}/v1/planets/me`;

  const res = await axios.get(url, config);
  return res.data;
}

export async function getAccountSettings() {
  const config = AuthenticationManager.instance.authHeader();

  const url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_ACCOUNT}/v2/accounts/settings`;
  const res = await axios.get(url, config);
  return res.data;
}

export async function setAfrTncAccountSettings(signature) {
  const config = AuthenticationManager.instance.authHeader();
  const body = {
    signature: signature,
  };

  const url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_ACCOUNT}/v2/accounts/settings`;
  const res = await axios.post(url, body, config);
  return res.data;
}

export async function getMyStarfighters() {
  const config = AuthenticationManager.instance.authHeader();
  const url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_ACCOUNT}/v2/accounts/starfighters/me?includeGameLocks=true&includeFavourite=true`;
  const res = await axios.get(url, config);
  return res.data;
}

export async function getMyAvatars() {
  const config = AuthenticationManager.instance.authHeader();
  const url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_ACCOUNT}/v2/accounts/avatars/me?includeGameLocks=true&includeFavourite=true`;
  const res = await axios.get(url, config);

  return res.data;
}

export async function getMyVaultBalance() {
  const config = AuthenticationManager.instance.authHeader();
  const url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_ACCOUNT}/v2/accounts/balances/me`;
  const res = await axios.get(url, config);
  return res.data;
}

export async function withdrawFromVault(astraferAmount) {
  const config = AuthenticationManager.instance.authHeader();
  const url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_ACCOUNT}/v2/accounts/transactions/vaultWithdrawal`;
  const res = await axios.post(url, { amount: astraferAmount }, config);

  return res.data;
}

export async function getPlanetDetails(missionId) {
  const config = AuthenticationManager.instance.authHeader();
  config.params = { missionId: missionId };

  let url = `${process.env.REACT_APP_BASEURL}/ceres-quadrant/v1/planets`;

  const res = await axios.get(url, config);
  return res.data;
}

export async function acceptTAndCs(walletAddress, signatureVerify, signedMessaged) {
  return await axios.post(`${process.env.REACT_APP_BASEURL_OLD}/api/v1/nft/license`, {
    walletAddress: walletAddress,
    signatureVerify: signatureVerify,
    nftLicenseSignatureVerify: signedMessaged,
  });
}

// TODO: Use the fetched catalyst trait data in GenerativePanetMintingContent to generate the grid
export async function getPlanetCatalysts() {
  const res = await axios.get(`${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_PLANET}/v1/catalysts`);
  return res.data;
}

export async function getStarfighterCatalysts() {
  const res = await axios.get(`${process.env.REACT_APP_BASEURL}/account-svc/v1/catalysts/starfighters`);
  return res.data;
}

export async function getAvatarCatalysts() {
  const res = await axios.get(`${process.env.REACT_APP_BASEURL}/account-svc/v1/catalysts/avatars`);
  return res.data;
}

export const NFT_COLLECTIONS = {
  planetMintpasses: 'planetMintpasses',
  planetCatalysts: 'planetCatalysts',
  originHopeful: 'originHopeful',
  accoladesZorransFavour: 'accoladesZorransFavour',
};

export async function getTokenBalances(walletAddress, tokens = []) {
  // NOTE: tokens specified are to be in the format below:
  // [
  //   {
  //     chainId: 43113,
  //     tokenType: 2,
  //     contractAddress: "0xd1ea5769484a799087c28211aaeeeb5c67d89e11",
  //     tokenIds: [
  //       101, 102, 103, 201, 202, 203, 301, 302, 303, 401, 402, 501, 502,
  //     ],
  //   }
  // ];

  let url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_BLOCKCHAIN}/v1/wallets/${walletAddress}`;

  return await axios.get(url, { params: { query: JSON.stringify(tokens) } });
}

export async function getPlanetMintpassBalances(walletAddress) {
  let tokens = {
    chainId: CHAINS[process.env.REACT_APP_CHAINID].chainId,
    tokenType: 2,
    contractAddress: PGERC1155Address_PlanetSale,
    tokenIds: [100, 101, 102, 103, 104, 200, 201, 202, 203, 204],
  };

  const res = await getTokenBalances(walletAddress, [tokens]);
  return res.data[0];
}

export async function getStarfighterMintpassBalances(walletAddress) {
  let tokens = {
    chainId: CHAINS[process.env.REACT_APP_CHAINID].chainId,
    tokenType: 2,
    contractAddress: PGERC1155Address_Avatar_Starfighter,
    tokenIds: [1, 2, 11, 12, 21, 22, 100],
  };

  const res = await getTokenBalances(walletAddress, [tokens]);

  return res.data[0];
}

export async function getStarfighterCatalystBalances(walletAddress) {
  let tokens = {
    chainId: CHAINS[process.env.REACT_APP_CHAINID].chainId,
    tokenType: 2,
    contractAddress: catalystPGERC1155Address,
    tokenIds: [1001, 1002, 1003, 1004],
  };

  let res = await getTokenBalances(walletAddress, [tokens]);
  return res.data[0];
}

export async function getPlanetCatalystBalances(walletAddress) {
  let tokens = {
    chainId: CHAINS[process.env.REACT_APP_CHAINID].chainId,
    tokenType: 2,
    contractAddress: catalystPGERC1155Address,
    tokenIds: [101, 102, 103, 201, 202, 203, 301, 302, 303, 401, 402, 501, 502],
  };

  const res = await getTokenBalances(walletAddress, [tokens]);
  return res.data[0];
}

export async function getAvatarMintpassBalances(walletAddress) {
  let tokens = {
    chainId: CHAINS[process.env.REACT_APP_CHAINID].chainId,
    tokenType: 2,
    contractAddress: PGERC1155Address_Avatar_Starfighter,
    tokenIds: [3, 13, 4, 23, 200],
  };

  const res = await getTokenBalances(walletAddress, [tokens]);
  return res.data[0];
}

export async function getAvatarCatalystBalances(walletAddress) {
  let tokens = {
    chainId: CHAINS[process.env.REACT_APP_CHAINID].chainId,
    tokenType: 2,
    contractAddress: catalystPGERC1155Address,
    tokenIds: [2001, 2002, 2101, 2102, 2103, 2104],
  };

  const res = await getTokenBalances(walletAddress, [tokens]);
  return res.data[0];
}

export async function getPlanetCatalystSignature(walletAddress, mintpassId, catalystIds) {
  let url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_PLANET}/v1/catalysts/signature?`;

  let queryParams = [`toAddress=${walletAddress}`, `tokenId=${mintpassId}`, `catalystTokenIds=${catalystIds.toString()}`];

  url += queryParams.join('&');

  const res = await axios.get(url);
  return {
    signature: res.data.signatures[0],
    structArray: res.data.structArrays[0],
  };
}

export async function getStarfighterCatalystSignature(walletAddress, tokenId, catalystTokenIds) {
  let url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_ACCOUNT}/v1/catalysts/starfighters/signature?`;

  let queryParams = [`toAddress=${walletAddress}`, `tokenId=${tokenId}`, `catalystTokenIds=${catalystTokenIds.toString()}`];

  url += queryParams.join('&');

  const res = await axios.get(url);
  return {
    signature: res.data.signatures[0],
    structArray: res.data.structArrays[0],
  };
}

export async function getAvatarCatalystSignature(walletAddress, mintpassId, catalystIds) {
  let url = `${process.env.REACT_APP_BASEURL}/account-svc/v1/catalysts/avatars/signature?`;
  let queryParams = [`toAddress=${walletAddress}`, `tokenId=${mintpassId}`, `catalystTokenIds=${catalystIds.toString()}`];

  url += queryParams.join('&');

  const res = await axios.get(url);
  return {
    signature: res.data.signatures[0],
    structArray: res.data.structArrays[0],
  };
}

export async function getActiveMissions() {
  const url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_CQ}/v1/missions`;

  const params = {
    status: MissionStatus.ACTIVE,
  };

  const res = await axios.get(url, { params });
  return res.data;
}

export async function getMission(missionId) {
  const response = await axios.get(`${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_CQ}/v1/missions/${missionId}`);

  return response.data;
}

export async function updateCheckpoint(missionId, missionEntryId, checkpointId, checkinCompleted) {
  const config = AuthenticationManager.instance.authHeader();

  let url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_CQ}/v1/missions/${missionId}/missionEntries/${missionEntryId}/checkpoints/${checkpointId}`;

  const res = await axios.patch(url, { checkinCompleted }, config);
  return res.data;
}

export async function updateMissionStatusByEntryId(entryId, missionId, status) {
  const config = AuthenticationManager.instance.authHeader();
  const query = `?action=abort`;
  const url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_CQ}/v1/missions/${missionId}/missionEntries/${entryId}${query}`;
  const response = await axios.put(url, {}, config);
  return response.status;
}

export async function getMissions() {
  const url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_CQ}/v1/missions`;
  const params = {
    status: MISSION_STATUS.ACTIVE,
  };

  const res = await axios.get(url, { params });
  return res.data;
}

export async function getMissionEntry(missionId, entryId) {
  const config = AuthenticationManager.instance.authHeader();
  const url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_CQ}/v1/missions/${missionId}/missionEntries/${entryId}`;
  const response = await axios.get(url, config);
  return response.data;
}

export async function getMissionEntries(missionId) {
  const config = AuthenticationManager.instance.authHeader();
  const query = `?status=${MISSION_STATUS.ACTIVE},${MISSION_STATUS.COMPLETED}`;
  const url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_CQ}/v1/missions/${missionId}/missionEntries${query}`;
  const response = await axios.get(url, config);
  return response.data;
}

export async function sendMissionEntryStart(delegatedWallet, enteredPlanets, missionId) {
  const config = AuthenticationManager.instance.authHeader();
  const body = generateMissionBody(enteredPlanets, delegatedWallet);

  const url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_CQ}/v1/missions/${missionId}/missionEntries`;

  const res = await axios.post(url, body, config);
  return res.data;
}

export async function sendMissionEntryClaimRewards(entryId, missionId) {
  const config = AuthenticationManager.instance.authHeader();
  const query = `?action=claim`;
  const url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_CQ}/v1/missions/${missionId}/missionEntries/${entryId}${query}`;
  const response = await axios.put(url, {}, config);
  return response.data;
}

export async function sendMissionEntryStartTxHash(delegatedWallet, enteredPlanets, missionId, blockchainTransactionHash) {
  const config = AuthenticationManager.instance.authHeader();
  const body = generateMissionBody(enteredPlanets, delegatedWallet, blockchainTransactionHash);

  const url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_CQ}/v1/missions/${missionId}/missionEntries`;

  const res = await axios.post(url, body, config);
  return res.data;
}

export function generateMissionBody(enteredPlanets, delegatedWallet, blockchainTransactionHash) {
  if (blockchainTransactionHash) {
    return delegatedWallet
      ? { missionParameters: { delegatedWallet, enteredPlanets }, blockchainTransactionHash }
      : { missionParameters: { enteredPlanets }, blockchainTransactionHash };
  }

  return delegatedWallet ? { missionParameters: { delegatedWallet, enteredPlanets } } : { missionParameters: { enteredPlanets } };
}

export async function getVaultSignatures(request) {
  const config = AuthenticationManager.instance.authHeader();
  const body = {
    structs: request,
  };

  const url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_BLOCKCHAIN}/v1/signatures/vaultSignatureBatch`;

  const res = await axios.post(url, body, config);
  return res.data;
}

// Get planet by guid
export async function getPlanetByGuid(guid) {
  const url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_PLANET}/v1/planets/${guid}`;
  const res = await axios.get(url);
  return res.data;
}

// Get planet by chainId, contract address and tokenId
export async function getPlanetByChainIdContractAddressAndTokenId(chainId, contractAddress, tokenId) {
  const url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_PLANET}/v1/planets/${chainId}/${contractAddress}/${tokenId}`;
  const res = await axios.get(url);
  return res.data;
}

export async function getThirdClassMintPassDetails(walletAddress) {
  const url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_BLOCKCHAIN}/v1/signatures/avatarStarfighterMintpass?walletAddress=${walletAddress}`;
  const res = await axios.post(url);

  return res.data;
}

export async function getPlanetRewardStatusByChainIdContractAddressAndTokenId(chainId, contractAddress, tokenId) {
  const params = {
    chainId,
    contractAddress,
    tokenId,
  };
  const url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_CQ}/v1/planets/rewards`;
  const res = await axios.get(url, { params });
  return res.data;
}

export async function getAfrKycCheck() {
  const config = AuthenticationManager.instance.authHeader();
  const url = `${process.env.REACT_APP_BASEURL}/${process.env.REACT_APP_API_PREFIX_BLOCKCHAIN}/v1/astrafiteRush/kycCheck`;
  const res = await axios.get(url, config);
  return res.data;
}
