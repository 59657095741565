import React from "react";
import "./ConvertingPosterModal.js.scss";
import {Modal} from "react-bootstrap";
import PolygonBackgrounds from "../PolygonBackgrounds/PolygonBackground/PlygonBackgrounds";
import BackgroundBanner from "../PolygonBackgrounds/BackgroundBanner/BackgroundBanner";
import {bottem_horns, flame, header_horns, right_arrows} from "../../../assets/images/PopUps"
import TiltedButton from "../../TiltedButton/TiltedButton";
import {agreement, colors, convertingPoster1forPoster2} from "../../../utils/GeneralUtils";
import {closeIcon} from "../../../assets/images/icons";
import IconElement from "../PolygonBackgrounds/IconElements/IconElement";
import {checkForApproval, convertToken} from "../../../utils/ContractUtils";
import {BUTTON_ACTION, converterAddress, episodeIds} from "../../../utils/Globals";

export default function ConvertingPosterModal(props) {

  const {currentWallet} = JSON.parse(sessionStorage.getItem("pg.wallet")) != null ? JSON.parse(sessionStorage.getItem("pg.wallet")) : {};

  function setStringValue() {
    return <>{"x" + props.multipleValue + " "}{props.epsiode1Text}</>;
  }

  function mintToken(){
      checkForApproval(props.pgerContract, currentWallet, converterAddress).then(res => {
        convertToken(props.contract,episodeIds.EPISODE_1,props.episodeId, 1).then(res => {
          props.buttonClick(BUTTON_ACTION.CONVERTING_YES);
        })
      })
  }

  return <Modal
    {...props}
    show={true}
    aria-labelledby="contained-modal-title-vcenter"
    centered
    dialogClassName='converting-poster-modal-parent'>
    <img alt="" className={"converting-poster-modal-header-horns"} src={header_horns}/>
    <div className={"converting-poster-modal-body"}>
      <div className={"converting-poster-header-container"}>
        <img alt="" className={"converting-poster-modal-close-button"} onClick={() => props.buttonClick(BUTTON_ACTION.CLOSE)} src={closeIcon}/>
      </div>
      <div className={"converting-poster-modal-content"}>
        {convertingPoster1forPoster2.Note}
        <p>{convertingPoster1forPoster2.processDescription}</p>
      </div>
      <div className={"converting-poster-modal-banner-elements-container"}>
        <div className={"converting-poster-modal-banner-control"}>
          <BackgroundBanner/>
        </div>
        <div>
          <PolygonBackgrounds img={props.episode1Img} text={setStringValue()} multipleValue={props.multipleValue}
                              backgroundColor={colors.cherry}/>
        </div>
        <IconElement firstIcon={flame} secondIcom={right_arrows}/>
        <div>
          <PolygonBackgrounds img={props.epsiode2Img} text={props.episode2Text}
                              backgroundColor={colors.cherry}/>
        </div>
      </div>
      <div className={"converting-poster-modal-agreement-container"}>
        <p className={"converting-poster-modal-agreement-text adjust-confirmation-text-size"}>
          {convertingPoster1forPoster2.agreementText}
        </p>
        {props.isFirstTimeMinting ? (
            <p className='converting-poster-modal-agreement-text'>{convertingPoster1forPoster2.firstMintMessage}</p>
          ) : (
            <></>
          )}
        <div className={"converting-poster-modal-buttons-container"}>
          <div className={"converting-poster-modal-buttons-size"}>
            <TiltedButton clickEvent={() => mintToken()} text={agreement.Yes}/>
          </div>
          <div className={"converting-poster-modal-buttons-size"}>
            <TiltedButton clickEvent={() => props.buttonClick(BUTTON_ACTION.CLOSE)} text={agreement.No}/>
          </div>
        </div>
      </div>
      <img alt="" className={"converting-poster-modal-bottem-horns"} src={bottem_horns}/>
    </div>

  </Modal>
}
