import './GenesisNFTs.scss';
import HeaderComponent from '../components/Header/HeaderComponent';
import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer/Footer';
import FighterLady from '../components/Footer/FooterElements/FighterLady/FighterLady';
import PageHeader from '../components/PageHeader';
import { StarfighterNFTsList } from '../utils/GeneralUtils';
import { GenesisNFTsTabsList } from '../components/GenesisNFTs/GenesisNFTsTabsList/GenesisNFTsTabsList';
import { GenesisNFTsCombination } from '../components/GenesisNFTs/GenesisNFTsCombination/GenesisNFTsCombination';
import { Col, Container, Image, Row, Tab } from 'react-bootstrap';
import GenesisNFTsDescriptionsList from '../components/GenesisNFTs/GenesisNFTsDescriptionsList/GenesisNFTsDescriptionsList';
import { MintpassAstraferModal } from '../components/Modal/MintpassAstraferModal/MintpassAstraferModal';
import { MintpassModal } from '../components/Modal/MintpassModal/MintpassModal';
import { walletContext } from '../utils/WalletContext';
import SwitchNetworkChainSection from '../components/SwitchNetworkChain';
import LoadingSpinner from '../components/LoadingSpinner';
import CustomConnectWalletButton from '../components/CustomeConnectWalletButton/CustomeConnectWalletButton';
import PGERC1155Abi from '../assets/abi/PGERC1155.json';
import GenesisMintPassPGERC1155Abi from '../assets/abi/GenesisMintPassPGERC1155.json';
import ERC20Abi from '../assets/abi/ERC20.json';
import mintPassConverterAbi from '../assets/abi/MintPassConverter.json';
// import axios from 'axios';
import {
  CONTRACT_APPROVAL_STATUS,
  MINT_PASS_IDS,
  mintPassConverterAddress,
  PGERC1155Address,
  PGERC1155Address_Avatar_Starfighter,
} from '../utils/Globals';
import { getBlockchainContract } from '../utils/providerObject';
import {
  additionalAllowanceRequired,
  checkForApproval,
  convertMultipleIds,
  getNumberOfTokensMinted,
  getTokenSupply,
  increaseERC20Allowance,
} from '../utils/ContractUtils';
import ConfirmationModal from '../components/Modal/ConfirmationModal/ConfirmationModal';
import { ethers } from 'ethers';
import PayableMinterV2Abi from '../assets/abi/PayableMinterV2.json';
import PurchaseModalError from '../components/Modal/PlanetPurchaseModal/PurchaseModalError';
import { useAccount, useNetwork } from 'wagmi';
import { Starfighter1, Starfighter2, Starfighter3, Starfighter4, Starfighter5 } from '../assets/images/Starfighters';
import { getThirdClassMintPassDetails } from '../utils/ApiCalls';
import { useIsCorrectChain } from '../hooks/useIsCorrectChain';

export default function Starfighter() {
  const [activeNFT, setActiveNFT] = useState(StarfighterNFTsList[0]);
  const [showCombinations, setShowCombinations] = useState(false);
  const [showMintWithPosters, setShowMintWithPosters] = useState(false);
  const [showMintWithAstrafer, setShowMintWithAstrafer] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  // const [error, setError] = useState(false);
  const [account, setAccount] = useState();
  const [isLoadingAccount, setIsLoadingAccount] = useState();
  const [pgerContract, setPgerContract] = useState();
  const [genesisPgerContract, setGenesisPgerContract] = useState();
  const [mintPassConverterContract, setMintPassConverterContract] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [balance, setBalance] = useState(null);
  const [mintPassPrice, setMintPassPrice] = useState(null);
  const [requestError, setRequestError] = useState(null);
  const [isGenesisMintPassSoldOut, setIsGenesisMintPassSoldOut] = useState(null);

  const network = useNetwork();
  const isCorrectChain = useIsCorrectChain();
  const { connector } = useAccount();
  const [provider, setProvider] = useState(undefined);
  const generativeStarfighterImages = [
    { alt: 'Black Starfighter', image: Starfighter1 },
    { alt: 'Orange Starfighter', image: Starfighter2 },
    { alt: 'White Starfighter', image: Starfighter3 },
    { alt: 'Yellow Starfighter', image: Starfighter4 },
    { alt: 'Blue Starfighter', image: Starfighter5 },
  ];

  useEffect(() => {
    const getProvider = async () => {
      const thisProvider = await connector?.getProvider();

      setProvider(thisProvider);
    };
    getProvider();
  });

  const [astraferContract, setAstraferContract] = useState();
  const getAstraferContract = () => {
    let contract = astraferContract;

    if (!contract) {
      contract = getBlockchainContract(process.env.REACT_APP_ASTRAFER_ERC20_ADDRESS, ERC20Abi, provider);
      setAstraferContract(contract);
    }
    return contract;
  };

  const [genesisPayableMinterContract, setGenesisPayableMinterContract] = useState();
  const getGenesisPayableMinterContract = () => {
    let contract = genesisPayableMinterContract;

    if (!contract) {
      contract = getBlockchainContract(process.env.REACT_APP_GENESIS_MINT_PASS_PAYABLE_MINTER_ADDRESS, PayableMinterV2Abi, provider);
      setGenesisPayableMinterContract(contract);
    }
    return contract;
  };

  const ASTRAFER_CONFIG = {
    name: 'ASTRAFER',
    tokenAddress: process.env.REACT_APP_ASTRAFER_ERC20_ADDRESS,
    weiConversionDecimals: 18,
  };

  const GENESIS_MINT_PASS_CONFIG = {
    payableMinterAddress: process.env.REACT_APP_GENESIS_MINT_PASS_PAYABLE_MINTER_ADDRESS,
  };

  const BLOCKCHAIN_ERROR_MAP = [
    {
      blockchainMessage: 'MetaMask Tx Signature: User denied transaction signature.',
      displayMessage: `Request rejected. You must approve the blockchain request to continue`,
    },
    {
      blockchainMessage: 'execution reverted: ERC20: transfer amount exceeds balance',
      displayMessage: `You have insufficient Astrafer available to make this purchase.`,
    },
  ];

  const DEFAULT_ERROR_MESSAGE = 'Sorry, something went wrong. Please try again later.';

  // get the players NFTs
  useEffect(() => {
    const getData = async () => {
      if (provider && walletContext.signed && !account && !isLoadingAccount) {
        setIsLoadingAccount(true);
        try {
          await getAccountDetails();
          await fetchBalances();
          await fetchMintPassPrice(MINT_PASS_IDS.PRISTINE_FLEET_MINTPASS);
          await isGenesisNFTSoldOut(MINT_PASS_IDS.PRISTINE_FLEET_MINTPASS);
        } catch (err) {
          console.error(err);
          handleRequestError('Something has gone wrong, please try again later.');
        }
      }
    };
    getData();
  });

  const fetchBalances = async () => {
    const currencyContract = getAstraferContract();
    const balanceInWei = await currencyContract.balanceOf(walletContext.currentWallet);
    const balanceInCurrency = parseFloat(ethers.utils.formatUnits(balanceInWei, ASTRAFER_CONFIG.weiConversionDecimals));
    const balance = {
      value: balanceInCurrency,
      formatted: balanceInCurrency.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 2 }),
    };

    setBalance(balance);
  };

  const fetchGenesisMintPassSupply = async tokenId => {
    // only get the contract if it hasnt been set yet. This should only happen the first time
    const mintpassPgerContract = getMintPassPgerContract();
    return await getTokenSupply(mintpassPgerContract, tokenId);
  };

  const fetchGenesisMintPassMintedNumber = async tokenId => {
    const mintpassPgerContract = getMintPassPgerContract();
    return await getNumberOfTokensMinted(mintpassPgerContract, tokenId);
  };

  const getMintPassPgerContract = () => {
    let contract = genesisPgerContract;

    if (!contract) {
      contract = getBlockchainContract(PGERC1155Address_Avatar_Starfighter, GenesisMintPassPGERC1155Abi, provider);
      setGenesisPgerContract(contract);
    }
    return contract;
  };

  const isGenesisNFTSoldOut = async tokenId => {
    const supply = await fetchGenesisMintPassSupply(tokenId);
    const minted = await fetchGenesisMintPassMintedNumber(tokenId);
    const isSoldOut = supply - minted < 1;
    setIsGenesisMintPassSoldOut(isSoldOut);
    return isSoldOut;
  };

  const handleTabChange = async key => {
    //get the token id for the selected tab
    const genesisMintPass = StarfighterNFTsList.find(nft => nft.eventKey === key);
    await fetchMintPassPrice(genesisMintPass.globalMintPassId);
    await isGenesisNFTSoldOut(genesisMintPass.globalMintPassId);
  };

  const fetchMintPassPrice = async mintPassId => {
    // getCost returns the amount in wei as a big number
    const costInWei = await getGenesisPayableMinterContract().getCost(mintPassId, ASTRAFER_CONFIG.tokenAddress);

    const costInCurrency = parseFloat(ethers.utils.formatUnits(costInWei, ASTRAFER_CONFIG.weiConversionDecimals));

    const nftCost = {
      costInWei: costInWei,
      cost: costInCurrency,
      formatted: costInCurrency.toLocaleString(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 2 }),
    };

    setMintPassPrice(nftCost);
  };

  const requestAllowanceIncrease = async costInWei => {
    try {
      const result = await increaseERC20Allowance(
        getAstraferContract(),
        walletContext.currentWallet,
        costInWei,
        GENESIS_MINT_PASS_CONFIG.payableMinterAddress,
      );
      await loadAdditionalAllowanceRequired(costInWei);

      if (result !== CONTRACT_APPROVAL_STATUS.APPROVED) {
        handleRequestError('generic error');
      }
    } catch (e) {
      handleRequestError(e);
    }
  };

  const getAccountDetails = async () => {
    const walletAddress = walletContext.currentWallet;
    let pgerContract = getBlockchainContract(PGERC1155Address, PGERC1155Abi, provider);
    let converterContract = getBlockchainContract(mintPassConverterAddress, mintPassConverterAbi, provider);
    setPgerContract(pgerContract);
    setMintPassConverterContract(converterContract);

    // let response = await axios
    //   .post(`${process.env.REACT_APP_BASEURL_OLD}/api/v1/getAccountDetails`, {
    //     walletAddress: walletAddress,
    //     signatureVerify: walletContext.signature,
    //   })
    //   .catch(error => {
    //     setIsLoaded(true);
    //     setError(error);
    //   });
    const thirdClassMintPass = await getThirdClassMintPassDetails(walletAddress);
    // const accountData = { ...response.data.accountData, ...thirdClassMintPass };
    const accountData = { ...thirdClassMintPass };

    if (isCorrectChain) {
      if (accountData) {
        setAccount(accountData);
      }
    }
    setIsLoaded(true);
  };

  /**
   * Converts 4 NFTs for a mintpass
   * @param tokenAIds
   * @param tokenBId
   */
  const handleConversion = (tokenAIds, tokenBId) => {
    checkForApproval(pgerContract, walletContext.currentWallet, mintPassConverterAddress)
      .then(res => {
        convertMultipleIds(mintPassConverterContract, tokenAIds, tokenBId)
          .then(async res => {
            setShowConfirmationModal(true);
            const isSoldOut = await isGenesisNFTSoldOut(tokenBId);
            setShowCombinations(!isSoldOut);
          })
          .catch(err => handleRequestError(err));
      })
      .catch(err => handleRequestError(err));
  };

  /**
   * Handles the purchase of the mint pass with Astrafer
   * @returns {Promise<void>}
   */
  const handleMintWithAstrafer = async tokenId => {
    let mintingResult;
    setShowMintWithAstrafer(false);

    try {
      await requestAllowanceIncrease(mintPassPrice.costInWei);

      let accountSignature;
      if (tokenId === MINT_PASS_IDS.PRISTINE_FLEET_MINTPASS) {
        accountSignature = account.pristineThirdClass;
      } else if (tokenId === MINT_PASS_IDS.VETERAN_FLEET_MINTPASS) {
        accountSignature = account.veteranThirdClass;
      } else if (tokenId === MINT_PASS_IDS.STANDARD_ISSUE_STARFIGHTER_MINTPASS) {
        accountSignature = account.standardStarfighter;
      }
      if (accountSignature !== null) {
        mintingResult = await getGenesisPayableMinterContract().mintPublicSale(
          tokenId,
          ASTRAFER_CONFIG.tokenAddress,
          1,
          '0x',
          accountSignature.v,
          accountSignature.r,
          accountSignature.s,
        );
      }
      await mintingResult.wait();

      if (mintingResult) {
        setShowConfirmationModal(true);
        await isGenesisNFTSoldOut(tokenId);
      } else {
        // Generic 'try again' error. Not sure if this scenario would happen (i.e. no result returned, but also no errors thrown)
        setRequestError('minting failed');
      }
    } catch (e) {
      console.error(e);
      handleRequestError(e);
    }
  };

  const handleRequestError = e => {
    let errorMessage;
    let error = BLOCKCHAIN_ERROR_MAP.find(error => (e?.data?.message || e?.message) === error.blockchainMessage);

    errorMessage = error ? error.displayMessage : DEFAULT_ERROR_MESSAGE;
    setRequestError({ errorMessage });
  };
  const loadAdditionalAllowanceRequired = async costInWei => {
    await additionalAllowanceRequired(getAstraferContract(), walletContext.currentWallet, costInWei, GENESIS_MINT_PASS_CONFIG.payableMinterAddress);
  };

  return (
    <>
      <HeaderComponent noBackground={true} />
      <div>
        <PageHeader headerText="STARFIGHTERS" classname="page-mobile-header-text-no-margin" />
        {/*{error && <div className={'nft-adjust-error-content'}>Error: {error.message}</div>}*/}
      </div>

      {!walletContext.signed && (
        <div className="mx-auto code-panel">
          <p className={'connect-wallet-general-text-design'}>Connect your wallet to Phantom Galaxies</p>

          <div className={'home-connect-wallet-button'}>
            <div className={'home-connect-wallet-button-mobile'}>
              <CustomConnectWalletButton />
            </div>
          </div>
        </div>
      )}

      {walletContext.signed && !isLoaded && <LoadingSpinner />}

      {walletContext.signed && !isCorrectChain && isLoaded && (
        <SwitchNetworkChainSection network={network} message={`You must be on the ${process.env.REACT_APP_CHAINID} network to mint NFTs`} />
      )}

      {/*{walletContext.signed && isCorrectChain && isLoaded && !error && (*/}
      {walletContext.signed && isCorrectChain && isLoaded && (
        <>
          <Container className="mintpass-info">
            <div className="mintpass-info__images-container">
              {generativeStarfighterImages.map((starfighter, index) => (
                <div key={index}>
                  <Image className="mintpass-info__image" fluid src={starfighter.image} alt={starfighter.alt} />
                </div>
              ))}
            </div>
            <p className="mintpass-info__description">
              Forged in the fires of interstellar conflict, Starfighters are now the vehicle of choice for the Ranger Corps, traversing war torn space
              and fighting to achieve peace. Starfighters switch deftly between mecha and starship forms, each with their own distinct strengths,
              purposes and actions. Players have four diverse classes to choose from, with unique abilities and styles of combat, all bristling with
              advanced weaponry and technology - these instruments of war have defined the intense, fast-paced and tactical combat that has overtaken
              the Canis Major Galaxy.
            </p>
          </Container>
          <div className={'genesis-tabs-container'}>
            <Container fluid>
              <Tab.Container id={'nft-tabs'} defaultActiveKey={'nft1'} onSelect={key => handleTabChange(key)}>
                <Row className={'nft-row-add-margin row justify-content-md-center'}>
                  <GenesisNFTsTabsList setShowCombination={setShowCombinations} setActiveNft={setActiveNFT} nfts={StarfighterNFTsList} />
                  <Col sm={2} className={'vertical-rule-container'}>
                    <div className={'vertical-rule-sticky-out-thingy'}>$nbsp;</div>
                    <div className={'vertical-rule'}>&nbsp;</div>
                  </Col>
                  <GenesisNFTsDescriptionsList
                    setShowMintWithAstrafer={setShowMintWithAstrafer}
                    setShowCombinations={setShowCombinations}
                    nfts={StarfighterNFTsList}
                    isSoldOut={isGenesisMintPassSoldOut}
                  />
                </Row>
                {showCombinations ? (
                  <Row className={'genesis-nft-combination-row'}>
                    <GenesisNFTsCombination setShowMintWithPosters={setShowMintWithPosters} activeNFT={activeNFT} account={account} />
                  </Row>
                ) : (
                  <></>
                )}
              </Tab.Container>
              {requestError ? (
                <div className={'genesis-error'}>
                  <PurchaseModalError errorMessage={requestError.errorMessage} />
                </div>
              ) : (
                <></>
              )}
            </Container>
          </div>
          {showMintWithPosters ? (
            <MintpassModal
              setShowMintWithPosters={setShowMintWithPosters}
              nft={activeNFT}
              account={account}
              convertButtonHandler={handleConversion}
            />
          ) : (
            <></>
          )}
          {showMintWithAstrafer ? (
            <MintpassAstraferModal
              astraferBalance={balance}
              setShowMintWithAstrafer={setShowMintWithAstrafer}
              astraferAmount={mintPassPrice.cost}
              activeNFT={activeNFT}
              purchaseConfirmHandler={handleMintWithAstrafer}
            />
          ) : (
            <></>
          )}
        </>
      )}

      <ConfirmationModal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)} />

      <Footer>
        <FighterLady />
      </Footer>
    </>
  );
}
