import './GenesisNFTsCombination.scss'
import {GenesisNFTCombinationElement} from "./GenesisNFTCombinationElement/GenesisNFTCombinationElement";
import {combination_equal, combination_plus} from "../../../assets";
import {useEffect, useState} from "react";

function GenesisNFTsCombination({setShowMintWithPosters, activeNFT, account}) {
  const [isButtonEnabled, setIsButtonEnabled] = useState(true)

  const showMintWithPosters = () => {
    setShowMintWithPosters(true)
  }

  useEffect(()=>{
    setIsButtonEnabled(true)
    activeNFT.nftsRequired.forEach((nft) =>{
      if(Number(account[nft.accountDataKey]) < 1){
        setIsButtonEnabled(false)
      }
    })
  },[activeNFT, account])

  return (
    <>
      <div className={'genesis-nft-combination-container'}>
        {activeNFT.nftsRequired.map((nft) => {
          return (
            <><GenesisNFTCombinationElement name={nft.name} image={nft.image} nrOwned={Number(account[nft.accountDataKey])}
                                            openSeaLink={nft.openSea}/><img
              src={combination_plus} alt={''} className={'genesis-nft-combination-plus'}/></>
          )
        })}
        <div className={'combination-result-container'}>
          <img src={combination_equal} alt={''} className={'genesis-nft-combination-equals'}/>
          <div className={'mintapass-container'}>
            <div className={'mintpass-image-container'}>
              <img src={activeNFT.image} alt={''}/>
            </div>
            <div className={'claim-mintpass-button-container'}>
              {isButtonEnabled ? <>
                <div className={'claim-mintpass-button'} onClick={showMintWithPosters}>CLAIM MINTPASS</div>
              </>: <>
                <div className={'claim-mintpass-button disabled'}>CLAIM MINTPASS</div></>}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {GenesisNFTsCombination}
