import React, {useEffect, useState} from "react";
import "./RevealInfo.scss";
import {bottom_text_container_border, text_container_border} from "../../../assets/images/AboutSection";


export default function RevealInfo({imgToShow, textToShow,heightAnimationLoader,onLoade}) {
  const [showDropDown, setShowDropDown] = useState(false);
  const [dontShowText,setDontShowText]=useState(false);

  function dropDownShow() {
    setShowDropDown(!showDropDown);
     heightAnimationLoader();
  }

useEffect(()=>{
  if(onLoade){
    setDontShowText(true);
  }
},[onLoade,setDontShowText])



  return <>
    <div className={"reveal-info-main-container"}>
      <div className={"reveal-img-container"}>
        <img alt={""} src={imgToShow} className={"reveal-content-img"}/>
      </div>
      <div className={"reveal-text-container"}>
        <img className={"text-container-border"} alt={""} src={text_container_border} />
        <div className={"reveal-text-box"}>
          {textToShow}
        </div>
        <img className={"text-container-border"} alt={""} src={text_container_border}/>
      </div>
    </div>

    <div className={"reveal-device-main-container"}>
      <div className={"reveal-device-img-container"} onClick={()=>dropDownShow()}>
        <img alt={""} src={imgToShow} className={"reveal-content-img"}/>
      </div>
      <div className={"reveal-device-text-container"}>
        <img alt={""} src={bottom_text_container_border} className={"drop-down-border"}/>

        <div
          className={dontShowText === true ?  showDropDown ? "animation-container" : "hide-animation-container" :"dont-show-the-text"}>
          <div className={"reveal-device-text-box"}>{textToShow}</div>
          <img alt={""} src={bottom_text_container_border} className={"drop-down-border"}/>

        </div>
      </div>
    </div>
  </>
}
