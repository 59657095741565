import React, {useContext} from "react";
import "./PayWithCurrency.scss";
import {PlanetSaleContext} from "../../../../Context/PlanetSalePageContext";

const PayWithCurrency = (props) => {

  const planetSaleContext = useContext(PlanetSaleContext);

  let clickEvent = (selectedCurrency) => {
    planetSaleContext.setSelectedCurrency(selectedCurrency);
    planetSaleContext.setMintModalShowValue(false);
    planetSaleContext.setPlanetPurchaseModalShow(true);
  }


  return <>
    <div className={(props.nftCost * props.numberOfNFT) > props.userBalance ? "USDC-principel-container-disabled" : "USDC-principel-container"} onClick={() => clickEvent(props.currency)}>
      <img src={props.currencyImg} alt=""/>
      <p className={"USDC-currency-text"}>{props.currencyText}</p>
    </div>
  </>
}

export default PayWithCurrency
