import {useEffect} from "react";
import "./ConnectButton.scss";
import {walletContext} from "../../../../utils/WalletContext";

export default function ConnectButton(props) {

  useEffect(() => {
    if(props.status) {
      startBlockpassKycConnect()
    }
  },[props.status]);

  const startBlockpassKycConnect = () => {
    const blockpass = new window.BlockpassKYCConnect(
      'phantom_galaxies_cc230',
      {
        env: 'prod',
        refId: walletContext.currentWallet
      });

    blockpass.startKYCConnect();
  }

  return <>
    {props.status?
      <div className={"button-outfit connect-button-active connect-button-text"}
          id="blockpass-kyc-connect" >
        {props.text}
        </div>
      :
    <div className={"button-outfit connect-button-inactive connect-button-text"}>
      <div className={"button-pink-border"}>
        {props.text}
      </div>
    </div>
    }
  </>
}
