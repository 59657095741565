import {Modal} from "react-bootstrap";
import {useEffect, useState} from "react";
import './Gam3AnnouncementModal.scss'
import {
  exit_gam3_img,
  gam3_awards_background,
  gam3_awards_logo, gam3_awards_logo_mobile, gam3_awards_mobile_background,
  gam3_badge,
  gam3_vote_background,
  medium_gam3_awards_background
} from "../../../assets";


export default function Gam3AnnouncementModal() {
  const [popupVisibility, setPopupVisibility] = useState(false);

  useEffect(() => {
    const lastShownDate = localStorage.getItem('lastModalShownDate');

    if (lastShownDate !== undefined && lastShownDate !== null) {

      let currentDate = new Date().getTime();
      let difference = currentDate - lastShownDate;
      let diffDays = difference / 1000 / 60 / 60 / 24;

      if(diffDays >= 1){
        setPopupVisibility(true);
      }

    } else {
      setPopupVisibility(true);
    }
  }, []);

  function onExitButtonClicked() {
      setPopupVisibility(!popupVisibility);
      localStorage.setItem('lastModalShownDate', new Date().getTime());
  }

  return <>
    <div className={'gam3-modal-main-container'}>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName='gam3-dialog'
        show={popupVisibility}
        className={'gam3-modal'}
      >
        <img alt={''} src={gam3_awards_background} className={'gam3-background'}/>
        <img alt={''} src={medium_gam3_awards_background} className={'medium-gam3-background'}/>
        <img alt={''} src={gam3_awards_mobile_background} className={'gam3-mobile-background'}/>
        <img alt={''} src={exit_gam3_img} className={'exit-gam3-img'} onClick={onExitButtonClicked}/>
        <div className={'game3-content-container'}>
          {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
          <a href={'https://polkastarter.gg/gam3awards/best-rpg/'} target={'_blank'} rel={'noreferrer'} className={'content-clickable'}></a>
          <div className={'gam3-badge-container'}>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a href={'https://polkastarter.gg/gam3awards/best-rpg/'} target={'_blank'} rel={'noreferrer'} className={'badge-clickable'}></a>
            <img alt={''} src={gam3_badge} className={'gam3-badge-image'}/>
            <div className={'gam3-vote-container'}>
              <img alt={''} src={gam3_vote_background} className={'gam3-vote-background'}/>
              <div className={'gam3-vote-text-container'}>
                <h2 className={'gam3-vote-finalist-text'}>FINALIST</h2>
                <h1 className={'gam3-vote-year-text'}>2022</h1>
                <h2 className={'gam3-vote-now-text'}>VOTE NOW</h2>
              </div>
            </div>
          </div>
          <div className={'gam3-description-container'}>
            <img alt={''} src={gam3_awards_logo} className={'gam3-awards-logo-image'}/>
            <img alt={''} src={gam3_awards_logo_mobile} className={'gam3-awards-logo-image-mobile'}/>
            <div className={'gam3-description-text-container'}>
              <h1 className={'gam3-description-finalist-text'}>FINALIST</h1>
              <h1 className={'gam3-description-phantom-text'}>PHANTOM<br/>GALAXIES</h1>
              <h1 className={'gam3-description-best-rpg-text'}>BEST RPG</h1>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  </>
}
