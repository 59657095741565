import React from "react";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import axios from "axios";

import Footer from "../components/Footer/Footer";
import LoadingSpinner from "../components/LoadingSpinner";
import ConnectWalletButton from "../components/ConnectWalletButton";
import {walletContext} from "../utils/WalletContext";

import "./Compensation.scss";
import PageHeader from "../components/PageHeader";
import HeaderComponent from "../components/Header/HeaderComponent";
import FighterLady from "../components/Footer/FooterElements/FighterLady/FighterLady";
import initializeTimelines from "../old/scripts/timelines";
import ShowOnScroll from "../old/scripts/show-on-scroll";

export default class Download extends React.Component {

  static contextType = walletContext.context;

  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoaded: false,
      justSaved: false,
      savedSelection: -1,
      email: null,
      chosenOption: -1,
    };

    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleSubmitForm = this.handleSubmitForm.bind(this);
  }

  async componentDidMount() {
    await this.maybeGetCompensationDetails();
    initializeTimelines();
    setTimeout(function () {
      var instShowOnScroll = new ShowOnScroll();
      instShowOnScroll.init();
    }, 250);
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    await this.maybeGetCompensationDetails();
  }

  async maybeGetCompensationDetails() {
    if (walletContext.signed && !this.state.isLoaded) {
      await this.getCompensationDetails();
    }
  }

  async getCompensationDetails() {
    let response = await axios
      .post(
        `${process.env.REACT_APP_BASEURL_OLD}/api/v1/checkCompensation`,
        {
          walletAddress: walletContext.currentWallet,
          signatureVerify: walletContext.signature,
        }
      )
      .catch((error) => {
        this.setState({
          isLoaded: true,
          error,
          savedSelection: -1,
        });
      });

    if (response.data.result == null) {
      this.setState({
        isLoaded: true,
        error: { message: "Unknown error occurred. Please try again. [0x80000000]" },
        savedSelection: -1
      });
    } else {
      if(response.data.result === 0) {
        this.setState({
          isLoaded: true,
          savedSelection: response.data.existingCompensationSelection
        }, () => {
          if (this.state.savedSelection === 1) {
            this.startBlockpassKycConnect()
          }
        });

      } else {
        this.setState({
          isLoaded: true,
          error: { message: "This wallet was not affected by the scam, and so is not eligible for this compensation." },
          savedSelection: -1
        });
      }
    }
  }

  handleChangeCheckbox(event) {
    this.setState({chosenOption: event.target.value});
  }

  handleChangeEmail(event) {
    this.setState({email: event.target.value});
  }

  async handleSubmitForm(event) {
    event.preventDefault();
    if(this.state.email && this.state.chosenOption !== -1) {

      let response = await axios
        .post(
          `${process.env.REACT_APP_BASEURL_OLD}/api/v1/submitCompensationRequest`,
          {
            walletAddress: walletContext.currentWallet,
            signatureVerify: walletContext.signature,
            compensationChoice: this.state.chosenOption,
            email: this.state.email,
          }
        )
        .catch((error) => {
          this.setState({
            isLoaded: true,
            error,
            savedSelection: -1,
          });
        });

      if (response.data.result == null) {
        this.setState({
          isLoaded: true,
          error: { message: "Unknown error occurred. Please try again. [0x80000001]" },
          savedSelection: -1
        });
      } else {
        if(response.data.result === 0) {
          this.setState({
            isLoaded: true,
            justSaved: true,
            savedSelection: response.data.existingCompensationSelection
          }, () => {
            if (this.state.savedSelection === 1) {
              this.startBlockpassKycConnect()
            }
          });

        } else {
          this.setState({
            isLoaded: true,
            error: { message: "Unknown error occurred. Please try again. [0x80000002]" },
            savedSelection: -1
          });
        }
      }
    }

  }



  startBlockpassKycConnect() {
    const blockpass = new window.BlockpassKYCConnect(
      'phantom_galaxies_cc230',
      {
        env: 'prod',
        refId: walletContext.currentWallet
      });

      blockpass.startKYCConnect();
  }

  render() {
    return (

    <>

      <HeaderComponent noBackground={true}/>
      <Container fluid>
        <Row>
          <hr className="downward-vee"/>
        </Row>
        <Row style={{
          // "background": "url(images/compensation-page-bg.png) top center no-repeat",
        }}>
          <img className={"compensation-page-bg"} src={"images/compensation-page-bg.png"} alt=""/>
          <Col className="d-flex align-items-center">
            <Container style={{maxWidth: "1600px", marginTop: "100px"}}>

              <PageHeader headerText='COMPENSATION' />

              <Row>
              <Col lg={{span: 5}}>

              {this.state.error &&
                <div className="mx-auto compensation">
                  <p>Error: {this.state.error.message}</p>
                  <p className={"spacer"}/>
                </div>
              }

              {!walletContext.signed &&
                <div className="mx-auto compensation">
                  <p>You have not connected your Wallet</p>
                  <p className={"spacer"}/>
                  <p>In order to proceed, please connect your Wallet and approve the signature request to verify
                    your elibility for compensation.</p>
                  <ConnectWalletButton/>
                </div>
              }

              {(walletContext.signed && !this.state.isLoaded) &&
                <LoadingSpinner/>
              }

              {(walletContext.signed && this.state.isLoaded && !this.state.error && this.state.savedSelection < 0) &&
                <div className="mx-auto compensation">
                  <p>Your Wallet is connected and verified</p>
                  <p className={"spacer"}/>
                  <p>Please select from the following options for compensation. Note that once you have submitted your choice, it cannot be changed.</p>

                  <div className={"form-panel"}>
                    <div className={"border-top"} />
                    <h3>Compensation Application</h3>
                    <Form onSubmit={this.handleSubmitForm}>
                      <Form.Group className="mb-3">
                        <Form.Control type="email" id={"email"} placeholder="Email Address" onChange={this.handleChangeEmail} />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Check type="radio" id={"comp1"} name={"comp"} value={"0"} onChange={this.handleChangeCheckbox} label="Poster 3 NFT" />
                        <Form.Check type="radio" id={"comp2"} name={"comp"} value={"1"} onChange={this.handleChangeCheckbox} label="ETH refund (requires KYC check)" />
                      </Form.Group>
                      <Form.Group className="mb-3 submit">
                        <Button type="submit" className={"button"}>
                          Submit
                        </Button>
                      </Form.Group>
                    </Form>
                    <div className={"border-bottom"} />
                  </div>
                </div>
              }

              {(walletContext.signed && this.state.isLoaded && !this.state.error && this.state.savedSelection === 0)&&
                <div className="mx-auto compensation">
                  <p>Your Wallet is connected and your compensation selection has been saved</p>
                  <p className={"spacer"}/>
                  <p>You have chosen "Poster 3 NFT" for your compensation. No further action is required.</p>
                </div>
              }

              {(walletContext.signed && this.state.isLoaded && !this.state.error && this.state.savedSelection === 1) &&
                <div className="mx-auto compensation">
                  <p>Your Wallet is connected and your compensation selection has been saved</p>
                  <p className={"spacer"}/>
                  <p>You have chosen "Ethereum Refund" for your compensation. Please complete the Blockpass KYC check below.</p>

                  <button id="blockpass-kyc-connect">Connect with Blockpass</button>

                  <p>If you are unable to successfully complete your Blockpass KYC check, you will default to receiving the Poster 3 NFT compensation.</p>
                </div>
              }

              {(walletContext.signed && this.state.isLoaded && !this.state.error && this.state.savedSelection > 1) &&
                <div className="mx-auto compensation">
                  <p>Your Wallet is connected and your compensation selection has been saved</p>
                  <p className={"spacer"}/>
                  <p>You have chosen an invalid compensation item. Please contact a Blowfish moderator on Discord for further assistance.</p>
                </div>
              }

              </Col>
              <Col lg={{span: 7}} className="compensation-man">
                <img src={"images/compensation-man.png"} alt=""/>
              </Col>
              </Row>

            </Container>
          </Col>
        </Row>
        <Row>
          <hr className="downward-vee"/>
        </Row>
        <Row>
          <Footer>
            <FighterLady/>
          </Footer>
        </Row>
      </Container>
    </>
    );
  }
}
