import "./GenerativePlanetCatalystGrid.scss";
import GenerativePlanetCatalyst from "../GenerativePlanetCatalyst/GenerativePlanetCatalyst";
import { disabled_catalyst_icon } from "../../../assets/images/GenerativePlanetMinting";
import LoadingSpinner from "../../LoadingSpinner";

export default function GenerativePlanetCatalystGrid({
  disabled,
  selectHandler,
  catalysts,
  isCatalystFlow,
}) {
  return (
    <div className={"batch-content"}>
      <div
        className={`add-catalyst-title-container ${
          !isCatalystFlow ? "disabled-title-add" : ""
        }`}
      >
        <div className={"add-catalyst-title"}>ADD CATALYST</div>
      </div>

      <div className={"add-catalyst-list"}>
        {catalysts.length > 0 ? (
          catalysts.map((catalyst, index) => (
            <GenerativePlanetCatalyst
              key={index}
              title={catalyst.title}
              isDisabled={disabled || !catalyst.selectableCatalyst}
              image={
                isCatalystFlow && catalyst.selectableCatalyst
                  ? catalyst.image
                  : disabled_catalyst_icon
              }
              pinkImage={catalyst.selectedImage}
              selected={catalyst.selected}
              onClick={selectHandler.bind(this, catalyst.title)}
            />
          ))
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </div>
  );
}
