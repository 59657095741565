import './OtherNFTRow.scss'
import OtherNFT from "../OtherNFT/OtherNFT";

export default function OtherNFTRow({nftRow, handleClick}){
  return <>
    <div className={'other-nft-row-container'}>
      {nftRow.map(nft => (
        <OtherNFT tokenId={nft.tokenId} isClaimed={nft.isClaimed} handleClick={event => handleClick(nft.index)}/>
      ))}
    </div>
  </>
}
