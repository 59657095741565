import React, {useContext} from "react";
import "./MintButton.scss";
import {PlanetSaleContext} from "../../../../../../../components/Context/PlanetSalePageContext";

export default function MintButton(props) {


  const planetSaleContext = useContext(PlanetSaleContext);

  function openMintModal() {


    if (props.activeButton) {
      planetSaleContext.setMintModalShowValue(true);
      planetSaleContext.setPlanetToMint(props.planet);
      planetSaleContext.setNumberOfNFTToMin(props.numberOfNFT);
      planetSaleContext.setNFTCost(props.cost);
      planetSaleContext.setNFTId(props.nftId);
      planetSaleContext.setSellType(props.sellType);
      planetSaleContext.setV(props.v);
      planetSaleContext.setR(props.r);
      planetSaleContext.setS(props.s);
      planetSaleContext.setTicket(props.ticketId);
      planetSaleContext.setIndexOfNFT(props.index);
    }
  }

  return <>

    <div className={props.activeButton ? "mb-principle-body" : "mb-principle-body-inactive"} onClick={openMintModal}>
      <p className={"mb-text-adjustment"}>MINT</p>
    </div>
  </>

}
