import React from 'react';
import { ENABLE_KYC_PAGE, ENABLE_PRIVATE_PLANET_SALE, episodeIds, MINT_PASS_IDS } from './Globals';
import {
  discord_logo,
  email_logo,
  epic_games_logo,
  fa_paper_plane_icon_s,
  facebook_logo,
  genesis_nft_1,
  genesis_nft_1_sold_out,
  genesis_nft_2,
  genesis_nft_2_sold_out,
  genesis_nft_3,
  genesis_nft_3_sold_out,
  halberd_nft,
  instagram_logo,
  medal_nft_1,
  medal_nft_2,
  medal_nft_3,
  medal_nft_4,
  medium_logo,
  reddit_logo,
  redeemed_halberd_nft,
  redeemed_shining_star_nft,
  redeemed_thermopylae_nft,
  redeemed_xanorra_slave_barge_nft,
  shining_star_nft,
  steam_logo,
  thermopylae_nft,
  tiktok_logo,
  twitch_logo,
  x_logo,
  xanorra_slave_barge_nft,
  youtube_logo,
} from '../assets';
import { planet_image } from '../assets/images/ExplorationSelection';
import { walletContext } from './WalletContext';
import { large_asteroid, large_planet, medium_planet, small_asteroid, small_planet } from '../assets/images/GenerativePlanetMinting';
import { MISSION_STATUS, MISSION_TAB_DISPLAY_TEXT } from '../components/PlanetExploration/PlanetExplorationContent/PlanetExplorationContent';
import {
  elpis_first_class_mintpass,
  standard_issue_mintpass,
  zveta_first_class_mintpass,
  zveta_second_class_mintpass,
  zveta_third_class_mintpass,
} from '../assets/images/GenerativeAvatarMinting';
import { Pristine, Standardfleet, Unspecefied, Veteran, Zveta } from '../assets/images/ThirdClassMintpass';

export const EMPTY_STRING = '';
export const MAXIMUM_PLANET_MINTS = 100;
export const ECOSYSTEM_TRAIT_TITLE = 'Ecosystem';
export const RESOURCE_STATE_TRAIT_TITLE = 'Resource State';
export const RESOURCE_REACTIVITY_TRAIT_TITLE = 'Resource Reactivity';
export const DISCOVERIES_TRAIT_TITLE = 'Discoveries';
export const CELESTIAL_BODIES_TRAIT_TITLE = 'Celestial Bodies';
export const BUILD_TRAIT_TITLE = 'Build';
export const generativePlanetMintingInfoText = {
  planetMint: (
    <p>
      <p>
        When using Batch Mint, you can add up to one hundred (100) of your Planet Mintpass NFTs by clicking the plus ("+") button. There is no
        restriction on the size of planets to be minted at the same time. However, while using the Batch Mint mode, you can NOT add any Catalyst NFTs
        to the planets, so they will all be assigned random Traits. As well, all Planet Mintpass NFTs must be from the same generation in order to
        Batch Mint.
      </p>
      <p>
        For more granular control over the planet being minted, use the Use Catalyst option. While you can only mint one of your Planet Mintpass NFTs
        at a time using this method, it allows you to select one or more Catalyst NFTs to influence the Traits of the planet being minted. Note: You
        can only apply one Trait per catalyst category.
      </p>
      <p>
        When you are happy with your planet(s), select "Mint" and follow the steps shown in the checklist. Some steps only need to be followed once,
        so don't worry if they already show as complete!
      </p>
      <p>
        Once you have completed the checklist, you will receive your Planet NFT(s) in your wallet. Note: The image and metadata of each Planet NFT may
        not be visible immediately as it does take time to generate.
      </p>
    </p>
  ),
  catalyst: (
    <div>
      Catalysts are used to influence the attributes of your Planet(s) at the time of minting.
      <br />
      <br />
      <ul>
        <li>The Ecosystem Catalyst defines the environment your planet will have, which impacts various surface traits.</li>
        <li>Resource State and Resource Reactivity Catalysts increase the proportion of certain categories of resources available on the planet.</li>
        <li>
          The Discoveries Catalyst influences the type of non-resource items that will be found on your planet. This includes both naturally occurring
          items, as well as archeological artifacts.
        </li>
        <li>
          The Celestial Bodies Catalyst defines the number and type of features within a planet’s satellite system such as Moons and Ring Systems.
          NOTE: Celestial Bodies are only for Planets. Asteroids do not have a Celestial Bodies trait.
        </li>
        <li>
          For more information about Catalysts & Traits, read our{' '}
          <a
            href="https://medium.com/@PhantomGalaxies/introduction-to-planet-traits-the-planet-phantom-catalysts-d4becef1a580"
            target="_blank"
            rel="noopener noreferrer"
          >
            Medium article
          </a>
        </li>
      </ul>
    </div>
  ),
  planets: (
    <p>
      You can view all your minted planets at any time on <a href="https://opensea.io/">OpenSea</a>!{' '}
    </p>
  ),
};

export const generativeAvatarMintingInfoText = {
  avatarMint: (
    <div>
      You can mint an Avatar using either the Batch Mint or Add Catalyst options.
      <br />
      <br />
      When using Batch Mint, you can add up to one hundred (100) of your Gene Sample NFTs by clicking the plus (“+”) button. There is no restriction
      on the type of Avatars to be minted at the same time. However, while using the Batch Mint mode, you can NOT add any Catalyst NFTs to the
      Avatars, so they will all be assigned random Traits.
      <br />
      <br />
      For more granular control over the Avatars being minted, use the Add Catalyst option. While you can only mint one of your Gene Sample NFTs at a
      time using this method, it allows you to select one or more Catalyst NFTs to select which Gender or Genetic Base trait you would like to
      receive. NOTE: You can only apply one Trait per catalyst category.
      <br />
      <br />
      When you are happy with your Avatar(s), select “Mint” and follow the steps shown in the checklist. Some steps only need to be followed once, so
      don’t worry if they already show as complete!
      <br />
      <br />
      Once you have completed the checklist, you will receive your Avatar NFT(s) in your wallet. Note: The image and metadata of each Avatar NFT may
      not be visible immediately as it does take time to generate.
    </div>
  ),
  catalyst: (
    <div>
      Catalysts are used to influence the attributes of your Avatar(s) at the time of minting.
      <br />
      <br />
      <ul>
        <li>The Gender Catalyst defines what the base model of your Avatar is; either Male or Female.</li>
        <li>
          The Genetic Base Catalyst allows you to select one of four unique Genetic Bases for your Avatar to generate as. This dictates its visual
          lineage from a selection of past heroes.
        </li>
        <li>
          For more information about Catalysts & Traits, read our{' '}
          <a
            href="https://medium.com/@PhantomGalaxies/supermint-trait-phantom-catalyst-nfts-for-starfighters-avatars-ea1419fffbef"
            target="_blank"
            rel="noopener noreferrer"
          >
            Medium article
          </a>
        </li>
      </ul>
    </div>
  ),
  avatars: (
    <p>
      You can view all your minted avatars at any time on <a href="https://opensea.io/">OpenSea</a>!{' '}
    </p>
  ),
};

export const NONE = 'NONE';
export const NO_TRAIT = 'NO_TRAIT';
export const convertingPoster1forPoster2 = {
  Note: (
    <>
      <b>Note:</b> Confirming this action will remove 5 <b>Poster 1</b> NFTs from your wallet,
      <br /> burning them and replacing them with the <b>Poster 2</b> NFT. This action cannot be
      <br />
      reversed.
      <br />
      <br />
    </>
  ),
  processDescription: 'This process will take a few minutes.',
  agreementText: 'Do you wish to proceed?',
  firstMintMessage: (
    <>
      <b>Note: </b>As this is the first time using the minting process, there will be <b>2 wallet transactions</b> to approve.
    </>
  ),
  episode1: (
    <>
      Episode 1:
      <br />
      Halberd-001
    </>
  ),
  episode2: (
    <>
      Episode 2:
      <br />
      Shining Star
    </>
  ),
};

export const UNBINDABLE_NFT_FILTER = 'UNBINDABLE';
export const BOUND_NFT_FILTER = 'BOUND';
export const UNBOUND_NFT_FILTER = 'UNBOUND';

export const mockDashboardFilters = [
  {
    title: "NFT'S",
    filters: [
      {
        text: UNBINDABLE_NFT_FILTER,
        isSelected: false,
      },
      {
        text: BOUND_NFT_FILTER,
        isSelected: false,
      },
      {
        text: UNBOUND_NFT_FILTER,
        isSelected: false,
      },
    ],
  },
  {
    title: 'PLANETS',
    filters: [
      {
        text: 'LARGE PLANET',
        isSelected: false,
      },
      {
        text: 'MEDIUM PLANET',
        isSelected: false,
      },
      {
        text: 'SMALL PLANET',
        isSelected: false,
      },
      {
        text: 'LARGE ASTEROID',
        isSelected: false,
      },
      {
        text: 'SMALL ASTEROID',
        isSelected: false,
      },
    ],
  },
  {
    title: 'TRAITS',
    filters: [
      {
        text: 'TRAIT 1',
        isSelected: false,
      },
      {
        text: 'TRAIT 2',
        isSelected: false,
      },
      {
        text: 'TRAIT 3',
        isSelected: false,
      },
      {
        text: 'TRAIT 4',
        isSelected: false,
      },
    ],
  },
  {
    title: 'PHASE 1',
    filters: [
      {
        text: 'COMPLETE',
        isSelected: false,
      },
      {
        text: 'INCOMPLETE',
        isSelected: false,
      },
      {
        text: 'IN PROGRESS',
        isSelected: false,
      },
    ],
  },
];

export const mockAstraferRewards = {
  minGain: 12,
  maxGain: 8000000,
  daysDuration: 60,
  checkpoints: 12,
  astraferPerCheckpoint: 28,
};

export const mockPlanetsRewards = {
  largePlanet: 12,
  mediumPlanet: 6,
  smallPlanet: 5,
  largeAsteroid: 32,
  smallAsteroid: 106,
};

export const planetSizes = {
  largePlanet: 5,
  mediumPlanet: 4,
  smallPlanet: 3,
  largeAsteroid: 2,
  smallAsteroid: 1,
};

const { largePlanet, mediumPlanet, smallPlanet, largeAsteroid, smallAsteroid } = planetSizes;
export const planetDetailsMap = new Map();
planetDetailsMap.set(largePlanet, { title: 'LARGE PLANETS', category: largePlanet });
planetDetailsMap.set(mediumPlanet, { title: 'MEDIUM PLANETS', category: mediumPlanet });
planetDetailsMap.set(smallPlanet, { title: 'SMALL PLANETS', category: smallPlanet });
planetDetailsMap.set(largeAsteroid, { title: 'LARGE ASTEROIDS', category: largeAsteroid });
planetDetailsMap.set(smallAsteroid, { title: 'SMALL ASTEROIDS', category: smallAsteroid });

export const explorationSelectInfo = [
  {
    title: <div>ASTRAFER HAULS</div>,
    description: (
      <div>
        Hermesium scavenged from asteroids of the Ceres Quadrant fetch generous prices in the Canis Major Galaxy. Rewards for Missions will be based
        upon the Bound Planets and the completion of Check-Ins. All rewards can be claimed at the end of the Mission.
        <br />
        <br />
        <ul>
          <li>Small asteroid &nbsp;- 7.5 ASTRAFER for Completed Mission</li>
          <li>Large asteroid &nbsp;- 34.5 ASTRAFER for Completed Mission</li>
          <li>Small planet &nbsp;- 93 ASTRAFER for Completed Mission</li>
          <li>Medium planet &nbsp;- 690 ASTRAFER for Completed Mission</li>
          <li>Large planet &nbsp;- 7837.5 ASTRAFER for Completed Mission</li>
        </ul>
        <br />
        For each Completed Mission, receive 6.66% of these ASTRAFER totals per Check-In Completed. Completing no Check-Ins will yield zero (0)
        ASTRAFER, while completing all Check-Ins will yield the Maximum haul totals listed above.
      </div>
    ),
  },
  {
    title: <div>PLANET REWARDS</div>,
    description: (
      <p>
        <p>
          Complete a Recon Mission with a Bound Gen-1 Planet to earn a new Gen-2 Planet Mintpass, i.e. for every Gen-1 Planet that has been Bound for
          the Mission, a Gen-2 Planet Mintpass of the same size will be rewarded after completion. Gen-2 Planets may also enter missions and receive
          rewards, but are not eligible to receive additional mintpasses.
        </p>
        <p>
          For each Planet, Recon can only be completed once. After completion, the Recon Mission will no longer be available for that Planet.
          Abandoning a Mission will forfeit all ASTRAFER and Planet rewards for that Mission. The Mission can be taken again with the same Planet(s),
          but must be started anew, as no Mission progress is saved.
        </p>
      </p>
    ),
  },
  {
    title: <div>LINKING WALLETS FOR PLANET HOLDERS</div>,
    description: (
      <div>
        This feature can be used to link wallets between Planet Holders and Players for The Ceres Quadrant: Recon Missions. This is useful if you want
        to complete Mission Check-Ins in one wallet (a Player Wallet), while holding Planet NFTs in a separate wallet (a Planet Holder Wallet).
        <br />
        Once linked, Check-Ins completed by the Player Wallet will contribute to the assigned Mission, earning rewards for the Planet Holder. Linked
        Player Wallets will not receive any rewards for Check-In or Mission completion.
        <br />
        The Planet Holder Wallet will not be able to complete Check-Ins for Missions they have assigned, and a Mission can be assigned to only one
        Player Wallet. Planet Holder and Player Wallets cannot unlink during an Active Mission - the Mission must be abandoned to unlink the wallets
        before completion, and any progress or rewards will not be saved.
        <br />
      </div>
    ),
  },
  {
    title: <div>UNAVAILABLE PLANETS</div>,
    description: (
      <div>
        <br />
        <ul>
          <li>Planets can only be used once for a specific mission.</li>
          <li>Planets can only be used once per mission.</li>
        </ul>
        <br />
      </div>
    ),
  },
];

export const PLANET_IN_MISSION_STATUS = 'IN_MISSION';
export const BOUND_STATUS = 'BOUND';
export const UNBOUND_STATUS = 'UNBOUND';
export const BOUND_IN_MISSION_STATUS = 'BOUND IN MISSION';
export const litepaperPath = `${window.location.protocol}//${window.location.host}/BFS_Phantom_Galaxies_Litepaper.pdf`;
export const tokenPage = '/token';

export const mockLargePlanets = [
  {
    id: 'LY4059',
    randomSeed: 842752710340160,
    chainId: 43113,
    contractAddress: '0x565609464f3032affa3b07f804d20969e2c64793',
    tokenId: 53,
    mintTxHash: '0x4adc711544cc42fe6ba297797349b2df5f3fdd28ad3ae41046651c98b56c16c6',
    catalysts: [],
    generation: 1,
    size: 1,
    name: '4765C',
    biome: 1,
    surfaceRoughness: 0.5,
    bedrockFirmness: 0.5,
    abundance: 0.5937458348926157,
    nebula: 2,
    resourceValue1: 2,
    resourceValue2: 0,
    resourceValue3: 0,
    resourceValue4: 0,
    resourceValue5: 0,
    resourceValue6: 0,
    resourceValue7: 0,
    resourceValue8: 0,
    resourceValue9: 0,
    overallHostility: 0.9949324866756797,
    stratosphereTurbulence: 56,
    electromagneticInterference: 56,
    visibility: 56,
    unstableTopography: 56,
    acidPools: 56,
    moltenPools: 56,
    iceSheetFissure: 56,
    moons: 56,
    ringSystem: 56,
    ancientMapFrequency: 2,
    undergroundTunnelFrequency: 3,
    nativeCavePaintingFrequency: 2,
    mysteriousObeliskFrequency: 2,
    ancientMapValue: 0,
    undergroundTunnelValue: 0,
    nativeCavePaintingValue: 0,
    mysteriousObeliskValue: 0,
    distanceFromSun: 3,
    createdAt: new Date('2023-08-23T02:23:17.811Z'),
    updatedAt: new Date('2023-08-23T02:23:17.811Z'),
    thumbnailImageUrl: 'undefined/43113/0x565609464f3032affa3b07f804d20969e2c64793/53.350.png',
    fullImageUrl: 'https://planets.dev.phantomgalaxies.com/43113/0x341534571e94c035d3ad98774dd59c848632616a/65.350.png',
  },
  {
    name: 'PLANET NAME',
    image: planet_image,
    nft: BOUND_NFT_FILTER,
    id: '2',
    location: 'XXXX',
    isSelected: false,
    isFavorite: false,
    status: 'BOUND',
    phase1: 'COMPLETED',
    ASTRAFER: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: planet_image,
    nft: BOUND_NFT_FILTER,
    id: '3',
    location: 'XXXX',
    isSelected: false,
    isFavorite: true,
    status: 'BOUND IN MISSION',
    phase1: 'COMPLETED',
    ASTRAFER: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: planet_image,
    nft: BOUND_NFT_FILTER,
    id: '4',
    location: 'XXXX',
    isSelected: false,
    isFavorite: false,
    status: 'BOUND IN MISSION',
    phase1: 'COMPLETED',
    ASTRAFER: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: planet_image,
    nft: BOUND_NFT_FILTER,
    id: '5',
    location: 'XXXX',
    isSelected: false,
    isFavorite: true,
    status: 'BOUND IN MISSION',
    phase1: 'COMPLETED',
    ASTRAFER: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: planet_image,
    nft: BOUND_NFT_FILTER,
    id: '6',
    location: 'XXXX',
    isSelected: false,
    isFavorite: false,
    status: 'BOUND IN MISSION',
    phase1: 'COMPLETED',
    ASTRAFER: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: planet_image,
    nft: BOUND_NFT_FILTER,
    id: '7',
    location: 'XXXX',
    isSelected: false,
    isFavorite: true,
    status: 'UNBOUND',
    phase1: 'COMPLETED',
    ASTRAFER: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: planet_image,
    nft: BOUND_NFT_FILTER,
    id: '8',
    location: 'XXXX',
    isSelected: false,
    isFavorite: false,
    status: 'BOUND IN MISSION',
    phase1: 'COMPLETED',
    ASTRAFER: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: planet_image,
    nft: BOUND_NFT_FILTER,
    id: '9',
    location: 'XXXX',
    isSelected: false,
    isFavorite: true,
    status: 'UNBOUND',
    phase1: 'COMPLETED',
    ASTRAFER: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
];

export const mockMediumPlanets = [
  {
    name: 'PLANET NAME',
    nft: UNBOUND_NFT_FILTER,
    image: medium_planet,
    id: '10',
    location: 'XXXX',
    isSelected: false,
    isFavorite: true,
    status: 'BOUND IN MISSION',
    phase1: 'COMPLETED',
    ASTRAFER: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: medium_planet,
    nft: UNBOUND_NFT_FILTER,
    id: '11',
    location: 'XXXX',
    isSelected: false,
    isFavorite: false,
    status: 'BOUND',
    phase1: 'COMPLETED',
    ASTRAFER: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: medium_planet,
    nft: UNBOUND_NFT_FILTER,
    id: '12',
    location: 'XXXX',
    isSelected: false,
    isFavorite: true,
    status: 'UNBOUND',
    phase1: 'COMPLETED',
    ASTRAFER: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: medium_planet,
    nft: UNBOUND_NFT_FILTER,
    id: '13',
    location: 'XXXX',
    isSelected: false,
    isFavorite: false,
    status: 'BOUND IN MISSION',
    phase1: 'COMPLETED',
    ASTRAFER: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: medium_planet,
    nft: UNBOUND_NFT_FILTER,
    id: '14',
    location: 'XXXX',
    isSelected: false,
    isFavorite: true,
    status: 'BOUND',
    phase1: 'COMPLETED',
    ASTRAFER: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: medium_planet,
    nft: UNBOUND_NFT_FILTER,
    id: '15',
    location: 'XXXX',
    isSelected: false,
    isFavorite: false,
    status: 'UNBOUND',
    phase1: 'COMPLETED',
    ASTRAFER: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: medium_planet,
    nft: UNBOUND_NFT_FILTER,
    id: '16',
    location: 'XXXX',
    isSelected: false,
    isFavorite: true,
    status: 'BOUND',
    phase1: 'COMPLETED',
    ASTRAFER: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: medium_planet,
    nft: UNBOUND_NFT_FILTER,
    id: '17',
    location: 'XXXX',
    isSelected: false,
    isFavorite: false,
    status: 'UNBOUND',
    phase1: 'COMPLETED',
    ASTRAFER: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: medium_planet,
    nft: UNBOUND_NFT_FILTER,
    id: '19',
    location: 'XXXX',
    isSelected: false,
    isFavorite: true,
    status: 'BOUND IN MISSION',
    phase1: 'COMPLETED',
    ASTRAFER: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
];

export const mockSmallPlanets = [
  {
    name: 'PLANET NAME',
    image: medium_planet,
    nft: UNBOUND_NFT_FILTER,
    id: '31',
    location: 'XXXX',
    isSelected: false,
    isFavorite: true,
    status: 'BOUND IN MISSION',
    phase1: 'COMPLETED',
    astrafer: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: medium_planet,
    nft: UNBOUND_NFT_FILTER,
    id: '32',
    location: 'XXXX',
    isSelected: false,
    isFavorite: false,
    status: 'BOUND IN MISSION',
    phase1: 'COMPLETED',
    astrafer: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: medium_planet,
    nft: UNBOUND_NFT_FILTER,
    id: '33',
    location: 'XXXX',
    isSelected: false,
    isFavorite: true,
    status: 'BOUND',
    phase1: 'COMPLETED',
    astrafer: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: medium_planet,
    nft: UNBOUND_NFT_FILTER,
    id: '34',
    location: 'XXXX',
    isSelected: false,
    isFavorite: false,
    status: 'UNBOUND',
    phase1: 'COMPLETED',
    astrafer: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: medium_planet,
    nft: UNBOUND_NFT_FILTER,
    id: '35',
    location: 'XXXX',
    isSelected: false,
    isFavorite: true,
    status: 'UNBOUND',
    phase1: 'COMPLETED',
    astrafer: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: medium_planet,
    nft: UNBOUND_NFT_FILTER,
    id: '36',
    location: 'XXXX',
    isSelected: false,
    isFavorite: false,
    status: 'BOUND IN MISSION',
    phase1: 'COMPLETED',
    astrafer: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: medium_planet,
    nft: UNBOUND_NFT_FILTER,
    id: '37',
    location: 'XXXX',
    isSelected: false,
    isFavorite: true,
    status: 'BOUND IN MISSION',
    phase1: 'COMPLETED',
    astrafer: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: medium_planet,
    nft: UNBOUND_NFT_FILTER,
    id: '38',
    location: 'XXXX',
    isSelected: false,
    isFavorite: false,
    status: 'BOUND IN MISSION',
    phase1: 'COMPLETED',
    astrafer: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: medium_planet,
    nft: UNBOUND_NFT_FILTER,
    id: '39',
    location: 'XXXX',
    isSelected: false,
    isFavorite: true,
    status: 'UNBOUND',
    phase1: 'COMPLETED',
    astrafer: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
];

export const mockLargeAsteroids = [
  {
    name: 'PLANET NAME',
    image: large_asteroid,
    nft: UNBINDABLE_NFT_FILTER,
    id: '21',
    location: 'XXXX',
    isSelected: false,
    isFavorite: true,
    status: 'BOUND IN MISSION',
    phase1: 'COMPLETED',
    ASTRAFER: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: large_asteroid,
    nft: UNBINDABLE_NFT_FILTER,
    id: '22',
    location: 'XXXX',
    isSelected: false,
    isFavorite: false,
    status: 'UNBOUND',
    phase1: 'COMPLETED',
    ASTRAFER: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: large_asteroid,
    nft: UNBINDABLE_NFT_FILTER,
    id: '23',
    location: 'XXXX',
    isSelected: false,
    isFavorite: true,
    status: 'BOUND IN MISSION',
    phase1: 'COMPLETED',
    ASTRAFER: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: large_asteroid,
    nft: UNBINDABLE_NFT_FILTER,
    id: '24',
    location: 'XXXX',
    isSelected: false,
    isFavorite: false,
    status: 'BOUND IN MISSION',
    phase1: 'COMPLETED',
    ASTRAFER: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: large_asteroid,
    nft: UNBINDABLE_NFT_FILTER,
    id: '25',
    location: 'XXXX',
    isSelected: false,
    isFavorite: true,
    status: 'BOUND',
    phase1: 'COMPLETED',
    ASTRAFER: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: large_asteroid,
    nft: UNBINDABLE_NFT_FILTER,
    id: '26',
    location: 'XXXX',
    isSelected: false,
    isFavorite: false,
    status: 'BOUND',
    phase1: 'COMPLETED',
    ASTRAFER: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: large_asteroid,
    nft: UNBINDABLE_NFT_FILTER,
    id: '27',
    location: 'XXXX',
    isSelected: false,
    isFavorite: true,
    status: 'BOUND IN MISSION',
    phase1: 'COMPLETED',
    ASTRAFER: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: large_asteroid,
    nft: UNBINDABLE_NFT_FILTER,
    id: '28',
    location: 'XXXX',
    isSelected: false,
    isFavorite: false,
    status: 'UNBOUND',
    phase1: 'COMPLETED',
    ASTRAFER: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: large_asteroid,
    nft: UNBINDABLE_NFT_FILTER,
    id: '29',
    location: 'XXXX',
    isSelected: false,
    isFavorite: true,
    status: 'BOUND IN MISSION',
    phase1: 'COMPLETED',
    ASTRAFER: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
];

export const mockSmallAsteroids = [
  {
    name: 'PLANET NAME',
    image: large_asteroid,
    nft: UNBINDABLE_NFT_FILTER,
    id: '41',
    location: 'XXXX',
    isSelected: false,
    isFavorite: true,
    status: 'BOUND IN MISSION',
    phase1: 'COMPLETED',
    astrafer: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: large_asteroid,
    nft: UNBINDABLE_NFT_FILTER,
    id: '42',
    location: 'XXXX',
    isSelected: false,
    isFavorite: false,
    status: 'BOUND IN MISSION',
    phase1: 'COMPLETED',
    astrafer: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: large_asteroid,
    nft: UNBINDABLE_NFT_FILTER,
    id: '43',
    location: 'XXXX',
    isSelected: false,
    isFavorite: true,
    status: 'BOUND IN MISSION',
    phase1: 'COMPLETED',
    astrafer: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: large_asteroid,
    nft: UNBINDABLE_NFT_FILTER,
    id: '44',
    location: 'XXXX',
    isSelected: false,
    isFavorite: false,
    status: 'BOUND IN MISSION',
    phase1: 'COMPLETED',
    astrafer: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: large_asteroid,
    nft: UNBINDABLE_NFT_FILTER,
    id: '45',
    location: 'XXXX',
    isSelected: false,
    isFavorite: true,
    status: 'BOUND IN MISSION',
    phase1: 'COMPLETED',
    astrafer: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: large_asteroid,
    nft: UNBINDABLE_NFT_FILTER,
    id: '46',
    location: 'XXXX',
    isSelected: false,
    isFavorite: false,
    status: 'BOUND IN MISSION',
    phase1: 'COMPLETED',
    astrafer: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: large_asteroid,
    nft: UNBINDABLE_NFT_FILTER,
    id: '47',
    location: 'XXXX',
    isSelected: false,
    isFavorite: true,
    status: 'BOUND IN MISSION',
    phase1: 'COMPLETED',
    astrafer: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: large_asteroid,
    nft: UNBINDABLE_NFT_FILTER,
    id: '48',
    location: 'XXXX',
    isSelected: false,
    isFavorite: false,
    status: 'BOUND IN MISSION',
    phase1: 'COMPLETED',
    astrafer: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
  {
    name: 'PLANET NAME',
    image: large_asteroid,
    nft: UNBINDABLE_NFT_FILTER,
    id: '49',
    location: 'XXXX',
    isSelected: false,
    isFavorite: true,
    status: 'BOUND IN MISSION',
    phase1: 'COMPLETED',
    astrafer: 'UNCLAIMED',
    phase2: 'AWAITING',
  },
];

export const mockPlanets = [
  {
    title: 'LARGE PLANETS',
    category: 'largePlanet',
    planets: mockLargePlanets,
  },
  {
    title: 'MEDIUM PLANETS',
    category: 'mediumPlanet',
    planets: mockMediumPlanets,
  },
  {
    title: 'SMALL PLANETS',
    category: 'smallPlanet',
    planets: mockSmallPlanets,
  },
  {
    title: 'LARGE ASTEROIDS',
    category: 'largeAsteroid',
    planets: mockLargeAsteroids,
  },
  {
    title: 'SMALL ASTEROIDS',
    category: 'smallAsteroid',
    planets: mockSmallAsteroids,
  },
];

export const licenseAndAgreement = {
  text: (
    <>
      <p className={'license-header'}>License Agreement</p>
      <p className={'license-header'}>Unlimited Personal and Commercial Use</p>
      <br className={'break-between-paragraphs'} />
      <p>
        This licence agreement (this "Agreement") sets out the terms relating to the grant of a licence in certain intellectual property in Phantom
        Galaxies, which is a collection of digital collectable tokens using the ERC-721 and ERC-1155 token standards ("
        <span className={'license-bold'}>Phantom Galaxies NFTs</span>"). Phantom Galaxies NFTs are minted via smart contracts deployed on the Polygon
        blockchain owned by the wallet address 0xF480212E126cE2278e1118FFc780574619dfeaa9 and designated for use in Phantom Galaxies (collectively
        referred to as the "<span className={'license-bold'}>Smart Contract</span>"). Phantom Galaxies NFTs contain metadata which are associated with
        digital art created by the Creator (as defined below) (the "<span className={'license-bold'}>Artwork</span>"). For the purposes of this
        Agreement, the "Licensed NFT Artwork" refers to the Artwork (in its entirety) specifically, directly and identifiably associated with the
        Phantom Galaxies NFT owned by you in totality and not any individual component of the Artwork.
      </p>
      <br className={'break-between-chapters'} />
      <p>This Agreement, which contains legally binding provisions, is initially entered into between:</p>
      <br className={'break-between-paragraphs'} />
      <ul className={'license-list-1'}>
        <li>
          Ultra Modern Group Limited, a company incorporated under the laws of the British Virgin Islands ("
          <span className={'license-bold'}>Creator</span>"); and
        </li>
        <br className={'break-between-paragraphs'} />
        <li>
          the individual or entity who is the recorded owner of <span className={'license-bold'}>Phantom Galaxies NFTs</span> and who has expressly
          accepted the terms of this Agreement by inserting their electronic signature ("<span className={'license-bold'}>you</span>").
        </li>
      </ul>
      <br className={'break-between-paragraphs'} />

      <p>
        Purchasing a non-fungible token does not automatically grant you any intellectual property rights or licences to any artwork depicted by the
        non-fungible token. Accordingly, you agree to enter into this Agreement in order to be granted the right to use the Licensed NFT Artwork.
        Individuals and entities who subsequently become owners of Phantom Galaxies NFTs using a secondary marketplace that supports creator fees may
        enter into this Agreement by inserting their electronic signature, subject to the verification of ownership of one or more Phantom Galaxies
        NFTs through a process specified by the Creator in its sole discretion.
      </p>
      <br className={'break-between-chapters'} />
      <p className={'license-header'}>WHEREAS:</p>
      <br className={'break-between-paragraphs'} />
      <ol className={'license-ordered-list-upper-alpha'}>
        <li>
          The Smart Contract maintains a record of which digital wallet is the owner of a specific Phantom Galaxies NFT. Accordingly, when you
          purchase a Phantom Galaxies NFT on a secondary marketplace or receive a Phantom Galaxies NFT by way of a transfer, your ownership of that
          specific Phantom Galaxies NFT is recorded by the Smart Contract. Holding a Phantom Galaxies NFT grants you the non-exclusive right to hold,
          sell, transfer and carry out blockchain transactions involving that Phantom Galaxies NFT ("
          <span className={'license-bold'}>Your Phantom Galaxies NFT</span>").
        </li>
        <br className={'break-between-paragraphs'} />
        <li>
          The Creator retains absolute ownership of all intellectual property rights, title and interest in the Artwork of the Phantom Galaxies NFTs,
          including but not limited to copyrights, trade marks, goodwill and other intellectual property rights.
        </li>
        <br className={'break-between-paragraphs'} />
        <li>
          You are granted a license on the terms set out in this Agreement to use the Licensed NFT Artwork for as long as you own the relevant Phantom
          Galaxies NFT. As between you and the Creator, you own all rights, title and interest in and to any modifications, revisions, adaptations or
          transformations based upon Your Phantom Galaxies NFT during the Term (as defined below) ("
          <span className={'license-bold'}>Derivative Works</span>"), provided that (i) the Creator retains the ownership of all intellectual property
          rights in the Licensed NFT Artwork; or (ii) your use of any Derivative Works during and after the Term is subject to the terms of the
          License (as defined below).
        </li>
      </ol>
      <br className={'break-between-paragraphs'} />
      <p className={'license-header'}>You and the Creator agree as follows:</p>
      <ol className={'license-list-2'}>
        <li>
          License.
          <ol className={'license-sublist-alphabetic'}>
            <li>
              Subject to the terms of this Agreement and provided that you have acquired Your Phantom Galaxies NFT lawfully, the Creator hereby grants
              to you during the Term (as defined below) a non-exclusive, non-transferrable, sublicensable, universe-wide, royalty-free license to use
              the Licensed NFT Artwork in any and all formats and media, by any and all technologies and means of delivery, for any and all purposes
              whatsoever, including the commercialization (without limit), reproduction, distribution, preparation of the Derivative Works, public
              display, and otherwise use and exploit the Licensed NFT Artwork (the "<span className={'license-bold'}>License</span>").
            </li>
            <li>
              Except as expressly provided herein, ownership of a Phantom Galaxies NFT and the License are not separable in any way. You may not
              engage in any transaction or activity that purports to decouple the License from Your Phantom Galaxies NFT.
            </li>
            <li>
              The License is subject to, and conditional upon your continued compliance with, the following restrictions and qualifications:
              <ol className={'license-sublist-romans'}>
                <li>
                  The License extends only to the Licensed NFT Artwork. Therefore, while the License allows you to create and exploit the Derivative
                  Works, the License does not grant you rights in any individual element (such as the features, attributes, properties, or traits) of
                  Your Phantom Galaxies NFT or the Licensed NFT Artwork or a license to exploit any individual element separate and apart from Your
                  Phantom Galaxies NFT (such as the features, attributes, properties, traits and other elements of other Phantom Galaxies NFTs).
                </li>
                <li>
                  The License does not grant you any rights in or to any trade names, logos, brands or trade marks of the Creator or any of its
                  affiliates ("
                  <span className={'license-bold'}>Creator Brand IP Rights</span>
                  "), all of which are expressly reserved to the Creator and its respective affiliates. This means that to the extent that the
                  Licensed NFT Artwork contains any Creator Brand IP Rights, you will need to blur or edit out such Creator Brand IP Rights prior to
                  any use of the Licensed NFT Artwork unless you receive additional written permission from the Creator. You hereby agree that any
                  Creator Brand IP Rights you purport to acquire, together with any associated goodwill, shall automatically, immediately, and at your
                  expense be assigned to the Creator or an affiliate designated by the Creator.
                </li>
                <li>
                  he License does not grant you any rights to use the business name of "Phantom Galaxies" or any other business name of the Creator
                  and its affiliates, all of which are exclusively reserved to the Creator and its affiliates. The License does not grant you any
                  right to represent yourself as being associated with the Creator or any of its affiliates in any capacity. Specifically, nothing
                  contained in this Agreement will be construed as creating any agency, partnership, joint venture, or other form of joint enterprise,
                  employment, or fiduciary relationship between you and the Creator, and neither you nor the Creator will have authority to contract
                  for or bind the other in any manner whatsoever.
                </li>
                <li>
                  You may not use the Licensed NFT Artwork (i) on any physical or digital tobacco, pharmaceutical or regulated product; (ii) on any
                  physical or digital weapons or other items which may represent or are associated with war (iii) in a manner that expresses hate or
                  prejudice or encourages violence towards a person or group based on membership in a protected class, such as race, religion, gender,
                  orientation, or disability; or (iv) any manner which would bring the Creator into disrepute.
                </li>
                <li>You may not use the Licensed NFT Artwork in a manner that violates any applicable law.</li>
                <li>
                  While your right to use the Licensed NFT Artwork under the License is royalty-free, you agree to pay a creator fee (otherwise known
                  as a resale royalty) when you buy or sell the Phantom Galaxies NFTs. The Creator has selected 10% as the percentage of creator fees.
                  These creator fees are deducted each time a Phantom Galaxies NFT is sold on a secondary marketplace that supports such creator fees.
                  You undertake not to transfer your ownership in any of Your Phantom Galaxies NFTs in any manner which would purposefully result in
                  you failing to pay the creator fees in full. For the avoidance of doubt, you are not prohibited from gifting Your Phantom Galaxies
                  NFTs to another person or making a transfer that is not for the purpose of circumventing the payment of creator fees.
                </li>
                <li>
                  As a continuing condition of receiving the rights under this License, you agree to the collection and payment of creator fees to the
                  Creator when you buy and sell the NFT. You agree that you will not use Your Phantom Galaxies NFTs and the Licensed NFT Artwork in
                  any way that circumvents the payment of creator fees in the sale of Your Phantom Galaxies NFTs. You agree that you will not offer to
                  sell or sell Your Phantom Galaxies NFTs on any website, app, intermediary, marketplace, platform, or exchange (collectively,
                  “marketplace”) that does not collect or enforce creator fees or that makes creator fees optional or adopts a so-called “zero
                  royalty” approach. You agree that offering to sell or selling Your Phantom Galaxies NFTs on a marketplace that does not collect or
                  enforce creator fees or makes creator fees optional, or conducting a sale by any other manner (such as peer-to-peer transactions)
                  that circumvents the payment of creator fees, is not authorized by this License and constitutes a breach of this License.
                  Additionally, if you sell Your Phantom Galaxies NFTs on a marketplace that does not collect the creator fees, you agree to pay the
                  amount of creator fees to the Creator.
                </li>
                <li>
                  Your obligation to pay creator fees in the sale of Your Phantom Galaxies NFTs shall expire when the copyright term to the Artwork
                  expires.
                </li>
                <li>
                  The License does not permit you to (i) mint another non-fungible token using the Licensed NFT Artwork; (ii) use the Licensed NFT
                  Artwork in a manner which competes with the Creator; or (iii) in audiovisual media.
                </li>
                <li>All rights not expressly granted in this Agreement are reserved by the Creator.</li>
              </ol>
            </li>
          </ol>
        </li>

        <br className={'break-between-paragraphs'} />

        <li>
          Release.
          <ol className={'license-sublist-alphabetic'}>
            <li>
              The Creator acknowledges that not every owner of a Phantom Galaxies NFT will be aware of the need to enter into a license agreement with
              the Creator upon the acquisition of a Phantom Galaxies NFT. Accordingly, by entering into this Agreement, the Creator hereby releases
              you from all liability and obligations and irrevocably waives its right to make a claim against you in respect of any infringement of
              Creator Brand IP Rights in respect of the Licensed NFT Artwork, subject to the following conditions:
              <ol className={'license-sublist-romans'}>
                <li>
                  your use of the Licensed NFT Artwork must not have breached any term of this Agreement. In other words, the Creator will treat you
                  as if you entered into this Agreement upon you legally acquiring Your Phantom Galaxies NFT; and
                </li>
                <li>
                  the Creator’s release and waiver shall only apply in respect of the period commencing from the date you acquire Your Phantom
                  Galaxies NFT lawfully and ending immediately upon your transfer of Your Phantom Galaxies NFT to another person.
                </li>
              </ol>
              <li>
                The Creator agrees to carry out all such acts to give effect to the release and waiver set out in this Clause 2. Specifically, if the
                release set out in Clause 2 is insufficient under any laws applicable to you, the Creator shall execute in your favour a separate
                document giving effect to the release and waiver.
              </li>
            </li>
          </ol>
        </li>
        <li>
          Representations and warranties.
          <ol className={'license-sublist-alphabetic'}>
            <li>
              Each party represents and warrants to the other that it has the full right, power, and authority to enter into, perform, and grant the
              rights and licenses it grants and is required to grant under this Agreement.
            </li>
            <li>
              You hereby represent and warrant to the Creator that:
              <ol className={'license-sublist-romans'}>
                <li>you lawfully acquired Your Phantom Galaxies NFT;</li>
                <li>you paid the creator fees when acquiring Your Phantom Galaxies NFT;</li>
                <li>
                  you are the sole and absolute owner of the private keys to the wallet address set forth in your signature block below and, to the
                  extent that you co-own such wallet address with any other person(s), that you have the authority to enter into this Agreement on the
                  behalf of such other person(s);
                </li>
                <li>you will not use the Licensed NFT Artwork in any manner that is defamatory, obscene, or otherwise unlawful;</li>
                <li>
                  you will not use the Licensed NFT Artwork in any manner that is in contravention of any applicable law, this Agreement or any right
                  of the Creator or its affiliates, suggests sponsorship of or by, or association with, any third party;
                </li>
                <li>you will not use the Licensed NFT Artwork in any manner that is detrimental to the Creator or its affiliates;</li>
                <li>
                  you will not carry out any act to sell or otherwise dispose of your ownership of Your Phantom Galaxies NFT in a manner which
                  circumvents the requirement to pay the creator fees; and
                </li>
                <li>
                  you are not, and will not, knowingly execute a transaction involving a Phantom Galaxies NFT or the Licensed NFT Artwork with any
                  person who is (i) located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S.
                  Government as a terrorist-supporting country; or (ii) listed on any list of prohibited or restricted parties, whether on a list
                  maintained by the Office of Foreign Assets Control of the U.S. Department of the Treasury, the U.S. Department of State, the United
                  Nations Security Council, the European Union, any European Union member state, His Majesty’s Treasury of the United Kingdom or other
                  relevant sanctions authority.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          Indemnification, Disclaimers and Limitations of Liability.
          <ol className={'license-sublist-alphabetic'}>
            <li>You will have an obligation to cover all of the Creator’s costs arising from any material breach of this Agreement by you.</li>
            <li>
              As a separate obligation to that in Clause 4.1, you will defend, indemnify, and hold the Creator, its licensors, affiliates,
              representatives, and service providers, and each of them, and all of their respective officers, directors, employees, and agents (the
              "Indemnified Parties") harmless from and against any and all claims, damages, losses, investigations, liabilities, judgments, fines,
              penalties, settlements, interest, and other similar results or occurrences (including attorneys' fees) that directly or indirectly arise
              from, or are related to or in connection with, any claim, suit, action, demand, or proceeding or other similar occurrence, process, or
              activity that is initiated, made, brought, or financed by a third party (including any person who accesses or transacts using any
              Phantom Galaxies NFTs or artwork associated with Phantom Galaxies, whether or not such person personally purchased a Phantom Galaxies
              NFT) against the Indemnified Parties, or on account of the investigation, defence, or settlement thereof, arising out of related to, or
              in connection with: (a) your access to or use of any NFT marketplace or third-party services or products; (b) your breach or alleged
              breach of this Agreement; (c) your exercise or attempted exercise of the License; or (d) your actual or alleged violation of applicable
              law. Counsel to be used in the defence of such claim must be approved by the Creator in writing prior to retention of such counsel and,
              upon our request, you will allow us to participate in the defence of any such claims. You will not enter into any settlement or
              compromise of any claim or litigation or that includes an admission of liability without the Creator’s prior written consent.
            </li>
            <li>
              Each Phantom Galaxies NFT is an intangible digital asset that exists only by virtue of the ownership record maintained on the Polygon
              blockchain. Any transfer of ownership that might occur in any unique digital asset occurs on the decentralized ledger within the Polygon
              blockchain, which the Creator does not control.
            </li>
            <li>
              The Creator will not be responsible or liable to owner for any loss in connection with any Phantom Galaxies NFT or the Licensed NFT
              Artwork and takes no responsibility for, and will not be liable to owner for, any use of or inability to use any Phantom Galaxies NFT or
              the Licensed NFT Artwork for any reason.
            </li>
            <li>
              There are numerous risks associated with blockchain technology and the Phantom Galaxies NFTs. You should familiarize yourself with these
              risks and agree to assume these risks by owning a Phantom Galaxies NFT.
            </li>
            <li>
              To the maximum extent permitted by law, no Indemnified Party will be liable for any incidental, special, exemplary, or consequential
              damages, or damages for lost profits, lost revenues, lost savings, lost business opportunity, loss of data or goodwill, service
              interruption, computer damage, or system failure, or the cost of substitute services of any kind arising out of or in connection with
              these terms or from the use of or inability to use or interact with any Phantom Galaxies NFT or the Licensed NFT Artwork, whether based
              on warranty, contract, tort (including negligence), product liability, or any other legal theory, and whether or not the Creator or its
              service providers have been informed of the possibility of such damage, even if a limited remedy set forth herein is found to have
              failed of its essential purpose. Notwithstanding the foregoing, in no event will any Indemnified Party’s cumulative liability hereunder
              from all causes of action and all theories of liability exceed the price paid for Your Phantom Galaxies NFTs.
            </li>
          </ol>
        </li>
        <li>
          Term and Termination.
          <ol className={'license-sublist-alphabetic'}>
            <li>
              This Agreement is effective commencing from the date that you enter into this Agreement and shall automatically expire immediately after
              your disposal (including by way of sale or transfer) of Your Phantom Galaxies NFT (as recorded by the Smart Contract) ("
              <span className={'license-bold'}>Term</span>").
            </li>
            <li>
              Upon the termination or expiration of this Agreement: (a) if you have complied with the conditions of the License, including the
              obligation to the payment of creator royalties, the subsequent acquirer of Your Phantom Galaxies NFT shall receive the same rights under
              this Agreement to the extent permitted under applicable law; (b) if you have not complied with the conditions of the License, all rights
              licensed under this Agreement will revert to the Creator and you will be prohibited from any further use of the Licensed NFT Artwork;
              (c) you may retain any tangible property using the Licensed NFT Artwork for personal use only; and (d) all sublicenses that have been
              granted by you shall automatically and immediately terminate. If, during the Term, you create and make available to the public a
              Derivative Work, you may continue to use and exploit that Derivative Work in accordance with this Agreement after the Term; provided
              however that: (i) you will be responsible for any obligations or liabilities arising from your continued use of the Derivative Work
              after the Term; and (ii) this privilege does not allow you to use the Licensed NFT Artwork to create any new works or materials after
              the Term.
            </li>
          </ol>
        </li>
        <li>
          Non-Compliance with this Agreement.
          <ol className={'license-sublist-alphabetic'}>
            <li>
              If you materially breach any term of this Agreement, or if the Creator discovers that you acquired Your Phantom Galaxies NFT(s)
              unlawfully or you acquired Your Phantom Galaxies NFT(s) and failed to pay creator fees, the Creator may seek appropriate measures and
              remedies, including but not limited to any one or more of the following:
              <ol className={'license-sublist-romans'}>
                <li>
                  terminating this Agreement with immediate effect, provided that the Creator may allow you to cure such breach within a specified
                  timeframe;
                </li>
                <li>
                  restricting you from enjoying or participating in any future benefit or gathering utility of the community for Your Phantom Galaxies
                  NFTs (including, without limitation, airdrops and events conducted for the community), although, for the avoidance of doubt, the
                  Creator has no obligation to provide any future benefit or gathering to the community or you;
                </li>
                <li>
                  requiring a marketplace to remove or disable access to the infringing uses of the Licensed NFT Artwork displayed on a marketplace,
                  platform or other Internet service provider by way of a Digital Millennium Copyright Act notice or a takedown notice in accordance
                  with applicable copyright or other laws.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          Miscellaneous.
          <ol className={'license-sublist-alphabetic'}>
            <li>
              Recognised Blockchain. The License applies only to the Phantom Galaxies NFTs on the blockchain that the Creator, in its sole discretion,
              may designate, which designation shall apply retroactively. Thus, for example, if a fork or other event purports to result in duplicate
              Phantom Galaxies NFTs, only the Phantom Galaxies NFTs recorded on the blockchain designated by the Creator will be eligible to receive
              the benefit of the License. Any license purportedly granted hereunder to the owner of a non-fungible token recorded on a blockchain not
              designated by the Creator is void ab initio.
            </li>
            <li>
              Assignment. This Agreement is personal to you. Unless otherwise provided in this Agreement, you may not assign or otherwise transfer any
              of its rights, delegate, or otherwise transfer any of its obligations or performance, under this Agreement. Any purported assignment,
              delegation, or transfer in violation of this Clause is void. The Creator may freely assign or otherwise transfer all or any of its
              rights, or delegate or otherwise transfer all or any of its obligations or performance, under this Agreement. This Agreement is binding
              on and inures to the benefit of the parties hereto and their respective permitted successors and assigns.
            </li>
            <li>
              Waiver. No waiver by any party of any of the provisions hereof will be effective unless explicitly set forth in writing and signed by
              the party so waiving. No waiver by any party will operate or be construed as a waiver of any failure, breach, or default not expressly
              identified by such written waiver, whether of a similar or different character, and whether occurring before or after that waiver. No
              failure to exercise, or delay in exercising, any right, remedy, power, or privilege arising from this Agreement will operate or be
              construed as a waiver thereof; nor will any single or partial exercise of any right, remedy, power, or privilege hereunder preclude any
              other or further exercise thereof or the exercise of any other right, remedy, power, or privilege.
            </li>
            <li>
              Governing Law. This Agreement shall be governed by and construed in accordance with the laws of the State of New York, without regard to
              its principles on the conflicts of laws.
            </li>
            <li>
              Dispute Resolution. Each party to this Agreement hereby irrevocably and unconditionally submits, for itself and its property, to the
              nonexclusive jurisdiction of any New York State court or federal court of the United States of America sitting in New York City, and any
              appellate court from any thereof, in any action or proceeding arising out of or relating to this Agreement, or for recognition or
              enforcement of any judgement, and each of the parties hereto hereby irrevocably and unconditionally agrees that all claims in respect of
              any such action or proceeding may be heard and determined in such New York State or, to the extent permitted by law, in such federal
              court.
            </li>
            <li>
              Entire Agreement, Severance. This Agreement, including and together with any related attachments, is the sole and entire agreement of
              the parties with respect to the subject matter herein, and supersedes all prior and contemporaneous understandings, agreements,
              representations, and warranties, whether written or oral, regarding such subject matter. If any term or provision of this Agreement is
              invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability will not affect any other term
              or provision of this Agreement or invalidate or render unenforceable such term or provision in any other jurisdiction.
            </li>
            <li>
              Amendment. The Creator reserves the right to alter, revise, modify or otherwise change the terms of this Agreement at any time. Any
              changes will take effect immediately upon being published on the Creator's website. It is your responsibility to check the Creator's
              website regularly for any updates. If, upon reviewing the revisions to this Agreement, you do not accept such changes, then you must
              cease utilising any of the rights granted under this Agreement.
            </li>
          </ol>
        </li>
      </ol>
      <br className={'break-between-paragraphs'} />

      <p>Disclaimer</p>
      <br className={'break-between-paragraphs'} />
      <p>
        This disclaimer does not form part of the License. None of Animoca Brands Limited or any of its affiliates (collectively, "
        <span className={'license-bold'}>Animoca Brands</span>") is not a party to this Agreement and assumes no responsibility or duty (such as a
        duty of care or fiduciary duty or otherwise) towards any of the parties to this Agreement. No relationship is created between any such party
        and Animoca Brands, including but not limited to lawyer-client relationship or other relationship with Animoca Brands. Animoca Brands makes no
        warranties about the license granted under this Agreement and disclaims all liability for damages resulting from the use of the License as
        permitted under applicable law. Animoca Brands does not authorize the use of any of its trademark, logo, or any other trademark in the use of
        the License. Animoca Brands disclaims all liability for damages resulting from their use to the fullest extent possible.
      </p>
      <br className={'break-between-paragraphs'} />
      <p>
        By inserting an electronic signature below, you, being the sole and absolute owner of the private keys to the wallet address{' '}
        {walletContext.currentWallet}, hereby agree to the terms of this Agreement.
      </p>
    </>
  ),
};

export const claimEpisode1 = {
  Note: (
    <>
      <b>Note: </b>Confirming this action will remove the Poster 1 NFT from your wallet, burning it and replacing it with the <b>Redeemed</b> Poster 1
      NFT.
    </>
  ),
  processDescription: <>This process may take a few minutes.</>,
  agreement: <>Do you wish to proceed?</>,
  firstMintMessage: (
    <>
      <b>Note: </b>As this is the first time using the minting process, there will be <b>2 wallet transactions</b> to approve.
    </>
  ),
  episode: (
    <>
      Episode 1:
      <br />
      Halberd-001
    </>
  ),
  Badge: (
    <>
      Episode 1:
      <br />
      In-Game
      <br />
      Completion Badge
      <br />
    </>
  ),
  Redeemed: (
    <>
      Episode 1:
      <br /> Halberd-001 Redeemed
    </>
  ),
  Medal: (
    <>
      Episode 1:
      <br /> Episode Medal
    </>
  ),
};

export const claimEpisode2 = {
  Note: (
    <>
      <b>Note: </b>Confirming this action will remove the Poster 2 NFT from your wallet, burning it and replacing it with the <b>Redeemed</b> Poster 2
      NFT.
    </>
  ),
  processDescription: <>This process may take a few minutes.</>,
  agreement: <>Do you wish to proceed?</>,
  firstMintMessage: (
    <>
      <b>Note: </b>As this is the first time using the minting process, there will be <b>2 wallet transactions</b> to approve.
    </>
  ),
  episode: (
    <>
      Episode 2:
      <br />
      Shining Star
    </>
  ),
  Badge: (
    <>
      Episode 2:
      <br />
      In-Game
      <br />
      Completion Badge
      <br />
    </>
  ),
  Redeemed: (
    <>
      Episode 2:
      <br /> Shining Star Redeemed
    </>
  ),
  Medal: (
    <>
      Episode 2:
      <br /> Episode Medal
    </>
  ),
};

// TODO: Fill out with episode 3 details once available
export const claimEpisode3 = {
  Note: (
    <>
      <b>Note: </b>Confirming this action will remove the Poster 3 NFT from your wallet, burning it and replacing it with the <b>Redeemed</b> Poster 3
      NFT.
    </>
  ),
  processDescription: <>This process may take a few minutes.</>,
  agreement: <>Do you wish to proceed?</>,
  firstMintMessage: (
    <>
      <b>Note: </b>As this is the first time using the minting process, there will be <b>2 wallet transactions</b> to approve.
    </>
  ),
  episode: (
    <>
      Episode 3:
      <br />
      Thermopylae
    </>
  ),
  Badge: (
    <>
      Episode 3:
      <br />
      In-Game
      <br />
      Completion Badge
      <br />
    </>
  ),
  Redeemed: (
    <>
      Episode 3:
      <br /> Thermopylae Redeemed
    </>
  ),
  Medal: (
    <>
      Episode 3:
      <br /> Episode Medal
    </>
  ),
};

// TODO: Fill out with episode 4 details once available
export const claimEpisode4 = {
  Note: (
    <>
      <b>Note: </b>Confirming this action will remove the Poster 4 NFT from your wallet, burning it and replacing it with the <b>Redeemed</b> Poster 4
      NFT.
    </>
  ),
  processDescription: <>This process may take a few minutes.</>,
  agreement: <>Do you wish to proceed?</>,
  firstMintMessage: (
    <>
      <b>Note: </b>As this is the first time using the minting process, there will be <b>2 wallet transactions</b> to approve.
    </>
  ),
  episode: (
    <>
      Episode 4:
      <br />
      Xanorra Slave Barge
    </>
  ),
  Badge: (
    <>
      Episode 4:
      <br />
      In-Game
      <br />
      Completion Badge
      <br />
    </>
  ),
  Redeemed: (
    <>
      Episode 4:
      <br /> Xanorra Slave Barge Redeemed
    </>
  ),
  Medal: (
    <>
      Episode 4:
      <br /> Episode Medal
    </>
  ),
};

export const PlanetGenesisSaleText = {
  title: <>PLANET GENESIS SALE</>,
  paragraph1: (
    <>
      Own a piece of Phantom Galaxies property, on a planetary scale. Purchase one of the gargantuan planets that loom amidst the stars in the
      upcoming Public Planet Sale. Each planet has unique coordinates and traits, from humble pebbles dwarfed by the vastness of space, to immense
      monoliths that can host thousands.
    </>
  ),
  paragraph2: (
    <>
      Planet owners have a wealth of customization options. Govern your planet with a rigid dictatorship, or share power within a republic. Dot the
      surface with hangars and marketplaces, residential units for guild members, and refineries to harvest minerals.
    </>
  ),
  paragraph3: (
    <>
      Develop your planet with buildings, draw in visitors, and engage in the wider game by completing missions, to earn a multiplier on your emission
      of PGToken up to a set maximum.
    </>
  ),
  paragraph4: <>Holders of Presale Ticket A or Presale Ticket B will have access to a Presale before the upcoming Public Planet Sale.</>,
  paragraph5_verify: ENABLE_KYC_PAGE ? (
    <>
      In order to participate in the Planet Sale, you will need to be registered and complete a KYC (Know Your Client) check to verify your identity.
      The KYC process helps to prevent money laundering, and is a mandatory process required by many countries.
    </>
  ) : (
    <></>
  ),
};

export const PlanetPrivateSaleText = {
  title: <>PLANET GENESIS SALE</>,
  paragraph1: (
    <>
      Own a piece of Phantom Galaxies property, on a planetary scale. Purchase one of the gargantuan planets that loom amidst the stars in the Private
      Planet Sale, accessible to whitelisted participants only. Each planet has unique coordinates and traits, from humble pebbles dwarfed by the
      vastness of space, to immense monoliths that can host thousands.
    </>
  ),
  paragraph2: (
    <>
      Planet owners have a wealth of customization options. Govern your planet with a rigid dictatorship, or share power within a republic. Dot the
      surface with hangars and marketplaces, residential units for guild members, and refineries to harvest minerals.
    </>
  ),
  paragraph3: (
    <>
      Develop your planet with buildings, draw in visitors, and engage in the wider game by completing missions, to earn a multiplier on your emission
      of PGToken up to a set maximum.
    </>
  ),
};

export const PrivateSaleText = {
  title: <>PRIVATE SALE</>,
};

export const ConnectWalletText = {
  title: (
    <>
      Please connect your wallet
      <br />
      to view the sale.
    </>
  ),
};

export const ConnectWalletAstrafite = {
  title: (
    <>
      Please connect your wallet
      <br />
      to Astrafite Rush Event.
    </>
  ),
};

export const ConnectWalletAFRClaim = {
  title: (
    <>
      Please connect your wallet
      <br />
      to claim rewards.
    </>
  ),
};

export const confirmationModal = {
  transaction: (
    <>
      Your transaction is now pending on the <br /> blockchain.
    </>
  ),
};

export const CurrencyModalText = {
  description: <>You are about to purchase</>,
  paymentDetails: (
    <>
      Please select which currency you wish to
      <br /> purchase with.
    </>
  ),
};

export const colors = {
  cherry: '#801433',
  sandbrown: '#6f3f1f',
};

export const menuValues = [];

export const NFTsName = {
  LargePlanet: 'Large Planet',
  MediumPlanet: 'Medium Planet',
  SmallPlanet: 'Small Planet',
  LargeAsteroid: 'Large Asteroid',
  SmallAsteroid: 'Small Asteroid',
};

menuValues.push(
  {
    id: 0,
    title: 'MINT NFTS',
    link: 'nft',
  },
  {
    id: 1,
    title: 'PLANET GENESIS SALE',
    link: 'planet-sale',
  },
);

ENABLE_KYC_PAGE &&
  menuValues.push({
    id: 2,
    title: 'KYC',
    link: 'KYC',
  });

ENABLE_PRIVATE_PLANET_SALE &&
  menuValues.push({
    id: 1,
    title: 'PRIVATE PLANET SALE',
    link: 'PlanetSalePagePrivate',
  });

export const privateSaleErrorBoxText = {
  text: (
    <>
      Unfortunately, you do not have access to this
      <br /> private sale.
    </>
  ),
  publicSaleText: <>Unfortunately, you do not have access to this sale!</>,
};

export const buyOnOpenSea = {
  episode1PosterNFT: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/1',
  episode1Medal: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/101',
  episode2PosterNFT: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/2',
  episode2Medal: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/102',
  episode3PosterNFT: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/3',
  episode3Medal: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/103',
  episode4PosterNFT: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/4',
  episode4Medal: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/104',
  saleA: `https://opensea.io/assets/matic/${process.env.REACT_APP_PLANET_SALE_PGERC1155_ADDRESS}/${process.env.REACT_APP_PLANET_SALE_TOKEN_ID_PRESALE_A}`,
  saleB: `https://opensea.io/assets/matic/${process.env.REACT_APP_PLANET_SALE_PGERC1155_ADDRESS}/${process.env.REACT_APP_PLANET_SALE_TOKEN_ID_PRESALE_B}`,
  pristineMintPassFirstClass: 'https://opensea.io/assets/matic/0x97e114d21063a331312BeA8Cf00Db2b580238003/1',
  veteranMintPassFirstClass: 'https://opensea.io/assets/matic/0x97e114d21063a331312BeA8Cf00Db2b580238003/2',
  zvetaGeneMintPassFirstClass: 'https://opensea.io/assets/matic/0x97e114d21063a331312BeA8Cf00Db2b580238003/3',
  pristineMintPassSecondClass: 'https://opensea.io/assets/matic/0x97e114d21063a331312BeA8Cf00Db2b580238003/11',
  veteranMintPassSecondClass: 'https://opensea.io/assets/matic/0x97e114d21063a331312BeA8Cf00Db2b580238003/12',
  pristineMintPassThirdClass: 'https://opensea.io/assets/matic/0x97e114d21063a331312BeA8Cf00Db2b580238003/21',
  veteranMintPassThirdClass: 'https://opensea.io/assets/matic/0x97e114d21063a331312BeA8Cf00Db2b580238003/22',
  standardIssueFleetMintPass: 'https://opensea.io/assets/matic/0x97e114d21063a331312BeA8Cf00Db2b580238003/100',
  zvetaGeneMintPassSecondClass: 'https://opensea.io/assets/matic/0x97e114d21063a331312BeA8Cf00Db2b580238003/13',
  standardIssueMintPass: 'https://opensea.io/assets/matic/0x97e114d21063a331312BeA8Cf00Db2b580238003/100',

  zvetaGeneMintPassThirdClass: 'https://opensea.io/assets/matic/0x97e114d21063a331312BeA8Cf00Db2b580238003/23',
  unspecifiedMintPass: 'https://opensea.io/assets/matic/0x97e114d21063a331312BeA8Cf00Db2b580238003/200',

  smallAsteroidMintpass: 'https://opensea.io/assets/matic/0x0686113b494851402389e6371ca7c76da4ed0875/100',
  largeAsteroidMintpass: 'https://opensea.io/assets/matic/0x0686113b494851402389e6371ca7c76da4ed0875/101',
  smallPlanetMintpass: 'https://opensea.io/assets/matic/0x0686113b494851402389e6371ca7c76da4ed0875/102',
  mediumPlanetMintpass: 'https://opensea.io/assets/matic/0x0686113b494851402389e6371ca7c76da4ed0875/103',
  largePlanetMintpass: 'https://opensea.io/assets/matic/0x0686113b494851402389e6371ca7c76da4ed0875/104',
  gen2SmallAsteroidMintpass: 'https://opensea.io/assets/matic/0x0686113b494851402389e6371ca7c76da4ed0875/200',
  gen2LargeAsteroidMintpass: 'https://opensea.io/assets/matic/0x0686113b494851402389e6371ca7c76da4ed0875/201',
  gen2SmallPlanetMintpass: 'https://opensea.io/assets/matic/0x0686113b494851402389e6371ca7c76da4ed0875/202',
  gen2MediumPlanetMintpass: 'https://opensea.io/assets/matic/0x0686113b494851402389e6371ca7c76da4ed0875/203',
  gen2LargePlanetMintpass: 'https://opensea.io/assets/matic/0x0686113b494851402389e6371ca7c76da4ed0875/204',
  planetCatalystCollection: 'https://opensea.io/collection/phantom-galaxies-phantom-catalysts',
  avatarZvetaFirstClass: 'https://opensea.io/assets/matic/0x97e114d21063a331312bea8cf00db2b580238003/3',
  avatarZvetaSecondClass: 'https://opensea.io/assets/matic/0x97e114d21063a331312bea8cf00db2b580238003/13',
  avatarZvetaThirdClass: 'https://opensea.io/assets/matic/0x97e114d21063a331312bea8cf00db2b580238003/23',
  avatarElpisFirstClass: 'https://opensea.io/assets/matic/0x97e114d21063a331312bea8cf00db2b580238003/4',
  avatarStandardAvatar: 'https://opensea.io/assets/matic/0x97e114d21063a331312bea8cf00db2b580238003/200',
};

export const PLANET_MINTPASSES = [
  {
    tokenId: 100,
    name: 'Small Asteroid',
    image: small_asteroid,
    acceptsCelestialBodies: false,
    openseaUrl: buyOnOpenSea.smallAsteroidMintpass,
  },
  {
    tokenId: 101,
    name: 'Large Asteroid',
    image: large_asteroid,
    acceptsCelestialBodies: false,
    openseaUrl: buyOnOpenSea.largeAsteroidMintpass,
  },
  {
    tokenId: 102,
    name: 'Small Planet',
    image: small_planet,
    acceptsCelestialBodies: true,
    openseaUrl: buyOnOpenSea.smallPlanetMintpass,
  },
  {
    tokenId: 103,
    name: 'Medium Planet',
    image: medium_planet,
    acceptsCelestialBodies: true,
    openseaUrl: buyOnOpenSea.mediumPlanetMintpass,
  },
  {
    tokenId: 104,
    name: 'Large Planet',
    image: large_planet,
    acceptsCelestialBodies: true,
    openseaUrl: buyOnOpenSea.largePlanetMintpass,
  },
  {
    tokenId: 200,
    name: 'Small Asteroid',
    image: small_asteroid,
    acceptsCelestialBodies: false,
    openseaUrl: buyOnOpenSea.gen2SmallAsteroidMintpass,
  },
  {
    tokenId: 201,
    name: 'Large Asteroid',
    image: large_asteroid,
    acceptsCelestialBodies: false,
    openseaUrl: buyOnOpenSea.gen2LargeAsteroidMintpass,
  },
  {
    tokenId: 202,
    name: 'Small Planet',
    image: small_planet,
    acceptsCelestialBodies: true,
    openseaUrl: buyOnOpenSea.gen2SmallPlanetMintpass,
  },
  {
    tokenId: 203,
    name: 'Medium Planet',
    image: medium_planet,
    acceptsCelestialBodies: true,
    openseaUrl: buyOnOpenSea.gen2MediumPlanetMintpass,
  },
  {
    tokenId: 204,
    name: 'Large Planet',
    image: large_planet,
    acceptsCelestialBodies: true,
    openseaUrl: buyOnOpenSea.gen2LargePlanetMintpass,
  },
];

export const AVATAR_MINTPASSES = [
  {
    tokenId: 3,
    name: 'Zveta Gene Sample Mintpass: First Class',
    image: zveta_first_class_mintpass,
    acceptsCelestialBodies: false,
    openseaUrl: buyOnOpenSea.avatarElpisFirstClass,
  },
  {
    tokenId: 13,
    name: 'Zveta Gene Sample Mintpass: Second Class',
    image: zveta_second_class_mintpass,
    acceptsCelestialBodies: false,
    openseaUrl: buyOnOpenSea.avatarZvetaSecondClass,
  },
  {
    tokenId: 4,
    name: 'Elpis Gene Sample Mintpass: First Class',
    image: elpis_first_class_mintpass,
    acceptsCelestialBodies: true,
    openseaUrl: buyOnOpenSea.avatarElpisFirstClass,
  },
  {
    tokenId: 23,
    name: 'Zveta Gene Sample Mintpass: Third Class',
    image: zveta_third_class_mintpass,
    acceptsCelestialBodies: true,
    openseaUrl: buyOnOpenSea.avatarZvetaThirdClass,
  },
  {
    tokenId: 200,
    name: 'Unspecified Origin Avatar Mintpass',
    image: standard_issue_mintpass,
    acceptsCelestialBodies: true,
    openseaUrl: buyOnOpenSea.avatarStandardAvatar,
  },
];

export const agreement = {
  Yes: 'YES',
  No: 'NO',
};

export const socialIconDropdown = [
  { text: 'BLOG', image: medium_logo, link: 'https://medium.com/@PhantomGalaxies' },
  { text: 'DISCORD', image: discord_logo, link: 'https://discord.com/invite/PhantomGalaxies' },
  { text: 'TWITTER', image: x_logo, link: 'https://twitter.com/the_phantom_g' },
  { text: 'TWITCH', image: twitch_logo, link: 'https://www.twitch.tv/blowfish_studios' },
  { text: 'YOUTUBE', image: youtube_logo, link: 'https://www.youtube.com/PhantomGalaxies' },
  { text: 'REDDIT', image: reddit_logo, link: 'https://www.reddit.com/r/PhantomGalaxiesGame/' },
  { text: 'INSTAGRAM', image: instagram_logo, link: 'https://www.instagram.com/phantomgalaxiesgame/' },
  { text: 'TELEGRAM', image: fa_paper_plane_icon_s, link: 'https://t.me/PhantomGalaxies' },
  { text: 'FACEBOOK', image: facebook_logo, link: 'https://www.facebook.com/PhantomGalaxies' },
  { text: 'TIKTOK', image: tiktok_logo, link: 'https://www.tiktok.com/@phantomgalaxies' },
  { text: 'EMAIL US', image: email_logo, link: '#' },
];

export const portfolioDropdown = [
  {
    text: 'PLANETS',
    children: [
      { text: 'GENERATE PLANETS', link: '/portfolio/mint-planets' },
      { text: 'THE CERES QUADRANT', link: '/portfolio/tcq-recon' },
      { text: 'PLANET DASHBOARD', link: '/portfolio/planets/dashboard' },
    ],
  },
  {
    text: 'POSTERS',
    link: '/nft',
  },
  {
    text: 'PILOTS',
    link: '/portfolio/pilots',
    children: [
      {
        text: 'BUY PILOTS',
        link: '/portfolio/pilots',
      },
      {
        text: 'GENERATE PILOTS',
        link: '/portfolio/mint-pilots',
      },
      {
        text: 'PILOT DASHBOARD',
        link: '/portfolio/pilots/dashboard',
      },
    ],
  },
  {
    text: 'STARFIGHTERS',
    link: '/portfolio/starfighters',
    children: [
      {
        text: 'BUY STARFIGHTERS',
        link: '/portfolio/starfighters',
      },
      {
        text: 'GENERATE STARFIGHTERS',
        link: '/portfolio/mint-starfighters',
      },
      {
        text: 'STARFIGHTERS DASHBOARD',
        link: '/portfolio/starfighters/dashboard',
      },
    ],
  },
  {
    text: 'CURRENCY',
    link: '/portfolio/currency',
  },
];

export const eventsDropdown = [
  { text: 'ASTRAFITE RUSH', link: '/astrafite-rush-event' },
  { text: 'THE CERES QUADRANT', link: '/portfolio/tcq-recon' },
];

export const astraferDropdown = [
  {
    text: 'TOKEN',
    link: tokenPage,
  },
  {
    text: 'LITEPAPER',
    link: litepaperPath,
  },
  {
    text: 'BRIDGE',
    link: '/bridge',
  },
];

export const socialIconPaths = {
  discord: 'https://discord.gg/PhantomGalaxies',
  telegram: 'https://t.me/PhantomGalaxies',
  twitter: 'https://twitter.com/the_phantom_g',
  instagram: 'https://www.instagram.com/phantomgalaxiesgame/',
  facebook: 'https://www.facebook.com/PhantomGalaxies',
  twitch: 'https://www.twitch.tv/blowfish_studios',
  medium: 'https://medium.com/@PhantomGalaxies',
  youtube: 'https://www.youtube.com/PhantomGalaxies',
};

export const contactEmail = {
  email: 'dpo-pg@blowfishstudios.com',
};

export const pgLinks = {
  walletConnect: 'https://walletconnect.com/',
  metamask: 'https://metamask.io/ ',
  coinbase: 'https://www.coinbase.com/wallet',
  originSeries: 'https://opensea.io/collection/phantom-galaxies-origin-collection',
  zorransFavour: 'https://opensea.io/assets/matic/0x4c60857bb2a0cd603c97b58881d2500f3cf019f6/2',
  planetMintpass:
    'https://opensea.io/collection/phantom-galaxies-planet-genesis-series?search[stringTraits][0][name]=Type&search[stringTraits][0][values][0]=Planet%20Genesis%20Mintpass',
  theHopeful: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/100',
  mediumPost: 'https://medium.com/@PhantomGalaxies/closed-development-beta-cohort-1-friends-of-zorran-will-begin-soon-7151d1791967',
  smallAsteroid: 'https://opensea.io/collection/phantom-galaxies-small-asteroid-genesis',
  largeAsteroid: 'https://opensea.io/collection/phantom-galaxies-large-asteroid-genesis',
  smallPlanet: 'https://opensea.io/collection/phantom-galaxies-small-planet-genesis',
  mediumPlanet: 'https://opensea.io/collection/phantom-galaxies-medium-planet-genesis',
  largePlanet: 'https://opensea.io/collection/phantom-galaxies-large-planet-genesis',
  avatarOrStarfighterMintpass: 'https://opensea.io/collection/phantom-galaxies-avatar-and-starfighter-genesis',
  astraferHolder: 'https://phantomgalaxies.com/token',
};

export const downloadDescriptions = {
  top: (
    <div>
      <p>Phantom Galaxies is free-to-play and can be downloaded directly from this page by following the steps below.</p>
      <br />
      <p>
        You can download and play Phantom Galaxies directly on Steam (Non-Web3), Epic Games (Web3) and HyperPlay (Web3) by clicking on the links
        below.
      </p>
      <br />
      <div className={'download-page__button-container'}>
        <a href={'https://store.steampowered.com/app/1272550/Phantom_Galaxies/'} target="_blank" rel="noreferrer" className={'astrafer-mint-button'}>
          <img src={steam_logo} alt={'steam logo'} />
          DOWNLOAD ON STEAM
        </a>
        <a href={'https://store.epicgames.com/p/phantom-galaxies-8f4ca5'} target="_blank" rel="noreferrer" className={'astrafer-mint-button'}>
          <img src={epic_games_logo} alt={'epic games logo'} />
          DOWNLOAD ON EPIC
        </a>
        {/*<a href={'https://store.hyperplay.xyz/game/phantomgalaxies'} target="_blank" rel="noreferrer" className={'astrafer-mint-button download-hyper-play__button'}>*/}
        {/*  <img src={download_hyper_play} alt={'download on hyper play badge'} />*/}
        {/*</a>*/}
        <a href={'https://store.hyperplay.xyz/game/phantomgalaxies'} target="_blank" rel="noreferrer" className={'astrafer-mint-button'}>
          <div className={'download-button-container hyper-play download-hyper-play'}></div>
        </a>
      </div>
      <br />

      <p>
        An access code is required for our Launcher, and can also be used with the Epic Games Launcher and HyperPlay Launcher. Generating an access
        code is free, but you will need to own one of the following wallets:
      </p>
      <ul>
        {/* <li><a href={pgLinks.walletConnect} target={'_blank'} rel={'noreferrer'}>Wallet Connect</a></li> */}
        <li>
          <a href={pgLinks.metamask} target={'_blank'} rel={'noreferrer'}>
            MetaMask
          </a>
        </li>
        <li>
          <a href={pgLinks.coinbase} target={'_blank'} rel={'noreferrer'}>
            Coinbase Wallet
          </a>
        </li>
        <li>
          <a href={pgLinks.walletConnect} target={'_blank'} rel={'noreferrer'}>
            Wallet Connect
          </a>
        </li>
      </ul>
      <p>
        Connect your wallet to the website using the 'Login' page. This may raise a series of popups from your wallet of choice - please follow these
        carefully.
      </p>
      <br />
      <p>
        Then, click 'Generate Access Code'. After your wallet has been connected and your access code has been generated successfully, buttons to
        download will appear.
      </p>
    </div>
  ),
  bottom: (
    <>
      <p>
        Run the launcher.
        <ul>
          <li>Depending on your Windows settings, you may have to 'Run as administrator'.</li>
          <li>If prompted with a 'Windows protected your PG' prompt, please select 'More info' → 'Run anyway.'</li>
          <li>If prompted with a 'Windows Firewall' prompt, please select 'Allow access.'</li>
        </ul>
      </p>
      <br />
      <p>
        The launcher will download and install the game.
        <ul>
          <li>If prompted with a 'Windows change notice' prompt, please select 'Allow.'</li>
        </ul>
      </p>
      <br />
      <p>Once installed, select 'Play Phantom Galaxies.'</p>
      <br />
      <p>On load, you will be prompted to enter your unique access code. Please paste your access code into the prompt and hit 'Enter.'</p>
      <br />
      <p>You are now ready to enter the world of Phantom Galaxies!</p>
    </>
  ),
};
export const footerLinks = {
  animoca: 'https://www.animocabrands.com/',
  blowFish: 'https://www.blowfishstudios.com/',
};

export const legalAndPolicyLinks = {
  privacyPolicy: 'https://phantomgalaxies.com/PrivacyPolicy.pdf',
  cookiePolicy: 'https://phantomgalaxies.com/CookiePolicy.pdf',
  termsAndConditions: 'https://phantomgalaxies.com/TermsAndConditions.pdf',
  termsAndConditionsAfr: 'https://phantomgalaxies.com/TermsAndConditionsAFR.pdf',
  credits: '/Credits.pdf',
};

export const astrafiteEventNotStarted = {
  paragraph1: (
    <div>
      The <b>ASTRAFITE RUSH EVENT</b> event will track two activities: mining Astrafite and defeating Fire Dancer pirates. Both activities will
      contribute to Goals.
    </div>
  ),
  paragraph2: (
    <>
      The Event will take place across four weekly ‘Cycles’, each lasting four days. The locations of the Astrafite asteroids and Fire Dancer pirates
      will change with each Cycle.
    </>
  ),
  paragraph3: (
    <>
      You will have Personal Goals to work towards as an individual, as well as Community Goals shared amongst all players who are active during the
      Event. Completing a Personal Goal will increase your chance to earn rewards, while if a Community Goal is completed, all active players will
      have the chance to benefit.
    </>
  ),
  paragraph4: (
    <>
      The Event will take place across four weekly ‘Cycles’, each lasting four days. The locations of the Astrafite asteroids and Fire Dancer pirates
      will change with each Cycle.
    </>
  ),
  paragraph5: (
    <>There will be two ‘tracks’ of rewards - one for players who own Planets, one for players who do not. Further details about rewards below.</>
  ),
};
export const astrafiteEventStarted = {
  paragraph1: (
    <>
      The Event will take place across four weekly ‘Cycles’, each lasting four days. The locations of the Astrafite asteroids and Fire Dancer pirates
      will change with each Cycle.
    </>
  ),
  contributors: <>Number of Wallets contributed in event:</>,
  numberOfContributors: <>100000</>,
};

export function MintpassAstraferText(astraferAmount, genesisNftTitle) {
  return {
    note: (
      <p className={'mintpass-astrafer-text'}>
        You will be using {astraferAmount} ASTRAFER to mint a <br />
        <span className={'bold'}> {genesisNftTitle} </span> NFT.
      </p>
    ),
  };
}

export function astrafiteRushEvent(onHover) {
  return {
    descriptionTextLeft: (
      <p>
        Planet Holders will receive ASTRAFER if they rank in the top 10,000 on the leaderboard, based on their final position - the amount of tokens
        will be multiplied based on the{' '}
        <span
          onMouseEnter={() => {
            onHover(true);
          }}
          onMouseLeave={() => {
            onHover(false);
          }}
          style={{ textDecoration: 'underline' }}
          className={'planets-own-text'}
        >
          Planet(s) they own
        </span>
        . If a wallet holds multiple Planets, the multipliers are added together before being applied.
        <br />
        <br />
        There is also a wallet cap for all Planet Holders, based on where the player finishes on the leaderboard in a Cycle. A Planet Holder may hold
        enough Planets to gain a significant multiplier - however, their rewards will be limited by their wallet cap.
        <br />
        <br />
        Planet Holders who rank in the top 10,000 on the leaderboard, will also earn a bonus of 5 ASTRAFER each Cycle, if the Community Goals are
        reached.
      </p>
    ),

    descriptionTextRight: (
      <>
        All players who rank in the top 10,000 on the leaderboard will receive ASTRAFER, based on their final position.
        <br />
        <br />
        All players who rank in the top 10,000 on the leaderboard will also receive a bonus of 5 ASTRAFER each Cycle if the Community Goals are
        reached.
      </>
    ),
    overallEventRewardsFirstParagraph: (
      <>
        There will be two ‘tracks’ of rewards — one for players who own Planets, one for players who do not. While completion of goals will generate
        greater rewards for both, the two tracks are treated differently.
      </>
    ),
    overallEventRewardsSecondParagraph: (
      <>
        Community Goals are shared amongst all players who are active during the event. If the community succeeds in achieving this goal then ALL
        active players will benefit from it. Although they will not all benefit to the exact same extent, as rewards are based on planet ownership and
        the planet type owned.
      </>
    ),
    overallEventRewardsThirdParagraph: (
      <>
        Personal Goals determine and increase the rewards an individual player will receive. Completing their personal goals each cycle will allow
        players to maximise their individual rewards.
      </>
    ),
    bigAdditionalInfoText: (
      <>
        Base amount of Tokens: 2,664,234
        <br />
        Each cycle success increases the pool by: 222,019
        <br />
        4 Cycle Complete bonus: 88,808
        <br />
        Max total: 4,440,389
        <br />
        <br />
        <br />
        Each cycle's objectives are broken down into three levels
        <br />
        of completion, a player's completion % for the cycle is the
        <br />
        average of their completion of both objectives. Each
        <br />
        completion level has a different percentage of the
        <br />
        reward pool that will be earned.
      </>
    ),
    smallAdditionalInfoText: (
      <>
        There are a maximum of <br />
        100,000 prizes in the raffle that <br />
        are gradually unlocked as <br />
        Community Goals are <br />
        completed, starting from a <br />
        base of 60,000.
        <br />
      </>
    ),
    personalLeftAdditionalInfoText: (
      <>
        For each Cycle, completing <br />
        Community Goals will earn a <br />
        bonus of 5 ASTRAFER for all <br />
        players who rank in the top <br />
        10,000 on the leaderboard. <br />
      </>
    ),
    personalRightAdditionalInfoTextFirst: (
      <>For each Cycle, all players that rank in the top 10,000 on the leaderboard will receive ASTRAFER, based on their final position.</>
    ),
    personalRightAdditionalInfoTextSecond: (
      <>A bonus 2 Tickets will be awarded for 100% completion of all 6 cycles. Players can earn a maximum of 14 Tickets during the Event.</>
    ),
    communityLeftAdditionalInfoText: (
      <>
        Base amount of Astrafer Tokens: 2,486,618
        <br />
        Successful completion of each Cycle’s Community Goals increases <br className={'show-break'} />
        the pool by: 177,616
        <br />
        6 Cycle Complete bonus: 888,078
        <br />
        Max total: 4,440,389
      </>
    ),
    communityRightAdditionalInfoText: (
      <>
        There are a maximum of 144,000 prizes in the Raffle, which are gradually unlocked as Community Goals are completed. In each Cycle, there is a
        base number of prizes that will be available. If Community goals are achieved, more prizes will become available.
      </>
    ),
    linkingWalletsInfoText: (
      <>
        Linking Wallets for Planet Holders <br className={'show-break'} />
        This feature can be used to link wallets
        <br className={'show-break'} />
        between Planet Holders and Players for <br className={'show-break'} />
        the Astrafite Rush event. This is useful if <br className={'show-break'} />
        you want to play the game in one wallet <br className={'show-break'} />
        (a Player Wallet), while having a <br className={'show-break'} />
        separate wallet holding Planet NFTs (a <br className={'show-break'} />
        Planet Holder Wallet).
        <br className={'show-break'} />
        <span className={'br'} />
        A Planet Holder Wallet can submit a <br className={'show-break'} />
        request for another wallet to play on their <br className={'show-break'} />
        behalf. A Player Wallet can then choose <br className={'show-break'} />
        to approve or reject the request to link. <br className={'show-break'} />
        <span className={'br'} />
        Once linked, progress by the Player <br className={'show-break'} />
        Wallet will be transferred to the Planet <br className={'show-break'} />
        Holder Wallet for the Planet Holder to <br className={'show-break'} />
        earn rewards. Progress made by the <br className={'show-break'} />
        Planet Holder Wallet will not earn any <br className={'show-break'} />
        rewards.
      </>
    ),
  };
}

export const astrafiteRushEvent4 = {
  paragraph1: (
    <>
      Safeguard the cosmos in this special Event - mine Astrafite and defeat the Xanorra Fire Dancers for the chance to earn the first ever Astrafer
      Token rewards!
    </>
  ),
  paragraph2: (
    <>
      Download and complete Episode 3 of Phantom Galaxies to participate in six weekly Astrafite Rush 'Cycles', lasting six days each. The locations
      of the Astrafite and Xanorra Fire Dancers will change with each Cycle.
    </>
  ),
};
export const astrafiteRushEvent4Ended = {
  paragraph1: (
    <>Fine flying, Ensign. Your hold is bursting with Astrafite, and you’ve turned legions of Fire Dancers to ash. Time to claim your rewards.</>
  ),
  paragraph2: (
    <>
      Every month, 10% of every ‘Astrafite Rush: Progress Tracker’ NFT’s total ASTRAFER earnings will be unlocked. When each payment unlocks, you can
      claim them by clicking ‘Claim Rewards’ below. Should you miss a payment upon its first unlock, it will be added to the next payment.
    </>
  ),
};

export default function checkForGameRules(showModal) {
  return (
    <>
      Learn more and track progress for Cycles, Goals and rewards below. Be sure to Save and Claim your Astrafer Tokens once each Cycle finishes, and
      don't forget to read the{' '}
      <span className={'game-roles-description'} onClick={() => showModal()}>
        Game Rules!
      </span>
    </>
  );
}

export const redirectTermsAndConditions = () => {
  return (
    <>
      To learn more about claiming and rewards, read the event’s{' '}
      <a href={'https://phantomgalaxies.com/TermsAndConditionsAFR.pdf'} target={'_blank'} rel={'noreferrer'} className={'game-roles-description'}>
        Terms and Conditions!
      </a>
    </>
  );
};
export const errorText = {
  pendingTransaction: (
    <>
      Your transaction is now pending on the
      <br />
      blockchain.
      <br />
      <br />
      This process will take a few minutes.
    </>
  ),
  defaultError: (
    <>
      Something went wrong.
      <br />
      Please try again later.
    </>
  ),
};

export const errorTypeModalCss = {
  defaultError: 'error-modal-parent-default-error',
};
export const astraferDescriptionText = {
  title: <p className={'main-title-for-astrafer'}>THE GOVERNANCE AND UTILITY TOKEN FOR OPEN-WORLD MECHA ARPG PHANTOM GALAXIES.</p>,
  mobileTitle: (
    <>
      <p className={'mobile-title-1-for-astrafer'}>THE GOVERNANCE AND UTILITY TOKEN</p>
      <p className={'mobile-title-2-for-astrafer'}>FOR OPEN-WORLD MECHA ARPG PHANTOM GALAXIES.</p>
    </>
  ),
  paragraph1: (
    <>
      ASTRAFER allows players the ability to acquire and upgrade Starfighter mechas, run galactic organisations, build expansive corporate empires,
      and more! Players will earn ASTRAFER through various avenues, including completing missions, trading items, and participating in special events.
      Additionally, player-owned Planets and Asteroids emit ASTRAFER over the course of gameplay.
    </>
  ),
  paragraph2: (
    <>
      {' '}
      Download Phantom Galaxies now to pilot the biggest
      <br className={'show-hide-break'} /> bad-ass mechas on the blockchain!
    </>
  ),
};

export const SWAP_url = {
  quickSwapUrl:
    'https://legacy.quickswap.exchange/#/swap?inputCurrency=0x2791bca1f2de4661ed88a30c99a7a9449aa84174&outputCurrency=0xdfce1e99a31c4597a3f8a8945cbfa9037655e335',
  uniSwapUrl: 'https://app.uniswap.org/#/tokens/polygon/0xDfCe1e99A31C4597a3f8A8945cBfa9037655e335',
  sushiSwap: 'https://app.sushi.com/swap?inputCurrency=ETH&outputCurrency=0xb51b97dd5569fab69495316b5a065cccff4b829d',
};

export const astrafiteAnnouncementDescriptionText = {
  paragraph1: (
    <>
      <p>
        Asteroids riddled with precious Astrafite drift through the stars, and vicious Xanorra Fire Dancer pirates swarm in their wake. Safeguard the
        cosmos, mining Astrafite and defeating Fire Dancers, for the chance to earn the first ever Astrafer token rewards!
      </p>
    </>
  ),
};

export const MintpassPopupText = genesisNftName => {
  return {
    note: (
      <p className={'mintpass-note'}>
        <span className={'bold'}>Note:</span> Confirming this action will remove the NFTs from your wallet, burning them and replacing them with a{' '}
        <span className={'bold'}> {genesisNftName} </span> NFT.
        <br />
        <br />
        This process will take a few minutes.
      </p>
    ),
  };
};

export const GenesisNFTsDescriptionsText = {
  paragraph1: '',
  paragraph2: '',
  paragraph3: '',
  pristineFleet: (
    <p className={'body'}>
      This NFT entitles the holder to a Third Class Pristine Fleet Generative Starfighter, granting them the ability to mint their own Limited Edition
      Starfighter for use in Phantom Galaxies. Third Class Starfighters will also gain access to exclusive, limited edition rarity-based aesthetic
      traits as well as the Third Class Hangars.
      <br />
      <br />
      Fresh from the factories of the Canis Major Galaxy’s finest manufacturers, Pristine Fleet Generative Starfighters offer immaculate and
      innovative craftsmanship - sleek and polished perfection, unmarred by battle.
      <br />
      <br />
      Climb into the cockpit of an exclusive mecha Starship - launch into the stars with the tactical edge of stat buffs and a special starter weapon,
      and display refined engineering artistry with the visually unique Super Move and Generative theming.
      <br />
      <br />
    </p>
  ),
  standardIssueStarfighter: (
    <p className={'body'}>
      This NFT entitles the holder to a Standard Issue Generative Starfighter, granting them the ability to mint their own Starfighter for use in
      Phantom Galaxies.
      <br />
      <br />
      Hard-working and reliable, the Standard Issue Generative Starfighters may not be as sleek as the Pristine Fleet or as hardened as the Veteran
      Fleet, but they are the perfect vessel for a Ranger to conduct their heroic duties.
      <br />
      <br />
    </p>
  ),
  zvetaGene: (
    <p className={'body'}>
      This NFT entitles the holder to a Third Class Zveta Gene Generative Avatar, granting them the ability to mint their own Limited Edition Avatar
      for use in Phantom Galaxies. Third Class Avatars gain access to exclusive limited edition rarity-based aesthetic traits as well as their own
      Third Class player quarters.
      <br />
      <br />
      After abandoning a barren Terra, humanity settled on Zveta - a promising planet with a breathable atmosphere and resources in abundance. But
      history did what it does, repeats - and Zveta too withered and died.
      <br />
      <br />
      When the Hopeful left the corpse of Zveta in search of a new future, they were accompanied on their intergalactic voyage by a contingent of
      Zvetan Military support - these workers braved the frontier, dutiful and steadfast, running and maintaining the starships that carried humanity
      across the cosmos.
      <br />
      <br />
    </p>
  ),
  unspecifiedOrigin: (
    <p className={'body'}>
      This NFT entitles the holder to a Unspecified Origin Generative Avatar, granting them the ability to mint their own Avatar for use in Phantom
      Galaxies.
      <br />
      <br />
      A new generation of Space Ranger pilots have signed up to join the peacekeeping cause! Their heritage is mixed: their ancestry includes both the
      civilian spacefarers carrying the hope of a brighter future and the military contingent charged with protecting it, as together they ventured
      from the dying planet of Zveta.
      <br />
      <br />
      Now, many generations have passed and these two groups have united, divided, and agreed once again to unite to bring peace and safety to the
      Galaxy.
      <br />
      <br />
    </p>
  ),
  veteranFleet: (
    <p className={'body'}>
      This NFT entitles the holder to a Third Class Veteran Fleet Generative Starfighter, granting them the ability to mint their own Limited Edition
      Starfighter for use in Phantom Galaxies. Third Class Starfighters will also gain access to exclusive, limited edition rarity-based traits as
      well as the Third Class Hangars.
      <br />
      <br />
      Hardened by the crucible of battle, Veteran Fleet Generative Starfighters have weathered scorching waves of plasma, fiery missile avalanches,
      vicious hailstorms of bullets - and emerged on the other side, tougher and meaner.
      <br />
      <br />
      Climb into the cockpit of an exclusive mecha Starship - launch into the stars with the tactical edge of stat buffs and a special starter weapon,
      and display hard-won battle prestige with the visually unique Super Move and Generative theming.
      <br />
      <br />
    </p>
  ),
};

export const GenesisNFTsList = [
  {
    image: genesis_nft_1,
    imageSoldOut: genesis_nft_1_sold_out,
    title: 'Pristine Fleet Mintpass - Second Class',
    eventKey: 'genesis-nft1',
    description: GenesisNFTsDescriptionsText.paragraph1,
    moreInfo: 'https://medium.com/@PhantomGalaxies/origin-collection-nft-snapshot-coming-15th-february-at-12-am-utc-ae4801ecced1',
    globalMintPassId: MINT_PASS_IDS.PRISTINE_FLEET_MINTPASS,
    conversionButtonText: 'mint with posters',
    openseaUrl: buyOnOpenSea.pristineMintPassSecondClass,
    nftsRequired: [
      {
        image: halberd_nft,
        name: 'Halberd-001',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/1',
        accountDataKey: 'ep1_posters',
        globalsEpisodeId: episodeIds.EPISODE_1,
      },
      {
        image: shining_star_nft,
        name: 'Shining Star',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/2',
        accountDataKey: 'ep2_posters',
        globalsEpisodeId: episodeIds.EPISODE_2,
      },
      {
        image: thermopylae_nft,
        name: 'Thermopylae',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/3',
        accountDataKey: 'ep3_posters',
        globalsEpisodeId: episodeIds.EPISODE_3,
      },
      {
        image: xanorra_slave_barge_nft,
        name: 'Xanorra Slave Barge',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/4',
        accountDataKey: 'ep4_posters',
        globalsEpisodeId: episodeIds.EPISODE_4,
      },
    ],
  },
  {
    image: genesis_nft_2,
    imageSoldOut: genesis_nft_2_sold_out,
    title: 'Veteran Fleet Mintpass - Second Class',
    eventKey: 'genesis-nft2',
    description: GenesisNFTsDescriptionsText.paragraph2,
    openSea: buyOnOpenSea.episode1PosterNFT,
    moreInfo: 'https://medium.com/@PhantomGalaxies/origin-collection-nft-snapshot-coming-15th-february-at-12-am-utc-ae4801ecced1',
    globalMintPassId: MINT_PASS_IDS.VETERAN_FLEET_MINTPASS,
    conversionButtonText: 'mint with posters',
    openseaUrl: buyOnOpenSea.veteranMintPassSecondClass,
    nftsRequired: [
      {
        image: redeemed_halberd_nft,
        name: 'Halberd-001\nRedeemed',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/11',
        accountDataKey: 'ep1_redeemed_posters',
        globalsEpisodeId: episodeIds.EPISODE_1_REDEEMED,
      },
      {
        image: redeemed_shining_star_nft,
        name: 'Shining Star\nRedeemed',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/12',
        accountDataKey: 'ep2_redeemed_posters',
        globalsEpisodeId: episodeIds.EPISODE_2_REDEEMED,
      },
      {
        image: redeemed_thermopylae_nft,
        name: 'Thermopylae\nRedeemed',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/13',
        accountDataKey: 'ep3_redeemed_posters',
        globalsEpisodeId: episodeIds.EPISODE_3_REDEEMED,
      },
      {
        image: redeemed_xanorra_slave_barge_nft,
        name: 'Xanorra Slave Barge\nRedeemed ', //for testing only, because the corresponding  image is too small
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/14',
        accountDataKey: 'ep4_redeemed_posters',
        globalsEpisodeId: episodeIds.EPISODE_4_REDEEMED,
      },
    ],
  },
  {
    image: genesis_nft_3,
    imageSoldOut: genesis_nft_3_sold_out,
    title: 'Zveta Gene Sample Mintpass - Second Class',
    eventKey: 'genesis-nft3',
    description: GenesisNFTsDescriptionsText.paragraph3,
    moreInfo: 'https://medium.com/@PhantomGalaxies/origin-collection-nft-snapshot-coming-15th-february-at-12-am-utc-ae4801ecced1',
    globalMintPassId: MINT_PASS_IDS.ZVETA_GENE_SAMPLE_MINTPASS,
    conversionButtonText: 'mint with medals',
    openseaUrl: buyOnOpenSea.zvetaGeneMintPassSecondClass,
    nftsRequired: [
      {
        image: medal_nft_1,
        name: 'Medal of Spirit - \nShowing Courage',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/101',
        accountDataKey: 'ep1_badges',
        globalsEpisodeId: episodeIds.EPISODE_1_BADGE,
      },
      {
        image: medal_nft_2,
        name: 'Medal of Spirit - \nForming Alliances',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/102',
        accountDataKey: 'ep2_badges',
        globalsEpisodeId: episodeIds.EPISODE_2_BADGE,
      },
      {
        image: medal_nft_3,
        name: 'Medal of Spirit - \nKnowing your Enemies',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/103',
        accountDataKey: 'ep3_badges',
        globalsEpisodeId: episodeIds.EPISODE_3_BADGE,
      },
      {
        image: medal_nft_4,
        name: 'Medal of Spirit - \nTechnical Proficiency',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/104',
        accountDataKey: 'ep4_badges',
        globalsEpisodeId: episodeIds.EPISODE_4_BADGE,
      },
    ],
  },
];

export const StarfighterNFTsList = [
  {
    image: Pristine,
    imageSoldOut: genesis_nft_1_sold_out,
    title: 'Pristine Fleet Mintpass - Third Class',
    eventKey: 'nft1',
    description: GenesisNFTsDescriptionsText.pristineFleet,
    moreInfo: 'https://medium.com/@PhantomGalaxies/supermint-trait-phantom-catalyst-nfts-for-starfighters-avatars-ea1419fffbef',
    globalMintPassId: MINT_PASS_IDS.PRISTINE_FLEET_MINTPASS,
    conversionButtonText: 'mint with posters',
    openseaUrl: buyOnOpenSea.pristineMintPassThirdClass,
    nftsRequired: [
      {
        image: halberd_nft,
        name: 'Halberd-001',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/1',
        accountDataKey: 'ep1_posters',
        globalsEpisodeId: episodeIds.EPISODE_1,
      },
      {
        image: shining_star_nft,
        name: 'Shining Star',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/2',
        accountDataKey: 'ep2_posters',
        globalsEpisodeId: episodeIds.EPISODE_2,
      },
      {
        image: thermopylae_nft,
        name: 'Thermopylae',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/3',
        accountDataKey: 'ep3_posters',
        globalsEpisodeId: episodeIds.EPISODE_3,
      },
      {
        image: xanorra_slave_barge_nft,
        name: 'Xanorra Slave Barge',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/4',
        accountDataKey: 'ep4_posters',
        globalsEpisodeId: episodeIds.EPISODE_4,
      },
    ],
  },
  {
    image: Veteran,
    imageSoldOut: genesis_nft_2_sold_out,
    title: 'Veteran Fleet Mintpass - Third Class',
    eventKey: 'nft2',
    description: GenesisNFTsDescriptionsText.veteranFleet,
    openSea: buyOnOpenSea.episode1PosterNFT,
    moreInfo: 'https://medium.com/@PhantomGalaxies/supermint-trait-phantom-catalyst-nfts-for-starfighters-avatars-ea1419fffbef',
    globalMintPassId: MINT_PASS_IDS.VETERAN_FLEET_MINTPASS,
    conversionButtonText: 'mint with posters',
    openseaUrl: buyOnOpenSea.veteranMintPassThirdClass,
    nftsRequired: [
      {
        image: redeemed_halberd_nft,
        name: 'Halberd-001\nRedeemed',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/11',
        accountDataKey: 'ep1_redeemed_posters',
        globalsEpisodeId: episodeIds.EPISODE_1_REDEEMED,
      },
      {
        image: redeemed_shining_star_nft,
        name: 'Shining Star\nRedeemed',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/12',
        accountDataKey: 'ep2_redeemed_posters',
        globalsEpisodeId: episodeIds.EPISODE_2_REDEEMED,
      },
      {
        image: redeemed_thermopylae_nft,
        name: 'Thermopylae\nRedeemed',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/13',
        accountDataKey: 'ep3_redeemed_posters',
        globalsEpisodeId: episodeIds.EPISODE_3_REDEEMED,
      },
      {
        image: redeemed_xanorra_slave_barge_nft,
        name: 'Xanorra Slave Barge\nRedeemed ', //for testing only, because the corresponding  image is too small
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/14',
        accountDataKey: 'ep4_redeemed_posters',
        globalsEpisodeId: episodeIds.EPISODE_4_REDEEMED,
      },
    ],
  },
  {
    image: Standardfleet,
    imageSoldOut: genesis_nft_2_sold_out,
    title: 'Standard Issue Fleet Mintpass',
    eventKey: 'nft3',
    description: GenesisNFTsDescriptionsText.standardIssueStarfighter,
    openSea: buyOnOpenSea.episode1PosterNFT,
    moreInfo: 'https://medium.com/@PhantomGalaxies/supermint-trait-phantom-catalyst-nfts-for-starfighters-avatars-ea1419fffbef',
    globalMintPassId: MINT_PASS_IDS.STANDARD_ISSUE_STARFIGHTER_MINTPASS,
    conversionButtonText: 'mint with posters',
    openseaUrl: buyOnOpenSea.standardIssueMintPass,
    nftsRequired: [
      {
        image: redeemed_halberd_nft,
        name: 'Halberd-001\nRedeemed',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/11',
        accountDataKey: 'ep1_redeemed_posters',
        globalsEpisodeId: episodeIds.EPISODE_1_REDEEMED,
      },
      {
        image: redeemed_shining_star_nft,
        name: 'Shining Star\nRedeemed',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/12',
        accountDataKey: 'ep2_redeemed_posters',
        globalsEpisodeId: episodeIds.EPISODE_2_REDEEMED,
      },
      {
        image: redeemed_thermopylae_nft,
        name: 'Thermopylae\nRedeemed',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/13',
        accountDataKey: 'ep3_redeemed_posters',
        globalsEpisodeId: episodeIds.EPISODE_3_REDEEMED,
      },
      {
        image: redeemed_xanorra_slave_barge_nft,
        name: 'Xanorra Slave Barge\nRedeemed ', //for testing only, because the corresponding  image is too small
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/14',
        accountDataKey: 'ep4_redeemed_posters',
        globalsEpisodeId: episodeIds.EPISODE_4_REDEEMED,
      },
    ],
  },
];

export const AvatarNFTsList = [
  {
    image: Zveta,
    imageSoldOut: genesis_nft_3_sold_out,
    title: 'Zveta Gene Sample Mintpass - Third Class',
    eventKey: 'nft4',
    description: GenesisNFTsDescriptionsText.zvetaGene,
    moreInfo: 'https://medium.com/@PhantomGalaxies/supermint-trait-phantom-catalyst-nfts-for-starfighters-avatars-ea1419fffbef',
    globalMintPassId: MINT_PASS_IDS.ZVETA_GENE_SAMPLE_MINTPASS,
    conversionButtonText: 'mint with medals',
    openseaUrl: buyOnOpenSea.zvetaGeneMintPassThirdClass,
    nftsRequired: [
      {
        image: medal_nft_1,
        name: 'Medal of Spirit - \nShowing Courage',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/101',
        accountDataKey: 'ep1_badges',
        globalsEpisodeId: episodeIds.EPISODE_1_BADGE,
      },
      {
        image: medal_nft_2,
        name: 'Medal of Spirit - \nForming Alliances',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/102',
        accountDataKey: 'ep2_badges',
        globalsEpisodeId: episodeIds.EPISODE_2_BADGE,
      },
      {
        image: medal_nft_3,
        name: 'Medal of Spirit - \nKnowing your Enemies',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/103',
        accountDataKey: 'ep3_badges',
        globalsEpisodeId: episodeIds.EPISODE_3_BADGE,
      },
      {
        image: medal_nft_4,
        name: 'Medal of Spirit - \nTechnical Proficiency',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/104',
        accountDataKey: 'ep4_badges',
        globalsEpisodeId: episodeIds.EPISODE_4_BADGE,
      },
    ],
  },
  {
    image: Unspecefied,
    imageSoldOut: genesis_nft_3_sold_out,
    title: 'Unspecified Origin Gene Sample',
    eventKey: 'nft5',
    description: GenesisNFTsDescriptionsText.unspecifiedOrigin,
    moreInfo: 'https://medium.com/@PhantomGalaxies/supermint-trait-phantom-catalyst-nfts-for-starfighters-avatars-ea1419fffbef',
    globalMintPassId: MINT_PASS_IDS.UNSPECIFIED_ORIGIN_GENESIS_MINTPASS,
    conversionButtonText: 'mint with medals',
    openseaUrl: buyOnOpenSea.unspecifiedMintPass,
    nftsRequired: [
      {
        image: medal_nft_1,
        name: 'Medal of Spirit - \nShowing Courage',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/101',
        accountDataKey: 'ep1_badges',
        globalsEpisodeId: episodeIds.EPISODE_1_BADGE,
      },
      {
        image: medal_nft_2,
        name: 'Medal of Spirit - \nForming Alliances',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/102',
        accountDataKey: 'ep2_badges',
        globalsEpisodeId: episodeIds.EPISODE_2_BADGE,
      },
      {
        image: medal_nft_3,
        name: 'Medal of Spirit - \nKnowing your Enemies',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/103',
        accountDataKey: 'ep3_badges',
        globalsEpisodeId: episodeIds.EPISODE_3_BADGE,
      },
      {
        image: medal_nft_4,
        name: 'Medal of Spirit - \nTechnical Proficiency',
        openSea: 'https://opensea.io/assets/matic/0x95fcb7f46f1e652fdf23db087c0f24011775be00/104',
        accountDataKey: 'ep4_badges',
        globalsEpisodeId: episodeIds.EPISODE_4_BADGE,
      },
    ],
  },
];

export const revealText = {
  paragraph1: (
    <p className={'reveal-paragraph-1'}>
      Blowfish Studios are an award-winning developer & publisher of interactive entertainment based in Sydney, Australia. Founded in 2008 by industry
      veterans and led to this day by its founders. They distinguish themselves by creating innovative and engaging games that push the latest
      technologies in tomorrow’s next-gen gaming platforms.
    </p>
  ),
  paragraph2: (
    <p className={'reveal-paragraph-2'}>
      Animoca Brands is a global leader in gamification and blockchain with a large portfolio of over 340 investments. Its mission is to advance
      digital property rights and decentralized projects to help build the open metaverse.
    </p>
  ),
};
export const exchangeAddress = {
  ethereum: '0xb51b97dd5569fab69495316b5a065cccff4b829d',
  matic: '0xdfce1e99a31c4597a3f8a8945cbfa9037655e335',
};

export const linkingWarningMessage = {
  paragraph1: <>Warning you have a pending link request, or are already linked. Doing this will cancel the link.</>,
};
export const miniLeaderBoardMissingData = {
  paragraph1: <>You have not made any progress. </>,
  paragraph2: (
    <>
      Click the button below to view <br className={'mobile-line-break'} /> the current Cycle’s Full Leaderboard.
    </>
  ),
};
export const leaderBoardNoData = {
  paragraph1: <>No data.</>,
  paragraph2: <>Please check again later.</>,
};

const originalColectionNFT = {
  url1: 'https://opensea.io/collection/phantom-galaxies-origin-collection',
};

export const carouselText = {
  firstCarouselText: (
    <>
      Connect a wallet containing a{' '}
      <a className={'original-nft-location'} rel="noreferrer" target={'_blank'} href={originalColectionNFT.url1}>
        {' '}
        Phantom Galaxies Origin Collection NFT
      </a>{' '}
      to the site, download the Phantom Galaxies game and complete Episodes 1 to 3. Once complete, you will find a new Event called ‘Astrafite Rush:
      Cycle X’ in your in-game Quest Log
    </>
  ),
  paragraph2: (
    <>In Astrafite Rush, mine Astrafite and destroy Xanorra Fire Dancers to contribute to your own Personal Goals and shared Community Goals.</>
  ),
  paragraph3: (
    <>
      {' '}
      Track Personal and Community Goal progress by visiting this page.
      <br />
      At the end of each Cycle, come back here to save your Cycle progress!
    </>
  ),
  paragraph4: (
    <>
      After you take part in your first Cycle, you will be airdropped an <br className={'break-on-1024'} /> ‘Astrafite Rush: Progress Tracker’ NFT.
      You will need this later to claim your rewards!
    </>
  ),
  paragraph5: (
    <>
      {' '}
      Before you take off, make sure to read up on the{' '}
      <a className={'original-nft-location'} rel="noreferrer" target={'_blank'} href={legalAndPolicyLinks.termsAndConditionsAfr}>
        Astrafite Rush Terms and Conditions
      </a>{' '}
      for more in-depth details of the Event.
    </>
  ),
};

export const genesisNFTAvailable = {
  paragraph1: (
    <>
      In the tireless campaign against pirate anarchy, elite pilots and Starfighters turn the tide. Rare pilots, imbued with the genes of the Hopeful
      or Zveta’s defenders, are renowned among their fellow Rangers. Coveted Starfighters, hardened by battle or gleaming with unmarred craftsmanship,
      are unmistakable amid the reaches of space.
      <br />
      <br />
      Limited Edition Genesis Pilots and Starfighters are entitled to top-class personal quarters and hangars.
    </>
  ),
};

export const rewardsClaim = {
  paragraph1: (
    <>
      To claim your ASTRAFER, you must complete KYC.
      <br />
      <br />
      Players who have previously completed KYC will not need to complete it again.
      <br />
      <br />
      <br className={'not-mobile'} />
    </>
  ),
  endOfDescriptionNotStarted: <>You have not completed KYC. You can do so by clicking ‘Connect with Blockpass’ below.</>,
  endOfDescriptionPending: (
    <>
      Your KYC request is pending. It may take up to 24 hours to return a success or failed result.
      <br />
      Please check this site again later.
    </>
  ),
  endOfDescriptionFailed: (
    <>
      Your KYC request has failed. If you wish to claim your Astrafer tokens, you will need to successfully complete KYC. You can try again by
      clicking ‘Connect with Blockpass’ below.
    </>
  ),
  endOfDescriptionBlocked: <>Your KYC request has failed. You will not be eligible to claim Astrafer tokens.</>,
  endOfDescriptionSuccess: (
    <>
      Your KYC request has been successful! Scroll down to view your ‘Astrafite Rush: Progress Tracker’ NFT/s below, and claim your ASTRAFER when it
      unlocks.
    </>
  ),
  kycPopupText1: (
    <>
      Know Your Customer, also known as Know Your Client (KYC), is the process of identifying and verifying a customer’s or client’s identity. In this
      current case of Phantom Galaxies, it is used to ensure those who wish to claim ASTRAFER, are genuinely who they claim to be.
      <br />
      <br />
      KYC procedures involve necessary actions to ensure the customers are real. Materials used to successfully pass KYC and KYC checks may involve
      personal documents such as: ID card verification, face verification and other documented verification such as a proof of address.
      <br />
      <br />
    </>
  ),
  kycPopupText2: (
    <>
      It is a legal requirement that Ultra Modern Group Ltd comply with KYC regulations to limit illegal activity such as: money laundering, terrorism
      financing, and other illegal corruption schemes.
      <br />
      <br />
      It is a requirement for all players to successfully complete KYC so the transfer of ASTRAFER can be done with confidence in the safety of the
      receiving party.
      <br />
      <br />
      After completing your KYC request using Blockpass, it may take up to 24 hours to return a success or failed result.
      <br />
      <br />
    </>
  ),
  kycPopupText3: (
    <>
      Blockpass is a unique, reusable digital identity solution for any organisations that participate in the regulated and increasingly remote
      business environment where trust needs to be verified digitally.
      <br />
      <br />
      Although a third party service, Blockpass is used to ensure KYC procedures are done efficiently and safely. You can read more about Blockpass by
      visiting{' '}
      <a href="https://www.blockpass.org/" target="_blank" rel="noreferrer">
        www.blockpass.org
      </a>
      .
      <br />
      <br />
    </>
  ),
  kycPopupText4: (
    <>
      By continuing to use this service, you agree that Ultra Modern Group Ltd is not responsible for any third party services such as Blockpass. Any
      use of third party services are at your own risk and subject to the terms of use with respect to such third party services. Ultra Modern Group
      Ltd have no control over third party services or the security of third party services, and disclaim all representations and warranties with
      respect to such third party services in their entirety. Any transactions or transfers you undertake with third party services, including payment
      transactions, are solely between you and the applicable third party services.
      <br />
      <br />
      You understand and agree that under no circumstances shall Ultra Modern Group Ltd be responsible for or liable to anyone in connection with your
      use of all third party services, and any transactions or transfers conducted with such third party services. You agree to direct any questions,
      complaints, or claims related to a third party service to such third party service.
      <br />
      <br />
    </>
  ),
};

// Test Data for Mission
export const ReconMission = [
  {
    id: '028e6ed5-302e-43e4-a2df-137a90423c83',
    phase: 'First wave',
    name: 'Recon mission',
    informationCopy:
      'Bacon ipsum dolor amet brisket ham shankle ground round meatloaf turducken tri-tip venison ham hock. Pork burgdoggen ground round leberkas chislic. Salami ball tip cow pancetta. Andouille sirloin kevin biltong tail pork tri-tip pork chop.',
    completionCopy:
      'Muffin jelly caramels cake lemon drops bear claw topping gummi bears jelly beans. Dragée sweet gummi bears icing muffin sweet. Gummies jelly oat cake biscuit biscuit. Fruitcake donut lemon drops icing bear claw. Cake pie chocolate jelly beans gingerbread. Chocolate powder shortbread shortbread candy canes sesame snaps bonbon. Chupa chups wafer jujubes gingerbread sugar plum tiramisu ice cream cake. Pie toffee dragée oat cake jujubes liquorice cookie chocolate sugar plum. Cheesecake danish marshmallow carrot cake cookie chupa chups dessert topping jelly-o. Sweet roll icing biscuit sesame snaps gummi bears caramels. Fruitcake marzipan icing sugar plum sesame snaps toffee. Marshmallow carrot cake bear claw sweet halvah',
    checkpointCount: 12,
    duration: 10368000,
    validFrom: '2023-07-11T00:00:00.000Z',
    validTo: new Date('December 23, 2023 12:00:00'),
    // TODO: Replace with real data for testing
    astraferRewards: {
      minGain: 12,
      maxGain: 8000000,
      daysDuration: 60,
      checkpoints: 12,
      astraferPerCheckpoint: 28,
      astraferEarned: 350,
    },
    planetRewards: {
      largePlanet: 12,
      mediumPlanet: 6,
      smallPlanet: 5,
      largeAsteroid: 32,
      smallAsteroid: 106,
      planetsEarned: 5,
    },
  },
];

// TODO: this is only for testing a special case (PHAN-637), delete after testing
export const PlanetExplorationOneMission = [
  {
    title: 'MISSION 1',
    description: 'CHECK IN AVAILABLE',
    missionTimer: new Date('December 23, 2023 12:00:00'),
    currentCheckinTimer: new Date('August 23, 2023 12:00:00'),
    progress: '12',
    checkInList: [
      { id: 1, status: 'completed' },
      { id: 2, status: 'completed' },
      { id: 3, status: 'ended' },
      { id: 4, status: 'completed' },
      { id: 5, status: 'progress' },
      { id: 6, status: 'locked' },
      { id: 7, status: 'locked' },
      { id: 8, status: 'locked' },
      { id: 9, status: 'locked' },
      { id: 10, status: 'locked' },
      { id: 11, status: 'locked' },
      { id: 12, status: 'locked' },
    ],
    astraferRewards: {
      astraferEarned: 200,
      astraferPerCheckpoint: 50,
      maxGain: 750,
      minGain: 150,
    },
    planetRewards: [
      {
        quantity: 1,
        rewardedPlanetSize: 3,
      },
      {
        quantity: 1,
        rewardedPlanetSize: 5,
      },
    ],
    planetsEarned: 2,
  },
];

export const PlanetExplorationMissions = [
  {
    id: '0e369ee6-7e4a-462a-a48e-418108abbad8',
    walletAddress: '0x9B6076B6498Bdf30cD0AEa8CF916D1E58e3d24C7',
    missionId: '028e6ed5-302e-43e4-a2df-137a90423c83',
    status: 'ENTERED',
    rewardsClaimed: false,
    createdAt: '2023-07-26T01:59:06.166Z',
    updatedAt: '2023-07-27T04:54:37.675Z',
    start: new Date('December 23, 2023 12:00:00'),
    end: new Date('August 23, 2023 12:00:00'),
    checkpoints: [
      {
        id: 'e620e1b8-1c96-48fd-a0c0-71d6c10917a2',
        missionEntryId: '0e369ee6-7e4a-462a-a48e-418108abbad8',
        checkpointNumber: 0,
        status: 'COMPLETED',
        checkpointCopy: 'To complete your Check-In for today..',
        checkinCompleted: false,
        completedAt: null,
      },
      {
        id: 'cd3fcbe0-4d16-4367-b03f-2e3453f7b19c',
        missionEntryId: '0e369ee6-7e4a-462a-a48e-418108abbad8',
        checkpointNumber: 1,
        status: 'ENDED',
        checkpointCopy: 'To complete your Check-In for today..',
        checkinCompleted: false,
        completedAt: null,
      },
      {
        id: 'a85302b4-708e-410c-9167-a44eda1d26c3',
        missionEntryId: '0e369ee6-7e4a-462a-a48e-418108abbad8',
        checkpointNumber: 2,
        status: 'COMPLETED',
        checkpointCopy: 'To complete your Check-In for today..',
        checkinCompleted: false,
        completedAt: null,
      },
      {
        id: '2521d106-0fe7-4670-b7b7-46caea958e68',
        missionEntryId: '0e369ee6-7e4a-462a-a48e-418108abbad8',
        checkpointNumber: 3,
        status: 'COMPLETED',
        checkpointCopy: 'To complete your Check-In for today..',
        checkinCompleted: false,
        completedAt: null,
      },
      {
        id: 'aa073c14-e055-4797-b439-3c9be50ff40f',
        missionEntryId: '0e369ee6-7e4a-462a-a48e-418108abbad8',
        checkpointNumber: 4,
        status: 'COMPLETED',
        checkpointCopy: 'To complete your Check-In for today..',
        checkinCompleted: false,
        completedAt: null,
      },
      {
        id: '9b12e302-f0f1-4790-8415-28b77442b466',
        missionEntryId: '0e369ee6-7e4a-462a-a48e-418108abbad8',
        checkpointNumber: 5,
        status: 'ACTIVE',
        checkpointCopy: 'To complete your Check-In for today..',
        checkinCompleted: false,
        completedAt: null,
      },
      {
        id: '13ac5238-c510-49d0-a6d3-81a671dbbd8e',
        missionEntryId: '0e369ee6-7e4a-462a-a48e-418108abbad8',
        checkpointNumber: 6,
        status: 'LOCKED',
        checkpointCopy: 'To complete your Check-In for today..',
        checkinCompleted: false,
        completedAt: null,
      },
      {
        id: '8b13392a-14ea-4122-acae-898fbe935c98',
        missionEntryId: '0e369ee6-7e4a-462a-a48e-418108abbad8',
        checkpointNumber: 7,
        status: 'LOCKED',
        checkpointCopy: 'To complete your Check-In for today..',
        checkinCompleted: false,
        completedAt: null,
      },
      {
        id: '32c2aa5f-7ae7-4cd7-b18e-3da01de0ffaf',
        missionEntryId: '0e369ee6-7e4a-462a-a48e-418108abbad8',
        checkpointNumber: 8,
        status: 'LOCKED',
        checkpointCopy: 'To complete your Check-In for today..',
        checkinCompleted: false,
        completedAt: null,
      },
      {
        id: '064fe152-6c24-4a8f-8e2b-56d21c4d7141',
        missionEntryId: '0e369ee6-7e4a-462a-a48e-418108abbad8',
        checkpointNumber: 9,
        status: 'LOCKED',
        checkpointCopy: 'To complete your Check-In for today..',
        checkinCompleted: false,
        completedAt: null,
      },
      {
        id: 'c2fd7bc6-ffb4-40b5-a0c9-7ecc132a7c07',
        missionEntryId: '0e369ee6-7e4a-462a-a48e-418108abbad8',
        checkpointNumber: 10,
        status: 'LOCKED',
        checkpointCopy: 'To complete your Check-In for today..',
        checkinCompleted: false,
        completedAt: null,
      },
      {
        id: '18d38d19-3014-41f0-9b0f-ccb7033e2852',
        missionEntryId: '0e369ee6-7e4a-462a-a48e-418108abbad8',
        checkpointNumber: 11,
        status: 'LOCKED',
        checkpointCopy: 'To complete your Check-In for today..',
        checkinCompleted: false,
        completedAt: null,
      },
    ],
    delegatedWallets: [],
  },
];

export const MissionStatus = {
  ACTIVE: 'active',
};

/**
 * Finds the valid checkpoint where the current date falls within the checkpoint's date range
 *
 * @param {Array} checkpoints - An array of checkpoint objects to search through.
 * @returns {Checkpoint | undefined} The first valid checkpoint found, or `undefined` if no valid checkpoint is found.
 */
export const findCurrentCheckpoint = checkpoints => {
  if (!checkpoints || checkpoints.length < 1) return;

  const now = new Date();

  return checkpoints.find(checkpoint => {
    return now >= new Date(checkpoint.start) && now <= new Date(checkpoint.end);
  });
};

export const findNextCheckpoint = (checkpoints, nextCheckPointNumber) => {
  return checkpoints.find(checkpoint => {
    return checkpoint.checkpointNumber === nextCheckPointNumber;
  });
};

export const findMissionTabContent = missionEntry => {
  if (missionEntry.status === MISSION_STATUS.COMPLETED) return MISSION_TAB_DISPLAY_TEXT.COMPLETE;

  const totalNumberOfCheckpoints = missionEntry.checkpoints.length;

  // Find the current checkpoint where the time/date now is between the start and end date of the checkpoint
  // If there are no valid checkpoints, it means all the checkpoints are in the past
  const foundValidCheckPoint = findCurrentCheckpoint(missionEntry.checkpoints);
  let nextCheckpoint;

  if (!foundValidCheckPoint) {
    // when the mission entry status is completed, it means rewards have been claimed
    if (missionEntry.status === MISSION_STATUS.COMPLETED) {
      return MISSION_TAB_DISPLAY_TEXT.COMPLETE;
    } else if (new Date() < new Date(missionEntry.end)) {
      return MISSION_TAB_DISPLAY_TEXT.UNAVAILABLE;
    } else {
      return MISSION_TAB_DISPLAY_TEXT.REWARDS_AVAILABLE;
    }
  }

  const foundValidCheckpointNumber = foundValidCheckPoint.checkpointNumber;

  // If the current checkpoint has been completed, find the next checkpoint by using the checkpoint number of the current checkpoint.
  // e.g. If the current checkpoint number is 4 then we expect the next checkpoint to be checkpoint number 5.
  if (foundValidCheckPoint?.checkinCompleted) {
    nextCheckpoint = findNextCheckpoint(missionEntry.checkpoints, foundValidCheckpointNumber + 1);
  }

  if (nextCheckpoint || (foundValidCheckpointNumber === totalNumberOfCheckpoints && foundValidCheckPoint.checkinCompleted)) {
    return MISSION_TAB_DISPLAY_TEXT.UNAVAILABLE;
  }

  return MISSION_TAB_DISPLAY_TEXT.AVAILABLE;
};

// Is valid GUID pattern - 02035791-4b14-41be-8868-822deb229304
export function isValidGuid(guid) {
  const guidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return guidPattern.test(guid);
}
