import './ExplorationSelectionContent.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PageHeader from '../../PageHeader';
import ExplorationRewards from '../ExplorationRewards/ExplorationRewards';
import PlanetsFilter from '../PlanetsFilter/PlanetsFilter';
import { explorationSelectInfo, planetDetailsMap } from '../../../utils/GeneralUtils';
import PlanetsPanels from '../PlanetsPanels/PlanetsPanels';
import PlayOnBehalfExploration from '../PlayOnBehalfExploration/PlayOnBehalfExploration';
import InfoDropdown from '../../InfoDropdown/InfoDropdown';
import { sendMissionEntryStart, sendMissionEntryStartTxHash } from '../../../utils/ApiCalls';
import LoadingSpinner from '../../LoadingSpinner';
import { PlanetStatus } from '../../../utils/Globals';
import { getBlockchainContract } from '../../../utils/providerObject';
import CeresQuadrantMissionsAbi from '@fugu/base-contracts/dist/abis/Games/CeresQuadrantMissions.sol/CeresQuadrantMissions.json';
import { startReconMission } from '../../../utils/ContractUtils';
import ErrorModal from '../../Modal/ErrorModal/ErrorModal';
import Web3 from 'web3';
import { walletContext } from '../../../utils/WalletContext';
import { useFetchMissions } from '../../../hooks/useFetchMissions';
import { useFetchPlanets } from '../../../hooks/useFetchPlanets';
import { useAccount } from 'wagmi';

export default function ExplorationSelectionContent() {
  const [totalPlanetBalance, setTotalPlanetBalance] = useState(0);
  const [planets, setPlanets] = useState([]);
  const [filteredPlanets, setFilteredPlanets] = useState([]);
  const [delegateWalletValue, setDelegateWalletValue] = useState('');
  const [error, setError] = useState(null);
  const [errorModalText, setErrorModalText] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [missionLaunchText, setMissionLaunchText] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  // We load the mission from the landing page state otherwise the useEffect will call the API if there is no loaded mission.
  const { mission, isMissionLoading, missionLoadError } = useFetchMissions(location.state?.loadedMission);
  const { planetDetails, arePlanetsLoading, planetsError } = useFetchPlanets(mission);
  const playOnBehalfRef = useRef(null);

  const { connector } = useAccount();
  const [provider, setProvider] = useState(undefined);

  useEffect(() => {
    const getProvider = async () => {
      const thisProvider = await connector?.getProvider();

      setProvider(thisProvider);
    };
    getProvider();
  });

  const formatPlanets = ({ planetInfo, planetSize }, planetDetailsMap) => {
    const { title, category } = planetDetailsMap.get(planetSize);
    const planets = planetInfo.map(({ name, tokenId, planetStatus, thumbnailImageUrl, generation }) => {
      return {
        id: tokenId,
        name: name,
        status: planetStatus,
        isSelected: false,
        isFavorite: false,
        image: thumbnailImageUrl,
        generation: generation,
      };
    });
    const newPlanet = {
      planetSize: planetSize,
      title: title,
      category: category,
      planets: planets,
    };

    return newPlanet;
  };

  useEffect(() => {
    // It should scroll page to the top once upon mounting component
    if (!planetDetails) {
      window.scroll(0, 0);
    }

    if (!walletContext.currentWallet) {
      navigate('/portfolio/tcq-recon');
    }

    setError(null);
    setMissionLaunchText('');

    if (!planetDetails) return;

    // process balances
    const newPlanets = [];
    let totalPlanetBalance = 0;

    planetDetails.forEach(planetDetail => {
      if (planetDetail.planetInfo.length > 0) {
        totalPlanetBalance += planetDetail.planetInfo.length;

        const formattedPlanets = formatPlanets(planetDetail, planetDetailsMap);
        newPlanets.push(formattedPlanets);
      }
    });

    const eligiblePlanets = filterEligiblePlanets(newPlanets);

    setTotalPlanetBalance(totalPlanetBalance);
    setPlanets(eligiblePlanets);
    setFilteredPlanets(eligiblePlanets);
  }, [navigate, mission, planetDetails]);

  const handlerPlanetsFilter = categories => {
    let filteredPlanetsList = planets;

    if (categories.length !== 0) {
      filteredPlanetsList = planets.filter(planet => categories.includes(planet.category));
    }

    setFilteredPlanets(filteredPlanetsList);
  };

  const filterEligiblePlanets = planetGroup => {
    let filteredCategories = [];
    let ineligiblePlanets = [];

    for (const category of planetGroup) {
      const filteredPlanetsInCategory = category.planets.filter(planet => planet.status === PlanetStatus.NOT_IN_MISSION);

      const removedPlanetsInCategory = category.planets.filter(
        planet => planet.status === PlanetStatus.IN_MISSION || planet.status === PlanetStatus.COMPLETED_MISSION,
      );

      if (filteredPlanetsInCategory.length > 0) {
        filteredCategories.push({ ...category, showSelectAll: true, planets: filteredPlanetsInCategory });
      }

      if (removedPlanetsInCategory.length > 0) {
        ineligiblePlanets.push(...removedPlanetsInCategory);
      }
    }

    if (ineligiblePlanets.length > 0) {
      filteredCategories.push({
        title: 'UNAVAILABLE PLANETS',
        planets: ineligiblePlanets,
        showSelectAll: false,
      });
    }

    return filteredCategories;
  };

  const handleMissionLaunch = async () => {
    setMissionLaunchText('Launching Mission...');
    setShowErrorModal(false);
    const enteredPlanets = [];

    // Check if any selected planets are currently on a mission
    // The 'enteredPlanets' array will be populated with planets ready for launch
    if (planets.length > 0) {
      for (const planetType of planets) {
        const planetInMission = planetType.planets.find(t => t.status === PlanetStatus.IN_MISSION && t.isSelected);

        if (planetInMission) {
          setMissionLaunchText('');
          setErrorModalText(`Failed to launch mission: ${planetInMission.id} currently in mission`);
          setShowErrorModal(true);
          return;
        }
        const chosenPlanets = planetType.planets
          .filter(planet => planet.isSelected === true)
          .map(planet => ({
            size: planetType.category,
            tokenId: planet.id,
            generation: planet.generation,
          }));

        enteredPlanets.push(...chosenPlanets);
      }
    }

    if (enteredPlanets.length < 1) {
      setMissionLaunchText('');
      setErrorModalText('Select at least 1 planet to launch mission.');
      setShowErrorModal(true);
      return;
    }

    try {
      let address;

      if (delegateWalletValue) {
        address = Web3.utils.toChecksumAddress(delegateWalletValue);
      }
      // Attempt to start the mission entry process
      const missionStartResponse = await sendMissionEntryStart(address, enteredPlanets, mission.id);

      if (missionStartResponse.missionEntry?.id) {
        // If mission entry object is returned, the mission can be started directly
        navigate('/portfolio/tcq-recon/view-mission', { state: { launchedMissionId: missionStartResponse.missionEntry.id } });
      } else {
        // Initiate the mission start transaction on the blockchain and extract the transaction hash
        const transactionHash = await handleMissionStartSignature(missionStartResponse.signature);
        // Send the transaction hash to the server to initiate the mission using the returned hash
        const missionEntryStartTxResult = await sendMissionEntryStartTxHash(address, enteredPlanets, mission.id, transactionHash);
        navigate('/portfolio/tcq-recon/view-mission', { state: { launchedMissionId: missionEntryStartTxResult.missionEntry.id } });
      }
    } catch (e) {
      setMissionLaunchText('');
      setShowErrorModal(true);
    }
  };

  const handleMissionStartSignature = async blockchainData => {
    // Use struct and signature returned from backend to initiate mission start on blockchain
    // const { chainId, missionId, user, delegatedPlayerWallet, missionPlanets } = blockchainData.missionData;
    return startReconMission(
      getBlockchainContract(process.env.REACT_APP_CERES_QUADRANT_MISSION_ADDRESS, CeresQuadrantMissionsAbi.abi, provider),
      blockchainData.structArrays[0],
      blockchainData.signatures[0],
    );
  };

  let planetSelectedHandler = (filteredPlanets, allPlanets) => {
    setPlanets(allPlanets);
    setFilteredPlanets(filteredPlanets);
  };

  const handleDelegateWalletTextChange = e => {
    const value = e.target.value;
    setDelegateWalletValue(value);
  };

  const handleShowErrorModal = () => {
    setShowErrorModal(!showErrorModal);
    if (playOnBehalfRef.current && errorModalText && errorModalText.includes('not a valid Ethereum address')) {
      // The page will scroll to the delegate wallet form
      playOnBehalfRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <>
      <div className="exploration-selection-main-container">
        <div className="exploration-selection-title-container">
          <PageHeader headerText="NEW MISSION - SELECT PLANETS" />
        </div>
        {isMissionLoading && <LoadingSpinner />}
        {missionLoadError && <div style={{ marginBottom: '40px', width: '500px', textAlign: 'center' }}>{missionLoadError}</div>}
        {mission ? (
          <>
            <ExplorationRewards
              mission={mission}
              planets={planets}
              planetsCount={totalPlanetBalance}
              handleMissionLaunch={handleMissionLaunch}
              handleError={setError}
              missionLaunchText={missionLaunchText}
            />
            <PlanetsFilter filterCallback={handlerPlanetsFilter} />
            {filteredPlanets.length > 0 && (
              <PlanetsPanels allPlanets={planets} filteredPlanets={filteredPlanets} setPlanetsCallback={planetSelectedHandler.bind(this)} />
            )}
            <div className="exploration-select-planet-loading-spinner">{arePlanetsLoading && <LoadingSpinner />}</div>
            {planetsError && <div style={{ marginBottom: '40px', width: '500px', textAlign: 'center' }}>{planetsError}</div>}
            <PlayOnBehalfExploration
              textValue={delegateWalletValue}
              handleTextChange={handleDelegateWalletTextChange}
              error={error}
              handleRef={playOnBehalfRef}
            />
          </>
        ) : (
          <>
            <p>{error}</p>
          </>
        )}
        <div className="exploration-info-container">
          <div className="exploration-info-title">
            <PageHeader headerText={'INFO'} />
          </div>
          {explorationSelectInfo.map((item, index) => (
            <InfoDropdown key={index} infoTitle={item.title} infoDescription={item.description} />
          ))}
        </div>
        {showErrorModal && <ErrorModal onHide={handleShowErrorModal} title="Failed to launch!" text={errorModalText} handleConfirm={true} />}
      </div>
    </>
  );
}
