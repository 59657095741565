import { PGAccordion } from '../../../Common/Accordion/PGAccordion';
import PageHeader from '../../../PageHeader';
import { Accordion, Container } from 'react-bootstrap';

export const Info = () => (
  <>
    <PageHeader headerText="INFO" headerTextSecondLine={undefined} classname="planet-dashboard-info" />
    <Container>
      <PGAccordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>BOUND PLANETS:</Accordion.Header>
          <Accordion.Body>
            <p>
              Planets can be Bound and Unbound by first selecting a Planet and then selecting the subsequent 'Bind' or 'Unbind' option.
              A Planet is Bound or Unbound on the blockchain, and the NFT cannot be transferred or moved from your wallet while Bound.
            </p>
            <p>A Planet is game-locked when it is blockchain Bound, AND actively being used by Phantom Galaxies or an affiliated game.
              For example, a Planet engaged in a 'The Ceres Quadrant' Active  Mission is game-locked for the duration of that Mission.</p>
            <p>
              Bound Planets that are game-locked cannot be Unbound, until released from the game lock by aborting or completing the Active Mission.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>OTHER QUESTIONS ABOUT BINDING:</Accordion.Header>
          <Accordion.Body>
            <dl>
              <dt>Q: Why can’t I Unbind my Planet?</dt>
              <dd>A: If your Planet is engaged in an Active Mission, it cannot be Unbound until it is no longer game-locked.
                The Active Mission must be aborted or completed.</dd>
              <dt>Q: Why do I need to bind my Planets?</dt>
              <dd>A: Planets must be Bound in order to make them eligible for use in Phantom Galaxies and its affiliated games.
              </dd>
            </dl>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>HOW DO I UNBIND?</Accordion.Header>
          <Accordion.Body>
            <p>
              If your Planet is not engaged in an Active Mission, it can be Unbound simply by selecting the Planet in question and clicking the ‘Unbind’ button.
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </PGAccordion>
    </Container>
  </>
)
