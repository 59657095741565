import { PropsWithChildren } from 'react';

type SuccessMessageProps = {
  message?: string;
};

export default function SuccessMessage({ message, children }: PropsWithChildren<SuccessMessageProps>) {
  return (
    <div className="success-message">
      <p>{message}</p>
      {children}
    </div>
  );
}
