import HeaderComponent from "../components/Header/HeaderComponent";
import Footer from "../components/Footer/Footer";
import FighterLady from "../components/Footer/FooterElements/FighterLady/FighterLady";
import LicenseContent from "../components/LicenseContent/LicenseContent";
import {walletContext} from "../utils/WalletContext";
import CustomConnectWalletButton from "../components/CustomeConnectWalletButton/CustomeConnectWalletButton";
import PageHeader from "../components/PageHeader";

export default function License() {
  return (
    <>
      <HeaderComponent  noBackground={true}/>
      <PageHeader classname={'license-header'} headerText={'NFT LICENSE'} headerTextSecondLine={'AGREEMENT'}/>

      {!walletContext.signed &&
        <div className="mx-auto code-panel">
          <p className={"connect-wallet-general-text-design"}>Connect your wallet to Phantom Galaxies</p>

          <div className={"home-connect-wallet-button"}>
            <div className={"home-connect-wallet-button-mobile"}>
              <CustomConnectWalletButton/>
            </div>
          </div>
        </div>
      }

      {walletContext.signed && <LicenseContent/>}
      <Footer>
        <FighterLady/>
      </Footer>
    </>
  )
}
