import './ClaimTimer.scss'
import PrivateSaleCountdown
  from "../../../routes/PublicPlanetSale/components/PrivateSaleCountdown/PrivateSaleCountdown";
import React from "react";
import {boarder_detail_top} from "../../../assets/images/AstrafiteRushEvent";
import {claim_timer_background} from "../../../assets";

export default function ClaimTimer({nextRewardTime}){
  return<>
    <div className={'claim-timer-main-container'}>
      <img alt={''} src={claim_timer_background} className={'claim-timer-background'}/>
      <div className={'claim-countdown-container'}>
        <div className={"top-decoration"}>
          <img alt={""} className={"top-crown-decoration"} src={boarder_detail_top}/>
          <div className={"line-separator"}></div>
        </div>
        <div className={'claim-timer-shadow-element'}/>
        <p className={'next-claim-text'}>Next claim unlocks in:</p>
        <PrivateSaleCountdown targetTime={nextRewardTime}/>
      </div>
    </div>
    </>
}
