import {Accordion} from "react-bootstrap";
import {PropsWithChildren} from "react";
import "./PGAccordion.scss";

type PGAccordionProps = {
  defaultActiveKey?: string;
  classNameExtension?: string;
};

export function PGAccordion({ defaultActiveKey, children, classNameExtension = '' }: PropsWithChildren<PGAccordionProps>) {
  return (
    <>
      <Accordion defaultActiveKey={defaultActiveKey} className={`pg-accordion ${classNameExtension}`} alwaysOpen>
        {children}
      </Accordion>
    </>
  );
}
