import './TopMenu.scss'
import {topMenuLogo} from "../../assets";
import TopMenuNavigation from "./TopMenuNavigation/TopMenuNavigation";

export default function TopMenu() {
  return <div className={'top-menu-main-container'}>
    <a href={'/'}>
      <img src={topMenuLogo} className={'top-menu-logo'} alt={'PHANTOM GALAXIES'}/>
    </a>
    <TopMenuNavigation/>
  </div>
}
