import './GenerativeStarfighterMintpassGrid.scss';
import GenerativeStarfighterComponent from '../GenerativeStarfighterComponent/GenerativeStarfighterComponent';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';

export default function GenerativeStarfighterMintpassGrid({ scrollToInfo, isCatalyst, selected, selectHandler, mintpasses, isBatch }) {
  const starfighterCountHandler = (tokenId, count) => {
    const toUpdate = mintpasses.find(type => type.tokenId === tokenId);
    toUpdate.count = count;

    const updatedValues = [...mintpasses];

    selectHandler(updatedValues);
  };

  const getTotalStarfighters = () => {
    let totalStarfighters = 0;
    mintpasses.forEach(mintpass => {
      totalStarfighters += mintpass.count;
    });
    return totalStarfighters;
  };

  return (
    <div className="generative-starfighter-mintpass-container">
      <div className="generative-starfighter-mintpass-title">SELECT STARFIGHTER MINTPASS</div>
      <div onClick={scrollToInfo} className="generative-starfighter-mintpass-helper">
        (How do I mint a starfighter?)
      </div>
      {
        <div className={isBatch ? 'generative-starfighter-mintpass-grid batch' : 'generative-starfighter-mintpass-grid'}>
          {mintpasses.map(mintpass => (
            <GenerativeStarfighterComponent
              isCatalyst={isCatalyst}
              title={mintpass.title}
              stock={mintpass.stock}
              count={mintpass.count}
              imgSrc={mintpass.image}
              totalPlanets={getTotalStarfighters()}
              onClick={isCatalyst ? selectHandler.bind(this, mintpass.tokenId) : starfighterCountHandler.bind(this, mintpass.tokenId)}
              selected={selected === mintpass.tokenId}
              openseaUrl={mintpass.openseaUrl}
            />
          ))}
        </div>
      }
      {!isBatch && (
        <div className="generative-starfighter-mintpass-slider">
          <Swiper slidesPerView={3} slidesPerGroup={1} spaceBetween={-80} className="starfighter-swiper">
            <SwiperSlide></SwiperSlide>
            {mintpasses.map(starfighterMintpass => (
              <SwiperSlide>
                <GenerativeStarfighterComponent
                  isCatalyst={isCatalyst}
                  title={starfighterMintpass.title}
                  stock={starfighterMintpass.stock}
                  imgSrc={starfighterMintpass.image}
                  totalPlanets={getTotalStarfighters()}
                  onClick={isCatalyst ? selectHandler.bind(this, starfighterMintpass.tokenId) : starfighterCountHandler.bind(this, starfighterMintpass.tokenId)}
                  selected={selected === starfighterMintpass.tokenId}
                  openseaUrl={starfighterMintpass.openseaUrl}
                />
              </SwiperSlide>
            ))}
            <SwiperSlide></SwiperSlide>
          </Swiper>
        </div>
      )}
    </div>
  );
}
