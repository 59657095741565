import './TermsAndConditionsModal.scss';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { terms_and_cond_background } from '../../../assets/images/AstrafiteRushEvent';
import { useNavigate } from 'react-router-dom';
import ErrorModal from '../ErrorModal/ErrorModal';
import { errorText, legalAndPolicyLinks } from '../../../utils/GeneralUtils';
import LoadingSpinner from '../../LoadingSpinner';
import { useSignMessage } from 'wagmi';
import { setAfrTncAccountSettings } from '../../../utils/ApiCalls';
import { AFR_TNC_SIGNING_MESSAGE } from '../../../utils/Globals';

export default function TermsAndConditionsModal(props) {
  const [popupVisibility, setPopupVisibility] = useState(props.visible);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [showTable, setShowTable] = useState({ showTable: false, showErrorModal: false });

  function cancelButtonHandler() {
    setPopupVisibility(!popupVisibility);
    navigate('/');
  }

  const { signMessage } = useSignMessage({
    async onSuccess(data) {
      //Just finished signing message
      await setAfrTncAccountSettings(data);
      props.setTerms(true);
    },
    onError() {
      //Sign message error
      props.setTerms(false);
    },
  });

  async function continueButtonHandler() {
    setIsLoading(true);
    try {
      signMessage({ message: AFR_TNC_SIGNING_MESSAGE });
      setIsChecked(false);
      setPopupVisibility(!popupVisibility);
      localStorage.setItem('pg.tnc.afr.agree', true);
      props.setTerms(true);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setPopupVisibility(false);
      setShowTable(prevState => ({
        ...prevState,
        ...{ showErrorModal: true },
      }));
    }
  }

  function checkTermsAndConditionsAgreement() {
    setIsChecked(!isChecked);
  }

  function hideErrorModal() {
    setShowTable(prevState => ({
      ...prevState,
      ...{ showErrorModal: false },
    }));
    navigate('/');
  }

  return (
    <>
      {showTable.showErrorModal && <ErrorModal onHide={() => hideErrorModal()} text={errorText.defaultError} errorType={'defaultError'} />}
      <div className={'terms-and-cond-main-container'} style={{ display: popupVisibility ? 'block' : 'none' }}>
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="terms-and-cond-dialog"
          show={popupVisibility}
          className={'terms-and-cond-modal'}
        >
          <img alt={''} src={terms_and_cond_background} className={'terms-and-cond-background'} />
          <div className={'terms-and-cond-container'}>
            <h1 className={'terms-and-cond-title'}>HOLD ON, RANGER!</h1>
            <p className={'terms-and-cond-text'}>To take part in the Astrafite Rush event, you must agree to the:</p>
            <ol className={'terms-and-cond-list'}>
              <li>
                <a href={legalAndPolicyLinks.termsAndConditions} target={'_blank'} rel={'noreferrer'}>
                  Terms and Conditions
                </a>
              </li>
              <li>
                <a href={legalAndPolicyLinks.termsAndConditionsAfr} target={'_blank'} rel={'noreferrer'}>
                  Terms and Conditions (Astrafite Rush)
                </a>
              </li>
              <li>
                <a href={legalAndPolicyLinks.privacyPolicy} target={'_blank'} rel={'noreferrer'}>
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href={legalAndPolicyLinks.cookiePolicy} target={'_blank'} rel={'noreferrer'}>
                  Cookie Policy
                </a>
              </li>
            </ol>
            <div className={'terms-and-cond-checkbox-container'}>
              <label className="checkbox-container">
                <input type="checkbox" />
                <span className="checkmark" onClick={checkTermsAndConditionsAgreement}></span>
              </label>
              <p>I confirm that I have read, have understood, and agree to the contents of the documents listed above.</p>
            </div>
            {!isLoading ? (
              <div className={'terms-and-cond-buttons'}>
                <div
                  className={isChecked && !isLoading ? 'terms-and-cond-continue' : 'terms-and-cond-continue disabled'}
                  onClick={continueButtonHandler}
                >
                  CONTINUE
                </div>
                <div className={'terms-and-cond-cancel'} onClick={cancelButtonHandler}>
                  <div className={'terms-and-cond-cancel-border'}></div>
                  CANCEL
                </div>
              </div>
            ) : (
              <div className={'infinite-loading-background'}>
                <LoadingSpinner />
              </div>
            )}
          </div>
        </Modal>
      </div>
    </>
  );
}
