import { useState } from 'react';
import { share_icon } from '../../../assets';
import './ShareButton.scss';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

type ShareButtonProps = {
  url: string;
};

export const ShareButton = ({ url }: ShareButtonProps) => {
  const [showCopiedPopover, setShowCopiedPopover] = useState(false);

  const copiedPopover = (
    <Popover className="share-button-popover">
      <Popover.Body>Copied to clipboard!</Popover.Body>
    </Popover>
  );

  const copyUrlPopover = (
      <Popover className="share-button-popover">
        <Popover.Body>Copy URL</Popover.Body>
      </Popover>
  );

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setShowCopiedPopover(true);
        // Hide the popover after 2 seconds
        setTimeout(() => setShowCopiedPopover(false), 2000);
      })
      .catch(error => {
        console.error('Error copying to clipboard:', error);
      });
  };

  return (
    <OverlayTrigger
      trigger={['hover', 'focus']}
      delay={{ show: 250, hide: 400 }}
      placement="right"
      overlay={showCopiedPopover ? copiedPopover : copyUrlPopover}
    >
      <button className="share-button" onClick={handleCopyClick}>
        <img src={share_icon} alt="Share Icon" />
      </button>
    </OverlayTrigger>
  );
};
