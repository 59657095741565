import { useEffect, useState } from 'react';
import { getMissionEntries } from '../utils/ApiCalls';
import { useLocation, useNavigate } from 'react-router-dom';

export function useFetchMissionEntries(mission, currentWallet, validLoadedMissionEntries) {
  const location = useLocation();
  const navigate = useNavigate();

  const [missionEntries, setMissionEntries] = useState(null);
  const [missionEntriesLoadError, setMissionEntriesLoadError] = useState(null);
  const [isMissionEntriesLoading, setIsMissionEntriesLoading] = useState(false);

  useEffect(() => {
    // If there is no access token, return early.
    if (location.pathname === '/portfolio/tcq-recon' && !currentWallet) return;
    const fetchMissionEntries = async () => {
      setIsMissionEntriesLoading(true);
      try {
        if (mission && !validLoadedMissionEntries) {
          let missionEntries = await getMissionEntries(mission?.id);

          if (location.pathname === '/portfolio/tcq-recon/view-mission' && missionEntries.length === 0) {
            navigate('/portfolio/tcq-recon/new-mission');
          }

          missionEntries.sort((a, b) => a.id - b.id);
          setMissionEntries(missionEntries);
        }

        if (validLoadedMissionEntries) {
          setMissionEntries(validLoadedMissionEntries);
        }

        setIsMissionEntriesLoading(false);
      } catch {
        setMissionEntriesLoadError('Failed to load as missions not found');
        setIsMissionEntriesLoading(false);
      }
    };

    fetchMissionEntries();
  }, [mission, location, currentWallet, validLoadedMissionEntries, navigate]);

  return { missionEntries, missionEntriesLoadError, isMissionEntriesLoading, setMissionEntries };
}
