import './ClaimButton.scss'
import React from "react";

export default function ClaimButton({isActive, activeText, disabledText, handler}) {
  return <>
    {isActive ? <div className={'claim-rewards-button-container'}><div onClick={handler}>{activeText}</div></div> :
      <div className={"claim-border"}>
        <div className={"claim-limit"}></div>
        <p className={"claim-button-label"}>
          {disabledText}
        </p>
      </div>
      }
  </>
}
