import PGModal from '../../Common/PGModal/PGModal';
import GenericButton from '../../Button/GenericButton';
import LoadingSpinner from '../../LoadingSpinner';
import { checkbox_checked } from '../../../assets';
import SuccessMessage from '../../Common/SuccessMessage/SuccessMessage';
import ErrorModal from '../../Modal/ErrorModal/ErrorModal';
import { DashboardSummary } from '../Common/DashboardSummary';
import { Suspense, useEffect, useState } from 'react';
import AssetCardList from '../../Common/AssetCard/AssetCardList';
import { useFetchMyPlanets } from '../../../hooks/useFetchMyPlanets';
import { ERC721GameLock, VaultSignatureLockData } from '@pg/blockchain-api';
import { LockStatus } from '../../Common/AssetCard/AssetCard';
import { createFavourite, deleteFavourite, getVaultSignatures } from '../../../utils/ApiCalls';
import { getBlockchainContract } from '../../../utils/providerObject';
import PGVaultAbi from '@fugu/base-contracts/dist/abis/Vault/Previous.Versions/PGVault.v2.sol/PGVaultv2.json';
import { metamaskErrorMap } from '../../../blockchain/errors';
import { getCurrentPlanetSizeAddress } from '../../../blockchain';
import { walletContext } from '../../../utils/WalletContext';
import { UserPlanetData } from '@pg/planet-api';
import { AssetType } from '@pg/account-api';
import { Info } from './Info/Info';
import { useProvider } from '../../../hooks/useProvider';
import { planet_terraforming } from '../../../assets/images/Planets';
import { Contract } from 'ethers';
import { TransactionResponse } from '@ethersproject/abstract-provider';

/**
 * Some planet properties may return 'null' from the backend
 * but the `Planet` interface from @pg/planet-api expects the values to be number | undefined
 * Adding this custom type here for the test data - this can be removed if not required.
 */
type CustomPlanetProperties = {
  id: string;
  stratosphereTurbulence: number | null;
  electromagneticInterference: number | null;
  acidPools: number | null;
  moltenPools: number | null;
  iceSheetFissure: number | null;
  moons: number | null;
  ringSystem: number | null;
  visibility: number | null;
  unstableTopography: number | null;
  thumbnailImageUrl: string;
  fullImageUrl: string;
  status: LockStatus;
  isBlockchainLocked: boolean;
  gameLocks: ERC721GameLock[];
  isChecked?: boolean;
  alt?: string;
  displayName: string;
};

type VaultSignatureLockAdditionalProperty = {
  contractAddress: string;
};

export type CustomPlanet = Omit<UserPlanetData, keyof CustomPlanetProperties> & CustomPlanetProperties;
export type CustomVaultSignatureLockRequest = VaultSignatureLockData & VaultSignatureLockAdditionalProperty;

enum BindType {
  UNBIND = 'unbind',
  BIND = 'bind',
}

export default function PlanetDashboardContent() {
  const [selectedPlanets, setSelectedPlanets] = useState<CustomPlanet[] | undefined>();
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [inMissionCount, setInMissionCount] = useState(0);
  const [boundCount, setBoundCount] = useState(0);
  const [unboundCount, setUnboundCount] = useState(0);
  const [isBindActionLoading, setIsBindActionLoading] = useState(false);
  const [boundSuccess, setIsBoundSuccess] = useState(false);
  const [showBindModal, setShowBindModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [totalUpdatePlanetsCount, setTotalUpdatePlanetsCount] = useState(0);
  const [bindStatusText, setBindStatusText] = useState('');
  const [errorModalText, setErrorModalText] = useState('');
  const [bindType, setBindType] = useState<BindType>(BindType.UNBIND);
  const { planets, planetsError, setPlanets } = useFetchMyPlanets();

  const provider = useProvider();

  const planetStatus = (isBlockchainLocked: boolean, gameLocks: ERC721GameLock[]): LockStatus => {
    if (gameLocks.length > 0) {
      return LockStatus.IN_MISSION;
    } else if (isBlockchainLocked && gameLocks.length === 0) {
      return LockStatus.BOUND;
    } else {
      return LockStatus.UNBOUND;
    }
  };

  useEffect(() => {
    if (planets.length > 0) {
      setPlanets(prevPlanetList =>
        prevPlanetList.map(planet => ({
          ...planet,
          isChecked: isSelectAll,
          status: planetStatus(planet.isBlockchainLocked, planet.gameLocks),
          altImageText: `A planet named ${planet.name}.`,
        })),
      );
    }
  }, [isSelectAll, planets.length, setPlanets]);

  useEffect(() => {
    setSelectedPlanets(planets?.filter(planet => planet.isChecked));
    setSelectedCount(planets.filter(asset => asset.isChecked).length);
    setInMissionCount(planets.filter(asset => asset.isChecked && asset.status === LockStatus.IN_MISSION).length);
    setBoundCount(planets.filter(asset => asset.isChecked && asset.status === LockStatus.BOUND).length);
    setUnboundCount(planets.filter(asset => asset.isChecked && asset.status === LockStatus.UNBOUND).length);
  }, [planets]);

  const selectAllHandler = (isSelectAll: boolean) => {
    setIsSelectAll(isSelectAll);
    setSelectedCount(isSelectAll ? planets?.length || 0 : 0);
    setInMissionCount(isSelectAll ? planets?.filter(asset => asset.status === LockStatus.IN_MISSION).length || 0 : 0);
    setBoundCount(isSelectAll ? planets?.filter(asset => asset.status === LockStatus.BOUND).length || 0 : 0);
    setUnboundCount(isSelectAll ? planets?.filter(asset => asset.status === LockStatus.UNBOUND).length || 0 : 0);
  };

  const selectPlanetHandler = (id: string) => {
    setPlanets(prevPlanets =>
      prevPlanets.map(planet => {
        if (planet.id === id) {
          planet.isChecked = !planet.isChecked;
        }
        return planet;
      }),
    );
  };

  const updatePlanetFavourite = async (id: string, isFavourite: boolean) => {
    if (isFavourite) {
      await deleteFavourite(id);
    } else {
      await createFavourite(id, AssetType.PLANET);
    }

    setPlanets(prevPlanets =>
      prevPlanets.map(planet => {
        if (planet.id === id) {
          planet.isFavourite = !planet.isFavourite;
        }
        return planet;
      }),
    );
  };

  const handlePlanetBindingAction = async (bindType: BindType) => {
    setIsBindActionLoading(true);
    const vaultSignatureRequestList: CustomVaultSignatureLockRequest[] = [];

    if (!selectedPlanets || selectedPlanets.length < 1) {
      return;
    }

    const state = bindType === BindType.BIND;

    for (const planet of selectedPlanets) {
      const shouldAddToRequest = (state && planet.status === LockStatus.UNBOUND) || (!state && planet.status === LockStatus.BOUND);

      if (shouldAddToRequest) {
        const request = formatVaultSignatureRequest(planet, state);
        vaultSignatureRequestList.push(request);
      }
    }

    try {
      setBindStatusText('Getting signature...');
      const result = await getVaultSignatures(vaultSignatureRequestList);
      setTotalUpdatePlanetsCount(vaultSignatureRequestList.length);
      setBindStatusText('Please sign transaction to proceed');

      const transaction = await set721LockStatus(
        getBlockchainContract(process.env.REACT_APP_CONTRACT_ADDRESS_VAULT, PGVaultAbi.abi, provider) as Contract,
        result.structArrays,
        result.signatures,
      );

      // We set the text to an empty string so the element is hidden
      // Then add the status text to transition the next message
      setBindStatusText('');
      setTimeout(() => {
        setBindStatusText(
          `${bindType === BindType.BIND ? 'Bind' : 'Unbind'}ing ${getPluralizedPlanet(
            totalUpdatePlanetsCount,
          )}\\n This may take a while, please wait...`,
        );
      }, 500);

      const transactionResponse = await transaction.wait();

      if (transactionResponse) {
        setIsBoundSuccess(true);

        const updatePlanetStatus = (planet: CustomPlanet) => {
          const updatedPlanet = selectedPlanets.find(sp => sp.id === planet.id);

          if (updatedPlanet) {
            return {
              ...planet,
              status: state ? LockStatus.BOUND : LockStatus.UNBOUND,
              isBlockchainLocked: state,
            };
          } else {
            return planet;
          }
        };

        const updatedPlanets = planets.map(planet => updatePlanetStatus(planet));
        const updatedSelectedPlanets = selectedPlanets.map(planet => updatePlanetStatus(planet));

        setPlanets(updatedPlanets);
        setSelectedPlanets(selectedPlanets.map(planet => updatePlanetStatus(planet)));
        setBoundCount(updatedSelectedPlanets?.filter(asset => asset.status === LockStatus.BOUND).length);
        setUnboundCount(updatedSelectedPlanets?.filter(asset => asset.status === LockStatus.UNBOUND).length);
      }
    } catch (e) {
      setShowBindModal(false);
      setShowErrorModal(true);

      // throw a generic error if error code is unknown
      if (!metamaskErrorMap.some(error => error === (e as any).code)) {
        setErrorModalText('Something went wrong, please try again later');
        setIsBindActionLoading(false);
        setBindStatusText('');
        return;
      }

      if ((e as any).code === 'ACTION_REJECTED') {
        setErrorModalText('Request rejected. You must approve the blockchain request to continue');
      } else {
        setErrorModalText('Something went wrong, please try again later');
      }
    }

    setBindStatusText('');
    setIsBindActionLoading(false);
  };

  const formatVaultSignatureRequest = (planet: CustomPlanet, state: boolean) => {
    return {
      tokenAddress: getCurrentPlanetSizeAddress(planet.size),
      playerAddress: walletContext.currentWallet,
      state: state,
      tokenId: planet.tokenId,
      amount: `1`,
      deadline: 9999999999999,
      chainId: planet.chainId,
      contractAddress: process.env.REACT_APP_CONTRACT_ADDRESS_VAULT as string,
    };
  };

  const set721LockStatus = async (contract: Contract, struct: {}[], signature: string[]): Promise<TransactionResponse> => {
    return await contract.set721LockedStatusBatch(struct, signature);
  };

  const promptBindingAction = (bindType: BindType) => {
    setShowBindModal(true);
    setBindType(bindType);
  };

  const getBoundCount = () => {
    return bindType === BindType.BIND ? unboundCount : boundCount;
  };

  const getPluralizedPlanet = (count: number) => {
    return count > 1 ? 'planets' : 'planet';
  };

  const getBindingActionPastTense = (bindType: BindType) => {
    return bindType === BindType.BIND ? 'bound' : 'unbound';
  };

  return (
    <>
      <PGModal
        show={showBindModal}
        onHide={() => {
          setShowBindModal(false);
          setIsBoundSuccess(false);
        }}
        // The prompt message will display for a brief moment if we set isBoundSuccess to false as the modal is closed
        // This ensures the success message will persist until the modal has exited completely
        onExited={() => setIsBoundSuccess(false)}
      >
        <div className="planet-dashboard__modal-text-container">
          {!isBindActionLoading && !boundSuccess && (
            <>
              <p className={'planet-dashboard__modal-text'}>{`You are about to ${bindType} ${getBoundCount()} ${getPluralizedPlanet(
                getBoundCount(),
              )}.`}</p>
              <p>Do you wish to proceed?</p>
              <GenericButton
                handleClick={() => handlePlanetBindingAction(bindType)}
                buttonText={bindType}
                buttonClass="planet-dashboard__modal-button"
              />
            </>
          )}
          {isBindActionLoading && (
            <>
              <LoadingSpinner marginBottom={'16px'} />
            </>
          )}

          {bindStatusText.split('\\n').map((text, index) => (
            <p className={bindStatusText ? 'planet-dashboard__modal-loading-text' : 'planet-dashboard__modal-loading-text'} key={index}>
              {text}
            </p>
          ))}

          {boundSuccess && (
            <>
              <img alt="a tick inside of circle" src={checkbox_checked} className="planet-dashboard__modal-success-icon" />
              <SuccessMessage
                message={`You have successfully ${getBindingActionPastTense(bindType)} ${totalUpdatePlanetsCount} ${getPluralizedPlanet(
                  totalUpdatePlanetsCount,
                )}`}
              />
              <GenericButton handleClick={() => setShowBindModal(false)} buttonText="close" buttonClass="planet-dashboard__modal-button" />
            </>
          )}
        </div>
      </PGModal>
      {showErrorModal && (
        <ErrorModal
          onHide={() => {
            setShowErrorModal(false);
            setIsBoundSuccess(false);
          }}
          title="Error"
          text={errorModalText}
          handleConfirm={true}
        />
      )}

      <DashboardSummary
        bindClickHandler={() => promptBindingAction(BindType.BIND)}
        unbindClickHandler={() => promptBindingAction(BindType.UNBIND)}
        selectedCount={selectedCount}
        inMissionCount={inMissionCount}
        boundCount={boundCount}
        unboundCount={unboundCount}
        isBindDisabled={selectedCount < 1 || unboundCount < 1}
        isUnbindDisabled={selectedCount < 1 || boundCount < 1}
        mainText={'IN MISSION'}
      />
      {planetsError && <p style={{ marginBottom: '40px', textAlign: 'center' }}>{planetsError}</p>}
      <Suspense fallback={<LoadingSpinner />}>
        <AssetCardList
          assetListTitle={'My Planets'}
          assetList={planets}
          assetFavouriteHandler={updatePlanetFavourite}
          isSelectAll={isSelectAll}
          selectHandler={selectPlanetHandler}
          selectAllHandler={selectAllHandler}
          fallbackImageUrl={planet_terraforming}
        />
      </Suspense>
      <Info />
    </>
  );
}
