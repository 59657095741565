import React, { useState } from 'react';
import 'reflect-metadata';

import { walletContext } from './utils/WalletContext';
import './App.scss';
import '../src/assets/fonts/icon-fonts/style.css';
import { TooltipProvider } from './components/Context/TooltipContext';

import { WagmiConfig, createConfig, configureChains, mainnet } from 'wagmi';
import { polygon, avalancheFuji, bscTestnet } from '@wagmi/core/chains'

import { publicProvider } from 'wagmi/providers/public';

import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { AuthContext, AuthenticationManager } from "./utils/auth";

// Configure chains & providers with the Alchemy provider.
// Two popular providers are Alchemy (alchemy.com) and Infura (infura.io)
const { chains, publicClient, webSocketPublicClient } = configureChains(
  [polygon, mainnet, avalancheFuji, bscTestnet],
  [publicProvider()],
);

// Set up wagmi config
const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: 'ca488480616216f08bce23d8995deabf',
        metadata: {
          name: 'Phantom Galaxies',
          description: 'Phantom Galaxies',
          url: 'https://phantomgalaxies.com',
          icons: ['https://phantomgalaxies.com/old/img/mecha-3@1x.png'],
        },
      },
    }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'wagmi',
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
});

function App({ children }) {
  const [authPayload, setAuthPayload] = useState(AuthenticationManager.instance.getAccessTokenPayload());

  AuthenticationManager.instance.authStatePublisher.setStateSetter(setAuthPayload);

  return (
    <AuthContext.Provider value={authPayload}>
      <walletContext.context.Provider value={{ wallet: walletContext }}>
        <WagmiConfig config={config}>
          <TooltipProvider>
            {/* This injects the route component (e.g. Home, Downloads) */}
            {/* into the main page */}
            {children}
          </TooltipProvider>
        </WagmiConfig>
      </walletContext.context.Provider>
    </AuthContext.Provider>
  );
}

export default App;
