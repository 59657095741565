import { Col, Row } from 'react-bootstrap';
import TokenBridgeContent from '../components/TokenBridge/TokenBridgeContent';
import HeaderComponent from '../components/Header/HeaderComponent';
import PageHeader from '../components/PageHeader';
import FooterPirate from '../components/Footer/FooterElements/FooterPirate/FooterPirate';
import Footer from '../components/Footer/Footer';
import './TokenBridge.scss';

export function TokenBridge() {
  return (
    <>
      <HeaderComponent noBackground={true} />
      <PageHeader headerText="BRIDGE" classname="page-mobile-header-text-no-margin" />
      <div className={'page-description'}>
        <p>
          Use this token bridging page to securely swap ASTRAFER – the Phantom Galaxies utility and governance token – between blockchain networks.
        </p>
        <p>
          This bridge utilises LayerZero’s omnichain interoperability, enabling users to securely and smoothly exchange ASTRAFER between blockchains.
          This cross-chain bridge currently enables exchanges across Polygon and Ethereum networks.
        </p>
      </div>
      <Row className={'gap-5 mb-5'}>
        <Col className={'d-flex justify-content-center align-items-start'}>
          <TokenBridgeContent />
        </Col>
      </Row>
      <Footer>
        <FooterPirate />
      </Footer>
    </>
  );
}
