import { ethers } from 'ethers';
import { CONTRACT_APPROVAL_STATUS, layerZeroConfig, polygonId } from './Globals';
import { getBlockchainContract } from './providerObject';

const mintTokenByIdWithTransaction = async (contract, walletId, tokenId, amount) => {
  try {
    const tx = await contract.mintBatch([walletId], tokenId, amount, '0x');
    const receipt = await tx.wait();
    return `Hash: ${receipt.transactionHash} Block: ${receipt.blockNumber}`;
  } catch (err) {
    throw new Error(err);
  }
};

const mintTokenById = async (contract, walletId, tokenId, amount) => {
  try {
    await contract.mintBatch([walletId], tokenId, amount, '0x');
  } catch (err) {
    throw new Error(err);
  }
};

const hasApproval = async (contract, walletAddress, targetContractAddress) => {
  let contractIsApproved = await contract.isApprovedForAll(walletAddress, targetContractAddress);

  return Boolean(contractIsApproved);
};

export const requestApproval = async (contract, targetContractAddress) => {
  let result = await contract.setApprovalForAll(targetContractAddress, true);

  await result.wait();
  return Boolean(result);
};

// FOR TESTING, won't be used by the website
export const revokeApproval = async (contract, targetContractAddress) => {
  let result = await contract.setApprovalForAll(targetContractAddress, false);

  await result.wait();
  return Boolean(result);
};

const checkForApproval = async (contract, walletAddress, targetContractAddress) => {
  // First make sure you have approval
  try {
    let contractIsApproved = await hasApproval(contract, walletAddress, targetContractAddress);

    if (!contractIsApproved) {
      let result = await contract.setApprovalForAll(targetContractAddress, true);

      await result.wait();

      if (result) {
        return CONTRACT_APPROVAL_STATUS.APPROVED;
      } else {
        return CONTRACT_APPROVAL_STATUS.UNAPPROVED;
      }
    } else {
      return CONTRACT_APPROVAL_STATUS.APPROVED;
    }
  } catch (err) {
    throw new Error(err);
  }
};

const additionalAllowanceRequired = async (erc20Contract, walletAddress, allowanceNeeded, targetContractAddress) => {
  let currentAllowance = await erc20Contract.allowance(walletAddress, targetContractAddress);
  return allowanceNeeded.sub(currentAllowance);
};

const increaseERC20Allowance = async (erc20Contract, walletAddress, allowanceNeeded, targetContractAddress) => {
  let allowanceToAdd = await additionalAllowanceRequired(erc20Contract, walletAddress, allowanceNeeded, targetContractAddress);
  if (allowanceToAdd.gt(0)) {
    let result = await erc20Contract.increaseAllowance(targetContractAddress, allowanceToAdd.abs());

    await result.wait();

    if (result) {
      return CONTRACT_APPROVAL_STATUS.APPROVED;
    } else {
      return CONTRACT_APPROVAL_STATUS.UNAPPROVED;
    }
  } else {
    return CONTRACT_APPROVAL_STATUS.APPROVED;
  }
};

const checkForERC20Allowance = async (erc20Contract, walletAddress, allowanceNeeded, targetContractAddress) => {
  // First make sure you have approval
  try {
    let currentAllowance = await erc20Contract.allowance(walletAddress, targetContractAddress);

    let allowanceToAdd = allowanceNeeded.sub(currentAllowance);

    if (allowanceToAdd > 0) {
      let result = await erc20Contract.increaseAllowance(targetContractAddress, allowanceToAdd);

      await result.wait();

      if (result) {
        return CONTRACT_APPROVAL_STATUS.APPROVED;
      } else {
        return CONTRACT_APPROVAL_STATUS.UNAPPROVED;
      }
    } else {
      return CONTRACT_APPROVAL_STATUS.APPROVED;
    }
  } catch (err) {
    throw new Error(err);
  }
};

const mintBadge = async (contract, tokenId1, tokenId2, tokenId3, v, r, s) => {
  try {
    await contract.convertInto2TokensSigned(tokenId1, tokenId2, tokenId3, v, r, s);
  } catch (err) {
    throw new Error(err);
  }
};

const convertToken = async (contract, tokenId, tokenId2, amount) => {
  try {
    await contract.convert(tokenId, tokenId2, amount);
  } catch (err) {
    throw new Error(err);
  }
};

const convertMultipleIds = async (contract, tokenAIds, tokenB) => {
  try {
    await contract.convertMultipleIds(tokenAIds, tokenB);
  } catch (err) {
    throw new Error(err);
  }
};

const getTokenSupply = async (contract, tokenId) => {
  try {
    const supply = await contract.getSupplies(tokenId);
    return parseInt(supply);
  } catch (err) {
    throw new Error(err);
  }
};

const getNumberOfTokensMinted = async (contract, tokenId) => {
  try {
    const numberMinted = await contract.minted(tokenId);
    return parseInt(numberMinted);
  } catch (err) {
    throw new Error(err);
  }
};

const startReconMission = async (contract, missionData, signature) => {
  try {
    const tx = await contract.startMission(missionData, signature);
    const receipt = await tx.wait();
    return receipt.transactionHash;
  } catch (err) {
    throw new Error(err);
  }
};

const endReconMission = async (contract, missionData, signature) => {
  try {
    const tx = await contract.endMission(missionData, signature);
    const receipt = await tx.wait();
    return receipt.transactionHash;
  } catch (err) {
    throw new Error(err);
  }
};

const checkInReconMission = async (contract, checkInData, signature) => {
  try {
    let tx;
    // If the checkindata with rewards has 7 items
    if (checkInData.length === 7) {
      tx = await contract.missionCheckInWithRewards(checkInData, signature)
    } else {
      // Check in data without rewards has 5 items
      tx = await contract.missionCheckIn(checkInData, signature)
    }
    const receipt = await tx.wait();
    return receipt.transactionHash;
  } catch (err) {
    throw new Error(err);
  }
}

const set721LockStatus = async (contract, struct, signature) => {
  const tx = await contract.set721LockedStatusBatch(struct, signature);
  return await tx.wait();
};

const getERC20Balance = async (contract, address) => {
  try {
    const balance = (await contract.balanceOf(address)).toString();
    return balance;
  } catch (err) {
    throw new Error(err);
  }
};

const vaultDepositERC20 = async (vaultContract, erc20Contract, walletAddress, amount) => {
  try {
    await increaseERC20Allowance(erc20Contract, walletAddress, amount, vaultContract.address);
    const tx = await vaultContract.depositERC20(erc20Contract.address, amount);
    return await tx.wait();
  } catch (err) {
    throw new Error(err);
  }
};

const sendTokenCrossChains = async (sendingChain, receivingChain, walletAddress, amount, provider) => {

  const proxyContract = getBlockchainContract(layerZeroConfig[sendingChain].proxyAddress, layerZeroConfig[sendingChain].abi, provider);

  // If polygon needs approval
  if (sendingChain === Number(polygonId)) {
    // todo change to .env
    const AstraferAbi = require('@fugu/base-contracts/dist/abis/Tokens/Astrafer.ERC20.sol/Astrafer.json').abi;
    const astraferContract = getBlockchainContract(process.env.REACT_APP_ASTRAFER_ERC20_ADDRESS, AstraferAbi, provider);

    const approval = await increaseERC20Allowance(astraferContract, walletAddress, amount, proxyContract.address);

    if (approval !== CONTRACT_APPROVAL_STATUS.APPROVED) {
      throw new Error('Error approving proxy contract');
    }
  }

  const sendParams = [
    layerZeroConfig[receivingChain].layerZeroChainId,
    ethers.utils.zeroPad(walletAddress, 32),
    amount,
    amount,
    "0x",
    '0x',
    '0x'
  ]
  const [nativeFee] = await proxyContract.quoteSend(sendParams, false);
  const tx = await proxyContract.send(sendParams, [nativeFee, 0], walletAddress, { value: nativeFee });

  const receipt = await tx.wait();
  return layerZeroConfig[sendingChain].etherscanUrl + receipt.transactionHash;
};

export const getContractForChain = async (chainId, provider) => {
  const config = layerZeroConfig[chainId];

  if (!config) {
    throw new Error(`Unsupported chain ID: ${chainId}`);
  }

  return getBlockchainContract(config.astraferAddress ?? config.proxyAddress, config.astraferAbi ?? config.abi, provider);
};

export {
  mintTokenById,
  mintTokenByIdWithTransaction,
  hasApproval,
  checkForApproval,
  mintBadge,
  convertToken,
  convertMultipleIds,
  checkForERC20Allowance,
  additionalAllowanceRequired,
  increaseERC20Allowance,
  getTokenSupply,
  getNumberOfTokensMinted,
  startReconMission,
  endReconMission,
  checkInReconMission,
  set721LockStatus,
  getERC20Balance,
  vaultDepositERC20,
  sendTokenCrossChains,
};
