import React from "react";
import "./CarouselModalButton.scss"
import {button_left_horn, button_right_horn} from "../../../assets/images/CarouselModal";

export default function CarouselModalButton({text, toExecute}) {

  return (<>

    <div
      className={toExecute !== undefined ? "carousel-button-main-container cursor-pointer-button" : "carousel-button-main-container"}
      onClick={() => {
        toExecute !== undefined &&
        toExecute();
      }}>
      <img className={"left-horn-button-adjustment"} src={button_left_horn} alt={""}/>
      <div className={"carousel-button-text-adjustment"}>
        {text}
      </div>
      <img className={"right-horn-button-adjustment"} src={button_right_horn} alt={""}/>
    </div>
  </>)
}
