import React, { SyntheticEvent } from 'react';

type ImageProps = {
  url: string;
  fallback: string;
  altImageText: string;
} & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

const handleImageError = (event: SyntheticEvent<HTMLImageElement, Event>, fallback: string) => {
  event.currentTarget.src = fallback;
  event.currentTarget.onerror = null;
};

export const ImageFallback = ({ url, fallback, altImageText, ...rest }: ImageProps) => (
  <img src={url} onError={event => handleImageError(event, fallback)} alt={altImageText} {...rest} loading={'lazy'} />
);
