import React from "react";
import "./Footer.scss";
import BottomComponent from "./FooterElements/BottomComponent/BottomComponent";
import SubscribeComponent from "./FooterElements/SubscribeComponent/SubscribeComponent";

export default function Footer(props){
  return <>
 <div className={"footer-main-container"}>
   <div className={"empty-div-necessary"}/>
   <SubscribeComponent>
     {props.children}
   </SubscribeComponent>
  <BottomComponent/>
 </div>
  </>
}
