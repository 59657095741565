import React, { useEffect, useState } from 'react';
import GenericButton from 'components/Button/GenericButton';
import { getERC20Balance, vaultDepositERC20 } from 'utils/ContractUtils';
import PGVaultAbi from '@fugu/base-contracts/dist/abis/Vault/Previous.Versions/PGVault.v2.sol/PGVaultv2.json';
import AstraferAbi from '@fugu/base-contracts/dist/abis/Tokens/Astrafer.ERC20.sol/Astrafer.json';
import { getBlockchainContract } from 'utils/providerObject';
import { useAccount } from 'wagmi';
import { ethers } from 'ethers';
import './VaultAstrafer.scss';
import PGModal from '../Common/PGModal/PGModal';
import LoadingSpinner from '../LoadingSpinner';
import { checkbox_checked } from '../../assets';
import SuccessMessage from '../Common/SuccessMessage/SuccessMessage';

export default function VaultDepositAstrafer() {
  const { connector, address } = useAccount();
  const [walletAddress, setWalletAddress] = useState('');
  const [provider, setProvider] = useState(undefined);
  const [vaultContract, setVaultContract] = useState(undefined);
  const [amount, setAmount] = useState(0);
  const [astraferContract, setAstraferContract] = useState(undefined);
  const [astraferBalance, setAstraferBalance] = useState(0);
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [isDepositSuccess, setIsDepositSuccess] = useState(false);
  const [isDepositLoading, setIsDepositLoading] = useState(false);

  useEffect(() => {
    const getAstraferBalance = async astraferContract => {
      const balance = ethers.utils.formatEther(await getERC20Balance(astraferContract, address)).toString();
      setAstraferBalance(balance);
    };

    const runAsyncUseEffect = async () => {
      const thisProvider = await connector?.getProvider();

      setProvider(thisProvider);

      if (thisProvider) {
        setWalletAddress(address);

        const astraferContract = getBlockchainContract(process.env.REACT_APP_ASTRAFER_ERC20_ADDRESS, AstraferAbi.abi, thisProvider);

        setAstraferContract(astraferContract);
        getAstraferBalance(astraferContract);

        const vaultContract = getBlockchainContract(process.env.REACT_APP_CONTRACT_ADDRESS_VAULT, PGVaultAbi.abi, thisProvider);

        setVaultContract(vaultContract);
      }
    };
    runAsyncUseEffect();
  }, [connector, address]);

  const promptDepositTransaction = () => {
    setShowDepositModal(true);
  };

  const vaultDepositAstrafer = async () => {
    setIsDepositLoading(true);
    try {
      if (!amount || amount === 0) {
        return;
      }
      const depositAmount = ethers.utils.parseEther(amount.toString());

      //Next do the deposit
      await vaultDepositERC20(vaultContract, astraferContract, walletAddress, depositAmount);
      setIsDepositLoading(false);
      setIsDepositSuccess(true);
    } catch (error) {
      setShowDepositModal(false);
      console.error(`Error depositing ASTRAFER: ${error}`);
    }
  };

  return (
    <>
      {
        //deposit from blockchain take the intput and multiply by 1e18 no min
        provider && address && (
          <div className={'vault-astrafer__container'}>
            <label className={'vault-astrafer__label'} htmlFor={'astraferDeposit'}>
              DEPOSIT ASTRAFER INTO GAME
            </label>
            <div className={'vault-astrafer__form'}>
              <input
                className={'vault-astrafer__input'}
                placeholder={'Deposit amount'}
                type="number"
                id={'astraferDeposit'}
                min={0}
                onChange={e => setAmount(Number(e.target.value))}
              />
              <GenericButton handleClick={() => promptDepositTransaction()} buttonText="Deposit" disabled={amount > astraferBalance} />
            </div>

            <p>Wallet Balance: {astraferBalance}</p>
            {amount > astraferBalance && (
              <>
                <p className={'vault-astrafer__error-text'}>Insufficient Astrafer to deposit</p>
              </>
            )}
          </div>
        )
      }
      <PGModal
        show={showDepositModal}
        onHide={() => {
          setShowDepositModal(false);
          setIsDepositSuccess(false);
        }}
        // The prompt message will display for a brief moment if we set isBoundSuccess to false as the modal is closed
        // This ensures the success message will persist until the modal has exited completely
        onExited={() => setIsDepositSuccess(false)}
      >
        <div className="vault-astrafer__modal-text-container">
          {isDepositLoading && (
            <>
              <LoadingSpinner marginBottom={'16px'} />
            </>
          )}
          {!isDepositLoading && !isDepositSuccess && (
            <>
              <p>This will require you to sign 2 transactions. </p>
              <p>Do you wish to proceed?</p>
              <p>Note: The Astrafer may take up to a few minutes to appear in game.</p>
              <GenericButton handleClick={() => vaultDepositAstrafer()} buttonText={'YES'} buttonClass="planet-dashboard__modal-button" />
            </>
          )}
          {isDepositSuccess && (
            <>
              <img alt="a tick inside of circle" src={checkbox_checked} className="planet-dashboard__modal-success-icon" />
              <SuccessMessage message={`Congratulations ranger, you have successfully deposited ${amount} Astrafer.`} />
              <GenericButton handleClick={() => setShowDepositModal(false)} buttonText="close" buttonClass="planet-dashboard__modal-button" />
            </>
          )}
        </div>
      </PGModal>
    </>
  );
}
