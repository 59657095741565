import './SocialComponent.scss'
import React from "react";

export default function SocialComponent({item, index, navigateTo, text}) {

  return <>
    <div className="social-component-main-container">
      <div onClick={item.link !== '' ? navigateTo.bind(this, item.link) : <></>}
           key={index} className={`top-menu-dropdown-list-item ${text}`}>
        <img src={item.image} alt={'ICON'} className={'top-menu-dropdown-list-text'}/>
        <span className="social-component-title">
          { item.text }
        </span>
      </div>
    </div>
  </>
}
