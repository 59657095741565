import React, {useState} from 'react';

const AstrafiteRushContext = React.createContext({});

function AstrafiteRushProvider(props) {

  const [apiCallsStatus, setApiCallsStatus] = useState({cycle: false, player: false});
  const [activeCycleNumber, setActiveCycleNumber] = useState(0);
  const [completionHoverDetails, setCompletionHoverDetails] = useState();
  const [cycleData, setCycleData] = useState(null);
  const [playerData, setPlayerData] = useState(null);
  const [onChainSignature, setOnChainSignature] = useState(null);
  const [onChainPlayerStatsData, setOnChainPlayerStatsData] = useState(null);
  const [afrNftContract, setAfrNftContract] = useState(null);
  const [useBiconomy, setUseBiconomy] = useState(null);
  const [planetsHeld, setPlanetsHeld] = useState(null);
  const [afrNftTokenId, setAfrNftTokenId] = useState(null);
  const [eventStatus, setEventStatus] = useState(true);


  return (
    <AstrafiteRushContext.Provider
      value={{
        apiCallsStatus,
        setApiCallsStatus,
        eventStatus,
        setEventStatus,
        activeCycleNumber,
        setActiveCycleNumber,
        completionHoverDetails,
        setCompletionHoverDetails,
        cycleData,
        setCycleData,
        playerData,
        setPlayerData,
        onChainSignature,
        setOnChainSignature,
        onChainPlayerStatsData,
        setOnChainPlayerStatsData,
        afrNftContract,
        setAfrNftContract,
        useBiconomy,
        setUseBiconomy,
        planetsHeld,
        setPlanetsHeld,
        afrNftTokenId,
        setAfrNftTokenId
      }}
    >
      {props.children}
    </AstrafiteRushContext.Provider>
  )
}

export {AstrafiteRushContext, AstrafiteRushProvider};
