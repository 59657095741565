import React from "react";
import "./PlanetGenesisSale.scss";
import {NFT_PlanetGenesis_MediumPlanet, planet_genesis_circle} from "../../../../assets/images/PlanetSalePagePrivate";
import {PlanetPrivateSaleText} from "../../../../utils/GeneralUtils";
import BorderImg from "../BorderImg/BorderImg";

export default function PlanetGenesisSale() {

  return <>
    <div className={"pspp-main-body"}>
      <img alt={""} className={"pgs-planet"} src={NFT_PlanetGenesis_MediumPlanet}/>
      <div id='pgs-border-img'>
        <BorderImg text={PlanetPrivateSaleText.title}/>
      </div>
      <div className={"gsp-text-container"}>
        <div className={"gsp-text-section"}>
          <p className={"gsp-text-adjustment"}>{PlanetPrivateSaleText.paragraph1}</p>
          <p className={"gsp-text-adjustment"}>{PlanetPrivateSaleText.paragraph2}</p>
          <p className={"gsp-text-adjustment"}>{PlanetPrivateSaleText.paragraph3}</p>
        </div>
      </div>
      <img alt={""} className={"psg-left-shape"} src={planet_genesis_circle}/>
    </div>
  </>
}
