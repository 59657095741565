

export default class AudioManager
{
    soundEnabled = true;
    soundCooldowns = new Map();

    static myInstance = null;

    static getInstance() {
        if (AudioManager.myInstance == null) {
            AudioManager.myInstance = new AudioManager();
        }

        return this.myInstance;
    }

    stopSound(theSound)
    {
        theSound.stop();
    }

    pauseSound(theSound)
    {
        theSound.pause();
    }
    resumeSound(theSound)
    {
        if(this.soundEnabled)
        {
            theSound.resume();
        }
    }

    playSound(theSound, volume = 1, loop = true, overlap = false, scene = null)
    {
        if(this.soundEnabled)
        {
            if(theSound.isPaused)
            {
                theSound.resume();
            }
            else
            {
                theSound.play();
            }
            
        }
    }

    playSoundOverlap(scene, soundAssetName, soundVolume = 1, cooldownMilliseconds = 0)
    {
        if(this.soundEnabled)
        {
            var allowSamplePlayback = true;
            var timeStamp = scene.time.now;
            if(cooldownMilliseconds > 0)
            {
                // get the map if it exists
                var storedCooldownTime = this.soundCooldowns.get(soundAssetName);
                if(!storedCooldownTime)
                {
                    // no stored cooldowns so add it and allow sample playback
                    this.soundCooldowns.set(soundAssetName, cooldownMilliseconds);
                    allowSamplePlayback = true;
                }
                else
                {
                    // we have a cooldown time for this sample already
                    var currentTime = scene.time.now;
                    if(currentTime - storedCooldownTime > cooldownMilliseconds)
                    {
                        // cooldown has expired so remove it from the map and play the sample
                        this.soundCooldowns.set(soundAssetName, timeStamp + cooldownMilliseconds);
                        allowSamplePlayback = true;
                    }
                    else
                    {
                        allowSamplePlayback = false;
                    }
                }
                
            }
            if(allowSamplePlayback)
            {
                scene.sound.play(soundAssetName, { volume: soundVolume });
            }
            
        }
    }
}
