import "./GenerativeStarfighterComponent.scss";
import BuyOnOpenSea from "../../NFTComponents/BuyOnOpenSea/BuyOnOpenSea";
import { useEffect, useRef } from "react";
import {
  EMPTY_STRING,
  MAXIMUM_PLANET_MINTS,
} from "../../../utils/GeneralUtils";

export default function GenerativeStarfighterComponent({
  count,
  stock,
  selected,
  isCatalyst,
  imgSrc,
  title,
  onClick,
  totalPlanets,
  openseaUrl,
}) {
  const counterInputRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        counterInputRef.current &&
        !counterInputRef.current.contains(event.target) &&
        count === EMPTY_STRING
      ) {
        onClick(0);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [count, onClick]);

  const plusButtonHandler = () => {
    if (count < stock && totalPlanets !== MAXIMUM_PLANET_MINTS) {
      let updatedCounter = count + 1;
      onClick(updatedCounter);
    }
  };

  const minusButtonHandler = () => {
    if (count > 0) {
      let updatedCounter = count - 1;
      onClick(updatedCounter);
    }
  };
  const handleCounterChange = (e) => {
    const value = e.target.value;
    if (value >= 0 && value !== EMPTY_STRING) {
      if (/^[0-9][0-9]*$/.test(value) || value === "0") {
        if (value <= stock) {
          onClick(parseInt(value));
        } else {
          onClick(parseInt(stock));
        }
      }
    } else {
      onClick(0);
    }
  };

  const nftBorder = () => {
    if (selected) return "generative-starfighter-border selected";
    else if (isCatalyst && stock > 0)
      return "generative-starfighter-border clickable";
    else return "generative-starfighter-border";
  };

  const isOpenSeaButton = () => {
    const titleLower = title.toLowerCase();
    const isMintpassAvailable = titleLower.includes('third class') || titleLower.includes('standard issue');
    let buttonText = 'VIEW ON OPENSEA';
    let link = openseaUrl;
    let imageClass = '';

    if(isMintpassAvailable) {
      buttonText = 'BUY MINTPASS';
      link = '/portfolio/starfighters';
      imageClass = 'generative-starfighter-open-sea-container--hide-image';
    }

    if (stock === 0 || isCatalyst) {
      return (
        <div className={`generative-starfighter-open-sea-container ${imageClass}`}>
          <BuyOnOpenSea
            backgroundColor={"#000"}
            borderColor={"#d42758"}
            text={buttonText}
            link={link}
          />
        </div>
      );
    }

    return (
      <div className={"generative-starfighter-counter-container"}>
        <div
          onClick={minusButtonHandler}
          className={"generative-starfighter-counter-minus"}
        >
          <p>-</p>
        </div>
        <div className={"generative-starfighter-counter-number"}>
          <input
            className={"generative-starfighter-counter-input"}
            onChange={handleCounterChange}
            value={count}
            ref={counterInputRef}
          />
        </div>
        <div
          onClick={plusButtonHandler}
          className={"generative-starfighter-counter-plus"}
        >
          <p>+</p>
        </div>
      </div>
    );
  };


  return (
    <div className={"generative-starfighter-component"}>
      <div
        onClick={isCatalyst && stock > 0 ? onClick : () => {}}
        className={nftBorder()}
      >
        <img
          className={"generative-starfighter-component-image"}
          src={imgSrc}
          alt={"PLANET"}
        />
      </div>
      <div
        className={
          isCatalyst && stock > 0
            ? "generative-starfighter-component-title clickable"
            : "generative-starfighter-component-title"
        }
        onClick={isCatalyst && stock > 0 ? onClick : () => {}}
      >
        {title.toUpperCase()}
      </div>
      <div className={"generative-starfighter-component-stock"}>
        {stock} AVAILABLE
      </div>
      {isOpenSeaButton()}
    </div>
  );
}
