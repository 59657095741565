import './LicenseContent.scss'
import {licenseAndAgreement} from "../../utils/GeneralUtils";
import {
  boarder_detail_bottom,
  boarder_detail_top
} from "../../assets/images/AstrafiteRushEvent";
import React, {useEffect, useState} from "react";
import {walletContext} from "../../utils/WalletContext";
import {acceptTAndCs, hasAcceptedTAndCs} from "../../utils/ApiCalls";
import {T_AND_C_SIGNING_MESSSGE} from "../../utils/Globals";
import { useSignMessage } from 'wagmi';

export default function LicenseContent() {
  const [isSigned, setIsSigned] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    //check to see if the user has accepted the T&Cs before
    hasAcceptedTAndCs(walletContext.currentWallet, walletContext.signature)
      .then(res => {
        setIsSigned(res.data.isAccepted);
        setIsLoading(false);
      });

  });

  const { signMessage } = useSignMessage(
    {
      async onSuccess(data) {
        //Just finished signing message
        await acceptTAndCs(walletContext.currentWallet, walletContext.signature, data);
        setIsSigned(true);
      },
      onError() {
        //Sign message error
        setIsSigned(false);
      }
    }
  );

  const handleAcceptButton = async () => {
    try {
      signMessage({ message: T_AND_C_SIGNING_MESSSGE });
    } catch(err) {
      console.error(err);
      setIsSigned(false);
    }
  }

  return (
    <>
      <div className={'license-content'}>
        <div className={'license-text-container'}>
          {licenseAndAgreement.text}
        </div>
        <div className={'license-accept-container'}>
          <img alt={""} src={boarder_detail_top} className={"license-accept-decoration-top"}/>
          {!isSigned  && !isLoading ? <>
            <div className={'license-accept-button'} onClick={handleAcceptButton}>
              SIGN & ACCEPT
            </div></>:<><p className={'license-signed-text'}>You have signed this agreement.</p></>}
          <img alt={""} src={boarder_detail_bottom} className={"license-accept-decoration-bottom"}/>

        </div>
      </div>
    </>
  )
}
