import './CheckInTab.scss'
import {useEffect, useState} from "react";
import {checked_round_icon, ended_icon, loading_pink_circle, locked_icon} from "../../assets";
import {CHECKPOINT_STATUS} from "../PlanetExploration/PlanetExplorationContent/PlanetExplorationContent";

export default function CheckInTab({checkIn, index}) {
  const { status } = checkIn;
  let [className, setClassName] = useState('locked');
  let [icon, setIcon] = useState({ iconImage: locked_icon, iconClass: 'check-in-tab-locked' });

  useEffect(() => {
    switch (status) {
      case CHECKPOINT_STATUS.COMPLETED:
        setClassName('completed');
        setIcon({iconImage: checked_round_icon, iconClass: 'check-in-tab-completed-ended'});
        break;
      case CHECKPOINT_STATUS.MISSED:
        setClassName('ended');
        setIcon({iconImage: ended_icon, iconClass: 'check-in-tab-completed-ended'});
        break;
      case CHECKPOINT_STATUS.AVAILABLE:
        setClassName('progress');
        break;
      default:
        setClassName('locked');
        setIcon({iconImage: locked_icon, iconClass: 'check-in-tab-locked'});
        break;
    }
  }, [status, index] )

  return <>
    {className === 'progress' ?
      <div className={`check-in-tab-main-container-progress`}>
        <div className={'check-in-tab-left-decor progress-decor'}/>
        <div className={'check-in-tab-text-container'}>
          <p className={'check-in-tab-title'}> CHECK IN {index} </p>
          <p className={'check-in-tab-content'}> IN PROGRESS </p>

            <p className={'check-in-tab-title-progress'}> {index} </p>
            <img src={loading_pink_circle} alt={'progress'} className={'checkin-tab-loading-icon'}/>
        </div>
      </div>
      :
      <div className={`check-in-tab-main-container ${className}`}>
        <div className={'check-in-tab-left-decor'}/>
        <div className={'check-in-tab-right-decor'}>
          <p className={'check-in-tab-title number'}> {index} </p>
            <img src={icon.iconImage} alt={status} className={icon.iconClass}/>
        </div>
      </div>
    }
  </>
}
