import React from "react";
import "./FighterLady.scss";
import {circle_pattern_lady_img, lady_img, small_lady_img} from "../../../../assets/images/Footer";

export default function FighterLady() {

  return (<>
    <div className={"fighter-lady-main-container"} show-on-scroll="true">
      <div className={"align-lady-img"}>
        <img alt={""} src={circle_pattern_lady_img} className={"fighter-lady-background-circle"}/>
        <img alt={""} src={lady_img} className={"fighter-lady-img"}/>
        <img alt={""} src={small_lady_img} className={"fighter-lady-img-small"}/>
      </div>
    </div>
  </>)
}
