import { Form } from 'react-bootstrap';
import './BlockchainTokenInput.scss';
import { TokenNetworkFormType } from '../../TokenForm/TokenNetworkForm';

type BlockchainTokenInputProps = {
  inputAmount: number;
  inputType: TokenNetworkFormType;
  handleInputChange?: (event: React.FormEvent<HTMLElement>) => void;
  handleMaxInput?: () => void;
};

export function BlockchainTokenInput({ inputAmount, handleInputChange, inputType, handleMaxInput }: BlockchainTokenInputProps) {
  return (
    <div className={'token-input__wrapper'}>
      <Form className={'token-input__form'}>
        <Form.Label>Transfer Amount</Form.Label>
        <Form.Control
          className={'token-input__box'}
          name={'amount'}
          type="number"
          value={inputAmount}
          placeholder={inputType === TokenNetworkFormType.Send ? '0' : '-'}
          min={10}
          autoComplete={'off'}
          disabled={inputType === TokenNetworkFormType.Receive}
          onInput={event => {
            const inputValue = event.currentTarget.value;
            // Allow only numbers
            event.currentTarget.value = inputValue.replace(/[^0-9]/g, '');
            handleInputChange?.(event);
          }}
        />
      </Form>
      <div className={'token-input__right-content'}>
        {inputType === TokenNetworkFormType.Send && (
          <button className={'token-input-max__button'} onClick={handleMaxInput}>
            MAX
          </button>
        )}
      </div>
    </div>
  );
}
