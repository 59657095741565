import './PartnerList.scss';

import { Container, Image } from 'react-bootstrap';
import { avocado_dao, aws_icon, epic_games, gamefi, grease_monkey_games, mega_grants, mocaverse, polygon_icon } from "../../assets/images/PartnerLogos";
import PageHeader from "../PageHeader";

export const PartnerList = () => {
  const defaultPartnerLogos = [
    { name: 'Gamefi Ventures logo', image: gamefi, class: 'partner-logos__gamefi' },
    { name: 'AWS logo', image: aws_icon, class: 'partner-logos__aws' },
    { name: 'Unreal engine epic megagrants logo', image: mega_grants, class: 'partner-logos__mega-grants' },
    { name: 'Epic games logo', image: epic_games, class: 'partner-logos__epic' },
    { name: 'Grease Monkey Games logo', image: grease_monkey_games, class: 'partner-logos__grease-monkey' },
    { name: 'Mocaverse logo', image: mocaverse, class: 'partner-logos__mocaverse' },
    { name: 'Polygon logo', image: polygon_icon, class: 'partner-logos__polygon' },
    { name: 'Avocado DAO', image: avocado_dao, class: 'partner-logos__avocado' },
  ];

  return (
    <>
      <PageHeader headerText={'Partners'} headerTextSecondLine={''} classname={'page-mobile-header-text-no-margin'} />
      <Container>
        <div className="partner-logos">
          {defaultPartnerLogos.map(partner => (
            <div key={partner.name} className={partner.class}>
                <Image className="partner-logos__image" fluid src={partner.image} alt={partner.name} />
            </div>
          ))}
        </div>
      </Container>
    </>
  );
};
