import './GenesisNFTCombinationElement.scss'
import BuyOnOpenSea from "../../../NFTComponents/BuyOnOpenSea/BuyOnOpenSea";
import React from "react";

function GenesisNFTCombinationElement ({name, image, nrOwned, openSeaLink}) {
  return (
    <>
      <div className={'nft-combination-element-container'}>
        <div className={'nft-combination-element-name-container'}>
          <div className={'nft-combination-element-name'}>
            {name.split('\n').map((line, index) => (
              <div key={index}>{line}</div>
            ))}
          </div>
        </div>
        <div className={'nft-combination-element-image'}>
          {nrOwned !== 0 ? <>
            <img src={image} alt={""}/>
          </>:<>
            <img src={image} alt={""} className={'genesis-nft-disable-img'}/>
          </>}
        </div>
        <div className={'nft-combination-element-nr-owned'}>
          x{nrOwned} Owned
        </div>
        <div className={'nft-combination-open-sea-container'}>
          <BuyOnOpenSea
            link={openSeaLink}/>
        </div>
      </div>
    </>
  )
}

export {GenesisNFTCombinationElement}
