import React, {useEffect} from "react";
import "./Token.scss";
import HeaderComponent from "../components/Header/HeaderComponent";
import Footer from "../components/Footer/Footer";
import Astrafer from "../components/Astrafer/Astrafer";
import TokenMetrics from "../components/TokenMetrics/TokenMetrics";
import About from "../components/AboutSection/About";
import AstraferTeam from "../components/AstraferTeam/AstraferTeam";
import FighterLady from "../components/Footer/FooterElements/FighterLady/FighterLady";
import initializeTimelines from "../old/scripts/timelines";
import ShowOnScroll from "../old/scripts/show-on-scroll";


export default function Token() {
  function componentDidMount() {
    initializeTimelines();
    setTimeout(function () {
      var instShowOnScroll = new ShowOnScroll();
      instShowOnScroll.init();
    }, 250);
  }
  useEffect(() => {
    componentDidMount();
  })
  return (<>
    <HeaderComponent noBackground={true}/>
    <Astrafer/>
    <TokenMetrics/>
    <AstraferTeam/>
    <About/>
    <Footer>
      <FighterLady/>
    </Footer>
  </>)

}
