import React, {useEffect, useState} from "react";
import "./About.scss";
import {about_section_background, animoca_logo, blow_fish} from "../../assets/images/AboutSection";
import PageHeader from "../PageHeader";
import RevealInfo from "./RevealInfo/RevealInfo";
import {revealText} from "../../utils/GeneralUtils";


export default function About() {

  const [dropDownBackground, setDropDownBackground] = useState(true);
  const [dropDownState, setDropDownState] = useState([{blowFish: "blowFish", state: false},{animoca: "animoca", state: false}]);

  function backgroundAnimation(value) {
    if(value.blowFish!== undefined){

      const newState = dropDownState.map(obj => {
        if (obj.blowFish !== undefined) {
          return {...obj, state: !obj.state};
        }
        return obj;
      });

      setDropDownState(newState);
    }
    if(value.animoca!== undefined){
      const newState = dropDownState.map(obj => {
        if (obj.animoca !== undefined) {
          return {...obj, state: !obj.state};
        }
        return obj;
      });

      setDropDownState(newState);
    }

  }

  useEffect(()=>{
    if (dropDownState[0].state === true || dropDownState[1].state === true) {
      setDropDownBackground(false);
    } else {
      setDropDownBackground(true);
    }
  },[dropDownState])

  return <>
    <div className={dropDownBackground ? "about-main-container" : "about-main-container-animation"}>
      <img alt={""} src={about_section_background} className={"about-img-background"}/>

      <div className={"about-content-container"}>

        <div className={"about-title"}>
          <PageHeader headerText="ABOUT" classname="page-mobile-header-text-no-margin"/>
        </div>

        <div className={"reveal-info-size-container"}>
          <div className={"reveal-info-adjustments"}>
            <RevealInfo imgToShow={blow_fish} textToShow={revealText.paragraph1}
                        heightAnimationLoader={() => backgroundAnimation(dropDownState[0])} onLoade={dropDownState[0].state}/>
          </div>
          <div className={"reveal-info-adjustments-margin"}>
            <RevealInfo imgToShow={animoca_logo} textToShow={revealText.paragraph2}
                        heightAnimationLoader={() => backgroundAnimation(dropDownState[1])} onLoade={dropDownState[1].state}/>
          </div>
        </div>


      </div>
    </div>
  </>
}
