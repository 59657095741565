import React, {useEffect, useState} from 'react';
import './PrivateSaleCountdown.scss';
import {boarder_detail_bottom} from "../../../../assets/images/AstrafiteRushEvent";

export default function PrivateSaleCountdown({targetTime}) {

  let [days, setDays] = useState(0);
  let [hours, setHours] = useState(0);
  let [minutes, setMinutes] = useState(0);
  let [seconds, setSeconds] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  function formatNumberWithMultipleIntegerDigits(number, digits) {
    return number.toLocaleString('en-US', {minimumIntegerDigits: digits, useGrouping: false})
  }

  function countDown() {
    let x = setInterval(() => {
      let now = new Date().getTime();
      let distance = targetTime - now;
      if (distance < 0) {
        clearInterval(x);
      } else {
        setDays(Math.floor(distance / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
        setSeconds(Math.floor((distance % (1000 * 60)) / 1000));
      }
    }, 1000)
    setIntervalId(x);
  }

  useEffect(() => {
    if (typeof intervalId === 'number') {
      clearInterval(intervalId);
    }
    countDown();
    // eslint-disable-next-line
  }, [targetTime])

  function TimeCell({timeValue, unit}) {
    return <div>
      <p className='countdown-time-text'>{timeValue}</p>
      <p className='countdown-time-under-text'>{unit}</p>
    </div>
  }

  return (<>
    <div className='sale-countdown-container'>
      {(days > 0 || hours > 0 || minutes > 0 || seconds > 0) &&
        <div className='sale-countdown-timecells-container'>
          <TimeCell timeValue={`${formatNumberWithMultipleIntegerDigits(days, 2)}`} unit={'DAYS'}/>
          <p className='countdown-time-text-separator'>:</p>
          <TimeCell timeValue={`${formatNumberWithMultipleIntegerDigits(hours, 2)}`} unit={'HOURS'}/>
          <p className='countdown-time-text-separator'>:</p>
          <TimeCell timeValue={`${formatNumberWithMultipleIntegerDigits(minutes, 2)}`} unit={'MINUTES'}/>
          <p className='countdown-time-text-separator'>:</p>
          <TimeCell timeValue={`${formatNumberWithMultipleIntegerDigits(seconds, 2)}`} unit={'SECONDS'}/>
        </div>
      }
      <div className={"bottom-decoration"}>
        <div className={"line-separator"}></div>
        <img alt={""} className={"bottom-crown-decoration"} src={boarder_detail_bottom}/>
      </div>
    </div>
  </>);
}
