import { Col, Container, Row } from 'react-bootstrap';
import { IconCard } from '../../Common/IconCard/IconCard';
import { lock_outline, lock_unlocked, locked_white, tick_white_circle } from '../../../assets';
import './DashboardSummary.scss';

type DashboardSummaryProps = {
  bindClickHandler: () => void;
  unbindClickHandler: () => void;
  mainText: string;
  selectedCount?: number;
  inMissionCount?: number;
  boundCount?: number;
  unboundCount?: number;
  isBindDisabled?: boolean;
  isUnbindDisabled?: boolean;
};

type CardProps = {
  imagePath: string;
  imageAltText: string;
  mainText: string;
  subText: string;
  buttonText?: string;
  clickHandler?: () => void;
  disabled?: boolean;
};

export function DashboardSummary({
  mainText,
  bindClickHandler,
  unbindClickHandler,
  selectedCount = 0,
  inMissionCount = 0,
  boundCount = 0,
  unboundCount = 0,
  isBindDisabled = false,
  isUnbindDisabled = false,
}: DashboardSummaryProps) {
  const cards: CardProps[] = [
    {
      imagePath: tick_white_circle,
      imageAltText: 'tick in white circle',
      mainText: 'SELECTED',
      subText: String(selectedCount),
    },
    {
      imagePath: locked_white,
      imageAltText: 'white locked padlock',
      mainText: mainText,
      subText: String(inMissionCount),
    },
    {
      imagePath: lock_outline,
      imageAltText: 'locked padlock outline',
      mainText: 'BOUND',
      subText: String(boundCount),
      buttonText: 'UNBIND',
      clickHandler: unbindClickHandler,
      disabled: isUnbindDisabled
    },
    {
      imagePath: lock_unlocked,
      imageAltText: 'unlocked padlock outline',
      mainText: 'UNBOUND',
      subText: String(unboundCount),
      buttonText: 'BIND',
      clickHandler: bindClickHandler,
      disabled: isBindDisabled
    },
  ];

  return (
    <div className="dashboard-summary-container">
      <Container className="dashboard-summary">
        <Row className="gx-2 gy-2">
          {cards.map((card, index) => (
            <Col key={index} lg={3} xs={6} sm={6}>
              <IconCard {...card} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}
