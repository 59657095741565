import React from "react";
import "./CounterButton.scss";
import CounterButtonElement from "../CounterButtonElement/CounterButtonElement";

export default function CounterButton(props) {

  function showMinusInactiveButton(nrNFT) {
    if (props.numberOfElement === 0) {
      return false;
    } else {
      return true;
    }
  }

  function showPlusInactiveButton(nrNFT,allowedNrNFT) {
    if (props.numberOfElement < props.numberOfPosibleElements) {
      return true;
    } else {
      return false;
    }
  }

  return <>
    <div className={"cb-principle-container"}>
      <div className={"cb-container"}>
        <CounterButtonElement text={"-"} sellObject={props.sellObject} numberOfElement={props.numberOfElement} index={props.index} nftType={props.nftType} nftAllowedToMint={props.numberOfPosibleElements}  nftId={props.nftId} sellType={props.sellType} enableButton={showMinusInactiveButton()}/>
        <p className={"cb-counter-value-design"}>{props.numberOfElement}/{props.numberOfPosibleElements}</p>
        <CounterButtonElement text={"+"} sellObject={props.sellObject} numberOfElement={props.numberOfElement} index={props.index} nftType={props.nftType} nftAllowedToMint={props.numberOfPosibleElements} nftId={props.nftId} sellType={props.sellType} enableButton={showPlusInactiveButton()}/>
      </div>
    </div>
  </>
}
