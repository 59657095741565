import {Navigate} from "react-router-dom";
import {walletContext} from "../../utils/WalletContext";
import {PropsWithChildren} from "react";

export const PrivateRoute = ({ children }: PropsWithChildren) => {
    return (
        walletContext.signed
            ? children
            : <Navigate to={'/account'}/>
    )
}
