import React from "react";
import "./ImgOverlay.scss"

export default function ImgOverlay(props) {

  return <>

    <div className={"adjustpop"}>
      <div className={"img-ovelay"}>
        <div className={"img-overlay-text"}>
          x{props.nrOfPosters} Owned<br/>
        </div>
      </div>
    </div>
    </>
}
