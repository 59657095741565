import './ExplorationRewardsCell.scss'
import {
  rewards_cell_left_decoration,
  rewards_cell_right_decoration,
  rewards_cell_left_decoration_pink,
  rewards_cell_right_decoration_pink,

} from "../../../assets/images/ExplorationSelection";

export default function ExplorationRewardsCell({cellTitle, cellData, cellColor, cellSize}) {
  let transformDataFormat = (data) => {
    return data?.toLocaleString();
  }

  return <div className={`rewards-cell-container ${cellColor === 'pink' ? 'margin' : ''} ${cellSize === 'big' ? 'big-cell' : ''}`}>
    <div className={'rewards-cell-title-container'}>
      {cellTitle}
    </div>
    <div className={'rewards-cell-data-container'}>
      {cellColor === 'blue' ?
        <img src={rewards_cell_left_decoration} alt={''} className={'rewards-cell-left-decoration'}/>
        : <img src={rewards_cell_left_decoration_pink} alt={''} className={'rewards-cell-left-decoration'}/>}
      <div className={'rewards-cell-data'}>
        {transformDataFormat(cellData)}
      </div>
      {cellColor === 'blue' ?
        <img src={rewards_cell_right_decoration} alt={''} className={'rewards-cell-right-decoration'}/> :
        <img src={rewards_cell_right_decoration_pink} alt={''} className={'rewards-cell-right-decoration'}/>}
    </div>
  </div>
}
