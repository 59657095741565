import React from 'react';

import "./SwitchNetworkChain.scss";
import {CHAINS} from "../utils/ChainHelper";
import { useSwitchNetwork } from 'wagmi'

export function IsOnCorrectChain(network) {
  if (!network) {
    console.error(`Cannot check correct chain as network is null`);
    return false;
  }

  const expectedChain = CHAINS[process.env.REACT_APP_CHAINID];

  if (network?.chain?.id?.toString() === expectedChain.chainId.toString()) {
    return true;
  }

  return false;
}

export default function SwitchNetworkChainSection({ message, network }) {
  const chain = CHAINS[process.env.REACT_APP_CHAINID]

  const { switchNetwork } = useSwitchNetwork({
    onSuccess({ id }) {
      if(id === 1 && window.location.href.endsWith("rewards-claim")) {
        //Dont refresh when swapping to ethereum on claims page - its blockpass trying to do its thing
      } else {
        window.location.reload()
      }
    }
  })

  const switchToCorrectChain = () => {
    switchNetwork?.(chain.chainId);
  }

  return (
    <div className="mx-auto code-panel">
      <p>{message}</p>

      {!IsOnCorrectChain(network) &&
        <div className={"button wallet-button connect-wallet-button switch-network"} style={{textAlign: "center"}}
              onClick={switchToCorrectChain}>
          <span>Switch Network</span>
        </div>}
    </div>
  )
}
