import React from "react";
import "./PlusIconElement.scss";


export default function PlusIconElement(props) {
  return <>
    <div className={"icon-element-img"}>
      <div className={"icon-element-container"}>
        <img alt="" className={"icon-element-dimension"} src={props.icon}/>
      </div>
    </div>
  </>
}
