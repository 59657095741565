import './Account.scss';
import {useContext, useEffect, useState} from 'react';
import Footer from '../components/Footer/Footer';
import './ConnectWallet.scss';
import LoadingSpinner from '../components/LoadingSpinner';
import HeaderComponent from '../components/Header/HeaderComponent';
import FighterLady from '../components/Footer/FooterElements/FighterLady/FighterLady';
import {
  AuthContext,
  authenticatedAccountOptions,
  providerIconMap,
  defaultProviderIcon, AuthenticationManager,
} from "../utils/auth";
import {FederatedAuthenticationHandler} from "../components/FederatedAuthenticationHandler";
import {Accordion, Col, Container, Row} from "react-bootstrap";
import PGModal from "../components/Common/PGModal/PGModal";
import GenericButton from "../components/Button/GenericButton";
import {AuthResponse, useAuthentication} from "../hooks/useAuthentication";
import PageHeader from "../components/PageHeader";
import {AuthenticationProvider} from "@pg/auth-api";
import {walletContext} from "../utils/WalletContext";
import {PGAccordion} from "../components/Common/Accordion/PGAccordion";

export type AuthButtonProps = {
  label: string;
  icon?: string;
  value?: string;
  link?: string;
  linkBuilder?: () => string;
  onClick?: () => Promise<AuthResponse | void>;
  disabled?: boolean;
  children?: AuthButtonProps[];
  class?: string;
}

export type AccountProvider = {
  icon: string;
  button?: AuthButtonProps;
  confirmAction?: boolean;
  label: string;
  provider?: AuthenticationProvider;
  id?: string;
  isBusy?: boolean;
};

export function Account() {
  const authManager = AuthenticationManager.instance;
  const [isBusy, setIsBusy] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedButton, setSelectedButton] = useState<AuthButtonProps | undefined>(undefined);
  const [authResponse, setAuthResponse] = useState<AuthResponse | undefined>(undefined);
  const authData = useContext(AuthContext);

  const { connector, connect, connectors, error, setError } = useAuthentication();

  useEffect(() => {
    setAuthResponse(authManager.getFederatedAuthResponse());
  }, [authManager, error]);

  function blockchainConnectOptions(): AuthButtonProps[] {
    return connectors.map(connector => ({
      label: connector.ready ? connector.name : `${connector.name} (UNSUPPORTED)`,
      icon: providerIconMap.find(i => i.name === connector.name)?.icon ?? defaultProviderIcon,
      onClick: async () => connect({ connector }),
    }));
  }

  function providerList(): AccountProvider[] {
    return authenticatedAccountOptions(authData, blockchainConnectOptions(), walletContext.signed, connector);
  }

  function handleBuildLink(button: AuthButtonProps) {
    window.location.href = button!.linkBuilder!();
  }

  const confirmationHandler = async function () {
    try {
      setIsBusy(true);
      setShowModal(false);
      await handleButtonClick(selectedButton!);
    } finally {
      setIsBusy(false);
    }
  }

  const handleButtonClick = async (button: AuthButtonProps) => {
    const result = await button?.onClick!();
    if (result) {
      setAuthResponse(result);
    }
  }

  const closeModal = () => {
    setShowModal(false);
  }
  const showModalDialog = (selectedButton: AuthButtonProps) =>
  {
    setSelectedButton(selectedButton);
    setShowModal(true);
  }

  const clearMessages = () => {
    setAuthResponse(undefined);
    authManager.clearFederatedAuthResponse();
    setError({ show: false, text: '' });
  }


  return (
    <>
      <HeaderComponent noBackground={true} />
      <PageHeader headerText={'ACCOUNT PAGE'}/>
      <Container className='account-body justify-content-center'>
        <Row className='justify-content-center'>
            <Col className='col-auto'>
              <FederatedAuthenticationHandler displayText={authResponse?.text ?? error.text} onDismiss={clearMessages} isSuccess={!!authResponse?.success}/>
            </Col>
        </Row>
      </Container>
      <Container className={'account-provider-list'}>
        { providerList().map((provider, index) => (
          <Row key={index} className='justify-content-between account-provider-entry mx-1 gy-2 py-3 align-items-center'>
            <Col md={8} xs={8} className="d-flex justify-content-start my-0 align-items-center">
              <img src={provider.icon} alt={`${provider.button?.label} provider`} className='account-provider-icon pe-3'/>
              <p className={'account-provider__label'}>{provider.label}</p>
              { provider.isBusy && <LoadingSpinner marginBottom={1} size='sm'/>}
            </Col>
            { provider.button &&
              <Col md={2} xs={4} className="align-items-center my-0">
                <div className='w-100 '>
                  { provider.button?.link ?
                    <a href={provider.button.link}><GenericButton buttonText={provider.button.label} buttonClass='account-auth-button' borderClass='account-auth-button-border' bordered={true}/></a> :
                      <GenericButton
                          handleClick={provider.button.linkBuilder ? () => handleBuildLink(provider.button!) : (provider.confirmAction ? () => showModalDialog(provider.button!) : () => handleButtonClick(provider.button!))}
                          disabled={provider.button.disabled} buttonText={provider.button.label}
                          buttonClass={`account-auth-button ${provider.button.class}`}
                          borderClass='account-auth-button-border'
                          bordered={true}/>
                  }
                </div>
              </Col>
            }
          </Row>
        )) }
      </Container>
      <PageHeader headerText={'FAQ'}/>
      <Container>
        <p>
          To play Phantom Galaxies, you must create your PG account by linking it with a blockchain wallet, Steam account or Epic Games Store account.
        </p>
        <p>
          A maximum of one blockchain wallet, one Steam account, and one Epic account can be linked to a PG account. All assets associated with each wallet or account will, on linking, be merged into the PG account.
        </p>
        <p>
          NFT assets will always remain associated with their blockchain wallet, regardless of linking or unlinking. However, all other assets will remain associated with your PG account after unlinking, and will not be restored to the original wallet or account.
        </p>
        <p>Unlinking of accounts will be available as a feature at a later date.</p>
      </Container>

      <Container className={'py-5'}>
        <PGAccordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>LINKING WALLETS</Accordion.Header>
            <Accordion.Body>
              <p>
                Remember that once linked, all assets associated with this wallet will be merged into your PG account, and on unlinking, only NFT assets will remain with this wallet. All other assets will remain with the PG account and will NOT be restored to this wallet if it is unlinked in future.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>LINKING ACCOUNTS</Accordion.Header>
            <Accordion.Body>
              <p>
                Remember that once linked, all assets associated with this account will be merged into your PG account. These assets will remain with the PG account and will NOT be restored to this account if it is unlinked in future.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>DO I NEED TO LINK MY WALLET OR ACCOUNTS?</Accordion.Header>
            <Accordion.Body>
              <p>
                Linking a Steam, Epic Games Store, or a wallet to your PG account is required to authenticate your account and play Phantom Galaxies.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>WHAT ARE THE BENEFITS OF LINKING TO MY PG ACCOUNT?</Accordion.Header>
            <Accordion.Body>
              <p>
                All assets associated with each wallet or account will, on linking, be merged into the PG account. This means that linking will allow you to play Phantom Galaxies with the benefits of all assets associated with any linked wallet and accounts.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </PGAccordion>
      </Container>

      <PGModal show={showModal} onHide={closeModal}>
        <div className="planet-dashboard__modal-text-container">
          {!isBusy && (
            <>
              <p className={'planet-dashboard__modal-text'}>ARE YOU SURE YOU WANT TO UNLINK YOUR {selectedButton?.label.split(' ')[1]} ACCOUNT?</p>
              <GenericButton
                handleClick={() => confirmationHandler()}
                buttonText='YES'
                buttonClass="planet-dashboard__modal-button"
              />
            </>
          )}
          {isBusy && (
            <>
              <LoadingSpinner />
            </>
          )}
        </div>
      </PGModal>
      <div>
        <Footer>
          <FighterLady />
        </Footer>
      </div>
    </>
  );
}
