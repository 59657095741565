import './GenesisNFTTab.scss'
import React from "react";

function GenesisNFTTab({title, image}){
  return (
    <>
        <div className={"episode-tab-container"}>
          <div className={'episode-border'}/>
          <div className={"episode-tab-header"}>
            <h2>{title}</h2>
          </div>
          <div className={"nft-tab-image"}>
            <img src={image} alt=""/>
          </div>
        </div>
    </>
  )
}

export {GenesisNFTTab}
