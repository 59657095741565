import React from "react";
import "./CarouselModal.scss";
import {Modal} from "react-bootstrap";
import {
  ReactComponent as CarouselCloseModalButton
} from "../../../src/assets/images/CarouselModal/carousel-close-modal-button.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';
import GetReadyForAstrafiteRush from "./GetReadyForAstrafiteRush/GetReadyForAstrafiteRush";
import LockLoad from "./Lock&Load/Lock&Load";
import TrackSave from "./Tranck&Save/Track&Save";
import SafetyFirst from "./SafetyFirst/SafetyFirst";
import SalvageTheProgressTracker from "./SalvageTheProgressTracker/SalvageTheProgressTracker";
import LiftOff from "./LiftOff/LiftOff";

export default function CarouselModal(props) {


  return (<Modal
      {...props}
      aria-labelledby=""
      centered
      dialogClassName="set-carousel-design"
    >
      <Modal.Body>
        <div className={"content-of-carousel-content"}>
          <CarouselCloseModalButton className={"carousel-close-modal-button"} onClick={() => props.onHide()}/>
          <div className={"carousel-modal-title-element"}>
            <div className={"carouser-title-content"}>HOW TO PLAY</div>
          </div>
          <div className={"carousel-container-for-design"}>
            <Carousel className={"carousel-component-design"} showThumbs={false} useKeyboardArrows={true}
                      showStatus={false} width={"1200"}>
              <div className={"carousel-element-manager"}>
                <GetReadyForAstrafiteRush/>
              </div>
              <div className={"carousel-element-manager"}>
                <LockLoad/>
              </div>
              <div className={"carousel-element-manager"}>
                <TrackSave/>
              </div>
              <div className={"carousel-element-manager"}>
             <SalvageTheProgressTracker/>
              </div>
              <div className={"carousel-element-manager"}>
                <SafetyFirst/>
              </div>
              <div className={"carousel-element-manager"}>
                <LiftOff closeModal={props.onHide}/>
              </div>
            </Carousel>
          </div>
        </div>
      </Modal.Body>

    </Modal>

  )
}
